import { gql } from 'graphql.macro'

export default gql`
  mutation CreateCreditApplication(
    $customerId: String!
    $application: CreditApplicationRequest!
  ) {
    createApplication(customerId: $customerId, application: $application) {
      status
    }
  }
`
