import React from 'react'
import useDimensions from 'react-cool-dimensions'
import Loading from 'components/shared/Loading'
import { DEFAULT_SWIFTLINKS_IFRAME_HEIGHT } from 'properties/properties'
import { inIframe } from 'util/EnvironmentHelper'
import { isLoggedIn } from 'util/SessionHelper'
import { postApplicationStatusMessageToParentWindowForCustomer } from 'util/PrequalificationHelper'
import prequalificationFormStatuses from 'constants/enums/prequalificationFormStatuses'

type Props = {
  customerLoading: Boolean,
  clickedContinue: Boolean,
  lastPostedStatus: any,
  lastPostedLayoutHeight: any,
  setLastPostedMessage: Function
}

const LoadingHandler = ({
  customerLoading,
  clickedContinue,
  lastPostedStatus,
  lastPostedLayoutHeight,
  setLastPostedMessage
}: Props) => {
  const { height: layoutHeight } = useDimensions()
  const nonIframe = customerLoading && isLoggedIn() && !clickedContinue

  if (nonIframe) {
    if (
      inIframe() &&
      lastPostedStatus !== prequalificationFormStatuses.loading &&
      lastPostedLayoutHeight !== layoutHeight
    ) {
      // Empty layout
      postApplicationStatusMessageToParentWindowForCustomer(
        DEFAULT_SWIFTLINKS_IFRAME_HEIGHT,
        prequalificationFormStatuses.loading
      )
      setLastPostedMessage({
        status: prequalificationFormStatuses.loading,
        layoutHeight: DEFAULT_SWIFTLINKS_IFRAME_HEIGHT
      })
    }
  }

  return <Loading />
}

export default LoadingHandler
