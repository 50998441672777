export default theme => ({
  disclosureContainer: {
    overflowY: 'scroll',
    height: '180px',
    border: `0 solid ${theme.palette.grey['400']}`,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey['200'],
    '&::-webkit-scrollbar': {
      appearance: 'none',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      margin: '12px'
    }
  },
  formFieldLabel: {
    marginBottom: theme.spacing(1)
  },
  discloseText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    textAlign: 'top',
    lineHeight: 1.4,
    color: theme.palette.grey['800'],
    marginBottom: theme.spacing(1)
  },
  discloseLink: {
    color: theme.palette.grey['500']
  },
  marginHeader: {
    marginBottom: theme.spacing(4)
  },
  titleHeader: {
    fontWeight: theme.typography.fontWeightRegular
  },
  marginFooter: {
    marginTop: theme.spacing(2)
  },
  labelText: {
    marginTop: theme.spacing(3),
    fontWeight: 400
  },
  errorMessage: {
    marginTop: theme.spacing(1),
    color: theme.palette.error.main
  },
  title: {
    fontSize: '1.5rem',
    color: theme.palette.black
  },
  paragraph: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: 1.5,
    color: theme.palette.black
  },
  disclosureText: {
    marginTop: theme.spacing(2)
  },
  question: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize,
    lineHeight: 1.5,
    color: theme.palette.grey['700']
  }
})
