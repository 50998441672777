// @flow
import React from 'react'
import cx from 'classnames'
import {
  Typography,
  Paper,
  FormControl,
  Select,
  MenuItem
} from '@material-ui/core'
import l10n from 'properties/translations'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'
import serviceOptionValues from 'constants/enums/servicePlanValues'
import { NO_ELEVATION } from 'properties/properties'

type Props = {
  classes: { [string]: string },
  currentValue: number,
  onChangeService?: Function,
  serviceOptions?: Array<{| text: string, value: number |}>,
  isSelected: boolean,
  loanProductSelected: boolean,
  onClick: Function,
  addOnsAmount: number,
  readonly?: boolean,
  qualifiedScreen?: ?boolean,
  servicePlanDropDownOpen?: ?boolean,
  ldFlags: Object
}

const ServicePlan = ({
  classes,
  currentValue,
  onChangeService = () => {},
  serviceOptions = serviceOptionValues,
  isSelected,
  loanProductSelected,
  onClick,
  addOnsAmount,
  readonly = false,
  qualifiedScreen = false,
  servicePlanDropDownOpen = false
}: Props) => {
  const amountAddOns = addOnsAmount.toFixed(2)
  const safeCurrentValue = Number(currentValue).toFixed(0)


  return loanProductSelected ? (
    <>
      <Typography
        variant="body2"
        className={cx(classes.labelTitle, classes.spaceOnTop)}
        data-testid="serviceplan-selectable-title"
      >
        {l10n.quote.servicePlan.title}
      </Typography>
      <Paper
        data-testid="serviceplan-paper"
        elevation={NO_ELEVATION}
        onClick={() => {
          if (!readonly || !qualifiedScreen) onClick()
        }}
        className={cx(classes.row, {
          [classes.rowSelected]: isSelected,
          [classes.rowReadonly]: readonly,
          [classes.rowReadonlyAndSelected]: isSelected && readonly
        })}
      >
        <Typography className={cx(classes.productNameBottomSpace)}>
          {l10n.quote.servicePlan.productName}
        </Typography>
        <Typography variant="caption" display="block">
          <Typography variant="caption" display="block">
            <>
              {l10n.quote.servicePlan.description.maintenancePlan}
              {<SuperScriptAmount price={amountAddOns} />}
              {l10n.quote.servicePlan.description.servicePlanText}
            </>
          </Typography>
        </Typography>
        <FormControl className={classes.formControl}>
          <Select
            value={safeCurrentValue}
            open={servicePlanDropDownOpen}
            onChange={onChangeService}
            name="servicePlan"
            disabled={readonly || qualifiedScreen}
            className={classes.serviceSelectBottomSpace}
            data-testid="service-plan-select"
          >
            {serviceOptions.map(option => (
              <MenuItem
                key={option.value}
                value={option.value}
                data-testid={`service-plan-option-${option.value}`}
              >
                {option.text}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
    </>
  ) : (
    <>
      <Typography
        variant="body2"
        className={cx(classes.labelTitle, classes.spaceOnTop)}
        data-testid="serviceplan-title"
      >
        {l10n.quote.servicePlan.title}
      </Typography>
      <Paper elevation={NO_ELEVATION} className={cx(classes.unavailableRow)}>
        <Typography variant="caption" display="block">
          {l10n.quote.servicePlan.unavailable}
        </Typography>
      </Paper>
    </>
  )
}

ServicePlan.defaultProps = {
  currentValue: 0
}

export default ServicePlan
