// @flow
import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { isEmpty, compact } from 'lodash'
import Dialog from 'components/shared/Dialog'
import Loading from 'components/shared/Loading'
import TranslationsContext from 'util/TranslationsContext'

import AddressVerification from 'screens/apply/AddressVerification'
import { SPACE_SEPARATOR } from 'properties/properties'

type Props = {
  open: boolean,
  onSubmit: (formValues: any) => void,
  onClose: () => void,
  onEntered: () => void,
  customer: {
    firstName: string,
    lastName: string,
    middleName?: string,
    email: string,
    phoneNumber: string | number
  },
  addresses: any[],
  classes: any
}

const styles = theme => ({
  paper: {
    padding: theme.spacing(2)
  }
})

const AddressVerificationDialog = ({
  open,
  onSubmit,
  onClose,
  onEntered,
  customer: { firstName, middleName, lastName, email, phoneNumber },
  addresses,
  classes
}: Props) => {
  const l10n = useContext(TranslationsContext)

  return (
    <Dialog
      open={open}
      title={l10n.apply.addressVerification.confirmTitle}
      onClose={onClose}
      onEntered={onEntered}
      classes={{ dialogScrollPaper: classes.paper }}
    >
      {!isEmpty(addresses) && firstName && lastName ? (
        <AddressVerification
          hideTitle
          userNameComplete={compact([firstName, middleName, lastName]).join(SPACE_SEPARATOR)}
          email={email}
          phoneNumber={phoneNumber ? phoneNumber.toString() : undefined}
          addresses={[...addresses]}
          handleDismiss={onClose}
          handleSubmit={onSubmit}
        />
      ) : (
        <Loading block />
      )}
    </Dialog>
  )
}

export default withStyles(styles)(AddressVerificationDialog)
