import Cookies from 'js-cookie'
import { makeSafeLocalStorage } from 'util/makeSafeStorage'
import MobileInfo from './MobileInfo'

const safeLocalStorage = makeSafeLocalStorage()

const DEFAULT_STORAGE_EXPIRATION = { expires: 365 }

export default {
  get(key) {
    if (MobileInfo.isStandalone()) {
      return safeLocalStorage.getItem(key)
    }

    return Cookies.get(key)
  },
  remove(key, options = {}) {
    if (MobileInfo.isStandalone()) {
      safeLocalStorage.removeItem(key)
    } else {
      Cookies.remove(key, { ...DEFAULT_STORAGE_EXPIRATION, ...options })
    }
  },
  set(key, value, options = {}) {
    const isStandalone = MobileInfo.isStandalone()
    if (isStandalone) {
      safeLocalStorage.setItem(key, value)
    } else {
      const cookieOptions = { ...DEFAULT_STORAGE_EXPIRATION, ...options }
      Cookies.set(key, value, cookieOptions)
    }
  }
}
