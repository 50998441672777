import get from 'lodash/get'
import { nth } from 'lodash'

import {
  GENERIC_ERROR_MESSAGE_TIMEOUT,
  ERROR_REGEX_STR,
  handledErrorTypes,
  handledErrorKeys,
  suppressedErrorTypes
} from 'properties/properties'
import errorTypes from 'constants/enums/errorTypes'
import { QuoteStore } from 'screens/quote/QuoteStore'

export const writeMessageToCache = ({
  cache,
  text,
  errMsg,
  errorType,
  timeout = GENERIC_ERROR_MESSAGE_TIMEOUT
}) => {
  return QuoteStore.setMessage({
    __typename: 'message',
    text: [text, errMsg, errorType],
    timeout
  })
}

export const graphQLErrorsAreHandledByComponent = graphQLErrors => {
  if (graphQLErrors) {
    const serverErr = graphQLErrors.every(
      error => error.message === errorTypes.internalServerError
    )
    if (serverErr) return true
    return (
      graphQLErrors.every(error =>
        handledErrorTypes.includes(get(error, 'extensions.type'))
      ) ||
      graphQLErrors.every(error =>
        handledErrorKeys.includes(get(error, 'extensions.key'))
      )
    )
  }
  return false
}

export const suppressErrorMessage = errorMessage =>
  suppressedErrorTypes.includes(errorMessage)

export const displayCustomError = errorMessage =>
  nth(ERROR_REGEX_STR.exec(errorMessage), 1) // get group 1 in regex match
