import React, { useContext } from 'react'
import { get, noop } from 'lodash'
import { withRouter, Redirect } from 'react-router-dom'
import PrequalificationRouteContext from 'util/PrequalificationRouteContext'
import UserContext from 'util/UserContext'
import ApplyContext from 'util/ApplyContext'
import Routes from 'util/Routes'
import { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import ApplicationFormStatus from 'screens/apply/ApplicationFormStatus/ApplicationFormStatus'

type Props = {
  customer: Object,
  isEstimateFlow: Boolean,
  quote: Object,
  stopPolling: Function
}

const RedirectIfCreditApplication = ({
  customer,
  isEstimateFlow,
  quote,
  stopPolling
}: Props) => {
  const { isSwiftlinks } = useContext(PrequalificationRouteContext)
  const { me } = useContext(UserContext)

  const { FIELDSET_PUBLIC_APPLICATION } = fieldsetNames
  const contractStatus = get(customer, 'contract.status')
  const creditApplication = get(customer, 'creditApplication')

  if (!isSwiftlinks) {
    return (
      <Redirect
        to={{
          pathname: Routes.apply(customer.id),
          state: { isEstimateFlow }
        }}
      />
    )
  }
  return (
    // Application status page i.e. processing
    <ApplyContext.Provider
      value={{
        fieldset: FIELDSET_PUBLIC_APPLICATION
      }}
    >
      <ApplicationFormStatus
        hideMenu
        quote={quote}
        creditApplication={creditApplication}
        onContinue={noop}
        onEditOffer={noop}
        customerId={customer.id}
        contractStatus={contractStatus}
        resetErrorMessage={noop}
        alternateFinancingEnabled={false}
        stopPollingClient={stopPolling}
        isProductLoading={false}
        me={me}
      />
    </ApplyContext.Provider>
  )
}
export default withRouter(RedirectIfCreditApplication)
