import { createContext } from 'react'
import { memoize, get } from 'lodash'

import {
  getDisclosureFromPackage,
  getDisclosurePackageForCustomerResponse
} from 'util/DisclosuresHelper'
import { DEFAULT_LANGUAGE_KEY, EMPTY_STRING } from 'properties/properties'
import { isDevelopmentEnv } from './EnvironmentHelper'

export default createContext({
  disclosurePackage: null,
  disclosureFileForId: () => {},
  disclosureFileForAssetType: () => {}
})

const getDisclosureFile = (disclosurePackage, query) => {
  const disclosure = getDisclosureFromPackage(disclosurePackage, query)
  if (isDevelopmentEnv()) {
    // eslint-disable-next-line no-console
    console.log(
      '[DisclosurePackageContext] Loading disclosure from',
      disclosure
    )
    if (!disclosure) {
      // eslint-disable-next-line no-console
      console.error(
        `[DisclosurePackageContext] Wasn't able to load disclosure for ${JSON.stringify(
          query
        )}`
      )
    }
  }
  return disclosure
}

export const disclosurePackageContextValue = memoize(
  (customer, channelPartner) => {
    const disclosurePackage = getDisclosurePackageForCustomerResponse(
      customer,
      channelPartner
    )
    return {
      disclosurePackage,
      disclosureFileForId: memoize(disclosureIdentifier =>
        getDisclosureFile(disclosurePackage, { disclosureIdentifier })
      ),
      disclosureFileForAssetType: memoize(disclosureAssetType =>
        getDisclosureFile(disclosurePackage, { disclosureAssetType })
      )
    }
  },
  (customer, channelPartner) => {
    const defaultLoanType = get(
      channelPartner,
      'projectConfiguration.defaultLoanType',
      EMPTY_STRING
    )
    return [
      get(customer, 'loanType', defaultLoanType),
      get(customer, 'language', DEFAULT_LANGUAGE_KEY)
    ].join('-')
    // Cache key should be loan type
  }
)
