import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'themes/default'

const styles = () => ({
  errorText: { color: colors.errorRed }
})

const ErrorMessage = ({ error, classes }) => {
  return error ? (
    <Typography
      data-testid="error-message"
      variant="body2"
      className={classes.errorText}
    >
      {error}
    </Typography>
  ) : null
}

export default withStyles(styles)(ErrorMessage)
