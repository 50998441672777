//@flow
import React, { useRef, useContext } from 'react'
import TextValidator from 'components/shared/TextValidator'
import TranslationsContext from 'util/TranslationsContext'
import { EMPTY_STRING } from 'properties/properties'
import customerFields from 'constants/enums/customerFields'

type Props = {
  canEdit: boolean,
  email?: string,
  demoMode: boolean,
  onFieldEdit: Function,
  inputProps: Object,
  emailAddressVerified: boolean,
  helperText: string,
  required?: boolean
}

const ReferredEmailField = ({
  canEdit,
  email = EMPTY_STRING,
  demoMode,
  onFieldEdit,
  inputProps,
  emailAddressVerified,
  required = false
}: Props) => {
  const assignedToRef = useRef()
  const l10n = useContext(TranslationsContext)

  return (
    <TextValidator
      id={customerFields.assignedTo}
      ref={assignedToRef}
      fullWidth
      label={l10n.customer.assignedToLabel}
      onChange={onFieldEdit(customerFields.assignedTo)}
      name={customerFields.assignedTo}
      value={email}
      disabled={emailAddressVerified || !canEdit || demoMode}
      required={required}
      inputProps={{
        ...inputProps,
        'data-testid': `customer-${customerFields.assignedTo}`
      }}
      placeholder={l10n.customer.email}
      validators={[
        l10n.foundation.applicationContent.errorMessages.textValidatorEmailCheck
      ]}
      errorMessages={[l10n.customer.emailErrorMessage]}
      type="email"
    />
  )
}

export default ReferredEmailField
