// @flow
import React, { type Node as ReactNode, useContext } from 'react'
import cx from 'classnames'
import { Box, Container, Grid, Paper } from '@material-ui/core'
import { withRouter } from 'react-router-dom'
import { flow } from 'lodash'
import BackButton from 'components/shared/BackButton'
import StageNavigation from 'components/shared/StageNavigation'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import PageLayoutContext, {
  pageLayoutContextValue
} from 'util/PageLayoutContext'
import pageVariants, { type PageVariants } from 'constants/enums/pageVariants'
import { inIframe } from 'util/EnvironmentHelper'
import { handleBackButtonPressed } from 'util/Routes'
import stylesModule from '../../screens/common/OpportunityViewScreen.module.css'
import LogoBanner from '../shared/LogoBanner'
import useStyles from './Page.styles'
import { NO_ELEVATION, DEFAULT_ELEVATION } from 'properties/properties'
import UserContext from 'util/UserContext'
import { isBorrower } from 'util/CustomerHelper'

type Props = {
  classes?: any,
  variant?: PageVariants,
  children: ReactNode,
  customerId?: string,
  ariaLabel?: string,
  to?: string,
  dataTest?: string,
  background?: boolean,
  pageRef?: React$Ref<any>,
  ldFlags?: Object,
  isSalesforceIdentityEnabled?: boolean
}

const WithLogo = ({ pageRef, classes, children }) => (
  <div ref={pageRef}>
    <LogoBanner />
    <Paper className={classes.pageRoot} elevation={DEFAULT_ELEVATION}>
      <Grid className={classes.pageContainer} container>
        {children}
      </Grid>
    </Paper>
  </div>
)

const NarrowLogo = ({ pageRef, classes, children }) => (
  <div ref={pageRef}>
    <LogoBanner />
    <Grid container justify="center" alignItems="stretch">
      <Grid item xs={10} sm={7} md={6} lg={4}>
        <Paper elevation={DEFAULT_ELEVATION}>
          <Grid className={classes.pageNarrowContainer} container>
            {children}
          </Grid>
        </Paper>
      </Grid>
    </Grid>
  </div>
)

const StageNav = ({
  pageRef,
  classes,
  children,
  customerId,
  showBackButton,
  ariaLabel,
  to,
  dataTest
}) => {
  return (
    <div ref={pageRef}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <StageNavigation customerId={customerId} />
        </Grid>
      </Grid>
      <Paper className={classes.paperRoot} elevation={DEFAULT_ELEVATION}>
        {showBackButton && (
          <BackButton ariaLabel={ariaLabel} to={to} dataTest={dataTest} />
        )}
        <Box className={classes.pageContainer}>{children}</Box>
      </Paper>
    </div>
  )
}

const StageNavBack = ({
  pageRef,
  classes,
  children,
  customerId,
  ariaLabel,
  to,
  dataTest = '',
  isSalesforceIdentityEnabled
}: Props) => (
  <div ref={pageRef}>
    <Grid container spacing={2} className={classes.stageNavBackPadTop}>
      <Grid item xs={12}>
        <StageNavigation customerId={customerId} />
      </Grid>
    </Grid>
    <Paper
      className={classes.paperStageNavBack}
      elevation={inIframe() ? NO_ELEVATION : DEFAULT_ELEVATION}
    >
      {!inIframe() && !isSalesforceIdentityEnabled && (
        <BackButton ariaLabel={ariaLabel} to={to} dataTest={dataTest} />
      )}
      <Box className={classes.boxStageNavBack}>{children}</Box>
    </Paper>
  </div>
)

const Default = ({
  pageRef,
  classes,
  children,
  ariaLabel,
  to,
  dataTest = '',
  showBackButton
}) => {
  return (
    <div ref={pageRef}>
      <div
        className={cx({
          [classes.headerReplacement]: true,
          [classes.stageNavBackPadTop]: showBackButton
        })}
      />
      <Paper className={classes.pageRoot} elevation={DEFAULT_ELEVATION}>
        {showBackButton && (
          <BackButton ariaLabel={ariaLabel} to={to} dataTest={dataTest} />
        )}
        <Grid className={classes.pageContainer} container>
          {children}
        </Grid>
      </Paper>
    </div>
  )
}

const LogoRight = ({ pageRef, classes, children }) => (
  <div data="logo-right" ref={pageRef}>
    <LogoBanner small align="right" />
    <Grid
      className={cx(
        classes.pageContainer,
        classes.pagePrequalificationFormContainer,
        classes.pagePrequalificationFormContainer
      )}
      container
    >
      <Grid item classes={{ root: classes.flex }}>
        {children}
      </Grid>
    </Grid>
  </div>
)

const WithoutLogo = ({ pageRef, children }) => (
  <Grid ref={pageRef}>{children}</Grid>
)

const Page = React.forwardRef((props: Props, ref) => {
  const { me } = useContext(UserContext)
  const classes = useStyles()
  const { history } = props
  const {
    variant = pageVariants.default,
    customerId = '',
    ariaLabel = '',
    to = handleBackButtonPressed(history),
    dataTest = '',
    background = true,
    showBackButton = false,
    ldFlags,
    isPersisted = true,
    ...other
  } = props
  const showBackground =
    background && !inIframe() && (isBorrower(me) || !me?.isSalesforceIdentityEnabled)

  const pageProps = { pageRef: ref, classes }
  if (other.ldClient) {
    delete other.ldClient
  }

  return (
    <PageLayoutContext.Provider value={pageLayoutContextValue(variant)}>
      <Container
        className={cx({
          [stylesModule['has-customer']]: showBackground
        })}
        classes={{
          root: cx({
            [classes.root]: isPersisted,
            [classes.rootNew]: !isPersisted,
            [classes.rootLogoRight]: variant === pageVariants.logoRight
          })
        }}
        disableGutters
        maxWidth={false}
        {...other}
      >
        {variant === pageVariants.withLogo && (
          <WithLogo {...pageProps}>{props.children}</WithLogo>
        )}
        {variant === pageVariants.narrowLogo && (
          <NarrowLogo {...pageProps}>{props.children}</NarrowLogo>
        )}
        {variant === pageVariants.stageNav && (
          <StageNav
            {...pageProps}
            customerId={customerId}
            showBackButton={showBackButton}
            to={to}
            ldFlags={ldFlags}
          >
            {props.children}
          </StageNav>
        )}
        {variant === pageVariants.stageNavBack && (
          <StageNavBack
            {...pageProps}
            customerId={customerId}
            ariaLabel={ariaLabel}
            to={to}
            dataTest={dataTest}
            ldFlags={ldFlags}
            isSalesforceIdentityEnabled={me?.isSalesforceIdentityEnabled}
          >
            {props.children}
          </StageNavBack>
        )}
        {variant === pageVariants.default && (
          <Default
            {...pageProps}
            showBackButton={showBackButton}
            ariaLabel={ariaLabel}
            to={to}
            dataTest={dataTest}
            ldFlags={ldFlags}
          >
            {props.children}
          </Default>
        )}
        {variant === pageVariants.logoRight && (
          <LogoRight {...pageProps}>{props.children}</LogoRight>
        )}
        {variant === pageVariants.withoutLogo && (
          <WithoutLogo {...pageProps}>{props.children}</WithoutLogo>
        )}
      </Container>
    </PageLayoutContext.Provider>
  )
})

export default flow(withRouter, withLaunchDarkly)(Page)
