import { useState, useEffect } from 'react'
import GetUserType from 'components/shared/queries/GetUserType.graphql'
import { useQuery } from '@apollo/client'
import userRolesEnum from 'constants/enums/userRoles'
import { isLoggedIn } from 'util/SessionHelper'
import { isTestingEnv } from 'util/EnvironmentHelper'

const useAccessControl = (
  allowedUserTypes = []
  //   renderNoAccess = () => null
) => {
  const { loading, error, data } = useQuery(GetUserType)
  const [hasAccess, setHasAccess] = useState(false)
  const [hasAdminRole, setHasAdminRole] = useState(false)
  const [hasSalesManagerRole, setHasSalesManagerRole] = useState(false)
  const [hasOwnerRole, setHasOwnerRole] = useState(false)
  const [isMosaicSupport, setIsMosaicSupport] = useState(false)
  const [userType, setUserType] = useState(null)
  const [permissions, setPermissions] = useState(null)
  const [userRoles, setUserRoles] = useState(null)

  useEffect(() => {
    const initialize = () => {
      if (data && data.me) {
        const {
          me: {
            userType: userTypeFromData,
            userRoles: userRolesFromData,
            permissions: permissionsFromData
          } = {}
        } = data

        setHasAccess(allowedUserTypes.includes(userTypeFromData))
        setUserType(userTypeFromData)
        setPermissions(permissionsFromData)
        setUserRoles(userRolesFromData)
        setHasAdminRole(
          userRolesFromData &&
            userRolesFromData.includes(userRolesEnum.installer.admin)
        )
        setHasSalesManagerRole(
          userRolesFromData &&
            userRolesFromData.includes(userRolesEnum.installer.salesmgr)
        )
        setHasOwnerRole(
          userRolesFromData &&
            userRolesFromData.includes(userRolesEnum.installer.owner)
        )
        setIsMosaicSupport(
          userRolesFromData &&
            userRolesFromData.includes(userRolesEnum.installer.mosaicSupport)
        )
      }
    }

    if (isLoggedIn() || isTestingEnv()) {
      initialize()
    }

    return null
  })

  return {
    loading,
    error,
    data,
    hasAccess,
    permissions,
    userType,
    hasAdminRole,
    hasSalesManagerRole,
    hasOwnerRole,
    isMosaicSupport,
    userRoles
  }
}

export default useAccessControl
