// @flow
import React, { Component } from 'react'
import { Query } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { flow, get, noop } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { customerLanguageKey } from 'util/CustomerHelper'
import { localizedTranslations } from 'properties/translations'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import verificationMutationActions from 'constants/enums/verificationMutationActions'
import languageKeys from 'constants/enums/languageKeys'
import { singleDobFieldFlagEnabled } from 'util/FeatureHelper'
import routes from 'util/Routes'
import Loading from 'components/shared/Loading'
import VerifyIdentityForm from './VerifyIdentityForm'
import GetCreditApplication from '../queries/GetCreditApplication.graphql'
import ValidateSsn from '../queries/ValidateSsn.graphql'
import ValidateChallenge from '../queries/ValidateChallenge.graphql'
import styles from './VerifyIdentityContent.styles'
import { formatDateOfBirth } from './utils/verifyIdentityUtils'
import GetCustomerVerify from '../queries/GetCustomerVerify.graphql'

const { validateSsn, validateChallenge } = verificationMutationActions

const mutationMap = {
  [validateSsn]: ValidateSsn,
  [validateChallenge]: ValidateChallenge
}

type Props = {
  client: {
    mutate: Function
  },
  classes: any,
  customerId: string,
  description: string,
  mutationToInvoke: string,
  onVerificationSuccess: Function,
  fullSsnRequired: boolean,
  ldFlags: Object,
  dobRequired: boolean,
  history: {
    push: Function
  }
}

type State = {
  error: string,
  submitting: boolean
}

class VerifyIdentityContent extends Component<Props, State> {
  static defaultProps = {
    onVerificationSuccess: noop,
    fullSsnRequired: false,
    dobRequired: true
  }

  state = {
    error: '',
    submitting: false
  }
  redirectToApply = customerId => {
    const { history } = this.props
    history.push(routes.apply(customerId))
  }

  handleFormSubmit = async ({ formValues, l10n }) => {
    const {
      client: { mutate },
      mutationToInvoke,
      onVerificationSuccess,
      dobRequired
    } = this.props
    const mutation = mutationMap[mutationToInvoke]

    if (mutation) {
      const id = formValues.customerId

      const variables = {
        customerId: id,
        validateSsnRequest: {
          ssn: formValues.ssn,
          dateOfBirth: formatDateOfBirth({ formValues, dobRequired })
        }
      }

      try {
        this.setState({ error: '', submitting: true })

        const { data } = await mutate({
          mutation,
          variables,
          refetchQueries: [
            {
              query: GetCreditApplication,
              variables: { id }
            }
          ]
        })

        const validationSuccess = get(data, mutationToInvoke)
        if (!validationSuccess) {
          this.setState({
            error: l10n.apply.confirmIdentityError,
            submitting: false
          })
          if (mutationToInvoke === verificationMutationActions.validateSsn) {
            this.redirectToApply(id)
          }
        } else {
          onVerificationSuccess()
        }
        // eslint-disable-next-line no-empty
      } catch (_) {}
    }
  }

  render() {
    const { error, submitting } = this.state
    const {
      classes,
      customerId,
      description,
      fullSsnRequired,
      ldFlags,
      dobRequired
    } = this.props

    const singleDobField = singleDobFieldFlagEnabled(ldFlags)

    return (
      <Query query={GetCustomerVerify} variables={{ customerId }}>
        {({ data = {}, loading }) => {
          if (loading) return <Loading block />
          const customer = get(data, 'customer', {})
          const l10n = localizedTranslations(customerLanguageKey(customer))
          return (
            <Grid
              container
              className={
                singleDobField
                  ? classes.verifyIdentityDialogSingleDob
                  : classes.verifyIdentityDialog
              }
              data-testid="verify-identity-dialog"
            >
              <Grid item xs={12}>
                <Typography
                  variant="body2"
                  paragraph
                  data-testid="verify-identity-description"
                >
                  {description}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <VerifyIdentityForm
                  fullSsnRequired={fullSsnRequired}
                  dobRequired={dobRequired}
                  error={error}
                  translations={l10n}
                  customerId={customerId}
                  customer={customer}
                  submitting={submitting}
                  onSubmitForm={formValues =>
                    this.handleFormSubmit({ formValues, l10n })
                  }
                  languageIsEs={
                    customerLanguageKey(customer) === languageKeys.es
                  }
                />
              </Grid>
            </Grid>
          )
        }}
      </Query>
    )
  }
}

export default flow(
  withApolloConsumer,
  withStyles(styles),
  withLaunchDarkly
)(VerifyIdentityContent)
