import elbowImage from 'images/elbow-2.png'
import { colors } from 'themes/default'

export default theme => ({
  projectType: {
    '& div > div > svg': {
      color: theme.palette.black,
      width: 24,
      height: 24,
      opacity: 0.65
    },
    '& input': {
      marginTop: '2px'
    }
  },
  costWrapper: {
    position: 'relative'
  },
  removeIcon: {
    position: 'absolute',
    top: 0,
    right: 0,
    color: theme.palette.black,
    marginTop: 14,
    marginRight: 2,
    width: 14,
    height: 14,
    opacity: 0.27,
    cursor: 'pointer'
  },
  text: {
    float: 'left',
    verticalAlign: 'middle',
    margin: '10px 0 0 10px'
  },
  description: {
    paddingLeft: theme.spacing(4),
    marginTop: theme.spacing(2)
  },
  descWrapper: {
    backgroundImage: `url("${elbowImage}")`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '17px 58px',
    backgroundPositionY: '-13px',
    height: 'auto',
    position: 'relative'
  },
  optDescription: {
    margin: '5px 0 0 0px'
  },
  endAdornmentReset: {
    cursor: 'pointer'
  },
  errorContainer: {
    marginBottom: theme.spacing(1)
  },
  errorMessage: {
    color: colors.errorRed
  }
})
