// @flow
export const loanProductTypeEnum = Object.freeze({
  deferredInterestFlex: 'DEFERRED_INTEREST_FLEX',
  deferredInterest: 'DEFERRED_INTEREST',
  reducedRateAPR: 'REDUCED_RATE_APR',
  powerSwitch: 'POWER_SWITCH',
  light: 'LIGHT'
})

export type LoanProductTypes = $Values<typeof loanProductTypeEnum>

export default loanProductTypeEnum
