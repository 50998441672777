import lodashParseInt from 'lodash/parseInt'
import Routes from 'util/Routes'

export const validRoutingNumberValidationRule = routingNumber => {
  const number = lodashParseInt(routingNumber)

  if (!number || routingNumber.length !== 9) return true

  const numbers = Array(9)
    .fill(null)
    .map((_, i) => lodashParseInt(routingNumber[i]) || 0)

  // https://en.wikipedia.org/wiki/ABA_routing_transit_number#Check_digit
  return (
    (3 * (numbers[0] + numbers[3] + numbers[6]) +
      7 * (numbers[1] + numbers[4] + numbers[7]) +
      1 * (numbers[2] + numbers[5] + numbers[8])) %
      10 ===
    0
  )
}

export const onSetupAutopay = (customer, history) => {
  history.push(Routes.setupScreen(customer.id))
}

export const thanksRoute = 'thanks'
