import { gql } from 'graphql.macro'
import ContractFragment from './fragments/ContractFragment.graphql'

export default gql`
  mutation UpdateContract(
    $customerId: String!
    $quote: QuoteRequest!
    $deviceFingerprint: String
  ) {
    updateContract(
      customerId: $customerId
      quote: $quote
      deviceFingerprint: $deviceFingerprint
    ) {
      ...ContractFragment
    }
  }
  ${ContractFragment}
`
