// @flow

export const flagTypesEnum = Object.freeze({
  identity: 'IDENTITY_NOT_ACCEPTED',
  ofac: 'OFAC_FLAG',
  income: 'INCOME',
  fraudReport: 'FRAUD_REPORT'
})

export type TaskTypes = $Values<typeof flagTypesEnum>

export default flagTypesEnum
