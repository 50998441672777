/* eslint-disable react/jsx-boolean-value */

// @flow
import * as React from 'react'
import { Grid, Typography, NativeSelect } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import l10n from 'properties/translations'
import { stateList } from 'util/FoundationFormHelper'
import { PhoneFormat } from '../NumberFormatHelper'

type Props = {
  handleFormState: Function,
  classes: Object,
  driversLicense: string,
  applicantDrState: string,
  email: string,
  dob: string,
  ssn: string,
  phoneNumber: number,
  applicantAlternatePhone: number
}

const AboutYou = (props: Props) => {
  const {
    handleFormState,
    classes,
    driversLicense,
    applicantDrState,
    email,
    dob,
    ssn,
    phoneNumber,
    applicantAlternatePhone
  } = props
  return (
    <>
      <Grid container spacing={0}>
        <Grid container className={classes.sectionGrid}>
          <Typography className={classes.sectionTitleTextStyle}>
            {l10n.foundation.applicationContent.aboutYouLabel}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.driverLicenseFieldStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.driverLicenseLabel}
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames.driversLicense
              }
              value={driversLicense}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .applicantDriversLicenseRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages
                  .invalidDriversLicense
              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            {l10n.foundation.applicationContent.driverLicenseState}
            <br />
            <NativeSelect
              fullWidth
              onChange={handleFormState}
              value={applicantDrState}
              name={
                l10n.foundation.applicationContent.fieldNames.applicantDrState
              }
              required={true}
              className={classes.nativeSelectDropStyle}
            >
              {stateList}
            </NativeSelect>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              disabled
              fullWidth
              label={l10n.foundation.applicationContent.applicantEmailLabel}
              onChange={handleFormState}
              name={l10n.foundation.applicationContent.fieldNames.email}
              value={email}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.dob}
              onChange={handleFormState}
              name={l10n.foundation.applicationContent.fieldNames.dob}
              value={dob}
              disabled
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.ssnLabel}
              onChange={handleFormState}
              name={l10n.foundation.applicationContent.fieldNames.ssn}
              value={ssn}
              disabled
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              disabled
              label={
                l10n.foundation.applicationContent.applicantMobileNumberLabel
              }
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .applicantMobilePhone
              }
              value={phoneNumber}
              InputProps={{ inputComponent: PhoneFormat }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={
                l10n.foundation.applicationContent.applicantAlternateNumberLabel
              }
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .applicantAlternatePhone
              }
              value={applicantAlternatePhone}
              validators={[
                l10n.foundation.applicationContent.errorMessages
                  .alternatePhoneRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.errorMessages.invalidPhone
              ]}
              InputProps={{ inputComponent: PhoneFormat }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default AboutYou
