import React from 'react'
import { Typography } from '@material-ui/core'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { deprecateVerifyAndAuthenticateFlagEnabled } from 'util/FeatureHelper'

const DeprecatedEndpointAlert = ({ ldFlags, deprecatedEndpointError }) => {
  if (deprecateVerifyAndAuthenticateFlagEnabled(ldFlags)) {
    return <Typography align="center">{deprecatedEndpointError}</Typography>
  }
  return null
}

export default withLaunchDarkly(DeprecatedEndpointAlert)
