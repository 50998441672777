import { gql } from 'graphql.macro'

export default gql`
  query getTeam {
    channelPartner {
      id
      partnerLogins {
        id
        firstName
        lastName
        emailAddress
        status
        role
      }
    }
  }
`
