// @flow
import { get } from 'lodash'
// import accessModeKeys, { type AccessMode } from 'constants/enums/accessModeKeys'
import { type EventType } from 'constants/enums/analyticsEventTypes'
// import {
//   getAppVersionString,
//   getAppVersionCommitHash,
// } from 'util/EnvironmentHelper'
// import analytics from './SegmentAnalytics'
// import { getAccessMode } from './AccessModeHelper'
// import MobileInfo from './MobileInfo'
// import StringHelper from './StringHelper'
import * as self from './AnalyticsHelper'
import { FullStory, init as FSInit } from '@fullstory/browser'

// const buildVersion: string = getAppVersionString()
// const commitHash = getAppVersionCommitHash()

// const PIIFields = [
//   'firstName',
//   'lastName',
//   'email',
//   'streetAddress',
//   'phoneNumber'
// ]

type PartnerPixelLDFlagJSON = {
  provider: string,
  accountId: string,
  event: string,
  info: any
}

// const maskPII = (properties: Object = {}, accessMode: AccessMode) => {
//   if (accessMode === accessModeKeys.demo) {
//     return properties
//   }
//   const maskedProperties = properties

//   PIIFields.forEach(key => {
//     if (Object.keys(properties).includes(key)) {
//       if (key === 'phoneNumber') {
//         maskedProperties.phoneNumber = StringHelper.rightMask(
//           StringHelper.digitsOnly(properties.phoneNumber),
//           3,
//           'x'
//         )
//       } else {
//         maskedProperties[key] = StringHelper.fullMask(properties[key], 'x')
//       }
//     }
//   })

//   return maskedProperties
// }

// const getForceSegment = () => {
//   const urlParams = new URLSearchParams(window.location.search)
//   return Boolean(urlParams.get('forceSegment')) || false
// }

const skipInit = () => {
  // if (getForceSegment()) {
  //   // eslint-disable-next-line no-console
  //   console.info('[AnalyticsHelper] enabling Segment analytics in nonprod env')
  //   return false
  // }
  return true
}

// const mobileProps = () =>
//   MobileInfo && {
//     isInstalled: MobileInfo.isStandalone(),
//     supportsTouchEvents: MobileInfo.supportsTouchEvents,
//     isMobile: MobileInfo.isMobile()
//   }

// const universallyTrackedProps = () => ({
//   build: buildVersion,
//   commitHash,
//   accessMode: getAccessMode().toLowerCase(),
//   ...(mobileProps() || {})
// })

export const init = async () => {
  if (skipInit()) {
    // eslint-disable-next-line no-console
    console.info('[AnalyticsHelper] suppressing Segment analytics')
    return
  }
}

export const identify = (_userType: string, _userId: string) => {
  try {
    // if (window.analytics.initialize) {
    //   window.analytics.identify(`${userType.toLowerCase()}-${userId}`)
    // }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export const identifyGuest = () => {
  try {
    // if (window.analytics.initialize) {
    //   window.analytics.identify('guest')
    // }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

export const track = (_event: EventType, _properties: Object) => {
  // const accessMode = getAccessMode()

  try {
    // if (window.analytics.initialize) {
    //   window.analytics.track(event, {
    //     ...maskPII(properties, accessMode),
    //     ...universallyTrackedProps()
    //   })
    // }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(error)
  }
}

const swiftlinksPartnerConversionPixelTagProviders = {
  ga: 'ga'
}

export const getSwiftlinksPartnerConversionPixel = (
  config: { [key: string]: PartnerPixelLDFlagJSON },
  hostName?: string
) => get(config, hostName)

export const swiftlinksLoadPartnerConversionPixelTagIfConfigured = (
  jsonConfig?: PartnerPixelLDFlagJSON
) => {
  if (!jsonConfig) return
  const { accountId } = jsonConfig
  if (document) {
    const body = document.getElementsByTagName('body')[0]

    const script1 = document.createElement('script')
    script1.type = 'text/javascript'
    script1.src = `https://www.googletagmanager.com/gtag/js?id=${accountId}`
    body.appendChild(script1)

    const script2 = document.createElement('script')
    script2.type = 'text/javascript'
    script2.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date()); gtag('config', '${accountId}');
    `
    body.appendChild(script2)
  }
}

export const swiftlinksTrackPartnerConversionPixelIfConfigured = (
  jsonConfig?: PartnerPixelLDFlagJSON
) => {
  if (!jsonConfig) return
  const { provider } = jsonConfig
  switch (provider) {
    case swiftlinksPartnerConversionPixelTagProviders.ga: // Google Analytics
      trackGA(jsonConfig)
      break
    default: {
      // eslint-disable-next-line no-console
      console.warn(
        '[swiftlinksTrackPartnerConversionPixelIfConfigured]',
        'appears to be misconfigured in LD'
      )
    }
  }
}

const trackGA = ({ event, info }: PartnerPixelLDFlagJSON) => {
  if (window.gtag) {
    window.gtag('event', event, info)
  } else {
    // eslint-disable-next-line no-console
    console.warn(
      '[swiftlinksLoadPartnerConversionPixelTagIfConfigured]',
      'appears to be misconfigured - cannot track'
    )
  }
}

export const initFullStory = ({ partnerName, userId }) => {
  const FULLSTORY_ORG_ID = 'o-19FG4D-na1'
  const partnerLoginId = userId

  FSInit({
    orgId: FULLSTORY_ORG_ID
  })

  FullStory.setUserVars({
    partnerName,
    partnerLoginId
  })
}

export default self
