// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { flow } from 'lodash'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'

import styles from './Admin.styles'

type Props = {
  classes: {
    adminNavItemArrow: string,
    adminArrowContainer: string
  },
  url: string,
  history: Object
}

class PanelButton extends React.PureComponent<Props> {
  updateUrl = () => {
    const { history, url } = this.props
    if (url) {
      history.push(url)
    }
  }

  render() {
    const { classes } = this.props

    return (
      <div
        data-testid="admin-arrow-container"
        className={classes.adminArrowContainer}
        onClick={this.updateUrl}
        onKeyPress={this.updateUrl}
        role="button"
        tabIndex="0"
      >
        <KeyboardArrowRight className={classes.adminNavItemArrow} />
      </div>
    )
  }
}
export default flow(withRouter, withStyles(styles))(PanelButton)
