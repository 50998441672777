import React, { useContext } from 'react'
import NumberFormat from 'react-number-format'
import cx from 'classnames'
import { makeStyles } from '@material-ui/core/styles'
import { FormHelperText, ButtonBase, Grid, Typography } from '@material-ui/core'
import TextValidator from 'components/shared/TextValidator'
import customerFields from 'constants/enums/customerFields'
import browserAutocompleteKeys from 'constants/enums/browserAutocompleteKeys'
import fieldMasks from 'constants/enums/fieldMasks'
import { PHONE_NUMBER_VALIDATORS } from 'properties/properties'
import placeholders from 'properties/placeholders'
import { blockEnterKeyCode } from 'util/ApplyHelper'
import TranslationsContext from 'util/TranslationsContext'
import PrequalificationFormContext from 'util/PrequalificationFormContext'
import styles from './AlternatePhoneNumber.styles'

const useStyles = makeStyles(styles)

const AlternatePhoneNumber = ({
  setFieldEnabled,
  showAlternatePhoneNumber,
  onFieldEdit,
  errorMessage
}) => {
  const classes = useStyles()
  const l10n = useContext(TranslationsContext)
  const { customerPending } = useContext(PrequalificationFormContext)

  return (
    <>
      {showAlternatePhoneNumber ? (
        <Grid item xs={12} sm={12} className={classes.altPhoneText}>
          <Typography
            variant="caption"
            gutterBottom
            className={cx({
              [classes.formFieldLabel]: true,
              [classes.labelText]: true,
              [classes.inactiveColor]: customerPending
            })}
          >
            {l10n.customer.phoneNumberAlternate}
          </Typography>

          <NumberFormat
            id={customerFields.phoneNumberAlt}
            mask="_"
            name={customerFields.phoneNumber}
            data-testid={customerFields.phoneNumberAlt}
            value={customerFields.phoneNumberAlt1}
            format={fieldMasks.phoneNumber}
            fullWidth
            type="tel"
            onValueChange={onFieldEdit(customerFields.phoneNumberAlt)}
            validators={PHONE_NUMBER_VALIDATORS}
            customInput={TextValidator}
            placeholder={placeholders.phoneNumber}
            autoComplete={browserAutocompleteKeys.telNational}
            errorMessages={[
              l10n.customer.phoneNumberErrorMessage,
              l10n.customer.phoneNumberMinLength
            ]}
            helperText={errorMessage}
            error={Boolean(errorMessage)}
            onKeyPress={blockEnterKeyCode}
          />
        </Grid>
      ) : (
        <FormHelperText>
          {l10n.customer.phoneNumberHelpText.prependingText}
          <ButtonBase
            data-testid="alt-phone-toggle"
            onClick={() =>
              setFieldEnabled(state => ({
                alternatePhoneNumberFieldEnabled:
                  !state.alternatePhoneNumberFieldEnabled
              }))
            }
            classes={{ root: classes.captionButton }}
            tabIndex={-1}
          >
            {l10n.customer.phoneNumberHelpText.buttonText}
          </ButtonBase>
          {l10n.customer.phoneNumberHelpText.appendingText}
        </FormHelperText>
      )}
    </>
  )
}

export default AlternatePhoneNumber
