import { gql } from 'graphql.macro'
import UserAuthModelFields from 'queries/fragments/UserAuthModelFields.graphql'

export default gql`
  mutation CreateAndAuthenticateCustomer(
    $request: GuestCreateCustomerRequest!
    $captchaToken: String
  ) {
    createAndAuthenticateCustomer(
      request: $request
      captchaToken: $captchaToken
    ) {
      ...UserAuthModelFields
    }
  }
  ${UserAuthModelFields}
`
