import React, { useEffect } from 'react'
import { Grid, Typography, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './NewExperience.styles'
import lightbulb from 'images/lightbulb.svg'
import { getSalesforceLoginURL } from 'util/SessionHelper'
import { addStyle } from 'util/NavigationHelper'

const NewExperience = ({ classes }) => {
  const salesforceLoginURL = getSalesforceLoginURL()
  const salesforceLoginURLHostname = new URL(salesforceLoginURL).hostname

  // Ensure P3X Header is not displayed for this screen
  addStyle(`
    #single-spa-application\\:\\@solarmosaic-mfe\\/header {
      display: none
    }
    #root.p3xGlobalNavEnabled {
      padding: 0
    }
  `)

  return (
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="space-around"
      className={classes.container}
    >
      <Grid item>
        <Box className={classes.icon}>
          <img alt="lightbulb" className={classes.logo} src={lightbulb} />
        </Box>
        <Typography variant="h1" align="center" className={classes.headline}>
          Welcome to the new Mosaic experience
        </Typography>
        <Typography align="center" className={classes.subHeadline}>
          Sign in with your existing email and password
        </Typography>
        <Typography align="center" className={classes.instruction}>
          If you haven’t created a new password yet, you’ll be sent an email to
          create one.
        </Typography>
        <Typography align="center" className={classes.instruction}>
          Create your new password and sign in.
        </Typography>
        <Typography align="center" className={classes.bookmarkReminder}>
          Be sure to bookmark the new login URL{' '}
          <a href={salesforceLoginURL} className={classes.newLoginUrl}>
            {salesforceLoginURLHostname}
          </a>
        </Typography>
        <Box className={classes.buttonContainer}>
          <Typography align="center">
            <a href={salesforceLoginURL} className={classes.link}>
              Get Started
            </a>
          </Typography>
        </Box>
      </Grid>
    </Grid>
  )
}

export default NewExperience

const UnwrappedNewExperience = ({ classes, overrideHeader }) => {
  useEffect(() => {
    overrideHeader({ hidden: true })
  }, [overrideHeader])
  return <NewExperience classes={classes} />
}

export const NewExperienceScreen = withStyles(styles)(UnwrappedNewExperience)
