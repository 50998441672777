import backendEnvironments from './enums/backendEnvironments'

const { demo, e2e, local, production, qa } = backendEnvironments

export default {
  'localhost:3000': local,

  'main-testing.ecs.ui.mosaic-point-of-sale.com': qa,
  'main-e2e.ecs.ui.mosaic-point-of-sale.com': e2e,
  'main.ecs.ui.demo.mosaic-point-of-sale.com': demo,
  'main.ecs.ui.mosaic-point-of-sale.com': production,

  'tap.qa.joinmosaic.com': qa,
  'tap.e2e.joinmosaic.com': e2e,
  'demo.tap.joinmosaic.com': demo,
  'tap.joinmosaic.com': production,

  'apply.qa.joinmosaic.com': qa,
  'apply.e2e.joinmosaic.com': e2e,
  'demo.apply.joinmosaic.com': demo,
  'apply.joinmosaic.com': production
}

export const development = 'development'
