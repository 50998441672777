// @flow
import React, { useEffect, useState, cloneElement } from 'react'
import { Grid, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import SwipeableViews from 'react-swipeable-views'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import Loading from 'components/shared/Loading'
import mosaicLogo from 'images/mosaic-logo.svg'
import l10n from 'properties/translations'
import MobileInfo from 'util/MobileInfo'
import Stepper from './Stepper'
import { Step0, Step1, Step2, Step3 } from './content'
import styles from './OnboardingStyles'
import GetChannelPartnerContacts from './queries/GetChannelPartnerContacts.graphql'

const DEFAULT_STEPS = [
  {
    component: <Step0 />,
    title: l10n.onboardingSteps.step0.title,
    backgroundColor: 'rgb(247, 204, 132)'
  },
  {
    component: <Step1 />,
    title: l10n.onboardingSteps.step1.title,
    backgroundColor: 'rgb(106, 204, 201)'
  },
  {
    component: <Step2 />,
    title: l10n.onboardingSteps.step2.title,
    backgroundColor: 'rgb(240, 159, 117)'
  },
  {
    component: <Step3 />,
    title: l10n.onboardingSteps.step3.title,
    backgroundColor: 'rgb(83, 143, 149)'
  }
]

type Props = {
  classes: Object,
  client: Object
}

const renderSteps = (steps, classes) =>
  steps.map(({ title, component }) => (
    <React.Fragment key={`${title}`}>
      <Typography variant="h1" className={classes.title}>
        {title}
      </Typography>
      {component}
    </React.Fragment>
  ))

const { isMobile } = MobileInfo.getMobileInfo()

// Clean up default steps based on isMobile and contact information availabilty
const configSteps = contacts => {
  let updatedSteps = [...DEFAULT_STEPS]
  // Remove 3rd step that contains contact information if no contacts exists
  if (contacts === null) {
    updatedSteps = updatedSteps.filter(
      s => s.title !== l10n.onboardingSteps.step2.title
    )
  } else {
    // If contacts exist, clone component and add contact props to component
    const STEP_2 = 2
    updatedSteps[STEP_2].component = cloneElement(
      updatedSteps[STEP_2].component,
      {
        contacts
      }
    )
  }
  // Remove last element from default if not on a mobile device
  if (isMobile === null) {
    updatedSteps.pop()
  }
  return updatedSteps
}

const Onboarding = ({ classes, client }: Props) => {
  const [activeStep, setActiveStep] = useState(0)
  const [contacts, setContacts] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const queryContacts = async () => {
      const {
        data: { partnerContacts }
      } = await client.query({
        query: GetChannelPartnerContacts
      })
      setLoading(false)
      if (partnerContacts === null) return
      setContacts(partnerContacts)
    }
    queryContacts()
  }, [contacts, client])

  const steps = configSteps(contacts)
  if (loading) return <Loading />

  return (
    <Grid
      data-testid="onboarding"
      container
      direction="column"
      wrap="nowrap"
      className={classes.container}
      style={{
        backgroundColor: steps[activeStep].backgroundColor
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        justify="center"
        className={classes.headerContainer}
      >
        <Grid
          item
          container
          alignItems="center"
          justify="center"
          className={classes.logoContainer}
        >
          <img alt={l10n.onboardingSteps.logoAlt} src={mosaicLogo} />
        </Grid>
        <Button className={classes.skipLinkContainer}>
          <Link
            to={{
              pathname: '/',
              state: {
                fromOnboarding: true
              }
            }}
          >
            {activeStep === steps.length - 1 ? 'Done' : 'Skip'}
          </Link>
        </Button>
      </Grid>
      <Grid item className={classes.swipeableContainer}>
        <SwipeableViews
          index={activeStep}
          onChangeIndex={index => setActiveStep(index)}
          className={classes.swipeable}
        >
          {renderSteps(steps, classes)}
        </SwipeableViews>
      </Grid>
      <Stepper
        maxSteps={steps.length}
        activeStep={activeStep}
        handleBack={() => setActiveStep(activeStep - 1)}
        handleNext={() => setActiveStep(activeStep + 1)}
      />
    </Grid>
  )
}

export default withApolloConsumer(withStyles(styles)(Onboarding))
