// @flow
import { createContext } from 'react'
import { memoize } from 'lodash'
import pageVariants, { type PageVariants } from 'constants/enums/pageVariants'
export type PageLayoutContextType = {
  menu: boolean,
  logo: boolean,
  stageNav: boolean
}

export const pageLayoutContextValue = memoize(
  (variant: PageVariants): PageLayoutContextType => {
    switch (variant) {
      case pageVariants.withLogo:
        return { menu: false, logo: true, stageNav: false }
      case pageVariants.withoutLogo:
        return { menu: false, logo: false, stageNav: false }
      case pageVariants.narrowLogo:
        return { menu: false, logo: true, stageNav: false }
      case pageVariants.stageNav:
        return { menu: false, logo: false, stageNav: false }
      case pageVariants.stageNavBack:
        return { menu: false, logo: false, stageNav: false }
      case pageVariants.logoRight:
        return { menu: false, logo: true, stageNav: false }
      default:
        return { menu: false, logo: false, stageNav: false }
    }
  }
)

const PageLayoutContext: PageLayoutContextType = createContext(
  pageLayoutContextValue(pageVariants.withLogo)
)

export default PageLayoutContext
