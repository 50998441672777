// @flow

import { Button, CircularProgress, Grid, Typography } from '@material-ui/core'
import React from 'react'
import l10n from 'properties/translations'

type ChildProps = {
  circularProgressSize: number,
  disabled: boolean,
  submitting: boolean,
  onClick: () => {}
}

const renderChildren = (props: ChildProps) => {
  const { circularProgressSize, disabled, submitting, onClick } = props
  return (
    <Button
      variant="contained"
      type="submit"
      color="primary"
      disabled={disabled || submitting}
      size="large"
      onClick={onClick}
    >
      {submitting ? (
        <CircularProgress
          size={circularProgressSize}
          data-testid="circular-progress"
        />
      ) : (
        <Typography>{l10n.foundation.submit}</Typography>
      )}
    </Button>
  )
}

type Props = {
  circularProgressSize: number,
  disabled: boolean,
  submitting: boolean,
  useGridComponent?: boolean,
  onSubmit: () => {},
  customerId: string
}

const Submit = (props: Props) => {
  const {
    circularProgressSize,
    classes,
    disabled,
    submitting,
    useGridComponent,
    onSubmit,
    customerId
  } = props

  const childProps = {
    circularProgressSize,
    classes,
    disabled,
    submitting,
    onClick: onSubmit,
    customerId
  }

  if (useGridComponent) {
    return <Grid item>{renderChildren(childProps)}</Grid>
  }
  return <div>{renderChildren(childProps)}</div>
}

Submit.defaultProps = {
  useGridComponent: true
}

export default Submit
