// @flow

const demoLoanProductKeys = Object.freeze({
  bank: 'BANK DEMO',
  lender: 'LENDER DEMO'
})

export type DemoLoanProduct = $Values<typeof demoLoanProductKeys>

export default demoLoanProductKeys
