import React from 'react'
import noop from 'lodash/noop'

const SlidingPaperStateContext = React.createContext()
const SlidingPaperDispatchContext = React.createContext()

function SlidingPaperStateProvider({ children }) {
  const [shouldClose, setState] = React.useState(false)
  return (
    <SlidingPaperStateContext.Provider value={{ shouldClose }}>
      <SlidingPaperDispatchContext.Provider value={setState}>
        {children}
      </SlidingPaperDispatchContext.Provider>
    </SlidingPaperStateContext.Provider>
  )
}

function useSlidingPaperDispatch() {
  const context = React.useContext(SlidingPaperDispatchContext)
  if (context === undefined) {
    return noop
  }
  return context
}

export {
  SlidingPaperStateProvider,
  useSlidingPaperDispatch,
  SlidingPaperDispatchContext,
  SlidingPaperStateContext
}
