// @flow

export const eventTypes = Object.freeze({
  borrowerUserOpenedShareLink: 'BorrowerUser OpenedShareLink',
  borrowerUserShareLinkRedirect: 'BorrowerUser ShareLinkRedirect',
  connectionOffline: 'Connection TransitionedOffline',
  connectionOnline: 'Connection TransitionedOnline',
  creditAppSubmitted: 'CreditApp Submitted',
  customerCreated: 'Customer Created',
  customerUpdated: 'Customer Updated',
  demoACHSignupBannerClicked: 'DemoACHSignupBanner Clicked',
  demoEsignVerifyBannerClicked: 'DemoEsignVerifyBanner Clicked',
  demoSignLoanDocsBannerClicked: 'DemoSignLoanDocsBanner Clicked',
  demoWelcomeBannerClicked: 'DemoWelcomeBanner Clicked',
  deviceVerificationStarted: 'DeviceVerification Started',
  duplicateSessionLoggedOut: 'DuplicateSession LoggedOut',
  editOfferSaved: 'EditOffer Saved',
  emailVerificationSent: 'EmailVerification Sent',
  estimateCreated: 'Estimate Created',
  estimateUpdated: 'Estimate Updated',
  expiredSessionLoggedOut: 'ExpiredSession LoggedOut',
  installerUserLoggedIn: 'InstallerUser LoggedIn',
  loanAgreementCreated: 'LoanAgreement Created',
  loanAgreementSigned: 'LoanAgreement Signed',
  loanAgreementViewed: 'LoanAgreement Viewed',
  refreshVersionClicked: 'RefreshVersion Clicked',
  shareEmail: 'ShareEmail Sent',
  shareSms: 'ShareSms Sent',
  sideBarAdmin: 'SideBarAdmin Clicked',
  sideBarInstallThisApp: 'SideBarInstallThisApp Clicked',
  sideBarLogout: 'SideBarLogout Clicked',
  sideBarPipeline: 'SideBarPipeline Clicked',
  sideBarStart: 'SideBarStart Clicked',
  startPageGiveAnEstimate: 'StartPageGiveAnEstimate Clicked',
  startPageNewCustomer: 'StartPageNewCustomer Clicked',
  startPagePreQualNow: 'StartPagePreQualNow Clicked',
  setupACHCompleted: 'ACH Completed',
  setupACHSkipped: 'ACH Skipped',
  setupProjectMarkedComplete: 'Project MarkedComplete',
  userRefreshedAccessToken: 'User RefreshedAccessToken',
  unsupportedBrowserNotified: 'UnsupportedBrowser Notified',
  prequalificationUserLoggedIn: 'PrequalificationUser LoggedIn',
  helpClicked: 'SidebarHelp Clicked',
  prequalificationAgreeAndContinueClicked:
    'Prequalification AgreeAndContinue Clicked',
  prequalificationUserInIFrame: 'PrequalificationUser iFramed',
  // Prequalification button was clicked - address not yet confirmed
  prequalificationSubmitClicked: 'Prequalification Submit Clicked',
  // Application actually created
  prequalificationSubmitApplication: 'Prequalification Submit Application',
  // User clicked Continue when confirming info (name, address, email, etc.)
  prequalificationConfirmContinueClicked:
    'PrequalificationConfirm Continue Clicked',
  // User clicked Back to avoid continuing after info confirm modal was visible
  prequalificationConfirmBackClicked: 'PrequalificationConfirm Back Clicked',
  // Swiftlinks page load
  prequalificationLoad: 'Prequalification Swiftlinks Load',
  helpButtonClicked: 'GlobalHelpButton Clicked',
  leasePipeline: 'Lease View PipelineScreen',
  leaseDashboard: 'Lease View DashboardScreen',
  leaseOverviewOfTerms: 'Lease View OverviewOfTerms',
  leaseCreateEstimateForm: 'Lease View CreateEstimateForm',
  leaseEditEstimateForm: 'Lease Open EditEstimateForm',
  leaseCreditApplicationSubmit: 'Lease Submit CreditApplication',
  leaseEstimateHandoff: 'Lease View EstimateHandoff',
  leaseCertifyProjectCompletion: 'Lease Certify ProjectCompletion',
  leaseSignAgreement: 'Lease Sign Agreement',
  leaseCustomerAuth: 'Lease Authenticate Customer',
  overviewOfTerms: 'Overview Of Terms Viewed'
})

export type EventType = $Values<typeof eventTypes>

export default eventTypes
