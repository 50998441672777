export default theme => ({
  container: {
    maxWidth: 340,
    paddingBottom: theme.spacing(4)
  },
  linkText: {
    color: theme.palette.action.textLinkColor,
    textDecoration: 'none'
  }
})
