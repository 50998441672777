// @flow

export const userTypesEnum = Object.freeze({
  borrower: 'BORROWER',
  installer: 'INSTALLER',
  integrator: 'INTEGRATOR',
  customer: 'CUSTOMER'
})

export type UserTypes = $Values<typeof userTypesEnum>

export default userTypesEnum
