import { gql } from 'graphql.macro'

export default gql`
query getEsignUrl($id: String!, $eSignCallbackUrl: URL!, $signatoryRole: SignatoryRoles!) {
  customer(customerId: $id) {
    id
    contract {
      signator(signatoryRole: $signatoryRole) {
        signator
        eSignUrl(callbackUrl: $eSignCallbackUrl)
      }
    }
  }
}
`
