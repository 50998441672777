export default {
  phoneNumber: '(000) 000-0000',
  email: 'youremail@example.com',
  emailSpanish: 'email@ejemplo.com',
  ssn: '000000000',
  last4Ssn: '0000',
  verifySsn: '000000000',
  emptySpaces: '--',
  dobPlaceholder: '**/**/**',
  ssnPlaceHolder: '***-**-****',
  dobFull: 'MM/DD/YYYY',
  dobFullSpanish: 'MM/DD/AAAA'
}
