// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import Button from 'components/shared/Button'
import l10n from 'properties/translations'
import styles from '../Admin.styles'

type BankAccount = {
  routingNumber: string,
  accountNumber: string,
  currentAccountNumber?: string,
  accountType: string,
  bankName: string,
  address: string
}

type Props = {
  handleEdit: () => void,
  bankAccount: BankAccount,
  classes: object
}

const BankAccountDisplay = (props: Props) => {
  const { handleEdit, bankAccount, classes } = props
  return (
    <Formik initialValues={{ bankAccount }}>
      {({ values }) => (
        <Grid item>
          <Form>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              spacing={3}
            >
              <Grid item>
                <Typography
                  variant="h1"
                  data-testid="admin-bank-information-title"
                  className={classes.pageTitle}
                >
                  {l10n.admin.bankInformation.title}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  data-testid="admin-bank-information-description"
                  className={classes.bodyTextNormal}
                >
                  {values.bankAccount.status === 'invalid'
                    ? l10n.admin.bankInformation.descriptionInvalid
                    : l10n.admin.bankInformation.description}
                </Typography>
              </Grid>
              <Grid item>
                <Field
                  type="text"
                  name="bankAccount.routingNumber"
                  data-testid="bank-account-display-routing-number"
                  label={l10n.admin.bankInformation.routingNumber}
                  component={TextField}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  type="text"
                  name="bankAccount.accountNumber"
                  data-testid="bank-account-display-account-number"
                  label={l10n.admin.bankInformation.accountNumber}
                  component={TextField}
                  fullWidth
                  disabled
                />
              </Grid>
              <Grid item>
                <Field
                  type="text"
                  name="bankAccount.accountType"
                  data-testid="bank-account-display-account-type"
                  label={l10n.setup.confirmation.bankFields.accountType}
                  component={TextField}
                  fullWidth
                  disabled
                />
              </Grid>
            </Grid>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  data-testid="bank-account-display-edit"
                  onClick={() => {
                    handleEdit()
                  }}
                >
                  <Typography>
                    {l10n.admin.bankInformation.editButton}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </Form>
        </Grid>
      )}
    </Formik>
  )
}

export default withStyles(styles)(BankAccountDisplay)
