// @flow
import React from 'react'
import get from 'lodash/get'
import basePaths from 'constants/enums/basePaths'
import contractStatuses from 'constants/enums/contractStatuses'
import { AGGREGATOR_CODE, EMPTY_STRING } from 'properties/properties'
import { Redirect } from 'react-router-dom'
import { isBorrower } from 'util/CustomerHelper'
import { isEmpty } from 'lodash'
import { dashboardHubAndSpokeFlagEnabled } from './FeatureHelper'

const {
  dashboard,
  admin,
  accept,
  apply,
  customer,
  setup,
  expired,
  login,
  logout,
  pipeline,
  quote,
  estimate,
  start,
  settings,
  contract,
  returnFromContract,
  returnFromContractGuest,
  onboarding,
  loanAgreement,
  creditDecision,
  swiftlink,
  products,
  autopay,
  thanks,
  team,
  members,
  partnerAccount,
  projectTypes,
  bankAccount,
  weblinksPartnerPath,
  resources,
  verify,
  verifyCoApp,
  verifyIdentity,
  uploadPersonalDocuments,
  documentResolution,
  offer,
  sharePendingState,
  signing,
  application,
  newExperience
} = basePaths

const genericRoute = (...args: Array<string>): string => `/${args.join('/')}`

const addOptionalSection = (section: ?string): string =>
  section ? `/${section}` : ''

const sourceURIComponent = (sourceRoute: string): string =>
  encodeURIComponent(sourceRoute)

const aggregatorCodeParams = aggregatorCode => {
  if (!aggregatorCode) return EMPTY_STRING
  const searchParams = new URLSearchParams()
  searchParams.set(AGGREGATOR_CODE, aggregatorCode)
  return `?${searchParams.toString()}`
}

const routes = {
  admin: (): string => admin,
  team: (): string => `${admin}${team}`,
  projectTypes: (): string => `${admin}${projectTypes}`,
  partnerAccount: (): string => `${admin}${partnerAccount}`,
  teamMember: (id: ?string) =>
    id ? `${admin}${team}${members}/${id}` : `${admin}${team}${members}`,
  bankAccount: () => `${admin}${bankAccount}`,
  accept: (id: string): string => (id ? `${accept}/${id}` : accept),
  apply: (id: ?string, section: ?string = null): string =>
    id ? `${apply}/${id}${addOptionalSection(section)}` : apply,
  customer: (id: string): string => (id ? `${customer}/${id}` : customer),
  setupScreen: (id: string): string => `${setup}/${id}${autopay}`,
  setupConfirmation: (id: string): string => `${setup}/${id}`,
  setupThanks: (id: string): string => `${setup}/${id}${thanks}`,
  sharePendingState: (id: string): string => `${sharePendingState}/${id}`,
  expired: (): string => expired,
  login: (): string => login,
  logout: (): string => logout,
  signing: (id: string): string => `${signing}/${id}`,
  pendingState(
    customerToQuery = {},
    me = {},
    ldFlags = {},
    dashboardLink = false
  ): string {
    const id = get(customerToQuery, 'id')

    if (!id) return this.start()

    const supressId = isBorrower(me)
    const routeArgument = supressId ? null : id

    if (
      dashboardLink &&
      !isEmpty(ldFlags) &&
      dashboardHubAndSpokeFlagEnabled(ldFlags)
    ) {
      return this.dashboard(routeArgument)
    }

    const contractSigned =
      get(customerToQuery, 'contract.status') === contractStatuses.signed &&
      get(customerToQuery, 'dateESignVerified')

    if (contractSigned) {
      return this.setup(id)
    }

    return this.apply(id)
  },
  pipeline: (): string => pipeline,
  pipelineWithSearch: (
    searchText: string,
    queryLimit: string,
    queryOffset: string
  ): string =>
    `${pipeline}?query=${searchText}&limit=${queryLimit}&offset=${queryOffset}`,
  quote: (id: string): string => (id ? `${quote}/${id}` : quote),
  setup: genericRoute.bind(null, 'setup'),
  start: (): string => start,
  settings: (): string => settings,
  contract: (
    id: string,
    sourceRoute: string,
    signatorRole: string = ''
  ): string =>
    `${contract}/${id}/${sourceURIComponent(sourceRoute)}/${signatorRole}`,
  returnFromContract: (
    id: string,
    sourceRoute: string,
    signatorRole: string = ''
  ): string =>
    `${returnFromContract}/${id}/${sourceURIComponent(
      sourceRoute
    )}/${signatorRole}`,
  returnFromContractGuest: (id: string, signatorRole: string = ''): string =>
    `${returnFromContractGuest}/${id}/${signatorRole}`,
  buildAbsoluteUrl: (pathname: string): string =>
    `${window.location.protocol}//${window.location.host}${pathname}`,
  products: () => `${admin}${products}`,
  onboarding: () => onboarding,
  loanAgreement: (customerId: string) => `${loanAgreement}/${customerId}`,
  creditDecision: (customerId: string) => `${creditDecision}/${customerId}`,
  dashboard: (customerId: string) =>
    customerId ? `${dashboard}/${customerId}` : dashboard,
  dashboardProjectDetail: (customerId: string, projectId: string) =>
    customerId && projectId
      ? `${dashboard}/${customerId}/${projectId}`
      : dashboard,
  swiftlink: () => `${admin}${swiftlink}`,
  weblinksUrl: (partnerCode, aggregatorCode) =>
    `${weblinksPartnerPath}${partnerCode}${aggregatorCodeParams(
      aggregatorCode
    )}`,
  resetPassword: (resetCode: string) =>
    `${basePaths.resetPassword}/${resetCode}`,
  resources: (): string => resources,
  swiftlinkResources: () => `${resources}${swiftlink}`,
  customerBlocked: () => '/not-found',
  confirmStage: (customerId: string, projectId: string): string =>
    `${setup}/${customerId}/${projectId}`,
  verify: (customerId: string) => `${verify}/${customerId}`,
  verifyCoApp: (customerId: string) => `${verifyCoApp}/${customerId}`,
  verifyIdentity: (customerId: string) => `${verifyIdentity}/${customerId}`,
  uploadPersonalDocuments: (customerId: string) =>
    `${uploadPersonalDocuments}/${customerId}`,
  documentResolution: (customerId: string) =>
    `${documentResolution}/${customerId}`,
  application: (applicationId: string) => `${application}/${applicationId}`,
  newExperience: (): string => newExperience
}

export default routes

export const errorFallbackRoute = routes.pipeline

export const handleBackButtonPressed = (history: History) => () =>
  history.goBack()

export const redirectToLoginWithAttemptedLink = (pathname: string) => (
  <Redirect
    to={{ pathname: routes.login(), state: { attemptedLink: pathname } }}
  />
)
