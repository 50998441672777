import React from 'react'
import createTheme, { colors } from 'themes/default'

const ThemeContext = React.createContext({
  themeOverrideKey: 'default',
  setThemeOverrideKey: () => {},
  getTheme: () => createTheme(colors)
})

export default ThemeContext
