import { gql } from 'graphql.macro'

export default gql`
  fragment ApplicantResponseFields on ApplicantResponse {
    id
    phoneNumber
    firstName
    middleName
    lastName
    email
    address {
      street
      city
      stateAbbreviation
      zip
    }
    dateElectronicAccessVerified
  }
`
