// flow
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'

import isEmpty from 'lodash/isEmpty'
import {
  Dialog,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
  IconButton,
  Typography,
  Button,
  Box
} from '@material-ui/core'
import cx from 'classnames'
import CloseIcon from '@material-ui/icons/Close'
import { colors } from 'themes/default'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { type CustomerShape } from 'screens/customer/shapes/customer'
import ContactBox from './ContactBox'
import styles from './ContactCard.styles'
import defaultTranslations, {
  localizedTranslations
} from 'properties/translations'
import TranslationsContext from 'util/TranslationsContext'
import { customerLanguageKey } from 'util/CustomerHelper'
import { coApplicantFormEnabled } from 'util/FeatureHelper'
import { ActiveProductContext } from 'util/ActiveProductContext'
import * as AnalyticsHelper from 'util/AnalyticsHelper'
import {
  getContactCardData,
  getGeneralSupport,
  getUnderwritingSupport,
  getWelcomeSupport,
  getLoanPaymentSupport,
  getSupportPhoneNumber,
  getSupportEmail
} from 'util/ContactCardHelper'
import { eventTypes } from 'constants/enums/analyticsEventTypes'

const supportEmailStyle = {
  color: colors.primary,
  margin: 0,
  textTransform: 'lowercase',
  fontWeight: 700,
  minHeight: '15px',
  minWidth: '220px',
  padding: '3px',
  fontSize: '14px',
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",Helvetica,Arial,sans-serif'
}

const DialogTitle = withStyles(styles)(props => {
  const { children, classes, onClose } = props
  return (
    <MuiDialogTitle disableTypography className={classes.root}>
      <Typography variant="h2">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="Close"
          data-testid="contact-card-close-btn"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}))(MuiDialogContent)

interface Props {
  emailText: string;
  financingType: string;
  responseText: string;
  cardData?: object;
  customer: CustomerShape;
  open?: boolean;
  customerIsBorrower: boolean;
  isOkayToCancelFlag: boolean;
  blockedByManualReview: boolean;
  contractStatusFlag: string;
  creditApplicationStatus: string;
  opportunityStatus: string;
}
class ContactCard extends Component<Props> {
  static defaultProps = {
    cardData: defaultTranslations.contactCard.DefaultSupport,
    open: false
  }

  static contextType = ActiveProductContext

  state = {
    open: false
  }

  handleClickOpen = () => {
    AnalyticsHelper.track(eventTypes.helpButtonClicked)
    this.setState({
      open: true
    })
  }

  handleClose = e => {
    this.setState({ open: false })
    e.stopPropagation()
    const { onClose } = this.props
    if (onClose) onClose()
  }

  redirectToMailApp = (e, supportEmail) => {
    e.preventDefault()
    window.open(`mailto:${supportEmail}`)
    return false
  }

  componentDidUpdate(prevProps) {
    if (prevProps.open && !this.props.open) {
      this.setState({ open: false })
      return
    }
    if (!prevProps.open && this.props.open) {
      this.setState({ open: true })
      return
    }
  }

  render() {
    const {
      ldFlags,
      classes,
      customer,
      customerIsBorrower,
      isOkayToCancelFlag,
      blockedByManualReview,
      contractStatusFlag,
      creditApplicationStatus,
      opportunityStatus,
      financingType
    } = this.props
    const { activeProductValue } = this.context

    const l10n = localizedTranslations(customerLanguageKey(customer))
    const emailText = l10n.contactCard.DefaultSupport.emailText
    const responseText = l10n.contactCard.DefaultSupport.responseText
    const supportPhoneNumber = getSupportPhoneNumber({
      financingType,
      activeProductValue,
      customer
    })
    const supportEmail = getSupportEmail({
      financingType,
      activeProductValue,
      customer
    })

    const cardData = getContactCardData(
      customerIsBorrower,
      isOkayToCancelFlag,
      blockedByManualReview,
      contractStatusFlag,
      creditApplicationStatus,
      opportunityStatus,
      customer
    )

    const showExtendedContent = !coApplicantFormEnabled(ldFlags, { customer })
    const generalSupport = getGeneralSupport(cardData)
    const underwritingSupport = getUnderwritingSupport(cardData)
    const welcomeSupport = getWelcomeSupport(cardData)
    const loanPaymentSupport = getLoanPaymentSupport(cardData)

    return (
      <Dialog
        onClose={this.handleClose}
        onBackdropClick={this.handleClose}
        aria-labelledby="customized-dialog-title"
        open={this.state.open}
        classes={{
          paperScrollPaper: classes.dialog
        }}
      >
        <DialogTitle id="customized-dialog-title" onClose={this.handleClose}>
          &nbsp;
        </DialogTitle>
        <DialogContent>
          <TranslationsContext.Provider value={l10n}>
            {generalSupport && !isEmpty(generalSupport) ? (
              <ContactBox
                supportObj={generalSupport}
                supportPhoneNumber={supportPhoneNumber}
              />
            ) : (
              <ContactBox
                supportObj={l10n.contactCard.DefaultSupport}
                supportPhoneNumber={supportPhoneNumber}
              />
            )}

            {showExtendedContent && (
              <>
                {underwritingSupport && !isEmpty(underwritingSupport) && (
                  <ContactBox
                    underwritingClass={classes.underwritingSupportSection}
                    supportObj={underwritingSupport}
                  />
                )}

                {welcomeSupport && !isEmpty(welcomeSupport) && (
                  <ContactBox
                    welcomeClass={classes.welcomeSupportSection}
                    supportObj={welcomeSupport}
                  />
                )}

                {loanPaymentSupport && !isEmpty(loanPaymentSupport) && (
                  <ContactBox
                    loanClass={classes.loanPaymentSupportSection}
                    supportObj={loanPaymentSupport}
                  />
                )}
              </>
            )}

            <Typography
              align="center"
              variant="body2"
              data-testid="contact-card-email-text"
              className={cx(classes.contactCardEmail, classes.bodyTextNormal)}
            >
              {emailText}
            </Typography>
            <Box style={{ display: 'flex', justifyContent: 'center' }}>
              <Button
                align="center"
                data-testid="contact-card-email"
                className={classes.contactCardEmailLink}
                onClick={e => {
                  this.redirectToMailApp(e, supportEmail)
                }}
                style={supportEmailStyle}
              >
                {supportEmail}
              </Button>
            </Box>
            <Typography
              variant="caption"
              align="center"
              data-testid="contact-card-response-text"
              className={classes.contactCardResponseMessage}
              paragraph
            >
              {responseText}
            </Typography>
          </TranslationsContext.Provider>
        </DialogContent>
      </Dialog>
    )
  }
}

export default withStyles(styles)(withLaunchDarkly(ContactCard))
