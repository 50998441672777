// @flow
import ReactSessionApi from 'react-session-api'
import { ALLOWED_ORIGIN_ALL } from 'properties/properties'
import localStorageKeys from 'constants/enums/localStorageKeys'

type Message = {
  name: string
}

export const postMessageToParentWindow = (
  message: Message,
  allowedOrigin: string = ALLOWED_ORIGIN_ALL
): void => {
  if (window.top) {
    window.top.postMessage(message, allowedOrigin)
  }
}

export const storeIframeConfiguration = (search: string) => {
  ReactSessionApi.set(localStorageKeys.iframeConfiguration, search)
}

export const getStoredIframeConfiguration = () => {
  const string = ReactSessionApi.get(localStorageKeys.iframeConfiguration)
  try {
    return new URLSearchParams(string)
  } catch {
    return {}
  }
}