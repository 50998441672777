import coreScreenStyles from 'themes/coreScreenStyles'

export default theme => {
  const coreStyles = coreScreenStyles(theme)
  return {
    stageText: {
      textAlign: 'center',
      width: '100%',
      ...theme.typography.caption
    },
    stageLine: {
      borderBottom: `1px solid ${theme.palette.grey['300']}`,
      width: '100%',
      position: 'relative',
      top: '27px'
    },
    stageLineFirst: {
      width: '50%',
      marginLeft: '50%'
    },
    stageLineLast: {
      width: '50%',
      marginright: '50%'
    },
    container: {
      margin: 'auto',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      maxWidth: 480
    },
    subheadingLight: coreStyles.subheadingLight,
    headerReplacement: coreStyles.headerReplacement
  }
}
