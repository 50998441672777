const MuiTypography = (themeColors, theme) => ({
  h1: {
    fontSize: '1.5rem',
    fontWeight: 300,
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.15rem'
    },
    [theme.breakpoints.down('md')]: {
      fontSize: '1.35rem'
    }
  },
  h2: {
    fontSize: '1.3125rem',
    fontWeight: 500
  },
  subtitle1: {
    fontSize: '1rem',
    fontWeight: 400
  },
  body1: {
    fontSize: '0.875rem',
    fontWeight: 700
  },
  body2: {
    fontSize: '0.875rem',
    fontWeight: 400
  },
  caption: {
    fontSize: '0.75rem',
    fontWeight: 300
  }
})

export default MuiTypography
