import * as Yup from 'yup'
import l10n from 'properties/translations'
import { EMPTY_STRING } from 'properties/properties'

const { admin } = l10n

const hasPhoneNumberInvalidMinLength = value => value.length < 10
const hasPhoneNumberInvalidMaxLength = value => value.length > 10

const getPhoneNumberError = (createError: Function) => (message: string) =>
  createError({
    message
  })

const MembershipSchema = Yup.object().shape({
  members: Yup.array().of(
    Yup.object().shape({
      firstName: Yup.string()
        .min(2, admin.errors.tooShort)
        .max(50, admin.errors.tooLong)
        .required(admin.errors.required),
      lastName: Yup.string()
        .min(2, admin.errors.tooShort)
        .max(50, admin.errors.tooLong)
        .required(admin.errors.required),
      phoneNumber: Yup.string()
        .default(EMPTY_STRING)
        .nullable()
        .test({
          name: 'phone-number-valid',
          test: function validatePhoneNumber(value) {
            if (!value) {
              return true
            }
            const getPhoneNumberErrorObject = getPhoneNumberError(
              this.createError
            )
            if (hasPhoneNumberInvalidMinLength(value)) {
              return getPhoneNumberErrorObject(admin.errors.minPhoneNumber)
            }
            if (hasPhoneNumberInvalidMaxLength(value)) {
              return getPhoneNumberErrorObject(admin.errors.maxPhoneNumber)
            }
            return true
          }
        }),
      emailAddress: Yup.string()
        .email(admin.errors.invalidEmail)
        .required(admin.errors.required),
      role: Yup.string().required(admin.errors.required)
    })
  )
})

export default MembershipSchema
