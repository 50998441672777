// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import {
  Formik,
  Form,
  Field,
  type FormikActions,
  type FormikValues
} from 'formik'
import * as Yup from 'yup'
import { TextField } from 'formik-material-ui'
import { Redirect } from 'react-router-dom'
import { Query } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import Button from 'components/shared/Button'
import Page from 'components/templates/Page'
import l10n from 'properties/translations'
import { minimumLength } from 'properties/properties'
import IdentityQuery from 'queries/GetPasswordResetIdentity.graphql'
import Error from 'components/shared/Error'
import Loading from 'components/shared/Loading'
import { storeUserTokens } from 'util/SessionHelper'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import accessModeKeys from 'constants/enums/accessModeKeys'
import withUnstatedContainer from 'components/shared/UnstatedContainerHOC'
import OnLoginSuccess from 'screens/login/LoginEntry/components/OnLoginSuccess'
import { getAccessMode } from 'util/AccessModeHelper'
import Routes from 'util/Routes'
import AccessModeContainer, {
  type IAccessModeContainer
} from 'store/AccessModeContainer'
import GetUserRolesDoNotCache from 'queries/GetUserRolesDoNotCache.graphql'
import ResetPassword from '../queries/ResetPassword.graphql'

import styles from '../Admin.styles'

const equalTo = (ref: any, msg: any) =>
  Yup.mixed().test({
    name: 'equalTo',
    exclusive: false,
    // eslint-disable-next-line no-template-curly-in-string
    message: msg || '${path} must be the same as ${reference}',
    params: {
      reference: ref.path
    },
    test(value: any) {
      return value === this.resolve(ref)
    }
  })
Yup.addMethod(Yup.string, 'equalTo', equalTo)

type PasswordReset = {
  email: string,
  password: string,
  verifyPassword: string
}

type Props = {
  match: {
    isExact: boolean,
    params: {
      code: string
    },
    path: string,
    url: string
  },
  accessModeContainer: IAccessModeContainer,
  client: any
}

type State = {
  isLoading: boolean,
  showError: boolean,
  loginInfo?: {
    userRoles: string[],
    accessModes?: string[]
  }
}

class PasswordResetForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      showError: false,
      loginInfo: undefined
    }
  }

  reset = (password, code) =>
    this.props.client.mutate({
      mutation: ResetPassword,
      variables: {
        password,
        resetCode: code
      }
    })

  handleSubmit = async (
    decodedResetCode: string,
    values: FormikValues,
    actions: FormikActions
  ) => {
    const { client } = this.props

    try {
      const response = await this.reset(
        values.passwordReset.password,
        decodedResetCode
      )

      const { accessToken, refreshToken, accessModes } =
        response.data.forgotPartnerLoginPassword.userAuth

      storeUserTokens(accessToken, refreshToken)

      const meResponse = await client.query({
        query: GetUserRolesDoNotCache,
        fetchPolicy: 'network-only'
      })
      const { userRoles } = meResponse.data.me

      this.setState(
        {
          showError: false,
          isLoading: false,
          loginInfo: {
            userRoles,
            accessModes
          }
        },
        // Only execute after the last render
        () => actions.setSubmitting(false)
      )
    } catch (e) {
      this.setState({
        showError: true,
        isLoading: false,
        loginInfo: undefined
      })
      actions.setSubmitting(false)
    }
  }

  render() {
    const { match, accessModeContainer } = this.props
    const { showError, isLoading, loginInfo } = this.state

    if (loginInfo && loginInfo.accessModes) {
      const { userRoles, accessModes } = loginInfo
      // If user does not have access to live mode, force them to demo mode
      const forceDemoMode = !accessModes.includes(accessModeKeys.live)
      accessModeContainer.setModeLater(
        forceDemoMode ? accessModeKeys.demo : getAccessMode()
      )
      return <OnLoginSuccess userRoles={userRoles} accessModes={accessModes} />
    }

    const resetCode = match.params && match.params.code ? match.params.code : ''
    const decodedResetCode = decodeURIComponent(resetCode)

    if (showError) return <Error />
    if (isLoading) return <Loading />

    return (
      <Query query={IdentityQuery} variables={{ token: decodedResetCode }}>
        {({ loading, error, data }) => {
          if (error) {
            return <Redirect to={Routes.expired()} />
          }
          if (loading || !data) return <Loading />

          const passwordReset: PasswordReset = {
            email: data.identity.emailAddress,
            password: '',
            verifyPassword: ''
          }
          const passwordResetSchema = Yup.object().shape({
            passwordReset: Yup.object().shape({
              email: Yup.string()
                .email(l10n.admin.errors.invalidEmail)
                .required(l10n.admin.errors.required),
              password: Yup.string()
                .min(
                  minimumLength.passwordResetForm.email,
                  l10n.admin.errors.minimumLength(
                    l10n.admin.passwordReset.passwords,
                    minimumLength.passwordResetForm.email
                  )
                )
                .matches(
                  /^\S+$/,
                  l10n.admin.errors.passwordDoNotAllowBlankSpaces
                )
                .required(l10n.admin.errors.required),
              verifyPassword: Yup.string()
                .required(l10n.admin.errors.required)
                .equalTo(Yup.ref('password'), l10n.admin.errors.passwordMatch)
            })
          })

          return (
            <Page variant="narrowLogo">
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="stretch"
                spacing={3}
              >
                {
                  // eslint-disable-next-line no-unused-vars
                  <Formik
                    initialValues={{ passwordReset }}
                    validationSchema={passwordResetSchema}
                    onSubmit={(values, actions) => {
                      this.setState({ isLoading: true }, () =>
                        this.handleSubmit(decodedResetCode, values, actions)
                      )
                    }}
                  >
                    {({ dirty, isValid, isSubmitting }) => (
                      <Grid item>
                        <Form>
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            alignItems="stretch"
                            spacing={3}
                          >
                            <Grid item>
                              <Typography
                                variant="subtitle1"
                                data-testid="password-reset-title"
                              >
                                {l10n.admin.passwordReset.title}
                              </Typography>
                            </Grid>

                            <Grid item>
                              <Field
                                type="text"
                                name="passwordReset.email"
                                data-testid="password-reset-email"
                                label={l10n.admin.passwordReset.email}
                                component={TextField}
                                fullWidth
                                disabled
                              />
                            </Grid>

                            <Grid item>
                              <Field
                                type="password"
                                name="passwordReset.password"
                                data-testid="password-reset-password"
                                label={l10n.admin.passwordReset.createPassword}
                                component={TextField}
                                fullWidth
                              />
                            </Grid>

                            <Grid item>
                              <Field
                                type="password"
                                name="passwordReset.verifyPassword"
                                data-testid="password-reset-verify-password"
                                label={l10n.admin.passwordReset.verifyPassword}
                                component={TextField}
                                fullWidth
                              />
                            </Grid>
                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item>
                                <Button
                                  color="primary"
                                  variant="contained"
                                  data-testid="password-reset-submit"
                                  disabled={!isValid || !dirty || isSubmitting}
                                  type="submit"
                                >
                                  {l10n.admin.submit}
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Form>
                      </Grid>
                    )}
                  </Formik>
                }
              </Grid>
            </Page>
          )
        }}
      </Query>
    )
  }
}

export default withUnstatedContainer(
  withApolloConsumer(withStyles(styles)(withLaunchDarkly(PasswordResetForm))),
  {
    accessModeContainer: AccessModeContainer
  }
)
