import { gql } from 'graphql.macro'

import ProjectTypeConfigurationResponseFields from 'queries/fragments/ProjectTypeConfigurationResponseFields.graphql'

export default gql`
  fragment ProjectConfigurationResponseFields on ProjectConfigurationResponse {
    defaultLoanType
    maxStagesPerProject
    minStageAmount
    minStagePercentage
    projectTypes {
      ...ProjectTypeConfigurationResponseFields
    }
  }

  ${ProjectTypeConfigurationResponseFields}
`
