// @flow
import React from 'react'
import cx from 'classnames'
import { isFunction } from 'lodash'
import { IconButton } from '@material-ui/core'
import ArrowBack from '@material-ui/icons/ArrowBack'
import { makeStyles } from '@material-ui/core/styles'
import { Link as RouterLink } from 'react-router-dom'
import styles from './BackButton.styles'

const useStyles = makeStyles(styles)

type Props = {
  ariaLabel?: string,
  to?: string | Function,
  dataTest?: string,
  customClasses?: any
}

const BackButton = ({
  ariaLabel = '',
  to = '',
  dataTest = '',
  customClasses
}: Props) => {
  const classes = useStyles()

  return isFunction(to) ? (
    <IconButton
      onClick={to}
      aria-label={ariaLabel}
      className={cx({
        [classes.root]: true,
        [customClasses && customClasses.backButtonStyles]:
          customClasses || false
      })}
      data-testid={dataTest}
    >
      <ArrowBack />
    </IconButton>
  ) : (
    <IconButton
      component={RouterLink}
      to={to}
      aria-label={ariaLabel}
      className={cx({
        [classes.root]: true,
        [customClasses && customClasses.backButtonStyles]:
          customClasses || false
      })}
      data-testid={dataTest}
    >
      <ArrowBack />
    </IconButton>
  )
}

export default BackButton
