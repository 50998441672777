// @flow
import React from 'react'
import cx from 'classnames'
import { MobileStepper, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ArrowBackRounded, ArrowForwardRounded } from '@material-ui/icons'

const styles = () => ({
  stepper: {
    backgroundColor: 'transparent',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'static'
  },
  dots: {
    width: '20%',
    justifyContent: 'space-evenly'
  },
  dot: {
    border: '1px solid #fff',
    backgroundColor: 'transparent'
  },
  activeDot: { backgroundColor: '#fff' },
  arrow: { color: '#fff' },
  invisibleBtn: {
    visibility: 'hidden'
  }
})

type Props = {
  steps: Array<{
    title: string,
    backgroundColor: string
  }>,
  activeStep: number,
  handleBack: Function,
  handleNext: Function,
  classes: Object,
  maxSteps: number
}

const NextButton = ({ activeStep, classes, handleNext, maxSteps }) => {
  return (
    <IconButton
      onClick={handleNext}
      className={cx({
        [classes.invisibleBtn]: activeStep === maxSteps - 1
      })}
    >
      <ArrowForwardRounded className={classes.arrow} />
    </IconButton>
  )
}

const BackButton = ({ activeStep, classes, handleBack }) => {
  return (
    <IconButton
      onClick={handleBack}
      className={cx({
        [classes.invisibleBtn]: activeStep === 0
      })}
    >
      <ArrowBackRounded className={classes.arrow} />
    </IconButton>
  )
}

const Stepper = (props: Props) => {
  const { maxSteps, activeStep, classes } = props
  return (
    <MobileStepper
      className={classes.stepper}
      classes={{
        dot: classes.dot,
        dotActive: classes.activeDot,
        dots: classes.dots
      }}
      variant="dots"
      steps={maxSteps}
      activeStep={activeStep}
      nextButton={NextButton(props)}
      backButton={BackButton(props)}
    />
  )
}

export default withStyles(styles)(Stepper)
