// @flow
import { createContext } from 'react'

export type ActiveProductType = 'lease' | 'loan'

export const ActiveProductContext = createContext<ActiveProductType>({
  activeProductValue: 'lease'
})

export default ActiveProductContext
