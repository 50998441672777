export default theme => ({
  disclosureBlock: {
    paddingTop: '20px'
  },
  agreeAndContinueButton: {
    paddingTop: '26px'
  },
  formFieldLabel: {
    marginBottom: theme.spacing(1)
  },
  labelText: {
    fontWeight: 400
  },
  disclosureContainer: {
    overflowY: 'scroll',
    height: '150px',
    border: `0 solid ${theme.palette.grey['400']}`,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey['200'],
    '&::-webkit-scrollbar': {
      appearance: 'none',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      margin: '12px'
    }
  },
  discloseText: {
    fontFamily: 'sans-serif',
    fontSize: '13px',
    lineHeight: 1.4,
    color: theme.palette.grey['500'],
    marginBottom: theme.spacing(1)
  },
  discloseLink: {
    color: theme.palette.grey['500']
  }
})
