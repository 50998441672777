// Corresponds to GraphQL type "PermissionTypes"
export default {
  SKIP_SSN_DOB_VALIDATION: 'SKIP_SSN_DOB_VALIDATION',
  COMPLETE_PROJECTS: 'COMPLETE_PROJECTS',
  CREATE_CUSTOMER: 'CREATE_CUSTOMER',
  SIGN_OFFER: 'SIGN_OFFER',
  VIEW_ESTIMATE: 'VIEW_ESTIMATE',
  EDIT_CUSTOMER: 'EDIT_CUSTOMER',
  VIEW_CUSTOMER: 'VIEW_CUSTOMER',
  FULL_ACCESS: 'FULL_ACCESS',
  AUTO_REP: 'AUTO_REP',
  INSTALL_COMPLETE_CERTIFICATE: 'INSTALL_COMPLETE_CERTIFICATE',
  VIEW_PROJECTS: 'VIEW_PROJECTS',
  EDIT_ACH_SETUP: 'EDIT_ACH_SETUP',
  PARTIAL_CREDIT_APPLICATION: 'PARTIAL_CREDIT_APPLICATION',
  P2_ZERO_PREQUAL_ACCESS: 'P2_ZERO_PREQUAL_ACCESS'
}
