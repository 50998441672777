import { colors } from 'themes/default'

export default () => ({
  stagesContainer: {
    margin: '10px 0 0 0'
  },
  stageIcon: {
    color: colors.stageGreen
  },
  stageText: {
    paddingLeft: '25px'
  },
  optDescription: {
    paddingBottom: '1ex'
  }
})
