import React from 'react'

import basePaths from 'constants/enums/basePaths'

const { onboarding, returnFromContract, contract, sharePendingState } =
  basePaths

export const hiddenPaths = [
  onboarding,
  returnFromContract,
  contract,
  sharePendingState
]

export const HeaderContext = React.createContext({
  toggleHeader: () => undefined,
  overrideHeader: () => undefined,
  resetHeader: () => undefined,
  showHeader: true
})

export default HeaderContext
