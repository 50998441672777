import projectTypeIds from './projectTypeId'

export const projectTypeKeys = {
  DOORS: 'DOORS',
  OTHER: 'OTHER',
  CUSTOM: 'CUSTOM',
  HVAC: 'HVAC',
  WINDOWS: 'WINDOWS',
  ROOFING: 'ROOFING',
  SOLAR_SYSTEM: 'SOLAR_SYSTEM',
  EXTERIOR_REMODEL: 'EXTERIOR_REMODEL',
  INTERIOR_REMODEL: 'INTERIOR_REMODEL',
  BATTERY: 'BATTERY'
}

export const projectTypeToId = {
  [projectTypeKeys.SOLAR_SYSTEM]: 1,
  [projectTypeKeys.ROOFING]: 2,
  [projectTypeKeys.WINDOWS]: 3,
  [projectTypeKeys.DOORS]: 4,
  [projectTypeKeys.HVAC]: 5,
  [projectTypeKeys.INTERIOR_REMODEL]: 6,
  [projectTypeKeys.EXTERIOR_REMODEL]: 7,
  [projectTypeKeys.BATTERY]: 8,
  [projectTypeKeys.OTHER]: 20,
  [projectTypeKeys.CUSTOM]: 20
}

export const projectTypeIdToEnumValue = {
  [projectTypeIds.solar]: projectTypeKeys.SOLAR_SYSTEM,
  [projectTypeIds.roofing]: projectTypeKeys.ROOFING,
  [projectTypeIds.windows]: projectTypeKeys.WINDOWS,
  [projectTypeIds.doors]: projectTypeKeys.DOORS,
  [projectTypeIds.hvac]: projectTypeKeys.HVAC,
  [projectTypeIds.interiorRemodel]: projectTypeKeys.INTERIOR_REMODEL,
  [projectTypeIds.exteriorRemodel]: projectTypeKeys.EXTERIOR_REMODEL,
  [projectTypeIds.otherOrCustom]: projectTypeKeys.CUSTOM,
  [projectTypeIds.battery]: projectTypeKeys.BATTERY
}

export const loanStageKeys = {
  completion: 'Completion',
  countersignature: 'Countersignature'
}

export default {
  [projectTypeKeys.OTHER]: 'Other',
  [projectTypeKeys.CUSTOM]: 'Custom',
  [projectTypeKeys.HVAC]: 'HVAC',
  [projectTypeKeys.DOORS]: 'Doors',
  [projectTypeKeys.WINDOWS]: 'Windows',
  [projectTypeKeys.ROOFING]: 'Roofing',
  [projectTypeKeys.SOLAR_SYSTEM]: 'Solar',
  [projectTypeKeys.EXTERIOR_REMODEL]: 'Exterior Remodel',
  [projectTypeKeys.INTERIOR_REMODEL]: 'Interior Remodel',
  [projectTypeKeys.BATTERY]: 'Battery'
}
