import { gql } from 'graphql.macro'

export default gql`
  mutation ValidateSsn(
    $customerId: String!
    $validateSsnRequest: CustomerSsnValidationRequest!
  ) {
    validateSsn(
      customerId: $customerId
      validateSsnRequest: $validateSsnRequest
    )
  }
`
