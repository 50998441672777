const scriptUrl = siteKey =>
  `https://www.google.com/recaptcha/api.js?render=${siteKey}`

export const reCaptchaActions = {
  prequalificationFormEntered: 'prequalification_form_entered'
}

export const displayReCaptcha = (handleLoaded, reCaptchaSiteKeys) => {
  const script = document.createElement('script')
  script.src = scriptUrl(reCaptchaSiteKeys)
  script.addEventListener('load', handleLoaded)
  document.body.appendChild(script)
}

export const hideReCaptcha = () => {
  const recaptchaElement =
    document.getElementsByClassName('grecaptcha-badge')[1]
  if (recaptchaElement) {
    recaptchaElement.style.display = 'none'
  }
}

export default { displayReCaptcha, reCaptchaActions }
