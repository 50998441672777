// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { get } from 'lodash'
import PhoneIcon from '@material-ui/icons/Phone'
import { Grid, Typography } from '@material-ui/core'
import l10n from 'properties/translations'
import { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import ApplyContext from 'util/ApplyContext'
import styles from './commonStyles'

type Props = {
  classes: {
    row: string,
    iconWrapperGray: string,
    iconGray: string,
    subheading: string,
    linkText: string
  },
  conditionalApprovalLink: string,
  shouldSubmitButtonRender: Function
}

const ConditionalApprovalMultiple = ({
  classes,
  conditionalApprovalLink,
  shouldSubmitButtonRender
}: Props) => (
  <ApplyContext.Consumer>
    {value => (
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        data-testid="conditional-approval-container"
      >
        <Grid item>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.iconWrapperGray}
          >
            <Grid item>
              <PhoneIcon className={classes.iconGray} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.subheading}>
          <Typography variant="subtitle1" align="center">
            {l10n.apply.formStatus.conditionalApprovalPhone.subHeader}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" paragraph>
            {l10n.apply.formStatus.conditionalApprovalPhone.descriptionLn1}
          </Typography>
          <Typography variant="body2" paragraph>
            {`${l10n.apply.formStatus.conditionalApprovalPhone.descriptionLn2} ${l10n.apply.formStatus.conditionalApprovalPhone.descriptionLn3}`}
            {
              l10n.apply.formStatus.conditionalApprovalPhone
                .descriptionLn3Action
            }
          </Typography>
          <Typography variant="body2" paragraph>
            {l10n.apply.formStatus.conditionalApprovalUpload.descriptionLn2}
          </Typography>
          {shouldSubmitButtonRender(
            get(value, 'fieldset') === fieldsetNames.FIELDSET_PUBLIC_APPLICATION
          ) && (
            <Typography
              variant="body1"
              paragraph
              data-testid="submit-document-link"
            >
              <a
                href={conditionalApprovalLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkText}
              >
                {l10n.apply.formStatus.conditionalApprovalUpload.descriptionLn3}
              </a>
            </Typography>
          )}
          <Typography
            variant="body2"
            data-testid="proceed-via-email-description"
          >
            {l10n.apply.formStatus.conditionalApprovalUpload.descriptionLn4}
            {
              l10n.apply.formStatus.conditionalApprovalUpload
                .descriptionLn4Action
            }
          </Typography>
        </Grid>
      </Grid>
    )}
  </ApplyContext.Consumer>
)

export default withStyles(styles)(ConditionalApprovalMultiple)
