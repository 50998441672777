import { gql } from 'graphql.macro'

export default gql`
  query GetPasswordResetIdentity($token: String!) {
    identity(passwordResetToken: $token) {
      channelPartner {
        name
      }
      emailAddress
      firstName
      userRoles
    }
  }
`
