import MuiButton from './MuiButton'
import MuiFormControlLabel from './MuiFormControlLabel'
import MuiFormLabel from './MuiFormLabel'
import MuiFormHelperText from './MuiFormHelperText'
import MuiInput from './MuiInput'
import MuiListItem from './MuiListItem'
import MuiMenuItem from './MuiMenuItem'
import MuiTypography from './MuiTypography'

const overrides = (themeColors, theme) => {
  const components = {
    MuiButton,
    MuiInput,
    MuiListItem
  }
  // Assign properties for components with styles that use theme variables/fns.
  // Pass theme if you need to access helper methods for breakpoints.
  components.MuiFormHelperText = MuiFormHelperText(theme)
  components.MuiFormControlLabel = MuiFormControlLabel(themeColors)
  components.MuiFormLabel = MuiFormLabel(themeColors)
  components.MuiMenuItem = MuiMenuItem(themeColors)
  components.MuiTypography = MuiTypography(themeColors, theme)
  return components
}

export default overrides
