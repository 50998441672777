// @flow
import React, { Component } from 'react'
import {
  Grid,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { get, last, split, isEmpty } from 'lodash'
import * as Yup from 'yup'
import { Select } from 'formik-material-ui'
import { Mutation } from '@apollo/client/react/components'
import type { BrowserHistory, HashHistory, MemoryHistory } from 'history'
import errorKeys from 'constants/enums/errorKeys'
import { minimumLength } from 'properties/properties'
import {
  getFieldErrors,
  displayErrorsInline
} from 'screens/admin/util/BankAccountHelper'

import Routes from 'util/Routes'
import Button from 'components/shared/Button'
import l10n from 'properties/translations'
import accountTypes from 'constants/enums/accountTypes'
import SuccessToast from 'components/shared/SuccessToast'
import ErrorToast, { errorToastIcon } from 'components/shared/ErrorToast'
import RoutingNumber from 'components/shared/BankAccountRoutingNumber'
import BankAccountNumber from 'components/shared/BankAccountNumber'
import { inP3xIframe } from 'util/EnvironmentHelper'
import styles from '../Admin.styles'
import EditBankAccount from '../queries/EditBankAccount.graphql'

import { Typography as MdsTypography } from '@solarmosaic/mds.components.typography'
import { ThemeWrapper } from '@solarmosaic/util.react.theme-wrapper-for-compositions'
import { createMuiThemeTessera } from '@solarmosaic/mds.themes.mui-theme-tessera'
import MdsCheckmark from 'images/mds-checkmark.png'

type Props = {
  lastFour: string,
  history: BrowserHistory | HashHistory | MemoryHistory
}

type State = {
  openSuccess: boolean,
  errorOccurred: boolean
}

type BankAccount = {
  routingNumber: string,
  accountNumber: string,
  currentAccountNumber?: string,
  accountType: string,
  hasBeenSubmitted: boolean
}

const bankAccount: BankAccount = {
  routingNumber: '',
  accountNumber: '',
  currentAccountNumber: '',
  accountType: accountTypes.checking
}

const bankAccountSchema = Yup.object().shape({
  bankAccount: Yup.object().shape({
    currentAccountNumber: Yup.string()
      .min(
        minimumLength.bankAccount.currentAccountNumber,
        l10n.admin.errors.minimumLength(
          l10n.admin.bankInformation.currentAccountNumber,
          minimumLength.bankAccount.currentAccountNumber
        )
      )
      .required(l10n.admin.errors.required),
    accountNumber: Yup.string()
      .min(
        minimumLength.bankAccount.cccountNumber,
        l10n.admin.errors.minimumLength(
          l10n.admin.bankInformation.accountNumber,
          minimumLength.bankAccount.cccountNumber
        )
      )
      .required(l10n.admin.errors.required),
    routingNumber: Yup.string()
      .min(
        minimumLength.bankAccount.currentRoutingNumber,
        l10n.admin.errors.minimumLength(
          l10n.admin.bankInformation.currentRoutingNumber,
          minimumLength.bankAccount.currentRoutingNumber
        )
      )
      .required(l10n.admin.errors.required),
    accountType: Yup.string().required(l10n.admin.errors.required)
  })
})

class BankAccountEdit extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      openSuccess: false,
      errorOccurred: false,
      loading: false,
      formErrors: [],
      hasBeenSubmitted: false,
      p3xSuccess: false
    }
  }

  toggleLoading = () => {
    this.setState(prevState => ({
      loading: !prevState.loading
    }))
  }

  showSuccess = showSuccess => async () => {
    await this.setState({
      openSuccess: showSuccess
    })
    const { openSuccess } = this.state
    const { history } = this.props

    // Redirects only if success toast is closed
    if (!openSuccess) {
      this.toggleLoading()

      if (inP3xIframe()) {
        this.setState(() => ({
          p3xSuccess: true
        }))
      } else {
        history.push(Routes.admin())
      }
    }
  }

  showError = showError => () => {
    if (showError) this.toggleLoading()
    this.setState({
      errorOccurred: showError
    })
  }

  handleSubmit = async (
    values: BankAccount,
    updatePartnerBankAccount: Function
  ) => {
    this.toggleLoading()

    const response = await updatePartnerBankAccount({
      variables: {
        currentAccountNumber: values.currentAccountNumber,
        accountNumber: values.accountNumber,
        routingNumber: values.routingNumber,
        accountType: values.accountType
      }
    })

    const status = get(
      response,
      'data.updatePartnerBankAccount.bankAccount.status',
      {}
    )
    const errors =
      response.data && get(response, 'data.updatePartnerBankAccount.errors', [])

    if (!isEmpty(status)) {
      this.showSuccess(true)()
    } else if (!isEmpty(errors)) {
      this.setState({
        formErrors: response.data.updatePartnerBankAccount.errors
      })
      this.toggleLoading()
    } else {
      this.showError(true)()
    }
  }

  onChange = async event => {
    const { hasBeenSubmitted, formErrors } = this.state

    const fieldName = event.target.name
    const editFieldName = last(split(fieldName, '.'))

    switch (editFieldName) {
      case editFieldName:
        await this.setState({
          formErrors: getFieldErrors(formErrors, editFieldName)
        })
        break
      default:
        return []
    }

    if (hasBeenSubmitted) {
      this.setState({
        hasBeenSubmitted: false,
        formErrors: []
      })
    }
  }

  render() {
    const { classes, lastFour } = this.props
    const {
      openSuccess,
      errorOccurred,
      loading,
      formErrors,
      hasBeenSubmitted,
      p3xSuccess
    } = this.state

    if (p3xSuccess) {
      return (
        <ThemeWrapper theme={createMuiThemeTessera()}>
          <Grid item className={classes.p3xAdminEmptyState}>
            <img
              alt="Checkmark"
              className={classes.mdsCheckmark}
              src={MdsCheckmark}
            />
            <MdsTypography
              variant="h2"
              style={{ color: '#0F8189' }}
              data-testid="bank-account-info-updated"
            >
              Bank account information updated.
            </MdsTypography>
          </Grid>
        </ThemeWrapper>
      )
    }

    return (
      <>
        <SuccessToast
          message={l10n.admin.bankInformation.bankAccountUpdatedSuccess}
          open={openSuccess}
          handleClose={this.showSuccess(false)}
        />
        <ErrorToast
          message={l10n.shared.error}
          open={errorOccurred}
          handleClose={this.showError(false)}
          iconType={errorToastIcon.serverError}
        />
        <Mutation mutation={EditBankAccount}>
          {updatePartnerBankAccount => (
            <Formik
              id="edit-bank-account-form"
              initialValues={{ bankAccount }}
              validationSchema={bankAccountSchema}
              onSubmit={(values, actions) => {
                this.handleSubmit(values.bankAccount, updatePartnerBankAccount)
                actions.setSubmitting(false)
              }}
            >
              {({ isValid, errors, dirty }) => {
                return (
                  <Grid item>
                    <Form>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item>
                          <Typography
                            variant="h1"
                            data-testid="admin-bank-information-title"
                            className={classes.pageTitle}
                          >
                            {l10n.admin.bankInformation.titleEdit}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            data-testid="admin-bank-information-description"
                            className={classes.bodyTextNormal}
                          >
                            {l10n.admin.bankInformation.descriptionVerify(
                              lastFour
                            )}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Field
                            fullWidth
                            type="tel"
                            id="bankAccount.currentAccountNumber"
                            name="bankAccount.currentAccountNumber"
                            data-testid="bank-account-edit-current-account-number"
                            label={
                              l10n.admin.bankInformation.currentAccountNumber
                            }
                            component={BankAccountNumber}
                            onChange={this.onChange}
                            disabled={loading}
                          />
                          {!isEmpty(formErrors) && (
                            <Typography
                              component="span"
                              className={classes.errorMessage}
                            >
                              {displayErrorsInline(
                                errorKeys.currentAccountNumber,
                                formErrors,
                                errors
                              )}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            data-testid="admin-bank-new-account-number"
                            className={classes.bodyTextNormal}
                          >
                            {l10n.admin.bankInformation.descriptionNewAccount}
                          </Typography>
                        </Grid>

                        <Grid item>
                          <Field
                            fullWidth
                            type="tel"
                            id="accountNumber"
                            name="bankAccount.accountNumber"
                            data-testid="bank-account-edit-new-account-number"
                            label={l10n.admin.bankInformation.accountNumber}
                            component={BankAccountNumber}
                            onChange={this.onChange}
                            disabled={loading}
                          />
                          {!isEmpty(formErrors) && (
                            <Typography
                              component="span"
                              className={classes.errorMessage}
                            >
                              {displayErrorsInline(
                                errorKeys.accountNumber,
                                formErrors,
                                errors
                              )}
                            </Typography>
                          )}
                        </Grid>

                        <Grid item>
                          <Field
                            fullWidth
                            type="tel"
                            id="routingNumber"
                            name="bankAccount.routingNumber"
                            data-testid="bank-account-edit-routing-number"
                            label={l10n.admin.bankInformation.routingNumber}
                            component={RoutingNumber}
                            onChange={this.onChange}
                            disabled={loading}
                          />
                          {!isEmpty(formErrors) && (
                            <Typography
                              component="span"
                              className={classes.errorMessage}
                            >
                              {displayErrorsInline(
                                errorKeys.routingNumber,
                                formErrors,
                                errors
                              )}
                            </Typography>
                          )}
                        </Grid>
                        <Grid item>
                          <FormControl fullWidth>
                            <InputLabel
                              shrink
                              htmlFor="bankAccount.accountType"
                            >
                              {l10n.setup.confirmation.bankFields.accountType}
                            </InputLabel>
                            <Field
                              native
                              fullWidth
                              name="bankAccount.accountType"
                              id="bankAccount.accountType"
                              data-testid="bank-account-edit-account-type"
                              component={Select}
                              disabled={loading}
                            >
                              <option
                                key={accountTypes.checking}
                                value={accountTypes.checking}
                                data-testid="bank-account-edit-account-type-checking"
                              >
                                {
                                  l10n.setup.confirmation.accountTypes[
                                    accountTypes.checking
                                  ]
                                }
                              </option>
                              <option
                                key={accountTypes.savings}
                                value={accountTypes.savings}
                                data-testid="bank-account-edit-account-type-savings"
                              >
                                {
                                  l10n.setup.confirmation.accountTypes[
                                    accountTypes.savings
                                  ]
                                }
                              </option>
                            </Field>
                          </FormControl>
                        </Grid>
                        {!isEmpty(formErrors) && hasBeenSubmitted && (
                          <Grid item className={classes.formErrorContainer}>
                            <Typography
                              component="span"
                              className={classes.errorMessage}
                            >
                              {l10n.admin.bankInformation.checkAndTryAgain}
                            </Typography>
                          </Grid>
                        )}
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        spacing={3}
                      >
                        <Grid item>
                          <Button
                            disabled={
                              (hasBeenSubmitted && !isEmpty(formErrors)) ||
                              !isValid ||
                              !dirty ||
                              loading
                            }
                            color="primary"
                            variant="contained"
                            type="submit"
                            data-testid="bank-account-edit-submit"
                            onClick={() => {
                              this.setState({ hasBeenSubmitted: true })
                            }}
                          >
                            {loading ? (
                              <CircularProgress size={16} />
                            ) : (
                              <Typography>{l10n.admin.submit}</Typography>
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  </Grid>
                )
              }}
            </Formik>
          )}
        </Mutation>
      </>
    )
  }
}
export const UnwrappedBankAccountEdit = withStyles(styles)(BankAccountEdit)
export default withRouter(UnwrappedBankAccountEdit)
