// @flow
/* eslint react/prop-types: 0 */

import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { EMPTY_STRING } from 'properties/properties'

import placeholders from 'properties/placeholders'
import browserAutocompleteKeys from 'constants/enums/browserAutocompleteKeys'
import translations from 'properties/translations'
import TextValidator from 'components/shared/TextValidator'
import customerFields from 'constants/enums/customerFields'
import TranslationsContext from 'util/TranslationsContext'

type Props = {
  canEdit: boolean,
  email: string,
  demoMode: boolean,
  onFieldEdit: func,
  getErrorMessagesRequired: (fieldName: string) => string[],
  getValidatorsRequired: (fieldName: string) => string[],
  inputProps: object,
  emailAddressVerified: boolean,
  helperText: string,
  showError: boolean,
  required: boolean,
  onKeyPress: Function
}

class CustomerEmail extends Component<Props> {
  static contextType = TranslationsContext

  static defaultProps = {
    required: false
  }

  render() {
    const {
      canEdit,
      email,
      demoMode,
      onFieldEdit,
      getErrorMessagesRequired,
      getValidatorsRequired,
      inputProps,
      emailAddressVerified,
      helperText,
      showError,
      required,
      onKeyPress
    } = this.props
    const l10n = this.context || translations

    return (
      <Grid item xs={12}>
        <TextValidator
          id={customerFields.email}
          ref={customerFields.email}
          fullWidth
          label={l10n.customer.email}
          onChange={onFieldEdit(customerFields.email)}
          name={customerFields.email}
          value={email || EMPTY_STRING}
          disabled={emailAddressVerified || !canEdit || demoMode}
          required={required}
          inputProps={{
            ...inputProps,
            'data-testid': `customer-email-${customerFields.email}`,
            onKeyPress
          }}
          placeholder={placeholders.email}
          validators={[
            ...getValidatorsRequired(customerFields.email),
            'isEmail'
          ]}
          errorMessages={[
            ...getErrorMessagesRequired(customerFields.email, l10n),
            l10n.customer.emailErrorMessage
          ]}
          type="email"
          autoComplete={browserAutocompleteKeys.email}
          helperText={showError ? helperText : ''}
          error={showError}
        />
      </Grid>
    )
  }
}

export default CustomerEmail
