import { gql } from 'graphql.macro'

export default gql`
  query GetTasks($customerId: String) {
    customer(customerId: $customerId) {
      id
      tasks {
        taskId
        taskType
        taskStatus
        document {
          requiredDocumentType
          activeDocuments {
            fileName
          }
        }
      }
    }
  }
`
