// @flow
import React from 'react'
import Typography from '@material-ui/core/Typography'
import l10n from 'properties/translations'
import { withStyles } from '@material-ui/core/styles'
import styles from './NoLoanProductsMessage.styles'

type Props = {
  classes: any
}

const NoLoanProductsMessage = ({ classes }: Props) => (
  <Typography variant="body2" className={classes.noLoanProductsRow}>
    {l10n.quote.borrowerContactInstallerNoLoanProductChosen}
  </Typography>
)

export default withStyles(styles)(NoLoanProductsMessage)
