import {
  colors,
  pageTitle,
  bodyTextNormal,
  buttonMinWidth
} from 'themes/default'

export default theme => ({
  h1: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize * 2,
    color: colors.normalBlack
  },
  headerRow: {
    marginBottom: theme.spacing(1)
  },
  body1: {
    fontSize: theme.typography.fontSize + 2,
    fontWeight: 400,
    color: colors.normalBlack
  },
  inactiveColor: {
    color: theme.palette.grey[900]
  },
  errorText: {
    color: colors.errorRed,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  button: {
    marginBottom: '15px',
    ...buttonMinWidth
  },
  dialog: {
    padding: theme.spacing(2),
    minWidth: '33vw',
    position: 'relative',
    top: '-300px'
  },
  pleaseTellUsAboutYourselfContainer: {
    paddingTop: theme.spacing(3),
    '& p': { color: colors.normalBlack }
  },
  disclosuresContainer: {
    marginTop: theme.spacing(1)
  },
  loading: {
    top: '50% !important'
  },
  lockedField: {
    backgroundColor: 'rgba(14, 97, 156, 0.15)',
    borderRadius: 5,
    height: 55,
    display: 'flex',
    justifyContent: 'center',
    '& input': {
      padding: theme.spacing(2),
      fontWeight: 500,
      color: `${colors.normalBlack} !important`
    }
  },
  inputAdornment: {
    paddingRight: theme.spacing(1)
  },
  lockedFieldIcon: {
    color: colors.lockedIconColor
  },
  inactiveForm: {
    opacity: 0.4,
    cursor: 'default',
    'pointer-events': 'none'
  },
  captionButton: {
    fontSize: 'inherit',
    color: theme.palette.action.link,
    position: 'relative',
    verticalAlign: 'baseline'
  },
  label: {
    paddingTop: theme.spacing(1.5)
  },
  pageTitle: {
    fontWeight: pageTitle.fontWeight,
    fontSize: pageTitle.fontSize,
    color: pageTitle.color
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  inputContainer: {
    '& input': {
      fontSize: '20px'
    },
    '& ::placeholder': {
      color: colors.mutedBlack,
      opacity: 1
    }
  },
  altPhoneText: {
    paddingTop: '15px'
  },
  formFieldLabel: {
    marginBottom: theme.spacing(1)
  },
  labelText: {
    fontWeight: 400
  },
  emailAndPhoneLabelInlineBlock: {
    display: 'inline-block'
  },
  disclosureContainer: {
    overflowY: 'scroll',
    height: '150px',
    border: `0 solid ${theme.palette.grey['400']}`,
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.grey['200'],
    '&::-webkit-scrollbar': {
      appearance: 'none',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      margin: '12px'
    }
  },
  discloseText: {
    fontFamily: 'sans-serif',
    fontSize: '13px',
    lineHeight: 1.4,
    color: theme.palette.grey['500'],
    marginBottom: theme.spacing(1)
  },
  discloseLink: {
    color: theme.palette.grey['500']
  }
})
