// @flow

export const pageVariantsEnum = Object.freeze({
  default: 'default',
  withLogo: 'withLogo',
  narrowLogo: 'narrowLogo',
  stageNav: 'stageNav',
  stageNavBack: 'stageNavBack',
  logoRight: 'logoRight',
  withoutLogo: 'withoutLogo'
})

export type PageVariants = typeof pageVariantsEnum

export default pageVariantsEnum
