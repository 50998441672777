// @flow

import foudationCreditStatues from 'constants/enums/foundationApplicationDecisionStatuses'

export const decisionScreenSubTitle = (applicationState: string) => {
  switch (applicationState) {
    case foudationCreditStatues.counteroffered:
      return `Please contact your rep for more information,
      as you have been approved for a lesser amount than initially requested.`
    case foudationCreditStatues.error:
      return 'Please refresh and try again.'
    default:
      return `Please contact your rep for more information ${`about this ${
        applicationState === foudationCreditStatues.approved
          ? 'approval.'
          : 'application.'
      }`}`
  }
}

export default { decisionScreenSubTitle }
