// @flow

export const getPromoPeriodText = (
  loanMonthTerm: number = 0,
  numMonthlyPaymentsPromo: number = 0,
  promoPeriod: number = 0,
  updatePaymentPlansFlagEnabled: boolean,
  servicePlanIsAvailable: boolean,
  l10n: any
): string => {
  const { month } = l10n.time
  const { approved } = l10n.apply.formStatus

  let promoPeriodText = `${numMonthlyPaymentsPromo}${
    approved.month
  } ${approved.promoPeriod(updatePaymentPlansFlagEnabled)}`

  if (servicePlanIsAvailable) {
    promoPeriodText = updatePaymentPlansFlagEnabled
      ? `${numMonthlyPaymentsPromo}${approved.month} ${approved.promoPeriod(
          updatePaymentPlansFlagEnabled
        )}`
      : `${numMonthlyPaymentsPromo || loanMonthTerm}${approved.month} ${
          numMonthlyPaymentsPromo &&
          approved.promoPeriod(updatePaymentPlansFlagEnabled)
        }`
  } else {
    promoPeriodText = `${numMonthlyPaymentsPromo}-${month} ${approved.promoPeriod(
      updatePaymentPlansFlagEnabled
    )}`
  }

  return promoPeriodText
}

export const getNonPromoPeriodText = (
  loanMonthTerm: number = 0,
  promoPeriod: number = 0,
  updatePaymentPlansFlagEnabled: boolean,
  servicePlanIsAvailable: boolean,
  l10n: any
): string => {
  const { approved } = l10n.apply.formStatus
  let nonPromoPeriodText = null

  if (servicePlanIsAvailable) {
    nonPromoPeriodText = updatePaymentPlansFlagEnabled
      ? approved.yearsRate(loanMonthTerm, promoPeriod, !!promoPeriod)
      : `${loanMonthTerm}${approved.month}`
  } else {
    if (updatePaymentPlansFlagEnabled) {
      nonPromoPeriodText = approved.yearsRate(
        loanMonthTerm,
        promoPeriod,
        !!promoPeriod
      )
    } else {
      nonPromoPeriodText = `${loanMonthTerm} ${approved.month}`
    }
  }

  return nonPromoPeriodText
}
