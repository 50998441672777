// @flow
import React, { Component } from 'react'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { flow } from 'lodash'
import CloseIcon from '@material-ui/icons/Close'
import { Snackbar, IconButton } from '@material-ui/core'
import NoSignal from '@material-ui/icons/ErrorOutline'
import { withStyles } from '@material-ui/core/styles'
import MessageCustom from './MessageCustom'
import { QuoteStore } from 'screens/quote/QuoteStore'

const styles = () => ({
  icon: {
    fontSize: 16
  },
  iconButton: {
    width: 40
  },
  offline: {
    verticalAlign: 'middle',
    fontSize: 20,
    marginRight: 6,
    marginTop: -1
  }
})

const variantIcon = {
  offline: NoSignal
}

type Props = {
  text: Array<string>,
  iconType: string,
  timeout: number,
  classes: Object,
  client: Object,
  canExit: boolean
}

type State = {
  showMessage: boolean
}

class Message extends Component<Props, State> {
  state = {
    showMessage: true
  }

  handleClose = (event: Object, reason: string) => {
    if (reason === 'clickaway') return

    QuoteStore.clearMessage()

    this.setState({
      ...this.state,
      showMessage: false
    })
  }

  /* eslint-disable react/no-array-index-key, no-shadow */
  render() {
    const { text, iconType, classes, timeout, canExit } = this.props

    const Icon = variantIcon[iconType]

    if (timeout) {
      return (
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={this.state.showMessage}
          autoHideDuration={timeout}
          onClose={this.handleClose}
          message={
            <>
              {Icon && <Icon className={classes[iconType]} />}
              <MessageCustom text={text} />
            </>
          }
        />
      )
    }

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left'
        }}
        action={
          canExit
            ? [
                <IconButton
                  key="close"
                  color="inherit"
                  aria-label="Close"
                  onClick={this.handleClose}
                  className={classes.iconButton}
                >
                  <CloseIcon className={classes.icon} />
                </IconButton>
              ]
            : null
        }
        open={this.state.showMessage}
        message={text}
      />
    )
  }
}

export default flow(withApolloConsumer, withStyles(styles))(Message)
