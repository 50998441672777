// https://developer.mozilla.org/en-US/docs/Web/HTML/Attributes/autocomplete
export default {
  additionalName: 'additional-name',
  givenName: 'given-name',
  familyName: 'family-name',
  email: 'email',
  telNational: 'tel-national',
  addressLine1: 'address-line1',
  city: 'address-level2',
  zip: 'postal-code',
  state: 'address-level1'
}
