// @flow
import { bodyTextNormal, buttonMinWidth } from 'themes/default'

export default (theme: any) => ({
  buttonsContainer: {
    padding: theme.spacing(2)
  },
  radioButtonControl: {
    alignItems: 'start'
  },
  row: {
    padding: theme.spacing(1),
    paddingLeft: theme.spacing(3)
  },
  title: {
    margin: `${theme.spacing(2)}px 0`
  },
  singleResult: {
    paddingLeft: theme.spacing(6)
  },
  informationContainer: {
    paddingTop: theme.spacing(1)
  },
  addressInformation: {
    color: theme.palette.error.main
  },
  informationText: {
    fontWeight: 700,
    wordWrap: 'break-word'
  },
  informationField: {
    fontWeight: 400
  },
  addressRadio: {
    padding: 12,
    paddingLeft: theme.spacing(1)
  },
  addressData: {
    paddingTop: theme.spacing(1)
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  button: {
    ...buttonMinWidth
  }
})
