// @flow
import type { SharedText } from './sharedText.type'

const sharedText: SharedText = {
  accountNumber: 'Account number',
  adminAccount: 'Account',
  adminAccountDocuments: 'Account Documents',
  adminBankAccount: 'Bank account',
  adminProducts: 'Payment plans',
  adminTeam: 'Team',
  adminTeamAdd: 'Invite team members',
  applicant: 'Applicant',
  back: 'Back',
  cancel: 'Cancel',
  cancelled: 'Cancelled',
  checking: 'Checking',
  close: 'Close',
  complete: 'Complete',
  completed: 'Completed',
  confirm: 'Confirm',
  continue: 'Continue',
  customer: 'Customer',
  customerInfo: 'Customer info',
  disclosures: 'Disclosures',
  dob: 'Date of birth',
  done: 'Done',
  edit: 'Edit',
  editOffer: 'Edit Offer',
  emailAddress: 'Email address',
  emailOnly: 'Email Only',
  firstName: 'First name',
  goBack: 'Go Back',
  gotIt: 'Got it',
  incomplete: 'Incomplete',
  lastName: 'Last name',
  middleName: 'Middle name (Optional)',
  monthlyPayment: 'Monthly Payment',
  newCustomer: 'New Customer',
  next: 'Next',
  optOutSMS: 'This user has opted out of receiving SMS messages',
  phoneNumber: 'Mobile phone number',
  pleaseWait: 'Please Wait',
  processing: 'Processing',
  projectAddress: 'Project Address',
  projectCost: 'Project cost',
  projectName: 'Project name',
  project: 'Project',
  projects: 'Projects',
  projectType: 'Project type',
  projectTypes: 'Project types',
  refresh: 'Refresh',
  remove: 'Remove',
  resources: 'Resources',
  required: 'Required',
  reviewAndAccept: 'Overview of terms',
  reviewAutopay: 'Review autopay',
  routingNumber: 'Routing number',
  savings: 'Savings',
  send: 'Send',
  setupAutopay: 'Set up autopay',
  share: 'Share',
  signedTitle: 'You’ve completed financing',
  stage: 'stage',
  stageCost: 'Stage cost',
  stageName: 'Stage name',
  start: 'Start',
  status: 'Status',
  street: 'Street',
  submit: 'Submit',
  summary: 'Summary',
  swiftlink: 'SwiftLink',
  unknownStatus: 'Unknown status',
  viewAgreement: 'View Agreement',
  emailMustBeValid: 'Must be a valid email address.',
  emailInstaller: 'Please enter your customer’s email address',
  phoneNumberIsInvalid:
    'Invalid phone number. Please enter a valid mobile phone number to proceed.',
  phoneNumberIsOptedOut:
    'This phone number has opted out of receiving text messages.',
  welcome: 'Welcome'
}

export default sharedText
