// @flow
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import DoneIcon from '@material-ui/icons/Done'
import l10n from 'properties/translations'

const DONE_ICON_WRAPPER_SIZE = 70
const DONE_ICON_SIZE = 28

const useStyles = makeStyles(theme => ({
  absolutelyCentered: {
    position: 'absolute',
    top: `calc(50% - ${DONE_ICON_WRAPPER_SIZE}px)`
  },
  icon: {
    color: theme.palette.iconSuccess,
    width: DONE_ICON_SIZE,
    height: DONE_ICON_SIZE,
    marginTop: 3
  },
  iconWrapper: {
    marginBottom: theme.spacing(2),
    backgroundColor: theme.palette.iconSuccessContainer,
    width: DONE_ICON_WRAPPER_SIZE,
    height: DONE_ICON_WRAPPER_SIZE,
    borderRadius: DONE_ICON_WRAPPER_SIZE / 2
  },
  projectConfirmedStyle: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    lineHeight: 1.2,
    textAlign: 'center',
    color: theme.palette.common.successGreen
  },
  genericHeading: {
    fontSize: '24px',
    color: '#222222',
    marginBottom: theme.spacing(2)
  },
  genericCaption: {
    fontFamily: theme.typography.fontFamily,
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize,
    lineHeight: 1.2,
    textAlign: 'center',
    color: theme.palette.darkGray
  }
}))

type Props = {
  caption?: string,
  centered?: boolean,
  genericUploader?: boolean
}

const SuccessBadge = (props: Props) => {
  const { caption = '', centered = false, genericUploader = false } = props
  const classes = useStyles()
  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      alignContent="center"
      className={centered ? classes.absolutelyCentered : null}
    >
      {genericUploader ? (
        <Typography variant="h1" className={classes.genericHeading}>
          {l10n.genericUploader.successBadgeTitle}
        </Typography>
      ) : (
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.iconWrapper}
        >
          <Grid item>
            <DoneIcon className={classes.icon} />
          </Grid>
        </Grid>
      )}
      {caption && (
        <Grid item className={classes.subheading}>
          <Typography
            variant="subtitle1"
            align="center"
            className={
              genericUploader
                ? classes.genericCaption
                : classes.projectConfirmedStyle
            }
          >
            {caption}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default SuccessBadge
