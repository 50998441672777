import React from 'react'
import { Helmet } from 'react-helmet-async'
import l10n from 'properties/translations'

type Props = {
  title: string
}

const HelmetComponent = ({ title }) => (
  <Helmet
    defaultTitle={l10n.screenTitles.default}
    titleTemplate={`%s | ${l10n.screenTitles.default}`}
  >
    <title>{title}</title>
  </Helmet>
)

const ScreenTitle = ({ title = '' }: Props) => {
  return <HelmetComponent title={title} />
}

export default ScreenTitle
