import React, { useContext } from 'react'
import { get } from 'lodash'
import { Typography, Dialog } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { EMPTY_STRING } from 'properties/properties'
import partnerStatuses from 'constants/enums/partnerStatuses'
import TranslationsContext from 'util/TranslationsContext'
import UserContext from 'util/UserContext'

const styles = theme => ({
  notLiveDialog: {
    '& .MuiPaper-root': { maxWidth: '500px', padding: theme.spacing(10) }
  },
  notLiveTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    fontSize: '18px'
  },
  notLiveDescription: { fontSize: '14px' }
})
const useStyles = makeStyles(styles)

const NotLiveDialog = () => {
  const l10n = useContext(TranslationsContext)
  const { channelPartner } = useContext(UserContext)
  const channelPartnerName = get(channelPartner, 'name', EMPTY_STRING)
  const channelPartnerStatus = get(channelPartner, 'status', EMPTY_STRING)
  const classes = useStyles()

  return (
    <Dialog
      className={classes.notLiveDialog}
      open={channelPartnerStatus !== partnerStatuses.ACTIVE}
      data-testid="not-live-dialog"
    >
      <Typography
        align="center"
        variant="subtitle1"
        className={classes.notLiveTitle}
      >
        {l10n.prequalificationForm.notLiveYetDialog.title}
      </Typography>
      <Typography
        align="center"
        variant="subtitle1"
        className={classes.notLiveDescription}
        data-testid="not-live-description"
      >
        {l10n.prequalificationForm.notLiveYetDialog.body(channelPartnerName)}
      </Typography>
    </Dialog>
  )
}
export default NotLiveDialog
