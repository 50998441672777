// @flow
import React from 'react'
import { Link } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { flow } from 'lodash'
import { Grid, Typography } from '@material-ui/core'
import { Query } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import Button from 'components/shared/Button'
import Page from 'components/templates/Page'
import l10n from 'properties/translations'
import IdentityQuery from 'queries/GetPasswordResetIdentity.graphql'
import Loading from 'components/shared/Loading'
import Storage from 'util/Storage'
import localStorageKeys from 'constants/enums/localStorageKeys'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import Routes from 'util/Routes'
import { EMPTY_STRING } from 'properties/properties'
import ScreenTitle from 'components/shared/ScreenTitle'

import WelcomeRedirect from './WelcomeRedirect'
import styles from '../Admin.styles'

type Props = {
  match: {
    params: {
      code: string
    }
  },
  history: {
    push: Function,
    replace: Function
  },
  classes: any
}

const Welcome = (props: Props) => {
  const { match, history, classes } = props
  const resetCode =
    match.params && match.params.code ? match.params.code : EMPTY_STRING
  const resetCodeDecoded = decodeURIComponent(resetCode)
  const accessToken = Storage.get(localStorageKeys.token)
  if (accessToken) {
    return <WelcomeRedirect />
  }
  // Prevent 'Bad Request' of IdentityQuery on /graphql/user
  if (accessToken) {
    return null
  }

  return (
    <Query query={IdentityQuery} variables={{ token: resetCodeDecoded }}>
      {({ loading, error, data }) => {
        if (error) {
          history.push(Routes.login())
        }
        if (loading || !data) return <Loading />

        return (
          <Page variant="narrowLogo">
            <ScreenTitle title={l10n.screenTitles.welcome} />
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
              spacing={1}
              style={{ textAlign: 'center' }}
              className={classes.welcomeContainer}
            >
              <Grid item>
                <Typography variant="h1" data-testid="admin-title" gutterBottom>
                  {l10n.admin.welcome.title}
                  <strong>{l10n.admin.welcome.siteName}</strong>
                </Typography>
                <Typography
                  variant="body2"
                  gutterBottom
                  className={classes.welcomeBody}
                >
                  {l10n.admin.welcome.body}
                </Typography>
              </Grid>
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  className={classes.button}
                  component={Link}
                  to={Routes.resetPassword(resetCode)}
                >
                  <Typography>
                    {l10n.admin.bankInformation.continueButton}
                  </Typography>
                </Button>
              </Grid>
              <Grid item className={classes.welcomeBody}>
                <Typography variant="caption" gutterBottom>
                  {l10n.admin.welcome.emailNote}
                </Typography>
              </Grid>
            </Grid>
          </Page>
        )
      }}
    </Query>
  )
}

export default flow(
  withLaunchDarkly,
  withApolloConsumer,
  withStyles(styles)
)(Welcome)
