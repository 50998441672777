// @flow

export const financingPlatformOptionsEnum = Object.freeze({
  loan: 'loan',
  lease: 'lease'
})

export type FinancingPlatformOptions = $Values<typeof financingPlatformOptionsEnum>

export default financingPlatformOptionsEnum
