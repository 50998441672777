// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import WatchLater from '@material-ui/icons/WatchLater'
import { Grid, Typography } from '@material-ui/core'
import l10n from 'properties/translations'
import styles from './commonStyles'

type Props = {
  classes: {
    subheading: string
  }
}

const StipResolutionConfirmation = ({ classes }: Props) => (
  <Grid container direction="column" justify="center" alignItems="center">
    <Grid item>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.iconWrapperGray}
      >
        <Grid item>
          <WatchLater className={classes.iconGray} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item className={classes.subheading}>
      <Typography variant="subtitle1" align="center">
        {l10n.apply.formStatus.stipResolutionConfirmation.subheading}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="body2" paragraph>
        {l10n.apply.formStatus.stipResolutionConfirmation.description}
      </Typography>
    </Grid>
  </Grid>
)

export default withStyles(styles)(StipResolutionConfirmation)
