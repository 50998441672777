import React, { useEffect, useRef } from 'react'
import { get } from 'lodash'
import { useApolloClient } from '@apollo/client'
import { CreditStipResolution as CreditStipResolutionScreen } from '@solarmosaic/apply.screens.credit-stip-resolution'
import { useModel } from '@solarmosaic/data-models.hooks.use-model'
import {
  CreditStipResolutionModel,
  UploadDocumentModel
} from '@solarmosaic/data-models.models.p2x'
import { loanDefaultWrapperProps, defaultFooterProps } from 'properties/p3x'
import { API_HOST } from 'properties/properties'
import { getCustomerLocale } from 'util/CustomerHelper'
import { getDeviceFingerprint } from 'util/DeviceHelper'
import { getUserTypeFromProps } from 'util/NavigationHelper'
import { getSessionProps } from 'util/SessionHelper'

const CreditStipResolution = props => {
  const deviceFingerprint = useRef(null)
  const customerId = get(props, 'customer.id')
  const userType = getUserTypeFromProps(props)

  useEffect(() => {
    async function fetchDeviceFingerprint() {
      deviceFingerprint.current = await getDeviceFingerprint()
    }
    fetchDeviceFingerprint()
  }, [])

  const apolloClient = useApolloClient()
  const creditStipResolution = useModel(CreditStipResolutionModel, undefined, {
    apolloClient
  })
  const sessionProps = getSessionProps()
  const uploadDocument = useModel(
    UploadDocumentModel,
    {
      ...sessionProps,
      baseURL: API_HOST
    },
    {
      httpBaseURL: ''
    }
  )

  const dataHandlers = {
    creditStipResolution,
    uploadDocument
  }

  const customer = props.customer
  const locale = getCustomerLocale(customer)

  return (
    <CreditStipResolutionScreen
      customerId={customerId}
      deviceFingerprint={deviceFingerprint.current}
      dataHandlers={dataHandlers}
      wrapperProps={{
        ...loanDefaultWrapperProps(),
        locale,
        footerProps: defaultFooterProps(userType, customer)
      }}
    />
  )
}

export default CreditStipResolution
