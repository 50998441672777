export const phoneFormat = phone => {
  switch (phone.length) {
    case 11:
      return '+# (###) ###-####'
    case 10:
      return '(###) ###-####'
    case 7:
      return '###-####'
    default:
      return null
  }
}

export default phoneFormat
