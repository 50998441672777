// @flow
import React, { useContext } from 'react'
import { Grid, Typography, Paper, Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import Page from 'components/templates/Page'
import pageVariants from 'constants/enums/pageVariants'
import { inIframe } from 'util/EnvironmentHelper'
import { localizedTranslations } from 'properties/translations'
import { detectBrowserLanguageKey } from 'util/CustomerHelper'
import PrequalificationRouteContext from 'util/PrequalificationRouteContext'
import UserContext from 'util/UserContext'
import styles from './Layout.styles'

const useStyles = makeStyles(styles)

const Layout = React.forwardRef(
  ({ renderBackground = false, children: content }, ref) => {
    const classes = useStyles()
    const l10n = localizedTranslations(detectBrowserLanguageKey())
    const { isSwiftlinks } = useContext(PrequalificationRouteContext)
    const { channelPartner } = useContext(UserContext)

    return (
      <Page
        background={renderBackground}
        variant={
          !isSwiftlinks || inIframe()
            ? pageVariants.withoutLogo
            : pageVariants.logoRight
        }
      >
        <div ref={ref}>
          {renderBackground && channelPartner ? (
            <>
              <Grid
                container
                justify="center"
                className={classes.welcomeText}
              />
              {isSwiftlinks && (
                <Box>
                  <Typography
                    variant="caption"
                    className={classes.channelPartnerName}
                  >
                    {l10n.prequalificationForm.welcome(channelPartner.name)}
                  </Typography>
                  <Typography
                    variant="body2"
                    align="center"
                    className={classes.preQualifyText}
                  >
                    {l10n.prequalificationForm.prequalifyExplanation(
                      channelPartner.name
                    )}
                  </Typography>
                </Box>
              )}

              <Paper className={classes.paper}>
                <Grid
                  container
                  direction="column"
                  className={classes.gridContainer}
                >
                  {content}
                </Grid>
              </Paper>
            </>
          ) : (
            <Grid
              container
              direction="column"
              className={classes.gridContainer}
            >
              {content}
            </Grid>
          )}
        </div>
      </Page>
    )
  }
)

export default Layout
