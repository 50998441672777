import { get } from 'lodash'
import {
  disclosureIdentifiersToAssetCodes,
  disclosurePackageTypes
} from 'constants/enums/disclosureIdentifiers'
import languageKeys from 'constants/enums/languageKeys'
import type { CustomerShape } from 'screens/customer/shapes/customer'

import ECOA_ES from 'properties/content/disclosure-packages/assets/ECOA_ES.md'
import ECOA from 'properties/content/disclosure-packages/assets/ECOA.md'
import INCOME_ES from 'properties/content/disclosure-packages/assets/INCOME_ES.md'
import INCOME from 'properties/content/disclosure-packages/assets/INCOME.md'
import ONSCREEN_ES from 'properties/content/disclosure-packages/assets/ONSCREEN_ES.md'
import ONSCREEN_WBBK_ES from 'properties/content/disclosure-packages/assets/ONSCREEN_WBBK_ES.md'
import ONSCREEN_WBBK from 'properties/content/disclosure-packages/assets/ONSCREEN_WBBK.md'
import ONSCREEN from 'properties/content/disclosure-packages/assets/ONSCREEN.md'
import OTHERINC_ES from 'properties/content/disclosure-packages/assets/OTHERINC_ES.md'
import OTHERINC from 'properties/content/disclosure-packages/assets/OTHERINC.md'
import OWN_ES from 'properties/content/disclosure-packages/assets/OWN_ES.md'
import OWN from 'properties/content/disclosure-packages/assets/OWN.md'
import TCPA_1_ES from 'properties/content/disclosure-packages/assets/TCPA_1_ES.md'
import TCPA_1 from 'properties/content/disclosure-packages/assets/TCPA_1.md'
import TCPA_2_ES from 'properties/content/disclosure-packages/assets/TCPA_2_ES.md'
import TCPA_2 from 'properties/content/disclosure-packages/assets/TCPA_2.md'

import homeSoftEn from 'properties/content/disclosure-packages/home-soft-en.json'
import homeSoftEs from 'properties/content/disclosure-packages/home-soft-es.json'
import solarSoftEn from 'properties/content/disclosure-packages/solar-soft-en.json'
import solarSoftEs from 'properties/content/disclosure-packages/solar-soft-es.json'
import zeroSoftEn from 'properties/content/disclosure-packages/zero-soft-en.json'
import zeroSoftEs from 'properties/content/disclosure-packages/zero-soft-es.json'
import customerLoanTypes from 'constants/enums/customerLoanTypes'
import { DEFAULT_LANGUAGE_KEY, EMPTY_OBJECT } from 'properties/properties'

const assetCodeToFile = {
  ECOA_ES,
  ECOA,
  INCOME_ES,
  INCOME,
  ONSCREEN_ES,
  ONSCREEN_WBBK_ES,
  ONSCREEN_WBBK,
  ONSCREEN,
  OTHERINC_ES,
  OTHERINC,
  OWN_ES,
  OWN,
  TCPA_1_ES,
  TCPA_1,
  TCPA_2,
  TCPA_2_ES,
}

export const getDisclosureFromPackage = (disclosurePackage, query) => {
  const foundDisclosure = disclosurePackage.disclosures.find(pkg => {
    const { disclosureIdentifier, disclosureAssetType } = query
    if (disclosureIdentifier) {
      const potentialMatches =
        disclosureIdentifiersToAssetCodes[disclosureIdentifier]
      if (!potentialMatches) return null
      return potentialMatches.includes(pkg.assetCode)
    } else if (disclosureAssetType) {
      return pkg.assetType === disclosureAssetType
    }
    return null
  })
  if (foundDisclosure) {
    return assetCodeToFile[foundDisclosure.assetCode]
  }
  return null
}

const readDisclosurePackage = (disclosurePackage, languageKey) => {
  switch (disclosurePackage) {
    case disclosurePackageTypes.solarSoft:
      if (languageKey === languageKeys.es) return solarSoftEs
      return solarSoftEn
    case disclosurePackageTypes.zeroSoft:
      if (languageKey === languageKeys.es) return zeroSoftEs
      return zeroSoftEn
    case disclosurePackageTypes.homeSoft:
    default:
      if (languageKey === languageKeys.es) return homeSoftEs
      return homeSoftEn
  }
}

const getDisclosurePackage = loanType => {
  switch (loanType) {
    case customerLoanTypes.solar:
      return disclosurePackageTypes.solarSoft
    case customerLoanTypes.zero:
      return disclosurePackageTypes.zeroSoft
    case customerLoanTypes.home:
    default:
      return disclosurePackageTypes.homeSoft
  }
}

export const getDisclosurePackageForCustomerResponse = (
  customerResponse: CustomerShape,
  channelPartner: Object
): string => {
  const defaultLoanType = get(
    channelPartner,
    'projectConfiguration.defaultLoanType',
    EMPTY_OBJECT
  )
  const pkg = getDisclosurePackage(
    get(customerResponse, 'loanType', defaultLoanType)
  )
  const language = get(customerResponse, 'language', DEFAULT_LANGUAGE_KEY)
  return readDisclosurePackage(pkg, language)
}

const DisclosuresHelper = {
  getDisclosureFromPackage,
  getDisclosurePackageForCustomerResponse
}

export default DisclosuresHelper
