import { gql } from 'graphql.macro'

export default gql`
  mutation ValidateChallenge(
    $customerId: String!
    $validateSsnRequest: CustomerSsnValidationRequest!
  ) {
    validateChallenge(
      customerId: $customerId
      validateRequest: $validateSsnRequest
    )
  }
`
