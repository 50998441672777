import { colors } from 'themes/default'

const BUBBLE_SIZE = 10

export default theme => ({
  bubble: {
    width: BUBBLE_SIZE,
    height: BUBBLE_SIZE,
    backgroundColor: theme.palette.common.background,
    borderRadius: '50%',
    fill: `${theme.palette.grey['600']}`,
    stroke: `${theme.palette.grey['600']}`
  },
  notClickableBubble: {
    fill: `${theme.palette.grey['600']} !important`,
    stroke: `${theme.palette.grey['600']} !important`,
    marginRight: theme.spacing(3),
    '@media (max-width: 720px)': {
      marginRight: theme.spacing(1)
    }
  },
  smallBubble: {
    width: BUBBLE_SIZE / 2,
    height: BUBBLE_SIZE / 2,
    fill: theme.palette.grey['400'],
    stroke: theme.palette.grey['400'],
    marginTop: BUBBLE_SIZE / 4,
    marginBottom: BUBBLE_SIZE / 4
  },
  notClickableSmallBubble: {
    '@media (max-width: 720px)': {
      marginRight: theme.spacing(1)
    }
  },
  bubbleImgSmall: {
    width: BUBBLE_SIZE,
    height: BUBBLE_SIZE,
    backgroundColor: theme.palette.common.background,
    borderRadius: '50%',
    margin: BUBBLE_SIZE / 2,
    fill: theme.palette.primary.main,
    stroke: theme.palette.primary.main
  },
  nodeContainer: {
    width: '50%',
    marginRight: '25%',
    marginLeft: '25%'
  },
  pipelineBubble: {
    padding: theme.spacing(2),
    justifyContent: 'center',
    '&:hover': {
      backgroundColor: 'transparent !important',
      cursor: 'default'
    }
  },
  block: {
    cursor: 'pointer',
    display: 'inline-block',
    borderRadius: 0,
    textAlign: 'center',
    '&:hover': {
      backgroundColor: theme.palette.grey['300']
    }
  },
  blockDisabled: {
    cursor: 'default',
    display: 'inline-block',
    borderRadius: 0,
    paddingTop: theme.spacing(1.5),
    height: theme.spacing(6),
    textAlign: 'center',
    '&:hover': {
      backgroundColor: 'transparent'
    }
  },
  blockSmall: {
    display: 'inline-block',
    borderRadius: 0,
    paddingTop: theme.spacing(2),
    height: theme.spacing(6),
    textAlign: 'center',
    zIndex: 10,
    position: 'relative'
  },
  active: {
    width: BUBBLE_SIZE * 1.5,
    height: BUBBLE_SIZE * 1.5,
    marginRight: 0,
    marginLeft: 0,
    position: 'relative',
    top: -2
  },
  focus: {
    '&:focus': {
      outline: `${colors.primary} solid 1px`
    }
  }
})
