// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import get from 'lodash/get'
import { Grid, Button, Typography } from '@material-ui/core'
import { ERROR_ICON_SIZE } from 'properties/properties'
import l10n from 'properties/translations'
import { suppressErrorMessage } from 'util/MessageHelper'
import { errorFallbackRoute } from 'util/Routes'
import defaultTheme, { colors } from 'themes/default'
import coreScreenStyles from 'themes/coreScreenStyles'
import ErrorStyles from './Error.styles'

const styles = theme => ({
  ...coreScreenStyles(theme),
  ...ErrorStyles(theme)
})

const errorIconProps = classes => ({
  nativeColor: defaultTheme(colors).palette.error.light,
  // For whatever reason it's better to pass this in directly in MUI < 3
  style: { fontSize: ERROR_ICON_SIZE },
  className: classes.icon
})

const handleClickGoBack = history => () => {
  if (history.length <= 2) {
    history.replace(errorFallbackRoute())
  } else {
    history.goBack()
  }
}

const handleClickRefresh = () => {
  window.location.reload()
}

type Props = {
  error: string | { message: string },
  errorMessage: ?string,
  errorTitle: string,
  errorIcon: Function,
  headerPadding: boolean,
  showRefreshButton: boolean,
  errorCode: ?string,
  classes: {
    container: string,
    marginHeader: string,
    titleHeader: string,
    icon: string,
    alignErrorDescription: string,
    errorTitleDescription: string,
    errorTextDescription: string,
    goBackText: string
  },
  history: ?{
    length: number,
    goBack: Function,
    replace: Function
  }
}

const Error = ({
  headerPadding = false,
  classes,
  error = {},
  errorTitle = l10n.shared.error,
  errorMessage = null,
  errorIcon = null,
  errorCode = null,
  history = null,
  showRefreshButton = false
}: Props) => {
  const message = errorMessage || get(error, 'message', '')
  if (suppressErrorMessage(message)) return null

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      spacing={2}
      direction="column"
      className={cx({
        [classes.marginHeader]: headerPadding,
        [classes.alignErrorDescription]: true
      })}
      data-testid="error"
    >
      <Grid item>
        <Typography
          data-testid="error-title"
          variant="h1"
          align="center"
          className={cx(classes.titleHeader, classes.errorTitleDescription)}
        >
          {errorTitle}
        </Typography>
      </Grid>
      {errorIcon && (
        <Grid item>
          {React.createElement(
            errorIcon,
            errorIconProps({ icon: classes.icon })
          )}
        </Grid>
      )}
      <Grid item>
        <Typography
          data-testid="error-message"
          align="center"
          variant="subtitle1"
          className={classes.errorTextDescription}
        >
          {l10n.customer.customerNotFoundError}
        </Typography>
      </Grid>
      {history && !showRefreshButton && (
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClickGoBack(history)}
          >
            <Typography className={classes.goBackText}>
              {l10n.shared.errorGoBack}
            </Typography>
          </Button>
        </Grid>
      )}

      {history && showRefreshButton && (
        <Grid item>
          <Button
            color="primary"
            variant="outlined"
            onClick={handleClickRefresh}
          >
            <Typography>{l10n.prequalificationForm.refreshLabel}</Typography>
          </Button>
        </Grid>
      )}

      {errorCode && (
        <Grid item>
          <Typography variant="caption">{errorCode}</Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default withStyles(styles)(Error)
