export default {
  fullName: 'fullName',
  firstName: 'firstName',
  middleName: 'middleName',
  lastName: 'lastName',
  email: 'email',
  emailAddress: 'emailAddress',
  assignedTo: 'assignedTo',
  phoneNumber: 'phoneNumber',
  phoneNumberAlt: 'phoneNumberAlt1',
  projectAddressType: 'projectAddressType',
  street: 'street',
  addresses: 'addresses',
  addressState: 'address.stateAbbreviation',
  addressStreet: 'address.street',
  addressCity: 'address.city',
  addressZip: 'address.zip',
  city: 'city',
  zip: 'zip',
  notes: 'notes',
  stateAbbreviation: 'stateAbbreviation',
  dateModified: 'dateModified',
  ssn: 'ssn',
  verifySsn: 'verifySsn',
  last4Ssn: 'last4Ssn',
  personalIncome: 'personalIncome',
  disclosureAccepted: 'disclosureAccepted',
  dobMonth: 'dobMonth',
  dobDay: 'dobDay',
  dobYear: 'dobYear',
  dobFull: 'dobFull',
  showMailingAddress: 'showMailingAddress',
  salespersonId: 'salespersonId',
  otherHouseholdIncome: 'otherHouseholdIncome',
  titleAttestation: 'titleAttestation',
  primaryStreet: 'PRIMARY.street',
  primaryCity: 'PRIMARY.city',
  primaryStateAbbreviation: 'PRIMARY.stateAbbreviation',
  primaryZip: 'PRIMARY.zip',
  projectSecondHomeStreet: 'PROJECT_SECOND_HOME.street',
  projectSecondHomeCity: 'PROJECT_SECOND_HOME.city',
  projectSecondHomeStateAbbreviation: 'PROJECT_SECOND_HOME.stateAbbreviation',
  projectSecondHomeZip: 'PROJECT_SECOND_HOME.zip',
  projectRentalStreet: 'PROJECT_RENTAL.street',
  projectRentalCity: 'PROJECT_RENTAL.city',
  projectRentalStateAbbreviation: 'PROJECT_RENTAL.stateAbbreviation',
  projectRentalZip: 'PROJECT_RENTAL.zip',
  primaryApplicantEmailAddress: 'primaryApplicant.emailAddress',
  primaryApplicantPhoneNumber: 'primaryApplicant.phoneNumber'
}
