import { gql } from 'graphql.macro'

export default gql`
  query getClientIds($channelPartnerId: String!) {
    clientIds {
      launchDarkly
    }
    channelPartner(channelPartnerId: $channelPartnerId) {
      id
      name
      logoUrl
      theme {
        primaryColor
        secondaryColor
        headerColor
      }
    }
  }
`
