// @flow
import React, { Component, type Node } from 'react'
import {
  IconButton,
  Grid,
  Typography,
  Input,
  InputAdornment
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Edit from '@material-ui/icons/Edit'
import { TextValidator } from 'react-material-ui-form-validator'
import NumberFormat from 'react-number-format'

import TranslationsContext from 'util/TranslationsContext'
import {
  EMPTY_STRING,
  NAME_LENGTH,
  SPACE_SEPARATOR
} from 'properties/properties'
import fieldMasks from 'constants/enums/fieldMasks'
import formValidatorValidators from 'constants/enums/formValidatorValidators'
import customerFields from 'constants/enums/customerFields'
import { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import LockedInputIcon from 'components/shared/LockedInputIcon'
import get from 'lodash/get'
import omit from 'lodash/omit'

const MAX_COLS = 12
const SPACING = 0

const styles = theme => ({
  lockedFieldBG: {
    backgroundColor: theme.palette.background.lockedTextFieldColor,
    borderRadius: 4,
    padding: '0.5em 0 0.5em 0.5em',
    borderBottomWidth: 0,
    borderBottomStyle: 'none'
  },
  lockedFieldContainer: {
    padding: theme.spacing(1)
  },
  lockedFieldText: {
    color: theme.palette.common.black
  },
  lockedFieldIcon: {
    width: 18,
    height: 18,
    color: theme.palette.common.teal
  },
  labelText: {
    fontWeight: 400
  }
})

const isPhoneNumberField = fieldName => fieldName === customerFields.phoneNumber

export const filterCustomerInfoValidators = (
  validators: Array<string>,
  fieldName: string,
  fieldValue: string,
  fieldset: string
): Array<string> => {
  const fieldsetAboutOrSinglePagePrequal = [
    fieldsetNames.FIELDSET_ABOUT,
    fieldsetNames.FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL
  ].includes(fieldset)

  let filteredResult = validators

  const isNameLengthRule = rule => rule !== NAME_LENGTH[0]

  if (fieldName === customerFields.middleName) {
    filteredResult = filteredResult.filter(isNameLengthRule)
  }

  if (!fieldsetAboutOrSinglePagePrequal) return filteredResult

  if (
    fieldsetAboutOrSinglePagePrequal &&
    (fieldName === customerFields.firstName || customerFields.lastName) &&
    !fieldValue
  ) {
    filteredResult = filteredResult.filter(isNameLengthRule)
  }
  return filteredResult
}

const safePropsForHTMLInput = fieldProps =>
  omit(fieldProps, ['disableUnderline'])

type Props = {
  classes: any,
  locked: boolean,
  disabled: boolean,
  fieldInputProps: Object,
  fieldLabels: Object,
  fieldValues: Object,
  fieldNames: Object,
  fieldAutocompleteKeys: Object,
  unifyWhenLocked: boolean,
  unifyWith: string,
  unifiedLabel: string,
  inputComponent: Node,
  onFieldEdit: Function,
  parentClasses: {
    gridItemTop: string
  },
  unlockable: boolean,
  onRequestUnlock: Function,
  requiredFields: string[],
  InputLabelProps: Object,
  InputProps: Object,
  validators: Object,
  errorMessages: Object,
  fieldHelperTexts: Object,
  fieldset: Object,
  maxCols: { xs: number, md: number },
  unlockedGridItemProps: any
}
class LockableField extends Component<Props> {
  static defaultProps = {
    fieldAutocompleteKeys: null,
    unifyWhenLocked: false,
    unifyWith: SPACE_SEPARATOR,
    inputComponent: null,
    unifiedLabel: EMPTY_STRING,
    unlockable: false,
    onRequestUnlock: null,
    requiredFields: [],
    disabled: false,
    InputLabelProps: {},
    InputProps: {},
    validators: [],
    errorMessages: [],
    unlockedGridItemProps: {}
  }
  static contextType = TranslationsContext

  getErrorMessagesRequired = (field, l10n) => {
    const isLastName = field === customerFields.lastName
    if (isLastName) {
      return this.isRequiredField(field)
        ? [
            l10n.formFields.required,
            l10n.customer.errorMessages.lastNameErrorMessage
          ]
        : [l10n.customer.errorMessages.lastNameErrorMessage]
    }
    return this.isRequiredField(field) ? [l10n.formFields.required] : []
  }

  getValidatorsRequired = field => {
    const isLastName = field === customerFields.lastName
    if (isLastName) {
      return this.isRequiredField(field)
        ? [
            formValidatorValidators.required,
            formValidatorValidators.isValidLastName
          ]
        : [formValidatorValidators.isValidLastName]
    }
    return this.isRequiredField(field) ? [formValidatorValidators.required] : []
  }
  isRequiredField = field => this.props.requiredFields.includes(field)

  fieldInputProps = fieldName => {
    const { fieldInputProps } = this.props
    const defaultProps = {}

    if (!fieldInputProps) return defaultProps

    return fieldInputProps[fieldName] || defaultProps
  }

  render() {
    const {
      locked,
      disabled,
      onRequestUnlock = null,
      fieldLabels,
      fieldValues,
      fieldNames,
      fieldHelperTexts = {},
      fieldAutocompleteKeys = {},
      unifyWhenLocked = false,
      unifyWith = ' ',
      unifiedLabel,
      onFieldEdit,
      parentClasses,
      classes,
      inputComponent = null,
      InputLabelProps,
      InputProps,
      validators,
      errorMessages,
      fieldset,
      unlockedGridItemProps,
      maxCols = {
        xs: MAX_COLS,
        md: MAX_COLS
      }
    } = this.props
    const l10n = this.context

    if (locked) {
      const lockedProps = {
        classes: {
          root: classes.lockedFieldBG,
          input: classes.lockedFieldText
        },
        fullWidth: true,
        disabled: true,
        endAdornment: (
          <InputAdornment>
            <IconButton
              onClick={onRequestUnlock}
              disabled={!onRequestUnlock || disabled}
            >
              {onRequestUnlock && !disabled ? (
                <Edit classes={{ root: classes.lockedFieldIcon }} />
              ) : (
                <LockedInputIcon classes={{ root: classes.lockedFieldIcon }} />
              )}
            </IconButton>
          </InputAdornment>
        )
      }
      const lockedInputProps = { disableUnderline: true }

      return (
        <Grid
          container
          classes={
            !isPhoneNumberField(fieldNames.phoneNumber)
              ? {
                  container: classes.lockedFieldContainer
                }
              : {}
          }
          spacing={SPACING}
        >
          {unifyWhenLocked ? (
            <Grid item xs={maxCols.xs} md={maxCols.md}>
              <Typography
                variant="caption"
                gutterBottom
                className={classes.labelText}
              >
                {unifiedLabel}
              </Typography>
              {isPhoneNumberField(fieldValues[0]) ? (
                <NumberFormat
                  {...lockedProps}
                  customInput={Input}
                  format={fieldMasks.phoneNumber}
                  value={Object.values(fieldValues).join(unifyWith)}
                  disabled
                  data-testid={fieldValues[0]}
                  InputProps={lockedInputProps}
                />
              ) : (
                <Input
                  {...lockedProps}
                  disabled
                  value={Object.values(fieldValues).join(unifyWith)}
                  InputProps={lockedInputProps}
                />
              )}
            </Grid>
          ) : (
            Object.keys(fieldNames).map(fieldName => (
              <Grid key={fieldName} item xs {...unlockedGridItemProps}>
                <Typography
                  variant="caption"
                  gutterBottom
                  className={classes.labelText}
                >
                  {fieldLabels[fieldName]}
                </Typography>
                {isPhoneNumberField(fieldName) ? (
                  <NumberFormat
                    {...lockedProps}
                    customInput={Input}
                    format={fieldMasks.phoneNumber}
                    value={fieldValues[fieldName]}
                    disabled
                    data-testid={fieldName}
                    InputProps={lockedInputProps}
                  />
                ) : (
                  <Input
                    {...lockedProps}
                    {...lockedInputProps}
                    disabled
                    value={fieldValues[fieldName]}
                  />
                )}
              </Grid>
            ))
          )}
        </Grid>
      )
    }

    return (
      <>
        {Object.keys(fieldNames).map(fieldName => (
          <Grid
            key={fieldName}
            item
            xs
            className={parentClasses.gridItemTop}
            {...unlockedGridItemProps}
          >
            {inputComponent || (
              <TextValidator
                fullWidth
                id={fieldName}
                label={l10n.customer[fieldName]}
                onChange={onFieldEdit(fieldName)}
                name={fieldName}
                value={fieldValues[fieldName] || EMPTY_STRING}
                autoComplete={
                  fieldAutocompleteKeys
                    ? fieldAutocompleteKeys[fieldName]
                    : null
                }
                validators={[
                  ...this.getValidatorsRequired(fieldName),
                  ...filterCustomerInfoValidators(
                    validators,
                    fieldName,
                    fieldValues[fieldName] || EMPTY_STRING,
                    fieldset
                  )
                ]}
                errorMessages={[
                  ...this.getErrorMessagesRequired(fieldName, l10n),
                  ...errorMessages
                ]}
                required={this.isRequiredField(fieldName)}
                disabled={disabled}
                helperText={get(fieldHelperTexts, fieldName)}
                InputLabelProps={InputLabelProps}
                inputProps={{
                  'data-testid': fieldName,
                  ...safePropsForHTMLInput(this.fieldInputProps(fieldName))
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  disableUnderline: disabled,
                  ...InputProps,
                  ...this.fieldInputProps(fieldName)
                }}
              />
            )}
          </Grid>
        ))}
      </>
    )
  }
}

export default withStyles(styles)(LockableField)
