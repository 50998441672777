// @flow
import * as React from 'react'
import { NavLink } from 'react-router-dom'
import isEmpty from 'lodash/isEmpty'
import { Typography } from '@material-ui/core'

type Props = {
  disabled: boolean,
  className: string,
  children: React.Node,
  typographyProps: null | {
    variant: string
  },
  to: any
}

const LinkComponent = ({ disabled, typographyProps, ...props }: Props) => {
  if (disabled) {
    return (
      <div className={props.className}>
        {typographyProps && !isEmpty(typographyProps) ? (
          <Typography {...typographyProps}>{props.children}</Typography>
        ) : (
          props.children
        )}
      </div>
    )
  }
  return typographyProps && !isEmpty(typographyProps) ? (
    <Typography {...typographyProps}>
      <NavLink {...props} />
    </Typography>
  ) : (
    <NavLink {...props} />
  )
}

LinkComponent.defaultProps = {
  typographyProps: null
}

export default LinkComponent
