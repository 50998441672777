import { colors } from 'themes/default'

// eslint-disable-next-line no-unused-vars
export default theme => ({
  noLoanProductsRow: {
    fontSize: '0.75rem',
    padding: 18,
    marginTop: 8,
    marginBottom: 6,
    boxShadow: 'none',
    border: '1px solid rgba(203,36,49,0.5)',
    borderRadius: 6,
    textAlign: 'center',
    color: colors.errorRed
  }
})
