// @flow
import React from 'react'
import { isEmpty } from 'lodash'
import { Grid, Select } from '@material-ui/core'
import AutoCompleteComboBox from 'components/shared/AutoCompleteComboBox'
import { EMPTY_STRING } from 'properties/properties'
import l10n from 'properties/translations'
import MobileInfo from 'util/MobileInfo'
import type { CustomerProjectConfiguration } from 'screens/quote/QuoteTypes'

const { supportsTouchEvents } = MobileInfo

type Props = {
  addStage: Object,
  availableProjectTypes: Array<Object>,
  handleChange: Function,
  handleChangeProps: Object,
  isProjectTypeCustomization: boolean,
  isSolarFlexFlagOn: boolean,
  projectTypeKey: string,
  projectType: number | string,
  readonly: ?boolean,
  isCustom: ?boolean,
  updateProjectFn: Function,
  customerProjectConfiguration?: CustomerProjectConfiguration
}

const buildProjectTypes = ({
  availableProjectTypes,
  isProjectTypeCustomization,
  customerProjectConfiguration
}: Props) =>
  availableProjectTypes.map(project => {
    if (isProjectTypeCustomization && !project.enabled) {
      return null
    }

    const selectContents =
      isProjectTypeCustomization || customerProjectConfiguration
        ? project.projectTypeDisplayName
        : project.projectTypeText

    return supportsTouchEvents ? (
      <option key={project.id} value={project.id}>
        {selectContents}
      </option>
    ) : (
      {
        title: selectContents,
        id: project.id,
        ...project
      }
    )
  })

const onProjectTypeChange = (event: any, newValue: any, props: Props): void => {
  const {
    handleChange,
    handleChangeProps,
    updateProjectFn,
    projectTypeKey,
    addStage
  } = props

  handleChange(
    handleChangeProps,
    updateProjectFn,
    projectTypeKey,
    addStage,
    newValue && newValue.id
  )(event && event.persist())
}

const ProjectTypeSelect = (props: Props) => {
  const {
    addStage,
    handleChange,
    handleChangeProps,
    projectType,
    projectTypeKey,
    readonly,
    updateProjectFn,
    isCustom = false
  } = props
  const options = buildProjectTypes(props).filter(project => project != null)
  const selectedOption = options.find(option => option.id === projectType)

  return (
    <Grid data-testid="project-type-select">
      {supportsTouchEvents || isEmpty(options) ? (
        <Select
          fullWidth
          native
          value={projectType}
          onChange={handleChange(
            handleChangeProps,
            updateProjectFn,
            projectTypeKey,
            addStage
          )}
          disabled={readonly}
          displayEmpty
        >
          <option value={EMPTY_STRING} disabled>
            {l10n.quote.projectRow.projectTypePlaceholder}
          </option>
          {options}
        </Select>
      ) : (
        <AutoCompleteComboBox
          isAutoFocus={isCustom}
          options={options}
          onChangeDropdown={(event, newEvent) =>
            onProjectTypeChange(event, newEvent, props)
          }
          selectedOption={selectedOption}
          displayKey="title"
          placeholder="Select"
        />
      )}
    </Grid>
  )
}

export default ProjectTypeSelect
