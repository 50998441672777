import { gql } from 'graphql.macro'

export default gql`
  query getChannelPartnerContacts {
    partnerContacts {
      primaryContact {
        phone
        name
        email
      }
      secondaryContact {
        phone
        name
        email
      }
    }
  }
`
