import get from 'lodash/get'
import { getEnvironment } from 'util/EnvironmentHelper'
import backendEnvironments from 'constants/enums/backendEnvironments'
import { isEmpty } from 'lodash'
import { EMPTY_STRING } from 'properties/properties'

let autocompleteService
let placesService
let loading
const loadjs = require('loadjs')

const getGooglePlacesTokenBasedOnEnvironment = googlePlacesTokenObject => {
  const { local, qa } = backendEnvironments
  const environmentName = getEnvironment()
  if (googlePlacesTokenObject) {
    if (environmentName === local) {
      return googlePlacesTokenObject[qa]
    } else {
      return googlePlacesTokenObject[environmentName]
    }
  }
  return null
}

const googlePlacesFetchAddressUrl = (text, googlePlacesToken, lang) => {
  return `https://maps.googleapis.com/maps/api/geocode/json?address=${text}&components=&language=${lang}&key=${googlePlacesToken}`
}

const googlePlacesApiUrl = googlePlacesToken =>
  `https://maps.googleapis.com/maps/api/js?key=${googlePlacesToken}&libraries=places&language=en&`

export const initService = googlePlacesTokenObject => {
  const googlePlacesToken = getGooglePlacesTokenBasedOnEnvironment(
    googlePlacesTokenObject
  )
  return new Promise((resolve, reject) => {
    if (autocompleteService || loading) return resolve()

    loadjs(googlePlacesApiUrl(googlePlacesToken), {
      success: () => {
        const places = get(window, 'google.maps.places')
        if (places) {
          autocompleteService = new places.AutocompleteService()
          placesService = new places.PlacesService(
            window.document.createElement('div')
          )
        }
        resolve()
      },
      error: () => {
        reject()
      }
    })
    loading = true
    return null
  })
}

export const initPlacesService = () => {
  if (!placesService) {
    placesService = new window.google.maps.places.PlacesService(
      window.document.createElement('div')
    )
  }
}

export const getGeoSuggestions = async (address, lang, types) => {
  if (!autocompleteService) await initService()
  return new Promise((resolve, reject) => {
    const options = {
      input: address,
      componentRestrictions: { country: 'usa' },
      language: lang,
      types
    }
    autocompleteService.getPlacePredictions(options, (predictions, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK)
        reject(status)
      else resolve(predictions)
    })
  })
}

export const getPlaceDetails = (placeid, lang) => {
  if (!placesService) initPlacesService()
  return new Promise((resolve, reject) => {
    const options = {
      placeId: placeid,
      language: lang
    }
    placesService.getDetails(options, (data, status) => {
      if (status !== window.google.maps.places.PlacesServiceStatus.OK) {
        reject(status)
      } else {
        resolve(data)
      }
    })
  })
}

const getAddressValue = (data, typeNameArray, propertyName) => {
  if (data.address_components) {
    const filtered = data.address_components.filter(
      item => item.types.filter(type => typeNameArray.includes(type)).length > 0
    )
    return get(filtered, ['0', propertyName])
  }
  return undefined
}

const getAddress = data => {
  const house = getAddressValue(data, ['street_number'], 'long_name')
  const street = getAddressValue(data, ['route'], 'long_name')
  return [house, street].join(' ').trim()
}

export const getAddressParts = data => {
  const address = getAddress(data)
  const city = getAddressValue(data, ['locality', 'sublocality'], 'long_name')
  const zip = getAddressValue(data, ['postal_code'], 'long_name')
  const state = getAddressValue(
    data,
    ['administrative_area_level_1'],
    'short_name'
  )
  if (isEmpty(address) || isEmpty(city) || isEmpty(zip) || isEmpty(state)) {
    return {
      address: EMPTY_STRING,
      city: EMPTY_STRING,
      zip: EMPTY_STRING,
      state: EMPTY_STRING
    }
  }
  return {
    address,
    city,
    zip,
    state
  }
}

export const getFullAddressByPlaceId = placeid =>
  getPlaceDetails(placeid).then(data => getAddressParts(data))

export const getFullAddressByText = async (
  text,
  googlePlacesTokenObject,
  lang = 'en'
) => {
  const googlePlacesToken = getGooglePlacesTokenBasedOnEnvironment(
    googlePlacesTokenObject
  )
  const data = await fetch(
    googlePlacesFetchAddressUrl(text, googlePlacesToken, lang)
  )

  const { results } = await data.json()
  return getAddressParts(results[0])
}
