// @flow
import React, { Component, useEffect, useContext } from 'react'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { debounce, get, head, flow } from 'lodash'
import NumberFormat from 'react-number-format'
import isEmpty from 'lodash/isEmpty'
import { Grid, Typography, Button } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import cx from 'classnames'
import l10n from 'properties/translations'
import {
  DOLLAR_PREFIX,
  EMPTY_ARRAY,
  EMPTY_OBJECT,
  EMPTY_STRING
} from 'properties/properties'
import { getDeviceFingerprint } from 'util/DeviceHelper'
import contractStatuses from 'constants/enums/contractStatuses'
import { getProductsFieldName } from 'util/LoanProductHelper'
import { isBorrower } from 'util/CustomerHelper'
import UpdateQuote from 'queries/UpdateQuote.graphql'
import * as AnalyticsHelper from 'util/AnalyticsHelper'
import { eventTypes } from 'constants/enums/analyticsEventTypes'
import { bodyTextGreen, bodyTextNormal, buttonMinWidth } from 'themes/default'
import {
  selectProjectsDataForMutation,
  getSimilarLoanProduct,
  getMatchingLoanProduct,
  approvedForLessThanProject,
  getApprovedFinancingAmount
} from 'util/ApplyHelper'
import errorTypes from 'constants/enums/errorTypes'
import Routes from 'util/Routes'
import { makeSafeSessionStorage } from 'util/makeSafeStorage'
import { inIframe } from 'util/EnvironmentHelper'
import {
  clearCustomerIdInStorage,
  clearEnteredCustomer
} from 'util/PrequalificationHelper'
import { moveAchBeforeLoanAgreementFlagEnabled } from 'util/FeatureHelper'
import applySections from 'constants/enums/applySections'
import emailVerificationSessionStore from 'constants/enums/emailVerificationSessionStore'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'
import { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import GetCreditApplicationWithDiscountedTerms from 'screens/apply/queries/GetCreditApplicationWithDiscountedTerms.graphql'
import ApplyContext from 'util/ApplyContext'
import UserContext from 'util/UserContext'
import TranslationsContext from 'util/TranslationsContext'
import { contractExists } from 'util/ContractCreationHelper'
import ProjectEstimate from './ProjectEstimate'
import styles from './commonStyles'
import GetCreditApplication from '../queries/GetCreditApplication.graphql'

const safeSessionStorage = makeSafeSessionStorage()

const customStyles = theme => ({
  ...styles(theme),
  salesmanName: {
    fontWeight: 700,
    marginBottom: theme.spacing(1)
  },
  salesmanEmail: {
    marginBottom: theme.spacing(1),
    color: '#0e619c'
  },
  handBackCommon: {
    textAlign: 'center',
    fontWeight: 400
  },
  handBackButton: {
    fontWeight: 600,
    marginTop: 8,
    padding: `0 ${theme.spacing(2)}px`
  },
  sectionCtn: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      paddingTop: '6px'
    },
    '& p': {
      marginLeft: theme.spacing(1)
    }
  },
  totalMonthlyPayment: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      paddingTop: '6px'
    },
    '& p': {
      marginLeft: theme.spacing(1)
    }
  },
  totalMonthlyPaymentColor: {
    color: theme.palette.common.successGreen
  },
  approvedText: {
    marginTop: theme.spacing(2)
  },
  totalMonthlyPaymentAmountColor: {
    color: theme.palette.common.successGreen
  },
  row: {
    padding: 18,
    marginBottom: 6,
    boxShadow: 'none',
    border: '2px solid #eee',
    cursor: 'pointer',
    borderRadius: 6,
    textAlign: 'center'
  },
  rowSelected: {
    backgroundColor: theme.palette.secondary.light,
    border: `2px solid ${theme.palette.secondary.main}`
  },
  rowReadonly: {
    border: '1px dashed rgba(0, 0, 0, 0.3) !important',
    cursor: 'default'
  },
  rowReadonlyAndSelected: {
    backgroundColor: theme.palette.grey['200']
  },
  productNumberText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'lowercase',
    '& span': {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  productNumberTextBold: {
    fontSize: 14,
    textTransform: 'lowercase',
    lineHeight: 1.2,
    '& span': {
      fontSize: 16
    }
  },
  productNumberReadonly: {
    color: theme.palette.grey['500']
  },
  section: {
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.palette.black,
    marginBottom: 14
  },
  borrowerContactInstaller: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: theme.palette.grey['400']
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  spaceOnTop: {
    marginTop: theme.spacing(2)
  },
  productNameBottomSpace: {
    marginBottom: theme.spacing(1)
  },
  unavailableRow: {
    padding: 30,
    marginBottom: 6,
    boxShadow: 'none',
    border: '2px solid #eee',
    cursor: 'pointer',
    borderRadius: 6,
    textAlign: 'center'
  },
  serviceSelectBottomSpace: {
    marginBottom: theme.spacing(-1)
  },
  labelTitle: {
    paddingTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    textAlign: 'center'
  },
  bodyTextGreen: {
    fontSize: bodyTextGreen.fontSize,
    fontWeight: bodyTextGreen.fontWeight,
    lineHeight: bodyTextGreen.lineHeight,
    color: bodyTextGreen.color
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  button: {
    ...buttonMinWidth
  }
})

export const projectEstimate = quote =>
  get(quote, 'projects', []).reduce((sum, p) => p.amount + sum, 0)

type Props = {
  classes: {
    icon: string,
    iconWrapper: string,
    subheading: string,
    textSuccess: string,
    sectionCtn: string
  },
  quote: {
    loanProductId: string | number,
    loanAmount: number,
    projects: [
      {
        id: string,
        amount: number
      }
    ]
  },
  creditApplication: {
    financingLimit: number,
    decision: { loanProductId: string | number }
  } | null,
  onEditOffer: () => {},
  onSubmitStart: () => {},
  lender: string,
  customerId: string,
  contractStatus: string | null,
  client: { mutate: () => {} },
  isProductLoading: Boolean,
  ldFlags: Object,
  contract: Object,
  isSubmitting: boolean
}

type State = {
  errorMessage: string
}

const SuccessMessage = props => {
  const { classes, text, quote, creditApplication, ldFlags, isSwiftlinks } =
    props

  const l10n = useContext(TranslationsContext)

  useEffect(() => {
    // On Successful Swiftlinks application, clear cookies and session storage
    // Do not log out user.
    if (isSwiftlinks && !inIframe()) {
      clearEnteredCustomer()
      clearCustomerIdInStorage()
    }
  }, [isSwiftlinks])

  return (
    <Typography
      variant="subtitle1"
      align="center"
      className={classes.bodyTextGreen}
      paragraph
    >
      <NumberFormat
        value={getApprovedFinancingAmount(quote, creditApplication, ldFlags)}
        renderText={() => (
          <>
            {l10n.apply.formStatus.approved.financingText}
            <SuperScriptAmount price={text} />
          </>
        )}
        prefix={DOLLAR_PREFIX}
        displayType="text"
        thousandSeparator
      />
    </Typography>
  )
}

class ApprovedContent extends Component<Props, State> {
  static defaultProps = {
    quote: {},
    creditApplication: null,
    contractStatus: null
  }

  static getDerivedStateFromProps = (
    { resetErrorMessage },
    { errorMessage }
  ) => ({
    errorMessage: resetErrorMessage ? '' : errorMessage
  })

  state = {
    errorMessage: ''
  }

  componentDidMount = async () => {
    const { quote, creditApplication, ldFlags, contract, me } = this.props

    const loanProduct =
      getMatchingLoanProduct({ creditApplication }, quote, ldFlags) ||
      getSimilarLoanProduct({ creditApplication }, quote, ldFlags)

    if (
      loanProduct &&
      quote.loanProductId !== loanProduct.loanProductId &&
      !contractExists(contract) &&
      !isBorrower(me)
    ) {
      await this.updateQuote(loanProduct)
    }
  }

  updateQuote = async loanProduct => {
    const { customerId, quote } = this.props
    const variables = {
      customerId,
      quote: {
        loanAmount: quote.loanAmount,
        loanProductId: loanProduct.loanProductId || loanProduct.id,
        hasAch: quote.hasAch,
        lumpSumPayment: quote.lumpSumPayment,
        projects: selectProjectsDataForMutation(quote.projects)
      }
    }

    await this.props.client.mutate({
      mutation: UpdateQuote,
      variables
    })
  }

  handleCreateContract = async (createContract, ldFlags) => {
    const { customerId, onSubmitStart, history } = this.props
    const achFlagEnabled = moveAchBeforeLoanAgreementFlagEnabled(ldFlags)
    let errorMessage = EMPTY_STRING
    await onSubmitStart()
    this.setState({ errorMessage })

    try {
      const deviceFingerprint = await getDeviceFingerprint()
      const { errors } = await createContract({
        variables: { customerId, deviceFingerprint },
        errorPolicy: 'all',
        refetchQueries: [
          {
            query: achFlagEnabled
              ? GetCreditApplicationWithDiscountedTerms
              : GetCreditApplication,
            variables: { id: customerId }
          }
        ],
        awaitRefetchQueries: true
      })

      if (errors) {
        const error = head(errors)
        const { extensions: { type } = {} } = error

        switch (type) {
          case errorTypes.contractExceedsProductLimit:
            errorMessage = l10n.contract.contractExceedsProductLimit
            break
          case errorTypes.emailVerificationError:
            // If email verification is needed, direct them to that screen
            history.replace(Routes.apply(customerId, applySections.verify))
            break
          default:
            errorMessage = error.message
        }
      } else {
        AnalyticsHelper.track(eventTypes.loanAgreementCreated, {
          customerId,
          deviceFingerprint
        })
      }
      // eslint-disable-next-line no-empty
    } catch (_) {
    } finally {
      this.setState({ errorMessage })
    }
  }

  handleQualifyOrUnderClick = (
    quote,
    creditApplication,
    onEditOffer,
    createContract,
    ldFlags
  ) => {
    const handleClick = () => {
      const isApprovedForLessThanProject = approvedForLessThanProject(
        quote,
        creditApplication,
        ldFlags
      )

      if (isApprovedForLessThanProject) {
        onEditOffer()
      } else {
        safeSessionStorage.setItem(
          emailVerificationSessionStore.CONTINUE_APP,
          emailVerificationSessionStore.CONTINUE_APP_VALUE
        )
        this.handleCreateContract(createContract, ldFlags)

        if (
          safeSessionStorage.getItem(emailVerificationSessionStore.SCREEN_NAME)
        ) {
          safeSessionStorage.removeItem(
            emailVerificationSessionStore.SCREEN_NAME
          )
        }
      }
    }
    return debounce(handleClick, 500)
  }

  render() {
    const {
      classes,
      onEditOffer,
      quote,
      lender,
      customerId,
      creditApplication,
      contractStatus,
      isProductLoading,
      ldFlags,
      isSubmitting
    } = this.props
    const { errorMessage } = this.state

    return (
      <UserContext.Consumer>
        {userContext => {
          const isLoanAmountOutOfLimits =
            get(quote, 'loanAmount') >
            get(
              creditApplication,
              `${getProductsFieldName()[0]}.borrowingLimit`
            )
          const channelPartner = get(
            userContext,
            'channelPartner',
            EMPTY_OBJECT
          )

          const addOns = get(channelPartner, 'addOns', EMPTY_ARRAY)
          return (
            <TranslationsContext.Consumer>
              {l10n => {
                const servicePlans =
                  addOns &&
                  addOns.filter(addOn => {
                    return addOn.code === l10n.quote.servicePlan.code
                  })

                const isServicePlanAvailable = !isEmpty(servicePlans)

                const isPublicApplication = fieldset =>
                  fieldset === fieldsetNames.FIELDSET_PUBLIC_APPLICATION

                return (
                  <ApplyContext.Consumer>
                    {applyContext => {
                      const isSwiftlinks = isPublicApplication(
                        get(applyContext, 'fieldset')
                      )
                      return (
                        <Grid
                          container
                          direction="column"
                          justify="center"
                          alignItems="center"
                        >
                          {!isServicePlanAvailable || isSwiftlinks ? (
                            <>
                              <Grid item>
                                <Grid
                                  container
                                  justify="center"
                                  alignItems="center"
                                  className={classes.iconWrapper}
                                >
                                  <Grid item>
                                    <DoneIcon className={classes.icon} />
                                  </Grid>
                                </Grid>
                              </Grid>
                              <Grid item className={classes.subheading}>
                                <NumberFormat
                                  thousandSeparator
                                  prefix={DOLLAR_PREFIX}
                                  displayType="text"
                                  renderText={text =>
                                    contractStatus ===
                                    contractStatuses.cancelled ? (
                                      <Typography
                                        variant="subtitle1"
                                        align="center"
                                        paragraph
                                        className={classes.textSuccess}
                                      >
                                        {
                                          l10n.apply.formStatus.approved
                                            .descriptionVoided
                                        }
                                        <Button
                                          component="a"
                                          onClick={onEditOffer}
                                          variant="text"
                                          disableFocusRipple
                                          disableRipple
                                          classes={{
                                            text: classes.buttonTextSuccess,
                                            root: classes.buttonSuccess
                                          }}
                                        >
                                          {
                                            l10n.apply.formStatus.approved
                                              .descriptionVoidedLinkText
                                          }
                                        </Button>
                                      </Typography>
                                    ) : (
                                      <SuccessMessage
                                        classes={classes}
                                        creditApplication={creditApplication}
                                        ldFlags={ldFlags}
                                        quote={quote}
                                        text={text}
                                        isSwiftlinks={isSwiftlinks}
                                      />
                                    )
                                  }
                                  value={getApprovedFinancingAmount(
                                    quote,
                                    creditApplication,
                                    ldFlags
                                  )}
                                />
                              </Grid>
                            </>
                          ) : (
                            <>
                              <Grid
                                item
                                className={cx({
                                  [classes.subheading]: !isServicePlanAvailable
                                })}
                              >
                                <NumberFormat
                                  thousandSeparator
                                  prefix={DOLLAR_PREFIX}
                                  displayType="text"
                                  renderText={() =>
                                    contractStatus ===
                                    contractStatuses.cancelled ? (
                                      <Typography
                                        variant="subtitle1"
                                        align="center"
                                        paragraph
                                        className={classes.textSuccess}
                                      >
                                        {
                                          l10n.apply.formStatus.approved
                                            .descriptionVoided
                                        }
                                        <Button
                                          component="a"
                                          onClick={onEditOffer}
                                          variant="text"
                                          disableFocusRipple
                                          disableRipple
                                          classes={{
                                            text: classes.buttonTextSuccess,
                                            root: classes.buttonSuccess
                                          }}
                                        >
                                          {
                                            l10n.apply.formStatus.approved
                                              .descriptionVoidedLinkText
                                          }
                                        </Button>
                                      </Typography>
                                    ) : (
                                      <Grid
                                        item
                                        className={cx({
                                          [classes.subheading]:
                                            !isServicePlanAvailable
                                        })}
                                      >
                                        <Typography
                                          variant="caption"
                                          align="center"
                                          gutterBottom
                                        >
                                          {
                                            l10n.apply.productSelection
                                              .approvedLoanAmount
                                          }
                                        </Typography>
                                        <Typography
                                          align="center"
                                          variant="h2"
                                          paragraph
                                        >
                                          <NumberFormat
                                            value={getApprovedFinancingAmount(
                                              quote,
                                              creditApplication,
                                              ldFlags
                                            )}
                                            renderText={text => (
                                              <SuperScriptAmount price={text} />
                                            )}
                                            prefix={DOLLAR_PREFIX}
                                            displayType="text"
                                            thousandSeparator
                                          />
                                        </Typography>
                                      </Grid>
                                    )
                                  }
                                  value={getApprovedFinancingAmount(
                                    quote,
                                    creditApplication,
                                    ldFlags
                                  )}
                                />
                              </Grid>
                            </>
                          )}
                          <ProjectEstimate
                            isLoanAmountOutOfLimits={isLoanAmountOutOfLimits}
                            quote={quote}
                            client={this.props.client}
                            creditApplication={creditApplication}
                            classes={classes}
                            lender={lender}
                            customerId={customerId}
                            errorMessage={errorMessage}
                            isSubmitting={isSubmitting}
                            onEditOffer={onEditOffer}
                            handleQualifyOrUnderClick={
                              this.handleQualifyOrUnderClick
                            }
                            isProductLoading={isProductLoading}
                          />
                        </Grid>
                      )
                    }}
                  </ApplyContext.Consumer>
                )
              }}
            </TranslationsContext.Consumer>
          )
        }}
      </UserContext.Consumer>
    )
  }
}

export default flow(
  withLaunchDarkly,
  withRouter,
  withApolloConsumer,
  withStyles(customStyles)
)(ApprovedContent)
