import coreScreenStyles from 'themes/coreScreenStyles'
import { colors } from 'themes/default'

const styles = theme => {
  const coreStyles = coreScreenStyles(theme)

  return {
    icon: {
      color: theme.palette.iconSuccess,
      width: 28,
      height: 28,
      marginTop: 3
    },
    errorMessage: {
      color: theme.palette.error.main
    },
    iconWrapper: {
      backgroundColor: theme.palette.iconSuccessContainer,
      width: 70,
      height: 70,
      borderRadius: 35,
      marginBottom: theme.spacing(2)
    },
    iconGray: {
      color: theme.palette.grey['500'],
      width: 28,
      height: 28,
      marginTop: 3
    },
    iconWrapperGray: {
      marginBottom: theme.spacing(2),
      backgroundColor: '#efefef',
      width: 70,
      height: 70,
      borderRadius: 35
    },
    iconWrapperBlank: {
      marginBottom: theme.spacing(2),
      backgroundColor: 'transparent',
      width: 70,
      borderRadius: 35
    },
    subheading: {
      maxWidth: 340,
      marginBottom: theme.spacing(4)
    },
    productRow: {
      maxWidth: 400
    },
    approvedContentText: {
      fontSize: '12px',
      fontWeight: 'normal',
      maxWidth: 576,
      color: colors.extraDarkGray,
      textAlign: 'center'
    },
    linkText: {
      color: theme.palette.action.textLinkColor,
      textDecoration: 'none'
    },
    textSuccess: {
      color: theme.palette.common.successGreen,
      fontWeight: 500
    },
    buttonTextSuccess: {
      color: theme.palette.common.successGreen,
      textTransform: 'none',
      fontSize: 'inherit',
      fontWeight: 'inherit',
      textDecoration: 'underline'
    },
    buttonSuccess: {
      padding: 0,
      minHeight: 'inherit',
      position: 'relative',
      verticalAlign: 'inherit'
    },
    buttonCenter: { textAlign: 'center', marginTop: theme.spacing(2) },
    buttonMargin: {
      marginRight: theme.spacing(1),
      marginLeft: theme.spacing(1)
    },
    circularProgressWithinContent: {
      textAlign: 'center',
      marginTop: theme.spacing(1)
    },
    selectPlaceholder: coreStyles.selectPlaceholder,
    sensitiveInfoInputWebkit: coreStyles.sensitiveInfoInputWebkit,
    sensitiveInfoInput: coreStyles.sensitiveInfoInput,
    invalidInformation: {
      color: theme.palette.error.main,
      marginTop: theme.spacing(2),
      textAlign: 'center'
    },
    marginBottom: {
      marginBottom: theme.spacing(2)
    },
    labelCtn: {
      paddingBottom: '0 !important'
    },
    fieldCtn: {
      paddingTop: '0 !important'
    },
    mobileMarginTop: {
      '@media (max-width: 400px)': {
        marginTop: theme.spacing(4)
      }
    }
  }
}

export default styles
