import React from 'react'
import ReactMarkdown from 'markdown-to-jsx'
import { merge } from 'lodash'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const styles = theme => ({
  listItem: {
    marginTop: theme.spacing(0.5)
  },
  p: {
    fontWeight: 'normal'
  }
})

export const defaultOverrides = {
  h1: {
    component: props => <Typography gutterBottom variant="h4" {...props} />
  },
  h2: {
    component: props => <Typography gutterBottom variant="h2" {...props} />
  },
  h3: {
    component: props => (
      <Typography gutterBottom variant="subtitle1" {...props} />
    )
  },
  h4: {
    component: props => (
      <Typography gutterBottom variant="caption" paragraph {...props} />
    )
  },
  p: {
    component: withStyles(styles)(({ classes, ...props }) => (
      <Typography paragraph classes={{ body1: classes.p }} {...props} />
    ))
  },
  li: {
    component: withStyles(styles)(({ classes, ...props }) => (
      <li className={classes.listItem}>
        <Typography component="span" {...props} />
      </li>
    ))
  }
}

const defaultOptions = {
  overrides: defaultOverrides
}

function Markdown({ options = {}, ...props }) {
  return (
    <ReactMarkdown
      data-testid="react-markdown"
      options={merge(defaultOptions, options)}
      {...props}
    />
  )
}

export default Markdown
