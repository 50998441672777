import { gql } from 'graphql.macro'

import LoanProductResponseFields from 'queries/fragments/LoanProductResponseFields.graphql'
import SampleTermsResponseFields from 'queries/fragments/SampleTermsResponseFields.graphql'

export default gql`
  query {
    channelPartner {
      id
      availableProducts {
        ...LoanProductResponseFields
        sampleTerms {
          ...SampleTermsResponseFields
        }
      }
    }
  }
  ${LoanProductResponseFields}
  ${SampleTermsResponseFields}
`
