export default theme => ({
  gridContainer: {
    '& > .MuiGrid-item, & > [class*=MuiGrid-item]': {
      paddingBottom: theme.spacing(3)
    },
    backgroundColor: '#FFF'
  },
  paper: {
    padding: theme.spacing(5),
    marginTop: theme.spacing(9),
    '@media (max-width: 600px)': {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    }
  },
  preQualifyText: {
    width: '90%',
    margin: 'auto',
    fontSize: theme.spacing(2),
    lineHeight: '1.5'
  },
  welcomeText: {
    position: 'absolute',
    top: '24px',
    left: '0',
    '@media (max-width: 400px)': {
      top: '48px'
    }
  },
  channelPartnerName: {
    fontWeight: 'bold',
    fontSize: theme.spacing(2),
    justifyContent: 'center',
    display: 'flex',
    lineHeight: '2.5'
  }
})
