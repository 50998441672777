export default {
  phoneNumberIsLandline: 'customer.error.phone.landline',
  phoneNumberIsInvalid: 'customer.error.phone.invalid',
  phoneNumberIsOptedOut: 'customer.error.phone.optedOut',
  emailInstaller: 'customer.error.email.installerEmail',
  emailInvalidFormat: 'customer.error.email.invalidFormat',
  currentAccountNumber: 'partnerBankAccount.error.invalidCurrentAccountNumber',
  accountNumber: 'partnerBankAccount.error.invalidAccountNumber',
  routingNumber: 'partnerBankAccount.error.invalidRoutingNumber',
  bankAccountInvalidAccountInformation:
    'customer.error.bankAccount.invalidAccountInformation'
}
