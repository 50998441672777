// @flow
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import l10n from 'properties/translations'
import PaperCtn from '../PaperCtn'

type Props = {
  classes: Object
}

const styles = theme => ({
  videoCtn: {
    flex: 6,
    padding: `0 ${theme.spacing(2)}px`,
    marginBottom: theme.spacing(2)
  },
  header: {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: theme.spacing(2),
    margin: theme.spacing(2),
    // eslint-disable-next-line
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3)
    }
  },
  iframe: {
    height: '100%',
    width: '100%'
  }
})

const ONBOARDING_VIDEO_URL = 'https://player.vimeo.com/video/379101662'

const Step1 = ({ classes }: Props) => (
  <PaperCtn>
    <Typography variant="body2" align="center" className={classes.header}>
      {l10n.onboardingSteps.step1.header}
    </Typography>
    <Grid container className={classes.videoCtn}>
      <iframe
        src={ONBOARDING_VIDEO_URL}
        className={classes.iframe}
        title={l10n.onboardingSteps.step1.videoTitle}
        frameBorder="0"
        allow="autoplay; fullscreen"
        allowFullScreen
      />
    </Grid>
  </PaperCtn>
)

export default withStyles(styles)(Step1)
