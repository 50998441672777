// @flow
import adminEnvironments from 'constants/enums/adminEnvironments'
import { getEnvironment } from 'util/EnvironmentHelper'

export const portalPathURL = (
  hostName: string = 'login',
  link: string = '/sign-in/reset-password'
): string => `${adminEnvironments[getEnvironment()](hostName)}${link}`

export default portalPathURL
