// @flow

/**
 * Return a percentage (0 - 100) of the principal
 * @param principal number
 * @param lumpSumPayment number
 * @returns {number}
 */

export const calculatePaydownPercentage = (
  principal: number,
  lumpSumPayment: number
): number =>
  // The pay down percentage is the percentage of the principal that the user
  // Wishes to pay off with the lumpSumPayment
  principal && lumpSumPayment
    ? Math.floor((lumpSumPayment / principal) * 100)
    : 0

/**
 * Retuns the new lumpSumPayment based on the percentage of the principal
 * @param payDownPercentage number
 * @param principal number
 * @returns {number}
 */

export const calculateLumpSumPayment = (
  payDownPercentage: number,
  principal: number,
  isSameAsCashLoanProduct: boolean = true
): number =>
  isSameAsCashLoanProduct ? (payDownPercentage / 100) * principal : 0

export const safeParseFloat = (input: any) => (input ? parseFloat(input) : null)

export const calculateServicePlanAmount = (addOnsObject: Array[]) => {
  return addOnsObject.reduce((a, b) => a + b.price, 0)
}

const MathHelper = {
  calculateLumpSumPayment,
  calculatePaydownPercentage,
  safeParseFloat,
  calculateServicePlanAmount
}

export default MathHelper
