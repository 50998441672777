// @flow
import React, { useEffect } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import l10n from 'properties/translations'
import { flow } from 'lodash'
import ScreenTitle from 'components/shared/ScreenTitle'
import Page from 'components/templates/Page'
import { pageTitle, bodyTextNormal } from 'themes/default'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import {
  getAllowDenyHostnamesConfig,
  isHostnameAllowed,
  allHostnamesAllowed
} from 'util/PrequalificationHelper'
import SwiftLinkPanel from 'screens/admin/swiftlink/SwiftLinkPanel'
import { redirectToP3X } from 'util/SessionHelper'

const styles = theme => ({
  pageTitle: {
    fontWeight: pageTitle.fontWeight,
    fontSize: pageTitle.fontSize,
    color: pageTitle.color
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  }
})
type Props = {
  ldFlags: Object,
  channelPartner: { hostName: string },
  classes: any
}

const Resources = (props: Props) => {
  const {
    ldFlags,
    channelPartner: { hostName },
    classes,
    me
  } = props
  const allowDenyHostnamesConfig = getAllowDenyHostnamesConfig(ldFlags)

  useEffect(() => {
    redirectToP3X(me?.isSalesforceIdentityEnabled)
  }, [])

  return (
    <Page>
      <ScreenTitle title={l10n.screenTitles.resources} />
      <Grid container direction="column" justify="center" alignItems="stretch">
        <Grid item>
          <Typography
            variant="h1"
            data-testid="admin-title"
            gutterBottom
            className={classes.pageTitle}
          >
            {l10n.resources.title}
          </Typography>
          <Typography
            variant="body2"
            paragraph
            className={classes.bodyTextNormal}
          >
            {l10n.resources.lead}
          </Typography>
        </Grid>
        {(isHostnameAllowed(allowDenyHostnamesConfig, hostName) ||
          allHostnamesAllowed(allowDenyHostnamesConfig)) && <SwiftLinkPanel />}
      </Grid>
    </Page>
  )
}

export default flow(withLaunchDarkly, withRouter, withStyles(styles))(Resources)
