import { gql } from 'graphql.macro'
import ContractFragment from 'queries/fragments/ContractFragment.graphql'

export default gql`
  mutation createContract($customerId: String!, $deviceFingerprint: String) {
    createContract(
      customerId: $customerId
      deviceFingerprint: $deviceFingerprint
    ) {
      ...ContractFragment
    }
  }
  ${ContractFragment}
`
