import React, { useContext } from 'react'
import customerFields from 'constants/enums/customerFields'
import NumberFormat from 'react-number-format'
import { TextValidator } from 'react-material-ui-form-validator'
import fieldMasks from 'constants/enums/fieldMasks'
import placeholders from 'properties/placeholders'
import { PHONE_NUMBER_VALIDATORS, EMPTY_STRING } from 'properties/properties'
import browserAutocompleteKeys from 'constants/enums/browserAutocompleteKeys'
import { FormHelperText, ButtonBase } from '@material-ui/core'
import TranslationsContext from 'util/TranslationsContext'

import LockableField from './components/LockableField'

type Props = {
  id: string,
  lockedFields: any[],
  canEdit: boolean,
  classes: { [key: string]: string },
  phoneNumber: string | Number,
  showAlternatePhoneNumber: boolean,
  onFieldEdit: Function,
  getValidatorsRequired: Function,
  getErrorMessagesRequired: Function,
  toggleAlternatePhoneNumber: Function,
  error?: Error,
  onKeyPress: Function
}

const PhoneNumberField = ({
  id,
  lockedFields,
  inputProps,
  canEdit,
  classes,
  phoneNumber,
  showAlternatePhoneNumber,
  onFieldEdit,
  getValidatorsRequired,
  getErrorMessagesRequired,
  toggleAlternatePhoneNumber,
  dataTest,
  error = null,
  onKeyPress
}: Props) => {
  const l10n = useContext(TranslationsContext)
  return (
    <>
      <LockableField
        locked={lockedFields[customerFields.phoneNumber]}
        fieldValues={{
          phoneNumber: phoneNumber ? phoneNumber.toString() : null
        }}
        fieldNames={{
          phoneNumber: customerFields.phoneNumber
        }}
        fieldLabels={{
          phoneNumber: l10n.customer.phoneNumber
        }}
        onFieldEdit={onFieldEdit}
        parentClasses={classes}
        inputComponent={
          <NumberFormat
            id={customerFields.phoneNumber}
            customInput={TextValidator}
            format={fieldMasks.phoneNumber}
            mask="_"
            type="tel"
            fullWidth
            required={canEdit}
            label={l10n.customer.phoneNumber}
            onChange={onFieldEdit(customerFields.phoneNumber)}
            name={customerFields.phoneNumber}
            value={phoneNumber || EMPTY_STRING}
            placeholder={placeholders.phoneNumber}
            disabled={!canEdit}
            autoComplete={browserAutocompleteKeys.telNational}
            validators={[
              ...getValidatorsRequired(customerFields.phoneNumber),
              ...PHONE_NUMBER_VALIDATORS
            ]}
            errorMessages={[
              ...getErrorMessagesRequired(customerFields.phoneNumber, l10n),
              l10n.customer.phoneNumberErrorMessage,
              l10n.customer.phoneNumberMinLength
            ]}
            helperText={error}
            error={Boolean(error)}
            InputLabelProps={{ required: !id }}
            InputProps={{ onKeyPress }}
            inputProps={{ ...inputProps, 'data-testid': dataTest }}
          />
        }
      />
      {canEdit && (
        <FormHelperText>
          {showAlternatePhoneNumber ? (
            l10n.customer.phoneNumberHelpText.completeText
          ) : (
            <>
              {l10n.customer.phoneNumberHelpText.prependingText}
              <ButtonBase
                data-testid="alt-phone-toggle"
                onClick={toggleAlternatePhoneNumber}
                classes={{ root: classes.captionButton }}
                tabIndex={-1}
              >
                {l10n.customer.phoneNumberHelpText.buttonText}
              </ButtonBase>
              {l10n.customer.phoneNumberHelpText.appendingText}
            </>
          )}
        </FormHelperText>
      )}
    </>
  )
}

export default PhoneNumberField
