import React, { useContext, useState } from 'react'
import NumberFormat from 'react-number-format'
import ReactSessionApi from 'react-session-api'
import { get } from 'lodash'
import { Grid, Typography, InputAdornment } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import TextValidator from 'components/shared/TextValidator'
import LockedInputIcon from 'components/shared/LockedInputIcon'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import LandlineWarning from 'components/shared/LandlineWarning'
import customerFields from 'constants/enums/customerFields'
import localStorageKeys from 'constants/enums/localStorageKeys'
import fieldMasks from 'constants/enums/fieldMasks'
import browserAutocompleteKeys from 'constants/enums/browserAutocompleteKeys'
import errorKeys from 'constants/enums/errorKeys'
import languageKeys from 'constants/enums/languageKeys'
import placeholders from 'properties/placeholders'
import { PHONE_NUMBER_VALIDATORS } from 'properties/properties'
import PrequalificationFormContext from 'util/PrequalificationFormContext'
import TranslationsContext from 'util/TranslationsContext'
import {
  getErrorType,
  emailErrorMessage,
  getServerErrorMessageObject,
  phoneErrorMessage
} from 'util/CustomerHelper'
import { blockEnterKeyCode } from 'util/ApplyHelper'
import { landlineNumberEnabled } from 'util/FeatureHelper'
import AlternatePhoneNumber from './AlternatePhoneNumber'
import styles from './EmailAndPhoneInputs.styles'

const useStyles = makeStyles(styles)

type Props = {
  ldFlags: Object,
  email: string,
  phoneNumber: string,
  serverErrors: any,
  onFieldEdit: Function,
  fieldDisabled: Boolean,
  isLandlineNumber: Boolean,
  agreeAndContinueHandler: Function,
  updateServerError: Function,
  mutationFn: Function
}

const EmailAndPhoneInputs = ({
  ldFlags,
  email,
  phoneNumber,
  serverErrors,
  onFieldEdit,
  fieldDisabled,
  isLandlineNumber,
  agreeAndContinueHandler,
  updateServerError,
  mutationFn
}: Props) => {
  const { customer, customerPending, customerVerified } = useContext(
    PrequalificationFormContext
  )
  const l10n = useContext(TranslationsContext)
  const classes = useStyles()

  const [landlineWarning, setLandlineWarning] = useState(false)
  const [
    alternatePhoneNumberFieldEnabled,
    setAlternatePhoneNumberFieldEnabled
  ] = useState(false)

  const serverErrorMessages = getServerErrorMessageObject(serverErrors)
  const landlineNumberFlagEnabled = landlineNumberEnabled(ldFlags)
  const phoneErrorType = getErrorType(serverErrorMessages, [
    customerFields.phoneNumber,
    customerFields.primaryApplicantPhoneNumber
  ])
  const isLandline = phoneErrorType === errorKeys.phoneNumberIsLandline

  const showAlternatePhoneNumber =
    get(customer, customerFields.phoneNumberAlt) ||
    alternatePhoneNumberFieldEnabled
  const labelProps = {
    className: customerPending ? classes.inactiveColor : undefined
  }
  const emailErrorType = getErrorType(serverErrorMessages, [
    customerFields.email,
    customerFields.primaryApplicantEmailAddress
  ])
  const emailError = emailErrorMessage(emailErrorType, email)
  const fieldProps = {
    className: fieldDisabled ? classes.lockedField : undefined,
    disabled: fieldDisabled,
    InputProps: {
      disableUnderline: fieldDisabled,
      endAdornment: fieldDisabled && (
        <InputAdornment className={classes.inputAdornment}>
          <LockedInputIcon classes={{ root: classes.lockedFieldIcon }} />
        </InputAdornment>
      )
    }
  }

  const displayLandlineConfirmModal = () => {
    setLandlineWarning(true)
  }
  const hideLandlineConfirmModal = () => {
    setLandlineWarning(false)
  }
  const allowLandlineNumber = mutationFn => {
    const variables = { email, phoneNumber }
    ReactSessionApi.set(localStorageKeys.allowedLandline, true)
    hideLandlineConfirmModal()
    agreeAndContinueHandler(
      serverErrors,
      variables,
      mutationFn,
      updateServerError
    )
  }

  const displayLandlineWarning = mutationFn => {
    if (ReactSessionApi.get(localStorageKeys.allowedLandline)) {
      return undefined
    }

    return (
      <LandlineWarning
        landlineWarning={landlineWarning}
        displayLandlineConfirmModal={displayLandlineConfirmModal}
        hideLandlineConfirmModal={hideLandlineConfirmModal}
        allowLandlineNumber={() => allowLandlineNumber(mutationFn)}
      />
    )
  }

  const landlineErrorWithLandlineFlagEnabled =
    landlineNumberFlagEnabled && isLandline

  const errorMessage = landlineErrorWithLandlineFlagEnabled
    ? displayLandlineWarning(mutationFn)
    : phoneErrorMessage(phoneErrorType, phoneNumber, isLandlineNumber)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} className={classes.inputContainer}>
        <Typography
          variant="caption"
          gutterBottom
          className={cx(
            classes.formFieldLabel,
            classes.labelText,
            labelProps && labelProps.className,
            classes.emailAndPhoneLabelInlineBlock
          )}
        >
          {l10n.customer.email}
        </Typography>

        <TextValidator
          data-testid="email-input"
          required
          fullWidth
          id={customerFields.email}
          name={customerFields.email}
          value={email || customer.email}
          email={email}
          errorType={emailErrorType}
          type="email"
          className={classes.emailVerificationInputWrapper}
          onChange={onFieldEdit(customerFields.email)}
          placeholder={
            l10n.languageKey === languageKeys.en
              ? placeholders.email
              : placeholders.emailSpanish
          }
          autoComplete={browserAutocompleteKeys.email}
          validators={['required', 'isEmail']}
          errorMessages={[
            l10n.formFields.required,
            l10n.customer.emailErrorMessage
          ]}
          helperText={emailError}
          error={Boolean(emailError)}
          {...fieldProps}
        />
      </Grid>
      <Grid item xs={12} sm={6} className={classes.inputContainer}>
        <Typography
          variant="caption"
          gutterBottom
          className={cx(
            classes.formFieldLabel,
            classes.labelText,
            labelProps && labelProps.className,
            classes.emailAndPhoneLabelInlineBlock
          )}
        >
          {l10n.customer.phoneNumber}
        </Typography>

        <NumberFormat
          id={customerFields.phoneNumber}
          mask="_"
          name={customerFields.phoneNumber}
          value={phoneNumber || customer.phoneNumber}
          format={fieldMasks.phoneNumber}
          required
          fullWidth
          type="tel"
          className={cx({
            [classes.phoneVerificationInputWrapper]: true
          })}
          onValueChange={onFieldEdit(customerFields.phoneNumber)}
          validators={PHONE_NUMBER_VALIDATORS}
          customInput={TextValidator}
          placeholder={placeholders.phoneNumber}
          autoComplete={browserAutocompleteKeys.telNational}
          errorMessages={[
            l10n.customer.phoneNumberErrorMessage,
            l10n.customer.phoneNumberMinLength
          ]}
          errorType={phoneErrorType}
          isLandlineNumber={isLandlineNumber}
          helperText={errorMessage && <>{errorMessage}</>}
          error={Boolean(errorMessage)}
          onKeyPress={blockEnterKeyCode}
          data-testid="phone-input"
          showAlternatePhoneNumber={showAlternatePhoneNumber}
          {...fieldProps}
        />
        {customerVerified && (
          <AlternatePhoneNumber
            setFieldEnabled={setAlternatePhoneNumberFieldEnabled}
            showAlternatePhoneNumber={showAlternatePhoneNumber}
            onFieldEdit={onFieldEdit}
            errorMessage={errorMessage}
          />
        )}
      </Grid>
    </Grid>
  )
}

export default withLaunchDarkly(EmailAndPhoneInputs)
