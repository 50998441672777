import { gql } from 'graphql.macro'
import QuoteProjectResponseFields from 'queries/fragments/QuoteProjectResponseFields.graphql'

export default gql`
  query customer($id: String!) {
    customer(customerId: $id) {
      id
      firstName
      lastName
      lender
      loanType
      estimateComplete
      dateESignVerified
      applyComplete
      setupComplete
      contract {
        status
      }
      creditApplication {
        status
      }
      quote {
        loanAmount
        loanProductId
        hasAch
        prePayment
        projects {
          ...QuoteProjectResponseFields
        }
      }
      allowedActions
    }
  }
  ${QuoteProjectResponseFields}
`
