import { gql } from 'graphql.macro'
import ContractFragmentDiscounted from 'queries/fragments/ContractFragmentDiscounted.graphql'
import QuoteProjectResponseFields from 'queries/fragments/QuoteProjectResponseFields.graphql'
import LoanProductResponseFields from 'queries/fragments/LoanProductResponseFields.graphql'
import SampleTermsResponseFields from 'queries/fragments/SampleTermsResponseFields.graphql'

export default gql`
  query GetCustomerCreditDataDiscounted($id: String!) {
    customer(customerId: $id) {
      id
      lender
      creditApplication {
        conditionalApprovalLink
        status
        financingLimit
        decision {
          flags
          status
          expired
          qualifiedProducts {
            ...LoanProductResponseFields
            sampleTerms {
              ...SampleTermsResponseFields
            }
          }
        }
      }
      quote {
        hasAch
        loanProductId
        prePayment
        projects {
          ...QuoteProjectResponseFields
        }
        loanAmount
      }
      contract {
        ...ContractFragmentDiscounted
      }
    }
    me {
      userType
    }
  }

  ${ContractFragmentDiscounted}
  ${QuoteProjectResponseFields}
  ${LoanProductResponseFields}
  ${SampleTermsResponseFields}
`
