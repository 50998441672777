// @flow
import React from 'react'
import { get } from 'lodash'
import { withRouter } from 'react-router-dom'
import OnLoginSuccess from 'screens/login/LoginEntry/components/OnLoginSuccess'
import UserContext from 'util/UserContext'

const WelcomeRedirect = () => {
  const userContext = React.useContext(UserContext)
  const { me } = userContext

  const userRoles = get(me, 'userRoles')
  const accessModes = get(me, 'accessModes')
  if (!userRoles || !accessModes) return null

  return <OnLoginSuccess userRoles={userRoles} accessModes={accessModes} />
}

export default withRouter(WelcomeRedirect)
