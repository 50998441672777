// @flow
import { every, minBy, maxBy, get, some, isEmpty } from 'lodash'
import { MINIMUM_LOAN_AMOUNT, MAXIMUM_LOAN_AMOUNT } from 'properties/properties'
import {
  projectTypeKeys,
  projectTypeIdToEnumValue
} from 'constants/enums/projectTypes'
import financingOptions from 'constants/enums/financingOptions'
import loanProductTypes from 'constants/enums/loanProductTypes'
import {
  type LoanProductType,
  type Project,
  ProjectTypeConfiguration
} from 'screens/quote/QuoteTypes'
import { handleServicePlanUpdates } from 'util/QuoteContext'

export const getValueFromLoanProduct = (
  loanProducts: LoanProductType[],
  value: string,
  selectedLoanProductId = null
) => {
  if (!loanProducts.length) return MINIMUM_LOAN_AMOUNT
  if (selectedLoanProductId) {
    const selectedLoanProduct = loanProducts.find(
      loanProduct =>
        ('id' in loanProduct && loanProduct.id === selectedLoanProductId) ||
        ('loanProductId' in loanProduct &&
          loanProduct.loanProductId === selectedLoanProductId)
    )

    if (selectedLoanProduct && selectedLoanProduct[value])
      return selectedLoanProduct[value]
  }
  if (value === 'minimumAmount') {
    return get(
      minBy(loanProducts, 'minimumAmount'),
      'minimumAmount',
      MINIMUM_LOAN_AMOUNT
    )
  }
  if (value === 'maximumAmount') {
    return get(
      maxBy(loanProducts, 'maximumAmount'),
      'maximumAmount',
      MAXIMUM_LOAN_AMOUNT
    )
  }

  return loanProducts[0][value]
}

export const getMinimumLoanAmount = (
  loanProducts,
  selectedLoanProductId = null
) =>
  getValueFromLoanProduct(loanProducts, 'minimumAmount', selectedLoanProductId)

export const getMaximumLoanAmount = (
  loanProducts,
  selectedLoanProductId = null
) =>
  getValueFromLoanProduct(loanProducts, 'maximumAmount', selectedLoanProductId)

export const getBorrowingLimit = (loanProducts, selectedLoanProductId = null) =>
  getValueFromLoanProduct(loanProducts, 'borrowingLimit', selectedLoanProductId)

const onlyFirstIsPresentOrTrue = (first, second) => first && !second

const noPromoPeriod = (first, second) => !first && !second

export const sortLoanProducts = (
  firstEl,
  secondEl,
  propertyMap = {
    promoPeriod: 'promoPeriod',
    loanTenorMonths: 'loanTenorMonths'
  }
) => {
  const {
    [propertyMap.promoPeriod]: firstPromoPeriod,
    [propertyMap.loanTenorMonths]: firstLoanTenorMonths
  } = firstEl
  const {
    [propertyMap.promoPeriod]: secondPromoPeriod,
    [propertyMap.loanTenorMonths]: secondLoanTenorMonths
  } = secondEl
  if (
    onlyFirstIsPresentOrTrue(firstPromoPeriod, secondPromoPeriod) ||
    noPromoPeriod(firstPromoPeriod, secondPromoPeriod)
  ) {
    return 1
  }
  if (
    onlyFirstIsPresentOrTrue(secondPromoPeriod, firstPromoPeriod) ||
    noPromoPeriod(firstPromoPeriod, secondPromoPeriod)
  ) {
    return -1
  }
  if (!noPromoPeriod(firstPromoPeriod, secondPromoPeriod)) {
    return firstPromoPeriod - secondPromoPeriod
  }

  return firstPromoPeriod
    ? firstLoanTenorMonths - secondLoanTenorMonths
    : secondLoanTenorMonths - firstLoanTenorMonths
}

export const isPromoProduct = loanProduct => {
  if (get(loanProduct, 'numMonthlyPaymentsPromo')) {
    return get(loanProduct, 'numMonthlyPaymentsPromo', 0) > 0
  }
  return get(loanProduct, 'promoPeriod', 0) > 0
}

export const currentProjectsEnums = (currentProjects: Project[]) =>
  currentProjects.reduce((acc, project) => {
    if (projectTypeIdToEnumValue[project.projectType]) {
      acc.push(projectTypeIdToEnumValue[project.projectType])
    }
    return acc
  }, [])

export const channelPartnerSupportedLoanTypes = projectTypes => {
  const result = []
  projectTypes.forEach(projectType => {
    projectType.loanProductTypes.forEach(loanType => {
      if (result.indexOf(loanType) === -1) result.push(loanType)
    })
  })
  return result
}

export const filterLoanProducts =
  (currentProjects: Project[], projectTypes: any) =>
  (loanProduct: LoanProductType) => {
    const currentProjectsEnumsArr = currentProjectsEnums(currentProjects)
    const isInitialCurrentProjects = isEmpty(currentProjectsEnumsArr)
    const isBatteryOnly =
      !isEmpty(currentProjectsEnumsArr) &&
      every(currentProjectsEnumsArr, el => el === projectTypeKeys.BATTERY)

    // If no projects selected, don't filter
    if (isInitialCurrentProjects) {
      return loanProduct
    }

    // If selected projects Battery-only (1 or more), run through filter
    if (isBatteryOnly) {
      if (loanProduct.financingOptions.includes(financingOptions.batteryOnly)) {
        return loanProduct
      }
    } else {
      const supportedLoanTypes = channelPartnerSupportedLoanTypes(projectTypes)

      if (supportedLoanTypes.includes(loanProduct.loanProductType)) {
        return loanProduct
      }
    }

    return null
  }

export const currentlySupportedLoanTypes = (
  projectTypes = [],
  currentProjects: Project[]
) => {
  const currentProjectsEnumArr = currentProjectsEnums(currentProjects)

  const result = []

  currentProjectsEnumArr.forEach(projectEnum => {
    if (projectEnum === undefined || isEmpty(projectTypes)) {
      return
    }

    const loanTypeMatchingProject = projectTypes.find(
      obj => obj.projectType === projectEnum
    )
    const matchingLoanProductTypes = get(
      loanTypeMatchingProject,
      'loanProductTypes'
    )
    if (!matchingLoanProductTypes) {
      return
    }
    matchingLoanProductTypes.forEach(element => {
      if (result.indexOf(element) === -1) result.push(element)
    })
  })

  return result
}

export const anySolarLoanProduct = (loanProducts: LoanProductType[]) =>
  some(loanProducts, {
    loanProductType: loanProductTypes.deferredInterestFlex
  })

export const removeBatteryAndSolarProjectTypes = (
  projectTypes: ProjectTypeConfiguration[]
): ProjectTypeConfiguration[] =>
  projectTypes.filter(
    projType =>
      ![projectTypeKeys.BATTERY, projectTypeKeys.SOLAR_SYSTEM].includes(
        projType.projectType
      )
  )

export const getProducts = (data: Object): Array<Object> => {
  return get(data, 'channelPartner.availableProducts', [])
}

export const getProductsFieldName = (): string => 'decision.qualifiedProducts'

export const servicePlanAllowed = (product: any) =>
  get(product, 'servicePlanAllowed', false)

export const getLoanProductById = (
  loanProducts: any[],
  currentLoanProductId: number
): ?LoanProductType =>
  loanProducts.find(product => product.id === currentLoanProductId)

export const changeService = (
  event: Event & { target: HTMLSelectElement },
  projectId: ?string,
  context: any
) => {
  if (!projectId) return
  const servicePlanCount: ?number = event.target.value
  handleServicePlanUpdates(context, {
    servicePlanCount: servicePlanCount || 0,
    projectId
  })
}

export const loanProductIncludesBatteryOnly = (loanProduct: LoanProductType) =>
  loanProduct.financingOptions.includes(financingOptions.batteryOnly)

export const loanProductOfTypeRRAPR = (
  loanProducts: Array<LoanProductType>,
  loanProductId: number
): boolean => {
  const found = loanProducts.find(
    loanProduct => loanProduct.id === loanProductId
  )

  if (!found) return false

  return found.loanProductType === loanProductTypes.reducedRateAPR
}

export const loanProductOfTypeDeferredInterestFlex = (
  loanProducts: Array<LoanProductType>,
  loanProductId: number
): boolean => {
  const found = loanProducts.find(
    loanProduct => loanProduct.id === loanProductId
  )

  if (!found) return false

  return found.loanProductType === loanProductTypes.deferredInterestFlex
}

export const getSortedLoanProducts = (
  loanProducts: Array<LoanProductType>
): Array<LoanProductType> => {
  return [...loanProducts].sort(sortLoanProducts)
}

export const getFilteredLoanProducts = (
  loanProducts: Array<LoanProductType>,
  currentProjects: Array<Project>,
  projectTypes: Array<ProjectTypeConfiguration>,
  customerIsBorrower: boolean
): Array<LoanProductType> =>
  loanProducts.filter(
    filterLoanProducts(currentProjects, projectTypes, customerIsBorrower)
  )

export const isPowerSwitchProduct = loanProduct =>
  get(loanProduct, 'loanProductType') === loanProductTypes.powerSwitch

export const isLightProduct = loanProduct =>
  get(loanProduct, 'loanProductType') === loanProductTypes.light

export const getCurrentMonthlyPayments = (loanProduct, terms) => {
  const isPowerSwitch = isPowerSwitchProduct(loanProduct)
  const isLight = isLightProduct(loanProduct)

  return isPowerSwitch || isLight
    ? [terms?.choiceMonthlyPayment, terms?.choiceMonthlyPaymentDiscounted]
    : [terms?.monthlyPayment, terms?.monthlyPaymentDiscounted]
}
