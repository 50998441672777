import { gql } from 'graphql.macro'

export default gql`
  fragment ContractTermsFragment on ContractTermsResponse {
    amountFinanced
    financeCharge
    financeChargePromo
    monthlyPayment
    monthlyPaymentPromo
    apr
    aprPromo
    annualRatePercentage
    numMonthlyPayments
    numMonthlyPaymentsPromo
    totalPayments
    totalPaymentsPromo
    estimatedFirstPaymentDate
    numPaymentsUntilReamortization
    numRegularPaymentsAfterReamortization
    choiceMonthlyPayment
    firstPaymentDateAfterReamortization
    numFinalPayments
    finalMonthlyPayment
  }
`
