import React from 'react'
import { ApolloConsumer } from '@apollo/client'

const withApolloConsumer = Component => props => {
  return (
    <ApolloConsumer>
      {client => <Component client={client} {...props} />}
    </ApolloConsumer>
  )
}

export default withApolloConsumer
