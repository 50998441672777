// @flow
import { set, get, isEqual, pick, defaultTo, pickBy, findKey } from 'lodash'
import {
  EMPTY_STRING,
  BYPASSABLE_PREQUALIFICATION_FORM_INLINE_ERRORS
} from 'properties/properties'
import customerFields from 'constants/enums/customerFields'
import fieldsets, { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import { generateDemoValues } from 'constants/enums/demoUser'
import addressFields from 'constants/enums/addressFields'
import customerFieldProperties from 'constants/fieldsets/customerFieldProperties'
import StringHelper from 'util/StringHelper'
import util from 'screens/customer/utils'
import { type AddressShape } from 'screens/customer/shapes/address'
import addressTypes from 'constants/enums/addressTypes'
import { showHighIncomeAlert, showLowIncomeAlert } from 'util/CustomerHelper'

export const getAddressValue = (
  name: string,
  defaultValue: string = EMPTY_STRING,
  propsAddress: Object,
  stateAddress: Object
) => {
  const address = defaultTo(propsAddress, stateAddress)
  return get(address, name) || defaultValue
}

export const collectFormValues = (customer: Object, form: Object): Object => {
  const formValues = {}
  const { childs } = form
  const {
    disclosureAccepted,
    dobMonth,
    dobDay,
    dobYear,
    phoneNumber,
    salesperson
  } = customer
  // // "Childs" [term from rn-mui-form-validator] is TextValidator nodes
  childs.forEach(child => {
    set(formValues, child.props.id, child.state.value)
  })

  // Set other fields manually
  set(
    formValues,
    customerFields.addressState,
    getAddressValue(customerFields.stateAbbreviation)
  )
  set(formValues, customerFields.disclosureAccepted, disclosureAccepted)
  set(formValues, customerFields.dobMonth, dobMonth)
  set(formValues, customerFields.dobDay, dobDay)
  set(formValues, customerFields.dobYear, dobYear)
  set(formValues, customerFields.salespersonId, salesperson && salesperson.id)
  if (phoneNumber) {
    set(
      formValues,
      customerFields.phoneNumber,
      StringHelper.digitsOnly(phoneNumber.toString())
    )
  }

  return formValues
}

export const buildAddresses = (
  addressMap: Map<string, AddressShape>
): Array<AddressShape> =>
  Array.from(addressMap.values()).map((myAddress: Object): Object => ({
    addressType: myAddress.addressType,
    ...pick(myAddress, addressFields)
  }))

export const buildFormData = (
  customerId: ?string,
  formValues: Object,
  demoMode: boolean
) => {
  const builtData = {
    customerId,
    customer: {
      ...pick(formValues, [
        customerFields.email,
        customerFields.assignedTo,
        customerFields.firstName,
        customerFields.lastName,
        customerFields.middleName,
        customerFields.addresses,
        customerFields.dobDay,
        customerFields.dobMonth,
        customerFields.dobYear,
        customerFields.ssn,
        customerFields.personalIncome,
        customerFields.otherHouseholdIncome,
        customerFields.disclosureAccepted,
        customerFields.notes
      ]),
      phoneNumber: util.parsePhoneNumber(formValues.phoneNumber.toString()),
      ...(formValues.phoneNumberAlt1
        ? {
            phoneNumberAlt1: util.parsePhoneNumber(
              formValues.phoneNumberAlt1.toString()
            )
          }
        : {}),
      ...(demoMode ? generateDemoValues() : {}),
      salespersonId: formValues.salespersonId
    }
  }

  if (get(builtData, 'customer.assignedTo', EMPTY_STRING) === EMPTY_STRING) {
    // assignedTo field is not included on the request
    const builtDataWithoutAssignedTo = { ...builtData }

    const customerWithAssignedTo = pickBy(
      builtDataWithoutAssignedTo.customer,
      (value, key) => key !== customerFields.assignedTo
    )

    set(builtDataWithoutAssignedTo, 'customer', customerWithAssignedTo)

    return builtDataWithoutAssignedTo
  }

  return builtData
}

export const getProjectAddressType = (
  addressMap: Map<string, AddressShape>
) => {
  if (addressMap.has(addressTypes.unknown)) return addressTypes.unknown
  if (addressMap.has(addressTypes.rental)) return addressTypes.rental
  if (addressMap.has(addressTypes.secondHome)) return addressTypes.secondHome

  return addressTypes.primary
}

export const initStateValues = (
  showAlternatePhoneNumber: boolean,
  addressMap: Object,
  customer: Object,
  projectAddressType: string
) => ({
  primaryAddressError: EMPTY_STRING,
  secondHomeOrRentalError: EMPTY_STRING,
  mailingAddressError: EMPTY_STRING,
  notesError: EMPTY_STRING,
  errorOccurred: false,
  addressMap,
  customer,
  showAlternatePhoneNumber,
  isFormValid: false,
  inlineErrors: {},
  unlockedFields: [],
  disclosureIdentifier: null,
  validationInProgress: false,
  readMoreClicked: false,
  projectAddressType,
  showMailingAddress: addressMap.has(addressTypes.mailing),
  customerPath: 'customer',
  openAddressModal: null,
  emailAlreadyInUse: false,
  incomeAlerts: {
    highPersonalIncomeAlert: false,
    lowPersonalIncomeAlert: false
  },
  assignedSalesperson: undefined,
  landlineWarning: false,
  dobAlert: false,
  titleAttestation: false
})

export const addFullNameToUsersInList = (
  usersList: Array<Object>
): Array<Object> =>
  usersList.map(user => {
    const userWithFullname = { ...user }
    userWithFullname.fullName = `${user.firstName} ${user.lastName}`
    return userWithFullname
  })

export const canBypassInlineErrors = (
  fieldset: Object,
  inlineErrors: Object
): boolean => {
  const { FIELDSET_PUBLIC_APPLICATION } = fieldsetNames

  return (
    fieldset === FIELDSET_PUBLIC_APPLICATION &&
    isEqual(inlineErrors, BYPASSABLE_PREQUALIFICATION_FORM_INLINE_ERRORS)
  )
}

export const buildFieldInputProps = (
  canEdit: boolean,
  inactiveForm: boolean,
  fieldset: ?string
) => {
  const { FIELDSET_PUBLIC_APPLICATION } = fieldsetNames

  const disableUnderline =
    !canEdit && !inactiveForm && fieldset !== FIELDSET_PUBLIC_APPLICATION

  return {
    firstName: {
      maxLength: customerFieldProperties.maxLengths[customerFields.firstName],
      disableUnderline
    },
    lastName: {
      maxLength: customerFieldProperties.maxLengths[customerFields.lastName],
      disableUnderline
    },
    middleName: {
      maxLength: customerFieldProperties.maxLengths[customerFields.middleName],
      disableUnderline
    }
  }
}

export const getRequiredAddressFields = (
  addressType: string
): Array<string> => [
  `${addressType}.${customerFields.street}`,
  `${addressType}.${customerFields.city}`,
  `${addressType}.${customerFields.stateAbbreviation}`,
  `${addressType}.${customerFields.zip}`
]

export const getUnlockedGridItemProps = (
  fieldset: string,
  solarLoanTypeMode?: boolean = false
): Object => {
  if (solarLoanTypeMode) {
    return {
      xs: 12,
      sm: 4
    }
  }
  return fieldset === fieldsetNames.FIELDSET_PUBLIC_APPLICATION
    ? {
        xs: 12,
        sm: 6
      }
    : {}
}

export const checkRequiredField = (
  field: string,
  fieldset: ?string = null,
  projectAddressType: string,
  showMailingAddress: boolean
): boolean => fieldsets[fieldset].requiredFields.includes(field)

export const isNotPrimaryOrUnknownAddressType = (
  projectAddressType: string
): boolean =>
  ![addressTypes.primary, addressTypes.unknown].includes(projectAddressType)

const incomeErrorKeys = {
  lowPersonalIncomeAlert: 'lowPersonalIncomeAlert',
  highPersonalIncomeAlert: 'highPersonalIncomeAlert'
}

export const getIncomeErrorKeys = (income: string) => {
  return {
    [incomeErrorKeys.highPersonalIncomeAlert]: showHighIncomeAlert(income),
    [incomeErrorKeys.lowPersonalIncomeAlert]:
      income !== EMPTY_STRING && showLowIncomeAlert(income)
  }
}

export const updateIncomeAlerts = (incomeKey: string | null) => {
  const incomeAlertsObj = {
    lowPersonalIncomeAlert: false,
    highPersonalIncomeAlert: false
  }

  if (incomeKey) {
    incomeAlertsObj[incomeKey] = true
  }

  return incomeAlertsObj
}

export const getCurrentIncomeErrorKey = incomeErrors =>
  findKey(incomeErrors, value => value)
