// @flow
import { createContext } from 'react'
import { type CustomerShape } from 'screens/customer/shapes/customer'
import { AboutMeResponse } from 'util/TypesHelper'

export type UserContextType = {
  me?: AboutMeResponse,
  channelPartner?: any,
  customer?: CustomerShape
}

const UserContext = createContext<UserContextType>({
  me: null,
  channelPartner: null,
  customer: null
})

export default UserContext
