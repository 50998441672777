import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TranslationsContext from 'util/TranslationsContext'
import { colors } from 'themes/default'
import errorTypes from 'constants/enums/errorTypes'
import { errorHasGraphQLErrorType } from 'util/ErrorHelper'

const styles = () => ({
  errorText: {
    color: colors.errorRed,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})
const useStyles = makeStyles(styles)

const ApplicationError = ({ error = undefined }) => {
  const l10n = useContext(TranslationsContext)
  const classes = useStyles()

  let message = l10n.prequalificationForm.error
  if (
    error &&
    errorHasGraphQLErrorType(error, errorTypes.applicationException)
  ) {
    message = l10n.prequalificationForm.checkAndTryAgain
  }
  return (
    <Grid item>
      <Grid container item justify="center">
        <Typography variant="body1" className={classes.errorText}>
          {message}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default ApplicationError
