/* eslint-disable react/jsx-boolean-value */
// @flow
import React, { Component } from 'react'
import {
  Grid,
  Typography,
  InputAdornment,
  NativeSelect
} from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import l10n from 'properties/translations'
import OptionalQuery from 'components/shared/OptionalQuery'
import GetCustomer from '../../quote/queries/GetCustomer'

interface Props {
  classes: Object;
  applicantFinancingAmount: string;
  applicantLoanOption: string;
  loanOptions: string;
  applicantLoanProductType: string;
  loanProductTypes: string;
  applicantDownpaymentAmount: string;
  handleApplicantLoanOptionChange: string;
  handleFormStateChange: Function;
  customerId: string;
}

interface State {
  touched: boolean;
}

class YourLoan extends Component<Props, State> {
  constructor(props: Props) {
    super(props)

    this.state = {
      touched: false
    }
  }

  handleFormStateChange = async (ev: Event) => {
    if (!this.state.touched) {
      await this.setState({ touched: true })
    }

    this.props.handleFormStateChange(ev)
  }

  render() {
    const {
      classes,
      applicantFinancingAmount,
      applicantLoanOption,
      loanOptions,
      applicantLoanProductType,
      loanProductTypes,
      applicantDownpaymentAmount,
      handleApplicantLoanOptionChange,
      customerId
    } = this.props
    const { touched } = this.state

    return (
      <OptionalQuery
        runQuery={!touched}
        query={GetCustomer}
        variables={{ id: customerId }}
      >
        {({ data = {} }) => {
          const { customer = {} } = data
          const { quote } = customer

          if (!touched && quote && quote.loanAmount) {
            this.handleFormStateChange({
              target: {
                value: quote.loanAmount,
                name: l10n.foundation.applicationContent.fieldNames
                  .applicantFinancingAmount
              }
            })
          } else if (!touched && quote === null) {
            this.handleFormStateChange({
              target: {
                value: '',
                name: l10n.foundation.applicationContent.fieldNames
                  .applicantFinancingAmount
              }
            })
          }

          return (
            <>
              <Grid container className={classes.secondSectionGrid}>
                <Typography className={classes.sectionTitleTextStyle}>
                  {l10n.foundation.applicationContent.yourLoanLabel}
                </Typography>
              </Grid>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
                  <div className={classes.fieldTitleStyle}>
                    {l10n.foundation.applicationContent.loanOptionLabel} <br />
                    <NativeSelect
                      fullWidth
                      onChange={handleApplicantLoanOptionChange}
                      name={
                        l10n.foundation.applicationContent.fieldNames
                          .applicantLoanOption
                      }
                      value={applicantLoanOption}
                      required={true}
                      className={classes.nativeSelectDropStyle}
                    >
                      {loanOptions}
                    </NativeSelect>
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
                  <div className={classes.fieldTitleStyle}>
                    {l10n.foundation.applicationContent.loanProductTypeLabel}
                    <br />
                    <NativeSelect
                      fullWidth
                      onChange={this.handleFormStateChange}
                      required={true}
                      name={
                        l10n.foundation.applicationContent.fieldNames
                          .applicantLoanProductType
                      }
                      value={applicantLoanProductType}
                      className={classes.nativeSelectDropStyle}
                    >
                      {loanProductTypes}
                    </NativeSelect>
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid
                  item
                  xs={12}
                  sm={6}
                  className={`${classes.fieldTitleStyle} ${classes.gridItemStyle}`}
                >
                  <TextValidator
                    fullWidth
                    onChange={this.handleFormStateChange}
                    name={
                      l10n.foundation.applicationContent.fieldNames
                        .applicantFinancingAmount
                    }
                    value={applicantFinancingAmount}
                    className={classes.financingrequestedStyle}
                    label={
                      l10n.foundation.applicationContent
                        .totalFinancingAmountLabel
                    }
                    validators={[
                      l10n.foundation.applicationContent.errorMessages.required,
                      l10n.foundation.applicationContent.errorMessages
                        .financingAmountRegex
                    ]}
                    errorMessages={[
                      l10n.foundation.applicationContent.fieldRequiredLabel,
                      l10n.foundation.applicationContent.errorMessages
                        .invalidAmount
                    ]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    type="tel"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.downpaymentStyle}>
                  <TextValidator
                    fullWidth
                    onChange={this.handleFormStateChange}
                    name={
                      l10n.foundation.applicationContent.fieldNames
                        .applicantDownpaymentAmount
                    }
                    value={applicantDownpaymentAmount}
                    label={
                      l10n.foundation.applicationContent.downpaymentAmountLabel
                    }
                    validators={[
                      l10n.foundation.applicationContent.errorMessages.required,
                      l10n.foundation.applicationContent.errorMessages
                        .applicantdownpaymentAmountRegex
                    ]}
                    errorMessages={[
                      l10n.foundation.applicationContent.fieldRequiredLabel,
                      l10n.foundation.applicationContent.errorMessages
                        .invalidAmount
                    ]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    type="tel"
                  />
                </Grid>
              </Grid>
            </>
          )
        }}
      </OptionalQuery>
    )
  }
}

export default YourLoan
