import React from 'react'
import { Typography } from '@material-ui/core'

const FieldErrorMessage = ({ classes, errorMessage }) => (
  <Typography className={classes.editInfoErrorMessage} align="center">
    {errorMessage}
  </Typography>
)

export default FieldErrorMessage
