// @flow

export const taskTypesEnum = Object.freeze({
  applicantInformation: 'APPLICANT_INFORMATION',
  consentToContact: 'CONSENT_TO_CONTACT',
  documentsUpload: 'DOCUMENTS_UPLOAD',
  esignPerfection: 'ESIGN_PERFECTION',
  hiaUpload: 'document-hia',
  welcomeCall: 'WELCOME_CALL'
})

export type TaskTypes = $Values<typeof taskTypesEnum>

export default taskTypesEnum
