// @flow
import React from 'react'
import { get } from 'lodash'
import { Query } from '@apollo/client/react/components'
import customerStatuses from 'constants/enums/customerStatuses'
import { getCustomerIdFromProps } from 'util/NavigationHelper'
import Routes from 'util/Routes'
import Loadable from 'util/Loadable'
import GetCustomerStatus from 'queries/GetCustomerStatus.graphql'

const Setup = Loadable(import('screens/setup/Setup'))

// Ensures Setup is not rendered for cancelled customers
const SetupGuard = props => {
  const customerId = getCustomerIdFromProps(props)
  const redirectToCustomer = () => {
    props.history.replace(Routes.customer(customerId))
    return null
  }
  if (!customerId) {
    return redirectToCustomer()
  }

  return (
    <Query query={GetCustomerStatus} variables={{ customerId }}>
      {({ data = {}, loading }) => {
        if (loading) return null
        const status = get(data, 'customer.status', customerStatuses.active)
        if (status === customerStatuses.cancelled) {
          return redirectToCustomer()
        }
        return <Setup {...props} />
      }}
    </Query>
  )
}

export default SetupGuard
