import { MAXIMUM_LOAN_AMOUNT } from 'properties/properties'

const quoteError = 'quote_error'

export const quoteErrorTypes = {
  EXCEEDS_MAXIMUM_LOAN_AMOUNT: 0,
  BELOW_MINIMUM_LOAN_AMOUNT: 1,
  NO_LOAN_PRODUCT: 2
}

export const quoteErrors = {
  [quoteErrorTypes.NO_LOAN_PRODUCT]: {
    __typename: quoteError,
    type: quoteErrorTypes.NO_LOAN_PRODUCT,
    value: 0 // passing 0 to avoid an error
  },
  [quoteErrorTypes.BELOW_MINIMUM_LOAN_AMOUNT]: minimumLoanAmount => ({
    __typename: quoteError,
    type: quoteErrorTypes.BELOW_MINIMUM_LOAN_AMOUNT,
    value: minimumLoanAmount
  }),
  [quoteErrorTypes.EXCEEDS_MAXIMUM_LOAN_AMOUNT]: {
    __typename: quoteError,
    type: quoteErrorTypes.EXCEEDS_MAXIMUM_LOAN_AMOUNT,
    value: MAXIMUM_LOAN_AMOUNT
  }
}
