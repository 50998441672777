// @flow
import React, { useContext } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Button, Typography, CircularProgress } from '@material-ui/core'
import TranslationsContext from 'util/TranslationsContext'
import { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import { CIRCULAR_PROGRESS_SIZE } from 'properties/properties'
import { buttonMinWidth } from 'themes/default'

const {
  FIELDSET_ABOUT,
  FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL,
  FIELDSET_APPLY,
  FIELDSET_PUBLIC_APPLICATION
} = fieldsetNames

const styles = theme => ({
  nextBtn: {
    marginTop: 0,
    ...buttonMinWidth
  },
  circularProgress: {
    marginRight: theme.spacing(1)
  }
})

type Props = {
  classes: any,
  size: number,
  className: string,
  fieldset: string,
  fullWidth: boolean,
  disabled: boolean,
  submitting: boolean,
  centerRipple: boolean,
  showsPleaseWait: boolean
}

const defaultProps = {
  className: '',
  disabled: false,
  fullWidth: false
}

const NextButton = React.forwardRef<Props, any>(
  (
    { classes, fieldset, submitting, showsPleaseWait = false, ...rest }: Props,
    ref
  ) => {
    const l10n = useContext(TranslationsContext)

    const labels = {
      [FIELDSET_ABOUT]: l10n.customer.next,
      [FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL]: l10n.customer.next,
      [FIELDSET_APPLY]: l10n.apply.submit,
      [FIELDSET_PUBLIC_APPLICATION]: l10n.apply.submit
    }

    return (
      <Button
        {...defaultProps}
        ref={ref}
        className={classes.nextBtn}
        variant="contained"
        color="primary"
        type="submit"
        data-testid="next"
        {...rest}
      >
        {submitting ? (
          <>
            <CircularProgress
              size={CIRCULAR_PROGRESS_SIZE}
              className={classes.circularProgress}
            />
            {showsPleaseWait && (
              <Typography>{l10n.customer.pleaseWait}</Typography>
            )}
          </>
        ) : (
          <Typography>{labels[fieldset] || l10n.customer.next}</Typography>
        )}
      </Button>
    )
  }
)

export default withStyles(styles)(NextButton)
