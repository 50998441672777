import React from 'react'
import LaunchDarklyContext from 'util/LaunchDarklyContext'

export default function withLaunchDarkly(WrappedComponent) {
  return React.forwardRef((props, ref) => (
    <LaunchDarklyContext.Consumer>
      {launchDarklyContext => (
        <WrappedComponent ref={ref} {...launchDarklyContext} {...props} />
      )}
    </LaunchDarklyContext.Consumer>
  ))
}
