import { isEmpty, every, isNull } from 'lodash'
import { sortLoanProducts } from 'util/LoanProductHelper'
import l10n from 'properties/translations'
import financingOptions from 'constants/enums/financingOptions'
import { projectTypeKeys } from 'constants/enums/projectTypes'
import loanProductSortableFields from 'constants/enums/loanProductSortableFields'
import { projectIsNullOrOtherType } from 'screens/quote/components/inputs/Projects'

export type ServiceOption = {
  value: number,
  text: string
}

export const defaultServiceOptions: Array<ServiceOption> = [
  { value: 0, text: 'None' },
  { value: 1, text: '1 maintenance plan' },
  { value: 2, text: '2 maintenance plans' },
  { value: 3, text: '3 maintenance plans' },
  { value: 4, text: '4 maintenance plans' },
  { value: 5, text: '5 maintenance plans' },
  { value: 6, text: '6 maintenance plans' }
]

export type LoanProduct = {
  loanProductId: string,
  loanProductType: string,
  productName: string,
  loanMonthTerm: number,
  minimumAmount: number,
  borrowingLimit: number,
  apr: number,
  interestRate: number,
  numMonthlyPayments: number,
  servicePlanAllowed: boolean,
  numMonthlyPaymentsPromo: number,
  aprPromo: number,
  monthlyPaymentPromo: number,
  promoPeriod: number,
  financingOptions: Array<string>
}

export type LoanProductWithMonthlyPayments = {
  id: number,
  loanProductType: string,
  displayName: string,
  loanTenorMonths: number,
  minimumAmount: number,
  borrowingLimit: number,
  dealerFee: number,
  maximumAmount: number,
  promoPeriod: number,
  interestRate: number,
  interestRateDiscounted: number,
  reamortizationPeriod: number,
  defermentPeriods: number,
  promoRate: number,
  servicePlanAllowed: boolean,
  financingOptions: [],
  //"__typename": "LoanProductResponse",
  sampleTerms: {
    apr: number,
    paymentFactor: number,
    monthlyPayment: number,
    aprPromo: number,
    numMonthlyPayments: number,
    numMonthlyPaymentsPromo: number,
    monthlyPaymentPromo: number
    //"__typename": "ContractTermsResponse"
  }
}

export type Stage = {
  id: string,
  name: string,
  amount: string
  //"__typename": "quote_project_stage"
}

export type Project = {
  id: string,
  projectType: number,
  amount: number,
  notes: string,
  projectName: string,
  stages: Array<Stage>,
  addOns: Array<ProjectAddOn>
  //__typename: 'project'
}

export type MonthlyPaymentProps = {
  classes: Object,
  label: string,
  value: Node,
  notAvailable?: boolean
}

export const buildLoanProduct = loanProduct => {
  return {
    loanProductId: loanProduct.id,
    loanProductType: loanProduct.loanProductType,
    productName: loanProduct.displayName,
    loanMonthTerm: loanProduct.loanTenorMonths,
    minimumAmount: loanProduct.minimumAmount,
    maximumAmount: loanProduct.maximumAmount,
    borrowingLimit: loanProduct.borrowingLimit,
    apr: loanProduct.sampleTerms.apr,
    interestRate: loanProduct.interestRate,
    numMonthlyPayments: loanProduct.sampleTerms.numMonthlyPayments,
    servicePlanAllowed: loanProduct.servicePlanAllowed,
    numMonthlyPaymentsPromo: loanProduct.sampleTerms.numMonthlyPaymentsPromo,
    aprPromo: loanProduct.sampleTerms.aprPromo,
    monthlyPaymentPromo: loanProduct.sampleTerms.monthlyPaymentPromo,
    monthlyPayment: loanProduct.monthlyPayment,
    promoPeriod: loanProduct.promoPeriod,
    financingOptions: loanProduct.financingOptions
  }
}

export const getSortedLoanProducts = (
  loanProducts: LoanProduct[]
): LoanProduct[] =>
  loanProducts.sort((first, second) =>
    sortLoanProducts(first, second, loanProductSortableFields)
  )

export const getLabel =
  (servicePlanSelected: boolean, numberOfPromoMonths: number): Function =>
  (combined, regular): string =>
    `${
      servicePlanSelected
        ? l10n.apply.productSelection.monthlyPayments[combined]
        : l10n.apply.productSelection.monthlyPayments[regular]
    } ${numberOfPromoMonths}`

export const findLoanProduct = (
  loanProducts: LoanProduct[],
  selectedLoanProductId: string
): LoanProduct => {
  const foundLoanProduct = loanProducts.find(
    product =>
      (product.loanProductId &&
        product.loanProductId === selectedLoanProductId) ||
      (product.id && product.id === selectedLoanProductId)
  )

  return foundLoanProduct ? foundLoanProduct : {}
}

export const isSelectedLoanProductIncompatibleWithBatteryOnly = (
  selectedLoanProduct: LoanProduct
): boolean => {
  if (!isEmpty(selectedLoanProduct)) {
    return !selectedLoanProduct.financingOptions.includes(
      financingOptions.batteryOnly
    )
  }

  return true
}

export const isBatteryOnly = (
  currentProjectsEnumsArr: Array<string>
): boolean =>
  !isEmpty(currentProjectsEnumsArr) &&
  every(currentProjectsEnumsArr, el => el === projectTypeKeys.BATTERY)

export const isProjectNameValid = (projects: Array<Project>): boolean =>
  projects.some(project => projectIsNullOrOtherType(project))

export const getProjectsWithServicePlan = (servicePlans, selectedProjects) =>
  servicePlans.flatMap(addOnProjectType =>
    selectedProjects.filter(
      selectedProject =>
        addOnProjectType.projectType === selectedProject.projectType
    )
  )

export const hasProjectsWithAmount = projects => {
  if (!projects) return false

  return projects.some(project => !isNull(project.amount))
}

export const getAddonsOfTypeServicePlan = addOns =>
  addOns.filter(addOn => addOn.code === l10n.quote.servicePlan.code)

export const findServicePlanWithCode = (
  addOns,
  code = l10n.quote.servicePlan.code
) => addOns.find(addOn => addOn.code === l10n.quote.servicePlan.code)

export const removeTypeName = obj => {
  const newObj: Object = { ...obj }
  delete newObj.__typename

  return newObj
}

export const getMinBorrowingLimit = (firstLimit, secondLimit) =>
  Math.min(firstLimit, secondLimit).toFixed(2)

export const buildAddOnObject = (code, price) => ({
  code: l10n.quote.servicePlan.code,
  price,
  __typename: 'addOn'
})
export const getExistingProjectWithServicePlan = projectsWithServicePlan =>
  projectsWithServicePlan.find(
    project => project.addOns && project.addOns.length !== 0
  )
