export default {
  isEmail: 'isEmail',
  matchRegexp: 'matchRegexp',
  minStringLength: 'minStringLength',
  maxStringLength: 'maxStringLength',
  minNumber: 'minNumber',
  maxNumber: 'maxNumber',
  isValidLastName: 'isValidLastName',
  required: 'required',
  isYearValid: 'isYearValid',
  noBlankSpaces: 'noBlankSpaces',
  isValidCalendarDate: 'isValidCalendarDate'
}
