import React, { useEffect, useContext } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { useQuery } from '@apollo/client'
import GetCustomerByApplicationId from 'screens/apply/queries/GetCustomerByApplicationId.graphql'
import Loading from 'components/shared/Loading'
import Routes from 'util/Routes'
import UserContext from 'util/UserContext'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'

const ApplicationToCustomer = props => {
  const { applicationId, ldFlags } = props
  const userContext = useContext(UserContext)
  const { me } = userContext
  const { loading, data } = useQuery(GetCustomerByApplicationId, {
    variables: { id: applicationId }
  })

  if (loading) return <Loading />

  const { customerByApplicationId } = data

  return (
    <Redirect
      to={Routes.pendingState({ id: customerByApplicationId.id }, me, ldFlags)}
    />
  )
}

export default ApplicationToCustomer

const UnwrappedApplicationToCustomerScreen = ({
  match,
  overrideHeader,
  ldFlags
}) => {
  useEffect(() => {
    overrideHeader({ hidden: true })
  }, [overrideHeader])
  return (
    <ApplicationToCustomer
      applicationId={match.params.applicationId}
      ldFlags={ldFlags}
    />
  )
}

export const ApplicationToCustomerScreen = withRouter(
  withLaunchDarkly(UnwrappedApplicationToCustomerScreen)
)
