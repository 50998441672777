import FingerprintJS from '@fingerprintjs/fingerprintjs'

import { isTestingEnv } from 'util/EnvironmentHelper'

const v2 = 'v2'

let fingerprintJS

export const loadFingerprintJS = async () => {
  fingerprintJS = await FingerprintJS.load()
}

export const getDeviceFingerprint = async () => {
  // Get device fingerprint
  if (window.Cypress || isTestingEnv()) {
    return '060675b0dc4315074bc52dR727bde1cd2'
  }

  if (!fingerprintJS) {
    await loadFingerprintJS()
  }

  try {
    const { visitorId } = await fingerprintJS.get()
    return `${v2}_${visitorId}` // Prepend hashes with v2 so we know that this fingerprint is post-upgrade
  } catch (e) {
    return `${v2}_fingerprint_failed_${new Date().getTime()}`
  }
}
