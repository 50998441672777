// @flow
import React, { useContext, useState, useEffect } from 'react'
import { filter, get, merge, noop, head, flow, has } from 'lodash'
import throttle from 'lodash/throttle'
import isString from 'lodash/isString'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import {
  FormControl,
  Grid,
  Input,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import projectTypeMappings, {
  projectTypeMappingsWithoutSolar
} from 'constants/enums/projectTypeMappings'
import projectTypesId from 'constants/enums/projectTypeId'
import QuoteContext, {
  defaultChannelPartnerProjectConfiguration
} from 'util/QuoteContext'
import {
  projectTypeToId,
  projectTypeIdToEnumValue,
  projectTypeKeys
} from 'constants/enums/projectTypes'
import {
  DOLLAR_PREFIX,
  THROTTLE_CHANGE_MS,
  PROJECT_COST_CHANGE_DEBOUNCE_TIME,
  DESCRIPTION_MAX_LENGTH,
  CUSTOM_PROJECT_MAX_LENGTH,
  EMPTY_STRING,
  ZERO_TIMEOUT
} from 'properties/properties'
import l10n from 'properties/translations'
import { getIsProjectTypeCustomization } from 'constants/enums/featureFlags'
import type {
  ProjectStage,
  CustomerProjectConfiguration
} from 'screens/quote/QuoteTypes'
import {
  stagesTotal,
  safeStageAmount,
  reduceAmounts,
  isSolarOrBatteryProject
} from 'util/ApplyHelper'
import {
  errorsForProjectRowStages,
  processStageNameValue,
  capitalizeRowText,
  nameValid,
  generateStageObject,
  getAmountAsString
} from 'util/ProjectRowHelper'
import NewCurrencyNumberFormat from 'components/shared/CurrencyNumberFormat'
import StageMenu from './StageMenu'
import projectRowStyles from './ProjectRow.styles'
import StagesContainer from './StagesContainer'
import fieldNames from './stageFieldNames.enum'
import stageActions from './stageActions.enum'
import projectActions from './projectActions.enum'
import NumberFormatCustom from './NumberFormatCustom'
import { ProjectRowContext } from 'util/ProjectRowContext'
import ProjectTypeSelect from './ProjectTypeSelect'
import { QuoteStore } from 'screens/quote/QuoteStore'
import CustomProjectField from './CustomProjectField'

const CurrencyNumberFormat = props => (
  <NewCurrencyNumberFormat displayType="input" prefix={null} {...props} />
)

export const findSelectedProjectType = (projectTypes, projectType) =>
  projectTypes.find(
    project => project.projectType === projectTypeIdToEnumValue[projectType]
  )

const styles = theme => ({
  ...projectRowStyles(theme),
  descriptionCtn: {
    marginTop: theme.spacing(3)
  },
  projectRowContainer: {
    marginBottom: theme.spacing(0.5)
  },
  hintContainer: { paddingTop: 6 }
})

const projectTypeKey = 'projectType'
const resetProjectType = 'resetProjectType'
const keysWithIntegerValues = [projectTypeKey]

const stageAmountAsString = (stages: Array<ProjectStage>): string =>
  reduceAmounts(stages).toFixed(0)

export const addStageOnCustomProject = async (
  addStage,
  updateProjectFn,
  stages,
  _amount
) => addStage(stages, updateProjectFn, { amount: safeStageAmount(_amount) })

export const handleChange =
  (
    props,
    updateProjectFn,
    toChange,
    addStage,
    projectId,
    isCustomFlag = false
  ) =>
  async event => {
    const {
      id,
      amount,
      getMonthlyPaymentsOnChange,
      updateProjectsOnParent,
      notes,
      projectName,
      stages
    } = props

    let projectType
    let newStages
    let newAmount = isString(amount) ? parseFloat(amount) : amount
    let newProjectName = projectName
    if (keysWithIntegerValues.includes(toChange)) {
      projectType = parseFloat(
        event && event.target ? event.target.value : projectId
      )
    } else if (resetProjectType.includes(toChange)) {
      // Reset to empty (no stages)
      projectType = EMPTY_STRING
      newAmount = parseFloat(stageAmountAsString(stages))
      newStages = []
      newProjectName = EMPTY_STRING
    } else {
      projectType = props.projectType
    }

    if (
      toChange === 'projectType' &&
      projectType === projectTypesId.otherOrCustom
    ) {
      if (isCustomFlag) {
        setTimeout(() => {
          addStageOnCustomProject(addStage, updateProjectFn, stages, amount)
        }, ZERO_TIMEOUT)
      } else {
        await addStageOnCustomProject(addStage, updateProjectFn, stages, amount)
      }
    }

    updateProjectFn({
      id,
      projectType,
      notes,
      amount: newAmount,
      projectName: newProjectName,
      stages: newStages
    })

    // If we define getMonthlyPaymentsOnChange, we need to run the function, updating monthly payments.
    // (Only needed on change events which actually change the monthly payment)
    if (getMonthlyPaymentsOnChange) {
      getMonthlyPaymentsOnChange()
    }
    // If we do not call getMonthlyPaymentsOnChange, which updates projects in the parent's state, we need to do so
    if (updateProjectsOnParent) {
      updateProjectsOnParent()
    }
  }

const projectTypeDescription = projectTypeInt =>
  get(
    projectTypeMappings.find(pt => pt.id === projectTypeInt),
    'description',
    ''
  )

const getMaxStages = currentlySelectedProjectType => {
  const defaultMaxStagesPerProject =
    defaultChannelPartnerProjectConfiguration.maxStagesPerProject
  return get(
    currentlySelectedProjectType,
    'stageConfiguration.maxStagesPerProject',
    defaultMaxStagesPerProject
  )
}
const WIDTH_WITH_ADORNMENT = 5
const WIDTH_FOR_TYPE = 7

type Props = {
  id: string,
  autoFocus: ?boolean,
  classes: { [string]: string },
  projectType: number | string,
  projectName: string,
  notes: string,
  amount: ?number,
  getMonthlyPaymentsOnChange: Function,
  readonly: ?boolean,
  removable: ?boolean,
  projectCount: number,
  stages: Array<ProjectStage>,
  editOffer: boolean,
  customerProjectConfiguration?: CustomerProjectConfiguration,
  toggleButtonDisplay: (arg: boolean) => void,
  ldFlags: any
}

type ProjectTypeDetail = {
  projectType: string,
  disbursementSequenceType: string,
  disbursementText: string,
  loanProductTypes: Array<string>,
  prerequisiteProjectType: string,
  id: number,
  projectTypeDisplayName: string
}

const appendProjectTypeDetails = (
  projectTypes: ProjectTypeConfiguration[]
): ProjectTypeDetail[] =>
  projectTypes.map(type => ({
    ...type,
    projectTypeDisplayName: type.projectTypeText,
    id: projectTypeToId[type.projectType]
  }))

const ProjectRow = (props: Props) => {
  const {
    amount = null,
    autoFocus = false,
    readonly = false,
    removable = true,
    editOffer = false,
    notes,
    projectName,
    updateProjectsOnParent,
    getMonthlyPaymentsOnChange,
    stages,
    projectType,
    customerProjectConfiguration,
    ldFlags,
    id,
    toggleButtonDisplay,
    projectCount,
    classes
  } = props

  const [amountState, setAmountState] = useState<string>(amount)
  const [projectNameState, setProjectNameState] = useState<string>(projectName)
  const [notesState, setNotesState] = useState<string>(notes)
  const [stageErrors, setStageErrors] = useState<any>({})
  const [dirtyFields, setDirtyFields] = useState<any>({})
  const [isCustom, setIsCustom] = useState<boolean>(false)
  const quoteContext = useContext(QuoteContext)

  let throttledHandleChange: ?Function = null
  let throttledGetMonthlyPaymentsOnChange: ?Function = null
  let throttledValidateAllStages: ?Function = null
  let throttledUpdateProjectsOnParent: ?Function = null

  const checkProjectType = () => {
    const { channelPartnerProjectConfiguration } = quoteContext

    const projectTypes = editOffer
      ? get(customerProjectConfiguration, 'projectTypes', [])
      : get(channelPartnerProjectConfiguration, 'projectTypes', [])

    const isProjectTypeCustomization = getIsProjectTypeCustomization(ldFlags)
    const projectTypesWithDetails = appendProjectTypeDetails(projectTypes)

    const availableProjectTypes =
      isProjectTypeCustomization ||
      projectTypeMappingsWithoutSolar ||
      customerProjectConfiguration
        ? projectTypesWithDetails
        : projectTypeMappingsWithoutSolar

    const enabledProjectTypes = filter(availableProjectTypes, el => el.enabled)

    if (
      (!projectType || projectType === '') &&
      enabledProjectTypes.length === 1
    ) {
      const newStages = []

      const isCustomFlag =
        head(enabledProjectTypes).projectType === projectTypeKeys.CUSTOM

      if (isCustomFlag) {
        const generatedState = generateStageObject({
          amount: safeStageAmount(amount)
        })

        newStages.push(generatedState)
      }

      QuoteStore.updateProject({
        id,
        projectType: head(enabledProjectTypes).id,
        notes,
        amount,
        projectName,
        stages: newStages
      })

      updateProjectsOnParent()
    }
  }

  useEffect(() => {
    checkProjectType()
  })

  const keyPressHandler = (args = {}) => {
    const {
      fieldName,
      value,
      updateProjectFn,
      projectType,
      toggleButtonDisplay = noop
    } = args

    let projectNameAux = projectName
    let amountAux = amountState
    let notesAux = notesState

    const fieldsActions = {
      [fieldNames.amount]: () => {
        amountAux = getAmountAsString(value)
      },
      [fieldNames.projectName]: () => {
        if (value.length > CUSTOM_PROJECT_MAX_LENGTH) {
          return
        }
        toggleButtonDisplay(!value)
        projectNameAux = capitalizeRowText(value)
      },
      [fieldNames.notes]: () => {
        if (value.length > DESCRIPTION_MAX_LENGTH) {
          return
        }
        notesAux = value
      }
    }

    if (has(fieldsActions, fieldName)) {
      fieldsActions[fieldName]()
    }

    setDirtyFields({ ...dirtyFields, [fieldName]: true })

    setAmountState(amountAux)
    setProjectNameState(projectNameAux)
    setNotesState(notesAux)

    if (!throttledHandleChange) return
    throttledHandleChange(
      {
        id,
        projectType,
        amount: amountAux,
        notes: notesAux,
        projectName: projectNameAux,
        getMonthlyPaymentsOnChange: throttledGetMonthlyPaymentsOnChange
      },
      updateProjectFn,
      fieldName
    )
  }

  const validateAllStages = () => {
    const { stages, projectType } = props
    const {
      channelPartnerProjectConfiguration: { projectTypes = [] }
    } = quoteContext

    const getProjectTypeConfiguration = () =>
      get(
        findSelectedProjectType(projectTypes, projectType),
        'stageConfiguration',
        {}
      )
    const projectConfigurationToUse = getProjectTypeConfiguration()

    setStageErrors(
      errorsForProjectRowStages(
        stages || [],
        stageErrors,
        projectConfigurationToUse
      )
    )
  }

  throttledValidateAllStages = throttle(
    validateAllStages,
    PROJECT_COST_CHANGE_DEBOUNCE_TIME
  )

  throttledUpdateProjectsOnParent = throttle(
    updateProjectsOnParent || noop,
    PROJECT_COST_CHANGE_DEBOUNCE_TIME
  )

  const addStage = async (stages, updateProjectFn, stageProps = {}) => {
    const safeStages = stages || []
    const newStages = [...safeStages]
    const generatedState = generateStageObject(stageProps)
    newStages.push(generatedState)

    const errorObj = {}
    errorObj[generatedState.id] = {
      showNameError: false,
      showAmountError: false
    }
    setStageErrors(merge(stageErrors, errorObj))

    updateProjectFn({
      id,
      stages: newStages
    })
  }

  const deleteProjectStage = deleteProjectStagesFn => async stageId => {
    QuoteStore.deleteStage({ id, stageId })

    if (throttledGetMonthlyPaymentsOnChange) {
      throttledGetMonthlyPaymentsOnChange()
    }
  }

  const handleStageField = updateProjectFn => async args => {
    const { stageId, fieldName, value } = args

    const stageToUpdate: any = {
      ...stages.find(element => element.id === stageId)
    }

    const isNameField = fieldName === fieldNames.name

    let newValue = value
    if (isNameField) {
      newValue = processStageNameValue(newValue)
    }
    stageToUpdate[fieldName] = newValue

    const updatedStages = stages.map(item =>
      item.id === stageToUpdate.id ? stageToUpdate : item
    )

    updateProjectFn({
      id,
      stages: updatedStages
    })

    if (isNameField && isString(value) && value.length < 2) {
      // Validate when typing name field for more responsive feedback when clearing out the stage name.
      validateAllStages()
    }
  }

  const handleStageFieldAmount = updateProjectFn => async args => {
    const { stageId, fieldName, value } = args

    if (fieldNames.amount !== fieldName) {
      return
    }
    const stageToUpdate: ?ProjectStage = {
      ...stages.find(element => element.id === stageId)
    }

    if (!stageToUpdate) return

    stageToUpdate[fieldName] = value

    const updatedStages = stages.map(item =>
      item.id === stageToUpdate.id ? stageToUpdate : item
    )

    updateProjectFn({
      id,
      stages: updatedStages
    })

    if (throttledGetMonthlyPaymentsOnChange) {
      throttledGetMonthlyPaymentsOnChange()
    }

    if (throttledValidateAllStages) {
      throttledValidateAllStages()
    }
  }

  const handleProjectDeletion = async (_id, deleteProjectFn) => {
    deleteProjectFn({ id: _id })

    if (throttledGetMonthlyPaymentsOnChange) {
      throttledGetMonthlyPaymentsOnChange()
    }
  }

  const buildDeleteStageObject = deleteProjectFn => {
    if (readonly || !removable) return {}
    return {
      [projectActions.deleteProject]: () =>
        handleProjectDeletion(id, deleteProjectFn)
    }
  }

  const buildAddStageObject = (
    stagesEnabled: boolean,
    updateProjectFn: Function,
    maxStageLimitReached: boolean
  ) => {
    if (readonly || !stagesEnabled || maxStageLimitReached) return {}
    return {
      [stageActions.add]: () => addStage(stages, updateProjectFn)
    }
  }

  const removeProjectStages = updateProjectFn => async () => {
    setAmountState(stageAmountAsString(stages))
    setProjectNameState(EMPTY_STRING)
    setIsCustom(true)

    handleChange(props, updateProjectFn, resetProjectType).call()
  }

  const handleBlur = () => {
    if (throttledUpdateProjectsOnParent) {
      throttledUpdateProjectsOnParent()
    }
    if (throttledValidateAllStages) {
      throttledValidateAllStages()
    }
  }

  const { channelPartnerProjectConfiguration } = quoteContext
  const isProjectTypeCustomization = getIsProjectTypeCustomization(ldFlags)
  const projectTypes = editOffer
    ? get(customerProjectConfiguration, 'projectTypes', [])
    : get(channelPartnerProjectConfiguration, 'projectTypes', [])
  const projectTypesWithDetails = appendProjectTypeDetails(projectTypes)

  const availableProjectTypes =
    isProjectTypeCustomization ||
    projectTypeMappingsWithoutSolar ||
    customerProjectConfiguration
      ? projectTypesWithDetails
      : projectTypeMappingsWithoutSolar

  const isCustomProject = projectType === projectTypesId.otherOrCustom

  const stagesEnabled = isCustomProject
  const currentlySelectedProjectType = findSelectedProjectType(
    projectTypes,
    projectType
  )
  const maxStages = getMaxStages(currentlySelectedProjectType)
  const maxStagesReached =
    stagesEnabled && stages && maxStages && stages.length >= maxStages

  const hasAdornment = !readonly && (stagesEnabled || removable)
  const projectNameError = !nameValid(projectNameState)

  const enabledProjectTypes = filter(availableProjectTypes, el => el.enabled)

  const updateProjectFn = QuoteStore.updateProject
  const deleteProjectStagesFn = QuoteStore.deleteStage
  const deleteProjectFn = QuoteStore.deleteProject

  // Setup throttled Fns
  if (!throttledHandleChange) {
    throttledHandleChange = throttle((input, updateMutationFn, fieldName) => {
      handleChange(input, updateMutationFn, fieldName).call()
    }, THROTTLE_CHANGE_MS)
  }

  if (!throttledGetMonthlyPaymentsOnChange) {
    throttledGetMonthlyPaymentsOnChange = throttle(
      getMonthlyPaymentsOnChange,
      THROTTLE_CHANGE_MS,
      { leading: false }
    )
  }

  return (
    <ProjectRowContext.Provider
      value={{
        stageConfiguration: get(
          currentlySelectedProjectType,
          'stageConfiguration',
          {}
        )
      }}
    >
      <>
        <Grid
          alignItems="center"
          container
          spacing={1}
          className={classes.projectRowContainer}
        >
          <Grid
            className={classes.projectType}
            data-testid="ProjectRow-projectType"
            item
            xs={WIDTH_FOR_TYPE}
          >
            {isCustomProject ? (
              <CustomProjectField
                projectName={projectNameState}
                readonly={readonly}
                handleBlur={handleBlur}
                keyPressHandler={keyPressHandler}
                updateProjectFn={updateProjectFn}
                projectType={projectType}
                toggleButtonDisplay={toggleButtonDisplay}
                removeProjectStages={removeProjectStages}
                enabledProjectTypes={enabledProjectTypes}
                dirtyFields={dirtyFields}
                projectNameError={projectNameError}
              />
            ) : (
              <>
                {readonly ? (
                  <FormControl fullWidth>
                    <Input
                      value={projectTypeDescription(projectType)}
                      disabled
                    />
                  </FormControl>
                ) : (
                  <ProjectTypeSelect
                    handleChange={handleChange}
                    handleChangeProps={props}
                    updateProjectFn={updateProjectFn}
                    projectTypeKey={projectTypeKey}
                    addStage={addStage}
                    availableProjectTypes={availableProjectTypes}
                    isCustom={isCustom}
                    projectType={projectType}
                    readonly={readonly}
                    isProjectTypeCustomization={isProjectTypeCustomization}
                    customerProjectConfiguration={
                      props.customerProjectConfiguration
                    }
                  />
                )}
              </>
            )}
          </Grid>

          <Grid item xs={WIDTH_WITH_ADORNMENT} className={classes.costWrapper}>
            <TextField
              autoFocus={autoFocus}
              fullWidth
              type="tel"
              value={stagesTotal(
                stages,
                amountState,
                isSolarOrBatteryProject(projectType)
              )}
              placeholder="0"
              name="amount-input"
              data-testid="amount-input"
              disabled={readonly || isCustomProject}
              onBlur={handleBlur}
              onChange={({ target: { value } }) => {
                if (readonly || isCustomProject) return
                keyPressHandler({
                  fieldName: fieldNames.amount,
                  value,
                  updateProjectFn,
                  projectType
                })
              }}
              InputProps={{
                inputComponent:
                  readonly || isCustomProject
                    ? CurrencyNumberFormat
                    : NumberFormatCustom,
                startAdornment: (
                  <InputAdornment position="start">
                    {DOLLAR_PREFIX}
                  </InputAdornment>
                ),
                endAdornment: hasAdornment && (
                  <InputAdornment position="end">
                    <StageMenu
                      actions={{
                        ...buildAddStageObject(
                          stagesEnabled,
                          updateProjectFn,
                          maxStagesReached
                        ),
                        ...buildDeleteStageObject(deleteProjectFn)
                      }}
                      projectCount={projectCount}
                      disabled={readonly || maxStagesReached}
                    />
                  </InputAdornment>
                )
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              inputProps={{
                'aria-label': 'amount-input'
              }}
            />
          </Grid>
        </Grid>

        {/* Error row */}
        {dirtyFields[fieldNames.projectName] && projectNameError && (
          <Grid item xs={10} className={classes.errorContainer}>
            <Typography
              data-testid="stage-error-message"
              variant="caption"
              className={classes.errorMessage}
            >
              {isCustomProject && l10n.quote.projectRow.projectNameError}
            </Typography>
          </Grid>
        )}

        {/* stagesContainer: Render all of the stages */}
        {stagesEnabled && (
          <StagesContainer
            readonly={readonly}
            autoFocusNewStage={stages && stages.length > 1}
            stages={stages}
            handleStageField={handleStageField(updateProjectFn)}
            handleStageFieldAmount={handleStageFieldAmount(updateProjectFn)}
            handleStageDeletion={deleteProjectStage(deleteProjectStagesFn)}
            handleBlur={handleBlur}
            maxStagesReached={maxStagesReached}
            errors={stageErrors}
          />
        )}

        {/* Optional description */}
        {!(readonly && notes === '') && (
          <Grid container alignItems="center">
            <TextField
              placeholder={l10n.quote.descriptionOptional}
              value={notes}
              disabled={readonly}
              fullWidth
              multiline
              rows={1}
              className={cx({
                [classes.optDescription]: isCustomProject
              })}
              onBlur={handleBlur}
              onChange={({ target: { value } }) =>
                keyPressHandler({
                  fieldName: fieldNames.notes,
                  value,
                  updateProjectFn,
                  projectType
                })
              }
            />
          </Grid>
        )}

        {/* Hint row */}
        {/* AEA-1429: hide disbursement schedules */}
        {/* {Boolean(projectType) && (
          <Grid xs={12} item className={classes.hintContainer}>
            <Typography variant="caption">
              {currentlySelectedProjectType &&
                currentlySelectedProjectType.disbursementText}
            </Typography>
          </Grid>
        )} */}
      </>
    </ProjectRowContext.Provider>
  )
}

export default flow(withLaunchDarkly, withStyles(styles))(ProjectRow)
