// @flow
import React from 'react'
import { Typography, Dialog, Grid, ButtonBase } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import l10n from 'properties/translations'
import LandlineConfirmDialog from 'components/shared/LandlineConfirmDialog'
import styles from './LandlineWarning.styles'

type Props = {
  classes: Object,
  landlineWarning: boolean,
  displayLandlineConfirmModal: () => void,
  hideLandlineConfirmModal: () => void,
  allowLandlineNumber: () => void
}

const LandlineWarning = ({
  classes,
  landlineWarning,
  displayLandlineConfirmModal,
  hideLandlineConfirmModal,
  allowLandlineNumber
}: Props) => {
  return (
    <Grid container direction="row" component="span">
      <Typography className={classes.landlineWarning} component="span">
        {
          l10n.apply.mobileVerificationScreen.phoneErrorTypes.landlineWarning
            .title
        }
        <ButtonBase
          data-testid="alt-phone-toggle"
          onClick={displayLandlineConfirmModal}
          className={classes.captionButton}
          tabIndex={-1}
        >
          {
            l10n.apply.mobileVerificationScreen.phoneErrorTypes.landlineWarning
              .iDoNotOwnMobileLink
          }
        </ButtonBase>
      </Typography>
      <Dialog open={landlineWarning}>
        <LandlineConfirmDialog
          classes={classes}
          hideLandlineConfirmModal={hideLandlineConfirmModal}
          allowLandlineNumber={allowLandlineNumber}
        />
      </Dialog>
    </Grid>
  )
}

export default withStyles(styles)(LandlineWarning)
