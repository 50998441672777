// @flow
import Routes from 'util/Routes'
import { get, isEmpty, intersection, values } from 'lodash'
import userRoles from 'constants/enums/userRoles'
import accessModeKeys from 'constants/enums/accessModeKeys'
import permissionsEnum from 'constants/enums/permissions'

const installerRoles = values(userRoles.installer)

export const isInstaller = (roles: string[]) =>
  userRolesMatch(roles, installerRoles)

export const userRolesMatch = (userRoles: string[], rolesToMatch: string[]) => {
  const matchedRoles = intersection(rolesToMatch, userRoles)
  return !isEmpty(matchedRoles)
}

export const startRoute = (
  myUserRoles: Array<string>,
  myAccessModes: Array<string>,
  ldFlags: any
) => {
  const hasLiveModeAccess = myAccessModes.includes(accessModeKeys.live)
  const shouldStartOnAdminRoute = isInstaller(myUserRoles) && !hasLiveModeAccess

  return shouldStartOnAdminRoute ? Routes.admin() : Routes.start()
}

export const userHasPermission = (
  me: AboutMeResponse,
  permissionCheck: string | Array<string>
) => {
  const mePermissions = get(me, 'permissions', [])

  if (
    mePermissions.includes(permissionsEnum.FULL_ACCESS) ||
    isEmpty(permissionCheck)
  ) {
    return true
  }

  if (Array.isArray(permissionCheck)) {
    return permissionCheck.some(element => mePermissions.includes(element))
  }

  return mePermissions.includes(permissionCheck)
}

export const hasPartialCreditApplicationPermission = (
  permissions: Array<string>
): boolean => permissions.includes(permissionsEnum.PARTIAL_CREDIT_APPLICATION)
