import { gql } from 'graphql.macro'

export default gql`
  fragment ContractTermsFragmentDiscounted on ContractTermsResponse {
    financeChargeDiscounted
    monthlyPayment
    monthlyPaymentDiscounted
    aprDiscounted
    annualRatePercentageDiscounted
    totalPaymentsDiscounted
    finalMonthlyPayment
    finalMonthlyPaymentDiscounted
    numFinalPayments
    choiceMonthlyPayment
    choiceMonthlyPaymentDiscounted
    numPaymentsUntilReamortization
    estimatedFirstPaymentDate
    numRegularPaymentsAfterReamortization
    firstPaymentDateAfterReamortization
  }
`
