import coreScreenStyles from 'themes/coreScreenStyles'
import {
  colors,
  pageTitle,
  bodyTextGreen,
  bodyTextNormal
} from 'themes/default'

export default theme => ({
  ...coreScreenStyles(theme),
  resourcesNavItemTitle: {
    fontWeight: '600',
    marginBottom: 6
  },
  resourcesNavItem: {
    border: `1px solid ${theme.palette.primary.main}59`, // 59 adds 35% of opacity
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    position: 'inherit',
    '&:hover': {
      backgroundColor: '#f8f8f8'
    }
  },
  resourcesNavItemLink: {
    textDecoration: 'none'
  },
  resourcesNavCtn: {
    flexDirection: 'column',
    position: 'relative'
  },
  swiftlinkDescription: {
    marginBottom: 6,
    '& span': { color: colors.redAlert }
  },
  swiftlinkTitle: {
    fontWeight: 500
  },
  swiftlinkPortalMessage: {
    marginBottom: 18
  },
  pageTitle: {
    fontWeight: pageTitle.fontWeight,
    fontSize: pageTitle.fontSize,
    color: pageTitle.color
  },
  bodyTextGreen: {
    fontSize: bodyTextGreen.fontSize,
    fontWeight: bodyTextGreen.fontWeight,
    lineHeight: bodyTextGreen.lineHeight,
    color: bodyTextGreen.color
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  textCopyWrapper: {
    width: `calc(100%)`
  }
})
