import React from 'react'
import { SvgIcon } from '@material-ui/core'

const AppleShareIcon = props => (
  <SvgIcon {...props}>
    <path d="M9.11 4.23l2.35-2.34v12.85a.54.54 0 1 0 1.08 0V1.89l2.35 2.34a.48.48 0 0 0 .38.16.49.49 0 0 0 .38-.16.52.52 0 0 0 0-.76L12.38.2c-.05-.05-.11-.11-.16-.11a.66.66 0 0 0-.44 0c0 .05-.11.05-.16.11L8.35 3.47a.54.54 0 0 0 .76.76zm10.51 2.34h-4.35v1.09h4.35v15.25H4.38V7.66h4.35V6.57H4.38a1.09 1.09 0 0 0-1.09 1.09v15.25A1.09 1.09 0 0 0 4.38 24h15.24a1.09 1.09 0 0 0 1.09-1.09V7.66a1.09 1.09 0 0 0-1.09-1.09z" />
  </SvgIcon>
)

export default AppleShareIcon
