// @flow
import errorTypes from 'constants/enums/errorTypes'
import { get, split, nth } from 'lodash'
import { displayCustomError } from 'util/MessageHelper'
import { COMMA_SEPARATOR } from 'properties/properties'

export const errorHasGraphQLErrorType = (error: Error, type: string) => {
  const errorType = get(error, 'graphQLErrors[0].extensions.type')
  return errorType === type
}

export const errorFailedToLoadOnBapiDown = (error: Error) => {
  const errorType = error.toString().trim()
  return errorType.includes(errorTypes.internalServerError)
}

export const errorMessageForBlockedPhoneNumbers = (text: string) => {
  const errMsg = split(displayCustomError(nth(text, 1)), COMMA_SEPARATOR)
  const errCode = displayCustomError(nth(errMsg, 1))
  const errorType = nth(errMsg, 2)
  return [errMsg, errCode, errorType]
}

export const getErrorType = (error: Error) =>
  get(error, 'graphQLErrors[0].extensions.type')

export const getErrorKey = (error: Error) => get(error, 'errors[0]')

export default {
  errorHasGraphQLErrorType,
  errorFailedToLoadOnBapiDown,
  errorMessageForBlockedPhoneNumbers,
  getErrorType,
  getErrorKey
}
