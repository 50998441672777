// @flow
import merge from 'lodash/merge'
import Stripe from 'images/stripe15.png'
import backgroundDesktop from 'images/background-desktop3.jpg'
import backgroundMobile from 'images/background-mobile3.jpg'
import { inIframe } from 'util/EnvironmentHelper'

export default (theme: any) => {
  const paperContainerPadding = {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(2)
  }
  const paper = {
    container: {
      ...paperContainerPadding,
      [theme.breakpoints.up('md')]: {
        paddingTop: theme.spacing(4),
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
        paddingBottom: theme.spacing(7)
      }
    },
    containerIframe: {
      ...paperContainerPadding
    },
    root: {
      position: 'relative',
      margin: theme.spacing(1),
      borderRadius: 4,
      maxWidth: theme.breakpoints.values.md - theme.spacing(2),
      minHeight: 300,
      [theme.breakpoints.up('md')]: {
        marginRight: 'auto',
        marginLeft: 'auto'
      }
    },
    rootNoBackground: {
      margin: theme.spacing(1),
      borderRadius: 4,
      maxWidth: theme.breakpoints.values.md - theme.spacing(2),
      minHeight: 300,
      [theme.breakpoints.up('md')]: {
        marginRight: 'auto',
        marginLeft: 'auto'
      }
    }
  }

  const subheading = {
    textAlign: 'center',
    textTransform: 'uppercase',
    fontSize: theme.typography.fontSize,
    fontWeight: '900',
    paddingTop: theme.spacing(8),
    height: 115
  }

  return {
    rootNew: {
      overflowX: 'hidden',
      paddingBottom: theme.spacing(7),
      backgroundColor: theme.palette.background.secondary,
      backgroundImage: `url("${Stripe}")`,
      backgroundSize: 12,
      backgroundRepeat: 'repeat',
      minHeight: '100%'
    },
    root: {
      overflowX: 'hidden',
      paddingBottom: theme.spacing(7),
      // backgroundColor: theme.palette.background.backgroundImageAlternative, // TEMPORARY FIX
      minHeight: '100%'
    },
    rootLogoRight: {
      backgroundColor:
        theme.palette.background.backgroundImageAlternativeLogoRight
    },
    paper: merge({}, paper.container, paper.root),
    paperContainer: inIframe() ? paper.containerIframe : paper.container,
    paperContainerPaddingWithBackButton: {
      paddingTop: theme.spacing(5)
    },
    paperRoot: paper.root,
    estimateWrapper: {
      margin: theme.spacing(1),
      maxWidth: theme.breakpoints.values.md - theme.spacing(2),
      [theme.breakpoints.up('md')]: {
        marginRight: 'auto',
        marginLeft: 'auto'
      }
    },
    subheadingLight: {
      ...subheading,
      color: theme.palette.common.white
    },
    subheadingDark: {
      ...subheading,
      color: theme.palette.common.black
    },
    headerReplacement: {
      height: 55
    },
    row: {
      padding: theme.spacing(2)
    },
    circularProgress: {
      position: 'absolute',
      top: '30%',
      left: 0,
      right: 0,
      margin: 'auto',
      color: theme.palette.grey['400']
    },
    circularProgressBlock: {
      margin: `${theme.spacing(2)}px auto`,
      color: theme.palette.grey['400']
    },
    loadingText: {
      textAlign: 'center',
      color: '#AAAAAA',
      margin: 0,
      paddingBottom: theme.spacing(2),
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 'normal',
      fontSize: '16px',
      lineHeight: '19px',
      animation: '$loadingTextTransition 1s'
    },
    '@keyframes loadingTextTransition': {
      '0%': {
        opacity: 0
      },
      '25%': {
        opacity: 0
      },
      '100%': {
        opacity: 1
      }
    },
    loadingSpinner: {
      color: theme.palette.grey['400'],
      margin: 0,
      paddingBottom: theme.spacing(3)
    },
    loaderText: {
      paddingTop: theme.spacing(3),
      fontSize: theme.spacing(2),
      fontFamily: 'Roboto',
      fontWeight: theme.typography.fontWeightMedium
    },
    selectPlaceholder: {
      color: 'rgba(0,0,0,0.38)'
    },
    sensitiveInfoInputWebkit: {
      'text-security': 'disc',
      '-webkit-text-security': 'disc',
      '-moz-text-security': 'disc'
    },
    sensitiveInfoInput: {
      'font-family': 'text-security-disc',
      '&::placeholder': {
        'font-family': theme.typography.fontFamily
      }
    },
    standardBackground: {
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'fixed',
      backgroundAttachment: 'fixed',
      backgroundSize: 'cover'
    },
    '@media (min-width: 601px)': {
      standardBackground: {
        backgroundImage: `url(${backgroundDesktop})`
      }
    },
    '@media (max-width: 600px)': {
      standardBackground: {
        backgroundImage: `url(${backgroundMobile})`
      }
    },
    whiteBackground: {
      backgroundColor: theme.palette.common.white
    },
    extraMarginForBackButton: { marginTop: '22px' },
    hasErrorMessages: {
      display: 'flex'
    }
  }
}

export type coreScreenStylesPropsShape = {
  rootNew: string,
  root: string,
  paper: string,
  subheadingLight: string,
  subheadingDark: string,
  headerReplacement: string,
  circularProgressBlock: string,
  circularProgress: string,
  paperRoot: string,
  paperContainer?: string,
  marginHeader?: string,
  titleHeader?: string,
  iconWrapper?: string,
  icon?: string,
  subheading?: string,
  description?: string,
  textSuccess?: string,
  page?: string,
  centerItemsWrapper?: string,
  doneButton?: string,
  loadingText: string,
  loadingSpinner: string,
  extraMarginForBackButton: string,
  loaderText: string
}
