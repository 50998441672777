// @flow
import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { isNull, isUndefined } from 'lodash'
import NumberFormat from 'react-number-format'
import { CIRCULAR_PROGRESS_SIZE, DOLLAR_PREFIX } from 'properties/properties'
import l10n from 'properties/translations'
import { isPromoProduct } from 'util/LoanProductHelper'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'

const isNullish = input => isUndefined(input) || isNull(input)

type LoanProduct = {
  monthlyPaymentPromo?: number,
  interestRate?: number,
  numMonthlyPaymentsPromo?: number,
  loanMonthTerm?: number,
  monthlyPayment?: number,
  monthlyPaymentValue?: number
}

type monthlyPaymentValueParams = {
  estimatedMonthlyPaymentPromo?: number,
  estimatedMonthlyPayment: number,
  ignorePromo?: boolean
}

const monthlyPaymentValue = (
  loanProduct: LoanProduct,
  {
    estimatedMonthlyPaymentPromo,
    estimatedMonthlyPayment,
    ignorePromo = false
  }: monthlyPaymentValueParams
) => {
  if (!ignorePromo && isPromoProduct(loanProduct)) {
    return !isNullish(estimatedMonthlyPaymentPromo)
      ? estimatedMonthlyPaymentPromo
      : loanProduct.monthlyPaymentPromo
  }
  return !isNullish(estimatedMonthlyPayment)
    ? estimatedMonthlyPayment
    : loanProduct.monthlyPayment
}

type Props = {
  loanProduct: LoanProduct,
  estimatedMonthlyPaymentPromo?: number,
  estimatedMonthlyPayment: number,
  isLoading: boolean,
  ignorePromo?: boolean,
  classes: any
}

const MonthlyPaymentView = ({
  loanProduct,
  estimatedMonthlyPaymentPromo,
  estimatedMonthlyPayment,
  isLoading,
  ignorePromo,
  classes
}: Props) => {
  if (isLoading) {
    return (
      <CircularProgress
        data-testid="circular-progress"
        size={CIRCULAR_PROGRESS_SIZE - 1}
      />
    )
  }

  return !isNullish(
    monthlyPaymentValue(loanProduct, {
      estimatedMonthlyPaymentPromo,
      estimatedMonthlyPayment,
      ignorePromo
    })
  ) ? (
    <NumberFormat
      renderText={text => (
        <span className={classes.amountSpacing}>
          <SuperScriptAmount price={text} />
        </span>
      )}
      displayType="text"
      thousandSeparator
      prefix={DOLLAR_PREFIX}
      decimalScale={2}
      fixedDecimalScale
      value={monthlyPaymentValue(loanProduct, {
        estimatedMonthlyPaymentPromo,
        estimatedMonthlyPayment,
        ignorePromo
      })}
    />
  ) : (
    <span>{l10n.apply.accept.notAvailable} </span>
  )
}

export default MonthlyPaymentView
