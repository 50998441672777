// @flow
import React from 'react'
import { Redirect } from 'react-router-dom'
import { Query } from '@apollo/client/react/components'
import { getRedirectRoute } from 'util/AuthHelper'
import Routes from 'util/Routes'
import CustomerQuery from 'queries/BorrowerGetOwnCustomerQuery.graphql'
import ErrorHandlingPage from 'components/shared/ErrorHandlingPage'
import createCustomerSources from 'constants/enums/createCustomerSources'
import creditApplicationStatuses from 'constants/enums/creditApplicationStatuses'
import Loading from 'components/shared/Loading'

const { weblinksUrl } = Routes

type Props = {
  me: any,
  channelPartner?: {
    hostName: string
  },
  hideHeader?: boolean,
  toggleHeader?: Function
}

const SwiftlinksGuard = ({
  me,
  hideHeader,
  toggleHeader,
  channelPartner
}: Props) => {
  return (
    <Query query={CustomerQuery}>
      {({ loading, error, data, data: { customer } = {} }) => {
        if (loading) return <Loading />
        if (error)
          return (
            <ErrorHandlingPage
              showHeader={!hideHeader}
              me={me}
              toggleHeader={toggleHeader}
            />
          )
        const { source, creditApplication, aggregatorCode } = customer

        if (
          source === createCustomerSources.weblinks &&
          (!creditApplication ||
            creditApplication.status !== creditApplicationStatuses.ready)
        ) {
          return (
            <Redirect
              to={weblinksUrl(channelPartner.hostName, aggregatorCode)}
            />
          )
        }

        return (
          <Redirect
            to={getRedirectRoute({ id: me.userId, userType: me.userType })}
          />
        )
      }}
    </Query>
  )
}

export default SwiftlinksGuard
