import { gql } from 'graphql.macro'
import SalespersonFields from 'queries/fragments/SalespersonFields.graphql'
import QuoteProjectResponseFields from 'queries/fragments/QuoteProjectResponseFields.graphql'
import LoanProductResponseFields from 'queries/fragments/LoanProductResponseFields.graphql'
import SampleTermsResponseFields from 'queries/fragments/SampleTermsResponseFields.graphql'

export default gql`
  query CustomerQuery($id: String!) {
    customer(customerId: $id) {
      id
      status
      salesperson {
        ...SalespersonFields
      }
      estimateComplete
      applyComplete
      setupComplete
      blockedByManualReview
      dateModified
      deviceVerificationStatus
      firstName
      lastName
      email
      phoneNumber
      phoneNumberAlt1
      opportunityStatus
      addresses {
        addressType
        street
        city
        stateAbbreviation
        zip
      }
      loanType
      language
      contract {
        status
        isOkayToCancel
      }
      quote {
        hasAch
        loanProductId
        prePayment
        projects {
          ...QuoteProjectResponseFields
        }
        loanAmount
      }
      creditApplication {
        status
        financingLimit
        decision {
          status
          qualifiedProducts {
            ...LoanProductResponseFields
            sampleTerms {
              ...SampleTermsResponseFields
            }
          }
        }
      }
      creditApplicationAlternativeFinancing {
        status
      }
      notes
      applyComplete
      estimateComplete
      isInfoEditable
    }
    me {
      permissions
    }
    channelPartner {
      id
      name
      bundleId
    }
  }
  ${SalespersonFields}
  ${QuoteProjectResponseFields}
  ${LoanProductResponseFields}
  ${SampleTermsResponseFields}
`
