// @flow
import { Container } from 'unstated'
import noop from 'lodash/noop'
import l10n from 'properties/translations'
import * as AnalyticsHelper from 'util/AnalyticsHelper'
import { eventTypes, type EventType } from 'constants/enums/analyticsEventTypes'

const DEFAULT_BANNER_OPTIONS = {
  bodyText: l10n.banner.demoModeBodyText.default,
  buttonText: l10n.banner.demoModeButtonText.default,
  callback: noop,
  source: eventTypes.demoWelcomeBannerClicked,
  customerId: ''
}

export interface IBannerContainer {
  open: Function;
  close: Function;
}

interface BannerOptions {
  bodyText: string;
  buttonText?: string;
  callback?: Function;
}

interface State extends BannerOptions {
  expanded: boolean;
  source: EventType;
  customerId: string;
}

export default class BannerContainer extends Container<State> {
  state = {
    ...DEFAULT_BANNER_OPTIONS,
    expanded: false
  }

  open = (options: BannerOptions = DEFAULT_BANNER_OPTIONS) => {
    this.setState({
      ...DEFAULT_BANNER_OPTIONS,
      ...options,
      expanded: true
    })
  }

  close = () => {
    const { source, customerId } = this.state

    AnalyticsHelper.track(source, {
      customerId
    })
    this.setState(
      {
        expanded: false
      },
      this.state.callback
    )
  }
}
