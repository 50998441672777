// @flow
import React from 'react'
import { Button as MuiButton } from '@material-ui/core'

const Button = props => {
  const { children, ...rest } = props
  return <MuiButton {...rest}>{children}</MuiButton>
}

export default Button
