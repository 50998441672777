// @flow
import React, { Component, useContext } from 'react'
import { Redirect, Route } from 'react-router-dom'
import UserContext from 'util/UserContext'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { isUserAllowedToViewRoute } from 'util/AuthHelper'
import { destroyUserSession, getUserAccessToken } from 'util/SessionHelper'
import { isBorrower } from 'util/CustomerHelper'
import Routes, { redirectToLoginWithAttemptedLink } from 'util/Routes'
import ErrorHandlingPage from 'components/shared/ErrorHandlingPage'
import { userHasPermission } from 'util/UserHelper'
import SwiftlinksGuard from 'appConfig/SwiftlinksGuard'

type Props = {
  component: Component<any, any>,
  location: { pathname: string },
  allowedPermissions: string[],
  allowedUserTypes?: string[],
  path?: string,
  hideHeader?: boolean,
  toggleHeader?: Function,
  overrideHeader?: Function,
  iframeUrl?: string,
  noLogin?: boolean
}

const PrivateRoute = ({ component: ComponentToRender, ...rest }: Props) => {
  const userContext = useContext(UserContext)
  const token = getUserAccessToken()
  const { me, channelPartner } = userContext

  const {
    allowedUserTypes,
    allowedPermissions = [],
    location: { pathname },
    path,
    hideHeader = false,
    toggleHeader,
    overrideHeader,
    iframeUrl,
    noLogin
  } = rest

  let isAllowedToViewRoute = noLogin || (!token && !path)
  if (token) {
    isAllowedToViewRoute = isUserAllowedToViewRoute(
      me,
      allowedUserTypes,
      allowedPermissions,
    )
  }

  // Redirects you away if you are a borrower and the route is not found
  if (!isAllowedToViewRoute) {
    if (isBorrower(me)) {
      return (
        <SwiftlinksGuard
          me={me}
          hideHeader={hideHeader}
          toggleHeader={toggleHeader}
          channelPartner={channelPartner}
        />
      )
    }
    if (noLogin) {
      return (
        <ErrorHandlingPage
          showHeader={!hideHeader}
          me={me}
          toggleHeader={toggleHeader}
        />
      )
    }
    if (token && !userHasPermission(me, allowedPermissions)) {
      return <Redirect to={Routes.customerBlocked()} />
    }
    destroyUserSession()
    return redirectToLoginWithAttemptedLink(pathname)
  }

  const additionalComponentProps = {
    me,
    channelPartner,
    ...{ toggleHeader, overrideHeader },
    ...{ iframeUrl }
  }

  return (
    <Route
      {...rest}
      render={props => (
        <ComponentToRender {...props} {...additionalComponentProps} />
      )}
    />
  )
}

export default withApolloConsumer(PrivateRoute)
