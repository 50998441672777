// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'
import { Grid, TextField, InputAdornment } from '@material-ui/core'
import type { StageResponse, StageError } from 'screens/quote/QuoteTypes'
import l10n from 'properties/translations'
import { DOLLAR_PREFIX, EMPTY_STRING } from 'properties/properties'
import NumberFormatCustom from './NumberFormatCustom'
import fieldNames from './stageFieldNames.enum'
import styles from './StagesContainer.styles'
import { SlidingPaperDispatchContext } from 'util/SlidingPaperContext'
import StageMenu from './StageMenu'
import StageErrorMessage from './StageErrorMessage'
import type { StageMenuActions, StageInputHandlerProps } from './StageTypes'

const WIDTH_WITH_ADORNMENT = 6
const WIDTH_FOR_VALUE = 5

type Props = {
  stage: StageResponse,
  classes: {
    [key: string]: any
  },
  index: number,
  readonly: boolean,
  autoFocusNewStage: boolean,
  stageError: StageError,
  stageDirtyFields: { name?: boolean, amount?: boolean },
  handleBlur: (stageId: string, fieldName: string) => void,
  handleStageField: (handlerProps: StageInputHandlerProps) => void,
  handleStageFieldAmount: (handlerProps: StageInputHandlerProps) => void,
  buildDeleteActionObject: (stageId: string) => StageMenuActions
}

const StageInputRow = ({
  stage,
  index,
  classes,
  handleBlur,
  handleStageField,
  handleStageFieldAmount,
  stagesCount,
  buildDeleteActionObject,
  stageError = {},
  stageDirtyFields = {},
  readonly = false,
  autoFocusNewStage = false
}: Props) => {
  const hasAdornment = !readonly && stagesCount > 1
  const { showNameError, showAmountError } = stageError

  return (
    <>
      <Grid
        key={stage.id}
        alignItems="center"
        container
        spacing={1}
        data-testid="stage-input-row"
      >
        <Grid item xs={1}>
          <SubdirectoryArrowRightIcon className={classes.stageIcon} />
        </Grid>
        <Grid item xs={WIDTH_WITH_ADORNMENT}>
          <TextField
            fullWidth
            autoFocus={autoFocusNewStage}
            disabled={readonly}
            id={`${fieldNames.name}-${index}`}
            placeholder={l10n.quote.projectRow.stageLabel}
            value={stage.name || EMPTY_STRING}
            name={fieldNames.name}
            error={stageDirtyFields.name && showNameError}
            onBlur={handleBlur(stage.id, fieldNames.name)}
            onChange={({ target: { value } }) =>
              handleStageField({
                stageId: stage.id,
                fieldName: fieldNames.name,
                value
              })
            }
          />
        </Grid>
        <Grid item xs={WIDTH_FOR_VALUE}>
          <TextField
            fullWidth
            id={`${fieldNames.amount}-${index}`}
            data-testid={`${fieldNames.amount}-${index}`}
            disabled={readonly}
            placeholder="0"
            value={stage.amount}
            name={fieldNames.amount}
            error={showAmountError}
            onBlur={handleBlur(stage.id, fieldNames.amount)}
            onChange={({ target: { value } }) =>
              handleStageFieldAmount({
                stageId: stage.id,
                fieldName: fieldNames.amount,
                value
              })
            }
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: (
                <InputAdornment position="start">
                  {DOLLAR_PREFIX}
                </InputAdornment>
              ),
              endAdornment: hasAdornment && (
                <InputAdornment position="end">
                  <SlidingPaperDispatchContext.Consumer>
                    {setShouldCloseSlidingPaper => (
                      <InputAdornment position="end">
                        <StageMenu
                          stagesCount={stagesCount}
                          actions={buildDeleteActionObject(stage.id)}
                          setShouldCloseSlidingPaper={
                            setShouldCloseSlidingPaper
                          }
                        />
                      </InputAdornment>
                    )}
                  </SlidingPaperDispatchContext.Consumer>
                </InputAdornment>
              )
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            inputProps={{
              'aria-label': `${fieldNames.amount}-${index}`
            }}
          />
        </Grid>
      </Grid>
      <Grid container justify="flex-end">
        <StageErrorMessage
          stageError={stageError}
          stageDirtyFields={stageDirtyFields}
        />
      </Grid>
    </>
  )
}

export default withStyles(styles)(StageInputRow)
