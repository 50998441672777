// @flow
import React from 'react'
import { v4 as uuidv4 } from 'uuid'
import isEmpty from 'lodash/isEmpty'
import { calculateServicePlanAmount } from 'util/MathHelper'

import type {
  Project,
  ServicePlan,
  ChannelPartnerAddOn,
  LoanProductType,
  CalculateInputType,
  ServicePlanUpdates,
  ChannelPartnerProjectConfiguration,
  CustomerProjectConfiguration
} from 'screens/quote/QuoteTypes'
import l10n from 'properties/translations'

import {
  DEFAULT_MAX_STAGES_PER_PROJECT,
  DEFAULT_MIN_STAGE_AMOUNT,
  DEFAULT_MIN_STAGE_PERCENTAGE
} from 'properties/properties'
import { QuoteStore } from 'screens/quote/QuoteStore'

export type QuoteContextType = {
  projects: Array<Project>,
  servicePlans: Array<ServicePlan>,
  loanProducts?: Array<LoanProductType>,
  calculateInput?: CalculateInputType,
  channelPartnerAddOns: Array<ChannelPartnerAddOn>,
  channelPartnerProjectConfiguration: ChannelPartnerProjectConfiguration,
  customerProjectConfiguration?: CustomerProjectConfiguration,
  // client: ApolloClient,
  client: any,
  readonly?: boolean,
  loading?: boolean,
  error?: Error
}

export default React.createContext<QuoteContextType>({})

export const createServicePlanAddons = (
  { servicePlans }: QuoteContextType,
  numberOfPlans: Object
) => {
  // this just finds the first
  if (isEmpty(servicePlans)) {
    return []
  }
  const servicePlan = servicePlans[0]

  const addOnsArray = []
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < numberOfPlans; i++) {
    addOnsArray.push({
      code: l10n.quote.servicePlan.code,
      price: Number(servicePlan.defaultPrice),
      __typename: 'quote_configuration_project_addOn'
    })
  }
  return addOnsArray
}

export const handleServicePlanUpdates = async (
  context: QuoteContextType,
  { servicePlanCount, projectId }: ServicePlanUpdates
) => {
  const addOnsObject = createServicePlanAddons(context, servicePlanCount)
  const servicePlanAmount = calculateServicePlanAmount(addOnsObject)

  QuoteStore.updateServicePlanAmount({ servicePlanAmount })

  if (projectId) {
    QuoteStore.updateProjectAddons({ id: projectId, addOns: addOnsObject })
  }
}

export const clearAddOnsFromProjects = (
  projects: Array<Project>
): Array<Project> =>
  projects.map(project => ({
    ...project,
    addOns: []
  }))

export const clearLoanProducts = async (context: QuoteContextType) => {
  const { projects } = context

  QuoteStore.updateCalculateInput({
    loanProductId: null,
    projects: clearAddOnsFromProjects(projects)
  })
}

export const handleSelectLoanProduct = async (
  context: QuoteContextType,
  previousId?: number,
  id: number,
  event: object
) => {
  const { projects } = context

  if (id === previousId) {
    event.preventDefault()
    clearLoanProducts(context)
    return
  }

  QuoteStore.updateCalculateInput({
    loanProductId: id,
    projects: clearAddOnsFromProjects(projects)
  })
}

export const addProjectButtonPressed = async (context: QuoteContextType) => {
  const newId = uuidv4()
  const defaultAmount = 0
  const defaultType = ''
  const defaultName = null

  QuoteStore.addProject({
    id: newId,
    amount: defaultAmount,
    projectType: defaultType,
    notes: '',
    projectName: defaultName,
    stages: []
  })
}

export const defaultChannelPartnerProjectConfiguration = {
  maxStagesPerProject: DEFAULT_MAX_STAGES_PER_PROJECT,
  minStageAmount: DEFAULT_MIN_STAGE_AMOUNT,
  minStagePercentage: DEFAULT_MIN_STAGE_PERCENTAGE,
  projectTypes: [
    {
      projectType: 'CUSTOM',
      disbursementSequenceType: 'FLEXIBLE',
      disbursementText: 'Payments made at each stage confirmation',
      loanProductTypes: ['REDUCED_RATE_APR', 'DEFERRED_INTEREST'],
      projectTypeText: 'Custom',
      enabled: true,
      stageConfiguration: {
        maxStagesPerProject: 10,
        minStageAmount: 500,
        minStagePercentage: 9.5,
        __typename: 'StageConfiguration'
      },
      __typename: 'ProjectTypeConfigurationResponse'
    }
  ]
}
