import React, { useContext } from 'react'
import cx from 'classnames'
import { Grid, ButtonBase } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TranslationsContext from 'util/TranslationsContext'
import { scrollToTopOfThePage } from 'util/PrequalificationHelper'
import { colors } from 'themes/default'

const styles = () => ({
  jumpToErrorsButton: {
    marginTop: '-1px',
    textDecoration: 'underline',
    fontSize: '0.875rem',
    marginLeft: '.25em'
  },
  errorText: {
    color: colors.errorRed,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }
})
const useStyles = makeStyles(styles)

const FormErrors = () => {
  const l10n = useContext(TranslationsContext)
  const { FormErrorsText } = l10n.prequalificationForm
  const classes = useStyles()

  const JumpToErrors = () => (
    <ButtonBase
      className={cx(classes.jumpToErrorsButton, classes.errorText)}
      onClick={scrollToTopOfThePage}
    >
      {l10n.prequalificationForm.jumpToErrors}
    </ButtonBase>
  )

  return (
    <Grid item>
      <Grid container item justify="center" alignItems="space-between">
        <FormErrorsText classes={classes} />
        <JumpToErrors />
      </Grid>
    </Grid>
  )
}
export default FormErrors
