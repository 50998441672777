import React, { useContext } from 'react'
import cx from 'classnames'
import { Typography, Grid } from '@material-ui/core'
import TranslationsContext from 'util/TranslationsContext'

const AddressBody = ({ classes, inactiveForm }) => {
  const l10n = useContext(TranslationsContext)

  return (
    <Grid container item xs={12}>
      <Typography
        variant="body1"
        className={cx(classes.bodyTextNormal, {
          [classes.bodyFormLabel]: true,
          [classes.bodyFormLabelInactive]: inactiveForm
        })}
      >
        {l10n.prequalificationForm.addressBody1}
      </Typography>
    </Grid>
  )
}

export default AddressBody
