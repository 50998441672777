// @flow
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import DoneIcon from '@material-ui/icons/Done'
import { withStyles } from '@material-ui/core/styles'
import { type LocalizedTranslations } from 'properties/translations'
import commonStyles from './commonStyles'

type Props = {
  classes: any,
  localizedTranslations: LocalizedTranslations
}

const ThankYouContent = ({ classes, localizedTranslations: l10n }: Props) => (
  <Grid container direction="column" alignItems="center" spacing={1}>
    <Grid item>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.iconSuccessContainer}
      >
        <Grid item>
          <DoneIcon className={classes.iconSuccess} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item>
      <Typography
        variant="body2"
        align="center"
        className={classes.successDescription}
      >
        {l10n.setup.thankYou.description}
      </Typography>
    </Grid>
  </Grid>
)

export default withStyles(commonStyles)(ThankYouContent)
