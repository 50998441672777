const MuiFormControlLabel = themeColors => {
  return {
    label: {
      paddingTop: 14,
      color: themeColors.normalBlack,
      fontWeight: 400,
      lineHeight: '1.46429em',
      fontSize: '0.875rem'
    },
    root: {
      alignItems: 'start'
    }
  }
}

export default MuiFormControlLabel
