import { colors } from 'themes/default'

const styles = theme => ({
  container: {
    paddingTop: theme.spacing(4),
    '& ul': {
      paddingLeft: theme.spacing(2)
    },
    '& li a': {
      color: colors.normalBlack,
      fontWeight: 'normal'
    }
  },
  inactiveForm: {
    opacity: 0.4,
    cursor: 'default',
    'pointer-events': 'none'
  },
  paragraph: {
    fontSize: '13px'
  },
  heading: {
    fontSize: '13px'
  },
  legalTextContainer: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(1)
  },
  equalHousingLenderIcon: {
    height: 60,
    marginRight: theme.spacing(2)
  },
  link: {
    textDecoration: 'underline',
    '&:visited': {
      color: colors.normalBlack
    }
  }
})

export default styles
