// @flow
import React, { useContext } from 'react'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { FormControl, InputAdornment, TextField } from '@material-ui/core'
import projectRowStyles from './ProjectRow.styles'
import fieldNames from './stageFieldNames.enum'
import { makeStyles } from '@material-ui/core/styles'
import TranslationsContext from 'util/TranslationsContext'

const useStyles = makeStyles(projectRowStyles)

type Props = {
  projectName: string,
  readonly: boolean,
  handleBlur: Function,
  keyPressHandler: Function,
  updateProjectFn: Function,
  projectType: string,
  toggleButtonDisplay: Function,
  removeProjectStages: Function,
  enabledProjectTypes: Array<string>,
  dirtyFields: any,
  projectNameError: string
}

const CustomProjectField = ({
  projectName,
  readonly,
  handleBlur,
  keyPressHandler,
  updateProjectFn,
  projectType,
  toggleButtonDisplay,
  removeProjectStages,
  enabledProjectTypes,
  dirtyFields,
  projectNameError
}: Props) => {
  const classes = useStyles()
  const l10n = useContext(TranslationsContext)

  return (
    <FormControl fullWidth data-testid="custom-form-control">
      <TextField
        value={projectName || ''}
        disabled={readonly}
        placeholder={l10n.quote.projectRow.customLabel}
        fullWidth
        autoFocus
        error={dirtyFields[fieldNames.projectName] && projectNameError}
        onBlur={handleBlur}
        onChange={({ target: { value } }) => {
          keyPressHandler({
            fieldName: fieldNames.projectName,
            value,
            updateProjectFn,
            projectType,
            toggleButtonDisplay
          })
        }}
        InputProps={{
          endAdornment: !readonly && (
            <InputAdornment
              data-testid="project-change-arrow"
              position="end"
              className={classes.endAdornmentReset}
              onMouseDown={removeProjectStages(updateProjectFn)}
            >
              {enabledProjectTypes.length > 1 && <ArrowDropDownIcon />}
            </InputAdornment>
          )
        }}
      />
    </FormControl>
  )
}

export default CustomProjectField
