import { gql } from 'graphql.macro'

export default gql`
  fragment LoanProductResponseFields on LoanProductResponse {
    id
    loanProductType
    displayName
    loanTenorMonths
    minimumAmount
    borrowingLimit
    dealerFee
    maximumAmount
    promoPeriod
    interestRate
    interestRateDiscounted
    reamortizationPeriod
    defermentPeriods
    promoRate
    servicePlanAllowed
    financingOptions
  }
`
