import { withStyles } from '@material-ui/core/styles'
import CommonImageCell from 'components/shared/CommonImageCell'

const cellStyles = theme => ({
  itemCell: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 400
  },
  itemImage: { padding: theme.spacing(1) },
  itemDescription: {
    marginTop: theme.spacing(1),
    fontSize: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.spacing(1.5)
    }
  }
})

export default withStyles(cellStyles)(CommonImageCell)
