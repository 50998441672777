// @flow

export const channelPartnerFlagsEnum = Object.freeze({
  PARTIAL_CREDIT_APPLICATION: 'PARTIAL_CREDIT_APPLICATION',
  DELAY_ADVERSE_ACTION_NOTICES: 'DELAY_ADVERSE_ACTION_NOTICES',
  PARTNER_MANAGES_ESIGN_VERIFICATION: 'PARTNER_MANAGES_ESIGN_VERIFICATION',
  PARTNER_MANAGES_COMMUNICATION: 'PARTNER_MANAGES_COMMUNICATION',
  AUTO_REP_HOME: 'AUTO_REP_HOME',
  WET_SIGN_INSTALL_CONFIRM: 'WET_SIGN_INSTALL_CONFIRM',
  BLOCK_OPPORTUNITY_CREATION: 'BLOCK_OPPORTUNITY_CREATION'
})

export type ChannelPartnerFlags = $Values<typeof channelPartnerFlagsEnum>

export default channelPartnerFlagsEnum
