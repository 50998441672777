// @flow
import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import creditCardClock from 'images/credit-card-clock.svg'
import finance from 'images/finance.svg'
import lightbulbOn from 'images/lightbulb-on-outline.svg'
import clockFast from 'images/clock-fast.svg'
import l10n from 'properties/translations'
import PaperCtn from '../PaperCtn'
import ImgCell from './ImgCell'

const items = {
  row0: {
    cell0: {
      description: l10n.onboardingSteps.step0.imageDescriptions[0],
      imageSrc: creditCardClock,
      imageAlt: 'Credit Card Clock'
    },
    cell1: {
      description: l10n.onboardingSteps.step0.imageDescriptions[1],
      imageSrc: finance,
      imageAlt: 'Finance'
    }
  },
  row1: {
    cell0: {
      description: l10n.onboardingSteps.step0.imageDescriptions[2],
      imageSrc: lightbulbOn,
      imageAlt: 'Lightbulb on'
    },
    cell1: {
      description: l10n.onboardingSteps.step0.imageDescriptions[3],
      imageSrc: clockFast,
      imageAlt: 'Clock fast'
    }
  }
}

const styles = theme => ({
  imgCtn: {
    display: 'flex',
    flexDirection: 'column',
    flex: 10,
    padding: theme.spacing(2)
  },
  imgRow: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1
  },
  footerCtn: {
    fontWeight: 400,
    flex: 1,
    fontSize: '14px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '10px',
      padding: `0 ${theme.spacing(3)}px ${theme.spacing(2)}px`
    }
  }
})

type Props = {
  classes: Object
}

const Step0 = ({ classes }: Props) => (
  <PaperCtn>
    <div className={classes.imgCtn}>
      <div className={classes.imgRow}>
        <ImgCell item={items.row0.cell0} />
        <ImgCell item={items.row0.cell1} />
      </div>
      <div className={classes.imgRow}>
        <ImgCell item={items.row1.cell0} />
        <ImgCell item={items.row1.cell1} />
      </div>
    </div>
    <Typography variant="body1" align="center" className={classes.footerCtn}>
      {l10n.onboardingSteps.step0.footerText}
    </Typography>
  </PaperCtn>
)

export default withStyles(styles)(Step0)
