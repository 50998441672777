const MuiFormLabel = themeColors => {
  return {
    root: {
      lineHeight: '1.46429em',
      fontWeight: 400,
      color: themeColors.mutedBlack
    }
  }
}

export default MuiFormLabel
