// @flow

import * as React from 'react'
import { get, flow } from 'lodash'
import { Paper, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { customerIsBlocked, isIntegrator } from 'util/CustomerHelper'
import { handleBackButtonPressed } from 'util/Routes'
import BackButton from 'components/shared/BackButton'
import { withRouter } from 'react-router-dom'
import coreScreenStyles, {
  type coreScreenStylesPropsShape
} from 'themes/coreScreenStyles'
import GetCustomer from 'screens/quote/queries/GetCustomer'
import { inIframe } from 'util/EnvironmentHelper'
import OptionalQuery from './OptionalQuery'
import { DEFAULT_ELEVATION } from 'properties/properties'
import UserContext from 'util/UserContext'
import permissionsEnum from 'constants/enums/permissions'
import { coApplicantFormEnabled } from 'util/FeatureHelper'

const styles = theme => ({
  ...coreScreenStyles(theme)
})

type Props = {
  children: React.ChildrenArray<React.Node | Function | string>,
  classes: coreScreenStylesPropsShape,
  history: { goBack: Function },
  ldFlags: Object,
  customerId?: string
}

const WizardPaper = (props: Props) => {
  const userContext = React.useContext(UserContext)
  const {
    me,
    me: { permissions }
  } = userContext

  const hideBackButton =
    isIntegrator(me) && !permissions.includes(permissionsEnum.VIEW_ESTIMATE)

  const { children, history, classes, customerId, ldFlags } = props

  if (inIframe()) {
    return children
  }

  return (
    <Paper className={classes.paperRoot} elevation={DEFAULT_ELEVATION}>
      <Grid container spacing={2}>
        {/* Render back button unless customer is blocked */}
        <OptionalQuery
          query={GetCustomer}
          runQuery={Boolean(customerId)}
          variables={{ id: customerId }}
        >
          {({ data, loading }) => {
            if (loading) return null
            const customer = get(data, 'customer', {})
            const coApplicant = coApplicantFormEnabled(ldFlags, { customer })

            if (!customerIsBlocked(userContext, ldFlags, customer)) {
              return (
                !hideBackButton &&
                !coApplicant && (
                  <BackButton to={handleBackButtonPressed(history)} />
                )
              )
            }
            return null
          }}
        </OptionalQuery>
        {/* Render children */}
        <Grid item xs={12}>
          {children}
        </Grid>
      </Grid>
    </Paper>
  )
}

export default flow(
  withLaunchDarkly,
  withRouter,
  withStyles(styles)
)(WizardPaper)
