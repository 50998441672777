export default {
  approved: 'APPROVED',
  pending: 'PENDING',
  blocked: 'BLOCKED',
  needsReview: 'NEEDS_REVIEW',
  declined: 'DECLINED',
  notQualified: 'NOT_QUALIFIED',
  loading: 'LOADING',
  processing: 'PROCESSING',
  needToVerifySsnDob: 'NEED_TO_VERIFY_SSN_DOB'
}
