// @flow
import React from 'react'
import NumberFormat from 'react-number-format'
import { Typography } from '@material-ui/core'
import l10n from 'properties/translations'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'
import { DOLLAR_PREFIX } from 'properties/properties'

type Props = {
  classes: any,
  amount: string
}

const ApprovedLoanAmount = ({ classes, amount }: Props) => (
  <div className={classes.sectionCtn}>
    <Typography variant="caption" align="center">
      {l10n.apply.productSelection.approvedLoanAmount}
    </Typography>
    <Typography align="center" variant="h6">
      <NumberFormat
        value={amount}
        prefix={DOLLAR_PREFIX}
        displayType="text"
        thousandSeparator
        renderText={text => <SuperScriptAmount price={text} />}
      />
    </Typography>
  </div>
)

export default ApprovedLoanAmount
