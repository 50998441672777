// @flow
import React from 'react'
import { Grid, Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const style = theme => ({
  containerButton: {
    textAlign: 'center',
    marginTop: theme.spacing(6)
  }
})

type Props = {
  description: string,
  cancelButtonText: string,
  approveButtonText: string,
  cancelOnClick: () => {},
  approveOnClick: () => {},
  classes: {
    containerButton: string
  }
}

export const YesNoDialog = ({
  description,
  cancelButtonText,
  approveButtonText,
  cancelOnClick,
  approveOnClick,
  classes
}: Props) => (
  <Grid container direction="column">
    <Grid item>
      <Typography variant="body2">{description}</Typography>
    </Grid>
    <Grid item className={classes.containerButton}>
      <Button
        data-testid="back"
        variant="outlined"
        color="primary"
        cancel="large"
        onClick={cancelOnClick}
      >
        <Typography>{cancelButtonText}</Typography>
      </Button>
      <Button
        data-testid="next"
        variant="contained"
        color="primary"
        size="large"
        onClick={approveOnClick}
      >
        <Typography>{approveButtonText}</Typography>
      </Button>
    </Grid>
  </Grid>
)

export default withStyles(style)(YesNoDialog)
