// @flow
import React, { Component } from 'react'
import { get, isEmpty } from 'lodash'
import { Mutation } from '@apollo/client/react/components'
import cx from 'classnames'
import { withStyles } from '@material-ui/core/styles'
import {
  Typography,
  Grid,
  Radio,
  RadioGroup,
  FormControlLabel,
  Button,
  CircularProgress,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import NumberFormat from 'react-number-format'
import ErrorToast, { errorToastIcon } from 'components/shared/ErrorToast'
import { getErrorType } from 'util/ErrorHelper'
import OptionalQuery from 'components/shared/OptionalQuery'
import Dialog from 'components/shared/Dialog'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import Loading from 'components/shared/Loading'
import { localizedTranslations } from 'properties/translations'
import sendViaTypes from 'constants/enums/sendViaTypes'
import { eventTypes } from 'constants/enums/analyticsEventTypes'
import Client from 'gql/clientNew'
import {
  CIRCULAR_PROGRESS_SIZE,
  EMPTY_STRING,
  reviewSummaryProperties,
  SHORT_TIMEOUT
} from 'properties/properties'
import {
  customerSelectedLoanProduct,
  customerLoanProjectTypes,
  reduceProjectAndStageAmounts
} from 'util/ApplyHelper'
import { landlineNumberEnabled } from 'util/FeatureHelper'
import DateHelper from 'util/DateHelper'
import { isPromoProduct } from 'util/LoanProductHelper'
import { phoneNumberAsText } from 'util/StringHelper'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'
import DialogConfirm from 'components/shared/DialogConfirm'
import customerFields from 'constants/enums/customerFields'
import BannerContainer from 'store/BannerContainer'
import AccessModeContainer from 'store/AccessModeContainer'
import { track } from 'util/AnalyticsHelper'
import withUnstatedContainer from 'components/shared/UnstatedContainerHOC'
import MonitoringHelper from 'util/MonitoringHelper'
import { customerLanguageKey } from 'util/CustomerHelper'
import GetPhoneInformation from 'screens/apply/queries/GetPhoneInformation.graphql'
import styles from './ShareLinkDialog.styles'
import ShareLinkDialogCustomerQuery from '../queries/ShareLinkDialogCustomerQuery.graphql'
import SendShareLink from '../queries/SendShareLink.graphql'
import GetCreditApplication from '../queries/GetCreditApplication.graphql'

const { dollarNumberFormat } = reviewSummaryProperties

const listItemKeys = {
  name: 'name',
  loanAmount: 'loanAmount',
  loanProduct: 'loanProduct',
  projectTypes: 'projectTypes'
}

const shareLinkSentAtNode = (l10n, date) => (
  <Typography variant="caption" key={date} component="p">
    {`${l10n.apply.shareLinkDialog.sent} ${DateHelper.printDateAndTime(
      DateHelper.utcToLocalTime(date)
    )}`}
  </Typography>
)

const shareLinkSentAtNodes = (l10n, dates) =>
  dates.map(date => shareLinkSentAtNode(l10n, date))

type Props = {
  open: boolean,
  customer: {
    firstName: ?string,
    lastName: ?string
  },
  customerId: ?string,
  confirmButtonTitle: ?string,
  title: ?string,
  body: ?string,
  onClose: Function,
  classes: {
    boldText: string,
    cancelButton: string,
    iconHidden: string,
    list: string,
    listItemText: string,
    listItemTextInvalid: string
  },
  accessModeContainer: {
    demoMode: boolean
  },
  bannerContainer: {
    open: Function
  },
  loanAmount: Number,
  ldFlags: Object
}

type State = {
  isSubmitting: boolean,
  sendVia: sendViaTypes,
  showConfirm: boolean,
  isLandline: boolean
}

class ShareLinkDialog extends Component<Props, State> {
  static defaultProps = {
    title: undefined,
    confirmButtonTitle: undefined,
    body: undefined,
    customer: null,
    customerId: null,
    isLandline: null
  }

  state = {
    isSubmitting: false,
    sendVia: sendViaTypes.SmsOnly,
    showConfirm: false,
    optOutErrorType: EMPTY_STRING
  }

  generateListItem(key, text, isValid = true) {
    const { classes } = this.props
    return (
      <ListItem
        key={key}
        disableGutters
        data-testid={`share-modal-${key}`}
        classes={{ root: classes.listRoot }}
      >
        <ListItemText
          classes={{
            primary: cx({
              [classes.listItemText]: true,
              [classes.listItemTextInvalid]: !isValid
            })
          }}
          primary={text}
        />
      </ListItem>
    )
  }

  financeProductListItem(l10n, loanProduct, customer, ldFlags) {
    const hasFinanceProduct = Boolean(loanProduct)
    if (!hasFinanceProduct) {
      return this.generateListItem(
        listItemKeys.loanProduct,
        l10n.apply.shareLinkDialog.noFinanceProductSelected,
        false
      )
    }
    const terms = get(customer, 'contract.terms', {})
    const apr = terms.annualRatePercentage || loanProduct.interestRate
    const loanMonthsPath = 'loanTenorMonths'
    const loanMonths = get(loanProduct, loanMonthsPath, EMPTY_STRING)
    const isPromo = isPromoProduct(loanProduct)

    const prefix = isPromo
      ? l10n.apply.shareLinkDialog.promotionalPrefix
      : EMPTY_STRING
    const loanProductString = l10n.apply.shareLinkDialog.loanProductString({
      loanMonths,
      apr
    })
    return this.generateListItem(
      listItemKeys.loanProduct,
      `${l10n.apply.shareLinkDialog.loanDetails} ${prefix} ${loanProductString}`
    )
  }

  projectTypesListItem(l10n, projectTypes) {
    const hasProjectTypes = !isEmpty(projectTypes)
    if (!hasProjectTypes) {
      return this.generateListItem(
        listItemKeys.projectTypes,
        l10n.apply.shareLinkDialog.noProjectTypeSelected,
        false
      )
    }
    return this.generateListItem(
      listItemKeys.projectTypes,
      `${l10n.apply.shareLinkDialog.projectName} ${projectTypes.join(', ')}`
    )
  }

  listItems(customer, { loanProduct, projectTypes }, ldFlags) {
    const l10n = localizedTranslations(customerLanguageKey(customer))
    const amountFinanced = customer.contract
      ? get(customer, 'contract.terms.amountFinanced', 0)
      : reduceProjectAndStageAmounts(get(customer, 'quote.projects', []))
    // When a contract does not yet exists, amountFinanced will return 0.
    // Use loanAmount from the quote to populate the modal text
    const { loanAmount } = this.props
    const amount = amountFinanced !== 0 ? amountFinanced : loanAmount

    const decimalScale = amount === 0 ? 0 : dollarNumberFormat.decimalScale

    return [
      this.generateListItem(
        listItemKeys.name,
        `${l10n.apply.shareLinkDialog.customerName} ${customer.firstName} ${customer.lastName}`
      ),
      this.generateListItem(
        listItemKeys.loanAmount,
        <NumberFormat
          {...dollarNumberFormat}
          decimalScale={decimalScale}
          displayType="text"
          renderText={text => {
            return (
              <>
                {`${l10n.apply.shareLinkDialog.loanAmount} `}
                <SuperScriptAmount price={text} />
              </>
            )
          }}
          value={amount}
        />
      ),
      this.financeProductListItem(l10n, loanProduct, customer, ldFlags),
      this.projectTypesListItem(l10n, projectTypes)
    ]
  }

  sendViaFormFields({
    dateShareLinkSentToSms,
    communications: { dateEmailsSent },
    phoneNumber,
    email,
    isLandline
  }) {
    const { customer, ldFlags } = this.props
    const { sendVia } = this.state
    const l10n = localizedTranslations(customerLanguageKey(customer))
    const landlineNumberFlag = landlineNumberEnabled(ldFlags)
    const isLandlineNumberWithFlagEnabled = landlineNumberFlag && isLandline
    const phoneNumberTypographyNode = isLandlineNumberWithFlagEnabled ? (
      l10n.apply.shareLinkDialog.landlineLabel
    ) : (
      <>
        <Typography variant="body2">
          {l10n.apply.shareLinkDialog.sendTo}{' '}
          {this.wrapWithBoldTypographyNode(phoneNumberAsText(phoneNumber))}
        </Typography>
        {!isEmpty(dateShareLinkSentToSms) &&
          shareLinkSentAtNodes(l10n, dateShareLinkSentToSms)}
      </>
    )

    if (email) {
      const emailTypographyNode = (
        <>
          <Typography variant="body2">
            {l10n.apply.shareLinkDialog.sendTo}{' '}
            {this.wrapWithBoldTypographyNode(email)}
          </Typography>
          {!isEmpty(dateEmailsSent) &&
            shareLinkSentAtNodes(l10n, dateEmailsSent)}
        </>
      )
      return (
        <RadioGroup
          aria-label="Send to email or phone"
          name="emailOrPhone"
          value={sendVia}
          onChange={this.handleChangeSendVia}
        >
          {isLandlineNumberWithFlagEnabled && (
            <>
              <FormControlLabel
                data-testid="send-to-sms"
                value={sendViaTypes.SmsOnly}
                disabled={isLandlineNumberWithFlagEnabled}
                checked={!isLandlineNumberWithFlagEnabled}
                control={<Radio color="primary" />}
                label={phoneNumberTypographyNode}
              />
              <FormControlLabel
                data-testid="send-to-email"
                value={sendViaTypes.EmailOnly}
                control={<Radio color="primary" />}
                checked={isLandlineNumberWithFlagEnabled}
                label={emailTypographyNode}
              />
            </>
          )}
          {!isLandlineNumberWithFlagEnabled && (
            <>
              <FormControlLabel
                data-testid="send-to-sms"
                value={sendViaTypes.SmsOnly}
                control={<Radio color="primary" />}
                label={phoneNumberTypographyNode}
              />
              <FormControlLabel
                data-testid="send-to-email"
                value={sendViaTypes.EmailOnly}
                control={<Radio color="primary" />}
                label={emailTypographyNode}
              />
            </>
          )}
        </RadioGroup>
      )
    }
    return phoneNumberTypographyNode
  }

  handleChangeSendVia = event => {
    this.setState({ sendVia: event.target.value })
  }

  wrapWithBoldTypographyNode = text => {
    const { classes } = this.props
    return (
      <Typography
        component="span"
        variant="body2"
        classes={{ root: classes.boldText }}
      >
        {text}
      </Typography>
    )
  }

  handleShare = async sendShareLink => {
    const {
      customerId,
      customer,
      accessModeContainer: { demoMode },
      bannerContainer
    } = this.props
    const { sendVia } = this.state
    const l10n = localizedTranslations(customerLanguageKey(customer))

    // Cannot send emails in demo mode, show banner instead
    if (demoMode && sendVia === sendViaTypes.EmailOnly) {
      bannerContainer.open({
        bodyText: l10n.banner.demoModeBodyText.shareLink
      })

      return
    }

    this.setState({ isSubmitting: true })
    try {
      await sendShareLink({
        variables: { customerId, sendVia },
        refetchQueries: [
          {
            query: GetCreditApplication,
            variables: {
              id: customerId
            }
          }
        ],
        awaitRefetchQueries: true
      })
      this.setState({ showConfirm: true })

      if (sendVia === sendViaTypes.SmsOnly) track(eventTypes.shareSms)
      if (sendVia === sendViaTypes.EmailOnly) track(eventTypes.shareEmail)
      if (sendVia === sendViaTypes.SmsAndEmail) {
        track(eventTypes.shareSms)
        track(eventTypes.shareEmail)
      }
    } catch (error) {
      MonitoringHelper.manuallyReportError(error)
      const errorType = getErrorType(error)
      this.setState({
        isSubmitting: false,
        optOutErrorType: errorType
      })
    }
  }

  handleClose = () => {
    this.setState(this.props.onClose)
    setTimeout(() => {
      this.setState({
        showConfirm: false,
        isSubmitting: false,
        optOutErrorType: EMPTY_STRING
      })
    }, SHORT_TIMEOUT)
  }

  clearError = () => {
    this.setState({
      optOutErrorType: EMPTY_STRING
    })
  }
  getPhoneInformation = async variables => {
    const { guestClient } = new Client()
    try {
      const {
        data: {
          getPhoneInformation: { isLandlineNumber }
        }
      } = await guestClient.query({
        query: GetPhoneInformation,
        variables: { phoneNumber: variables.phoneNumber }
      })
      return isLandlineNumber
    } catch (error) {
      // eslint-disable-next-line
      console.error(error)
    }
  }
  setIsLandlineNumber = async customer => {
    const { ldFlags } = this.props

    const phoneNumber = get(customer, customerFields.phoneNumber, EMPTY_STRING)
    if (phoneNumber) {
      const isLandline = await this.getPhoneInformation({ phoneNumber })
      const otherChanges = {}
      if (
        isLandline &&
        get(customer, 'email') &&
        landlineNumberEnabled(ldFlags)
      ) {
        otherChanges.sendVia = sendViaTypes.EmailOnly
      }
      this.setState({
        isLandline,
        ...otherChanges
      })
    }
  }

  render() {
    const {
      open,
      classes,
      title,
      body,
      customer,
      customerId,
      confirmButtonTitle,
      ldFlags
    } = this.props

    const { isSubmitting, sendVia, showConfirm, optOutErrorType, isLandline } =
      this.state
    const l10n = localizedTranslations(customerLanguageKey(customer))

    const landlineNumberFlag = landlineNumberEnabled(ldFlags)
    if (landlineNumberFlag && open && isLandline === undefined) {
      this.setIsLandlineNumber(customer)
    }
    return (
      <Dialog
        open={open}
        title={title || l10n.apply.shareLinkDialog.title}
        onClose={this.handleClose}
        onBackdropClick={this.handleClose}
      >
        <OptionalQuery
          query={ShareLinkDialogCustomerQuery}
          runQuery={!customer}
          variables={{ customerId }}
        >
          {({ loading, data = {} }) => {
            if (loading) return <Loading />
            const customerToRender = data.customer || customer
            const loanProduct = customerSelectedLoanProduct(customerToRender)
            const projectTypes = customerLoanProjectTypes(customerToRender)
            const cannotSubmit = !loanProduct || isEmpty(projectTypes)

            if (showConfirm) {
              const { email, phoneNumber } = customerToRender

              return (
                <DialogConfirm
                  classes={classes}
                  email={email}
                  phoneNumber={phoneNumber}
                  sendVia={sendVia}
                />
              )
            }

            return (
              <>
                <Typography variant="body2" className={classes.bodyTextNormal}>
                  {body || l10n.apply.shareLinkDialog.body}
                </Typography>
                <List dense classes={{ root: classes.list }}>
                  {this.listItems(
                    customerToRender,
                    {
                      loanProduct,
                      projectTypes
                    },
                    ldFlags
                  )}
                </List>
                {this.sendViaFormFields({
                  ...customerToRender,
                  isLandline
                })}
                <Grid
                  container
                  direction="column"
                  alignItems="center"
                  className={classes.btnCtn}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    onClick={this.handleClose}
                  >
                    <Typography>{l10n.apply.shareLinkDialog.cancel}</Typography>
                  </Button>
                  <Mutation mutation={SendShareLink}>
                    {sendShareLink => (
                      <Button
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={cannotSubmit || isSubmitting}
                        onClick={() => this.handleShare(sendShareLink)}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
                        ) : (
                          <Typography>
                            {confirmButtonTitle ||
                              l10n.apply.shareLinkDialog.buttonTitle(false)}
                          </Typography>
                        )}
                      </Button>
                    )}
                  </Mutation>
                </Grid>
                <ErrorToast
                  message={l10n.serverError[optOutErrorType]}
                  open={optOutErrorType}
                  handleClose={() => this.clearError()}
                  iconType={errorToastIcon.serverError}
                />
              </>
            )
          }}
        </OptionalQuery>
      </Dialog>
    )
  }
}

export default withStyles(styles)(
  withLaunchDarkly(
    withUnstatedContainer(ShareLinkDialog, {
      accessModeContainer: AccessModeContainer,
      bannerContainer: BannerContainer
    })
  )
)
