import { gql } from 'graphql.macro'

import LoanProductResponseFields from 'queries/fragments/LoanProductResponseFields.graphql'
import SampleTermsResponseFields from 'queries/fragments/SampleTermsResponseFields.graphql'

export default gql`
  query BorrowerGetOwnCustomer($customerId: String) {
    customer(customerId: $customerId) {
      id
      phoneNumber
      firstName
      middleName
      lastName
      email
      phoneNumberAlt1
      deviceVerificationStatus
      addresses {
        street
        city
        stateAbbreviation
        zip
        addressType
      }
      weblinksMetadata {
        dateOfBirth
      }
      loanType
      language
      source
      aggregatorCode
      creditApplication {
        status
        financingLimit
        decision {
          status
          flags
          qualifiedProducts {
            ...LoanProductResponseFields
            sampleTerms {
              ...SampleTermsResponseFields
            }
          }
        }
      }
    }
  }

  ${LoanProductResponseFields}
  ${SampleTermsResponseFields}
`
