import { I18nType } from '../typedefs.types.i18n-0.7.2'
import get from 'lodash.get'

import translations from './translations'

const defaultLocale = 'enUS'
const defaultCount = 1

export const getByLocale = (locale: string) => {
  if (locale) {
    if (translations[locale]) return translations[locale]
    const langPrefix = locale.slice(0, 2).toLowerCase()
    if (langPrefix in translations) return translations[langPrefix]
  }
  return translations[defaultLocale]
}

export const translationByPath =
  (i18nInput?: I18nType) =>
  (path: string, ...argsOrCount: any[]) => {
    const i18n = i18nInput || getByLocale(defaultLocale)
    const translation = get(i18n, path, get(translations[defaultLocale], path))
    if (!translation) return ''

    if (Array.isArray(translation)) {
      const count = argsOrCount.length ? argsOrCount[0] : defaultCount
      if (count !== 1 && translation.length > 1) {
        return translation[1]
      }
      return translation[0]
    }
    const fnOrString = translation
    if (typeof fnOrString === 'function') {
      return fnOrString(...argsOrCount)
    }
    return translation
  }
