// @flow
import { head } from 'lodash'
import { cookieDomains } from 'constants/enums/hostnames'
import emailVerificationSessionStore from 'constants/enums/emailVerificationSessionStore'
import basePaths from 'constants/enums/basePaths'
import { EMPTY_STRING } from 'properties/properties'
import { clearScreenNameInIframeFlagEnabled } from './FeatureHelper'
import appVersion from '../appVersion'
import environmentsByHost from 'constants/environments'
import backendEnvironments from 'constants/enums/backendEnvironments'

const CURRENT_URL = window.location.href
const DEFAULT_HOST = Object.keys(environmentsByHost).find(env =>
  env.startsWith('localhost')
)

export const isTestingEnv = () => process.env.NODE_ENV === 'test'

export const isQAEnv = () => {
  const {
    location: { hostname }
  } = window
  return hostname.endsWith(cookieDomains.qa)
}

export function isDevelopmentEnv() {
  return process.env.NODE_ENV === 'development'
}

export function inIframe() {
  return window && window.self !== window.top
}

export function inP3xIframe() {
  if (inIframe() === false) return false

  const parentUrl =
    window.location !== window.parent.location ? document.referrer : ''
  if (Boolean(parentUrl) === false) return false

  const parentHostname = new URL(parentUrl).hostname
  const parentHostnameParts = parentHostname.split('.')
  const isLocalhostIFrame = parentHostnameParts.includes('localhost')
  const isP3xIFrame =
    (parentHostnameParts.includes('portal') &&
      parentHostnameParts.includes('joinmosaic')) ||
    isLocalhostIFrame
  return isP3xIFrame
}

const apply = 'apply'
const tap = 'tap'

const tapUrls = {
  [`${apply}${cookieDomains.qa}`]: `https://${tap}${cookieDomains.qa}`,
  [`${apply}${cookieDomains.e2e}`]: `https://${tap}${cookieDomains.e2e}`,
  [`${apply}${cookieDomains.sandbox}`]: `https://${tap}${cookieDomains.sandbox}`,
  [`${apply}${cookieDomains.production}`]: `https://${tap}${cookieDomains.production}`
}

export const getTapHostByApplyHost = (host: string): string => tapUrls[host]

export const isHostNameSwiftlinks = (hostName: string): boolean =>
  hostName.startsWith(`${apply}.`)

export const isRootOrLoginPathName = (pathName: string): boolean =>
  pathName === basePaths.start || pathName === basePaths.login

export const trimmedPathName = (pathName: string): string =>
  head(pathName.split('/'))

export const isBasePath = (pathName: string): boolean => {
  const pathNameWithoutSlash = pathName.substring(1)
  const basePathsArray = Object.values(basePaths).map(value =>
    value.substring(1)
  )
  return basePathsArray.includes(trimmedPathName(pathNameWithoutSlash))
}

export const getAppVersionString = (): string => appVersion

export const getAppVersionCommitHash = (): string =>
  process.env.REACT_APP_SHA1 || EMPTY_STRING

export const appendAppVersionCommitHashToHead = () => {
  const metatag = document.createElement('meta')
  metatag.setAttribute('property', 'mosaic:commit-hash')
  metatag.content = getAppVersionCommitHash()
  document.getElementsByTagName('head')[0].appendChild(metatag)
}

export const usageContexts = {
  prod: 'prod',
  test: 'test',
  qa: 'qa',
  sandbox: 'sandbox'
}

export const getUsageContextForEnv = () => {
  const { hostname } = window.location
  if (hostname.endsWith(cookieDomains.qa)) {
    return usageContexts.qa
  }
  if (hostname.endsWith(cookieDomains.e2e)) {
    return usageContexts.test
  }
  if (hostname.endsWith(cookieDomains.sandbox)) {
    return usageContexts.sandbox
  }
  if (hostname.endsWith(cookieDomains.production)) {
    return usageContexts.prod
  }

  // Fallback - warning, this should not be relied on
  return usageContexts.qa
}

export const clearScreenNameInIframeIfFlagEnabled = (
  ldFlags: any,
  safeSessionStorage: Storage
) => {
  if (inIframe() && clearScreenNameInIframeFlagEnabled(ldFlags)) {
    safeSessionStorage.removeItem(emailVerificationSessionStore.SCREEN_NAME)
  }
}

export const getEnvironment = (UIHost = CURRENT_URL) => {
  const { host } = new URL(UIHost || DEFAULT_HOST)

  return environmentsByHost[host] || backendEnvironments.local
}
