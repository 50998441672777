import customerFields from 'constants/enums/customerFields'

export default {
  maxLengths: {
    [customerFields.firstName]: 100,
    [customerFields.lastName]: 100,
    [customerFields.middleName]: 100,
    [customerFields.email]: 100
  }
}
