export default theme => ({
  container: {
    height: '100%',
    padding: theme.spacing(2)
  },
  icon: {
    textAlign: 'center'
  },
  headline: {
    color: '#0F8189',
    fontSize: 36,
    lineHeight: '54px',
    fontWeight: 200,
    marginTop: theme.spacing(3)
  },
  subHeadline: {
    color: '#223472',
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  instruction: {
    color: '#4C4946',
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 300
  },
  bookmarkReminder: {
    color: '#4C4946',
    fontSize: 18,
    lineHeight: '28px',
    fontWeight: 300,
    marginTop: theme.spacing(2)
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: theme.spacing(4)
  },
  link: {
    display: 'inline-block',
    width: '196px',
    height: '64px',
    backgroundColor: '#4467E5',
    textTransform: 'uppercase',
    borderRadius: '200px',
    fontSize: 18,
    fontWeight: 600,
    letterSpacing: '1px',
    color: '#FFF',
    textDecoration: 'none',
    paddingTop: theme.spacing(2.25),
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: '#6985ea'
    },

    '&:active': {
      backgroundColor: '#3652b7',
      borderColor: '#3652b7'
    }
  },
  newLoginUrl: {
    color: '#3652b7',
    fontWeight: 600,
    textDecoration: 'none'
  }
})
