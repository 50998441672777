// @flow
import React, { useState } from 'react'
import { get, isEmpty, flow } from 'lodash'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import ServicePlan from 'screens/quote/components/inputs/ServicePlan'
import Error from 'components/shared/Error'
import Loading from 'components/shared/Loading'
import NoLoanProductsMessage from 'components/shared/NoLoanProductsMessage'
import UserContext from 'util/UserContext'

import LoanProductInfoBox from 'screens/apply/components/LoanProductInfoBox'
import { Typography } from '@material-ui/core'
import { getMinimumLoanAmount, getBorrowingLimit } from 'util/LoanProductHelper'
import { validLoanAmount } from 'util/ApplyHelper'
import { updatePaymentPlansLdFlagEnabled } from 'util/FeatureHelper'
import l10n from 'properties/translations'
import { Query } from '@apollo/client/react/components'
import { withStyles } from '@material-ui/core/styles'
import styles from './ProductSelectionModal.styles'
import {
  defaultServiceOptions,
  LoanProduct,
  LoanProductWithMonthlyPayments,
  Project,
  getSortedLoanProducts
} from 'util/ProductSelectionModalHelper'
import { EMPTY_ARRAY } from 'properties/properties'
import { GET_QUOTE } from 'screens/quote/QuoteStore'

const monthlyPaymentKeys = {
  monthlyPayment: 'monthlyPayment',
  monthlyPaymentPromo: 'monthlyPaymentPromo'
}

type Props = {
  servicePlans: any,
  disabled: boolean,
  selectedLoanProductId: number,
  setTotalMonthlyPayment: Function,
  numberOfPromoMonths: number,
  setStateValues: (values: Object, callback?: Function) => void,
  serviceOption: number,
  loanProducts: Array<LoanProduct>,
  loanProductsWithMonthlyPayments: Array<LoanProductWithMonthlyPayments>,
  saveButtonIsDisabled: boolean,
  projectEstimate: number,
  projects: Array<Project>,
  hasProjects: boolean,
  isLoadingMonthlyPayments: boolean,
  onProjectsChanged: Function,
  servicePlanDropDownOpen: Function,
  changeService: Function,
  servicePlanDropDownToggle: Function,
  servicePlanSelected: boolean,
  ldFlags: Object,
  classes: any
}

const FinancePlansSection = ({
  servicePlans,
  disabled,
  selectedLoanProductId,
  setTotalMonthlyPayment,
  numberOfPromoMonths: promoMonths,
  setStateValues,
  serviceOption,
  loanProducts,
  loanProductsWithMonthlyPayments,
  saveButtonIsDisabled,
  projectEstimate,
  projects,
  hasProjects,
  isLoadingMonthlyPayments,
  onProjectsChanged,
  servicePlanDropDownOpen,
  changeService,
  servicePlanDropDownToggle,
  servicePlanSelected,
  ldFlags,
  handleChangeOnPromoMonths,
  handleClickOnServicePlan,
  classes
}: Props) => {
  const [projectWithServicePlanId, setProjectWithServicePlanId] = useState('')

  const [servicePlanLoanProductSelected, setServicePlanLoanProductSelected] =
    useState(false)

  const getEstimatedMonthlyPayment = (
    loanProductId,
    selectedLoanProductId,
    saveButtonIsDisabled,
    projectEstimate,
    key = monthlyPaymentKeys.monthlyPayment
  ) => {
    const minimumLoanAmount = getMinimumLoanAmount(
      loanProducts,
      selectedLoanProductId
    )

    const maximumLoanAmount = getBorrowingLimit(
      loanProducts,
      selectedLoanProductId
    )

    const loanAmountIsValid = validLoanAmount(
      projectEstimate,
      loanProducts,
      minimumLoanAmount,
      maximumLoanAmount
    )

    if (saveButtonIsDisabled && !loanAmountIsValid) return null

    const loanProduct =
      loanProductsWithMonthlyPayments.find(
        lp => (lp.loanProductId || lp.id) === loanProductId
      ) || {}

    if (loanProduct && loanProduct.sampleTerms) {
      return get(loanProduct.sampleTerms, key, 0)
    }
    return get(loanProduct, key, 0)
  }

  const isServiceplanLoanProductAvailable = selectedLoanProduct => {
    const servicePlanAllowed = get(selectedLoanProduct, 'servicePlanAllowed')
    const numberOfPromoMonths = get(
      selectedLoanProduct,
      'numMonthlyPaymentsPromo'
    )

    if (selectedLoanProduct && promoMonths !== numberOfPromoMonths) {
      handleChangeOnPromoMonths(numberOfPromoMonths)
    }
    if (servicePlanAllowed && !servicePlanLoanProductSelected) {
      setServicePlanLoanProductSelected(true)
    }
  }

  // cycle through all the selected projects, find the projectId to attach the addOns object to if exists
  const servicePlanAvaiable = (addOnProjectTypes, selectedProjects) => {
    const projectsWithServicePlan = addOnProjectTypes.flatMap(
      addOnProjectType =>
        selectedProjects.filter(
          selectedProject =>
            addOnProjectType.projectType === selectedProject.projectType
        )
    )
    if (projectsWithServicePlan.length === 0) {
      return ''
    }
    const existingProjectWithServicePlan = projectsWithServicePlan.find(
      project => project.addOns && project.addOns.length !== 0
    )
    return existingProjectWithServicePlan
      ? existingProjectWithServicePlan.id
      : projectsWithServicePlan[0].id
  }

  const getSelectedloanproduct = (
    selectedLoanProductId,
    loanProductId,
    selectedLoanProduct
  ) => {
    if (selectedLoanProductId === loanProductId) {
      isServiceplanLoanProductAvailable(selectedLoanProduct)
      setTotalMonthlyPayment(selectedLoanProductId, false)
      return true
    }
    return false
  }

  // const checkLoanProSelectionForServicePlan = async (
  //   selectedLoanProduct,
  //   id
  // ) => {
  //   const numberOfPromoMonths = get(
  //     selectedLoanProduct,
  //     'numMonthlyPaymentsPromo'
  //   )

  //   setStateValues({
  //     numberOfPromoMonths,
  //     servicePlanSelected: false,
  //     serviceOption: 0
  //   })

  //   const servicePlanAllowed = get(selectedLoanProduct, 'servicePlanAllowed')
  //   if (servicePlanAllowed) {
  //     setServicePlanLoanProductSelected(true)
  //   } else {
  //     setServicePlanLoanProductSelected(false)
  //     setStateValues({
  //       servicePlanSelected: false,
  //       servicePlanAmount: 0
  //     })
  //   }
  //   setTotalMonthlyPayment(id, true)
  // }

  const sortedLoanProducts: Array<LoanProduct> =
    getSortedLoanProducts(loanProducts)

  const userContext = React.useContext(UserContext)
  const { channelPartner } = userContext
  const addOns = get(channelPartner, 'addOns', EMPTY_ARRAY)

  const servicePlansArr = addOns.filter(addOn => {
    return addOn.code === l10n.quote.servicePlan.code
  })

  return (
    <>
      <Typography variant="caption" gutterBottom>
        {l10n.apply.productSelection.financeProductSelectTitle}
      </Typography>
      {isEmpty(sortedLoanProducts) && <NoLoanProductsMessage />}
      {sortedLoanProducts.map(({ loanProductId, ...loanProduct }) => {
        return (
          <LoanProductInfoBox
            appearsInModal
            servicePlans={servicePlans}
            isSelected={
              servicePlans.length > 0 ||
              updatePaymentPlansLdFlagEnabled(ldFlags)
                ? getSelectedloanproduct(
                    selectedLoanProductId,
                    loanProductId,
                    loanProduct
                  )
                : selectedLoanProductId === loanProductId
            }
            key={loanProductId}
            loanProduct={loanProduct}
            disabled={disabled}
            onClick={() => {
              // setStateValues({
              //   selectedLoanProductId: loanProductId
              // })
              // checkLoanProSelectionForServicePlan(loanProduct, loanProductId)
              handleClickOnServicePlan(
                loanProduct,
                loanProductId,
                setServicePlanLoanProductSelected
              )
            }}
            estimatedMonthlyPayment={getEstimatedMonthlyPayment(
              loanProductId,
              selectedLoanProductId,
              saveButtonIsDisabled,
              projectEstimate
            )}
            estimatedMonthlyPaymentPromo={getEstimatedMonthlyPayment(
              loanProductId,
              selectedLoanProductId,
              saveButtonIsDisabled,
              projectEstimate,
              monthlyPaymentKeys.monthlyPaymentPromo
            )}
            isLoadingMonthlyPayment={hasProjects && isLoadingMonthlyPayments}
          />
        )
      })}

      {servicePlansArr.length > 0 ? (
        <Query query={GET_QUOTE}>
          {({ data: projectData, projectLoading, projectError }) => {
            if (projectLoading) return <Loading />
            if (projectError) return <Error error={projectError} />

            const retrievedProjects =
              projectData &&
              projectData.quote &&
              get(projectData.quote.configuration, 'projects')

            if (!isEmpty(retrievedProjects)) {
              const newProjectWithServicePlanId = servicePlanAvaiable(
                servicePlansArr,
                projects
              )

              if (newProjectWithServicePlanId !== projectWithServicePlanId) {
                setProjectWithServicePlanId(newProjectWithServicePlanId)
                onProjectsChanged()
              }

              return projectWithServicePlanId ? (
                <ServicePlan
                  classes={classes}
                  serviceOptions={defaultServiceOptions}
                  currentValue={serviceOption}
                  loanProductSelected={servicePlanLoanProductSelected}
                  servicePlanDropDownOpen={servicePlanDropDownOpen}
                  onChangeService={changeService}
                  onClick={servicePlanDropDownToggle}
                  isSelected={servicePlanSelected}
                  addOnsAmount={servicePlans[0].defaultPrice}
                  ldFlags={ldFlags}
                />
              ) : null
            }
            return null
          }}
        </Query>
      ) : null}
    </>
  )
}

export default flow(withStyles(styles), withLaunchDarkly)(FinancePlansSection)
