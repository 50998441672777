import { gql } from 'graphql.macro'

export default gql`
  fragment SalespersonFields on SalespersonResponse {
    id
    firstName
    lastName
    email
    channelPartnerName
  }
`
