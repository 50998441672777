// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { flow } from 'lodash'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { Grid, Typography } from '@material-ui/core'
import l10n from 'properties/translations'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import styles from './commonStyles'

type Props = {
  classes: {
    iconWrapperGray: string,
    iconGray: string,
    subheading: string
  },
  ldFlags: Object,
  customerId: string
}

const ProcessingContent = (props: Props) => {
  const { classes } = props

  return (
    <Grid
      container
      direction="column"
      justify="center"
      alignItems="center"
      data-testid="complete-prequalification-message"
    >
      <Grid item>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.iconWrapperGray}
        >
          <Grid item>
            <MailOutlineIcon className={classes.iconGray} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.subheading}>
        <Typography variant="subtitle1" align="center" paragraph>
          {l10n.apply.formStatus.complete.description}
        </Typography>
        <Typography variant="body1" align="center">
          {l10n.apply.formStatus.complete.description2}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default flow(
  withLaunchDarkly,
  withApolloConsumer,
  withStyles(styles)
)(ProcessingContent)
