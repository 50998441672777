import l10n from 'properties/translations'
import customerFields from '../enums/customerFields'

const FIELDSET_ABOUT = 'FIELDSET_ABOUT'
const FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL = 'FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL'
const FIELDSET_APPLY = 'FIELDSET_APPLY'
const FIELDSET_VERIFY_IDENTITY = 'FIELDSET_VERIFY_IDENTITY'
const FIELDSET_PUBLIC_APPLICATION = 'FIELDSET_PUBLIC_APPLICATION'

export default {
  [FIELDSET_APPLY]: {
    name: FIELDSET_APPLY,
    title: l10n.apply.creditApplication,
    showsModifiedDate: false,
    requiredFields: [
      customerFields.firstName,
      customerFields.lastName,
      customerFields.phoneNumber,
      customerFields.street,
      customerFields.city,
      customerFields.stateAbbreviation,
      customerFields.zip,
      customerFields.email,
      customerFields.ssn,
      customerFields.personalIncome,
      customerFields.dobMonth,
      customerFields.dobDay,
      customerFields.dobYear
    ]
  },
  [FIELDSET_ABOUT]: {
    name: FIELDSET_ABOUT,
    showsModifiedDate: true,
    requiredFields: [customerFields.phoneNumber, customerFields.email]
  },
  [FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL]: {
    name: FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL,
    title: l10n.customer.customerInfo,
    showsModifiedDate: true,
    requiredFields: [customerFields.phoneNumber, customerFields.email]
  },
  [FIELDSET_VERIFY_IDENTITY]: {
    name: FIELDSET_VERIFY_IDENTITY,
    title: l10n.apply.formStatus.verifyIdentity.title,
    showsModifiedDate: false,
    requiredFields: [
      customerFields.ssn,
      customerFields.dobMonth,
      customerFields.dobDay,
      customerFields.dobYear
    ]
  },
  [FIELDSET_PUBLIC_APPLICATION]: {
    name: FIELDSET_PUBLIC_APPLICATION,
    // title: ,
    showsModifiedDate: false,
    requiredFields: [
      customerFields.firstName,
      customerFields.lastName,
      customerFields.personalIncome,
      customerFields.ssn,
      customerFields.dobMonth,
      customerFields.dobDay,
      customerFields.dobYear,
      customerFields.street,
      customerFields.city,
      customerFields.stateAbbreviation,
      customerFields.zip,
      customerFields.primaryStreet,
      customerFields.primaryCity,
      customerFields.primaryStateAbbreviation,
      customerFields.primaryZip,
      customerFields.projectSecondHomeStreet,
      customerFields.projectSecondHomeCity,
      customerFields.projectSecondHomeStateAbbreviation,
      customerFields.projectSecondHomeZip,
      customerFields.projectRentalStreet,
      customerFields.projectRentalCity,
      customerFields.projectRentalStateAbbreviation,
      customerFields.projectRentalZip
    ]
  }
}

export const fieldsetNames = {
  FIELDSET_ABOUT,
  FIELDSET_ABOUT_SINGLE_PAGE_PREQUAL,
  FIELDSET_APPLY,
  FIELDSET_VERIFY_IDENTITY,
  FIELDSET_PUBLIC_APPLICATION
}

export const customerFieldsType = [
  customerFields.firstName,
  customerFields.lastName,
  customerFields.phoneNumber,
  customerFields.phoneNumberAlt,
  customerFields.addresses,
  customerFields.email,
  customerFields.notes,
  customerFields.salespersonId
]
