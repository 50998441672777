import React from 'react'
import { get } from 'lodash'
import { ThankYou as ThankYouScreen } from '@solarmosaic/apply.screens.thank-you'
import { getUserTypeFromProps } from 'util/NavigationHelper'
import { EMPTY_STRING } from 'properties/properties'
import { getCustomerLocale } from 'util/CustomerHelper'
import { loanDefaultWrapperProps, defaultFooterProps } from 'properties/p3x'

const emptySalesPerson = { firstName: EMPTY_STRING, lastName: EMPTY_STRING }

const ThankYou = props => {
  const userType = props.userType || getUserTypeFromProps(props)

  const { customer, hideHeader } = props
  const salesperson = get(customer, 'salesperson', emptySalesPerson)

  const locale = getCustomerLocale(customer)

  return (
    <ThankYouScreen
      salesRep={salesperson}
      enableP2XScreenWrapper={true}
      wrapperProps={{
        ...loanDefaultWrapperProps(hideHeader),
        footerProps: defaultFooterProps(userType, customer),
        locale
      }}
    />
  )
}

export default ThankYou
