export default theme => ({
  root: {
    margin: 0,
    padding: theme.spacing(1)
  },
  closeButton: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    color: theme.palette.grey[500],
    padding: '12px'
  },
  underwritingSupportSection: {
    paddingTop: 24
  },
  welcomeSupportSection: {
    paddingTop: 24
  },
  loanPaymentSupportSection: {
    paddingTop: 24
  },
  supportText: {
    fontWeight: 'bold'
  },
  contactCardUnAvailability: {
    color: '#DF6974'
  },
  contactCardAvailability: {
    fontWeight: 400,
    color: '#4db191'
  },
  contactCardEmail: {
    paddingTop: 24
  },
  dialog: {
    [theme.breakpoints.down('xs')]: {
      maxWidth: 'calc(100% - 32px) !important',
      maxHeight: 'calc(100% - 32px) !important',
      margin: `16px !important`
    }
  }
})
