import backendEnvironments from './backendEnvironments'

const { demo, e2e, local, production, qa, staging } = backendEnvironments
export default {
  [demo]: hostName => `https://${hostName}.demo.joinmosaic.com`,
  [e2e]: hostName => `https://${hostName}.financing.e2e.joinmosaic.com`,
  [local]: hostName => `https://${hostName}.financing.qa.joinmosaic.com`,
  [production]: hostName => `https://${hostName}.financing.joinmosaic.com`,
  [qa]: hostName => `https://${hostName}.financing.qa.joinmosaic.com`,
  [staging]: hostName => `https://${hostName}.financing.rc.joinmosaic.com`
}
