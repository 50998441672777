import { gql } from 'graphql.macro'

export default gql`
  fragment projectFields on project {
    id
    projectType
    amount
    notes
    projectName
    stages {
      id
      name
      amount
    }
    addOns {
      code
      price
    }
  }
`
