import get from 'lodash/get'
import userTypes from 'constants/enums/userTypes'
import { userHasPermission } from 'util/UserHelper'
import {
  getClaimsFromAccessToken,
  accessTokenIsLeaseCustomer
} from 'util/SessionHelper'
import Routes from './Routes'
import { LinkSharingRoutes } from 'appConfig/linkSharingRoutes'
import { UUIDRegex } from 'appConfig/constants'

export const getRedirectRoute = user => {
  const { id, userType } = user
  switch (userType) {
    case userTypes.installer:
      return Routes.start()

    // @todo: define the default route for the borrower userType
    default:
      return Routes.customer(id)
  }
}

export const isUserAllowedToViewRoute = (
  me,
  allowedUserTypes = [],
  permissions = []
) => {
  const userInfo = getClaimsFromAccessToken()
  if (accessTokenIsLeaseCustomer(userInfo)) {
    return allowedUserTypes.includes(userTypes.customer)
  }

  return (
    Boolean(allowedUserTypes.includes(get(me, 'userType'))) &&
    userHasPermission(me, permissions)
  )
}

const pathMatcher = pathname => path => {
  try {
    const firstPart = pathname.split('/')[1]
    return path.startsWith(`/${firstPart}`)
  } catch (e) {
    return false
  }
}

export const getUUIDFromPath = pathname => {
  const paths = Object.values(LinkSharingRoutes)
  const predicate = pathMatcher(pathname)
  if (paths.some(predicate)) {
    const match = pathname.match(UUIDRegex)
    if (match && match[0]) return match[0]
  }
}
