import { generateRandomSsn } from 'util/RNGHelper'
import customerFields from './customerFields'

export const DEMO_MODE_DEFAULT_CUSTOMER = {
  [customerFields.dobMonth]: 1,
  [customerFields.dobDay]: 1,
  [customerFields.dobYear]: 1990,
  [customerFields.personalIncome]: 100000
}
export const demoCustomer = {
  dobMonth: DEMO_MODE_DEFAULT_CUSTOMER[customerFields.dobMonth],
  email: 'demoemail@joinmosaic.com'
}
export const generateDemoValues = () => ({
  ...DEMO_MODE_DEFAULT_CUSTOMER,
  [customerFields.ssn]: generateRandomSsn().toString()
})

export default { DEMO_MODE_DEFAULT_CUSTOMER, demoCustomer, generateDemoValues }
