// @flow
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import NotificationsNoneIcon from '@material-ui/icons/NotificationsNone'
import { withStyles } from '@material-ui/core/styles'
import { type LocalizedTranslations } from 'properties/translations'
import commonStyles from './commonStyles'

type Props = {
  classes: any,
  localizedTranslations: LocalizedTranslations
}

const SetupSkippedContent = ({
  classes,
  localizedTranslations: l10n
}: Props) => {
  return (
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <Grid
          container
          justify="center"
          alignItems="center"
          className={classes.iconWrapperGray}
        >
          <Grid item>
            <NotificationsNoneIcon className={classes.iconGray} />
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.description}>
        <Typography
          variant="body2"
          align="center"
          className={classes.bodyTextNormal}
        >
          {l10n.setup.setupSkipped.description}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default withStyles(commonStyles)(SetupSkippedContent)
