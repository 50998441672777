// @flow
import React from 'react'
import l10n from 'properties/translations'
import YesNoDialogComponent from 'screens/common/YesNoDialog'

type Props = {
  classes: {
    containerButton: string
  },
  onClickConfirm: Function,
  onClickBack: Function
}

const CancelDialog = ({ onClickConfirm, onClickBack }: Props) => (
  <YesNoDialogComponent
    description={l10n.apply.cancelContractDialog.subtitle}
    cancelButtonText={l10n.apply.cancelContractDialog.backButton}
    approveButtonText={l10n.apply.cancelContractDialog.confirmButton}
    cancelOnClick={onClickBack}
    approveOnClick={onClickConfirm}
  />
)
export default CancelDialog
