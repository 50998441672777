// @flow
import React, { useContext } from 'react'
import NumberFormat from 'react-number-format'
import { FormHelperText } from '@material-ui/core'
import TextValidator from 'components/shared/TextValidator'
import TranslationsContext from 'util/TranslationsContext'
import placeholders from 'properties/placeholders'
import { DOLLAR_PREFIX } from 'properties/properties'
import customerFields from 'constants/enums/customerFields'

/* Values to display for customer in demo mode */
const DEMO_MODE_DISPLAY_VALUES = {
  otherHouseholdIncome: '$XX,XXX'
}

type Props = {
  classes: classesShape,
  demoMode: boolean,
  isRequiredField: (fieldName: string) => boolean,
  getErrorMessagesRequired: (fieldName: string) => string[],
  getValidatorsRequired: (fieldName: string) => string[],
  labelIsRequired: () => boolean,
  onFieldEdit: func,
  disabled?: boolean,
  placeholder?: string,
  singleDobField?: boolean,
  onKeyPress: Function
}

const OtherHouseholdIncomeField = (props: Props) => {
  const {
    demoMode,
    isRequiredField,
    getErrorMessagesRequired,
    getValidatorsRequired,
    labelIsRequired,
    onFieldEdit,
    disabled = false,
    placeholder = undefined,
    singleDobField,
    onKeyPress
  } = props
  const l10n = useContext(TranslationsContext)

  return (
    <>
      {demoMode ? (
        <TextValidator
          disabled
          fullWidth
          id={customerFields.otherHouseholdIncome}
          label={l10n.customer.otherHouseholdIncome}
          name={customerFields.otherHouseholdIncome}
          value={DEMO_MODE_DISPLAY_VALUES.otherHouseholdIncome}
        />
      ) : (
        <NumberFormat
          id={customerFields.otherHouseholdIncome}
          type="tel"
          fullWidth
          allowNegative={false}
          decimalScale={0}
          thousandSeparator
          customInput={TextValidator}
          prefix={DOLLAR_PREFIX}
          label={l10n.customer.otherHouseholdIncome}
          onChange={onFieldEdit(customerFields.otherHouseholdIncome)}
          name={customerFields.otherHouseholdIncome}
          placeholder={placeholder || placeholders.otherHouseholdIncome}
          validators={getValidatorsRequired(
            customerFields.otherHouseholdIncome
          )}
          errorMessages={getErrorMessagesRequired(
            customerFields.otherHouseholdIncome,
            l10n
          )}
          required={isRequiredField(customerFields.otherHouseholdIncome)}
          InputLabelProps={{
            required: labelIsRequired(),
            shrink: singleDobField ? true : undefined
          }}
          disabled={disabled}
          data-testid="other-household-income"
          InputProps={{ onKeyPress }}
        />
      )}
      <FormHelperText>
        <>{l10n.customer.otherHouseholdIncomeHelpText}</>
      </FormHelperText>
    </>
  )
}

export default OtherHouseholdIncomeField
