import { compact, split, parseInt } from 'lodash'
import {
  WHITE_SPACE,
  LASTNAME_EACH_WORD_LENGTH,
  MAX_DOB_YEAR
} from 'properties/properties'
import DateHelper from 'util/DateHelper'

const isValidLastName = value => {
  const lastName = split(value, WHITE_SPACE)
  return compact(lastName).every(
    name => name.length >= LASTNAME_EACH_WORD_LENGTH
  )
}

const noBlankSpaces = string => ![...string].some(char => char === WHITE_SPACE)

const isValidCalendarDate = date => {
  const dobMonth = DateHelper.monthFromMMDDYYYYDate(date)
  const dobDay = DateHelper.dayFromMMDDYYYYDate(date)
  const dobYear = DateHelper.yearFromMMDDYYYYDate(date)
  const dateAsArray = [dobYear, dobMonth, dobDay]
  const dateParsed = dateAsArray.map(date => parseInt(date))

  return DateHelper.isValid(...dateParsed)
}

const isYearValid = date => {
  const dobYear = DateHelper.yearFromMMDDYYYYDate(date)
  // Make sure it doesn't run the validation until user is finished typing.
  if (dobYear && dobYear > 1900) {
    return dobYear < MAX_DOB_YEAR
  }
  return true
}

export default {
  isValidLastName,
  noBlankSpaces,
  isValidCalendarDate,
  isYearValid
}
