/* eslint-disable react/jsx-boolean-value */
// flow
import React, { Component } from 'react'
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { flow } from 'lodash'
import { Query, Mutation } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import l10n from 'properties/translations'
import { DEBOUNCE_TIME } from 'properties/properties'
import Loading from 'components/shared/Loading'
import ErrorToast, { errorToastIcon } from 'components/shared/ErrorToast'
import SuccessToast from 'components/shared/SuccessToast'
import { getDeviceFingerprint } from 'util/DeviceHelper'
import styles from './Declined.styles'
import FoundationDisclosure from './FoundationDisclosure'
import recordConsentAlternativeFinancing from './query/recordConsentAlternativeFinancing.graphql'
import customerAndMeToFoundation from './query/customerAndMeToFoundation.graphql'
import Submit from './Submit'
import ApplicationForm from './ApplicationForm'

interface Props {
  classes: {
    title: string
  };
  client: Mutation;
}
interface State {
  submitting: boolean;
  errorType: ?string;
  checked: boolean;
}

const hasStatus = alternativeFinancing => alternativeFinancing !== null
class DeclinedScreen extends Component<Props, State> {
  static defaultProps = {
    circularProgressSize: 14
  }
  constructor(props) {
    super(props)
    this.handleCheck = this.handleCheck.bind(this)
    this.state = {
      submitting: false,
      checked: false
    }
  }

  handleSubmit = mutationFunction => async () => {
    this.setState({ submitting: true })
    const deviceFingerprint = await getDeviceFingerprint()
    const response = await mutationFunction({
      variables: {
        deviceFingerprint,
        customerId: this.props.customerId
      }
    })
    if (response.data) {
      this.setState({ success: true })
    } else {
      this.setState({ errorOccurred: true })
    }
    this.setState({ submitting: false })
  }

  handleCheck(event) {
    this.setState({
      checked: event.target.checked
    })
  }

  showError = bool => () => {
    this.setState({
      errorOccurred: bool
    })
  }

  showSuccess = bool => () => {
    this.setState({
      success: bool
    })
  }

  render() {
    const { classes, circularProgressSize, customerId, client } = this.props
    const { submitting, checked, success, errorOccurred } = this.state
    // toggle disable button based on check box status
    const disabled = !checked
    if (success) {
      return (
        <ApplicationForm
          client={client}
          open={success}
          customerId={customerId}
        />
      )
    }

    return (
      <Query
        query={customerAndMeToFoundation}
        variables={{ customerId }}
        fetchPolicy="network-only"
      >
        {({ data: { customer }, loading, error }) => {
          if (loading) return <Loading />
          if (error) {
            this.showError(true)
          }
          const alternativeFinancing =
            customer && customer.creditApplicationAlternativeFinancing
          // if this customer has a status, redirect to decision screen
          // Else foundation consent screen
          return hasStatus(alternativeFinancing) ? (
            <ApplicationForm
              client={client}
              open={true}
              customerId={customerId}
            />
          ) : (
            <>
              <SuccessToast
                message={l10n.foundation.successMessage}
                open={success}
                handleClose={this.showSuccess(false)}
              />
              <ErrorToast
                message={l10n.shared.error}
                open={errorOccurred}
                handleClose={this.showError(false)}
                iconType={errorToastIcon.serverError}
              />
              <Mutation mutation={recordConsentAlternativeFinancing}>
                {consentAlternativeFinancing => (
                  <ValidatorForm
                    ref={r => {
                      this.form = r
                    }}
                    onSubmit={this.handleSubmit}
                    debounceTime={DEBOUNCE_TIME}
                  >
                    <Grid container alignItems="center">
                      <Typography
                        className={classes.title}
                        variant="body2"
                        gutterBottom
                      >
                        {l10n.foundation.title}
                      </Typography>
                    </Grid>
                    <Typography className={classes.paragraph} variant="caption">
                      {l10n.foundation.paragraph}
                    </Typography>
                    <FoundationDisclosure
                      appContent={false}
                      classes={classes}
                    />
                    <Grid container direction="row" alignItems="center">
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={checked}
                            onChange={e => this.handleCheck(e)}
                            color="primary"
                          />
                        }
                        label={
                          <Typography fontWeight="fontWeightBold">
                            {l10n.foundation.checkboxAgreement}
                          </Typography>
                        }
                      />
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <Submit
                        circularProgressSize={circularProgressSize}
                        classes={classes}
                        disabled={disabled}
                        submitting={submitting}
                        customerId={customerId}
                        onSubmit={this.handleSubmit(
                          consentAlternativeFinancing
                        )}
                      />
                    </Grid>
                  </ValidatorForm>
                )}
              </Mutation>
            </>
          )
        }}
      </Query>
    )
  }
}

export default flow(withApolloConsumer, withStyles(styles))(DeclinedScreen)
