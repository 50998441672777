// @flow
import React from 'react'
import { Query } from '@apollo/client/react/components'
import type { QueryErrorType, MessageDataType } from 'util/TypesHelper'
import Error from 'components/shared/Error'
import Loading from 'components/shared/Loading'
import Message from './Message'
import { GET_MESSAGE_QUERY } from 'screens/quote/QuoteStore'

type QueryResultType = {
  loading: boolean,
  error: QueryErrorType,
  data: MessageDataType
}

const MessageContainer = () => (
  <Query query={GET_MESSAGE_QUERY}>
    {({ loading, error, data }: QueryResultType) => {
      if (loading) return <Loading />
      if (error) return <Error error={error} />

      const { message } = data

      if (!message.text) {
        return null
      }

      return <Message {...message} />
    }}
  </Query>
)

export default MessageContainer
