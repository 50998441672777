const MuiInput = {
  root: {
    fontWeight: 400,
    '&$disabled': {
      '&:before': {
        borderBottom: '1px dashed rgba(0, 0, 0, 0.3) !important'
      }
    }
  }
}

export default MuiInput
