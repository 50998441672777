import { gql } from 'graphql.macro'

export default gql`
  mutation recordConsentAlternativeFinancing(
    $customerId: String!
    $deviceFingerprint: String!
  ) {
    recordConsentAlternativeFinancing(
      customerId: $customerId
      deviceFingerprint: $deviceFingerprint
    )
  }
`
