// @flow
import React from 'react'
import { Grid, Paper, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import type { BrowserHistory, HashHistory, MemoryHistory } from 'history'
import { inIframe } from 'util/EnvironmentHelper'
import cx from 'classnames'
import l10n from 'properties/translations'
import poweredByMosaic from 'images/mosaic-logo.svg'
import coreScreenStyles from 'themes/coreScreenStyles'
import s from '../../screens/common/OpportunityViewScreen.module.css'
import { DEFAULT_ELEVATION } from 'properties/properties'

const styles = theme => ({
  ...coreScreenStyles(theme),
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  marginTop: {
    marginTop: theme.spacing(6)
  },
  marginButton: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(5)
  },
  titleHeader: {
    fontWeight: theme.typography.fontWeightExtraBold,
    fontSize: 17
  },
  wrapper: {
    height: '100%'
  },
  logo: {
    height: 20
  },
  logoContainer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  paperContainer: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 36,
      marginLeft: 36
    }
  }
})

type Props = {
  classes: {
    wrapper: string,
    logoContainer: string,
    logo: string,
    paper: string,
    paperContainer: string,
    marginBottom: string,
    marginTop: string,
    titleHeader: string,
    marginBottom: string,
    marginButton: string
  },
  history: BrowserHistory | HashHistory | MemoryHistory
}

const changePage = (history: Object) => () => {
  history.push('/password-forgotten')
}

const TokenExpired = ({ classes, history }: Props) => (
  <div
    className={cx({
      [classes.wrapper]: true,
      [s['has-customer']]: !inIframe()
    })}
  >
    <Grid
      container
      direction="column"
      alignItems="center"
      justify="center"
      spacing={1}
      className={classes.logoContainer}
    >
      <Grid item>
        <img
          alt={l10n.layout.poweredByMosaic}
          className={classes.logo}
          src={poweredByMosaic}
        />
      </Grid>
      <Grid item>
        <Typography variant="caption" align="center">
          {l10n.layout.applicationDescription}
        </Typography>
      </Grid>
    </Grid>
    <Paper
      className={cx(classes.paper, classes.paperContainer)}
      elevation={DEFAULT_ELEVATION}
    >
      <Grid
        container
        justify="center"
        alignItems="center"
        className={cx(classes.marginBottom, classes.marginTop)}
      >
        <Grid item>
          <Typography
            data-testid="screen-title"
            variant="subtitle1"
            align="center"
            className={classes.titleHeader}
          >
            {l10n.expired.title}
          </Typography>
        </Grid>
        <Grid item />
      </Grid>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.marginBottom}
      >
        <Grid item>
          <Typography variant="subtitle1" align="center" gutterBottom>
            {l10n.expired.description}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item className={classes.marginButton}>
          <Button
            variant="outlined"
            color="primary"
            onClick={changePage(history)}
          >
            <Typography>{l10n.expired.resendButton}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Paper>
  </div>
)

export default withStyles(styles)(TokenExpired)
