import { gql } from 'graphql.macro'
import SalespersonFields from 'queries/fragments/SalespersonFields.graphql'

export default gql`
  query GetCustomerStatus($customerId: String!) {
    customer(customerId: $customerId) {
      id
      status
      dateESignVerified
      opportunityStatus
      isInfoEditable
      salesperson {
        ...SalespersonFields
      }
      estimateComplete
      applyComplete
      setupComplete
      loanType
      language
      hasBankAccount
    }
  }
  ${SalespersonFields}
`
