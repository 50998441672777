import coreScreenStyles from 'themes/coreScreenStyles'
import { colors, pageTitle, bodyTextNormal } from 'themes/default'

const styles = theme => {
  const coreStyles = coreScreenStyles(theme)
  return {
    editionDisclaimer: { marginTop: theme.spacing(1) },
    list: {
      marginTop: theme.spacing(2),
      paddingLeft: theme.spacing(2)
    },
    listItem: {
      marginLeft: theme.spacing(4),
      marginBottom: theme.spacing(1)
    },
    listButtonText: {
      color: theme.palette.action.link,
      textAlign: 'left',
      fontSize: ' 0.875rem',
      cursor: 'pointer'
    },
    captionButton: {
      fontSize: 'inherit',
      color: theme.palette.action.link,
      position: 'relative',
      verticalAlign: 'baseline'
    },
    paper: {
      padding: theme.spacing(1)
    },
    marginHeader: {
      marginBottom: theme.spacing(4)
    },
    pageTitle: {
      fontWeight: pageTitle.fontWeight,
      fontSize: pageTitle.fontSize,
      color: pageTitle.color
    },
    bodyTextNormal: {
      fontSize: bodyTextNormal.fontSize,
      fontWeight: bodyTextNormal.fontWeight,
      lineHeight: bodyTextNormal.lineHeight,
      color: bodyTextNormal.color
    },
    buttonCentered: {
      textAlign: 'center'
    },
    icon: {
      color: theme.palette.iconSuccess,
      width: 28,
      height: 28,
      marginTop: 3
    },
    caption: { fontWeight: theme.typography.fontWeightMedium },
    condensedLabelRow: {
      position: 'relative',
      marginTop: -1 * (theme.spacing(4) + 3),
      marginBottom: theme.spacing(2)
    },
    dobMonth: {
      width: '100%'
    },
    dobGridItemWrapper: {
      paddingTop: '2px !important'
    },
    btmNextBtn: {
      marginTop: theme.spacing(4)
    },
    textAlignCenter: {
      display: 'flex',
      marginTop: theme.spacing(2),
      width: '100%',
      justifyContent: 'center'
    },
    lastModified: {
      marginTop: theme.spacing(4)
    },
    disclosureContent: {
      textAlign: 'justify'
    },
    patriotDisclosure: {
      color: 'rgba(36, 36, 43, 1)',
      fontSize: '0.875rem'
    },
    equalHousingLender: {
      alignItems: 'center',
      display: 'flex',
      marginTop: theme.spacing(3)
    },
    equalHousingLenderIcon: {
      verticalAlign: 'middle',
      marginRight: theme.spacing(1),
      minHeight: theme.spacing(5),
      height: theme.spacing(5)
    },
    selectPlaceholder: { ...coreStyles.selectPlaceholder, marginTop: 0 },
    sensitiveInfoInputWebkit: coreStyles.sensitiveInfoInputWebkit,
    sensitiveInfoInput: coreStyles.sensitiveInfoInput,
    subtextCtn: {
      padding: theme.spacing(1)
    },
    nextBtnCtn: {
      '& button': {
        height: theme.spacing(6),
        width: '180px',
        borderRadius: theme.spacing(3)
      }
    },
    buttonSubtext: {
      marginTop: theme.spacing(2)
    },
    alternativePhoneNumberExtraMargin: {
      marginTop: 0,
      [theme.breakpoints.up('md')]: {
        marginTop: theme.spacing(2)
      }
    },
    errorMessage: {
      color: theme.palette.error.main,
      marginBottom: theme.spacing(2)
    },
    editInfoErrorMessage: {
      color: theme.palette.error.main,
      fontWeight: theme.typography.fontWeightRegular,
      marginBottom: theme.spacing(2),
      marginLeft: theme.spacing(1),
      fontSize: theme.spacing(1.5)
    },
    ssnField: {
      marginTop: 0
    },
    editBtnCtn: {
      marginTop: theme.spacing(1)
    },
    inactiveForm: {
      marginTop: '15px',
      opacity: 0.4,
      cursor: 'default',
      'pointer-events': 'none'
    },
    disabledForm: {
      '& .Mui-disabled': {
        color: colors.mutedBlack,
        '&:before': {
          borderBottom: `1px dashed ${colors.mutedBlack} !important`
        }
      }
    },
    assigned: {
      '& .MuiFormControl-marginNormal': {
        marginTop: 0
      }
    },
    disabledAutocomplete: {
      '& .MuiFormControl-marginNormal': {
        marginTop: theme.spacing(1) - 3
      }
    },
    bodyFormLabel: {
      fontSize: theme.typography.fontSize + 2,
      fontWeight: 400,
      color: colors.normalBlack
    },
    bodyFormLabelInactive: {
      fontSize: theme.typography.fontSize + 2,
      color: theme.palette.grey[500]
    },
    label: {
      paddingTop: theme.spacing(1.5),
      color: colors.normalBlack
    },
    outOfRangeAlert: {
      color: colors.warningYellow
    },
    outOfRangeInput: {
      '& div': {
        '&:after': {
          borderBottom: `2px solid ${colors.secondary}`
        },
        '&:before': {
          borderBottom: `2px solid ${colors.secondary}`
        }
      }
    },
    customerForm: {
      '& .MuiInputBase-input': {
        padding: '8px 0',
        fontSize: '20px',
        lineHeight: 'normal',
        color: colors.normalBlack,
        '& ::placeholder': {
          fontSize: '20px'
        }
      },
      '& .MuiInputBase-multiline': {
        padding: 0
      },
      '& .MuiInputLabel-root': {
        top: '-3px',
        color: colors.mutedBlack,
        fontSize: '20px'
      },
      '& .MuiInputLabel-shrink': {
        top: 0,
        transform: 'translate(0, 1px) scale(0.63)'
      },
      '& .MuiFormHelperText-root': {
        lineHeight: 1.4
      }
    },
    unselected: {
      '& select': {
        color: `${colors.mutedBlack} !important`
      }
    },
    selected: {
      '& select': {
        color: `${colors.normalBlack} !important`
      }
    },
    hidden: {
      visibility: 'hidden'
    },
    formGlobalError: {
      color: theme.palette.error.main,
      fontSize: theme.spacing(1.5),
      display: 'flex',
      marginTop: theme.spacing(1),
      width: '100%',
      justifyContent: 'center'
    },
    singleInputFullNamePublicApplication: {
      '@media (min-width: 600px)': {
        '& .MuiGrid-item:nth-child(1)': {
          paddingRight: theme.spacing(1)
        },
        '& .MuiGrid-item:nth-child(2)': {
          paddingLeft: theme.spacing(1)
        },
        '& .MuiGrid-item:nth-child(3)': {
          paddingLeft: theme.spacing(1)
        }
      }
    },
    singleInputFullName: {
      '& .MuiGrid-item:nth-child(1)': {
        paddingRight: theme.spacing(1)
      },
      '& .MuiGrid-item:nth-child(2)': {
        paddingLeft: theme.spacing(1)
      },
      '& .MuiGrid-item:nth-child(3)': {
        paddingLeft: theme.spacing(1)
      }
    },
    middleNameVisible: {
      '& .MuiGrid-item:nth-child(2)': {
        paddingRight: theme.spacing(1),
      },
    },
    assignToHelpText: {
      marginTop: theme.spacing(2)
    }
  }
}

export default styles
