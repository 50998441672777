import { gql } from 'graphql.macro'

export default gql`
  mutation CreateBankAccount(
    $accountNumber: String!
    $routingNumber: String!
    $accountType: PartnerAccountTypes!
  ) {
    createPartnerBankAccount(
      request: {
        accountNumber: $accountNumber
        routingNumber: $routingNumber
        accountType: $accountType
      }
    ) {
      bankAccount {
        accountNumber
        routingNumber
        accountType
        status
        bank {
          name
          address
        }
      }
    }
  }
`
