// @flow
import React, { Component } from 'react'
import { Paper, MenuItem } from '@material-ui/core'
import deburr from 'lodash/deburr'
import { flow } from 'lodash'
import Autosuggest from 'react-autosuggest'
import match from 'autosuggest-highlight/match'
import parse from 'autosuggest-highlight/parse'
import { withStyles } from '@material-ui/core/styles'
import * as googlePlacesHelper from 'util/GooglePlacesHelper'
import { getGooglePlacesToken } from 'util/FeatureHelper'
import PoweredByGoogleImg from 'images/powered_by_google_on_white_hdpi.png'
import TextValidator from './TextValidator'
import './GooglePlacesAutosuggestField.css'
import withLaunchDarkly from './LaunchDarklyHOC'

const testing = {
  attrid: 'data-testid',
  valueSuffix: '.field'
}

const styles = theme => ({
  root: {
    height: 250,
    flexGrow: 1
  },
  container: {
    position: 'relative'
  },
  suggestionsContainerOpen: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0
  },
  suggestionsContainerOpenInline: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing(1)
  },
  suggestion: {
    display: 'block'
  },
  suggestionsList: {
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  suggestionsListInline: {
    display: 'inline-block',
    margin: 0,
    padding: 0,
    listStyleType: 'none'
  },
  divider: {
    height: theme.spacing(2)
  }
})

export const Logo = () => (
  <div
    style={{
      boxSizing: 'border-box',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      paddingBottom: '0.5em',
      paddingRight: '0.5em'
    }}
  >
    <img
      src={PoweredByGoogleImg}
      alt="Powered by Google"
      style={{
        height: 15
      }}
    />
  </div>
)

type Props = {
  classes: any,
  InputProps: any,
  onAutosuggestUpdate: Function,
  onChange: Function,
  ldFlags: Object
}

type State = {
  isLoading: boolean,
  suggestions: Array<any>
}

class GooglePlacesAutosuggestField extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      isLoading: false,
      suggestions: []
    }
    const { ldFlags } = props
    const googlePlacesTokenObject = getGooglePlacesToken(ldFlags)
    googlePlacesHelper.initService(googlePlacesTokenObject)
  }

  getSuggestions = value => {
    const inputValue = deburr(value.trim()).toLowerCase()
    const inputLength = inputValue.length

    return inputLength < 3
      ? []
      : googlePlacesHelper.getGeoSuggestions(inputValue).then(data => data)
  }

  getSuggestionValue = suggestion => {
    const { onAutosuggestUpdate, ldFlags } = this.props
    const googlePlacesTokenObject = getGooglePlacesToken(ldFlags)
    this.setState({ isLoading: true }, () => {
      googlePlacesHelper
        .getFullAddressByText(suggestion.description, googlePlacesTokenObject)
        .then(addressParts => {
          onAutosuggestUpdate(addressParts)
          this.setState({ isLoading: false })
        })
    })
    return suggestion.structured_formatting.main_text
  }

  handleChange = (event, { newValue }) => {
    const { onChange } = this.props
    onChange(newValue)
  }

  handleSuggestionsClearRequested = () => {
    this.setState({
      suggestions: []
    })
  }

  handleSuggestionsFetchRequested = async ({ value }) => {
    this.setState({
      suggestions: await this.getSuggestions(value)
    })
  }

  renderInputComponent = inputProps => {
    const { classes, 'data-testid': dataTest, ref, ...rest } = inputProps
    const { isLoading } = this.state
    return (
      <TextValidator
        fullWidth
        InputProps={{
          classes: {
            input: isLoading ? 'shimmer' : classes.input
          },
          inputProps: {
            [testing.attr]: dataTest || 'autoSuggestStreetInput',
            [testing.attrid]: dataTest || 'autoSuggestStreetInput'
          }
        }}
        margin="none"
        inputRef={node => {
          ref(node)
        }}
        {...rest}
        data-testid="autoSuggestStreet"
      />
    )
  }

  renderSuggestion = (suggestion, { query, isHighlighted }) => {
    const suggestionItem = suggestion.description.replace(', USA', '')
    const matches = match(suggestionItem, query)
    const parts = parse(suggestionItem, matches)

    const testingProps = {
      [testing.attr]: `autocompleteSuggestion${testing.valueSuffix}`
    }

    return (
      <MenuItem selected={isHighlighted} component="div" {...testingProps}>
        <div>
          {parts.map((part, index) =>
            part.highlight ? (
              <span key={String(index)} style={{ fontWeight: 500 }}>
                {part.text}
              </span>
            ) : (
              <strong key={String(index)} style={{ fontWeight: 300 }}>
                {part.text}
              </strong>
            )
          )}
        </div>
      </MenuItem>
    )
  }

  render() {
    const { classes, InputProps } = this.props
    const { suggestions } = this.state
    const autosuggestProps = {
      renderInputComponent: this.renderInputComponent,
      suggestions,
      onSuggestionsFetchRequested: this.handleSuggestionsFetchRequested,
      onSuggestionsClearRequested: this.handleSuggestionsClearRequested,
      getSuggestionValue: this.getSuggestionValue,
      renderSuggestion: this.renderSuggestion
    }
    return (
      <Autosuggest
        {...autosuggestProps}
        inputProps={{
          ...InputProps,
          onChange: this.handleChange,
          [testing.attr]: InputProps[testing.attr]
        }}
        theme={{
          container: classes.container,
          suggestionsContainerOpen: classes.suggestionsContainerOpenInline,
          suggestionsList: classes.suggestionsListInline,
          suggestion: classes.suggestion
        }}
        renderSuggestionsContainer={options => (
          <Paper {...options.containerProps} square>
            {options.children}
            {options.children ? <Logo /> : null}
          </Paper>
        )}
      />
    )
  }
}

export default flow(
  withLaunchDarkly,
  withStyles(styles)
)(GooglePlacesAutosuggestField)
