import l10n from 'properties/translations'
import projectTypesId from './projectTypeId'

const {
  quote: {
    projectRow: { projectTypes }
  }
} = l10n

const projectTypeMappings = [
  {
    id: projectTypesId.doors,
    description: projectTypes[projectTypesId.doors]
  },
  {
    id: projectTypesId.windows,
    description: projectTypes[projectTypesId.windows]
  },
  { id: projectTypesId.hvac, description: projectTypes[projectTypesId.hvac] },
  {
    id: projectTypesId.roofing,
    description: projectTypes[projectTypesId.roofing]
  },
  {
    id: projectTypesId.interiorRemodel,
    description: projectTypes[projectTypesId.interiorRemodel]
  },
  {
    id: projectTypesId.exteriorRemodel,
    description: projectTypes[projectTypesId.exteriorRemodel]
  },
  {
    id: projectTypesId.otherOrCustom,
    description: projectTypes[projectTypesId.otherOrCustom]
  },
  {
    id: projectTypesId.solar,
    description: projectTypes[projectTypesId.solar]
  },
  {
    id: projectTypesId.battery,
    description: projectTypes[projectTypesId.battery]
  }
]

export default projectTypeMappings

export const projectTypeMappingsWithoutSolar = projectTypeMappings.filter(
  mapping => mapping.id !== projectTypesId.solar
)
