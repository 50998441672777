export default {
  installer: {
    admin: 'Installer-Admin',
    owner: 'Installer-Owner',
    salesmgr: 'Installer-SalesMgr',
    mosaicSupport: 'Installer-MosaicSupport',
    salesrep: 'Installer-SalesRep',
    operations: 'Installer-Operations'
  },
  borrower: {
    defaultRole: 'Borrower-DefaultRole',
    primary: 'Customer-Primary',
    secondary: 'Customer-Secondary'
  }
}
