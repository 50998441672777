import { gql } from 'graphql.macro'

export default gql`
  fragment ChannelPartnerSetupOptionResponseFields on ChannelPartnerSetupOptionResponse {
    setupType
    tasks {
      taskId
      document {
        requiredDocumentType
      }
      isRequired
      taskType
    }
  }
`
