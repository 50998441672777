// @flow
import { memoize } from 'lodash'
import languageKeys from 'constants/enums/languageKeys'

import translationsEn from './translations.en'
import translationsEs from './translations.es'
import sharedTextEn from './translations/sharedText.en'
import sharedTextEs from './translations/sharedText.es'

type LanguageKey = 'en' | 'es'

export const localizedTranslations: Object = memoize((lang?: LanguageKey) => {
  switch (lang) {
    case languageKeys.es:
      return translationsEs(sharedTextEs)
    case languageKeys.en:
    default:
      return translationsEn(sharedTextEn)
  }
})

const defaultTranslations: Object = translationsEn(sharedTextEn)

export default defaultTranslations

export type LocalizedTranslations = Object
