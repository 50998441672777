import { Typography, CircularProgress, styled } from '@mui/material'
import { get } from 'lodash'
import {
  StyledBox,
  StyledTypography,
  OverridableComponent
} from '../util.react.styled-component-types-0.7.2'
import { FlexBox } from '@solarmosaic/mds.ui.flex-box'

export const SXProcessingCircularProgress = styled(CircularProgress)`
  color: ${({ theme }) =>
    get(theme, 'palette.brandBlue.main', theme.palette.primary.main)};
` as OverridableComponent<any>

export const SXContainerFlexbox = styled(FlexBox)`
  height: 100vh;
  background-color: ${({ theme }) => theme.palette.background.default};
` as StyledBox

export const SXProcessingTypography = styled(Typography)`
  color: ${({ theme }) =>
    get(theme, 'palette.brandBlue.main', theme.palette.primary.main)};
  margin-top: ${({ theme }) => theme.spacing(3)};
` as StyledTypography
