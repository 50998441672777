import { colors, transparencies } from 'themes/default'

export default theme => ({
  moreIcon: {
    backgroundColor: `${colors.primary}${transparencies.alpha10Percent}`,
    padding: theme.spacing(1),
    '&:hover': {
      backgroundColor: `${colors.primary}${transparencies.alpha20Percent}`
    }
  },
  menuApply: {
    position: 'absolute',
    margin: 0,
    top: '175px',
    right: 'calc(((100vw - 960px) / 2) - 60px)',
    minWidth: '155px',
    '& ul': {
      overflow: 'hidden'
    },
    '@media (max-width: 1100px)': {
      right: theme.spacing(2)
    }
  },
  menuApplyContainer: {
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent'
    }
  },
  invisibleMenuItem: {
    minHeight: 0,
    opacity: 0,
    height: 0,
    padding: 0
  },
  titleCaseMenuItem: {
    textTransform: 'capitalize'
  }
})
