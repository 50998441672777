const MuiListItem = {
  root: {
    color: 'rgba(36, 36, 43, 1)',
    fontSize: '1rem',
    fontWeight: '400',
    padding: '12px'
  }
}

export default MuiListItem
