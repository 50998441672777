// @flow
/* eslint react/prop-types: 0 */
import React, { useContext } from 'react'
import NumberFormat from 'react-number-format'
import cx from 'classnames'
import {
  Grid,
  FormControl,
  FormHelperText,
  Select,
  InputLabel
} from '@material-ui/core'
import customerFields from 'constants/enums/customerFields'
import fieldMasks from 'constants/enums/fieldMasks'
import ValidatorMessage from 'components/shared/ValidatorMessage'
import TextValidator from 'components/shared/TextValidator'
import languageKeys from 'constants/enums/languageKeys'
import {
  DOB_DAY_VALIDATORS,
  DOB_YEAR_VALIDATORS,
  DOB_FULL_VALIDATORS,
  EMPTY_STRING,
  EMPTY_ARRAY
} from 'properties/properties'
import placeholders from 'properties/placeholders'
import TranslationsContext from 'util/TranslationsContext'
import PrequalificationFormContext from 'util/PrequalificationFormContext'

/* Values to submit in credit application in demo mode */
const DEMO_MODE_DEFAULT_CUSTOMER = {
  [customerFields.dobMonth]: 1,
  [customerFields.dobDay]: 1,
  [customerFields.dobYear]: 1990
}

const DEMO_MODE_DISPLAY_VALUES = {
  dobDay: '0',
  dobYear: '0000',
  dobFull: '00/00/0000'
}

type Props = {
  classes: any,
  demoMode: boolean,
  dobDay: string,
  dobMonth: string,
  dobYear: string,
  dobFull: string,
  getErrorMessagesRequired: (fieldName: string) => string[],
  getValidatorsRequired: (fieldName: string) => string[],
  isRequiredField: (fieldName: string, runValidation: ?boolean) => boolean,
  labelIsRequired: () => boolean,
  onFieldEdit: func,
  disabled?: boolean,
  showSingleField?: boolean,
  onKeyPress: Function
}

const SingleDobField = (props: Props) => {
  const {
    classes,
    demoMode,
    dobFull,
    labelIsRequired,
    onFieldEdit,
    onKeyPress,
    disabled = false,
    dobAlert = false
  } = props
  const l10n = useContext(TranslationsContext)
  const { customerVerified } = useContext(PrequalificationFormContext)
  const validatorsValue = !customerVerified ? EMPTY_ARRAY : DOB_FULL_VALIDATORS
  const requiredValue = !customerVerified ? EMPTY_ARRAY : labelIsRequired()
  return (
    <Grid
      container
      item
      justify="space-between"
      className={classes.inputContainer}
      xs={12}
    >
      <Grid item xs={12}>
        <NumberFormat
          fullWidth
          type="tel"
          id={customerFields.dobFull}
          name={customerFields.dobFull}
          format={fieldMasks.dateOfBirth}
          placeholder={
            l10n.languageKey === languageKeys.en
              ? placeholders.dobFull
              : placeholders.dobFullSpanish
          }
          customInput={TextValidator}
          label={l10n.customer.dob}
          disabled={disabled || demoMode}
          InputLabelProps={{
            required: requiredValue,
            shrink: true
          }}
          InputProps={{
            classes: { input: classes.input },
            onKeyPress
          }}
          className={cx({
            [classes.outOfRangeInput]: dobAlert
          })}
          validators={[...(demoMode ? EMPTY_ARRAY : validatorsValue)]}
          errorMessages={[
            l10n.customer.dobDayErrorMessage,
            l10n.customer.dobDayErrorMessage
          ]}
          onChange={onFieldEdit(customerFields.dobFull)}
          value={demoMode ? DEMO_MODE_DISPLAY_VALUES.dobFull : dobFull}
        />
        {dobAlert && (
          <FormHelperText>
            <span className={classes.outOfRangeAlert}>
              {l10n.customer.dobYearRangeAlert}
            </span>
          </FormHelperText>
        )}
      </Grid>
    </Grid>
  )
}

const DobFieldsDeprecated = (props: Props) => {
  const {
    additionalCustomerInfo,
    classes,
    demoMode,
    dobDay,
    dobMonth,
    dobYear,
    getErrorMessagesRequired,
    getValidatorsRequired,
    isRequiredField,
    labelIsRequired,
    onFieldEdit,
    onKeyPress,
    dobAlert = false,
    disabled = false
  } = props
  const l10n = useContext(TranslationsContext)
  const { customerVerified } = useContext(PrequalificationFormContext)
  const monthIsSelected = dobMonth !== EMPTY_STRING && dobMonth !== undefined
  const requiredDobDay = isRequiredField(
    customerFields.dobDay,
    customerVerified
  )
  const requiredDobYear = isRequiredField(
    customerFields.dobYear,
    customerVerified
  )
  let validatorsDobYear = EMPTY_ARRAY
  let validatorsDobDay = EMPTY_ARRAY
  if (customerVerified) {
    validatorsDobYear = [
      ...getValidatorsRequired(customerFields.dobYear),
      ...(demoMode ? EMPTY_ARRAY : DOB_YEAR_VALIDATORS)
    ]
    validatorsDobDay = [
      ...getValidatorsRequired(customerFields.dobDay),
      ...(demoMode ? EMPTY_ARRAY : DOB_DAY_VALIDATORS)
    ]
  }

  return (
    <Grid
      container
      spacing={2}
      justify="space-between"
      className={classes.inputContainer}
    >
      <Grid item xs={12} className={classes.labelCtn}>
        <FormHelperText>{l10n.customer.dob}</FormHelperText>
      </Grid>
      <Grid
        item
        xs={6}
        sm={additionalCustomerInfo ? 6 : 4}
        className={classes.condensedLabelRow}
      >
        <FormControl
          className={classes.dobMonth}
          required={isRequiredField(customerFields.dobMonth)}
        >
          <InputLabel
            required={false}
            shrink={false}
            id="dob-month"
            className={cx({ [classes.hidden]: monthIsSelected })}
          >
            {l10n.customer.dobMonth}
          </InputLabel>
          <Select
            native
            fullWidth
            labelId="dob-month"
            data-testid="dobMonth"
            id={customerFields.dobMonth}
            onChange={onFieldEdit(customerFields.dobMonth)}
            name={customerFields.dobMonth}
            className={classes.selectPlaceholder}
            disabled={disabled || demoMode}
            SelectDisplayProps={{
              native: true
            }}
            value={
              dobMonth ||
              (demoMode
                ? DEMO_MODE_DEFAULT_CUSTOMER[customerFields.dobMonth]
                : EMPTY_STRING)
            }
            classes={{
              root: monthIsSelected ? classes.selected : classes.unselected
            }}
          >
            <option key="empty" value=""></option>
            {l10n.monthNames.map((name, index) => (
              <option
                key={name}
                value={index + 1}
                data-testid="dob-fields-select-option"
              >
                {name}
              </option>
            ))}
          </Select>
        </FormControl>
        <ValidatorMessage
          name="dobMonth"
          value={dobMonth}
          validators={getValidatorsRequired(customerFields.dobMonth)}
          errorMessages={getErrorMessagesRequired(
            customerFields.dobMonth,
            l10n
          )}
        />
      </Grid>
      <Grid item xs={3} sm={3} className={classes.condensedLabelRow}>
        <NumberFormat
          fullWidth
          label={l10n.customer.dobDay}
          id={customerFields.dobDay}
          customInput={TextValidator}
          onChange={onFieldEdit(customerFields.dobDay)}
          name={customerFields.dobDay}
          format={fieldMasks.dobDay}
          type="tel"
          placeholder={l10n.customer.dobDay}
          validators={validatorsDobDay}
          errorMessages={[
            ...getErrorMessagesRequired(customerFields.dobDay, l10n),
            l10n.customer.dobDayErrorMessage,
            l10n.customer.dobDayErrorMessage
          ]}
          required={requiredDobDay}
          InputLabelProps={{ required: labelIsRequired() }}
          InputProps={{
            classes: { input: classes.input },
            onKeyPress
          }}
          disabled={disabled || demoMode}
          value={demoMode ? DEMO_MODE_DISPLAY_VALUES.dobDay : dobDay}
          data-testid="dobDay"
        />
      </Grid>
      <Grid item xs={3} sm={3} className={classes.condensedLabelRow}>
        <NumberFormat
          fullWidth
          id={customerFields.dobYear}
          customInput={TextValidator}
          onChange={onFieldEdit(customerFields.dobYear)}
          name={customerFields.dobYear}
          format={fieldMasks.dobYear}
          type="tel"
          placeholder={l10n.customer.dobYear}
          label={l10n.customer.dobYear}
          validators={validatorsDobYear}
          errorMessages={[
            ...getErrorMessagesRequired(customerFields.dobYear, l10n),
            l10n.customer.dobYearErrorMessage,
            l10n.customer.dobYearErrorMessage
          ]}
          className={cx({
            [classes.outOfRangeInput]: dobAlert
          })}
          required={requiredDobYear}
          InputLabelProps={{ required: labelIsRequired() }}
          InputProps={{
            classes: { input: classes.input },
            onKeyPress
          }}
          disabled={disabled || demoMode}
          value={demoMode ? DEMO_MODE_DISPLAY_VALUES.dobYear : dobYear}
          data-testid="dobYear"
        />
        {dobAlert && (
          <FormHelperText>
            <span className={classes.outOfRangeAlert}>
              {l10n.customer.dobYearRangeAlert}
            </span>
          </FormHelperText>
        )}
      </Grid>
      <Grid justify="center" spacing={0} container>
        <ValidatorMessage
          name="dob"
          value={[dobYear, dobMonth, dobDay]}
          validators={['validDate']}
          errorMessages={[l10n.customer.dobDayErrorMessage]}
        />
      </Grid>
    </Grid>
  )
}

const DobFields = (props: Props) => {
  const {
    additionalCustomerInfo,
    classes,
    demoMode,
    dobDay,
    dobMonth,
    dobYear,
    dobFull,
    getErrorMessagesRequired,
    getValidatorsRequired,
    isRequiredField,
    labelIsRequired,
    onFieldEdit,
    disabled = false,
    showSingleField,
    onKeyPress,
    languageKey,
    dobAlert
  } = props
  const DobComponent = showSingleField ? SingleDobField : DobFieldsDeprecated

  return (
    <DobComponent
      additionalCustomerInfo={additionalCustomerInfo}
      classes={classes}
      demoMode={demoMode}
      dobDay={dobDay}
      dobMonth={dobMonth}
      dobYear={dobYear}
      dobFull={dobFull}
      getErrorMessagesRequired={getErrorMessagesRequired}
      getValidatorsRequired={getValidatorsRequired}
      isRequiredField={isRequiredField}
      labelIsRequired={labelIsRequired}
      onFieldEdit={onFieldEdit}
      disabled={disabled}
      showSingleField={showSingleField}
      onKeyPress={onKeyPress}
      languageKey={languageKey}
      dobAlert={dobAlert}
    />
  )
}

export default DobFields
