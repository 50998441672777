// @flow
import React, { useContext } from 'react'
import cx from 'classnames'
import { get } from 'lodash'
import { Query } from '@apollo/client/react/components'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { localizedTranslations } from 'properties/translations'
import OptionalQuery from 'components/shared/OptionalQuery'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import navigationStages from 'constants/enums/navigationStages'
import BubbleForStage from 'screens/pipeline/components/Bubble'
import GetCustomerStatuses from 'queries/GetCustomerStatus.graphql'
import GetVerificationDatesEmailsSent from 'screens/apply/queries/GetVerificationDatesEmailsSent.graphql'
import emailTypes from 'constants/enums/emailTypes'
import {
  customerIsBlocked,
  customerLanguageKey,
  isIntegrator
} from 'util/CustomerHelper'
import { inIframe } from 'util/EnvironmentHelper'
import UserContext from 'util/UserContext'
import { userHasPermission } from 'util/UserHelper'
import { applyIncomplete } from 'util/StageNavigationHelper'
import permissions from 'constants/enums/permissions'
import type { CustomerShape } from 'screens/customer/shapes/customer'
import styles from './StageNavigation.styles'
import { EMPTY_OBJECT } from 'properties/properties'
import useAccessControl from 'util/useAccessControl'
import userTypes from 'constants/enums/userTypes'

type Classes = {
  stageText: string,
  container: string,
  headerReplacement: string,
  item: string,
  stageLine: string,
  stageLineFirst: string,
  stageLineLast: string
}

const bubbleOptions = {
  small: true
}

type StageNavChildProps = {
  customer: CustomerShape,
  classes: Classes,
  ldFlags: any
}

const UnwrappedStageNavChild = ({
  customer,
  classes,
  ldFlags,
  userType
}: StageNavChildProps) => {
  const userContext = useContext(UserContext)
  const { me } = userContext
  const customerId = customer && customer.id
  const l10n = localizedTranslations(customerLanguageKey(customer))

  if (!customer || inIframe() || isIntegrator(me)) {
    return <div className={classes.headerReplacement} />
  }

  const blocked = customerIsBlocked(userContext, ldFlags, customer)
  const unpermitted = {
    info: !userHasPermission(me, permissions.VIEW_CUSTOMER),
    estimate: !userHasPermission(me, permissions.VIEW_ESTIMATE),
    apply: false,
    setup: applyIncomplete(customer)
  }

  return (
    <Grid
      container
      justify="center"
      alignContent="center"
      classes={{ container: classes.container }}
    >
      {userType !== userTypes.borrower && (
        <>
          <Grid item xs={3} classes={{ item: classes.item }}>
            <div className={cx(classes.stageLine, classes.stageLineFirst)} />
            <BubbleForStage
              disabled={blocked || unpermitted.info}
              stage={navigationStages.about}
              data={{ customer, me }}
              options={bubbleOptions}
              ariaLabel={l10n.pipeline.info}
            />
            <Typography classes={{ root: classes.stageText }}>
              {l10n.pipeline.info}
            </Typography>
          </Grid>
          <Grid item xs={3} classes={{ item: classes.item }}>
            <div className={classes.stageLine} />
            <BubbleForStage
              disabled={blocked || unpermitted.estimate}
              stage={navigationStages.estimate}
              data={{ customer, me }}
              options={bubbleOptions}
              ariaLabel={l10n.pipeline.estimate}
            />
            <Typography classes={{ root: classes.stageText }}>
              {l10n.pipeline.estimate}
            </Typography>
          </Grid>
          <Grid item xs={3} classes={{ item: classes.item }}>
            <div className={classes.stageLine} />
            <BubbleForStage
              disabled={blocked || unpermitted.apply}
              stage={navigationStages.apply}
              data={{ customer, me }}
              options={bubbleOptions}
              ariaLabel={l10n.pipeline.apply}
            />
            <Typography classes={{ root: classes.stageText }}>
              {l10n.pipeline.apply}
            </Typography>
          </Grid>
          <Grid item xs={3} classes={{ item: classes.item }}>
            <div className={cx(classes.stageLine, classes.stageLineLast)} />
            <OptionalQuery
              query={GetVerificationDatesEmailsSent}
              runQuery={Boolean(customerId)}
              variables={{ customerId, emailType: emailTypes.eSignReminder }}
            >
              {({ data, loading }) => {
                const props = { customer, me }
                const dateESignVerified = get(
                  data,
                  'customer.dateESignVerified'
                )

                return (
                  <>
                    <BubbleForStage
                      disabled={blocked || unpermitted.setup}
                      stage={navigationStages.setup}
                      data={
                        loading
                          ? props // Default props to remove jitter when loading
                          : {
                              ...props,
                              dateESignVerified
                            }
                      }
                      options={bubbleOptions}
                      ariaLabel={l10n.pipeline.setup}
                    />
                    <Typography classes={{ root: classes.stageText }}>
                      {l10n.pipeline.setup}
                    </Typography>
                  </>
                )
              }}
            </OptionalQuery>
          </Grid>
        </>
      )}
    </Grid>
  )
}

const StageNavChild = withLaunchDarkly(
  withStyles(styles)(UnwrappedStageNavChild)
)

type Props = {
  customerId: ?string,
  customer: ?CustomerShape,
  classes: Classes
}

const StageNavigation = ({
  classes,
  customerId = null,
  customer = null
}: Props) => {
  const { userType } = useAccessControl()

  if (!customerId && !customer) {
    return <div className={classes.headerReplacement} />
  }

  if (customer) {
    return <StageNavChild customer={customer} userType={userType} />
  }

  return (
    <Query query={GetCustomerStatuses} variables={{ customerId }}>
      {({ loading, data = EMPTY_OBJECT }) => {
        if (loading && !customer && customerId) {
          return null
        }
        return <StageNavChild customer={data.customer} userType={userType} />
      }}
    </Query>
  )
}

export default withStyles(styles)(StageNavigation)
