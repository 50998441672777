// @flow
import React, { useState, useRef } from 'react'
import { TextField, Typography } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { withStyles } from '@material-ui/core/styles'
import { head } from 'lodash'
import { EMPTY_STRING } from 'properties/properties'
import { type AutoCompleteComboBoxOption } from 'util/TypesHelper'
import styles from './AutoCompleteComboBox.styles'

type Props = {
  classes: {
    [string]: string
  },
  isAutoFocus: Boolean,
  options: AutoCompleteComboBoxOption[],
  onChangeDropdown: Function,
  displayKey: string,
  disabled: Boolean,
  selectedOption: AutoCompleteComboBoxOption,
  placeholder: string,
  label: string,
  helperText: string,
  'data-testid'?: string
}
const AutoCompleteComboBox = ({
  options,
  onChangeDropdown,
  displayKey,
  selectedOption,
  disabled,
  placeholder,
  label,
  classes,
  helperText,
  ...rest
}: Props) => {
  let inputRef = useRef()
  const dataTestId = rest['data-testid']
  const getSelectedOption = () => {
    if (options && options.length === 1) return head(options)
    return selectedOption !== undefined ? selectedOption : null
  }
  const [value, setValue] = useState(getSelectedOption())

  return (
    <>
      <Autocomplete
        openOnFocus
        disableClearable
        data-testid={dataTestId}
        options={options}
        value={value}
        getOptionLabel={option => option[displayKey] || EMPTY_STRING}
        getOptionSelected={option => (value ? option.id === value.id : true)}
        disabled={disabled}
        className={classes.autoCompleteComboBox}
        onChange={(event, newValue) => {
          if (newValue) {
            onChangeDropdown(event, newValue)
            setValue(newValue)
          }
        }}
        renderInput={params => (
          <TextField
            {...params}
            label={label}
            margin="normal"
            placeholder={placeholder}
            inputRef={input => {
              inputRef = input
              return inputRef
            }}
            data-testid="auto-complete-combo-box-assigned-to-select"
            classes={{ root: classes.root }}
          />
        )}
      />
      {helperText && (
        <Typography className={classes.helperText} variant="caption">
          {helperText}
        </Typography>
      )}
    </>
  )
}

export default withStyles(styles)(AutoCompleteComboBox)
