// @flow
import React from 'react'
import DeclinedScreen from './DeclinedScreen'

interface Props {
  client: any;
  customerId: string;
}

const Foundation = ({ client, customerId }: Props) => (
  <DeclinedScreen client={client} customerId={customerId} />
)

export default Foundation
