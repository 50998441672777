import { createBrowserHistory } from 'history'
import MobileInfo from 'util/MobileInfo'
import { makeSafeLocalStorage } from 'util/makeSafeStorage'

import localStorageKeys from 'constants/enums/localStorageKeys'
import basePaths from 'constants/enums/basePaths'

const safeLocalStorage = makeSafeLocalStorage()
const { urlKey } = localStorageKeys

const history = createBrowserHistory()

const contractBasePaths = [basePaths.contract, basePaths.returnFromContract]

if (MobileInfo.isStandalone()) {
  // Listen and set history as history changes
  history.listen(location => {
    const { pathname, search, hash } = location
    const url = [pathname, search, hash].join('')

    if (contractBasePaths.some(basePath => pathname.startsWith(basePath))) {
      // Do not store contract-related paths in history
      safeLocalStorage.removeItem(urlKey)
      return
    }
    safeLocalStorage.setItem(urlKey, url)
  })

  // Restore persisted storedUrl
  const storedUrl = safeLocalStorage.getItem(urlKey)
  if (storedUrl) {
    history.replace(storedUrl)
  }
}

export default history
