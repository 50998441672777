// @flow
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import sendViaTypes from 'constants/enums/sendViaTypes'
import { phoneNumberAsText } from 'util/StringHelper'
import l10n from 'properties/translations'

type Props = {
  classes: {
    confirmContainer: string,
    confirmText: string
  },
  email: string,
  phoneNumber: number,
  sendVia: string
}

const SmsOnlyContent = ({ classes, phoneNumber }) => (
  <>
    <Typography className={classes.confirmText} variant="body2">
      {l10n.apply.shareLinkDialog.doneSuccessfullySentToMobile}
    </Typography>

    <Typography className={classes.confirmText} variant="body1">
      {phoneNumberAsText(phoneNumber)}
    </Typography>
  </>
)

const EmailOnlyContent = ({ classes, email }) => (
  <>
    <Typography className={classes.confirmText} variant="body2">
      {l10n.apply.shareLinkDialog.doneSuccessfullySentToEmail}
    </Typography>

    <Typography className={classes.confirmText} variant="body1">
      {email}
    </Typography>
  </>
)

const SmsAndEmailContent = ({ classes, email, phoneNumber }) => (
  <Typography className={classes.confirmText} variant="body2" align="center">
    <l10n.apply.shareLinkDialog.DoneSuccessfullySentBoth
      email={email}
      phoneNumber={phoneNumberAsText(phoneNumber)}
    />
  </Typography>
)

const DialogConfirm = ({ sendVia, ...rest }: Props) => (
  <Grid
    className={rest.classes.confirmContainer}
    alignItems="center"
    direction="column"
    container
  >
    {sendVia === sendViaTypes.SmsOnly && <SmsOnlyContent {...rest} />}
    {sendVia === sendViaTypes.EmailOnly && <EmailOnlyContent {...rest} />}
    {sendVia === sendViaTypes.SmsAndEmail && <SmsAndEmailContent {...rest} />}
  </Grid>
)

DialogConfirm.defaultProps = {
  email: null
}

export default DialogConfirm
