import { gql } from 'graphql.macro'

export default gql`
  mutation EditBankAccount(
    $currentAccountNumber: String!
    $accountNumber: String!
    $routingNumber: String!
    $accountType: PartnerAccountTypes!
  ) {
    updatePartnerBankAccount(
      request: {
        currentAccountNumber: $currentAccountNumber
        accountNumber: $accountNumber
        routingNumber: $routingNumber
        accountType: $accountType
      }
    ) {
      bankAccount {
        accountNumber
        routingNumber
        accountType
        status
        bank {
          name
          address
        }
      }
      errors {
        message
        key
        field
      }
    }
  }
`
