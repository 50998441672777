// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { get } from 'lodash'
import CloudUploadIcon from '@material-ui/icons/CloudUpload'
import { Grid, Typography } from '@material-ui/core'
import l10n from 'properties/translations'
import { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import ApplyContext from 'util/ApplyContext'
import styles from './commonStyles'

type Props = {
  classes: {
    subheading: string,
    iconWrapperGray: string,
    iconGray: string,
    linkText: string
  },
  conditionalApprovalLink: string,
  shouldSubmitButtonRender: Function
}

const ConditionalApprovalUpload = ({
  classes,
  conditionalApprovalLink,
  shouldSubmitButtonRender
}: Props) => (
  <ApplyContext.Consumer>
    {value => (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.iconWrapperGray}
          >
            <Grid item>
              <CloudUploadIcon className={classes.iconGray} />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.subheading}>
          <Typography variant="subtitle1" align="center">
            {l10n.apply.formStatus.conditionalApprovalUpload.subHeader}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="body1" paragraph>
            {l10n.apply.formStatus.conditionalApprovalUpload.descriptionLn1}
          </Typography>
          <Typography variant="body2" paragraph>
            {l10n.apply.formStatus.conditionalApprovalUpload.descriptionLn2}
          </Typography>
          {shouldSubmitButtonRender(
            get(value, 'fieldset') === fieldsetNames.FIELDSET_PUBLIC_APPLICATION
          ) && (
            <Typography variant="body1" align="center" paragraph>
              <a
                href={conditionalApprovalLink}
                target="_blank"
                rel="noopener noreferrer"
                className={classes.linkText}
              >
                {l10n.apply.formStatus.conditionalApprovalUpload.descriptionLn3}
              </a>
            </Typography>
          )}
          <Typography variant="body2">
            {l10n.apply.formStatus.conditionalApprovalUpload.descriptionLn4}
            {
              l10n.apply.formStatus.conditionalApprovalUpload
                .descriptionLn4Action
            }
          </Typography>
        </Grid>
      </Grid>
    )}
  </ApplyContext.Consumer>
)

export default withStyles(styles)(ConditionalApprovalUpload)
