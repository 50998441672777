// @flow
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import cn from 'classnames'
import { MOBILE_DETECT_OS } from 'properties/properties'
import MobileInfo from 'util/MobileInfo'
import l10n from 'properties/translations'
import AppleShareIcon from 'images/AppleShareIcon'
import AndroidShareIcon from 'images/AndroidShareIcon'
import PaperCtn from '../PaperCtn'

const styles = theme => ({
  mobileText: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    '& a': {
      textDecoration: 'none'
    }
  },
  textIcon: {
    flex: 1
  },
  appleIcon: {
    color: '#1e90ff'
  },
  icon: {
    width: theme.spacing(6),
    height: theme.spacing(6)
  },
  textCtn: {
    padding: `${theme.spacing(3)}px ${theme.spacing(2)}px`,
    height: '100%'
  }
})

const { mobileOS } = MobileInfo.getMobileInfo()
const { android, ios } = MOBILE_DETECT_OS

const { Text1MobileLink, text2, text3 } = l10n.onboardingSteps.step3

const renderIconType = classes => {
  if (mobileOS === android) return <AndroidShareIcon className={classes.icon} />
  if (mobileOS === ios)
    return <AppleShareIcon className={cn(classes.icon, classes.appleIcon)} />
  return ''
}

const Step3 = ({ classes }) => {
  const textProps = {
    className: classes.mobileText,
    variant: 'body2'
  }

  return (
    <PaperCtn>
      <Grid container direction="column" className={classes.textCtn}>
        <Typography {...textProps} data-testid="onboard-mobile">
          <Text1MobileLink os={mobileOS} />
        </Typography>
        <Typography {...textProps}>{text2}</Typography>
        <Grid
          container
          alignItems="center"
          justify="center"
          className={classes.textIcon}
        >
          {renderIconType(classes)}
        </Grid>
        <Typography {...textProps}>{text3}</Typography>
      </Grid>
    </PaperCtn>
  )
}

export default withStyles(styles)(Step3)
