import { gql } from 'graphql.macro'

export default gql`
  mutation UpdatePartnerLogin($request: UpdatePartnerLoginRequest!) {
    updatePartnerLogin(request: $request) {
      id
      result {
        id
        channelPartnerId
        firstName
        lastName
        emailAddress
        phoneNumber
        role
        status
      }
      errors {
        key
        message
        field
      }
    }
  }
`
