import { gql } from 'graphql.macro'

export default gql`
  query getUserType {
    me {
      userType
      userRoles
      permissions
    }
  }
`
