// @flow
import React, { Component } from 'react'
import { Snackbar, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'

const styles = () => ({
  icon: {
    fontSize: 16
  }
})

type Props = {
  message: string,
  handleClose: Function,
  open: boolean,
  classes: {
    container: string,
    iconButton: string,
    icon: string
  }
}

class SuccessToast extends Component<Props> {
  static defaultProps = {
    open: false,
    classes: {}
  }

  handleClose = () => {
    const { handleClose } = this.props
    handleClose()
  }

  render() {
    const { message, open, classes } = this.props

    const action = (
      <IconButton
        key="close"
        color="inherit"
        aria-label="Close"
        onClick={this.handleClose}
        className={classes.iconButton}
      >
        <CloseIcon className={classes.icon} />
      </IconButton>
    )

    return (
      <>
        <Snackbar
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          message={<span>{message}</span>}
          autoHideDuration={3000}
          action={action}
        />
      </>
    )
  }
}

export default withStyles(styles)(SuccessToast)
