export default {
  accessMode: 'access_mode',
  token: 'pos__access__token',
  refreshToken: 'pos__refresh__token',
  invalidToken: 'invalidToken',
  pipelineSortInformationKey: 'SORT_INFORMATION_LOCALSTORAGE_KEY',
  seenDemoWelcome: 'SEEN_DEMO_WELCOME',
  seenShareLinkWelcome: 'SEEN_SHARE_LINK_WELCOME',
  allowedLandline: 'allowedLandline',
  partnerSessionId: 'partner-sessionId',
  urlKey: 'URL_KEY',
  iframeConfiguration: 'iframe_configuration',
}
