// @flow
import {
  isNull,
  isNil,
  get,
  omit,
  pick,
  isEmpty,
  isString,
  parseInt as lodashParseInt
} from 'lodash'
import lenders from 'constants/enums/lenders'
import userTypes from 'constants/enums/userTypes'
import opportunityStatuses, {
  type OpportunityStatuses
} from 'constants/enums/opportunityStatuses'
import deviceVerificationStatusTypes from 'constants/enums/deviceVerificationStatusTypes'
import contractStatuses from 'constants/enums/contractStatuses'
import projectTypesId from 'constants/enums/projectTypeId'
import projectTypeMappings from 'constants/enums/projectTypeMappings'
import {
  MINIMUM_LOAN_AMOUNT,
  MAXIMUM_LOAN_AMOUNT,
  typenames,
  EMPTY_STRING,
  ENTER_CHARCODE,
  ENTER_KEYCODE
} from 'properties/properties'
import l10n from 'properties/translations'
import { getSimilarApprovedProduct } from 'util/ApplicationFormStatusHelper'
import { projectTypeToId } from 'constants/enums/projectTypes'
import StringHelper from 'util/StringHelper'
import {
  projectIsNullOrOtherType,
  projectHasInvalidStages
} from 'screens/quote/components/inputs/Projects'
import { getProductsFieldName } from 'util/LoanProductHelper'
import {
  type ChannelPartnerAddOn,
  type Project,
  type ProjectStage,
  type QuoteType,
  type LoanProductType,
  type CalculateInputType,
  type ConfigurationType
} from 'screens/quote/QuoteTypes'
import { type CustomerShape } from 'screens/customer/shapes/customer'
import { type AddressShape } from 'screens/customer/shapes/address'

const { pending } = deviceVerificationStatusTypes

const validProjectAmount = project =>
  !isNull(project.amount) || !isEmpty(project.stages)

export const hasStatusAndIsNotPending = (status?: string): boolean =>
  status ? status !== pending : false

type SelectInputDataProps = {
  loanProductId: string,
  hasAchDiscount: boolean,
  lumpSumPayment: number
}

export const selectInputData = ({
  loanProductId,
  hasAchDiscount,
  lumpSumPayment
}: SelectInputDataProps) =>
  loanProductId
    ? { hasAch: hasAchDiscount, loanProductId, prePayment: lumpSumPayment }
    : null

export type HasTypename = {
  __typename: string
}

const removeTypeName = (obj: HasTypename) => {
  const newObj = { ...obj }
  delete newObj.__typename
  return newObj
}

const processStage = stage => ({
  ...stage,
  amount: stage.amount ? parseFloat(stage.amount) : stage.amount
})

export const reduceAmounts = (projectsOrStages: ProjectStage[]): number =>
  projectsOrStages.reduce(
    (accum, next) => (next.amount ? accum + parseFloat(next.amount) : accum),
    0
  )

export const safeStageAmount = (amount?: number | string = 0) => {
  if (isNil(amount)) return EMPTY_STRING
  return amount === 0 || amount === '0' ? EMPTY_STRING : amount
}

export const stagesTotal = (
  stages: ProjectStage[],
  amount?: string | number = EMPTY_STRING,
  isSolarOrBatteryProject?: boolean = false
): string | number => {
  if (isEmpty(stages) || isSolarOrBatteryProject) return safeStageAmount(amount)

  return safeStageAmount(reduceAmounts(stages))
}

export const isSolarOrBatteryProject = (projectType: string): boolean => {
  return (
    projectType === projectTypeToId.SOLAR_SYSTEM ||
    projectType === projectTypeToId.BATTERY
  )
}

export const selectProjectsData = (projects: Project[]): Project[] =>
  !isEmpty(projects)
    ? projects
        .map(
          ({
            id,
            projectType,
            amount,
            status,
            notes,
            projectName,
            addOns,
            stages
          }) => ({
            id,
            projectType,
            amount: stagesTotal(
              stages,
              amount,
              isSolarOrBatteryProject(projectType)
            ),
            status,
            notes,
            projectName,
            addOns: addOns ? addOns.map(removeTypeName) : null,
            stages: stages ? stages.map(removeTypeName).map(processStage) : null
          })
        )
        .filter(validProjectAmount)
    : []

export const selectProjectsDataForMutation = (projects: Project[]) =>
  projects
    ? selectProjectsData(projects).map(project => omit(project, ['status']))
    : []

const selectProjectAddOns = project =>
  project.addOns
    ? project.addOns.map(addOn => ({
        ...addOn,
        __typename: typenames.AddOn
      }))
    : undefined

const selectProjectStages = (project: Project): ?(ProjectStage[]) =>
  project.stages
    ? project.stages.map(stage => ({
        ...stage,
        __typename: typenames.Stage
      }))
    : null

export const selectProjectDataWithTypename = (projects: Project[]): Project[] =>
  selectProjectsData(projects).map(project => {
    const addOns = selectProjectAddOns(project)

    return {
      ...project,
      stages: selectProjectStages(project),
      __typename: typenames.Project,
      ...(addOns && !isEmpty(addOns) ? { addOns } : {})
    }
  })

export const selectProjectsDataForMutationWithTypename = (
  projects: Project[]
): Project[] =>
  selectProjectsDataForMutation(projects).map(project => ({
    ...project,
    addOns: selectProjectAddOns(project),
    stages: selectProjectStages(project),
    __typename: typenames.Project
  }))

export const safeLoanAmount = (
  loanAmount: number | string
): number | string => {
  const result = isString(loanAmount) ? parseFloat(loanAmount) : loanAmount
  if (Number.isNaN(result)) return 0
  return result
}

export const isNotSolarNorBattery = (project: Project): boolean => {
  const isNotSolar =
    !isEmpty(project.stages) &&
    project.projectType !== projectTypeToId.SOLAR_SYSTEM
  const isNotBattery =
    !isEmpty(project.stages) && project.projectType !== projectTypeToId.BATTERY
  return isNotSolar && isNotBattery
}

export const reduceProjectAndStageAmounts = (projects: Project[]) => {
  if (!projects) return 0
  const result = projects.reduce((accum, current) => {
    const projectAmount =
      isNotSolarNorBattery(current) && current.stages
        ? reduceAmounts(current.stages)
        : safeLoanAmount(current.amount)
    return accum + projectAmount
  }, 0)
  return safeLoanAmount(result)
}

export const getSimilarLoanProduct = (
  customer: CustomerShape,
  quote: QuoteType
): ?LoanProductType => {
  const id = get(quote, 'loanProductId')
  const products = `creditApplication.decision.qualifiedProducts`

  if (!id || !customer) return null

  return get(customer, products, []).find(
    lp =>
      lp['id'] ===
      getSimilarApprovedProduct(
        id,
        (get(customer, products, []) || []).map(product => product['id'])
      ).value
  )
}

export const buildLocalUpdateQuoteData = (projects: Project[]): any => ({
  quote: {
    configuration: {
      projects: selectProjectsDataForMutationWithTypename(projects),
      __typename: 'quote_configuration'
    },
    __typename: 'quote'
  }
})

export const validLoanAmount = (
  projectEstimate: number,
  loanProducts: LoanProductType[],
  minimumLoanAmount?: number = MINIMUM_LOAN_AMOUNT,
  maximumLoanAmount?: number = MAXIMUM_LOAN_AMOUNT
): boolean =>
  projectEstimate >= minimumLoanAmount &&
  projectEstimate <= maximumLoanAmount &&
  loanProducts &&
  loanProducts.some(product => product.borrowingLimit >= projectEstimate)

export const addDefaultBorrowingLimitToLoanProducts = (
  loanProducts: LoanProductType[],
  minimumLoanAmount?: number = MINIMUM_LOAN_AMOUNT,
  maximumLoanAmount?: number = MAXIMUM_LOAN_AMOUNT
): LoanProductType[] =>
  loanProducts.map(product => ({
    borrowingLimit: maximumLoanAmount,
    minimumAmount: minimumLoanAmount,
    ...product
  }))

export const customerSelectedLoanProduct = (customer?: CustomerShape = {}) => {
  const id =
    get(customer, 'contract.loanProduct.id') ||
    get(customer, 'quote.loanProductId')
  if (!id) return null

  const loanProducts = get(
    customer,
    'creditApplication.decision.qualifiedProducts',
    []
  )
  return loanProducts.find(
    loanProduct => (loanProduct.loanProductId || loanProduct.id) === id
  )
}

export const customerLoanProjectTypes = (customer?: CustomerShape = {}) => {
  const projects = get(customer, 'quote.projects', []).filter(
    project => !!project.projectType
  )

  return projects.map(project =>
    project.projectType === projectTypesId.otherOrCustom
      ? project.projectName
      : projectTypeMappings.find(mapping => mapping.id === project.projectType)
          .description
  )
}

export const blockEnterKeyCode = (event: KeyboardEvent) => {
  const { key, keyCode } = event
  if (key === ENTER_KEYCODE || keyCode === ENTER_CHARCODE) {
    event.preventDefault()
    event.target.blur()
  }
}

type ShouldShowEmailVerificationProps = {
  userType: string,
  lender: string,
  dateESignVerified: any,
  opportunityStatus: string,
  emailAddressVerified: boolean
}

export const shouldShowEmailVerification = ({
  userType,
  lender,
  dateESignVerified,
  opportunityStatus,
  emailAddressVerified
}: ShouldShowEmailVerificationProps): boolean =>
  !emailAddressVerified &&
  userType === userTypes.installer &&
  lender === lenders.WEBBANK &&
  !dateESignVerified &&
  opportunityStatus !== opportunityStatuses.deleted

export const shouldShowAcceptContract = (
  contractStatus: string,
  opportunityStatus: OpportunityStatuses
) =>
  [contractStatuses.viewed].includes(contractStatus) &&
  opportunityStatus !== opportunityStatuses.deleted

export const awaitingUserLinkContractCreation = (
  emailAddressVerified: boolean,
  contract: any | null
) => emailAddressVerified && isNull(contract)

export const autoPayRoute = 'autopay'
export const confirmRoute = 'confirm'

export const getMatchingLoanProduct = (
  customer: CustomerShape,
  quote: CalculateInputType,
  ldFlags: any
) => {
  const productsFieldName = getProductsFieldName(ldFlags)
  const products = `creditApplication.${productsFieldName}`

  return (
    customer &&
    quote &&
    get(customer, products, []).find(
      lp => (lp.loanProductId || lp.id) === quote.loanProductId
    )
  )
}

export const getApprovedFinancingAmount = (
  quote: QuoteType,
  creditApplication: any,
  ldFlags: any
): number | string => {
  const { financingLimit } = creditApplication
  const loanProduct =
    getMatchingLoanProduct({ creditApplication }, quote, ldFlags) ||
    getSimilarLoanProduct({ creditApplication }, quote, ldFlags)

  if (!loanProduct) {
    const loanProducts = get(
      creditApplication,
      getProductsFieldName(ldFlags),
      []
    )

    const maxBorrowingLimit = Math.max(
      0,
      ...loanProducts.map(lp => lp.borrowingLimit)
    )
    const borrowLimitMax = Math.min(financingLimit, maxBorrowingLimit).toFixed(
      2
    )

    return borrowLimitMax
  }

  const { borrowingLimit } = loanProduct

  const approvedFinanceAmount = Math.min(
    financingLimit,
    borrowingLimit
  ).toFixed(2)

  return approvedFinanceAmount
}

export const projectEstimate = (quote: ConfigurationType) =>
  reduceProjectAndStageAmounts(get(quote, 'projects', []))

export const approvedForLessThanProject = (
  quote: any,
  creditApplication: any,
  ldFlags?: any = {}
) => {
  return (
    getApprovedFinancingAmount(quote, creditApplication, ldFlags) <
    projectEstimate(quote)
  )
}
export const getProjectWithAddOns = (projects: Project[]) =>
  projects.find(project => !isEmpty(project.addOns))

export const getMonthlyPayment = (
  loanProducts: Array<LoanProductType & { loanProductId: number }>,
  selectedLoanProductId: number,
  ldFlags: any
) =>
  get(
    loanProducts.find(
      loanProduct =>
        loanProduct.loanProductId === selectedLoanProductId ||
        loanProduct.id === selectedLoanProductId
    ),
    'sampleTerms.monthlyPayment',
    null
  )

export const showCombinedAmount = (
  servicePlanAmount: string,
  totalAmount: string
): string => {
  const result = parseFloat(servicePlanAmount) + parseFloat(totalAmount)
  return result.toFixed(2)
}

export const isServicePlanAvailable = (projects?: Project[] = []) =>
  projects.some(project => !isEmpty(get(project, 'addOns')))

export const getAddOnsObject = (projects?: Project[] = []) => {
  let addonsObject = null
  projects.forEach(project => {
    if (get(project, 'addOns') !== null) {
      addonsObject = get(project, 'addOns')
    }
  })
  return addonsObject
}

export const projectIdWithServicePlan = (
  addOnProjectTypes: Project[],
  selectedProjects: Project[]
): ?string => {
  const projectsWithServicePlan = addOnProjectTypes.flatMap(addOnProjectType =>
    selectedProjects.filter(
      selectedProject =>
        addOnProjectType.projectType === selectedProject.projectType
    )
  )
  if (!isEmpty(projectsWithServicePlan)) {
    const existingProjectWithServicePlan = projectsWithServicePlan.find(
      project => project.addOns && !isEmpty(project.addOns)
    )
    return existingProjectWithServicePlan
      ? existingProjectWithServicePlan.id
      : projectsWithServicePlan[0].id
  }
  return null
}

export const channelPartnerOffersServicePlans = (
  channelPartnerAddOns: ChannelPartnerAddOn[]
) =>
  channelPartnerAddOns &&
  channelPartnerAddOns.some(addOn => addOn.code === l10n.quote.servicePlan.code)

export const isProjectAmountValid = (project: Project) => {
  if (isNotSolarNorBattery(project)) {
    return project.stages.every(stage => Boolean(stage.amount))
  }
  return Boolean(project.amount)
}

const safeAddresses = addresses =>
  addresses.map(addr => omit(addr, ['errors', '__typename']))

export const formatCreditApplicationRequest = ({
  firstName,
  lastName,
  middleName,
  email,
  assignedTo,
  phoneNumber,
  phoneNumberAlt1,
  addresses,
  dobYear,
  dobMonth,
  dobDay,
  ssn,
  personalIncome,
  otherHouseholdIncome,
  disclosureAccepted,
  notes,
  bundleId
}: any) => ({
  firstName,
  lastName,
  ...(middleName && { middleName }),
  emailAddress: email,
  assignedTo,
  phone: `${phoneNumber}`,
  ...(phoneNumberAlt1
    ? {
        phone2: parseInt(StringHelper.digitsOnly(phoneNumberAlt1), 10)
      }
    : {}),
  addresses: safeAddresses(addresses),
  dob: StringHelper.formatDOB(dobYear, dobMonth, dobDay),
  ssn,
  annualIncome: lodashParseInt(StringHelper.digitsOnly(personalIncome)),
  otherHouseholdIncome: otherHouseholdIncome
    ? lodashParseInt(StringHelper.digitsOnly(otherHouseholdIncome))
    : undefined,
  disclosureAccepted,
  notes,
  disclosuresBundleId: bundleId,
})

export const makeAddressRequest = (address: AddressShape) =>
  pick(address, ['street', 'city', 'stateAbbreviation', 'zip', 'addressType'])

export const anyInvalidCustomProject = (
  projects,
  channelPartnerProjectConfiguration
) =>
  projects.some(
    (project: Object) =>
      projectIsNullOrOtherType(project) ||
      !isProjectAmountValid(project) ||
      projectHasInvalidStages(project, channelPartnerProjectConfiguration)
  )
