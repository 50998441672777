// @flow
import React, { useContext, useEffect, useMemo, useState } from 'react'
import cx from 'classnames'
import { flow, get } from 'lodash'
import { Grid, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { inIframe } from 'util/EnvironmentHelper'
import Loading from 'components/shared/Loading'
import { handleBackButtonPressed } from 'util/Routes'
import BackButton from 'components/shared/BackButton'
import coreScreenStyles, {
  type coreScreenStylesPropsShape
} from 'themes/coreScreenStyles'
import { localizedTranslations } from 'properties/translations'
import { EMPTY_STRING } from 'properties/properties'
import OptionMenu from 'components/shared/OptionMenu'
import { customerLanguageKey } from 'util/CustomerHelper'
import SetupStyles from './Setup.styles'
import ThankYouContent from './components/ThankYouContent'
import SetupSkippedContent from './components/SetupSkippedContent'
import IntegratorContent from './components/IntegratorContent'
import commonStyles from './components/commonStyles'
import { coApplicantFormEnabled } from 'util/FeatureHelper'
import { getAccessMode } from 'util/AccessModeHelper'
import UserContext from 'util/UserContext'
import ThankYou from 'components/loan/ThankYou'
import { useLazyQuery } from '@apollo/client'
import GetCustomerStatus from 'queries/GetCustomerStatus.graphql'
import Client from 'gql/clientNew'
import LoanScreenWrapper from 'components/loan/ScreenWrapper'
import { thanksRoute } from 'util/SetupFormHelper'
import {
  getUserAccessToken,
  storeUserTokens,
  validateRefreshToken
} from 'util/SessionHelper'
import { getDeviceFingerprint } from 'util/DeviceHelper'
import MonitoringHelper from 'util/MonitoringHelper'

const { guestClient } = new Client()

const styles = theme => ({
  ...coreScreenStyles(theme),
  ...commonStyles(theme),
  ...SetupStyles(theme)
})

const getRefreshToken = search => {
  const params = new URLSearchParams(search)
  return params.get('refreshToken')
}

type Props = {
  classes: coreScreenStylesPropsShape,
  onClick: Function,
  hasBankAccount: boolean,
  customer: Object,
  history: Object,
  integrator: boolean,
  guest: boolean,
  ldFlags: Object
}
const Complete = (props: Props) => {
  const { channelPartner: channelPartnerContext } = useContext(UserContext)
  const [getCustomerStatus, { loading, data }] = useLazyQuery(GetCustomerStatus)
  const [contractSigned, setContractSigned] = useState(false)

  const {
    classes,
    onClick,
    customer: customerProp,
    history,
    hasBankAccount,
    ldFlags,
    match,
    location,
    integrator = false,
    guest = false
  } = props
  const customer = customerProp || get(data, 'customer')
  const channelPartner = channelPartnerContext || get(data, 'channelPartner')
  const { section, customerId } = match?.params
  const search = location?.search
  const refreshToken = React.useMemo(() => getRefreshToken(search), [search])

  const getStatus = (l10n, integrator, salesperson, classes) => {
    if (integrator) {
      return {
        content: (
          <IntegratorContent
            localizedTranslations={l10n}
            salesperson={salesperson}
            guest={guest}
            customer={customer}
            contractSigned={contractSigned}
            onContractSigned={() => setContractSigned(true)}
          />
        ),
        title: l10n.setup.integrator.title
      }
    } else {
      return hasBankAccount
        ? {
            content: <ThankYouContent localizedTranslations={l10n} />,
            title: l10n.setup.thankYou.title
          }
        : {
            content: <SetupSkippedContent localizedTranslations={l10n} />,
            title: l10n.setup.setupSkipped.title
          }
    }
  }

  const isGuestIntegrator = guest && integrator

  // TODO: adjust to pull in actual salesperson name for isGuestIntegrator later
  const salesperson = isGuestIntegrator
    ? { firstName: EMPTY_STRING, lastName: EMPTY_STRING }
    : get(customer, 'salesperson')
  const l10n = localizedTranslations(customerLanguageKey(customer))
  const { content, title } = getStatus(l10n, integrator, salesperson, classes)
  const accessToken = getUserAccessToken()
  const queriedAccessToken = React.useRef()

  useEffect(() => {
    const loadCustomer = () =>
      getCustomerStatus({
        variables: {
          customerId
        }
      })
    if (refreshToken && !accessToken && !queriedAccessToken.current) {
      const retrieveUserAccessTokenWhenCookiesMissing = async () => {
        try {
          const deviceFingerprint = await getDeviceFingerprint()
          const result = await validateRefreshToken(guestClient, {
            refreshToken,
            deviceFingerprint,
            accessMode: getAccessMode()
          })

          const { accessToken } = result
          storeUserTokens(accessToken, refreshToken)
          loadCustomer()
        } catch (e) {
          // eslint-disable-next-line
          console.error(e)
          MonitoringHelper.manuallyReportError(e)
        } finally {
          queriedAccessToken.current = true
        }
      }
      retrieveUserAccessTokenWhenCookiesMissing()
    }
  }, [refreshToken, accessToken, getCustomerStatus, customerId])

  useEffect(() => {
    if (accessToken && !customerProp) {
      getCustomerStatus({
        variables: {
          customerId
        }
      })
    }
  }, [accessToken, customerProp, getCustomerStatus, customerId])

  const coApplicant = useMemo(
    () => coApplicantFormEnabled(ldFlags, { customer, channelPartner }),
    [ldFlags, customer, channelPartner]
  )

  if (loading || !customer) {
    return <Loading />
  }

  if (coApplicant && (integrator || section === thanksRoute)) {
    return (
      <>
        <ThankYou {...props} customer={customer} hideHeader={false} />
        {!contractSigned && (
          <IntegratorContent
            localizedTranslations={l10n}
            // empty sales person signs the contract but does not render content
            salesperson={{}}
            customer={customer}
            guest={guest}
            contractSigned={contractSigned}
            onContractSigned={() => setContractSigned(true)}
          />
        )}
      </>
    )
  }

  const showBackButton = !inIframe() && !coApplicant

  return (
    <LoanScreenWrapper customer={customer} customerId={match.params.customerId}>
      <Grid
        container
        justify="space-between"
        alignItems="flex-start"
        className={classes.marginHeader}
      >
        {showBackButton && <BackButton to={handleBackButtonPressed(history)} />}
        <Grid item xs={12}>
          <Typography
            data-testid="screen-title"
            variant="h1"
            align="left"
            className={
              integrator ? classes.integratorCompleteTitle : classes.pageTitle
            }
          >
            {title}
          </Typography>
        </Grid>
        <Grid item className={classes.optionMenuAbsolute}>
          {!isGuestIntegrator && <OptionMenu customer={customer} />}
        </Grid>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center">
        {content}

        {!integrator && (
          <Grid item className={classes.centerItemsWrapper}>
            <Button
              className={cx(classes.doneButton)}
              onClick={onClick}
              color="primary"
              type="button"
              variant="outlined"
              data-testid="thank-you-done"
            >
              <Typography>{l10n.setup.form.buttons.done}</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </LoanScreenWrapper>
  )
}

export default flow(withLaunchDarkly, withRouter, withStyles(styles))(Complete)
