export default theme => ({
  handToCustomerDialog: {
    padding: theme.spacing(8),
    '@media (max-width: 400px)': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    }
  },
  handToCustomerGrid: {
    maxWidth: '236px'
  },
  handToCustomerTitle: {
    marginBottom: theme.spacing(3)
  }
})
