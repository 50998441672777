export default theme => ({
  root: {
    flexGrow: 1,
    color: '#28903b',
    '&$checked': {
      color: '#28903b'
    },
    checked: {}
  },
  checked: {},
  formContainer: {
    padding: '20px'
  },
  logoContainer: {
    maxWidth: '100%',
    flexBasis: '0%',
    marginBottom: '30px'
  },
  logoItem: {
    textAlign: 'center',
    flexBasis: '0%',
    maxWidth: '100%'
  },
  logo: {
    display: 'block',
    marginTop: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '180px'
  },
  foundationTitle: {
    fontWeight: theme.typography.fontWeightRegular,
    fontSize: theme.typography.fontSize * 1.7,
    paddingLeft: theme.spacing(1.5)
  },
  foundationParagraph: {
    marginTop: '10px',
    padding: theme.spacing(1.5),
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize - 1,
    lineHeight: 1.5,
    justifyContent: 'left',
    color: theme.palette.grey['700']
  },
  customerBoxGridStyle: {
    paddingLeft: theme.spacing(1.5),
    paddingRight: theme.spacing(1.5)
  },
  customerNameBox: {
    display: 'flex',
    marginTop: '20px',
    borderRadius: '5px',
    height: '50px',
    backgroundColor: '#e9f4eb',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  sectionTitleTextStyle: {
    fontWeight: theme.typography.fontWeightExtraBold
  },
  sectionGrid: {
    marginTop: '30px',
    padding: theme.spacing(1.5)
  },
  secondSectionGrid: {
    marginTop: '45px',
    padding: theme.spacing(1.5)
  },
  gridItemStyle: {
    padding: theme.spacing(1.5)
  },
  employerNameStyle: {
    marginTop: '12px',
    padding: theme.spacing(1.5)
  },
  financingrequestedStyle: {
    marginTop: '10px'
  },
  downpaymentStyle: {
    marginTop: '30px',
    padding: theme.spacing(1.5)
  },
  coAppJobtitleStyle: {
    marginTop: '-11px'
  },
  employerPhoneStyle: {
    padding: theme.spacing(1.5)
  },
  requestedLoantermsGridStyle: {
    marginTop: '9px',
    padding: theme.spacing(1.5)
  },
  coAppResidenceTypeStyle: {
    marginTop: '12px',
    padding: theme.spacing(1.5)
  },
  coAppJobTitleStyle: {
    marginTop: '10px',
    padding: theme.spacing(1.5)
  },
  appMonthsAtResidenceStyle: {
    marginTop: '-4px',
    padding: theme.spacing(1.5)
  },
  applicantMailingAddressFieldStyle: {
    marginTop: '33px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize - 1,
    color: theme.palette.grey['600']
  },
  applicantMonthlyPaymentFieldStyle: {
    marginTop: '33px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize - 1,
    color: theme.palette.grey['600']
  },
  coAppdriverLicenseFieldStyle: {
    marginTop: '33px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize - 1,
    color: theme.palette.grey['600']
  },
  driverLicenseFieldStyle: {
    marginTop: '33px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize - 1,
    color: theme.palette.grey['600']
  },
  customPrimaryResidenTitle: {
    paddingLeft: theme.spacing(1.5),
    marginTop: '20px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize - 1,
    color: theme.palette.grey['600']
  },
  fieldTitleStyle: {
    marginTop: '20px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize - 1,
    color: theme.palette.grey['600']
  },
  subFieldTitleStyle: {
    marginTop: '20px',
    width: '100%',
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize - 1,
    color: theme.palette.grey['600']
  },
  applicantZipStyle: {
    marginTop: '12px',
    padding: theme.spacing(1.5)
  },
  textValidatorStyle: {
    marginTop: '20px'
  },
  dob: {
    marginTop: '20px'
  },
  ssnGrid: {
    marginTop: '20px'
  },
  ssnLabelStyle: {
    marginTop: '20px'
  },
  amount: {
    marginTop: '20px'
  },
  nativeSelectDropStyle: {
    marginTop: '10px'
  },
  coAppPrimaryStyle: {
    marginTop: '25px',
    padding: theme.spacing(1.5)
  },
  coAppMonthsAtPrimaryStyle: {
    marginTop: '9px',
    padding: theme.spacing(1.5)
  },
  coApplicantGreenBox: {
    display: 'flex',
    borderRadius: '5px',
    height: '80px',
    backgroundColor: 'transparent',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center'
  },
  coApplicantTextStyle: {
    color: '#28903b',
    fontWeight: '700',
    fontSize: theme.typography.fontSize * 1.2
  },
  removeBtn: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.fontSize - 1.5,
    fontWeight: theme.typography.fontWeightExtraBold,
    borderColor: '#28903b',
    color: '#28903b',
    borderRadius: '30px',
    justifyContent: 'center',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  coAppDOB: {
    marginTop: '20px'
  },
  coAppSSNGrid: {
    marginTop: '20px'
  },
  coAppSSNLabelStyle: {
    width: 'max-content'
  },
  disclosureContainer: {
    overflowY: 'scroll',
    height: '96px',
    border: `0 solid ${theme.palette.grey['400']}`,
    paddingTop: theme.spacing(1) - 1.1,
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: theme.palette.grey['300'],
    '&::-webkit-scrollbar': {
      appearance: 'none',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      margin: '12px'
    }
  },
  formFieldLabel: {
    marginBottom: theme.spacing(1)
  },
  labelText: {
    marginTop: theme.spacing(3),
    fontWeight: 400
  },
  discloseText: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize - 1,
    textAlign: 'left',
    lineHeight: 1.4,
    color: theme.palette.grey['800'],
    marginBottom: theme.spacing(1)
  },
  checkboxGridStyle: {
    flexDirection: 'row',
    justifyContent: 'left',
    alignItems: 'center'
  },
  ctaBtnGridStyle: {
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center'
  },
  continueBtn: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.fontSize - 1.8,
    padding: theme.spacing(1.2),
    borderColor: '#28903b',
    backgroundColor: '#28903b',
    color: '#FFFFFF',
    borderRadius: '30px',
    width: '100px',
    justifyContent: 'center',
    textAlign: 'center',
    marginLeft: '10px',
    marginRight: 'auto',
    boxShadow: 'none'
  },
  cancelBtn: {
    marginTop: theme.spacing(1.5),
    marginBottom: theme.spacing(1),
    fontSize: theme.typography.fontSize - 1.8,
    padding: theme.spacing(1),
    borderColor: '#28903b',
    color: '#28903b',
    borderRadius: '30px',
    width: '100px',
    justifyContent: 'center',
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: '10px'
  }
})
