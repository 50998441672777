// @flow
import React, { Component } from 'react'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { isEmpty, keys, noop, get, head, identity, flow } from 'lodash'
import type { ApolloQueryResult } from 'apollo-client'
import { Grid, Button, Typography, Box } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import Error from 'components/shared/Error'
import Loading from 'components/shared/Loading'
import TranslationsContext from 'util/TranslationsContext'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import {
  type AddressShape,
  type AddressMapRecord
} from 'screens/customer/shapes/address'
import GetValidatedAddresses from './queries/GetValidatedAddresses.graphql'
import AddressList from './AddressVerification/components/AddressList'
import CustomerInfo from './AddressVerification/components/CustomerInfo'
import styles from './AddressVerification.styles'
import {
  buildAddressOptions,
  findAddressById
} from './AddressVerificationHelper'

type Props = {
  address?: {
    street: string,
    city: string,
    stateAbbreviation: string,
    zip: string
  },
  addresses: Array<AddressShape>,
  client: any,
  classes: any,
  handleDismiss: Function,
  handleSubmit: Function,
  userNameComplete: string,
  email: string,
  phoneNumber: string,
  showAddressWarning: boolean
}

type State = {
  addressId: {},
  isLoading: boolean,
  isSubmitting: boolean,
  validatedAddressMap: null | { [addressType: string]: AddressMapRecord[] },
  error: { data: { error: any } } | null
}

class AddressVerification extends Component<Props, State> {
  static defaultProps = {
    handleDismiss: noop,
    handleSubmit: noop,
    address: {},
    addresses: [],
    userNameComplete: '',
    email: '',
    phoneNumber: '',
    showAddressWarning: false
  }

  static contextType = TranslationsContext

  state = {
    addressId: {},
    isLoading: true,
    isSubmitting: false,
    error: null,
    validatedAddressMap: null
  }

  async componentDidMount() {
    const { addresses } = this.props
    await this.validateAddresses(addresses)
  }

  getValidatedAddressQuery = async addresses => {
    const { client } = this.props
    try {
      const response: ApolloQueryResult<any> = await client.query({
        query: GetValidatedAddresses,
        variables: { addresses: { addresses } }
      })

      const validatedAddresses = get(response, 'data.validateAddresses', [])
      const addressOptions = buildAddressOptions(validatedAddresses, addresses)

      const addressId = {}

      keys(addressOptions).forEach(valueAddress => {
        addressId[valueAddress] = addressOptions[valueAddress][0].id
      })

      if (!response.errors) {
        this.setState(() => ({
          validatedAddressMap: {
            ...addressOptions
          },
          addressId: {
            ...addressId
          }
        }))
      } else {
        this.setState({ error: head(response.errors) })
      }
    } catch (error) {
      this.setState({ error })
    }
  }

  handleChange = (target, addressType) => {
    const newAddressId = target.value
    this.setState(({ addressId }) => ({
      addressId: { ...addressId, [addressType]: newAddressId }
    }))
  }

  selectAddress = (selectedId: any) => {
    const { validatedAddressMap } = this.state
    const { handleSubmit } = this.props
    if (!validatedAddressMap) return

    this.setState({ isSubmitting: true })

    const addresses = keys(validatedAddressMap).map(addressType => {
      const foundRecord = findAddressById(
        selectedId,
        validatedAddressMap,
        addressType
      )

      if (!foundRecord) return null

      return {
        ...foundRecord.address,
        addressType
      }
    })

    const safeAddresses = addresses.filter(identity)

    handleSubmit(safeAddresses)
  }

  validateAddresses = async addresses => {
    this.setState({ isLoading: true })
    await this.getValidatedAddressQuery(addresses)
    this.setState({ isLoading: false })
  }

  render() {
    const {
      classes,
      handleDismiss,
      userNameComplete,
      email,
      phoneNumber,
      showAddressWarning
    } = this.props

    const l10n = this.context

    const {
      validatedAddressMap,
      isLoading,
      error,
      addressId: stateSelectedId,
      isSubmitting
    } = this.state

    if (isLoading) return <Loading block />
    if (error) return <Error error={error} />

    const { addressWarning, continueButton, multipleAddressDescription } =
      l10n.apply.addressVerification

    return (
      <>
        <CustomerInfo
          userNameComplete={userNameComplete}
          email={email}
          phoneNumber={phoneNumber}
        />

        {!isEmpty(validatedAddressMap) && (
          <>
            <Typography
              variant="body2"
              className={cx(
                classes.informationContainer,
                classes.bodyTextNormal
              )}
            >
              {multipleAddressDescription}
              {showAddressWarning && (
                <Box component="span" className={classes.addressInformation}>
                  {addressWarning}
                </Box>
              )}
            </Typography>

            <AddressList
              hideTitle
              addressMap={validatedAddressMap}
              stateSelectedId={stateSelectedId}
              handleChange={this.handleChange}
            />
          </>
        )}

        <Grid
          className={classes.buttonsContainer}
          alignItems="center"
          justify="center"
          container
          spacing={1}
        >
          <Grid item>
            <Button
              variant="outlined"
              fullWidth
              color="primary"
              onClick={handleDismiss}
              className={classes.button}
            >
              <Typography>
                {l10n.apply.addressVerification.cancelButton}
              </Typography>
            </Button>
          </Grid>
          <Grid item>
            <Button
              data-testid="next"
              variant="contained"
              color="primary"
              fullWidth
              disabled={isSubmitting}
              onClick={() => this.selectAddress(stateSelectedId)}
              className={classes.button}
            >
              <Typography>{continueButton}</Typography>
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }
}

export default flow(
  withApolloConsumer,
  withStyles(styles),
  withLaunchDarkly
)(AddressVerification)
