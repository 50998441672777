// @flow
import * as React from 'react'
import formConfig from 'properties/formConfig'

const dropDownCreator = formConfigEnum =>
  Object.entries(formConfigEnum).map(value => (
    <option key={value[0]} value={value[0]}>
      {value[1]}
    </option>
  ))

const stateListCreator = formConfigEnum =>
  Object.entries(formConfigEnum).map(value => (
    <option key={value[0]} value={value[1]}>
      {value[1]}
    </option>
  ))

export const stateList = stateListCreator(formConfig.VALID_STATES)
export const residenceTypes = dropDownCreator(formConfig.RESIDENCE_TYPES)
export const employmentStatusList = dropDownCreator(
  formConfig.EMPLOYMENT_STATUS
)
export const loanOptions = dropDownCreator(formConfig.LOAN_OPTIONS)
export const loanProductTypes = dropDownCreator(formConfig.LOAN_PRODUCT_TYPES)
export const otherIncomeSources = dropDownCreator(
  formConfig.OTHER_INCOME_SOURCES
)
