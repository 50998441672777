import React, { useContext } from 'react'
import { FormHelperText, Checkbox, FormControl, FormControlLabel } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TranslationsContext from 'util/TranslationsContext'
import PrequalificationFormContext from 'util/PrequalificationFormContext'
import customerFields from 'constants/enums/customerFields'

const styles = theme => ({
  titleAttestationCheckbox: {
    marginBottom: theme.spacing(2)
  },
  label: {
    paddingTop: theme.spacing(1.5)
  },
  titleAttestationError: { paddingLeft: theme.spacing(4), fontSize: '0.875rem' }
})
const useStyles = makeStyles(styles)

const TitleAttestationCheckbox = ({ submitButtonClicked, titleAttestationAccepted, toggleTitleAttestationAccepted }) => {
  const l10n = useContext(TranslationsContext)
  const classes = useStyles()
  const { customerVerified } = useContext(PrequalificationFormContext)

  return (
    <FormControl error={!titleAttestationAccepted} fullWidth className={classes.titleAttestationCheckbox}>
      <FormControlLabel
        classes={{ label: classes.label }}
        disabled={!customerVerified}
        control={
          <Checkbox
            required
            id="title-attestation"
            name={customerFields.titleAttestation}
            data-testid="title-attestation"
            color="primary"
            onChange={toggleTitleAttestationAccepted}
          />
        }
        label={l10n.apply.titleAttestation}
      />
      
      {submitButtonClicked && !titleAttestationAccepted && customerVerified && (
        <FormHelperText className={classes.titleAttestationError}>
          {l10n.apply.titleAttestationNotAccepted}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default TitleAttestationCheckbox
