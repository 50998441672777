// @flow
import React from 'react'
import { Redirect } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  FormLabel,
  FormControlLabel,
  Radio,
  Typography
} from '@material-ui/core'
import { Field } from 'formik'
import { TextField, RadioGroup } from 'formik-material-ui'
import l10n from 'properties/translations'
import PhoneField from 'components/shared/PhoneField'
import modes from 'constants/enums/teamMemberModes'
import adminRoles from 'constants/enums/adminRoles'
import Routes from 'util/Routes'
import styles from '../Admin.styles'
import ScreenTitle from 'components/shared/ScreenTitle'

const { admin } = l10n

type Role = {
  role: string,
  value: string,
  label: string,
  helperText: string
}

type Props = {
  classes: {
    buttonAdd: string,
    teamFormDivider: string,
    teamRadioButton: string,
    teamMemberFormField: string,
    roleLabel: string
  },
  roles: Array<Role>,
  index: number,
  helper: Object,
  mode: string,
  member: Object,
  meIsOwner: boolean,
  meEmailAddress: string
}

const TeamMemberForm = (props: Props) => {
  const { classes, roles, index, mode, member, meIsOwner } = props

  // Prevent Non-owner from Editing Owners
  if (member.role === adminRoles.owner && !meIsOwner) {
    return <Redirect to={{ pathname: Routes.team() }} />
  }

  const showRoles = () => {
    const { isSubmitting } = props.helper.form
    const { meEmailAddress } = props
    let rolesAux = roles

    if (modes.ADD === mode && !meIsOwner) {
      rolesAux = roles.filter(role => adminRoles.owner !== role.value)
    }
    if (modes.EDIT === mode && adminRoles.owner !== member.role && !meIsOwner) {
      rolesAux = roles.filter(role => adminRoles.owner !== role.value)
    }
    // Prevent Owner from demoting self
    if (
      modes.EDIT === mode &&
      meIsOwner &&
      member.emailAddress === meEmailAddress
    ) {
      rolesAux = roles.filter(role => adminRoles.owner === role.value)
    }

    return rolesAux.map(role => (
      <FormControlLabel
        key={role.value}
        className={classes.teamRadioButton}
        value={role.value}
        control={<Radio disabled={isSubmitting} color="primary" />}
        label={
          <>
            <Typography variant="body1">{role.label}</Typography>
            <Typography variant="caption">{role.helperText}</Typography>
          </>
        }
        disabled={isSubmitting}
      />
    ))
  }

  return (
    <>
      <ScreenTitle title={l10n.screenTitles.adminTeamAdd} />
      <Grid
        container
        item
        justify="flex-start"
        alignItems="stretch"
        spacing={3}
      >
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.teamMemberFormField}
            type="text"
            name={`members[${index}].firstName`}
            data-testid="team-member-form-firstname"
            label={admin.teamMembers.firstName}
            component={TextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.teamMemberFormField}
            type="text"
            name={`members[${index}].lastName`}
            data-testid="team-member-form-lastname"
            label={admin.teamMembers.lastName}
            component={TextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.teamMemberFormField}
            type="text"
            name={`members[${index}].phoneNumber`}
            data-testid="team-member-form-phone"
            label={admin.teamMembers.phoneNumber}
            component={PhoneField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Field
            className={classes.teamMemberFormField}
            type="email"
            name={`members[${index}].emailAddress`}
            data-testid="team-member-form-email"
            label={admin.teamMembers.emailAddress}
            component={TextField}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormLabel component="legend" className={classes.roleLabel}>
            {admin.teamMembers.role}
          </FormLabel>
          <Field
            component={RadioGroup}
            aria-label={admin.teamMembers.role}
            name={`members[${index}].role`}
            data-testid="team-member-form-role"
            required
          >
            {showRoles()}
          </Field>
        </Grid>
      </Grid>
    </>
  )
}

export default withStyles(styles)(TeamMemberForm)
