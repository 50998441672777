// @flow
import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import get from 'lodash/get'
import {
  Grid,
  FormControl,
  InputLabel,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { Formik, Form, Field } from 'formik'
import * as Yup from 'yup'
import { withStyles } from '@material-ui/core/styles'
import { Select } from 'formik-material-ui'
import { Mutation } from '@apollo/client/react/components'
import type { BrowserHistory, HashHistory, MemoryHistory } from 'history'
import Routes from 'util/Routes'
import Button from 'components/shared/Button'
import l10n from 'properties/translations'
import accountTypes from 'constants/enums/accountTypes'
import SuccessToast from 'components/shared/SuccessToast'
import ErrorToast, { errorToastIcon } from 'components/shared/ErrorToast'
import RoutingNumber from 'components/shared/BankAccountRoutingNumber'
import BankAccountNumber from 'components/shared/BankAccountNumber'
import { CIRCULAR_PROGRESS_SIZE } from 'properties/properties'
import styles from '../Admin.styles'
import CreateBankAccount from '../queries/CreateBankAccount.graphql'
import { inP3xIframe } from 'util/EnvironmentHelper'

import { Typography as MdsTypography } from '@solarmosaic/mds.components.typography'
import { ThemeWrapper } from '@solarmosaic/util.react.theme-wrapper-for-compositions'
import { createMuiThemeTessera } from '@solarmosaic/mds.themes.mui-theme-tessera'
import MdsCheckmark from 'images/mds-checkmark.png'

type Props = {
  history: BrowserHistory | HashHistory | MemoryHistory
}

type State = {
  openSuccess: boolean,
  errorOccurred: boolean
}

const bankAccountSchema = Yup.object().shape({
  bankAccount: Yup.object().shape({
    routingNumber: Yup.string().required(l10n.admin.errors.required),
    accountNumber: Yup.string().required(l10n.admin.errors.required),
    accountNumberRepeat: Yup.string()
      .required(l10n.admin.errors.required)
      .oneOf([Yup.ref('accountNumber')], l10n.admin.errors.accountMatch), 
    accountType: Yup.string().required(l10n.admin.errors.required)
  })
})

type BankAccount = {
  routingNumber: string,
  accountNumber: string,
  accountNumberRepeat: string,
  accountType: string
}

const bankAccount: BankAccount = {
  routingNumber: '',
  accountNumber: '',
  accountNumberRepeat: '',
  accountType: accountTypes.checking
}

class BankAccountNew extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      openSuccess: false,
      errorOccurred: false,
      loading: false,
      p3xSuccess: false
    }
  }

  showSuccess = () => {
    this.setState({
      openSuccess: true
    })
  }

  showError = () => {
    this.setState({
      errorOccurred: true,
      loading: false
    })
  }

  handleSubmit = async (
    values: BankAccount,
    createPartnerBankAccount: Function
  ) => {
    const response = await createPartnerBankAccount({
      variables: {
        accountNumber: values.accountNumber,
        accountNumberRepeat: values.accountNumberRepeat,
        routingNumber: values.routingNumber,
        accountType: values.accountType
      }
    })

    const bankAccountStatus = get(
      response,
      'data.createPartnerBankAccount.bankAccount.status'
    )

    if (bankAccountStatus) {
      this.showSuccess()
    } else {
      this.showError()
    }
  }

  handleSuccessClose = () => {
    const { history } = this.props
    this.setState({ openSuccess: false }, () => {
      if (inP3xIframe()) {
        this.setState(() => ({
          p3xSuccess: true
        }))
      } else {
        history.push(Routes.admin())
      }
    })
  }

  render() {
    const { classes } = this.props
    const { openSuccess, errorOccurred, p3xSuccess } = this.state

    if (p3xSuccess) {
      return (
        <ThemeWrapper theme={createMuiThemeTessera()}>
          <Grid item className={classes.p3xAdminEmptyState}>
            <img
              alt="Checkmark"
              className={classes.mdsCheckmark}
              src={MdsCheckmark}
            />
            <MdsTypography
              variant="h2"
              style={{ color: '#0F8189' }}
              data-testid="bank-account-info-updated"
            >
              Bank account information updated.
            </MdsTypography>
          </Grid>
        </ThemeWrapper>
      )
    }

    return (
      <>
        <SuccessToast
          message={l10n.admin.bankInformation.bankAccountUpdatedSuccess}
          open={openSuccess}
          handleClose={this.handleSuccessClose}
        />
        <ErrorToast
          message={l10n.shared.error}
          open={errorOccurred}
          handleClose={this.showError}
          iconType={errorToastIcon.serverError}
        />
        <Mutation mutation={CreateBankAccount}>
          {createPartnerBankAccount => (
            <Formik
              initialValues={{ bankAccount }}
              validationSchema={bankAccountSchema}
              onSubmit={async (values, actions) => {
                await this.handleSubmit(
                  values.bankAccount,
                  createPartnerBankAccount
                )
                actions.setSubmitting(false)
              }}
            >
              {({ isValid, isSubmitting, dirty }) => {
                const cannotSubmit = !isValid || !dirty || isSubmitting
                const isDisabled = isSubmitting || openSuccess

                return (
                  <Grid item>
                    <Form>
                      <Grid
                        container
                        item
                        direction="column"
                        justify="center"
                        alignItems="stretch"
                        spacing={3}
                      >
                        <Grid item>
                          <Typography
                            variant="h1"
                            data-testid="admin-bank-information-title"
                          >
                            {l10n.admin.bankInformation.title}
                          </Typography>
                        </Grid>
                        <Grid item>
                          <Typography
                            variant="subtitle1"
                            data-testid="admin-bank-information-description"
                          >
                            {l10n.admin.bankInformation.description}
                          </Typography>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        item
                        direction="row"
                        justify="flex-start"
                        alignItems="stretch"
                        spacing={3}
                        className={classes.buttonMargin}
                      >
                        <Grid item xs={12} sm={6}>
                          <FormControl fullWidth>
                            <InputLabel
                              shrink
                              htmlFor="bankAccount.accountType"
                            >
                              {
                                l10n.setup.confirmation.bankFields
                                  .accountTypeLabel
                              }
                            </InputLabel>
                            <Field
                              native
                              required
                              name="bankAccount.accountType"
                              component={Select}
                              inputProps={{
                                name: 'bankAccount.accountType',
                                id: 'bankAccount.accountType'
                              }}
                              disabled={isSubmitting}
                              fullWidth
                            >
                              <option
                                key={accountTypes.checking}
                                value={accountTypes.checking}
                              >
                                {
                                  l10n.setup.confirmation.accountTypes[
                                    accountTypes.checking
                                  ]
                                }
                              </option>
                              <option
                                key={accountTypes.savings}
                                value={accountTypes.savings}
                              >
                                {
                                  l10n.setup.confirmation.accountTypes[
                                    accountTypes.savings
                                  ]
                                }
                              </option>
                            </Field>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            required
                            type="tel"
                            name="bankAccount.routingNumber"
                            data-testid="bank-account-routing-number"
                            label={l10n.admin.bankInformation.routingNumber}
                            component={RoutingNumber}
                            disabled={isSubmitting}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            required
                            type="tel"
                            name="bankAccount.accountNumber"
                            data-testid="bank-account-account-number"
                            label={l10n.admin.bankInformation.accountNumber}
                            component={BankAccountNumber}
                            disabled={isSubmitting}
                            fullWidth
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Field
                            type="tel"
                            required
                            name="bankAccount.accountNumberRepeat"
                            data-testid="bank-account-account-number-repeat"
                            label={l10n.admin.bankInformation.titleVerify}
                            component={BankAccountNumber}
                            disabled={isSubmitting}
                            fullWidth
                          />
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="center"
                        className={classes.buttonMargin}
                        spacing={3}
                      >
                        <Grid item>
                          <Button
                            disabled={cannotSubmit || isDisabled}
                            color="primary"
                            data-testid="bank-account-submit"
                            variant="contained"
                            type="submit"
                          >
                            {isSubmitting || openSuccess ? (
                              <CircularProgress
                                size={CIRCULAR_PROGRESS_SIZE}
                                data-testid="circular-progress"
                              />
                            ) : (
                              l10n.admin.submit
                            )}
                          </Button>
                        </Grid>
                      </Grid>
                    </Form>
                  </Grid>
                )
              }}
            </Formik>
          )}
        </Mutation>
      </>
    )
  }
}

export const UnwrappedBankAccountNew = withStyles(styles)(BankAccountNew)
export default withRouter(UnwrappedBankAccountNew)
