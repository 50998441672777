// @flow
import React, { useContext } from 'react'
import { Grid, Input, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TranslationsContext from 'util/TranslationsContext'
import PrequalificationFormContext from 'util/PrequalificationFormContext'
import CreditScoreNotice from 'components/shared/CreditScoreNotice'
import cx from 'classnames'
import { getAppVersionCommitHash } from 'util/EnvironmentHelper'
import styles from './HeaderItems.styles'

const useStyles = makeStyles(styles)

const HeaderItems = () => {
  const classes = useStyles()
  const l10n = useContext(TranslationsContext)
  const { customerPending, customerVerified } = useContext(
    PrequalificationFormContext
  )

  return (
    <>
      <Grid
        container
        item
        justify="space-between"
        className={classes.headerRow}
      >
        <Typography
          variant="h1"
          className={cx(
            {
              [classes.h1]: true,
              [classes.inactiveColor]: customerPending
            },
            classes.pageTitle
          )}
        >
          {l10n.prequalificationForm.title}
        </Typography>
        <Input
          data-testid="commit-hash-swiftlinks"
          type="hidden"
          value={getAppVersionCommitHash()}
        />
        <CreditScoreNotice showCreditScoreText={false} />
      </Grid>
      <Grid item>
        <Typography
          variant="body1"
          className={cx(classes.bodyTextNormal, {
            [classes.body1]: true,
            [classes.inactiveColor]: customerPending
          })}
        >
          {customerVerified
            ? l10n.prequalificationForm.thanksContinueBelow
            : l10n.prequalificationForm.initialHelpText}
        </Typography>
      </Grid>
    </>
  )
}

export default HeaderItems
