import { colors } from 'themes/default'

export default theme => ({
  projects: {
    marginBottom: theme.spacing(1),
    color: colors.mutedBlack,
    '& .MuiInputBase-input': {
      padding: '8px 0',
      fontSize: '20px',
      '& ::placeholder': {
        fontSize: '20px'
      }
    },
    '& .MuiFormControl-marginNormal': {
      margin: 0
    },
    '& ::placeholder': {
      color: colors.mutedBlack,
      opacity: 1
    }
  },
  section: {
    fontWeight: 500,
    textTransform: 'uppercase',
    color: theme.palette.black,
    marginBottom: 14
  },
  root: {
    marginBottom: 36
  },
  buttonTertiary: {
    padding: '0 16px',
    margin: 6,
    height: 30,
    minHeight: 'inherit',
    borderRadius: 15,
    boxShadow: 'none'
  },
  buttonLabelTertiary: {
    fontSize: 11,
    fontWeight: 700,
    opacity: 0.9,
    letterSpacing: '0.025em',
    color: 'inherit'
  },
  projectRowContainer: {
    paddingBottom: theme.spacing(2)
  },
  projectType: {
    color: colors.mutedBlack
  }
})
