// @flow

const lenders = Object.freeze({
  WEBBANK: 'WEBBANK',
  MOSAIC: 'MOSAIC',
  CONNEXUS: 'CONNEXUS'
})

export type Lenders = $Values<typeof lenders>

export default lenders
