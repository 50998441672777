// @flow
import * as React from 'react'
import { useContext, useState } from 'react'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { flow, get } from 'lodash'
import cx from 'classnames'
import { pageTitle } from 'themes/default'
import Page from 'components/templates/Page'
import ScreenTitle from 'components/shared/ScreenTitle'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import verificationMutationActions from 'constants/enums/verificationMutationActions'
import { lastFourSSNFlagEnabled } from 'util/FeatureHelper'
import {
  getCustomerIdFromProps,
  queryStringHasKey
} from 'util/NavigationHelper'
import TranslationsContext from 'util/TranslationsContext'
import UserContext from 'util/UserContext'
import { EMPTY_STRING, reviewSummaryProperties } from 'properties/properties'
import { isIntegrator } from 'util/CustomerHelper'
import routes from 'util/Routes'
import * as AnalyticsHelper from 'util/AnalyticsHelper'
import { getContractEsignUrl } from 'util/ContractCreationHelper'
import { eventTypes } from 'constants/enums/analyticsEventTypes'
import { hasPartialCreditApplicationPermission } from 'util/UserHelper'
import VerifyIdentityContent from './VerifyIdentityContent'

const styles = theme => ({
  paper: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    minHeight: '50vh'
  },
  marginHeader: {
    marginBottom: theme.spacing(4)
  },
  pageTitle: {
    fontWeight: pageTitle.fontWeight,
    fontSize: pageTitle.fontSize,
    color: pageTitle.color
  }
})
const useStyles = makeStyles(styles)
const { minimumViewAgreementMs } = reviewSummaryProperties

type Props = {
  client: Object,
  history: {
    replace: Function,
    push: Function
  },
  ldFlags: Object,
  location: Object
}

const VerifyIdentity = (props: Props) => {
  const classes = useStyles()
  const [errorMessage, setErrorMessage] = useState(null)
  const l10n = useContext(TranslationsContext)
  const userContext = useContext(UserContext)
  const { me } = userContext
  const permissions = get(me, 'permissions', [])
  const { client, history, ldFlags, location } = props
  const queryString = get(location, 'search', EMPTY_STRING)
  const redirectState = get(location, 'state', {})
  const {
    title = l10n.apply.formStatus.informationVerification.title,
    description = l10n.apply.formStatus.informationVerification.description,
    id,
    mutationToInvoke,
    verifyingIdentityPriorToEsign = false,
    fullSsnRequired: fullSsnRequiredState = false,
    signatorRole = undefined,
  } = redirectState
  // fullSsnRequired UI can be enabled via React Router state or passing a 'full' parameter in the query string
  const fullSsnQueryParam = queryStringHasKey(queryString, 'full')
  const fullSsnRequired = fullSsnRequiredState || fullSsnQueryParam
  const customerId = getCustomerIdFromProps(props) || id

  const userHasPartialCreditAppPermission =
    hasPartialCreditApplicationPermission(permissions)

  const lastFourSSNEnabled =
    lastFourSSNFlagEnabled(ldFlags) && userHasPartialCreditAppPermission

  const loadEsignUrl = async () => {
    const sourceRoute = routes.apply(customerId)
    return getContractEsignUrl(
      { id: customerId },
      sourceRoute,
      client,
      ldFlags,
      isIntegrator(me),
      {
        throttleMs: minimumViewAgreementMs
      },
      signatorRole
    )
  }

  const handleVerificationSuccess = async () => {
    try {
      const eSignUrl = await loadEsignUrl()
      if (eSignUrl) {
        AnalyticsHelper.track(eventTypes.loanAgreementViewed, {
          customerId
        })
        history.replace(routes.contract(customerId, routes.apply(customerId), signatorRole))
      } else {
        setErrorMessage(l10n.apply.accept.loanAgreementError)
      }
    } catch (error) {
      setErrorMessage(l10n.apply.accept.genericError)
    }
  }

  const redirectToApply = () => {
    history.push(routes.apply(customerId))
  }

  return (
    <Page showBackButton>
      <ScreenTitle title={title} />
      <Grid
        container
        justify="space-between"
        alignItems="flex-start"
        className={cx({ [classes.marginHeader]: !lastFourSSNEnabled })}
      >
        <Grid item>
          <Typography
            variant="h1"
            className={classes.pageTitle}
            data-testid="screen-title"
            gutterBottom={lastFourSSNEnabled}
          >
            {title}
          </Typography>
          {lastFourSSNEnabled && description && (
            <Typography variant="body2" paragraph>
              {description}
            </Typography>
          )}
        </Grid>
      </Grid>
      <VerifyIdentityContent
        fullSsnRequired={fullSsnRequired}
        dobRequired={verifyingIdentityPriorToEsign ? true : false}
        customerId={customerId}
        mutationToInvoke={
          verifyingIdentityPriorToEsign
            ? mutationToInvoke
            : verificationMutationActions.validateSsn
        }
        onVerificationSuccess={
          verifyingIdentityPriorToEsign
            ? handleVerificationSuccess
            : redirectToApply
        }
        history={history}
      />
      {errorMessage && (
        <Grid container justify="center" alignItems="center">
          <Grid item>
            <Typography
              variant="body2"
              className={cx(classes.errorText, classes.errorTextSpacing)}
              align="center"
              paragraph
            >
              {errorMessage}
            </Typography>
          </Grid>
        </Grid>
      )}
    </Page>
  )
}

export default flow(withLaunchDarkly, withApolloConsumer)(VerifyIdentity)
