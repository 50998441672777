// @flow
import React from 'react'
import { Typography } from '@material-ui/core'
import l10n from 'properties/translations'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'
import CurrencyNumberFormat from 'components/shared/CurrencyNumberFormat'
import lenders from 'constants/enums/lenders'

type Props = {
  classes: any,
  projectEstimate: number,
  lender: string
}

const ProjectAmount = ({ classes, projectEstimate, lender }: Props) => (
  <div className={classes.sectionCtn}>
    <Typography variant="caption" align="center">
      {lender === lenders.WEBBANK
        ? l10n.apply.productSelection.projectEstimateWebbank
        : l10n.apply.productSelection.projectEstimate}
    </Typography>
    <Typography align="center" variant="h6">
      <CurrencyNumberFormat
        renderText={text => <SuperScriptAmount price={text} />}
        value={projectEstimate}
      />
    </Typography>
  </div>
)

export default ProjectAmount
