// @flow
import React, { useContext } from 'react'
import { get, find } from 'lodash'
import { Button, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import GetTasks from 'screens/apply/queries/GetTasks.graphql'
import OptionalQuery from 'components/shared/OptionalQuery'
import Loading from 'components/shared/Loading'
import taskTypes from 'constants/enums/taskTypes'
import taskStatuses from 'constants/enums/taskStatuses'
import TranslationsContext from 'util/TranslationsContext'

import prequalificationFormStyles from '../PrequalificationForm.styles'

const useStyles = makeStyles(prequalificationFormStyles)

type Props = {
  onClick: Function,
  onConsentToContact: Function,
  showLoading: boolean,
  currentCustomerId: ?string,
  disabled: ?boolean
}

const ContinueButton = ({
  onClick,
  onConsentToContact,
  showLoading,
  currentCustomerId,
  disabled = false
}: Props) => {
  const classes = useStyles(prequalificationFormStyles)
  const l10n = useContext(TranslationsContext)

  return (
    <>
      {showLoading ? (
        <Loading block />
      ) : (
        <OptionalQuery
          runQuery={() => Boolean(currentCustomerId)}
          fetchPolicy="network-only"
          query={GetTasks}
          variables={{ customerId: currentCustomerId }}
        >
          {({ loading, data }) => {
            if (loading) {
              return <Loading block />
            }

            const tasks = get(data, 'customer.tasks', [])

            const consentToContactTask =
              find(tasks, ['taskType', taskTypes.consentToContact]) || {}
            if (consentToContactTask.taskStatus === taskStatuses.completed) {
              onConsentToContact()
              return null
            }

            return (
              <Button
                className={classes.button}
                color="primary"
                type="submit"
                variant="contained"
                onClick={onClick}
                disabled={disabled}
                data-testid="swiftlinks-spp-agreeAndContinue"
              >
                <Typography>
                  {l10n.prequalificationForm.agreeAndContinue}
                </Typography>
              </Button>
            )
          }}
        </OptionalQuery>
      )}
    </>
  )
}

export default ContinueButton
