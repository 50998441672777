const MuiMenuItem = themeColors => {
  return {
    root: {
      color: 'rgba(36, 36, 43, 0.7)',
      width: '100%',
      fontSize: '1rem',
      fontWeight: '400',
      padding: '12px',
      '& p': {
        color: themeColors.normalBlack,
        fontWeight: 400
      }
    }
  }
}

export default MuiMenuItem
