const errorTypes = {
  offline: 'Network error: The internet connection appears to be offline.',
  phoneNumberOptedOut: 'PhoneNumberOptedOut',
  phoneNumberOptedOutException: 'PhoneNumberOptedOutException',
  phoneNumberIsLandline: 'PhoneNumberIsLandline',
  phoneNumberIsInvalid: 'PhoneNumberIsInvalid',
  serverErrorType: 'ServerError',
  challengedRequired: 'ChallengeRequiredException',
  productLimit: 'ContractSurrenderProductLimit',
  contractExceedsProductLimit: 'ContractExceedsProductLimit',
  emailVerificationError: 'EmailVerificationRequiredException',
  serverError: 'An error occurred',
  noLoanProducts: 'NoLoanProductsException',
  failedFields: {
    EMAIL: 'EMAIL',
    PRIMARY_ADDRESS: 'PRIMARY_ADDRESS',
    MAILING_ADDRESS: 'MAILING_ADDRESS',
    PROJECT_SECOND_HOME_ADDRESS: 'PROJECT_SECOND_HOME_ADDRESS',
    PROJECT_RENTAL_ADDRESS: 'PROJECT_RENTAL_ADDRESS',
    NOTES: 'NOTES',
    ALTERNATE_PHONE: 'ALTERNATE_PHONE',
    PHONE: 'PHONE'
  },
  graphQLErrorFieldsToFailedFields: {
    phoneNumber: 'PHONE',
    email: 'EMAIL'
  },
  createCreditApplicationError: 'SubmitException',
  notFound: 'NotFoundException',
  invalidCaptcha: 'FailedCaptchaException',
  noCaptcha: 'FailedPreconditionException',
  InstallCompleteException: 'InstallCompleteException',
  internalServerError: 'Internal server error',
  customErrorCode: {
    phoneNumberOptedOut: '1000'
  },
  creditApplicationExpired: 'Application expired',
  validationException: 'ValidationException',
  enterCustomerEmail: 'EnterCustomerEmail',
  emailAddress: 'emailAddress',
  contractAlreadySigned: 'ContractAlreadySigned',
  deprecatedEndpointException: 'DeprecatedEndpointException',
  fullSsnRequired: 'FullSsnChallengeRequiredException',
  applicationException: 'ApplicationException'
}

export type ErrorType = $Values<typeof errorTypes>

export default errorTypes
