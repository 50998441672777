// @flow
import { stagesTotal } from 'util/ApplyHelper'
import type {
  ProjectStage,
  ChannelPartnerProjectConfiguration,
  StageError
} from 'screens/quote/QuoteTypes'
import { get, isString, isNil, isEmpty } from 'lodash'
import {
  STAGE_NAME_MAX_LENGTH,
  EMPTY_STRING,
  typenames
} from 'properties/properties'
import { v4 as uuidv4 } from 'uuid'

export const generateStageObject = (stageProps = {}) => ({
  id: uuidv4(),
  name: '',
  amount: '',
  __typename: typenames.Stage,
  ...stageProps
})

export const getProjectCostWithoutStage = (
  stages: Array<ProjectStage>,
  stage: ProjectStage
) =>
  stagesTotal(
    stages.filter(stageToFilter => {
      if (!stageToFilter.id) {
        throw new Error('All stages must have ids.')
      }
      return stageToFilter.id !== stage.id
    })
  )

const getProjectPercentage = (
  projectConfig: ChannelPartnerProjectConfiguration,
  projectCost: number
) => (projectCost * projectConfig.minStagePercentage) / 100

export const getMinStageCost = (
  projectConfig: ChannelPartnerProjectConfiguration,
  projectCost: number
): number =>
  Math.max(
    projectConfig.minStageAmount,
    getProjectPercentage(projectConfig, projectCost)
  )

export const nameValid = name =>
  Boolean(name && isString(name) && !isEmpty(name.replace(/\W/g, ''))) // Ensure the content isn't just whitespace

export const getStageError = (
  stage: StageResponse,
  minStageCost?: number
): StageError => {
  const { name, amount } = stage
  const showNameError = !nameValid(name)

  if (isNil(amount)) {
    return { showAmountError: false, showNameError }
  }

  const stageAmount = parseFloat(amount)
  let showAmountError = false
  if (Number.isNaN(stageAmount)) {
    showAmountError = true
  } else if (!isNil(stageAmount) && !isNil(minStageCost)) {
    showAmountError = stageAmount < minStageCost
  }
  return { showAmountError, showNameError }
}

export const calculateErrorForStage = (
  stages,
  stageToUpdate,
  channelPartnerProjectConfiguration
): StageError => {
  // Method 1. EXCLUSIVE of stage:
  // const projectCost = getProjectCostWithoutStage(stages, stageToUpdate)

  // Method 2: INCLUSIVE of stage:
  const projectCost = stagesTotal(stages)

  const minStageCost = getMinStageCost(
    channelPartnerProjectConfiguration,
    projectCost
  )
  return getStageError(stageToUpdate, minStageCost)
}

export const errorsForProjectRowStages = (
  stages: Array<StageResponse>,
  errors: any,
  channelPartnerProjectConfiguration: ChannelPartnerProjectConfiguration
) => {
  const newErrors = { ...errors }
  if (!stages) return {}
  // No channel partner configuration but stages = solar flex
  if (!channelPartnerProjectConfiguration) return {}
  stages.forEach(stage => {
    const stageError = calculateErrorForStage(
      stages,
      stage,
      channelPartnerProjectConfiguration
    )
    newErrors[stage.id] = stageError
  })
  return newErrors
}

//  Converts the first letter of a word to caps
export const capitalizeRowText = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1)
}

export const processStageNameValue = stageName => {
  if (!isString(stageName)) return stageName
  return capitalizeRowText(stageName.substring(0, STAGE_NAME_MAX_LENGTH))
}

export const projectFinalStage = projectResponse => {
  // EX-4194 - Built with the understanding that only the final stage has docs
  // In the future, if other stages have docs, BE will need to provide a field
  // to indicate the final stage or provide a completion timestamp
  const { stages = [] } = projectResponse || {}
  const result = stages.find(stage => get(stage, 'documents', []).length > 0)
  return result || null
}

export const getAmountAsString = (value: string): string => {
  if (value === EMPTY_STRING) return '0'

  return parseFloat(value).toString()
}
