import { get, isEmpty } from 'lodash'
import Routes from 'util/Routes'
import stageComplete from 'constants/enums/stageCompleteProperties'
import contractStatuses from 'constants/enums/contractStatuses'
import navigationStages from 'constants/enums/navigationStages'
import userTypesEnum from 'constants/enums/userTypes'
import { EMPTY_STRING } from 'properties/properties'
import { isBorrower } from 'util/CustomerHelper'
import { shouldShowEmailVerification } from 'util/ApplyHelper'

export const completeForStage = (navigationStage, { customer }) => {
  switch (navigationStage) {
    case navigationStages.about:
      return true
    case navigationStages.estimate:
      return get(customer, stageComplete.estimate, false)
    case navigationStages.apply:
      return get(customer, stageComplete.apply, false)
    case navigationStages.setup:
      return get(customer, stageComplete.setup, false)
    default:
      return false
  }
}

export const routeForStage = (navigationStage, data) => {
  if (!data) return EMPTY_STRING
  const { me } = data
  const id = get(data, 'customer.id')
  const suppressId = me && isBorrower(me)
  const routeArgument = suppressId ? null : id
  switch (navigationStage) {
    case navigationStages.about:
      return Routes.customer(routeArgument)
    case navigationStages.estimate:
      return Routes.quote(routeArgument)
    case navigationStages.apply:
      return Routes.apply(routeArgument)
    case navigationStages.setup: {
      return Routes.dashboard(routeArgument)
    }
    default:
      return EMPTY_STRING
  }
}

export const disableIncompleteForStage = (
  navigationStage,
  {
    me,
    customer: { lender, contract, opportunityStatus },
    dateESignVerified = []
  }
) => {
  const { userType } = me || { userType: userTypesEnum.installer }
  const needsEmailVerification = shouldShowEmailVerification({
    userType,
    lender,
    dateESignVerified,
    opportunityStatus
  })
  switch (navigationStage) {
    case navigationStages.setup:
      return (
        needsEmailVerification ||
        get(contract, 'status') !== contractStatuses.signed
      )
    default:
      return false
  }
}

export const bubbleIsActive = (linkTo, pathname) =>
  pathname.startsWith(linkTo) || pathname === linkTo

export const renderSmallBubble = (navigationStage, data) => {
  const disableIncomplete = disableIncompleteForStage(navigationStage, data)
  const complete = completeForStage(navigationStage, data)
  const isSigned =
    get(data, 'customer.contract.status', '') === contractStatuses.signed
  const eSignVerified = !isEmpty(data.customer.dateESignVerified)

  const setupStageBlocked =
    navigationStage === navigationStages.setup &&
    !complete &&
    isSigned &&
    eSignVerified

  return (disableIncomplete && !complete) || setupStageBlocked
}
