// @flow
import React, { useContext } from 'react'
import { Typography, Button, Box } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { makeStyles } from '@material-ui/core/styles'
import TranslationsContext from 'util/TranslationsContext'
import { reloadPage } from 'util/NavigationHelper'

const styles = theme => ({
  timeOutText: { maxWidth: 500, marginTop: theme.spacing(4) },
  timeOutButton: { fontWeight: 600, marginTop: theme.spacing(4) },
  boxContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: `-${theme.spacing(4)}px`
  }
})

const useStyles = makeStyles(styles)

const TimeoutProcessing = ({
  onRefresh = reloadPage
}: {
  onRefresh: Function
}) => {
  const classes = useStyles()
  const l10n = useContext(TranslationsContext)

  return (
    <Box className={classes.boxContainer}>
      <Typography
        data-testid="timeOutText"
        variant="body2"
        className={classes.timeOutText}
        align="center"
      >
        {l10n.apply.formStatus.processing.timeout}
      </Typography>
      <Button
        data-testid="refreshButton"
        color="primary"
        className={classes.timeOutButton}
        onClick={onRefresh}
      >
        {l10n.apply.formStatus.processing.refresh} <RefreshIcon />
      </Button>
    </Box>
  )
}

export default TimeoutProcessing
