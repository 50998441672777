// @flow
import { get } from 'lodash'
import ReactSessionApi from 'react-session-api'
import localStorageKeys from 'constants/enums/localStorageKeys'
import backendEnvironments from 'constants/enums/backendEnvironments'
import {
  DEFAULT_COOKIE_OPTIONS,
  userAPI,
  oldCookieOptions
} from 'properties/properties'
import { leaseBaseURL } from 'properties/p3x'
import ValidateRefreshToken from 'queries/ValidateRefreshToken.graphql'
import { makeSafeLocalStorage } from 'util/makeSafeStorage'
import {
  getUsageContextForEnv,
  inIframe,
  usageContexts,
  getEnvironment,
  isDevelopmentEnv
} from 'util/EnvironmentHelper'
import { getUUIDFromPath } from 'util/AuthHelper'
import emailVerificationSessionStore from 'constants/enums/emailVerificationSessionStore'
import claimsUserRolesEnum from 'constants/enums/claimsUserRoles'
import { cookieDomains } from 'constants/enums/hostnames'
import Storage from './Storage'
import adminEnvironments from 'constants/enums/adminEnvironments'

const safeLocalStorage = makeSafeLocalStorage()
let FULL_HOSTNAME_ENABLED_FOR_COOKIES = false

const PROD_WILDCARD_DOMAIN = '.joinmosaic.com'

export const cookiesEnabled = (): boolean => navigator.cookieEnabled

let tokenKeyName = localStorageKeys.token

export const getSalesforceLoginURL = () => {
  const { hostname } = window.location
  let env = getUsageContextForEnv()

  // APD-235 - Special case for e2e since it can't rely on usageContext for a cookie prefix and will return a `test`, which is incorrect
  if (hostname.endsWith(cookieDomains.e2e)) {
    env = 'e2e'
  }
  const prefix = env === usageContexts.prod ? '' : `.${env}`
  return `https://login${prefix}${PROD_WILDCARD_DOMAIN}`
}

const getTokenKeyName = () => {
  const { hostname } = window.location
  let env = getUsageContextForEnv()

  // APD-1406 - Special case for e2e since it can't rely on usageContext for a cookie prefix and will return a `test`, which is incorrect
  if (hostname.endsWith(cookieDomains.e2e)) {
    env = 'e2e'
  }
  const prefix = env === usageContexts.prod ? '' : `${env}_`
  return `${prefix}${localStorageKeys.token}`
}

export const setCookiePrefixEnabled = enabled => {
  if (enabled) tokenKeyName = getTokenKeyName()
}

export const setUseFullHostnameOnAccessTokenCookie = enabled => {
  FULL_HOSTNAME_ENABLED_FOR_COOKIES = enabled
}

export const getUserAccessToken = () => {
  if (inIframe()) {
    const sessionToken = ReactSessionApi.get(tokenKeyName)
    if (sessionToken) return sessionToken
  }

  return Storage.get(tokenKeyName)
}

export const destroyUserAccessToken = () =>
  removeAllPossibleCookies(tokenKeyName)

export const getUserRefreshToken = (options = { allowFromPath: true }) => {
  if (window) {
    const {
      location: { pathname }
    } = window
    const uuid = getUUIDFromPath(pathname)
    const { allowFromPath } = options
    if (uuid && allowFromPath) {
      // eslint-disable-next-line no-console
      console.info('[refresh token] force-used from path')
      return uuid
    }
  }
  return Storage.get(localStorageKeys.refreshToken)
}

const removeAllPossibleCookies = (
  cookieKey,
  options = DEFAULT_COOKIE_OPTIONS
) => {
  Storage.remove(cookieKey, { ...options, domain: window?.location?.hostname }) // remove with hostname
  Storage.remove(cookieKey, { ...options, domain: undefined }) // Remove without domain
  Storage.remove(cookieKey, {
    ...options,
    domain: originalCookieDomainFromHostname(window?.location?.hostname)
  }) // Remove without "tap" or "apply" subdomain, original cookie domains
  Storage.remove(cookieKey, oldCookieOptions.defaultCookieOptions) // { Secure: true }
  Storage.remove(cookieKey, oldCookieOptions.crossDomainCookieOptions) // { SameSite: 'None', Secure: true }
  Storage.remove(cookieKey, {
    ...oldCookieOptions.defaultCookieOptions,
    domain: PROD_WILDCARD_DOMAIN
  })
  Storage.remove(cookieKey, {
    ...oldCookieOptions.crossDomainCookieOptions,
    domain: PROD_WILDCARD_DOMAIN
  })
}

export const destroyUserSession = (
  destroyAll = false,
  options = DEFAULT_COOKIE_OPTIONS
) => {
  const token = getUserAccessToken()
  if (token) {
    safeLocalStorage.setItem(localStorageKeys.invalidToken, token)
  }

  try {
    ReactSessionApi.remove(tokenKeyName)
    ReactSessionApi.remove(localStorageKeys.refreshToken)
    ReactSessionApi.remove(localStorageKeys.partnerSessionId)
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }

  removeAllPossibleCookies(tokenKeyName, options)
  removeAllPossibleCookies(localStorageKeys.refreshToken, options)
  removeAllPossibleCookies(localStorageKeys.partnerSessionId, options)

  safeLocalStorage.removeItem(localStorageKeys.pipelineSortInformationKey)
  safeLocalStorage.removeItem(localStorageKeys.allowedLandline)
  safeLocalStorage.removeItem(localStorageKeys.urlKey)
  safeLocalStorage.removeItem(localStorageKeys.iframeConfiguration)
  if (destroyAll) {
    safeLocalStorage.removeItem(localStorageKeys.invalidToken)
  }

  if (window.analytics) {
    window.analytics.reset()
  }
}

export const originalCookieDomainFromHostname = (hostname = '') => {
  const isDevelopment = isDevelopmentEnv()
  if (isDevelopment) {
    return undefined
  }

  if (
    hostname.includes(cookieDomains.qa) ||
    hostname.includes(cookieDomains.e2e) ||
    hostname.includes(cookieDomains.sandbox)
  ) {
    if (hostname.endsWith(cookieDomains.qa)) {
      return cookieDomains.qa
    }
    if (hostname.endsWith(cookieDomains.e2e)) {
      return cookieDomains.e2e
    }
    if (hostname.endsWith(cookieDomains.sandbox)) {
      return cookieDomains.sandbox
    }
  } else {
    // NOTE:
    // Because '.joinmosaic.com' is a subset of others (i.e. '.qa.joinmosaic.com')
    // this would override others above, so it needs its own block
    if (hostname.endsWith(cookieDomains.production)) {
      return cookieDomains.production
    }
  }
}

export const storeUserTokens = (
  accessToken,
  refreshToken,
  options = DEFAULT_COOKIE_OPTIONS
) => {
  try {
    ReactSessionApi.set(tokenKeyName, accessToken)
    ReactSessionApi.set(localStorageKeys.refreshToken, refreshToken)
  } catch (e) {
    // eslint-disable-next-line
    console.error(e)
  }
  try {
    const cookieDomain = FULL_HOSTNAME_ENABLED_FOR_COOKIES
      ? window?.location?.hostname
      : originalCookieDomainFromHostname(window?.location?.hostname)
    const cookieOptions = {
      domain: cookieDomain,
      ...options
    }
    Storage.set(tokenKeyName, accessToken, cookieOptions)
    Storage.set(localStorageKeys.refreshToken, refreshToken, cookieOptions)
  } catch (e) {
    // eslint-disable-next-line
    console.error(e)
  }
}

const isTestEnv = environmentName =>
  [backendEnvironments.local, backendEnvironments.qa].includes(
    environmentName
  ) && process.env.REACT_APP_CI === 'true'

export const apiEnvironmentIs = (environmentName, UIHost) => {
  if (isTestEnv(environmentName)) {
    return true
  }

  return getEnvironment(UIHost) === environmentName
}

const getInvalidAccessToken = () =>
  safeLocalStorage.getItem(localStorageKeys.invalidToken)

const constructMeQueryOptions = token => ({
  headers: {
    auth: token,
    'Content-Type': 'application/json'
  },
  method: 'POST',
  body: JSON.stringify({ query: 'query getMe { me { userId } }' })
})

export const fetchLogoutReason = async () => {
  let reason = ''
  const token = getInvalidAccessToken()

  if (token) {
    // Ping a simple endpoint to get the error message
    const response = await fetch(userAPI, constructMeQueryOptions(token))
    const bodyText = await response.text()
    if (bodyText) {
      reason = bodyText
    }
  }
  return reason
}

export const isLoggedIn = () => Boolean(getUserAccessToken())

export const validateRefreshToken = async (
  client,
  { refreshToken, deviceFingerprint, accessMode }
) => {
  const invalidResult = null
  try {
    const result = await client.mutate({
      mutation: ValidateRefreshToken,
      variables: {
        refreshToken,
        deviceFingerprint
      },
      context: {
        headers: {
          accessMode
        }
      }
    })
    const data = get(result, 'data.validateRefreshToken')

    if (!data) return invalidResult

    return {
      ...data,
      refreshToken
    }
  } catch {
    return invalidResult
  }
}

export const qualifiedScreenInLocalStorage = safeSessionStorage =>
  safeSessionStorage.getItem(emailVerificationSessionStore.SCREEN_NAME) ===
  emailVerificationSessionStore.SCREEN_NAME_VALUES.QUALIFIED_SCREEN

export const isNewSession = (requestId: string): boolean => {
  const existingRequestId = getUserRefreshToken()
  return requestId !== existingRequestId
}

export const getSessionProps = () => ({
  usageContext: getUsageContextForEnv(),
  authorization: getUserAccessToken(),
  baseURL: new URL(leaseBaseURL)
})

export const getClaimsFromAccessToken = () => {
  const token = getUserAccessToken()
  try {
    const middleOfToken = token.split('.')[1]
    const buf = Buffer.from(middleOfToken, 'base64')
    const metadata = JSON.parse(buf.toString())
    return metadata
  } catch (e) {
    return null
  }
}

export const accessTokenIsLeaseCustomer = metadata =>
  get(metadata, 'role') === claimsUserRolesEnum.customer &&
  get(metadata, 'engagementId')

export const cookieDomainForEnvironment = () => {}

export const redirectToP3X = (isSalesforceIdentityEnabled, path = '') => {
  if (isSalesforceIdentityEnabled) {
    const env = getEnvironment()

    const envStringMap = {
      demo: 'demo.',
      e2e: 'e2e.',
      local: 'qa.',
      production: '',
      qa: 'qa.'
    }

    return (window.location.href = `https://portal.${envStringMap[env]}joinmosaic.com${path}`)
  }
  return false
}

export const redirectToResiPortal = (
  isSalesforceIdentityEnabled,
  path = '',
  channelPartner = {}
) => {
  const hostName = get(channelPartner, 'hostName', 'login')
  if (isSalesforceIdentityEnabled) {
    return (window.location.href = `${adminEnvironments[getEnvironment()](
      hostName
    )}${path}`)
  }
  return false
}
