// @flow
import type { AccessModes, Section } from '../appConfig'

const filterByLiveModeEnabled =
  (accessModes: AccessModes) => (section: Section) =>
    !section.allowedAccessModes ||
    (section.allowedAccessModes &&
      section.allowedAccessModes.some(value => accessModes.includes(value)))

export default {
  filterByLiveModeEnabled
}
