// @flow
import React from 'react'
import { CircularProgress, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import coreScreenStyles, {
  type coreScreenStylesPropsShape
} from 'themes/coreScreenStyles'

const styles = theme => ({
  ...coreScreenStyles(theme)
})

type Props = {
  classes: coreScreenStylesPropsShape,
  block: boolean,
  text: ?string
}

const Loading = ({ classes, block = false, text }: Props) => (
  <Grid
    container
    item
    data-testid="loading"
    direction="column"
    alignItems="center"
    justify="center"
    className={block ? classes.circularProgressBlock : classes.circularProgress}
  >
    <CircularProgress
      className={classes.loadingSpinner}
      data-testid="circular-progress"
      tabIndex="-1"
    />
    {text && <Typography className={classes.loaderText}>{text}</Typography>}
  </Grid>
)

export default withStyles(styles)(Loading)
