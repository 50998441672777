import { gql } from 'graphql.macro'

export default gql`
  query validatePhoneAndEmail($emailAddress: String!, $phoneNumber: String!) {
    validatePhoneAndEmail(
      emailAddress: $emailAddress
      phoneNumber: $phoneNumber
    ) {
      isEmailValid
    }
  }
`
