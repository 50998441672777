// import { gql } from 'graphql.macro'
import { gql } from 'graphql.macro'

export default gql`
  mutation CreateCustomer($request: CreateCustomerRequest!) {
    createCustomer(request: $request) {
      id
    }
  }
`
