// @flow
import React, { Component } from 'react'
import { Subscribe } from 'unstated'
import { withRouter } from 'react-router-dom'
import cx from 'classnames'
import { noop, includes } from 'lodash'
import { Grid, Button, Drawer, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'themes/default'
import l10n from 'properties/translations'
import basePaths from 'constants/enums/basePaths'
import bannerTypes from 'constants/enums/bannerTypes'
import BannerContainer from 'store/BannerContainer'
import AccessModeContainer from 'store/AccessModeContainer'
import withUnstatedContainer from 'components/shared/UnstatedContainerHOC'
import flow from 'lodash/flow'

const styles = theme => ({
  wrapper: {
    position: 'fixed',
    bottom: 0,
    zIndex: 1150
  },
  contentWrapper: {
    width: '100%',
    textAlign: 'center',
    padding: theme.spacing(2)
  },
  drawer: {
    zIndex: 1400 // should be highest in the app
  },
  warningContentTitleWrapper: {
    backgroundImage: theme.palette.gradients.warningGradient,
    backgroundSize: '98.99px 98.99px',
    textTransform: 'uppercase',
    cursor: 'pointer'
  },
  warningContentBodyWrapper: {
    padding: theme.spacing(3),
    backgroundColor: colors.demoModeRed
  },
  text: {
    color: theme.palette.common.white
  },
  warningText: {
    fontSize: 18,
    fontWeight: theme.typography.fontWeightExtraBold
  },
  messageText: {
    whiteSpace: 'pre-line',
    maxWidth: theme.spacing(75),
    fontWeight: 500,
    margin: '0 auto'
  },
  messageAction: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  demoBtn: {
    color: theme.palette.common.white,
    border: `1px solid ${theme.palette.common.white}`,
    fontSize: '13px',
    fontWeight: 700,
    borderRadius: theme.spacing(3),
    padding: `0 ${theme.spacing(2)}px`,
    margin: '8px 0'
  },
  adminBanner: {
    backgroundImage: 'none',
    backgroundColor: colors.extraLightGray,
    textTransform: 'none',
    opacity: 1,
    transition: 'all 0s ease-out 1s',
    cursor: 'default',
    width: '100%'
  },
  adminBannerText: {
    color: colors.darkGray,
    textTransform: 'none',
    fontWeight: '400',
    fontSize: theme.spacing(1) + 6,
    display: 'inline'
  }
})

type BannerHeadProps = {
  type: string,
  classes: {
    wrapper: string,
    contentWrapper: string,
    text: string,
    bannerTitleText: string
  },
  bannerTitleText: string,
  setBannerBody: Function
}

const BannerHead = ({
  classes,
  type,
  bannerTitleText,
  setBannerBody,
  location: { pathname }
}: BannerHeadProps) => {
  const isAdminPage = includes(pathname, basePaths.admin)
  return (
    <Grid
      item
      className={cx(
        classes.contentWrapper,
        isAdminPage && classes.adminBanner,
        classes[`${type}ContentTitleWrapper`]
      )}
      onClick={() => {
        setBannerBody()
      }}
    >
      <Typography
        className={cx(
          classes.text,
          classes.bannerTitleText,
          isAdminPage && classes.adminBannerText,
          classes[`${type}Text`]
        )}
      >
        {bannerTitleText}
      </Typography>
    </Grid>
  )
}

BannerHead.defaultProps = {
  type: bannerTypes.warning,
  bannerTitleText: l10n.banner.demoModeTitleText
}

type BannerUnwrappedProps = {
  type: string,
  classes: {
    wrapper: string
  },
  bannerTitleText: string,
  bannerTestName: string
}
export class BannerUnwrapped extends Component<BannerUnwrappedProps> {
  static defaultProps = {
    ...BannerHead.defaultProps,
    bannerTestName: 'demo-mode-banner',
    location: {}
  }
  setBannerBody = banner => {
    if (!banner.state.expanded) {
      banner.open({
        bodyText: l10n.banner.demoModeBodyText.default
      })
    } else {
      banner.close()
    }
  }

  render() {
    const {
      type,
      classes,
      bannerTestName,
      location: { pathname }
    } = this.props
    const isAdminPage = includes(pathname, basePaths.admin)
    return (
      <Subscribe to={[BannerContainer]}>
        {banner => {
          const { bodyText, buttonText, expanded } = banner.state
          return (
            <>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
                className={classes.wrapper}
                data-testid={bannerTestName}
              >
                {isAdminPage ? (
                  <BannerHead
                    {...this.props}
                    setBannerBody={noop}
                    bannerTitleText={l10n.banner.demoModeBodyText.setup}
                  />
                ) : (
                  <BannerHead
                    {...this.props}
                    setBannerBody={() => {
                      this.setBannerBody(banner)
                    }}
                    bannerTitleText={l10n.banner.demoModeTitleTextNew}
                  />
                )}
              </Grid>
              <Drawer
                open={expanded}
                anchor="bottom"
                className={classes.drawer}
              >
                <>
                  {isAdminPage ? (
                    <BannerHead
                      {...this.props}
                      setBannerBody={noop}
                      bannerTitleText={l10n.banner.demoModeBodyText.setup}
                    />
                  ) : (
                    <BannerHead
                      {...this.props}
                      setBannerBody={() => {
                        this.setBannerBody(banner)
                      }}
                      bannerTitleText={l10n.banner.demoModeTitleTextNew}
                    />
                  )}
                  <div
                    className={cx(
                      classes.contentWrapper,
                      classes[`${type}ContentBodyWrapper`]
                    )}
                  >
                    <Grid item>
                      <Typography
                        className={cx(classes.messageText, classes.text)}
                      >
                        {bodyText}
                      </Typography>
                    </Grid>
                    {!isAdminPage && (
                      <Grid item className={classes.demoBtnCtn}>
                        <Button
                          className={classes.demoBtn}
                          data-testid="banner-button"
                          onClick={banner.close}
                          variant="outlined"
                          size="small"
                        >
                          <Typography className={cx(classes.text)}>
                            {buttonText}
                          </Typography>
                        </Button>
                      </Grid>
                    )}
                  </div>
                </>
              </Drawer>
            </>
          )
        }}
      </Subscribe>
    )
  }
}

export default flow(
  withStyles(styles),
  withRouter
)(
  withUnstatedContainer(BannerUnwrapped, {
    accessModeContainer: AccessModeContainer,
    bannerContainer: BannerContainer
  })
)
