import { pageTitle } from 'themes/default'

export default theme => ({
  dialog: {
    minHeight: 100,
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(2)}px !important`,
      maxWidth: 'calc(100% - 32px) !important'
    }
  },
  dialogScrollPaper: {
    [theme.breakpoints.down('xs')]: {
      margin: `${theme.spacing(1)}px !important`,
      maxHeight: `calc(100% - ${theme.spacing(1)}px)`
    }
  },
  dialogScrollPaperContent: {
    marginBottom: theme.spacing(4),
    '&::-webkit-scrollbar': {
      appearance: 'none',
      width: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '5px',
      backgroundColor: 'rgba(0, 0, 0, 0.25)',
      margin: '12px'
    }
  },

  root: {
    '-webkit-overflow-scrolling': 'touch'
  },
  backdrop: {
    top: '-200px',
    height: '175%'
  },
  row: {
    padding: theme.spacing(1)
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    color: theme.palette.grey[500],
    padding: '12px'
  },
  iconHover: {
    cursor: 'pointer'
  },
  logo: {
    display: 'block',
    margin: '0 auto',
    width: '85px'
  },
  largeModal: {
    maxWidth: '900px'
  },
  bannerTitle: {
    borderBottom: `1px solid ${theme.palette.divider}`
  },
  title: {
    paddingTop: theme.spacing(3)
  },
  fadeOutContent: {
    visibility: 'hidden',
    opacity: '0',
    transition: 'visibility 0s linear 300ms, opacity 300ms'
  },
  pageTitle: {
    fontWeight: pageTitle.fontWeight,
    fontSize: pageTitle.fontSize,
    color: pageTitle.color
  }
})
