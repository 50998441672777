import React from 'react'
import NumberFormat from 'react-number-format'
import { DOLLAR_PREFIX } from 'properties/properties'

const CurrencyNumberFormat = ({ inputRef, displayType = 'text', ...props }) => (
  <NumberFormat
    data-testid="currency-number-format-amount"
    displayType={displayType}
    thousandSeparator
    prefix={DOLLAR_PREFIX}
    decimalScale={2}
    fixedDecimalScale
    {...props}
  />
)

export default CurrencyNumberFormat
