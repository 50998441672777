/* eslint-disable react/jsx-boolean-value */

// @flow
import * as React from 'react'
import { Grid, InputAdornment, NativeSelect } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import l10n from 'properties/translations'
import { employmentStatusList } from 'util/FoundationFormHelper'
import { PhoneFormat, MonthWorkFormat } from '../NumberFormatHelper'

type Props = {
  classes: Object,
  handleFormState: string,
  applicantEmploymentStatus: string,
  applicantEmployerName: string,
  applicantEmployerPhone: number,
  applicantMonthlyIncome: number,
  applicantJobTitle: string,
  periodOfEmploymentMonths: number
}

const WhereYouWork = (props: Props) => {
  const {
    classes,
    handleFormState,
    applicantEmploymentStatus,
    applicantEmployerName,
    applicantEmployerPhone,
    applicantMonthlyIncome,
    applicantJobTitle,
    periodOfEmploymentMonths
  } = props
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            {l10n.foundation.applicationContent.employmentStatusLabel}
            <br />
            <NativeSelect
              fullWidth
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .applicantEmploymentStatus
              }
              value={applicantEmploymentStatus}
              required={true}
              className={classes.nativeSelectDropStyle}
            >
              {employmentStatusList}
            </NativeSelect>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.employerNameStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.employerNameLabel}
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .applicantEmployerName
              }
              value={applicantEmployerName}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .employerNameRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidName
              ]}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.employerPhoneStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={
                l10n.foundation.applicationContent.employerPhoneNumberLabel
              }
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .applicantEmployerPhone
              }
              value={applicantEmployerPhone}
              validators={[
                l10n.foundation.applicationContent.errorMessages
                  .employerPhoneRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.errorMessages.invalidPhone
              ]}
              InputProps={{ inputComponent: PhoneFormat }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={`${classes.gridItemStyle} ${classes.fieldTitleStyle}`}
        >
          <TextValidator
            fullWidth
            onChange={handleFormState}
            name={
              l10n.foundation.applicationContent.fieldNames
                .applicantMonthlyIncome
            }
            value={applicantMonthlyIncome}
            label={l10n.foundation.applicationContent.monthlyIncomeLabel}
            validators={[
              l10n.foundation.applicationContent.errorMessages.required,
              l10n.foundation.applicationContent.errorMessages
                .applicantMonthlyIncomeRegex
            ]}
            errorMessages={[
              l10n.foundation.applicationContent.fieldRequiredLabel,
              l10n.foundation.applicationContent.errorMessages.invalidAmount
            ]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
            type="tel"
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.occupationTitleLabel}
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames.applicantJobTitle
              }
              value={applicantJobTitle}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .applicantJobTitleRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidJobtitle
              ]}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={`${classes.gridItemStyle} ${classes.fieldTitleStyle}`}
        >
          <TextValidator
            fullWidth
            onChange={handleFormState}
            name={
              l10n.foundation.applicationContent.fieldNames
                .periodOfEmploymentMonths
            }
            value={periodOfEmploymentMonths}
            label={
              l10n.foundation.applicationContent.appPeriodOfEmploymentMonths
            }
            validators={[
              l10n.foundation.applicationContent.errorMessages.required,
              l10n.foundation.applicationContent.errorMessages
                .employedMonthsRegex
            ]}
            errorMessages={[
              l10n.foundation.applicationContent.fieldRequiredLabel,
              l10n.foundation.applicationContent.errorMessages.invalidMonth
            ]}
            InputProps={{ inputComponent: MonthWorkFormat }}
            type="tel"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default WhereYouWork
