// @flow
import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

import l10n from 'properties/translations'

import styles from './IframeThirdPartyCookiesErrorMessage.styles'

const Component = ({ classes }) => (
  <Typography classes={classes}>
    {l10n.prequalificationForm.thirdPartyCookiesDisabledError}
  </Typography>
)

export default withStyles(styles)(Component)
