import { colors } from 'themes/default'

export default () => ({
  stageErrorContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start'
  },
  stageErrorMessage: {
    color: colors.errorRed,
    padding: '9px 0px'
  }
})
