// @flow
import React, { useState } from 'react'
import { Grid, Dialog, Typography, Button } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import l10n from 'properties/translations'
import styles from './HandToCustomerDialog.styles'

type Props = {
  isOpen: Boolean,
  history: Object
}

const useStyles = makeStyles(styles)
const HandToCustomerDialog = ({ history, isOpen }: Props) => {
  const [open, setOpen] = useState(isOpen)
  const classes = useStyles()

  return (
    <Dialog
      open={open}
      classes={{ paper: classes.handToCustomerDialog }}
      data-testid="hand-to-customer-dialog"
    >
      <Grid direction="column" container className={classes.handToCustomerGrid}>
        <Typography align="center" className={classes.handToCustomerTitle}>
          {l10n.prequalificationForm.handDeviceToCustomer.title}
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => history.goBack()}
          data-testid="go-back-button"
        >
          <Typography>
            {l10n.prequalificationForm.handDeviceToCustomer.goBack}
          </Typography>
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => setOpen(false)}
          data-testid="continue-button"
        >
          <Typography>
            {l10n.prequalificationForm.handDeviceToCustomer.continue}
          </Typography>
        </Button>
      </Grid>
    </Dialog>
  )
}

export default withRouter(HandToCustomerDialog)
