// @flow

const opportunityStatusesEnum = Object.freeze({
  approved: 'APPROVED',
  new: 'NEW',
  prequalSent: 'PREQUAL_SENT',
  prequalCompleted: 'PREQUAL_COMPLETED',
  creditApproved: 'CREDIT_APPROVED',
  creditDenied: 'CREDIT_DENIED',
  loanAppSent: 'LOAN_APP_SENT',
  loanAppCompleted: 'LOAN_APP_COMPLETED',
  loanAppApproved: 'LOAN_APP_APPROVED',
  loanAppIncomplete: 'LOAN_APP_INCOMPLETE',
  loanAppCountersigned: 'LOAN_APP_COUNTERSIGNED',
  installComplete: 'INSTALL_COMPLETE',
  installCompletePending: 'INSTALL_COMPLETE_PENDING',
  installConfirmed: 'INSTALL_CONFIRMED',
  finalPayment: 'FINAL_PAYMENT',
  finalConfirmation: 'FINAL_CONFIRMATION',
  deleted: 'DELETED',
  inspectionComplete: 'INSPECTION_COMPLETE',
  equipmentSubmitted: 'EQUIPMENT_SUBMITTED',
  equipmentApproved: 'EQUIPMENT_APPROVED'
})

export type OpportunityStatuses = $Values<typeof opportunityStatusesEnum>

export default opportunityStatusesEnum
