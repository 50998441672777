// @flow
import React, { useContext, useState, useMemo, useEffect } from 'react'
import { Query } from '@apollo/client/react/components'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { ApplicationStatus } from '@solarmosaic/apply.screens.application-status'
import { useModel } from '@solarmosaic/data-models.hooks.use-model'
import {
  MonthlyPaymentModel,
  CustomerModel
} from '@solarmosaic/data-models.models.p2x'
import { Processing } from 'legacy-bit/apply.templates.processing-1.8.1'
import cx from 'classnames'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import OptionMenu from 'components/shared/OptionMenu'
import ScreenTitle from 'components/shared/ScreenTitle'
import creditApplicationDecisionStatuses from 'constants/enums/creditApplicationDecisionStatuses'
import creditApplicationStatuses from 'constants/enums/creditApplicationStatuses'
import {
  isIntegrator,
  getCustomerLocale,
  getCustomerName,
  customerLanguageKey
} from 'util/CustomerHelper'
import {
  inIframe,
  clearScreenNameInIframeIfFlagEnabled
} from 'util/EnvironmentHelper'
import emailVerificationSessionStore from 'constants/enums/emailVerificationSessionStore'
import featureFlags from 'constants/enums/featureFlags'
import lenders from 'constants/enums/lenders'
import verificationMutationActions from 'constants/enums/verificationMutationActions'
import { flow, get } from 'lodash'
import {
  AGGREGATOR_CODE,
  POLLING_INTERVAL_APPLICATION_PROCESSING
} from 'properties/properties'
import { Redirect, withRouter } from 'react-router-dom'
import { pageTitle } from 'themes/default'
import {
  showConditionalApprovalMultiple,
  showConditionalApprovalPhone,
  showConditionalApprovalUpload
} from 'util/ApplicationFormStatusHelper'
import {
  coApplicantFormEnabled,
  lastFourSSNFlagEnabled
} from 'util/FeatureHelper'
import { makeSafeSessionStorage } from 'util/makeSafeStorage'
import routes from 'util/Routes'
import TranslationsContext from 'util/TranslationsContext'
import UserContext from 'util/UserContext'
import { hasPartialCreditApplicationPermission } from 'util/UserHelper'
import Foundation from '../../foundation/Foundation'
import GetCreditApplication from '../queries/GetCreditApplication.graphql'
import ApprovedContent from './ApprovedContent'
import ConditionalApprovalMultiple from './ConditionalApprovalMultiple'
import ConditionalApprovalPhone from './ConditionalApprovalPhone'
import ConditionalApprovalUpload from './ConditionalApprovalUpload'
import DecisionEmailedContent from './DecisionEmailedContent'
import StipResolutionConfirmation from './StipResolutionConfirmation'
import ProcessingContent from './ProcessingContent'
import VerifyIdentityContent from './VerifyIdentityContent'
import CreditStipResolution from 'components/apply/CreditStipResolution'
import userRolesEnum from 'constants/enums/userRoles'
import signatorRolesEnum from 'constants/enums/signatorRoles'
import taskTypesEnum from 'constants/enums/taskTypes'
import { defaultFooterProps, loanDefaultWrapperProps } from 'properties/p3x'
import { localizedTranslations } from 'properties/translations'
import userTypesEnum from 'constants/enums/userTypes'

const safeSessionStorage = makeSafeSessionStorage()

const {
  blocked,
  approved,
  declined,
  needsReview,
  notQualified,
  needToVerifySsnDob,
  needFullSsn
} = creditApplicationDecisionStatuses

const styles = theme => ({
  paper: {
    margin: theme.spacing(4),
    padding: theme.spacing(2),
    minHeight: '50vh'
  },
  marginHeader: {
    marginBottom: theme.spacing(4)
  },
  pageTitle: {
    fontWeight: pageTitle.fontWeight,
    fontSize: pageTitle.fontSize,
    color: pageTitle.color
  }
})

type Props = {
  classes: {
    paper: string,
    marginHeader: string,
    pageTitle: string
  },
  creditApplication: {
    status: $Values<typeof creditApplicationDecisionStatuses>,
    financingLimit: number,
    conditionalApprovalLink: string,
    decision: {
      status: $Values<typeof creditApplicationDecisionStatuses>,
      loanProducts: [
        {
          loanProductId: number,
          productName: string
        }
      ]
    }
  },
  quote: {
    loanProductId: number,
    loanAmount: number,
    projects: [
      {
        loanProductId: number,
        loanAmount: number
      }
    ]
  },
  onContinue: () => {},
  onEditOffer: () => {},
  customerId: string,
  lender: string,
  contractStatus: string,
  resetErrorMessage: boolean,
  alternateFinancingEnabled: boolean,
  ldFlags: {
    [featureFlags.foundation]: boolean
  },
  client: Object,
  stopPollingClient: Function,
  isProductLoading: boolean,
  hideMenu: ?boolean,
  isSubmitting: boolean,
  setIsSubmittingContract: Function,
  onSubmitStart: () => void,
  onSubmitComplete: () => void,
  customer: Object
}

const renderNeedsReview = ({
  creditApplication,
  shouldSubmitButtonRender,
  l10n,
  flags
}) => {
  if (showConditionalApprovalMultiple(flags)) {
    return {
      content: (
        <ConditionalApprovalMultiple
          conditionalApprovalLink={creditApplication.conditionalApprovalLink}
          shouldSubmitButtonRender={shouldSubmitButtonRender}
        />
      ),
      title: l10n.apply.formStatus.conditionalApproval.title
    }
  }
  if (showConditionalApprovalUpload(flags)) {
    return {
      content: (
        <ConditionalApprovalUpload
          conditionalApprovalLink={creditApplication.conditionalApprovalLink}
          shouldSubmitButtonRender={shouldSubmitButtonRender}
        />
      ),
      title: l10n.apply.formStatus.conditionalApproval.title
    }
  }
  if (showConditionalApprovalPhone(flags)) {
    return {
      content: <ConditionalApprovalPhone />,
      title: l10n.apply.formStatus.conditionalApproval.title
    }
  }
  // TODO use stip status;
  /* Going to rush this for a bug resolution (AEA-79) but we ought to have content to cover each of the stipulation statuses:
  READY_FOR_REVIEW, AUTOMATICALLY_PASSED, MANUALLY_PASSED, FAILED, PENDING
  */
  return {
    content: <StipResolutionConfirmation />,
    title: l10n.apply.formStatus.stipResolutionConfirmation.title
  }
}

const renderNeedToVerifySsnDob = ({
  customerId,
  l10n,
  ldFlags,
  userHasPartialCreditAppPermission
}) => {
  if (lastFourSSNFlagEnabled(ldFlags) && userHasPartialCreditAppPermission) {
    return {
      content: (
        <Redirect
          to={{
            pathname: routes.verify(customerId),
            state: { fullSsnRequired: true }
          }}
        />
      )
    }
  } else {
    return {
      content: (
        <VerifyIdentityContent
          fullSsnRequired
          customerId={customerId}
          description={l10n.apply.formStatus.verifyIdentity.description}
          mutationToInvoke={verificationMutationActions.validateSsn}
        />
      ),
      title: l10n.apply.formStatus.verifyIdentity.title
    }
  }
}

const renderNeedFullSsn = ({ customerId }) => {
  return {
    content: (
      <Redirect
        to={{
          pathname: routes.verify(customerId),
          state: { fullSsnRequired: true }
        }}
      />
    )
  }
}

const hasStipulationTasks = stipulations => {
  const list = stipulations || []
  return list.some(stip => {
    const tasks = get(stip, 'tasks', [])
    return tasks.some(task => task.taskType === taskTypesEnum.documentsUpload)
  })
}

const ApplicationFormStatus = ({
  creditApplication,
  onContinue,
  onEditOffer,
  onEditCustomerInfo,
  customerId,
  contractStatus = null,
  resetErrorMessage,
  alternateFinancingEnabled,
  ldFlags,
  client,
  stopPollingClient,
  isProductLoading,
  location,
  contract,
  lender,
  me,
  isSubmitting,
  onSubmitStart,
  onSubmitComplete,
  quote = {},
  hideMenu,
  customer
}: Props) => {
  const l10n = useContext(TranslationsContext)
  const [menuItemsDisabled, setMenuItemsDisabled] = useState<boolean>(false)
  const [timedOut, hasTimedOut] = useState<boolean>(false)

  useEffect(function handleTimedOut() {
    const timeoutId = setTimeout(() => {
      hasTimedOut(true)
    }, POLLING_INTERVAL_APPLICATION_PROCESSING)

    return () => clearTimeout(timeoutId)
  }, [])

  const stipTasks = useMemo(
    () => get(customer, 'creditApplication.decision.stipulations'),
    [customer]
  )
  const hasStipTasks = useMemo(
    () => hasStipulationTasks(stipTasks),
    [stipTasks]
  )

  const useStyles = makeStyles(styles)
  const classes = useStyles()

  const getStatus = userHasPartialCreditAppPermission => {
    if (get(creditApplication, 'status') !== creditApplicationStatuses.ready) {
      return {
        content: <ProcessingContent stopPollingClient={stopPollingClient} />,
        title: l10n.apply.formStatus.processing.title
      }
    }
    // Stop polling if ready
    try {
      if (stopPollingClient) stopPollingClient()
    } catch (_) {
      // noop
    }

    const decisionStatus = get(creditApplication, 'decision.status')
    const flags = get(creditApplication, 'decision.flags', [])

    // Check if submit button should render in conditional approval
    const weblinksConditionalApprovalFlag = get(
      ldFlags,
      featureFlags.weblinksConditionalApproval
    )
    const aggregatorsConditionalApprovalList = get(
      ldFlags,
      featureFlags.conditionalApprovalForAggregators
    )
    const searchParams = new URLSearchParams(location.search)
    const aggregatorCode = searchParams.get(AGGREGATOR_CODE)
    const isAggregatorCodeInTheList = () =>
      aggregatorsConditionalApprovalList.some(item => item === aggregatorCode)
    const shouldSubmitButtonRender = isPublicApplication =>
      isPublicApplication
        ? !isAggregatorCodeInTheList() && weblinksConditionalApprovalFlag
        : lender !== lenders.WEBBANK

    // Check if email verification is being done before agreement has been signed
    // if so, check the screen name , if qualified screen the render it
    if (
      safeSessionStorage.getItem(emailVerificationSessionStore.CONTINUE_APP) ===
      emailVerificationSessionStore.CONTINUE_APP_VALUE
    ) {
      safeSessionStorage.removeItem(emailVerificationSessionStore.SCREEN_NAME)
    }
    if (
      safeSessionStorage.getItem(emailVerificationSessionStore.SCREEN_NAME) ===
      emailVerificationSessionStore.SCREEN_NAME_VALUES.QUALIFIED_SCREEN
    ) {
      // AEA-72
      clearScreenNameInIframeIfFlagEnabled(ldFlags, safeSessionStorage)
      if (
        safeSessionStorage.getItem(
          emailVerificationSessionStore.EMAIL_VERIFICATION
        ) === emailVerificationSessionStore.EMAIL_VERIFICATION_VALUE
      ) {
        return {
          content: (
            <ApprovedContent
              onViewOffer={onContinue}
              onEditOffer={onEditOffer}
              onEditCustomerInfo={onEditCustomerInfo}
              onSubmitStart={() => {
                toggleMenuItemsDisabled()
                onSubmitStart()
              }}
              onSubmitComplete={() => {
                toggleMenuItemsDisabled()
                onSubmitComplete()
              }}
              creditApplication={creditApplication}
              quote={quote}
              lender={lender}
              customerId={customerId}
              contractStatus={contractStatus}
              resetErrorMessage={resetErrorMessage}
              isProductLoading={isProductLoading}
              contract={contract}
            />
          ),
          showMenu: true,
          title: l10n.apply.formStatus.approved.title
        }
      }
    }

    const needsReviewProps = {
      creditApplication,
      shouldSubmitButtonRender,
      l10n,
      flags
    }

    const needToVerifySsnDobProps = {
      customerId,
      l10n,
      ldFlags,
      userHasPartialCreditAppPermission
    }

    const needFullSsnProps = {
      customerId
    }

    switch (decisionStatus) {
      case needsReview:
        return renderNeedsReview(needsReviewProps)
      case needToVerifySsnDob:
        return renderNeedToVerifySsnDob(needToVerifySsnDobProps)
      case needFullSsn:
        return renderNeedFullSsn(needFullSsnProps)
      case approved:
        if (
          !clearScreenNameInIframeIfFlagEnabled(ldFlags, safeSessionStorage)
        ) {
          safeSessionStorage.setItem(
            emailVerificationSessionStore.SCREEN_NAME,
            emailVerificationSessionStore.SCREEN_NAME_VALUES.QUALIFIED_SCREEN
          )
        }

        if (
          safeSessionStorage.getItem(
            emailVerificationSessionStore.CONTINUE_APP
          ) === emailVerificationSessionStore.CONTINUE_APP_VALUE
        ) {
          safeSessionStorage.removeItem(
            emailVerificationSessionStore.SCREEN_NAME
          )
        }
        if (
          safeSessionStorage.getItem(
            emailVerificationSessionStore.EMAIL_VERIFICATION
          )
        ) {
          safeSessionStorage.removeItem(
            emailVerificationSessionStore.EMAIL_VERIFICATION
          )
        }
        return {
          content: (
            <ApprovedContent
              onViewOffer={onContinue}
              onEditOffer={onEditOffer}
              onEditCustomerInfo={onEditCustomerInfo}
              onSubmitStart={() => {
                toggleMenuItemsDisabled()
                onSubmitStart()
              }}
              onSubmitComplete={() => {
                toggleMenuItemsDisabled()
                onSubmitComplete()
              }}
              creditApplication={creditApplication}
              quote={quote}
              lender={lender}
              customerId={customerId}
              contractStatus={contractStatus}
              resetErrorMessage={resetErrorMessage}
              isProductLoading={isProductLoading}
              contract={contract}
              me={me}
              isSubmitting={isSubmitting}
            />
          ),
          showMenu: true,
          title: l10n.apply.formStatus.approved.title
        }
      case notQualified:
      case declined:
        if (alternateFinancingEnabled && ldFlags[featureFlags.foundation]) {
          return {
            content: <Foundation client={client} customerId={customerId} />
          }
        }
        break
      case blocked:
      default:
        return {
          content: (
            <DecisionEmailedContent client={client} customerId={customerId} />
          ),
          title: l10n.apply.formStatus.complete.title
        }
    }
    return {
      content: (
        <DecisionEmailedContent client={client} customerId={customerId} />
      ),
      title: l10n.apply.formStatus.complete.title
    }
  }

  const toggleMenuItemsDisabled = () => {
    setMenuItemsDisabled(!menuItemsDisabled)
  }

  const decisionStatus = get(creditApplication, 'decision.status')
  const dataHandlers = {
    monthlyPayment: useModel(MonthlyPaymentModel, null, {
      apolloClient: client
    }),
    customer: useModel(CustomerModel, null, {
      apolloClient: client
    })
  }

  return !coApplicantFormEnabled(ldFlags, { customer }) ? (
    <UserContext.Consumer>
      {userContext => {
        const permissions = get(userContext, 'me.permissions', [])

        const userHasPartialCreditAppPermission =
          hasPartialCreditApplicationPermission(permissions)

        const lastFourSSNEnabled =
          lastFourSSNFlagEnabled(ldFlags) && userHasPartialCreditAppPermission

        const {
          content,
          title,
          description = null
        } = getStatus(userHasPartialCreditAppPermission)

        return (
          <>
            <ScreenTitle title={l10n.screenTitles.applicationFormStatus} />
            <Grid
              container
              justify="space-between"
              alignItems="flex-start"
              className={cx({ [classes.marginHeader]: !lastFourSSNEnabled })}
            >
              <Grid item>
                <Typography
                  variant="h1"
                  className={classes.pageTitle}
                  data-testid="screen-title"
                  gutterBottom={lastFourSSNEnabled}
                >
                  {title}
                </Typography>
                {lastFourSSNEnabled && description && (
                  <Typography variant="body2" paragraph>
                    {description}
                  </Typography>
                )}
              </Grid>
              {!hideMenu && (
                <Query
                  query={GetCreditApplication}
                  variables={{ id: customerId }}
                >
                  {({ data, error, loading }) => {
                    if (loading || error) return null
                    const { customer } = data
                    return (
                      <OptionMenu
                        customer={customer}
                        hasContract={false}
                        onEditOffer={onEditOffer}
                        disabled={menuItemsDisabled}
                      />
                    )
                  }}
                </Query>
              )}
            </Grid>

            {content}
          </>
        )
      }}
    </UserContext.Consumer>
  ) : (
    <UserContext.Consumer>
      {userContext => {
        const installerName = get(userContext, 'channelPartner.name')
        const l10n = localizedTranslations(customerLanguageKey(customer))

        if (
          !timedOut &&
          get(creditApplication, 'status') !== creditApplicationStatuses.ready
        ) {
          return <Processing text={l10n.apply.formStatus.processing.title} />
        }
        // Stop polling if ready
        try {
          if (stopPollingClient) stopPollingClient()
        } catch (_) {
          // noop
        }

        const locale = getCustomerLocale(customer)

        const isIntegratorIframe = inIframe() && isIntegrator(me)
        if (
          decisionStatus === needsReview &&
          !isIntegratorIframe &&
          hasStipTasks
        ) {
          return <CreditStipResolution customer={customer} me={me} />
        }

        const { userRoles } = me
        const isSecondaryApplicant =
          userRoles && userRoles.includes(userRolesEnum.borrower.secondary)
        let signatorRole = signatorRolesEnum.PRIMARY_APPLICANT
        if (isSecondaryApplicant) {
          signatorRole = signatorRolesEnum.SECONDARY_APPLICANT
        }

        if (decisionStatus === needFullSsn) {
          return (
            <Redirect
              to={{
                pathname: routes.verifyIdentity(customerId),
                state: {
                  signatorRole,
                  locale
                }
              }}
            />
          )
        }

        if (decisionStatus === needToVerifySsnDob) {
          return (
            <Redirect
              to={{
                pathname: routes.verifyCoApp(customerId),
                state: {
                  fullSsnRequired: true,
                  signatorRole,
                  customerName: getCustomerName(customer),
                  dobEnabled: decisionStatus === needToVerifySsnDob,
                  locale
                }
              }}
            />
          )
        }

        return (
          <ApplicationStatus
            decisionStatus={decisionStatus}
            customerId={customerId}
            dataHandlers={dataHandlers}
            installerName={installerName}
            processingTimeoutMS={POLLING_INTERVAL_APPLICATION_PROCESSING}
            wrapperProps={{
              ...loanDefaultWrapperProps(),
              footerProps: defaultFooterProps(userTypesEnum.borrower, customer),
              locale
            }}
          />
        )
      }}
    </UserContext.Consumer>
  )
}

export default flow(
  withLaunchDarkly,
  withRouter,
  withApolloConsumer
)(ApplicationFormStatus)
