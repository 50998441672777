import { useState, useEffect } from 'react'
import useLocalStorage from 'use-local-storage'

const prefix = 'UseFileHook-'

const useFile = (path, allowCaching = true) => {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [text, setText] = useState(null)
  const [cachedText, setCachedText] = useLocalStorage(`${prefix}${path}`, '')

  useEffect(() => {
    const initialize = async () => {
      setLoading(true)
      setError(null)
      setText(null)
      try {
        if (path) {
          if (cachedText) {
            setText(cachedText)
          } else {
            const result = await fetch(path)
            const text = await result.text()
            setText(text)
            if (allowCaching) {
              setCachedText(text)
            }
          }
        }
      } catch (err) {
        setError(err)
      } finally {
        setLoading(false)
      }
    }
    initialize()
  }, [path, allowCaching, cachedText, setCachedText])
  return { text, loading, error }
}

export default useFile
