import { gql } from 'graphql.macro'
import LoanProductResponseFields from 'queries/fragments/LoanProductResponseFields.graphql'
import SampleTermsResponseFields from 'queries/fragments/SampleTermsResponseFields.graphql'

export default gql`
  query getMonthlyPayment($customerId: String!, $loanAmount: BigDecimal!) {
    customer(customerId: $customerId) {
      id
      creditApplication {
        decision {
          qualifiedProducts {
            ...LoanProductResponseFields
            sampleTerms(loanAmount: $loanAmount) {
              ...SampleTermsResponseFields
            }
          }
        }
      }
    }
  }
  ${LoanProductResponseFields}
  ${SampleTermsResponseFields}
`
