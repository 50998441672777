import React, { useEffect, useState } from 'react'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import featureFlags from 'constants/enums/featureFlags'
import { displayReCaptcha } from 'util/ReCaptchaHelper'

const ReCaptcha = ({ ldFlags, action, setToken }) => {
  const { reCaptchaSiteKeys } = featureFlags
  const [recaptchaLoaded, setRecaptchaLoaded] = useState(false)

  useEffect(() => {
    const handleRecaptchaLoaded = () => {
      window.grecaptcha.ready(() => {
        window.grecaptcha
          .execute(ldFlags[reCaptchaSiteKeys], {
            action
          })
          .then(token => {
            setToken(token)
          })
      })
    }
    if (!recaptchaLoaded) {
      displayReCaptcha(handleRecaptchaLoaded, ldFlags[reCaptchaSiteKeys])
      setRecaptchaLoaded(true)
    }
  }, [action, ldFlags, reCaptchaSiteKeys, setToken, recaptchaLoaded])

  return (
    <div
      className="g-recaptcha"
      data-sitekey={ldFlags[reCaptchaSiteKeys]}
      data-size="invisible"
    />
  )
}

export default withLaunchDarkly(ReCaptcha)
