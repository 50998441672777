import React from 'react'
import { FormHelperText, ButtonBase } from '@material-ui/core'
import l10n from 'properties/translations'

const AlternatePhoneNumber = ({ handleClick, classes }) => (
  <FormHelperText>
    <ButtonBase
      onClick={handleClick}
      classes={{ root: classes.captionButton }}
      tabIndex={-1}
    >
      {l10n.customer.phoneNumberAlternateHelpText}
    </ButtonBase>
  </FormHelperText>
)

export default AlternatePhoneNumber
