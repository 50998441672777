// @flow
import get from 'lodash/get'
import Routes from 'util/Routes'
import GetContractEsignUrl from 'screens/apply/queries/GetContractEsignUrl.graphql'
import GetContractApplicantEsignUrl from 'screens/apply/queries/GetContractApplicantEsignUrl.graphql'
import contractStatuses from 'constants/enums/contractStatuses'
import SignContract from 'screens/apply/queries/SignContract.graphql'
import GetCustomerStatuses from 'queries/GetCustomerStatus.graphql'
import { throttledQueryLoad } from './AsyncHelper'
import {
  integratorDocusignFlagEnabled,
  coApplicantFormEnabled
} from './FeatureHelper'
import { parseQuery } from './NavigationHelper'
import signatorRolesEnum from 'constants/enums/signatorRoles'

const LEADING_QUESTION_MARK = /^\?/

export const eSignCallbackUrl = (
  customerId,
  originalSource,
  ldFlags,
  isIntegrator,
  signatorRole
) => {
  if (isIntegrator && integratorDocusignFlagEnabled(ldFlags)) {
    return Routes.buildAbsoluteUrl(
      Routes.returnFromContractGuest(customerId, signatorRole)
    )
  } else {
    return Routes.buildAbsoluteUrl(
      Routes.returnFromContract(customerId, originalSource, signatorRole)
    )
  }
}

const extractEsignUrlFromResponse = (data) =>
  get(data, 'customer.contract.eSignUrl')

const extractEsignUrlFromApplicantResponse = (data) =>
  get(data, 'customer.contract.signator.eSignUrl')

export const getContractEsignUrl = async (
  customer,
  sourceRoute,
  client,
  ldFlags,
  isIntegrator = false,
  options = { throttleMs: undefined },
  signatorRole = undefined
) => {
  const { id } = customer
  const coAppFlagOn = coApplicantFormEnabled(ldFlags, { customer })
  const gqlQuery = coAppFlagOn
    ? GetContractApplicantEsignUrl
    : GetContractEsignUrl
  const query = {
    query: gqlQuery,
    variables: {
      id,
      eSignCallbackUrl: eSignCallbackUrl(
        id,
        sourceRoute,
        ldFlags,
        isIntegrator,
        signatorRole
      ),
      signatoryRole: signatorRole || signatorRolesEnum.PRIMARY_APPLICANT
    }
  }
  const data = await throttledQueryLoad(
    options.throttleMs ? options.throttleMs : 0,
    client,
    query
  )

  return coAppFlagOn
    ? extractEsignUrlFromApplicantResponse(data)
    : extractEsignUrlFromResponse(data)
}

export const viewAgreement = (eSignUrl, options = {}) => {
  if (options.newWindow) {
    window.open(eSignUrl, '_blank')
  } else {
    window.location = eSignUrl
  }
}

export const trimLeadingQuestionMark = url =>
  url.replace(LEADING_QUESTION_MARK, '')

export const getQueryString = (location, param) => {
  const search = get(location, 'search', '')
  const parsedQuery = parseQuery(trimLeadingQuestionMark(search))
  return get(parsedQuery, param)
}

export const getQueryStringFrom = (location, param) => {
  const search = get(location, 'state.from.search', '')
  const parsedQuery = parseQuery(trimLeadingQuestionMark(search))
  return get(parsedQuery, param)
}

export const getDocuSignQueryStringEvent = history => {
  const search = get(history, 'location.search', '')
  const parsedQuery = parseQuery(trimLeadingQuestionMark(search))
  return get(parsedQuery, 'event')
}

export const getQueryStringSourceRoute = history => {
  const search = get(history, 'location.search', '')
  const parsedQuery = parseQuery(trimLeadingQuestionMark(search))

  return decodeURIComponent(get(parsedQuery, 'sourceRoute'))
}

export const getQueryStringSignatorRole = history => {
  const search = get(history, 'location.search', '')
  const parsedQuery = parseQuery(trimLeadingQuestionMark(search))
  return get(parsedQuery, 'signatorRole')
}

export const framebustIfNeededAndGoTo = (newLocation, history) => {
  if (window && window.parent) {
    window.parent.location = newLocation
  } else {
    history.replace(newLocation)
  }
}

export const signContract = (customer, client, signatorRole) => {
  const dateSigned = get(customer, 'contract.signator.dateSigned')
  const signed = Boolean(dateSigned)

  if (signed && signatorRole === signatorRolesEnum.PRIMARY_APPLICANT) return

  const id = get(customer, 'id')
  client.mutate({
    mutation: SignContract,
    variables: {
      id,
      signatorRole
    },
    refetchQueries: [
      {
        query: GetCustomerStatuses,
        variables: { customerId: id }
      }
    ]
  })
}

export const contractExists = contract => {
  const contractStatus = get(contract, 'status')
  return !!contract && contractStatus !== contractStatuses.cancelled
}
