// @flow
/* eslint react/prop-types: 0 */

import React, { useContext } from 'react'
import { get } from 'lodash'
import NumberFormat from 'react-number-format'
import { FormHelperText } from '@material-ui/core'
import TextValidator from 'components/shared/TextValidator'
import TranslationsContext from 'util/TranslationsContext'
import { hasPartialCreditApplicationPermission } from 'util/UserHelper'
import placeholders from 'properties/placeholders'
import fieldMasks from 'constants/enums/fieldMasks'
import MobileInfo from 'util/MobileInfo'
import {
  FULL_SSN_VALIDATORS,
  LAST_4_SSN_VALIDATORS,
  SSN_INPUT_PATTERN
} from 'properties/properties'

import customerFields from 'constants/enums/customerFields'
import { lastFourSSNFlagEnabled } from 'util/FeatureHelper'
import UserContext from 'util/UserContext'
import withLaunchDarkly from './LaunchDarklyHOC'

/* Values to display for customer in demo mode */
const DEMO_MODE_DISPLAY_VALUES = {
  fullSsn: '000-00-0000',
  partialSsn: '0000'
}

type Props = {
  classes: classesShape,
  demoMode: boolean,
  getErrorMessagesRequired: (fieldName: string) => string[],
  getValidatorsRequired: (fieldName: string) => string[],
  isRequiredField: (fieldName: string) => boolean,
  labelIsRequired: () => boolean,
  onFieldEdit: func,
  disabled?: boolean,
  singleDobField?: boolean,
  placeholder: string,
  ldFlags: Object
}

const SsnField = (props: Props) => {
  const {
    classes,
    demoMode,
    getErrorMessagesRequired,
    getValidatorsRequired,
    isRequiredField,
    labelIsRequired,
    onFieldEdit,
    disabled = false,
    placeholder = undefined,
    singleDobField,
    ldFlags
  } = props

  const l10n = useContext(TranslationsContext)
  const userContext = useContext(UserContext)
  const lastFourSsnFlagEnabled = lastFourSSNFlagEnabled(ldFlags)
  const userPermissions = get(userContext, 'me.permissions', [])
  const partnerPermissions = get(userContext, 'channelPartner.flags', [])
  const userHasPartialCreditAppPermission =
    hasPartialCreditApplicationPermission(userPermissions)
  const partnerHasPartialCreditAppPermission =
    hasPartialCreditApplicationPermission(partnerPermissions)

  const hasPartialCreditAppPermission =
    userHasPartialCreditAppPermission || partnerHasPartialCreditAppPermission

  const lastFourSsnEnabled =
    lastFourSsnFlagEnabled && hasPartialCreditAppPermission

  const fieldMask = lastFourSsnEnabled ? fieldMasks.last4Ssn : fieldMasks.ssn
  const baseValidators = [...getValidatorsRequired(customerFields.ssn)]
  const lenghtValidators = [
    ...(lastFourSsnEnabled ? LAST_4_SSN_VALIDATORS : FULL_SSN_VALIDATORS)
  ]

  const label = lastFourSsnEnabled
    ? l10n.customer.lastFourOfSsn
    : l10n.customer.ssn

  const customPlaceholder = lastFourSsnEnabled
    ? placeholders.last4Ssn
    : placeholders.ssn

  const helperText = lastFourSsnEnabled
    ? l10n.customer.fullSsnRequiredLater
    : l10n.customer.ssnHelpText

  return (
    <>
      {demoMode ? (
        <TextValidator
          disabled
          fullWidth
          id={customerFields.ssn}
          label={label}
          name={customerFields.ssn}
          value={
            lastFourSsnEnabled
              ? DEMO_MODE_DISPLAY_VALUES.partialSsn
              : DEMO_MODE_DISPLAY_VALUES.fullSsn
          }
        />
      ) : (
        <NumberFormat
          customInput={TextValidator}
          format={fieldMask}
          mask=""
          id={customerFields.ssn}
          fullWidth
          type="tel"
          label={label}
          onChange={onFieldEdit(customerFields.ssn)}
          name={customerFields.ssn}
          placeholder={placeholder || customPlaceholder}
          validators={[...baseValidators, ...lenghtValidators]}
          errorMessages={[
            ...getErrorMessagesRequired(customerFields.ssn, l10n),
            l10n.customer.ssnErrorMessage
          ]}
          required={isRequiredField(customerFields.ssn)}
          InputLabelProps={{
            required: labelIsRequired(),
            shrink: singleDobField ? true : undefined
          }}
          inputProps={{
            autoComplete: 'off',
            pattern: SSN_INPUT_PATTERN,
            inputMode: 'number',
            'data-testid': 'ssn',
            className: MobileInfo.isWebkitMobile()
              ? classes.sensitiveInfoInputWebkit
              : classes.sensitiveInfoInput
          }}
          disabled={disabled}
        />
      )}
      <FormHelperText data-testid="ssn-helper-text">
        {helperText}
      </FormHelperText>
    </>
  )
}

export default withLaunchDarkly(SsnField)
