// @flow

import cx from 'classnames'
import { Grid, Typography } from '@material-ui/core'
import React, { useContext } from 'react'
import TranslationsContext from 'util/TranslationsContext'
import DeviceVerificationDisclosures from './DeviceVerificationDisclosures'

type Props = {
  classes: {
    disclosureContainer: string,
    disclosureLink: string,
    disclosureText: string,
    formFieldLabel: string,
    labelText: string
  },
  classOverrides: ?{
    disclosureContainer: string,
    disclosureLink: string,
    disclosureText: string,
    formFieldLabel: string,
    labelText: string
  },
  xs?: number
}

const Disclosures = (props: Props) => {
  const { classes, xs, classOverrides = {} } = props
  const l10n = useContext(TranslationsContext)

  return (
    <Grid item xs={xs} data-testid="sms-disclosures">
      <Typography
        variant="caption"
        gutterBottom
        className={cx(
          classOverrides.formFieldLabel || classes.formFieldLabel,
          classOverrides.labelText || classes.labelText
        )}
        data-testid="sms-disclosures-title"
      >
        {l10n.apply.disclosures}
      </Typography>
      <DeviceVerificationDisclosures
        classes={{
          container:
            classOverrides.disclosureContainer || classes.disclosureContainer,
          text: classOverrides.discloseText || classes.discloseText,
          link: classOverrides.disclosureLink || classes.discloseLink
        }}
      />
    </Grid>
  )
}

Disclosures.defaultProps = {
  xs: 12
}

export default Disclosures
