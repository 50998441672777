export default theme => ({
  landlineWarning: {
    fontSize: theme.spacing(1.5),
    fontWeight: 400,
    lineHeight: 1.66,
    fontFamily: theme.typography.fontFamily,
    verticalAlign: 'baseline',
    textDecoration: 'none'
  },
  captionButton: {
    fontSize: 'inherit',
    color: theme.palette.action.link,
    position: 'relative',
    verticalAlign: 'baseline',
    display: 'inline-block'
  }
})
