export default theme => ({
  root: {
    backgroundColor: theme.palette.common.white,
    borderRadius: '50%',
    border: '1px solid #ccc',
    width: theme.spacing(5),
    height: theme.spacing(5),
    padding: theme.spacing(1.5),
    top: `-${theme.spacing(2.5)}px`,
    left: `-${theme.spacing(0.625)}px`,
    position: 'absolute',
    '&:hover': {
      backgroundColor: theme.palette.common.white
    },
    '& .MuiSvgIcon-root': {
      fontSize: '1rem'
    },
    [theme.breakpoints.up('md')]: {
      top: `-${theme.spacing(2.5)}px`,
      left: `-${theme.spacing(2.5)}px`
    }
  }
})
