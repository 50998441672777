import React from 'react'
import NumberFormat from 'react-number-format'
import fieldMasks from 'constants/enums/fieldMasks'

const numberFormatCreator =
  (format, mask = '_') =>
  props => {
    const { onChange, inputRef, ...others } = props
    return (
      <NumberFormat
        {...others}
        getInputRef={inputRef}
        format={format}
        mask={mask}
        onValueChange={values => {
          onChange({
            target: {
              value: values.value,
              name: others.name
            }
          })
        }}
        type="tel"
      />
    )
  }

export const PhoneFormat = numberFormatCreator(fieldMasks.phoneNumber)
export const MonthWorkFormat = numberFormatCreator(fieldMasks.monthWork, '')
export const SsnFormat = numberFormatCreator(fieldMasks.ssn, '')
export const DOBFormat = numberFormatCreator(fieldMasks.dateOfBirth)

export default numberFormatCreator
