// @flow
import React from 'react'
import cx from 'classnames'
import { Grid, Typography } from '@material-ui/core'
import { type AddressShape } from 'screens/customer/shapes/address'
import { EMPTY_STRING } from 'properties/properties'

type Props = {
  title: string,
  address: AddressShape,
  classes: {
    singleResult: string
  },
  singleResult: boolean
}

const formatAddress = (address: AddressShape) => {
  const { street, city, stateAbbreviation, zip } = address
  if (!street || !city || !stateAbbreviation || !zip) {
    return EMPTY_STRING
  }

  return `${street} ${city}, ${stateAbbreviation} ${zip}`
}

const Address = ({ title, address, classes, singleResult }: Props) => (
  <Grid
    direction="column"
    container
    className={cx({ [classes.singleResult]: singleResult })}
  >
    <Typography variant="body1">{title}</Typography>
    <Typography variant="body2">{formatAddress(address)}</Typography>
  </Grid>
)

Address.defaultProps = {
  singleResult: false
}

export default Address
