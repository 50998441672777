import React from 'react'
import { TextField } from '@material-ui/core'
import { ValidatorComponent } from 'react-material-ui-form-validator'

class TextValidator extends ValidatorComponent {
  render() {
    /* eslint-disable no-unused-vars */
    const {
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      showError = true,
      validatorListener,
      withRequiredValidator,
      ...rest
    } = this.props

    const { isValid } = this.state

    if (showError) {
      return (
        <TextField
          {...rest}
          error={!isValid || error}
          helperText={!isValid ? this.getErrorMessage() : helperText}
        />
      )
    }

    return <TextField {...rest} />
  }
}

export default TextValidator
