import formValidatorValidators from 'constants/enums/formValidatorValidators'
import errorTypes from 'constants/enums/errorTypes'
import errorKeys from 'constants/enums/errorKeys'
import backendEnvironments from 'constants/enums/backendEnvironments'
import { isDevelopmentEnv } from 'util/EnvironmentHelper'
import opportunityStatuses, {
  type OpportunityStatuses
} from 'constants/enums/opportunityStatuses'
import creditApplicationDecisionStatuses from 'constants/enums/creditApplicationDecisionStatuses'
import customerLoanTypes from 'constants/enums/customerLoanTypes'
import languageKeys from 'constants/enums/languageKeys'

export const DEBOUNCE_TIME = 1000
export const MESSAGE_TIMEOUT = 1000
export const POLLING_INTERVAL = 3000
export const POLLING_INTERVAL_10SEC = 10000
export const POLLING_INTERVAL_APPLICATION_PROCESSING = 30000
export const NEW_CONTENT_TIMEOUT = 60000
export const GENERIC_ERROR_MESSAGE_TIMEOUT = 3000
export const PROJECT_COST_CHANGE_DEBOUNCE_TIME = 300
export const SHORT_TIMEOUT = 300
export const MSGSENT_TIMEOUT = 600
export const DIALOG_TIMEOUT = 2000
export const ZERO_TIMEOUT = 0
export const SECONDS_IN_DAY = 86400
export const MILLI_SECOND = 1000
export const COPIED_TIMEOUT_MS = 2000
export const SORTING_EXPIRE_TIME = 10
export const ERROR_REGEX_STR = /\((.+)\)/
export const PHONE_NUMBER_VALIDATORS = [
  'matchRegexp:^\\([0-9]{3}\\) [0-9]{3}-[0-9]{4}$',
  'minStringLength:10'
]
export const COMPOUND_NAME_REGEX_STR = '^((?! and )(?!&).)*$'
export const COMPOUND_NAME_VALIDATORS = [
  `${formValidatorValidators.matchRegexp}:${COMPOUND_NAME_REGEX_STR}`
]
export const ZIP_REGEX_STR = '^$|^\\d{5}$'
export const ZIP_VALIDATORS = [
  `${formValidatorValidators.matchRegexp}:${ZIP_REGEX_STR}`
]
export const ROUTING_NUMBER_REGEX_STR = '^$|^\\d{9}$'
export const ROUTING_NUMBER_VALIDATORS = [
  `${formValidatorValidators.matchRegexp}:${ROUTING_NUMBER_REGEX_STR}`
]
export const FULL_SSN_REGEX_STR = '^(\\d{9})$'
export const FULL_SSN_VALIDATORS = [
  `${formValidatorValidators.matchRegexp}:${FULL_SSN_REGEX_STR}`,
  `${formValidatorValidators.maxStringLength}:9`
]
export const LAST_4_SSN_REGEX_STR = '^(\\d{4})$'
export const LAST_4_SSN_VALIDATORS = [
  `${formValidatorValidators.matchRegexp}:${LAST_4_SSN_REGEX_STR}`,
  `${formValidatorValidators.maxStringLength}:4`
]
export const SSN_INPUT_PATTERN = '[0-9]*'
export const STREET_REGEX_STR = '^(?![0-9 ]+$).*$'
export const STREET_VALIDATORS = [
  `${formValidatorValidators.matchRegexp}:${STREET_REGEX_STR}`,
  `${formValidatorValidators.maxStringLength}:50`
]

export const NAME_LENGTH = [`${formValidatorValidators.minStringLength}:2`]
export const LASTNAME_EACH_WORD_LENGTH = 2
export const WHITE_SPACE = ' '

export const MAX_DOB_YEAR = new Date().getFullYear()
export const DOB_DAY_VALIDATORS = [
  `${formValidatorValidators.minNumber}:1`,
  `${formValidatorValidators.maxNumber}:31`
]
export const DOB_YEAR_VALIDATORS = [
  `${formValidatorValidators.maxNumber}:${MAX_DOB_YEAR}`
]
export const DOB_FULL_VALIDATORS = [
  formValidatorValidators.noBlankSpaces,
  formValidatorValidators.isValidCalendarDate
]
export const NO_SPECIAL_CHARACTERS_VALIDATORS = [
  `${formValidatorValidators.matchRegexp}:^[A-Za-z' -]+$`
]
export const CURRENCY_TO_NUMBER = /[^0-9.]+/g
export const ONLY_NUMBERS_VALIDATOR = 'matchRegexp:^\\d+$'
export const NON_DIGIT_REGEX = /\D/g
export const COLON_SEPARATOR = ':'
export const EMPTY_STRING = ''
export const EMPTY_ARRAY = []
export const EMPTY_OBJECT = {}
export const COMMA_SEPARATOR = ','
export const DOT_SEPARATOR = '.'
export const COMMA_SPACE_SEPARATOR = ', '
export const SPACE_SEPARATOR = ' '
export const PLUS_SYMBOL = '+'
export const ZERO = 0
export const PHONE_NUMBER_LENGTH = 10

export const MINIMUM_LOAN_AMOUNT = 2500
export const MAXIMUM_LOAN_AMOUNT = 55000
export const MAXIMUM_PROJECT_COST = 99999
export const MINIMUM_PROJECT_COST = 0
export const BUTTON_MIN_WIDTH = 132

export const DOLLAR_PREFIX = '$'
export const DOLLAR_DECIMAL_SCALE = 2
export const INCOME_ALERT_HIGH = 999999
export const INCOME_ALERT_LOW = 10000
export const isEmailValid = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

export const isPhoneNumberValid = phoneNumber =>
  `${phoneNumber}`.length === PHONE_NUMBER_LENGTH

export const reviewSummaryProperties = {
  dollarNumberFormat: {
    prefix: DOLLAR_PREFIX,
    thousandSeparator: true,
    decimalScale: DOLLAR_DECIMAL_SCALE,
    fixedDecimalScale: true
  },
  spacing: 0,
  percentageNumberFormat: {
    suffix: '%',
    decimalScale: DOLLAR_DECIMAL_SCALE,
    fixedDecimalScale: true
  },
  firstPaymentFormatConstant: 'PP',
  minimumViewAgreementMs: 300
}

const currencyFormatCommon = {
  currency: 'USD',
  style: 'currency'
}

export const currencyFormatConfig = {
  ...currencyFormatCommon,
  minimumFractionDigits: 2
}

export const currencyWholeNumberFormatConfig = {
  ...currencyFormatCommon,
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}

export const COUNT_UP_DURATION = 1
export const COUNT_UP_DURATION_ZERO = 0.00001 // https://github.com/glennreyes/react-countup/issues/116

export const forgotPasswordLink =
  'https://login.financing.joinmosaic.com/sign-in/reset-password'

export const applyNowLink = 'https://www.joinmosaic.com/contractors/'

export const MOBILE_DETECT_OS = {
  android: 'AndroidOS',
  ios: 'iOS'
}

export const DEFAULT_DEFERMENT_PERIODS = 3

export const QUERY_FAIL_RESET_MS = 12000
export const THROTTLE_CHANGE_MS = 1000
export const DEBOUNCE_SEARCH_MS = 1000

// TODO: This const URL is down, is not used anywhere, and should not be relied on as an offline check - consider removal
export const OFFLINE_CHECK_URL =
  'https://skjeh430f4.execute-api.us-east-1.amazonaws.com/v0/ping'

export const CIRCULAR_PROGRESS_SIZE = 20
export const CIRCULAR_PROGRESS_WITH_LABEL_SIZE = 40
export const LINEAR_PROGRESS_WITH_LABEL_SIZE = 40

export const typenames = {
  CustomerResponse: 'CustomerResponse',
  Project: 'project',
  Stage: 'quote_project_stage',
  Quote: 'quote',
  QuoteStageResponse: 'QuoteStageResponse',
  StageResponse: 'StageResponse',
  AddOn: 'addOn'
}

export const handledErrorTypes = [
  errorTypes.phoneNumberOptedOut,
  errorTypes.phoneNumberOptedOutException,
  errorTypes.phoneNumberIsLandline,
  errorTypes.phoneNumberIsInvalid,
  errorTypes.challengedRequired,
  errorTypes.productLimit,
  errorTypes.emailVerificationError,
  errorTypes.noLoanProducts,
  errorTypes.createCreditApplicationError,
  errorTypes.notFound,
  errorTypes.validationException,
  errorTypes.InstallCompleteException,
  errorTypes.emailAddress,
  errorTypes.contractAlreadySigned,
  errorTypes.fullSsnRequired,
  errorTypes.applicationException
]
export const handledErrorKeys = [
  errorKeys.phoneNumberIsLandline,
  errorKeys.phoneNumberIsInvalid,
  errorKeys.phoneNumberIsOptedOut,
  errorKeys.emailInvalidFormat,
  errorKeys.emailInstaller
]
export const suppressedErrorTypes = [errorTypes.offline]

export const ACCOUNT_NUMBER_MAX_LENGTH = 17
export const ROUTING_NUMBER_MAX_LENGTH = 9

export const ERROR_ICON_SIZE = 48

export const API_HOST = process.env.REACT_APP_API_HOST || ''

// NOTE: Update src/setupProxy.js as endpoints are added or removed
export const userAPI = `${API_HOST}/graphql/user`
export const guestAPI = `${API_HOST}/graphql/guest`
export const uploadDocumentUrl = `${API_HOST}/upload-document/`
export const s3PresignedUrlProxy = 'http://localhost:8000/url'

export const CROSS_DOMAIN_COMPONENT_URL = Object.freeze({
  [backendEnvironments.local]: 'http://localhost:5000'
})

export const DESCRIPTION_MAX_LENGTH = 200
export const CUSTOM_PROJECT_MAX_LENGTH = 25
export const STAGE_NAME_MAX_LENGTH = 25
export const TRUNCATE_MIDDLE_CHARS = 15

export const DEFAULT_MAX_PROJECTS_ALLOWED = 10
export const DEFAULT_MAX_STAGES_PER_PROJECT = 10
export const DEFAULT_MIN_STAGE_AMOUNT = 500
export const DEFAULT_MIN_STAGE_PERCENTAGE = 10
export const CHARACTER_LENGTH_TO_TRIGGER_SEARCH = 3

export const E2E_DOMAIN = 'tap.e2e.joinmosaic.com'
export const TESTING_DOMAIN = 'qa'

export const WEBLINKS = 'weblinks'
export const SWIFTLINK_DOMAIN = 'apply.joinmosaic.com'
export const SWIFTLINK_DOMAIN_QA = 'apply.qa.joinmosaic.com'
export const SWIFTLINK_DOMAIN_E2E = 'apply.e2e.joinmosaic.com'
export const AGGREGATOR_CODE = 'aggregatorCode'
export const SWIFTLINK_URL = isE2e =>
  `https://${isE2e ? SWIFTLINK_DOMAIN_E2E : SWIFTLINK_DOMAIN}/`
export const SWIFTLINK_URL_QA = `https://${SWIFTLINK_DOMAIN_QA}/`
export const PUBLIC_APPLY_FORM_SUBDOMAIN_REGEX = new RegExp(
  `^localhost|^${SWIFTLINK_DOMAIN_QA}|^${SWIFTLINK_DOMAIN_E2E}|^(?:[A-z0-9])*\\.?${SWIFTLINK_DOMAIN}`
)
export const AGGREGATOR_CODE_REGEX = new RegExp(`W*(${AGGREGATOR_CODE})W*`)
export const PRIVACY_POLICY_URL = 'https://www.joinmosaic.com/privacypolicy/'
export const DEFAULT_INPUT_HEIGHT = 38
export const ANIMATED_SCROLL_VERTICAL_OFFSET = -20

const localStorageAvailable = () => {
  try {
    if (window.localStorage) return true
  } catch (e) {
    return false
  }
  return false
}

export const DEFAULT_COOKIE_OPTIONS =
  isDevelopmentEnv() && localStorageAvailable()
    ? {}
    : { SameSite: 'None', Secure: true }

export const oldCookieOptions = {
  defaultCookieOptions: { Secure: true },
  crossDomainCookieOptions: { Secure: true, SameSite: 'None' }
}

export const DEFAULT_SWIFTLINKS_IFRAME_HEIGHT = 800
export const BYPASSABLE_PREQUALIFICATION_FORM_INLINE_ERRORS = { email: true }
export const DISPLAY_ROLE = {
  SALES_MANAGER: 'Sales Manager',
  MANAGER: 'Manager',
  MGR: 'Mgr'
}
export const SCREEN_SIZE = {
  XS: 'xs',
  SM: 'sm',
  MD: 'md',
  LG: 'lg',
  XL: 'xl'
}

export const SWIFTLINK_VARIATIONS = {
  ALL: 'ALL',
  NONE: 'NONE'
}

export const UPDATE_AVAILABLE_EVENT_NAME = 'updateAvailable'

export const ALLOWED_FILE_TYPES = {
  CSV: '.csv',
  XLS: '.xls',
  XLSX: '.xlsx',
  DOC: '.doc',
  DOCX: '.docx',
  PDF: '.pdf'
}

export const IMAGE_FILE_TYPES = {
  png: '.png',
  jpg: '.jpg',
  jpeg: '.jpeg',
  gif: '.gif'
}

export const minimumLength = {
  bankAccount: {
    currentAccountNumber: 4,
    cccountNumber: 4,
    currentRoutingNumber: 9
  },
  passwordResetForm: {
    email: 8
  }
}

export const mosaicAddress =
  '300 Lakeside Drive, 24th Floor, Oakland, California 94612'

export const supportPhone = '(855) 746-5551'

export const NO_ELEVATION = 0
export const DEFAULT_ELEVATION = 8

export const loanAppCountersignedAndBeyond = [
  opportunityStatuses.loanAppCountersigned,
  opportunityStatuses.installComplete,
  opportunityStatuses.installCompletePending,
  opportunityStatuses.installConfirmed,
  opportunityStatuses.finalPayment,
  opportunityStatuses.finalConfirmation,
  opportunityStatuses.deleted,
  opportunityStatuses.inspectionComplete,
  opportunityStatuses.equipmentSubmitted,
  opportunityStatuses.equipmentApproved
]
export const isLoanAppCountersignedAndBeyond = (
  opportunityStatus: OpportunityStatuses
) => loanAppCountersignedAndBeyond.includes(opportunityStatus)

export const ENTER_CHARCODE = 13
export const ENTER_KEYCODE = 'Enter'
export const ALLOWED_ORIGIN_ALL = '*'
export const UTC_SUFFIX = 'Z'
export const SAMPLE_NON_UTC_DATE = 'YYYY-MM-DDTHH:MM:SS'

export const creditDecisionExpireWarningDay = 14
export const redirectFromSetupToApplyExceptions = [
  creditApplicationDecisionStatuses.approved,
  creditApplicationDecisionStatuses.needsReview
]

export const DEFAULT_CUSTOMER_LOAN_TYPE = customerLoanTypes.home
export const DEFAULT_LANGUAGE_KEY = languageKeys.en

export const CONNEXUS_CREDIT_UNION_URL = 'https://www.connexuscu.org/'
export const CONNEXUS_ASSOCIATION_URL = 'https://connexusassociation.org/'

export const contactCardSupportTypes = {
  default: 'default',
  general: 'general',
  underwriting: 'underwriting',
  welcome: 'welcome',
  loanpayment: 'loanpayment'
}
