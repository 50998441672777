export default {
  CONTINUE_APP: 'CONTINUE_APP',
  CONTINUE_APP_VALUE: 'true',
  SCREEN_NAME: 'SCREEN_NAME',
  SCREEN_NAME_VALUES: {
    QUALIFIED_SCREEN: 'QUALIFIED_SCREEN',
    FRIENDLY_TILA: 'FRIENDLY_TILA'
  },
  EMAIL_VERIFICATION: 'EMAIL_VERIFICATION',
  EMAIL_VERIFICATION_VALUE: 'VERIFIED'
}
