import { gql } from 'graphql.macro'
import StageResponseFields from 'queries/fragments/StageResponseFields.graphql'
import ContractTermsFragment from 'queries/fragments/ContractTermsFragment.graphql'
import ContractTermsFragmentDiscounted from 'queries/fragments/ContractTermsFragmentDiscounted.graphql'

export default gql`
  fragment ContractResponseWithDiscountedFields on ContractResponse {
    status
    achEnabled
    loanProduct {
      id
      servicePlanAllowed
      promoPeriod
      loanProductType
      interestRate
      interestRateDiscounted
    }
    terms {
      ...ContractTermsFragmentDiscounted
      ...ContractTermsFragment
    }
    projects {
      projectType
      projectName
      stages {
        ...StageResponseFields
      }
    }
    isOkayToCancel
    contractProjectSummary {
      code
      addOnCount
      addOnRate
      monthlyTotalCost
    }
    lender {
      lender
      displayInTila
    }
    signator(signatoryRole: PRIMARY_APPLICANT) {
      dateSigned
      signator
    }
  }
  ${ContractTermsFragment}
  ${ContractTermsFragmentDiscounted}
  ${StageResponseFields}
`
