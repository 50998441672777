export default {
  Unspecified: 'UNSPECIFIED',
  Cancelled: 'CANCELLED',
  InspectionComplete: 'INSPECTION_COMPLETE',
  EquipmentApproved: 'EQUIPMENT_APPROVED',
  EquipmentSubmitted: 'EQUIPMENT_SUBMITTED',
  Approved: 'APPROVED',
  New: 'NEW',
  InstallComplete: 'INSTALL_COMPLETE',
  InstallCompletePending: 'INSTALL_COMPLETE_PENDING',
  InstallConfirmed: 'INSTALL_CONFIRMED',
  FinalConfirmation: 'FINAL_CONFIRMATION'
}
