// @flow
import React from 'react'
import { Paper } from '@material-ui/core'
import WizardPaper from 'components/shared/WizardPaper'

const ApplicationContainer = ({
  isMobile,
  children
}: {
  isMobile: Boolean,
  children: node
}) =>
  isMobile ? (
    <Paper>{children}</Paper>
  ) : (
    <>
      <WizardPaper>{children}</WizardPaper>
    </>
  )

export default ApplicationContainer
