// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Paper, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Routes from 'util/Routes'
import l10n from 'properties/translations'
import styles from 'screens/resources/Resources.styles'
import PanelButton from '../PanelButton'

type Props = {
  classes: {
    resourcesNavItemLink: string,
    resourcesNavItem: string,
    resourcesNavItemTitle: string,
    resourcesNavCtn: string,
    swiftlinkDescription: string
  }
}

const SwiftLinkPanel = ({ classes }: Props) => (
  <Grid item className={classes.resourcesNavCtn}>
    <Link
      to={Routes.swiftlinkResources()}
      className={classes.resourcesNavItemLink}
      data-testid="swiftlink-panel-link"
    >
      <Paper className={classes.resourcesNavItem}>
        <Typography
          variant="subtitle1"
          className={classes.resourcesNavItemTitle}
          paragraph
          data-testid="swiftlink-panel-title"
        >
          {l10n.resources.swiftlink.title}
        </Typography>
        <Typography
          className={classes.swiftlinkDescription}
          align="left"
          variant="body2"
          paragraph
          data-testid="swiftlink-panel-description"
        >
          {l10n.resources.swiftlink.subtitle}
        </Typography>
      </Paper>
    </Link>
    <PanelButton url={Routes.swiftlinkResources()} />
  </Grid>
)

export default withStyles(styles)(SwiftLinkPanel)
