// @flow
import React, { Component } from 'react'
import { isFunction } from 'lodash'
import OptionalQuery from 'components/shared/OptionalQuery'
import { isLoggedIn } from 'util/SessionHelper'
import userRolesEnum from 'constants/enums/userRoles'
import { isTestingEnv } from 'util/EnvironmentHelper'
import GetUserType from './queries/GetUserType.graphql'

type Props = {
  allowedUserTypes: Array<string>,
  children: any,
  renderNoAccess: Function,
  hidden: boolean
}

class AccessControl extends Component<Props> {
  static defaultProps = {
    allowedUserTypes: [],
    renderNoAccess: () => null,
    hidden: false
  }

  render() {
    const { allowedUserTypes, children, renderNoAccess, hidden } = this.props

    if (hidden) return null

    return (
      <OptionalQuery
        query={GetUserType}
        runQuery={() => isLoggedIn() || isTestingEnv()}
      >
        {({
          data: { me: { userType, userRoles, permissions } = {} } = {},
          error,
          loading
        }) => {
          if (loading || error) return null

          const hasAccess = allowedUserTypes.includes(userType)
          const hasAdminRole =
            userRoles && userRoles.includes(userRolesEnum.installer.admin)
          const hasSalesManagerRole =
            userRoles && userRoles.includes(userRolesEnum.installer.salesmgr)
          const hasOwnerRole =
            userRoles && userRoles.includes(userRolesEnum.installer.owner)
          const isMosaicSupport =
            userRoles &&
            userRoles.includes(userRolesEnum.installer.mosaicSupport)

          if (isFunction(children)) {
            return children({
              hasPermission: hasAccess,
              userType,
              userRoles,
              hasAdminRole,
              hasSalesManagerRole,
              hasOwnerRole,
              isMosaicSupport,
              permissions
            })
          }

          if (!hasAccess) return renderNoAccess()

          return children
        }}
      </OptionalQuery>
    )
  }
}

export default AccessControl
