// @flow
import get from 'lodash/get'
import intersection from 'lodash/intersection'
import isEmpty from 'lodash/isEmpty'
import { apiEnvironmentIs } from 'util/SessionHelper'
import creditApplicationDecisionFlags from 'constants/enums/creditApplicationDecisionFlags'
import creditApplicationStatuses from 'constants/enums/creditApplicationStatuses'
import similarProducts from 'constants/enums/similarProducts'
import backendEnvironments from 'constants/enums/backendEnvironments'
import {
  type LoanProductType,
  type TyKeyValueObject
} from 'screens/quote/QuoteTypes'

const { production, demo } = backendEnvironments
const { processing, ready } = creditApplicationStatuses
const { ofac, fraud, income, identity } = creditApplicationDecisionFlags

type DecisionType = {
  loanProducts: [LoanProductType]
}

type CreditApplicationType = {
  status: string,
  decision: DecisionType
}

type ProjectType = {
  id: number,
  amount: number,
  addOns: ?Object
}

type QuoteType = {
  loanProductId: number,
  loanAmount: number,
  projects: [ProjectType]
}

export const formatToLowerWithoutUnderScore = (string: string) =>
  string.replace(/_/g, ' ').toLowerCase()

export const processingApplicationStatus = (
  creditApplication?: CreditApplicationType
) => creditApplication && creditApplication.status === processing

export const applicationStatusReady = (
  creditApplication?: CreditApplicationType
) => creditApplication && creditApplication.status === ready

export const showConditionalApprovalPhone = (flags: string[]) =>
  flags.includes(ofac) || flags.includes(fraud)

export const showConditionalApprovalUpload = (flags: string[]) =>
  flags.includes(identity) || flags.includes(income)

export const showConditionalApprovalMultiple = (flags: string[]) =>
  flags.includes(identity) && showConditionalApprovalPhone(flags)

const similarProductsList = () => {
  let similarLoanProductIds = []
  if (apiEnvironmentIs(production)) {
    similarLoanProductIds = similarProducts.productionProducts
  } else if (apiEnvironmentIs(demo)) {
    similarLoanProductIds = similarProducts.demoProducts
  } else {
    // If we ever switch Staging (https://main-testing.ecs.ui.demo.mosaic-point-of-sale.com) to point at the RC database
    // we'll need to add another check here since RC product ids will be different than QA
    similarLoanProductIds = similarProducts.testProducts
  }
  return similarLoanProductIds
}

export const getSimilarApprovedProduct = (
  loanProductId: number,
  approvedLoanProductIds?: [number]
): TyKeyValueObject => {
  const similarProductList = similarProductsList()
  const similarProductPair = similarProductList.find(
    productPair =>
      productPair.includes(loanProductId) &&
      !isEmpty(intersection(productPair, approvedLoanProductIds))
  )
  if (similarProductPair) {
    const similarProductId = similarProductPair.find(
      productId => productId !== loanProductId
    )
    return { key: true, value: similarProductId }
  }
  return { key: false }
}

export const canCreateContractForCreditApplication = (
  quote?: QuoteType,
  creditApplication?: CreditApplicationType
) => {
  const { loanAmount, loanProductId } = quote || {}

  const approvedLoanProductIds = (
    get(creditApplication, 'decision.qualifiedProducts', []) || []
  ).map(lp => lp.loanProductId || lp.id)

  const foundMinimumLoanAmount = (
    (get(creditApplication, 'decision.qualifiedProducts', []) || []).find(
      lp => (lp.loanProductId || lp.id) === loanProductId
    ) || {}
  ).minimumAmount

  return (
    loanAmount &&
    loanProductId &&
    loanAmount >= foundMinimumLoanAmount &&
    (approvedLoanProductIds.includes(loanProductId) ||
      getSimilarApprovedProduct(loanProductId, approvedLoanProductIds).key)
  )
}
