// @flow
import React, { useState, useEffect, useContext } from 'react'
import NumberFormat from 'react-number-format'
import { get, isArrayLikeObject, sumBy } from 'lodash'
import cx from 'classnames'
import {
  CircularProgress,
  Grid,
  Typography,
  Link,
  Button
} from '@material-ui/core'
import { Query, Mutation } from '@apollo/client/react/components'
import UserContext from 'util/UserContext'
import { canCreateContractForCreditApplication } from 'util/ApplicationFormStatusHelper'
import {
  CIRCULAR_PROGRESS_SIZE,
  DOLLAR_PREFIX,
  EMPTY_ARRAY
} from 'properties/properties'
import userTypes from 'constants/enums/userTypes'
import Error from 'components/shared/Error'
import l10n from 'properties/translations'
import { connexusMembershipFlagEnabled } from 'util/FeatureHelper'
import TranslationsContext from 'util/TranslationsContext'
import {
  getSimilarLoanProduct,
  approvedForLessThanProject,
  projectEstimate,
  getMatchingLoanProduct,
  isServicePlanAvailable,
  getAddOnsObject,
  showCombinedAmount,
  getProjectWithAddOns,
  safeLoanAmount
} from 'util/ApplyHelper'
import { calculateServicePlanAmount } from 'util/MathHelper'
import MonitoringHelper from 'util/MonitoringHelper'
import lenders from 'constants/enums/lenders'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import CurrencyNumberFormat from 'components/shared/CurrencyNumberFormat'
import ContractMutation from '../queries/ContractMutation.graphql'
import ContactRep from '../ContactRep'
import LoanProductInfoBox from '../components/LoanProductInfoBox'
import GetMonthlyPayment from '../queries/GetMonthlyPayment.graphql'
import ServicePlan from '../../quote/components/inputs/ServicePlan'
import GetUserTypeAndSalesPersonGraphql from '../../../queries/GetUserTypeAndSalesPerson.graphql'
import ApplyContext from 'util/ApplyContext'
import useAccessControl from 'util/useAccessControl'

const showHandBackText = (creditApplication, quote) => {
  const financingLimit = get(creditApplication, 'financingLimit') || 0
  const projects = get(quote, 'projects')
  const total =
    projects && isArrayLikeObject(projects) ? sumBy(projects, p => p.amount) : 0

  return total <= 0 || total > financingLimit
}

type YourFinancePlanProps = {
  classes: {
    [string]: string
  },
  customerId: string,
  quote: {
    loanProductId: number,
    loanAmount: number,
    projects: [{ id: number, amount: number, addOns: ?Object }]
  },
  lender: string,
  monthlyPayment: Object,
  servicePlanAmount: Object,
  servicePlanSelected: ?boolean,
  isLoadingMonthlyPayments: Object,
  ldFlags: Object,
  data: {}
}

const buildLoanProduct = loanProduct => {
  return {
    loanProductId: loanProduct.id,
    loanProductType: loanProduct.loanProductType,
    productName: loanProduct.displayName,
    loanMonthTerm: loanProduct.loanTenorMonths,
    minimumAmount: loanProduct.minimumAmount,
    borrowingLimit: loanProduct.borrowingLimit,
    apr: loanProduct.sampleTerms.apr,
    interestRate: loanProduct.interestRate,
    numMonthlyPayments: loanProduct.sampleTerms.numMonthlyPayments,
    servicePlanAllowed: loanProduct.servicePlanAllowed,
    numMonthlyPaymentsPromo: loanProduct.sampleTerms.numMonthlyPaymentsPromo,
    aprPromo: loanProduct.sampleTerms.aprPromo,
    monthlyPaymentPromo: loanProduct.sampleTerms.monthlyPaymentPromo,
    monthlyPayment: loanProduct.sampleTerms.monthlyPayment,
    promoPeriod: loanProduct.promoPeriod,
    financingOptions: []
  }
}

const YourFinancePlan = ({
  classes,
  quote,
  lender,
  monthlyPayment,
  isLoadingMonthlyPayments,
  ldFlags,
  servicePlanSelected,
  servicePlanAmount,
  data
}: YourFinancePlanProps) => {
  const userContext = React.useContext(UserContext)
  const { channelPartner } = userContext

  const customer = get(data, 'customer')
  const l10n = useContext(TranslationsContext)
  const connexusMembershipFlag = connexusMembershipFlagEnabled(ldFlags)
  const PartnerText = l10n.apply.PartnerText
  let loanProduct =
    getMatchingLoanProduct(customer, quote, ldFlags) ||
    getSimilarLoanProduct(customer, quote, ldFlags)

  loanProduct = buildLoanProduct(loanProduct)
  const addOns = get(channelPartner, 'addOns', EMPTY_ARRAY)

  const servicePlans =
    addOns &&
    addOns.filter(addOn => {
      return addOn.code === l10n.quote.servicePlan.code
    })
  return (
    <>
      {servicePlans.length > 0 && typeof monthlyPayment === 'number' && (
        <div className={classes.sectionCtn}>
          <Typography
            className={classes.totalMonthlyPaymentColor}
            variant="caption"
            align="center"
            gutterBottom
          >
            {servicePlanSelected
              ? l10n.apply.productSelection.combinedMonthlyPayment
              : l10n.apply.productSelection.totalMonthlyPayment}
          </Typography>
          <Typography
            className={classes.totalMonthlyPaymentColor}
            align="center"
            variant="h2"
            paragraph
          >
            {isLoadingMonthlyPayments ? (
              <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
            ) : (
              <NumberFormat
                value={
                  servicePlanSelected
                    ? showCombinedAmount(servicePlanAmount, monthlyPayment)
                    : monthlyPayment.toFixed(2)
                }
                prefix={DOLLAR_PREFIX}
                renderText={text => <SuperScriptAmount price={text} />}
                displayType="text"
                thousandSeparator
              />
            )}
          </Typography>
        </div>
      )}

      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography
            variant="body2"
            className={classes.approvedText}
            align="center"
            gutterBottom
          >
            {lender === lenders.WEBBANK
              ? l10n.apply.formStatus.approved.yourFinancePlanWebbank
              : l10n.apply.formStatus.approved.yourFinancePlan}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.productRow}>
          <LoanProductInfoBox
            loanProduct={loanProduct}
            servicePlans={servicePlans}
            isSelected
            isLoadingFinancePlan={!customer}
          />
        </Grid>
      </Grid>
      {connexusMembershipFlag && (
        <Grid container justify="center">
          <Typography variant="body2" className={classes.approvedContentText}>
            <PartnerText />
          </Typography>
        </Grid>
      )}
    </>
  )
}

type YourProjectEstimateProps = { quote: {}, lender: string }
const YourProjectEstimate = ({ quote, lender }: YourProjectEstimateProps) => {
  const l10n = useContext(TranslationsContext)
  return (
    <>
      <Grid container justify="center">
        <Grid item xs={12}>
          <Typography variant="body2" align="center" gutterBottom>
            {lender === lenders.WEBBANK
              ? l10n.apply.formStatus.approved.totalAmountToFinanceWebbank
              : l10n.apply.formStatus.approved.totalAmountToFinance}
          </Typography>
        </Grid>
      </Grid>
      <Grid item>
        <CurrencyNumberFormat
          renderText={text => (
            <Typography
              data-testid="totalAmountToFinance"
              align="center"
              variant="h2"
            >
              <SuperScriptAmount price={text} />
            </Typography>
          )}
          value={projectEstimate(quote)}
        />
      </Grid>
    </>
  )
}
const getMonthlyPaymentsOnChange = async (
  client,
  customerId,
  quote,
  setIsLoadingMonthlyPayments,
  setMonthlyPayment,
  setServicePlans,
  ldFlags,
  channelPartner
) => {
  try {
    if (quote && quote.loanAmount) {
      const addOns = channelPartner && get(channelPartner, 'addOns')
      const servicePlans = addOns.filter(addOn => {
        return addOn.code === l10n.quote.servicePlan.code
      })
      if (servicePlans.length > 0) {
        setServicePlans(servicePlans)
      }
      setIsLoadingMonthlyPayments(true)
      const {
        data: { customer }
      } = await client.query({
        fetchPolicy: 'no-cache',
        query: GetMonthlyPayment,
        variables: {
          customerId,
          loanAmount: safeLoanAmount(quote.loanAmount)
        }
      })

      setIsLoadingMonthlyPayments(false)
      const loanProduct =
        getMatchingLoanProduct(customer, quote, ldFlags) ||
        getSimilarLoanProduct(customer, quote, ldFlags)

      setMonthlyPayment(loanProduct.sampleTerms.monthlyPayment)
    }
  } catch (err) {
    MonitoringHelper.manuallyReportError(err)
    // eslint-disable-next-line no-console
    console.warn('Error getting getMonthlyPayments', err)
  }
}

type Props = {
  classes: {
    [string]: string
  },
  quote: {
    loanProductId: number,
    loanAmount: number,
    projects: [
      {
        id: number,
        amount: number,
        addOns: ?Object
      }
    ]
  },
  creditApplication: {
    status: string,
    financingLimit: number,
    decision: {
      loanProducts: [{ loanProductId: string }]
    }
  },
  onEditOffer: () => {},
  lender: string,
  customerId: string,
  client: { mutate: Function, query: Function },
  isProductLoading: Boolean,
  isLoanAmountOutOfLimits: Boolean,
  errorMessage: String,
  isSubmitting: boolean,
  handleQualifyOrUnderClick: Function,
  ldFlags: Object
}

const ProjectEstimate = ({
  isLoanAmountOutOfLimits,
  quote,
  creditApplication,
  classes,
  lender,
  customerId,
  errorMessage,
  isSubmitting,
  onEditOffer,
  handleQualifyOrUnderClick,
  isProductLoading,
  client,
  ldFlags
}: Props) => {
  const [isLoadingMonthlyPayments, setIsLoadingMonthlyPayments] =
    useState(false)
  const [monthlyPayment, setMonthlyPayment] = useState({})
  const [servicePlans, setServicePlans] = useState([])
  const [servicePlanAmount, setServicePlanAmount] = useState(0)
  const initialProjects = get(quote, 'projects')
  const addOnsObject = getAddOnsObject(initialProjects)
  const [serviceOption, setServiceOption] = useState('1')
  const [servicePlanSelected, setServicePlanSelected] = useState(false)
  const { editOfferModalOpen } = useContext(ApplyContext)
  const l10n = useContext(TranslationsContext)
  const userContext = React.useContext(UserContext)
  const { channelPartner } = userContext
  const { loading, error, hasAccess } = useAccessControl([userTypes.installer])

  useEffect(() => {
    // Get Monthly Payment and setState

    getMonthlyPaymentsOnChange(
      client,
      customerId,
      quote,
      setIsLoadingMonthlyPayments,
      setMonthlyPayment,
      setServicePlans,
      ldFlags,
      channelPartner
    )
    if (initialProjects) {
      const projectWithAddOn = getProjectWithAddOns(initialProjects)

      setServicePlanSelected(isServicePlanAvailable(initialProjects))
      if (projectWithAddOn) {
        const numberofServicePlanSelected =
          projectWithAddOn.addOns && projectWithAddOn.addOns.length // initialAddons[0] is array of addons
        setServiceOption(numberofServicePlanSelected)
      }
      if (addOnsObject !== null) {
        setServicePlanAmount(calculateServicePlanAmount(addOnsObject))
      }
    }
  }, [
    client,
    addOnsObject,
    customerId,
    serviceOption,
    initialProjects,
    servicePlanSelected,
    quote,
    ldFlags,
    channelPartner
  ])
  const canCreateCreditApplication = canCreateContractForCreditApplication(
    quote,
    creditApplication
  )

  if (loading || error)
    return <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />

  if (canCreateCreditApplication === undefined && isProductLoading) {
    return <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
  }

  return canCreateCreditApplication ? (
    <Query
      query={GetUserTypeAndSalesPersonGraphql}
      variables={{ customerId, loanAmount: projectEstimate(quote) }}
    >
      {({ loading, error, data }) => {
        if (!editOfferModalOpen && (loading || isLoadingMonthlyPayments))
          return <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
        if (error) return <Error error={error} />
        const { customer: { salesperson } = {}, me: { userType } = {} } = data

        return isLoanAmountOutOfLimits && userType === userTypes.borrower ? (
          <Grid item xs={6} align="center">
            <Typography
              className={classes.salesmanName}
            >{`${salesperson.firstName} ${salesperson.lastName}`}</Typography>
            <Link href={`mailto:${salesperson.email}`}>
              <Typography className={classes.salesmanEmail}>
                {salesperson.email}
              </Typography>
            </Link>
            <Typography>
              {l10n.apply.formStatus.approved.loanAmountOutOfLimitMessage(
                salesperson.channelPartnerName
              )}
            </Typography>
          </Grid>
        ) : (
          <>
            <YourProjectEstimate
              classes={classes}
              quote={quote}
              lender={lender}
            />
            <YourFinancePlan
              monthlyPayment={monthlyPayment}
              servicePlanAmount={servicePlanAmount}
              servicePlanSelected={servicePlanSelected}
              isLoadingMonthlyPayments={isLoadingMonthlyPayments}
              ldFlags={ldFlags}
              classes={classes}
              quote={quote}
              customerId={customerId}
              lender={lender}
              data={data}
            />
            {servicePlanSelected && servicePlans.length > 0 && (
              <ServicePlan
                classes={classes}
                currentValue={Number(serviceOption)}
                loanProductSelected
                qualifiedScreen
                readonly={false}
                onClick={() => {}}
                isSelected={servicePlanSelected}
                addOnsAmount={servicePlans[0].defaultPrice}
                ldFlags={ldFlags}
              />
            )}
            <Grid item>
              <Mutation mutation={ContractMutation}>
                {createContract => (
                  <Grid container justify="center" alignItems="center">
                    <Grid item xs={12}>
                      {errorMessage && (
                        <Typography
                          className={classes.errorMessage}
                          align="center"
                        >
                          {errorMessage}
                        </Typography>
                      )}
                    </Grid>
                    <Grid item xs={12} className={classes.buttonCenter}>
                      <Button
                        data-testid="next"
                        className={classes.button}
                        variant="contained"
                        color="primary"
                        size="large"
                        disabled={isSubmitting}
                        onClick={handleQualifyOrUnderClick(
                          quote,
                          creditApplication,
                          onEditOffer,
                          createContract,
                          ldFlags
                        )}
                      >
                        {isSubmitting ? (
                          <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
                        ) : (
                          <Typography>
                            {approvedForLessThanProject(
                              quote,
                              creditApplication,
                              ldFlags
                            ) || !quote
                              ? l10n.apply.formStatus.menu.editOffer
                              : l10n.apply.formStatus.approved.viewOffer}
                          </Typography>
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Mutation>
            </Grid>
          </>
        )
      }}
    </Query>
  ) : (
    <>
      {!hasAccess ? (
        <ContactRep customerId={customerId} />
      ) : (
        <Grid item container justify="center">
          {showHandBackText(creditApplication, quote) ? (
            <Typography
              component="span"
              className={cx(classes.handBackCommon, classes.bodyTextNormal)}
            >
              {l10n.apply.handBackText(onEditOffer, classes.handBackButton)}
            </Typography>
          ) : (
            <Button variant="outlined" color="primary" onClick={onEditOffer}>
              <Typography className={classes.buttonLabelSecondary}>
                {quote
                  ? l10n.apply.continueToEditOffer
                  : l10n.apply.formStatus.menu.editOffer}
              </Typography>
            </Button>
          )}
        </Grid>
      )}
    </>
  )
}

export default withLaunchDarkly(ProjectEstimate)
