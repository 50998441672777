// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import PhoneIcon from '@material-ui/icons/Phone'
import { Grid, Typography } from '@material-ui/core'
import l10n from 'properties/translations'
import styles from './commonStyles'

type Props = {
  classes: {
    subheading: string
  }
}

const ConditionalApprovalPhone = ({ classes }: Props) => (
  <Grid container direction="column" justify="center" alignItems="center">
    <Grid item>
      <Grid
        container
        justify="center"
        alignItems="center"
        className={classes.iconWrapperGray}
      >
        <Grid item>
          <PhoneIcon className={classes.iconGray} />
        </Grid>
      </Grid>
    </Grid>
    <Grid item className={classes.subheading}>
      <Typography variant="subtitle1" align="center">
        {l10n.apply.formStatus.conditionalApprovalPhone.subHeader}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="body2" paragraph>
        {l10n.apply.formStatus.conditionalApprovalPhone.descriptionLn1}
      </Typography>
      <Typography variant="body2" paragraph>
        {l10n.apply.formStatus.conditionalApprovalPhone.descriptionLn2}
      </Typography>
      <Typography variant="body1" paragraph>
        {l10n.apply.formStatus.conditionalApprovalPhone.descriptionLn3}
        {l10n.apply.formStatus.conditionalApprovalPhone.descriptionLn3Action}
      </Typography>
      <Typography variant="body2" paragraph>
        {l10n.apply.formStatus.conditionalApprovalPhone.descriptionLn4}
      </Typography>
    </Grid>
  </Grid>
)

export default withStyles(styles)(ConditionalApprovalPhone)
