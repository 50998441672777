// @flow
import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import VerifiedUserOutlinedIcon from '@material-ui/icons/VerifiedUserOutlined'
import TranslationsContext from 'util/TranslationsContext'
import { colors } from 'themes/default'
import cx from 'classnames'

type Props = {
  classes: {
    creditScoreNotice: string,
    inactive: string,
    confidenceIcons: string,
    iconsContainer: string
  },
  isInactive: boolean
}

const styles = theme => ({
  creditScoreNotice: {
    color: colors.iconSuccess,
    textAlign: 'center',
    marginTop: theme.spacing(5)
  },
  inactive: {
    opacity: 0.4
  },
  confidenceIcons: {
    opacity: 0.5,
    width: 'auto',
    '@media (max-width: 600px)': {
      marginTop: theme.spacing(1)
    }
  },
  iconsContainer: {
    width: 'auto',
    marginLeft: theme.spacing(0.5),
    '@media (max-width: 600px)': {
      marginLeft: 0
    }
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  icon: {
    paddingRight: '2px',
    width: '0.9em'
  },
  iconCaption: {
    fontWeight: 600,
    textTransform: 'uppercase',
    fontSize: '0.75rem',
    lineHeight: 1
  },
  iconContainerFirst: {
    paddingRight: '5px'
  },
  captionContainer: {
    marginTop: theme.spacing(1),
    justifyContent: 'center'
  }
})

const ConfidenceIcons = ({ classes, alignCenter }) => {
  const l10n = useContext(TranslationsContext)

  return (
    <Grid
      container
      justify={alignCenter ? 'center' : 'flex-start'}
      className={classes.iconsContainer}
    >
      <Grid
        item
        className={cx([classes.iconContainer, classes.iconContainerFirst])}
      >
        <LockOutlinedIcon fontSize="small" className={classes.icon} />
        <Typography className={classes.iconCaption}>
          {l10n.prequalificationForm.SSL}
        </Typography>
      </Grid>

      <Grid item className={classes.iconContainer}>
        <VerifiedUserOutlinedIcon fontSize="small" className={classes.icon} />
        <Typography className={classes.iconCaption}>
          {l10n.prequalificationForm.bitEncrypted}
        </Typography>
      </Grid>
    </Grid>
  )
}

const CreditScoreNotice = ({
  classes,
  isInactive,
  showCreditScoreText = true
}: Props) => {
  const l10n = useContext(TranslationsContext)

  return (
    <>
      {showCreditScoreText ? (
        <Grid
          className={`${classes.creditScoreNotice} ${
            isInactive ? classes.inactive : ''
          }`}
        >
          <Typography>{l10n.prequalificationForm.creditScoreNotice}</Typography>
          <Grid
            container
            alignItems="center"
            className={classes.captionContainer}
          >
            <Typography className={classes.iconCaption}>
              {l10n.prequalificationForm.informationIsSecured}
            </Typography>
            <ConfidenceIcons classes={classes} alignCenter />
          </Grid>
        </Grid>
      ) : (
        <Grid
          item
          container
          alignItems="center"
          className={classes.confidenceIcons}
        >
          <ConfidenceIcons classes={classes} />
        </Grid>
      )}
    </>
  )
}

export default withStyles(styles)(CreditScoreNotice)
