import React, { createContext } from 'react'
import translations from './translations'
import { translationByPath } from './utils'
import { I18nType } from '../typedefs.types.i18n-0.7.2'

const defaultLocale = 'enUS'

export type I18nApplyContextType = {
  i18n: I18nType
  t: (path: string, ...otherArgs: any[]) => string
  locales: string[]
}

export const I18nApplyContext: React.Context<I18nApplyContextType> =
  createContext<I18nApplyContextType>({
    i18n: translations[defaultLocale],
    t: (path: string, ...otherArgs: any[]) =>
      translationByPath(translations[defaultLocale])(path, ...otherArgs),
    locales: ['en-US', 'en']
  })
