// @flow
import React, { useContext, useEffect } from 'react'
import { withStyles } from '@material-ui/core/styles'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { Query } from '@apollo/client/react/components'
import { Grid, Typography } from '@material-ui/core'
import { get, isEmpty } from 'lodash'
import Error from 'components/shared/Error'
import Loading from 'components/shared/Loading'
import TranslationsContext from 'util/TranslationsContext'
import ApplyContext from 'util/ApplyContext'
import { makeSafeSessionStorage } from 'util/makeSafeStorage'
import { fieldsetNames } from 'constants/fieldsets/customerFieldsets'
import GetCustomerRep from './queries/GetCustomerRep.graphql'
import styles from './ContactRep.styles'
import { clearScreenNameInIframeIfFlagEnabled } from '../../util/EnvironmentHelper'

const safeSessionStorage = makeSafeSessionStorage()

type Props = {
  ldFlags: any,
  classes: {
    container: string,
    linkText: string
  },
  customerId: string
}

const ContactRep = ({ ldFlags, classes, customerId }: Props) => {
  useEffect(() => {
    // AEA-72
    clearScreenNameInIframeIfFlagEnabled(ldFlags, safeSessionStorage)
  }, [ldFlags])

  const l10n = useContext(TranslationsContext)
  return (
    <Query query={GetCustomerRep} variables={{ customerId }}>
      {({ data, loading, error }) => {
        if (loading) return <Loading />
        if (error) return <Error error={error} />

        const salesperson = get(data, 'customer.salesperson')
        if (!salesperson || isEmpty(salesperson)) return null
        const { firstName, lastName, email, channelPartnerName } = salesperson

        return (
          <ApplyContext.Consumer>
            {value => (
              <Grid className={classes.container} direction="column" container>
                {get(value, 'fieldset') ===
                fieldsetNames.FIELDSET_PUBLIC_APPLICATION ? (
                  <Typography align="center" variant="body2" paragraph>
                    {l10n.apply.contactRep.weblinksDescription(
                      channelPartnerName
                    )}
                  </Typography>
                ) : (
                  <>
                    <Typography align="center" variant="body1" paragraph>
                      {firstName} {lastName}
                    </Typography>

                    <Typography align="center" variant="body2" paragraph>
                      <a href={`mailto:${email}`} className={classes.linkText}>
                        {email}
                      </a>
                    </Typography>

                    <Typography align="center" variant="body2" paragraph>
                      {l10n.apply.contactRep.description(channelPartnerName)}
                    </Typography>
                  </>
                )}
              </Grid>
            )}
          </ApplyContext.Consumer>
        )
      }}
    </Query>
  )
}

export default withLaunchDarkly(withStyles(styles)(ContactRep))
