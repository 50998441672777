import { pick, pickBy, identity, get, find, map, isEmpty } from 'lodash'
import { subYears } from 'date-fns'
import getUserLocale from 'get-user-locale'
import customerFields from 'constants/enums/customerFields'
import customerStatuses from 'constants/enums/customerStatuses'
import opportunityStatuses from 'constants/enums/opportunityStatuses'
import addressTypes from 'constants/enums/addressTypes'
import errorTypes from 'constants/enums/errorTypes'
import customerLoanTypes from 'constants/enums/customerLoanTypes'
import languageKeys from 'constants/enums/languageKeys'
import {
  INCOME_ALERT_HIGH,
  INCOME_ALERT_LOW,
  EMPTY_STRING,
  handledErrorKeys,
  CURRENCY_TO_NUMBER
} from 'properties/properties'
import l10n from 'properties/translations'
import errorKeys from 'constants/enums/errorKeys'
import {
  type ServerErrorObject,
  type FieldError,
  type CustomerShape
} from 'screens/customer/shapes/customer'
import {
  type SalespersonResponse,
  type AboutMeResponse,
  type AutoCompleteComboBoxOption
} from 'util/TypesHelper'
import {
  allowSolarCustomersInPipelineFlag,
  coApplicantFormEnabled
} from 'util/FeatureHelper'
import { type UserContextType } from 'util/UserContext'
import ClearCalculateInput from 'screens/quote/queries/ClearCalculateInput'
import userTypesEnum from 'constants/enums/userTypes'
import { enUS, es } from 'date-fns/locale'
import { GraphQLError } from 'graphql'

export const getInlineValidationErrors = validatedFields =>
  pickBy(pick(validatedFields, Object.values(customerFields)), identity)

export const readOnlyDisclaimerTranslationKey = ({
  customerStatus,
  opportunityStatus
}) => {
  if (opportunityStatus === opportunityStatuses.deleted) {
    return customerStatuses.cancelled
  }
  switch (customerStatus) {
    case customerStatuses.cancelled:
      return customerStatuses.cancelled
    default:
      return null
  }
}

export const getProjectAddress = (addresses = []) =>
  addresses.reduce(
    (projectAddress, fullAddress) =>
      // TODO: For now, just tracking project address.  Will update per EX-2366
      projectAddress.addressType !== addressTypes.secondHome && // Always pass over primary if rental or second home is recorded
      projectAddress.addressType !== addressTypes.rental &&
      fullAddress.addressType !== addressTypes.mailing // Mailing address is never project address
        ? fullAddress
        : projectAddress,
    {
      street: '',
      city: '',
      stateAbbreviation: '',
      zip: '',
      addressType: ''
    }
  )

export const addressFormat = address =>
  `${address.street} ${address.city} ${address.stateAbbreviation} ${address.zip}`

export const addressFormatter = customer =>
  addressFormat(getProjectAddress(get(customer, 'addresses')))

export const primaryAddressFormatter = customer => {
  const addresses = get(customer, 'addresses')

  return addressFormat(
    find(addresses, address => address.addressType === addressTypes.primary)
  )
}

export const addressIsPrimary = projectAddressType => {
  if ([addressTypes.primary].includes(projectAddressType)) {
    return true
  }
  return false
}
export const addressIsSecondHomeOrRental = projectAddressType => {
  if (
    [addressTypes.rental, addressTypes.secondHome].includes(projectAddressType)
  ) {
    return true
  }
  return false
}

export const dobFieldsFromCustomerWeblinksMetadata = customer => {
  const dobString = get(customer, 'weblinksMetadata.dateOfBirth')
  if (!dobString) return {}

  try {
    const parsed = new Date(dobString)
    return {
      dobYear: parsed.getUTCFullYear(),
      dobMonth: parsed.getUTCMonth() + 1,
      dobDay: parsed.getUTCDate()
    }
  } catch {
    return {}
  }
}

export const isPhoneFieldEditable = ({
  canEdit,
  disableFields,
  isEditingCustomer,
  isMosaicSupport,
  isEditCustomerFlagEnabled
}) => {
  const isFieldEditable = canEdit || !disableFields
  const isPhoneFieldEditable = isEditingCustomer
    ? isFieldEditable && isMosaicSupport && isEditCustomerFlagEnabled
    : isFieldEditable
  return isPhoneFieldEditable
}

const incomeFieldToNumber = income => {
  try {
    if (Number.isInteger(income)) return income
    return Number(income.replace(CURRENCY_TO_NUMBER, EMPTY_STRING))
  } catch (e) {
    return 0
  }
}

export const showHighIncomeAlert = income => {
  const personalIncome = incomeFieldToNumber(income)
  return personalIncome > INCOME_ALERT_HIGH
}

export const showLowIncomeAlert = income => {
  const personalIncome = incomeFieldToNumber(income)
  return personalIncome < INCOME_ALERT_LOW
}

export const computeFieldIfExtensionMissing = (errorType, errorMessage) => {
  switch (errorType) {
    case errorTypes.validationException: {
      const lowercased = errorMessage.toLowerCase()
      if (lowercased.includes('phone')) return customerFields.phoneNumber
      return EMPTY_STRING
    }
    default:
      return EMPTY_STRING
  }
}

export const getServerErrorMessageObject = (
  serverErrors: GraphQLError[]
): ServerErrorObject => {
  let errorObject = {}
  if (!serverErrors) return errorObject
  for (const error of serverErrors) {
    const errorMessage = get(error, 'message', EMPTY_STRING)
    const errorType = get(error, 'extensions.type', null)
    const errorKey = get(error, 'extensions.key', null)

    const errorField =
      get(error, 'extensions.field') ||
      computeFieldIfExtensionMissing(errorType, errorMessage)

    const baseError = {
      field: errorField,
      type: errorType,
      key: errorKey
    }
    switch (errorField) {
      case customerFields.phoneNumber:
      case customerFields.primaryApplicantPhoneNumber: {
        errorObject[errorField] = {
          ...baseError,
          type: customerFields.phoneNumber,
          message: errorMessage
        }
        break
      }
      case customerFields.email:
      case customerFields.emailAddress:
      case customerFields.primaryApplicantEmailAddress: {
        errorObject[errorField] = {
          ...baseError,
          type: customerFields.email,
          message: errorMessage
        }
        break
      }
      default: {
        errorObject[errorField] = undefined
      }
    }
  }
  return errorObject
}

export const serverErrorObjectToFieldErrors = (
  errors: ServerErrorObject
): FieldError[] =>
  map(errors, (value, key) => {
    const failedField = get(
      errorTypes.failedFields,
      errorTypes.graphQLErrorFieldsToFailedFields[key]
    )
    if (!failedField) {
      return null
    }
    return {
      failedField,
      errorMessage: value.message
    }
  })

export const assignedTo = (
  salesperson: SalespersonResponse,
  me: AboutMeResponse,
  assignedSalesperson: SalespersonResponse
): AutoCompleteComboBoxOption =>
  isEmpty(salesperson)
    ? {
        ...me,
        id: me.userId,
        fullName: `${me.firstName} ${me.lastName}`
      }
    : {
        ...salesperson,
        fullName: assignedSalesperson
          ? `${assignedSalesperson.firstName} ${assignedSalesperson.lastName}`
          : `${salesperson.firstName} ${salesperson.lastName}`
      }

export const customerIsBlocked = (
  userContext: UserContextType,
  ldFlags: any,
  customer: CustomerShape
) => {
  const installersCannotSeeSolarCustomers =
    !allowSolarCustomersInPipelineFlag(ldFlags) &&
    !coApplicantFormEnabled(ldFlags, { customer })
  const userTypeInstaller =
    get(userContext, 'me.userType') === userTypesEnum.installer
  const loanTypeSolar = get(customer, 'loanType') === customerLoanTypes.solar

  return installersCannotSeeSolarCustomers && userTypeInstaller && loanTypeSolar
}

// Used in cases where we can't use customerLanguageKey() since we have no
// customer object yet
export const detectBrowserLanguageKey = () =>
  getUserLocale().substr(0, 2).toUpperCase()

export const customerLanguageKey = (customer: CustomerShape) =>
  get(customer, 'language', detectBrowserLanguageKey())

export const onNavigateAwayFromCustomer = (client: ApolloClient) => {
  client.mutate({ mutation: ClearCalculateInput })
}

// For backwards compatibility (we certainly don't want the site to think integrators are installers)
// isBorrower will return true for both borrowers and integrators.
// Use isIntegrator if you need to specifically check that the user is an integrator user.
export const isBorrower = (meQueryResult: MeQueryResult) =>
  [userTypesEnum.borrower, userTypesEnum.integrator].includes(
    get(meQueryResult, 'userType')
  )

export const isIntegrator = (meQueryResult: MeQueryResult) =>
  get(meQueryResult, 'userType') === userTypesEnum.integrator

export const emailErrorMessage = (
  errorType: string,
  fieldValue?: string
): string => {
  if (handledErrorKeys.includes(errorType)) {
    return l10n.apply.mobileVerificationScreen.emailErrorTypes[errorType]
  }
  return EMPTY_STRING
}

export const phoneErrorMessage = (
  errorType: string,
  fieldValue?: string,
  isLandlineNumber: boolean
): string => {
  const isLandline =
    errorType === errorKeys.phoneNumberIsLandline || isLandlineNumber
  const phoneErrorMessage = isLandline
    ? l10n.apply.mobileVerificationScreen.phoneErrorTypes.PhoneNumberIsLandline(
        fieldValue
      )
    : l10n.apply.mobileVerificationScreen.phoneErrorTypes[errorType]
  if (handledErrorKeys.includes(errorType)) {
    return phoneErrorMessage
  }
  return EMPTY_STRING
}
export const isLandlineError = fieldErrors =>
  fieldErrors.some(
    error =>
      error.errorMessage === l10n.customer.landlineAPIMessage &&
      error.failedField === errorTypes.failedFields.PHONE &&
      fieldErrors.length === 1
  )

const readErrorKey = (errorMessage: GraphQLError, prefix: string) =>
  get(errorMessage, `["${prefix}"].key`)

export const getErrorType = (
  errorMessages: any[],
  prefixes: string[] | undefined
) => {
  const singleErrorMessage = errorMessages
  if (!Array.isArray(prefixes)) {
    return get(singleErrorMessage, msg =>
      Object.keys(msg).find(prefix => msg[prefix].key)
    )
  }
  for (const prefix of prefixes) {
    const key = readErrorKey(singleErrorMessage, prefix)
    if (key) return key
  }
  return null
}

export const localeForLanguageKey = (languageKey: string) => {
  switch (languageKey) {
    case languageKeys.es:
      return es
    default:
      return enUS
  }
}

export const getCustomerName = customer =>
  `${get(customer, 'firstName', '')} ${get(customer, 'lastName', '')}`

export const isYearOutOfRange = dobYear => {
  return (
    dobYear >= subYears(new Date(), 18).getFullYear() ||
    dobYear <= subYears(new Date(), 100).getFullYear()
  )
}

export const getCustomerLocale = customer =>
  get(customer, 'language', detectBrowserLanguageKey()).toLowerCase()
