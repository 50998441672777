import { makeSafeLocalStorage } from 'util/makeSafeStorage'
import localStorageKeys from 'constants/enums/localStorageKeys'
import accessModeKeys from 'constants/enums/accessModeKeys'

const safeLocalStorage = makeSafeLocalStorage()

// Gets the current access mode, defaults to LIVE if no record
export const getAccessMode = () =>
  safeLocalStorage.getItem(localStorageKeys.accessMode) || accessModeKeys.live

export const setAccessMode = accessMode => {
  safeLocalStorage.setItem(localStorageKeys.accessMode, accessMode)
}
