// @flow
import React, { useContext } from 'react'
import { get } from 'lodash'
import { Query } from '@apollo/client/react/components'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import customerStatuses from 'constants/enums/customerStatuses'
import { getCustomerIdFromProps } from 'util/NavigationHelper'
import TranslationsContext from 'util/TranslationsContext'
import Routes from 'util/Routes'
import Loadable from 'util/Loadable'
import GetCustomerStatus from 'queries/GetCustomerStatus.graphql'
import { applyIncomplete } from 'util/StageNavigationHelper'
import {
  dashboardHubAndSpokeFlagEnabled,
  coApplicantFormEnabled
} from 'util/FeatureHelper'
import { localizedTranslations } from 'properties/translations'
import { customerLanguageKey } from 'util/CustomerHelper'
import { userHasPermission } from 'util/UserHelper'
import permissionsEnum from 'constants/enums/permissions'
import UserContext from 'util/UserContext'
import { userTypesEnum } from 'constants/enums/userTypes'
import { GET_SETUP } from 'screens/setup/SetupStore'

const Dashboard = Loadable(import('screens/dashboard/Dashboard'))

const DashboardGuard = props => {
  const customerId = getCustomerIdFromProps(props)
  const { ldFlags } = props
  const userContext = useContext(UserContext)
  const { me } = userContext

  const redirectToCustomer = () => {
    props.history.replace(Routes.customer(customerId))
    return null
  }
  const redirectToApply = () => {
    props.history.replace(Routes.apply(customerId))
    return null
  }
  const redirectToSetup = () => {
    props.history.replace(Routes.setup(customerId))
    return null
  }
  if (!customerId) {
    return redirectToCustomer()
  }
  return (
    <Query query={GetCustomerStatus} variables={{ customerId }}>
      {({ data = {}, loading }) => {
        if (loading) return null
        return (
          <Query query={GET_SETUP}>
            {({ data: setupData = {}, loading }) => {
              if (loading) return null
              const status = get(
                data,
                'customer.status',
                customerStatuses.active
              )
              const customer = get(data, 'customer', {})
              const applyComplete = get(data, 'customer.applyComplete', false)
              const setupComplete = get(data, 'customer.setupComplete', false)
              const coApplicant = coApplicantFormEnabled(ldFlags, { customer })
              const languageKey = customerLanguageKey(customer)

              const l10n =
                me.userType === userTypesEnum.installer
                  ? localizedTranslations()
                  : localizedTranslations(languageKey)

              const allowACH = userHasPermission(
                props.me,
                permissionsEnum.EDIT_ACH_SETUP
              )

              if (status === customerStatuses.cancelled) {
                return redirectToCustomer()
              }

              const setupSkipped = get(setupData, 'setup.cancelled')
              if (coApplicant && !setupComplete && allowACH && !setupSkipped) {
                return redirectToSetup()
              }

              if (
                !dashboardHubAndSpokeFlagEnabled(ldFlags) &&
                applyIncomplete(customer)
              ) {
                return coApplicant ? redirectToSetup() : redirectToApply()
              }

              return (
                <TranslationsContext.Provider value={l10n}>
                  <Dashboard
                    {...props}
                    applyComplete={applyComplete}
                    setupComplete={setupComplete}
                  />
                </TranslationsContext.Provider>
              )
            }}
          </Query>
        )
      }}
    </Query>
  )
}

export default withLaunchDarkly(DashboardGuard)
