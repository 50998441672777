import { gql } from 'graphql.macro'

import GuestChannelPartnerResponseFields from 'queries/fragments/GuestChannelPartnerResponseFields.graphql'

export default gql`
  query ChannelPartnerGuest($channelPartnerId: String, $hostName: String) {
    channelPartner(channelPartnerId: $channelPartnerId, hostName: $hostName) {
      ...GuestChannelPartnerResponseFields
    }
  }

  ${GuestChannelPartnerResponseFields}
`
