// @flow
import accessModeKeys from 'constants/enums/accessModeKeys'
import userRoles from 'constants/enums/userRoles'
import { eventTypes } from 'constants/enums/analyticsEventTypes'
import portalPaths from 'constants/enums/portalPaths'
import userTypes from 'constants/enums/userTypes'
import { get } from 'lodash'
import l10n from 'properties/translations'
import Routes from 'util/Routes'
import { portalLink } from 'util/NavigationHelper'

export type AccessModes = String[]

export type Section = {
  allowedAccessModes: AccessModes
}

export type SidebarItem = {
  name: string,
  to: string,
  allowedUserTypes: string[],
  allowedUserRoles: string[],
  description: string,
  featureFlag?: string
}

const ALL_USER_TYPES = Object.values(userTypes)

const sidebarConfig = (partner: string, ldFlags: Object): SidebarItem[] => {
  const sidebarItems = [
    [
      {
        name: l10n.sidebarNav.start,
        to: Routes.start(),
        exact: true,
        allowedUserTypes: [userTypes.installer],
        analyticsEvent: eventTypes.sideBarStart,
        description: '',
        ariaLabel: l10n.sidebarNav.ariaLabels.start
      },
      {
        name: l10n.sidebarNav.pipeline,
        to: Routes.pipeline(),
        allowedUserTypes: [userTypes.installer],
        analyticsEvent: eventTypes.sideBarPipeline,
        description: '',
        ariaLabel: l10n.sidebarNav.ariaLabels.pipeline
      },
      {
        name: l10n.sidebarNav.admin,
        to: Routes.admin(),
        allowedUserTypes: [userTypes.installer],
        allowedUserRoles: [
          userRoles.installer.admin,
          userRoles.installer.owner
        ],
        analyticsEvent: eventTypes.sideBarAdmin,
        description: '',
        ariaLabel: l10n.sidebarNav.ariaLabels.resources
      },
      {
        name: l10n.sidebarNav.resources,
        to: Routes.resources(),
        allowedUserTypes: [userTypes.installer],
        description: '',
        ariaLabel: l10n.sidebarNav.ariaLabels.resources
      },
      {
        name: l10n.sidebarNav.portalBackOffice,
        to: portalLink(get(partner, 'hostName', ''), portalPaths.finance),
        allowedUserTypes: [userTypes.installer],
        allowedUserRoles: [
          userRoles.installer.admin,
          userRoles.installer.owner
        ],
        allowedAccessModes: [accessModeKeys.live],
        analyticsEvent: eventTypes.sideBarPipeline,
        isExternal: true,
        description: l10n.sidebarNav.backOfficeDescription,
        ariaLabel: l10n.sidebarNav.ariaLabels.backOffice
      },
      {
        name: l10n.sidebarNav.help,
        allowedUserTypes: ALL_USER_TYPES,
        description: '',
        ariaLabel: l10n.sidebarNav.ariaLabels.helpAndSupport
      },
      {
        name: l10n.sidebarNav.logout,
        to: Routes.logout(),
        allowedUserTypes: ALL_USER_TYPES,
        analyticsEvent: eventTypes.sideBarLogout,
        description: '',
        ariaLabel: l10n.sidebarNav.ariaLabels.logout
      }
    ]
  ]

  const filteredByFeatureFlag = sidebarItems[0].filter(
    item => !item.featureFlag || ldFlags[item.featureFlag]
  )

  return new Array(filteredByFeatureFlag)
}

export default sidebarConfig
