// @flow
import React from 'react'

export type ApplyContextType = {
  editOfferModalOpen: boolean,
  fieldset?: string
}

const defaultContext: ApplyContextType = {
  editOfferModalOpen: false,
  fieldset: undefined
}

export default React.createContext<ApplyContextType>(defaultContext)
