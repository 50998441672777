import { colors, pageTitle, bodyTextNormal } from 'themes/default'

export default theme => ({
  headerRow: {
    marginBottom: theme.spacing(1)
  },
  h1: {
    fontFamily: theme.typography.fontFamily,
    fontSize: theme.typography.fontSize * 2,
    color: colors.normalBlack
  },
  inactiveColor: {
    color: theme.palette.grey[900]
  },
  pageTitle: {
    fontWeight: pageTitle.fontWeight,
    fontSize: pageTitle.fontSize,
    color: pageTitle.color
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  body1: {
    fontSize: theme.typography.fontSize + 2,
    fontWeight: 400,
    color: colors.normalBlack
  }
})
