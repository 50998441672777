import { gql } from 'graphql.macro'

export default gql`
  fragment GuestChannelPartnerResponseFields on ChannelPartnerResponse {
    id
    name
    hostName
    theme {
      primaryColor
      secondaryColor
      headerColor
    }
    status
    bundleId
    flags
    disclosures {
      assetCode
      version
    }
  }
`
