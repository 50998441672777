import React from 'react'
import { getIn } from 'formik'
import { TextField } from '@material-ui/core'

const fieldToTextField = ({ field, form, disabled = false, ...props }) => {
  const { name, onBlur } = field
  const { touched, errors, isSubmitting } = form

  const fieldError = getIn(errors, name)
  const showError = getIn(touched, name) && !!fieldError

  return {
    ...props,
    onBlur,
    name,
    error: showError,
    helperText: showError ? fieldError : props.helperText,
    disabled: isSubmitting || disabled
  }
}

const FormikNumberFormat = props => <TextField {...fieldToTextField(props)} />

export default FormikNumberFormat
