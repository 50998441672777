// @flow

const accessModeKeys = Object.freeze({
  demo: 'DEMO',
  live: 'LIVE'
})

export type AccessMode = $Values<typeof accessModeKeys>

export default accessModeKeys
