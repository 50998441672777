// @flow

const setupOptionTypes = Object.freeze({
  account: 'ACCOUNT',
  bank: 'BANK',
  team: 'TEAM',
  paymentPlan: 'PAYMENT_PLAN',
  projectTypes: 'PROJECT_TYPES'
})

export type SetupOptionType = $Values<typeof setupOptionTypes>

export default setupOptionTypes
