import { gql } from 'graphql.macro'
import SalespersonFields from 'queries/fragments/SalespersonFields.graphql'

export default gql`
  query GetCustomerRep($customerId: String!) {
    customer(customerId: $customerId) {
      id

      salesperson {
        ...SalespersonFields
      }
    }
  }
  ${SalespersonFields}
`
