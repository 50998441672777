export default theme => ({
  buttonLink: {
    color: theme.palette.action.link,
    fontFamily: theme.typography.fontFamily,
    fontSize: '16px',
    cursor: 'pointer',
    marginTop: '-3px'
  },
  closeButton: { fontWeight: 500 },
  proceedText: { fontSize: '16px', lineHeight: 1.5 },
  dialogTitle: { paddingBottom: 0, paddingTop: theme.spacing(3) },
  titleText: { fontSize: '22px', fontWeight: 300 },
  loadingContainer: { display: 'flex', minHeight: 380 }
})
