/* eslint-disable react/jsx-boolean-value */
// @flow
import * as React from 'react'
import {
  Grid,
  Typography,
  InputAdornment,
  NativeSelect
} from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import l10n from 'properties/translations'
import { employmentStatusList } from 'util/FoundationFormHelper'
import { PhoneFormat, MonthWorkFormat } from '../../NumberFormatHelper'

type Props = {
  classes: Object,
  handleFormState: string,
  coAppEmploymentStatus: string,
  coAppEmployerName: string,
  coAppEmployerPhoneNumber: number,
  coAppMonthlyIncome: number,
  coAppJobTitle: string,
  coAppPeriodOfEmploymentMonths: number
}

const CoApplicantEmployment = (props: Props) => {
  const {
    classes,
    handleFormState,
    coAppEmploymentStatus,
    coAppEmployerName,
    coAppEmployerPhoneNumber,
    coAppMonthlyIncome,
    coAppJobTitle,
    coAppPeriodOfEmploymentMonths
  } = props
  return (
    <>
      <Grid container className={classes.secondSectionGrid}>
        <Typography className={classes.sectionTitleTextStyle}>
          {l10n.foundation.applicationContent.coApplicantWorkLabel}
        </Typography>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            {l10n.foundation.applicationContent.employmentStatusLabel}
            <br />
            <NativeSelect
              fullWidth
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .coAppEmploymentStatus
              }
              value={coAppEmploymentStatus}
              required={true}
              className={classes.nativeSelectDropStyle}
            >
              {employmentStatusList}
            </NativeSelect>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.employerNameStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.employerNameLabel}
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames.coAppEmployerName
              }
              value={coAppEmployerName}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .coAppEmployerNameRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidEmployer
              ]}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.employerPhoneStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={
                l10n.foundation.applicationContent.coAppEmployerPhoneNumberLabel
              }
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .coAppEmployerPhoneNumber
              }
              value={coAppEmployerPhoneNumber}
              validators={[
                l10n.foundation.applicationContent.errorMessages.coAppPhoneRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.errorMessages.invalidPhone
              ]}
              InputProps={{ inputComponent: PhoneFormat }}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={`${classes.gridItemStyle} ${classes.fieldTitleStyle}`}
        >
          <TextValidator
            fullWidth
            onChange={handleFormState}
            name={
              l10n.foundation.applicationContent.fieldNames.coAppMonthlyIncome
            }
            value={coAppMonthlyIncome}
            label={l10n.foundation.applicationContent.monthlyIncomeLabel}
            validators={[
              l10n.foundation.applicationContent.errorMessages.required,
              l10n.foundation.applicationContent.errorMessages
                .coAppMonthlyIncomeRegex
            ]}
            errorMessages={[
              l10n.foundation.applicationContent.fieldRequiredLabel,
              l10n.foundation.applicationContent.errorMessages.invalidAmount
            ]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
            type="tel"
          />
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.coAppJobTitleStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.occupationTitleLabel}
              onChange={handleFormState}
              name={l10n.foundation.applicationContent.fieldNames.coAppJobTitle}
              value={coAppJobTitle}
              className={classes.coAppJobtitleStyle}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .coAppJobTitleRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidJobtitle
              ]}
            />
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          sm={6}
          className={`${classes.gridItemStyle} ${classes.fieldTitleStyle}`}
        >
          <TextValidator
            fullWidth
            onChange={handleFormState}
            name={
              l10n.foundation.applicationContent.fieldNames
                .coAppPeriodOfEmploymentMonths
            }
            value={coAppPeriodOfEmploymentMonths}
            label={
              l10n.foundation.applicationContent.coAppPeriodOfEmploymentMonths
            }
            validators={[
              l10n.foundation.applicationContent.errorMessages.required,
              l10n.foundation.applicationContent.errorMessages
                .coAppEmploymentPeriodRegex
            ]}
            errorMessages={[
              l10n.foundation.applicationContent.fieldRequiredLabel,
              l10n.foundation.applicationContent.errorMessages.invalidMonth
            ]}
            InputProps={{ inputComponent: MonthWorkFormat }}
            type="tel"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CoApplicantEmployment
