import { gql } from 'graphql.macro'

export default gql`
  query customer($partnerId: String!) {
    partnerLogin(id: $partnerId) {
      firstName
      lastName
      emailAddress
      phoneNumber
      role
    }
  }
`
