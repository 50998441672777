// @flow
import React, { Component } from 'react'
import { Mutation } from '@apollo/client/react/components'
import { Typography, Grid, Button, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import noop from 'lodash/noop'
import Dialog from 'components/shared/Dialog'
import l10n from 'properties/translations'
import coreScreenStyles from 'themes/coreScreenStyles'
import { CIRCULAR_PROGRESS_SIZE } from 'properties/properties'
import CancelContract from '../queries/CancelContract.graphql'

interface Props {
  isChangeOrder: ?boolean; // TODO: Remove when change order follows same edit offer flow
  open: boolean;
  customerId: string;
  title: ?string;
  confirmButtonTitle: ?string;
  body: ?string;
  onClose: Function;
  onCancelContractSuccess: Function;
  classes: Object;
}

interface State {
  isSubmitting: boolean;
}

class CancelContractDialog extends Component<Props, State> {
  static defaultProps = {
    title: l10n.apply.summary.requestChangeOrder,
    confirmButtonTitle: l10n.apply.summary.confirm,
    body: l10n.apply.summary.changeOrderText,
    onCancelContractSuccess: noop,
    isChangeOrder: false
  }

  state = {
    isSubmitting: false
  }

  // TODO: Remove this when Create Change Order no longer needs to cancel the contract
  handleChangeOrder = () => async () => {
    const { onCancelContractSuccess } = this.props
    onCancelContractSuccess()
  }

  render() {
    const {
      open,
      title,
      confirmButtonTitle,
      body,
      onClose,
      customerId,
      onCancelContractSuccess,
      isChangeOrder,
      classes
    } = this.props
    const { isSubmitting } = this.state

    return (
      <Dialog
        open={open}
        title={title}
        onClose={onClose}
        onBackdropClick={onClose}
      >
        <>
          <Typography variant="body2" className={classes.bodyTextNormal}>
            {body}
          </Typography>
          <Grid container justify="center">
            <Grid item>
              <Grid container direction="column" alignItems="center">
                <Button
                  variant="outlined"
                  fullWidth
                  color="primary"
                  onClick={onClose}
                >
                  <Typography>{l10n.apply.summary.cancel}</Typography>
                </Button>{' '}
                <Mutation mutation={CancelContract}>
                  {cancelContract => (
                    <Button
                      variant="contained"
                      color="primary"
                      fullWidth
                      disabled={isSubmitting}
                      onClick={
                        isChangeOrder
                          ? this.handleChangeOrder({
                              cancelContract,
                              customerId
                            })
                          : onCancelContractSuccess
                      }
                    >
                      {isSubmitting ? (
                        <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
                      ) : (
                        <Typography>{confirmButtonTitle}</Typography>
                      )}
                    </Button>
                  )}
                </Mutation>
              </Grid>
            </Grid>
          </Grid>
        </>
      </Dialog>
    )
  }
}

export default withStyles(coreScreenStyles)(CancelContractDialog)
