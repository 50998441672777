import { makeStyles } from '@material-ui/core/styles'
import coreScreenStyles from 'themes/coreScreenStyles'

const useStyles = makeStyles(theme => ({
  ...coreScreenStyles(theme),
  pageContainer: {
    paddingTop: theme.spacing(3),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(7)
    }
  },
  pageNarrowContainer: {
    paddingTop: theme.spacing(5),
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(4.5),
    paddingLeft: theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(7)
    }
  },
  pagePrequalificationFormContainer: {
    paddingTop: 0,
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    maxWidth: theme.spacing(118),
    margin: '0 auto',
    '@media (max-width: 600px)': {
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1)
    }
  },
  pageRoot: {
    position: 'relative',
    display: 'flex',
    margin: theme.spacing(1),
    borderRadius: 4,
    maxWidth: theme.breakpoints.values.md - theme.spacing(2),
    minHeight: 300,
    [theme.breakpoints.up('md')]: {
      marginRight: 'auto',
      marginLeft: 'auto'
    }
  },
  paperStageNavBack: {
    position: 'relative',
    margin: theme.spacing(1.5),
    borderRadius: 4,
    maxWidth: theme.breakpoints.values.md - theme.spacing(2),
    minHeight: 300,
    [theme.breakpoints.up('md')]: {
      marginRight: 'auto',
      marginLeft: 'auto'
    }
  },
  boxStageNavBack: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(6),
    paddingLeft: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(4),
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
      paddingBottom: theme.spacing(7)
    }
  },
  flex: {
    flex: 1
  },
  stageNavBackPadTop: {
    marginTop: theme.spacing(1)
  }
}))

export default useStyles
