import { gql } from 'graphql.macro'

export default gql`
  fragment SampleTermsResponseFields on ContractTermsResponse {
    apr
    paymentFactor
    monthlyPayment
    aprPromo
    numMonthlyPayments
    numMonthlyPaymentsPromo
    monthlyPaymentPromo
  }
`
