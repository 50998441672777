// @flow
import { Container } from 'unstated'
import accessModeKeys from 'constants/enums/accessModeKeys'
import { setAccessMode, getAccessMode } from 'util/AccessModeHelper'
import { DEBOUNCE_TIME } from 'properties/properties'

export interface IAccessModeContainer {
  setMode: (accessMode: string) => Promise<void>;
  setModeLater: (accessMode: string) => void;
  demoMode: Boolean;
}

interface State {
  accessMode: string;
}

export default class AccessModeContainer extends Container<State> {
  state = {
    accessMode: getAccessMode()
  }

  setMode = async (accessMode: string) =>
    new Promise(resolve => {
      this.setState({ accessMode }, () => {
        setAccessMode(accessMode)
        resolve()
      })
    })

  // You can call this variant if you do not care about waiting for the accessmode to be set
  setModeLater = (accessMode: string) =>
    setTimeout(() => {
      this.setState({ accessMode }, () => {
        setAccessMode(accessMode)
      })
    }, DEBOUNCE_TIME)

  get demoMode() {
    const { accessMode } = this.state
    return accessMode === accessModeKeys.demo
  }
}
