// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Avatar, Link, Typography } from '@material-ui/core'
import PersonIcon from '@material-ui/icons/Person'

type Props = {
  classes: {
    avatar: string,
    role: string,
    icon: string
  },
  srcImage: string,
  name: string,
  role: string,
  email: string,
  phone: string
}

const styles = () => ({
  avatar: {
    margin: 10,
    height: 60,
    width: 60
  },
  icon: {
    fontSize: 50
  },
  role: {
    textTransform: 'uppercase'
  }
})

const ManagerCard = (props: Props) => {
  const { classes, name, email, phone } = props
  return (
    <>
      <Avatar
        alt={name}
        // TODO: Uncomment when dynamic contact info is implemented. src={srcImage}
        className={classes.avatar}
      >
        <PersonIcon className={classes.icon} />
      </Avatar>
      <Typography variant="body1">{name}</Typography>
      {/* TODO: Remove as needed. Using a general contact number for now. */}
      {/* <Typography variant="caption" className={classes.role}>
        {role}
      </Typography> */}
      <Typography align="center">
        <Link href={`mailto:${email}`} variant="body2" block>
          {email}
        </Link>
        <br />
        <Link href={`tel:${phone}`} variant="body2" block>
          {phone}
        </Link>
      </Typography>
    </>
  )
}

export default withStyles(styles)(ManagerCard)
