// @flow
import React, { useState } from 'react'
import { Query } from '@apollo/client/react/components'
import { omit, noop, isFunction } from 'lodash'

type Props = {
  runQuery: { call: Function } | Function,
  children: Function
}

const OptionalQuery = (props: Props) => {
  const [forceRerenderBit, setRerender] = useState(0)
  const toggleRerender = () => setRerender(forceRerenderBit ? 0 : 1)
  const shouldRunQuery = isFunction(props.runQuery)
    ? props.runQuery.call()
    : props.runQuery
  return shouldRunQuery ? (
    <Query {...omit(props, ['runQuery'])} />
  ) : (
    props.children({
      loading: false,
      // intentionally leaving data null so defaultprop declaration takes over
      error: null,
      startPolling: noop,
      stopPolling: noop,
      refetch: toggleRerender,
      called: false
    })
  )
}

OptionalQuery.defaultProps = {
  runQuery: true
}

export default OptionalQuery
