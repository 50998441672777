// @flow
import React from 'react'
import { isNil } from 'lodash'
import NumberFormat from 'react-number-format'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'

type Props = {
  onChange: Function,
  ldFlags: Object,
  ldClient: any,
  inputRef: any
}

const NumberFormatCustom = ({
  onChange,
  ldFlags,
  ldClient, // omit ldClient and inputRef from rest operator
  inputRef,
  ...props
}: Props) => (
  <NumberFormat
    {...props}
    decimalScale={2}
    fixedDecimalScale
    thousandSeparator
    isAllowed={values => {
      const { floatValue, value } = values
      if (value === '-') return false
      if (isNil(floatValue)) return true
      return floatValue >= 0
    }}
    onValueChange={values => {
      onChange({
        target: {
          value: values.value
        }
      })
    }}
  />
)

export default withLaunchDarkly(NumberFormatCustom)
