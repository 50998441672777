// @flow
import * as React from 'react'
import { isEmpty } from 'lodash'

type Props = {
  children: React.Node,
  fieldset?: string,
  fieldsets?: Array<string>,
  activeFieldset?: string
}

const Fieldset = ({
  fieldset,
  activeFieldset,
  children,
  fieldsets = []
}: Props) => {
  if (!isEmpty(fieldsets) && fieldsets.includes(activeFieldset)) {
    return children
  }
  return fieldset === activeFieldset ? children : null
}

export default Fieldset
