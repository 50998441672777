import { bodyTextNormal } from 'themes/default'

export default theme => ({
  confirmContainer: {
    paddingTop: theme.spacing(6)
  },
  confirmText: {
    padding: theme.spacing(1),
    wordBreak: 'break-word'
  },
  list: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  listItemText: {
    paddingLeft: 0,
    paddingRight: 0,
    fontWeight: theme.typography.fontWeightBold
  },
  listItemTextInvalid: {
    color: theme.palette.error.main
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
    display: 'inline',
    wordBreak: 'break-word'
  },
  iconHidden: {
    visibility: 'hidden'
  },
  btnCtn: {
    '& button': {
      minWidth: theme.spacing(25),
      maxWidth: theme.spacing(35),
      [theme.breakpoints.up('md')]: {
        width: theme.spacing(35)
      }
    },
    '& button:first-child': {
      marginTop: theme.spacing(2)
    },
    '& button:last-child': {
      marginBottom: theme.spacing(2)
    }
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  listRoot: {
    padding: 0
  }
})
