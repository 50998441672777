/* eslint-disable no-undef */
import React from 'react'
import { includes, head, nth } from 'lodash'
import { handledErrorTypes } from 'properties/properties'
import l10n from 'properties/translations'

const MessageCustom = ({ text }) => {
  const errorType = nth(text, 2)
  if (includes(handledErrorTypes, errorType))
    return <>{l10n.serverError[errorType]}</>
  return <>{head(text)}</>
}

export default MessageCustom
