import { gql } from 'graphql.macro'

export default gql`
  query getEsignUrl($id: String!, $eSignCallbackUrl: URL!) {
    customer(customerId: $id) {
      id
      contract {
        eSignUrl(callbackUrl: $eSignCallbackUrl)
      }
    }
  }
`
