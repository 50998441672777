// flow
import React, { Component } from 'react'
import { get, flow } from 'lodash'
import { withRouter } from 'react-router-dom'
import { withStyles } from '@material-ui/core/styles'
import { Dialog, Slide } from '@material-ui/core'
import { Query } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import Loading from 'components/shared/Loading'
import MobileInfo from 'util/MobileInfo'
import customerAndMeToFoundation from './query/customerAndMeToFoundation.graphql'
import ApplicationContent from './components/ApplicationContent'
import ApplicationContainer from './ApplicationContainer'
import DecisionContainer from './DecisionContainer'

interface Props {
  customerId: string;
  open: Boolean;
  client: Mutation;
  ldFlags: object;
  history: object;
  classes: any;
}

function Transition(props) {
  return <Slide direction="up" {...props} />
}

const styles = {
  root: {
    backgroundColor: '#31903b'
  },
  dialogMargins: {
    top: '5px',
    right: '5px',
    left: '5px',
    bottom: '5px'
  }
}

class Applicationform extends Component<Props> {
  state = {
    open: this.props.open,
    success: false
  }

  componentDidUpdate() {
    /* Smooth scroll to top */
    this.dialogContent.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }

  updateForm = value => {
    this.setState({ success: value })
  }

  handleClose = () => {
    window.location.reload()
    this.setState({ open: false })
  }

  render() {
    const { success: successForm } = this.state
    const { isMobile } = MobileInfo.getMobileInfo()

    const { classes, customerId, client } = this.props
    return (
      <>
        <Dialog
          fullScreen
          open={this.state.open}
          onClose={this.handleClose}
          TransitionComponent={Transition}
          className={classes.dialogMargins}
          disableBackdropClick
          BackdropProps={{
            classes: {
              root: classes.root
            }
          }}
        >
          <div
            ref={node => {
              this.dialogContent = node
            }}
          >
            <Query
              query={customerAndMeToFoundation}
              variables={{ customerId }}
              fetchPolicy="network-only"
            >
              {({ data: { customer, me }, loading }) => {
                if (loading) return <Loading />
                const alternativeFinancing =
                  customer.creditApplicationAlternativeFinancing
                const { userType } = me
                const repData = customer.salesperson
                return (
                  <ApplicationContainer isMobile={isMobile} classes={classes}>
                    {!alternativeFinancing && !successForm ? (
                      <ApplicationContent
                        client={client}
                        handleClose={this.handleClose}
                        customerId={customerId}
                        updateForm={this.updateForm}
                      />
                    ) : (
                      <DecisionContainer
                        client={client}
                        applicationState={get(
                          alternativeFinancing,
                          'status',
                          undefined
                        )}
                        userType={userType}
                        repData={repData}
                        customerId={customerId}
                        history={this.props.history}
                      />
                    )}
                  </ApplicationContainer>
                )
              }}
            </Query>
          </div>
        </Dialog>
      </>
    )
  }
}

export default flow(
  withApolloConsumer,
  withRouter,
  withStyles(styles)
)(Applicationform)
