// @flow
import React, { useEffect } from 'react'
import filter from 'lodash/filter'
import flow from 'lodash/flow'
import { Grid, Typography } from '@material-ui/core'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { Query } from '@apollo/client/react/components'
import ScreenTitle from 'components/shared/ScreenTitle'
import Page from 'components/templates/Page'
import PageNav from 'components/shared/PageNav'
import Routes from 'util/Routes'
import l10n from 'properties/translations'
import Error from 'components/shared/Error'
import Loading from 'components/shared/Loading'
import demoLoanProductKeys from 'constants/enums/demoLoanProductKeys'
import { getProducts } from 'util/LoanProductHelper'
import stylesDefault from '../Admin.styles'
import ManagerCard from './ManagerCard'
import LoanProductsQuery from '../queries/LoanProductsQuery.graphql'
import { redirectToResiPortal } from 'util/SessionHelper'

const { product } = l10n.admin

type Props = {
  me: Object,
  channelPartner: Object,
  classes: {
    redSubtitle: string,
    pageTitle: string,
    bodyTextNormal: string
  }
}

const styles = theme => ({
  ...stylesDefault(theme),
  redSubtitle: {
    color: theme.palette.error.main
  },
  backButton: {
    paddingLeft: '0px'
  }
})

const mockManager = {
  srcImage: 'https://i.imgur.com/Xh1RZRP.jpg',
  name: 'Account Management Support',
  role: 'Account Manager',
  email: 'AccountSupport@joinmosaic.com',
  phone: '1 (855) 832-1234'
}

const AdminProducts = (props: Props) => {
  const { classes, me, channelPartner } = props
  useEffect(() => {
    redirectToResiPortal(
      me?.isSalesforceIdentityEnabled,
      '/admin/branches',
      channelPartner
    )
  }, [me, channelPartner])

  return (
    <Query query={LoanProductsQuery}>
      {({ loading, error, data }) => {
        if (error) return <Error />
        if (loading || !data) return <Loading />
        const products = getProducts(data)
        const hasProducts = products.length > 0

        const hasDemoProducts =
          hasProducts &&
          filter(
            products,
            obj =>
              obj.displayName.includes(`[${demoLoanProductKeys.bank}]`) ||
              obj.displayName.includes(`[${demoLoanProductKeys.lender}]`)
          ).length > 0

        return (
          <Page showBackButton>
            <ScreenTitle title={l10n.screenTitles.adminProducts} />
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              spacing={1}
            >
              <PageNav backLink={Routes.admin()} />
              <Grid item>
                <Typography
                  className={classes.pageTitle}
                  align="left"
                  variant="h1"
                  paragraph
                  data-testid="admin-products-title"
                >
                  {product.title}
                </Typography>
                <Typography
                  align="left"
                  variant="body2"
                  paragraph
                  data-testid="admin-products-subtitle"
                  className={classes.bodyTextNormal}
                >
                  {hasProducts && !hasDemoProducts ? (
                    product.description
                  ) : (
                    <>
                      <span className={classes.redSubtitle}>
                        {product.redSuggest}
                      </span>
                      {product.suggest}
                    </>
                  )}
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="center"
              >
                <ManagerCard {...mockManager} />
              </Grid>
            </Grid>
          </Page>
        )
      }}
    </Query>
  )
}
export default flow(
  withRouter,
  withLaunchDarkly,
  withStyles(styles)
)(AdminProducts)
