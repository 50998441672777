import React from 'react'
import merge from 'lodash.merge'
import isEmpty from 'lodash.isempty'
import { I18nProviderProps } from '../typedefs.types.i18n-0.7.2'

import { I18nApplyContext } from './i18n-apply-context'

import translations from './translations'
import { getByLocale, translationByPath } from './utils'

export function I18nApplyProvider({
  locale,
  locales = [],
  translations: extraTranslations = {},
  children
}: I18nProviderProps) {
  let i18n = getByLocale(locale)
  for (locale of locales) {
    if (translations[locale]) {
      i18n = translations[locale]
      break
    }
  }

  const mergedI18n = merge({}, i18n, extraTranslations)

  const contextValue = {
    i18n: mergedI18n,
    t: translationByPath(mergedI18n),
    locales: !isEmpty(locales) ? locales : [locale]
  }
  return (
    <I18nApplyContext.Provider value={contextValue}>
      {children}
    </I18nApplyContext.Provider>
  )
}

export const I18nApplyBrowserProvider = props => {
  const locales =
    'languages' in navigator
      ? navigator.languages.map(lang => lang.replace(/-/, ''))
      : ['enUS', 'en']
  return <I18nApplyProvider locales={locales} {...props} />
}
