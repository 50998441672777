import Cookies from 'js-cookie'
import { DEFAULT_COOKIE_OPTIONS } from 'properties/properties'

const PREFIX_SESSION = 'safeSessionStorage__'
const PREFIX_LOCAL = 'safeLocalStorage__'

export const makeSafeSessionStorage = (prefix = PREFIX_SESSION) => {
  try {
    if (window.sessionStorage) {
      return window.sessionStorage
    }
  } catch (e) {
    return {
      getItem: key => Cookies.get(`${prefix}${key}`),
      setItem: (key, value, options = DEFAULT_COOKIE_OPTIONS) =>
        Cookies.set(`${prefix}${key}`, value, options),
      removeItem: key => Cookies.remove(key)
    }
  }
  return window.sessionStorage
}

export const makeSafeLocalStorage = (prefix = PREFIX_LOCAL) => {
  try {
    if (window.localStorage) {
      return window.localStorage
    }
  } catch (e) {
    return {
      getItem: key => Cookies.get(`${prefix}${key}`),
      setItem: (key, value, options = DEFAULT_COOKIE_OPTIONS) =>
        Cookies.set(`${prefix}${key}`, value, options),
      removeItem: key => Cookies.remove(key)
    }
  }
  return window.localStorage
}

export default { makeSafeSessionStorage, makeSafeLocalStorage }
