const deviceVerificationStatusTypes = {
  pending: 'PENDING',
  expired: 'EXPIRED',
  verified: 'VERIFIED',
  optedOut: 'OPTED_OUT',
  authenticated: 'AUTHENTICATED',
  unauthenticated: 'UNAUTHENTICATED',
  internalError: 'INTERNAL_ERROR'
}

export type DeviceVerificationStatusTypes = $Values<
  typeof deviceVerificationStatusTypes
>

export default deviceVerificationStatusTypes
