import React, { useContext } from 'react'
import disclosureAssetTypes from 'constants/enums/disclosureAssetTypes'
import DisclosurePackageContext from 'util/DisclosurePackageContext'
import useFile from 'util/UseFileHook'
import Loading from 'components/shared/Loading'
import Markdown, { defaultOverrides } from 'components/shared/Markdown'

const options = {
  overrides: defaultOverrides
}

const DeviceVerificationDisclosures = ({ children, classes = {} }) => {
  const { disclosureFileForAssetType } = useContext(DisclosurePackageContext)
  const { loading, text: disclosure } = useFile(
    disclosureFileForAssetType(disclosureAssetTypes.sms)
  )
  return (
    <div className={classes.container}>
      {loading || !disclosure ? (
        <Loading block hideText />
      ) : (
        <Markdown options={options}>{disclosure}</Markdown>
      )}
    </div>
  )
}

export default DeviceVerificationDisclosures
