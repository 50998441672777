//@flow
import React from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { get } from 'lodash'
import type {
  BrowserHistory,
  HashHistory,
  MemoryHistory,
  Location
} from 'history'
import cx from 'classnames'
import l10n from 'properties/translations'
import Page from 'components/templates/Page'
import coreScreenStyles from 'themes/coreScreenStyles'

const styles = theme => ({
  ...coreScreenStyles(theme),
  marginBottom: {
    display: 'block',
    marginBottom: theme.spacing(2)
  },
  titleHeader: {
    fontWeight: '300',
    fontSize: '1.30rem'
  },
  okButton: {
    textDecoration: 'none'
  },
  displayEmailAlign: {
    wordBreak: 'break-word'
  }
})

type Props = {
  classes: {
    marginBottom: string,
    marginTop: string,
    titleHeader: string,
    marginBottom: string,
    marginButton: string,
    okButton: string,
    displayEmailAlign: string
  },
  history: BrowserHistory | HashHistory | MemoryHistory,
  location: Location
}

const changePage = (history: Object) => () => {
  history.push('/login')
}

const ForgotPassword = ({ classes, history, location }: Props) => {
  const email = get(location, 'state.email')

  return (
    <Page variant="narrowLogo">
      <Grid
        container
        justify="flex-start"
        alignItems="flex-start"
        className={cx(classes.marginBottom, classes.marginTop)}
      >
        <Grid item>
          <Typography
            data-testid="screen-title"
            variant="subtitle1"
            align="left"
            className={classes.titleHeader}
          >
            {l10n.forgotPassword.title}
          </Typography>
        </Grid>
        <Grid item />
      </Grid>
      <Grid
        container
        justify="flex-start"
        alignItems="flex-start"
        className={classes.marginBottom}
      >
        <Grid item>
          <Typography
            variant="subtitle1"
            align="left"
            gutterBottom
            className={classes.displayEmailAlign}
          >
            {l10n.forgotPassword.descriptionEmail(email)}
          </Typography>
          <Typography variant="subtitle1" align="left" gutterBottom>
            {l10n.forgotPassword.descriptionEmailCheckSpamFolder}
          </Typography>
        </Grid>
      </Grid>
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item className={classes.marginButton}>
          <Button
            variant="contained"
            color="primary"
            onClick={changePage(history)}
          >
            <Typography>{l10n.notFound.login}</Typography>
          </Button>
        </Grid>
      </Grid>
    </Page>
  )
}

export default withStyles(styles)(ForgotPassword)
