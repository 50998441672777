import React, { useContext } from 'react'
import { I18nApplyContext } from '../apply.utils.i18n-apply-context-2.3.0'
import {
  SXContainerFlexbox,
  SXProcessingCircularProgress,
  SXProcessingTypography
} from './processing.styled'

export type ProcessingProps = {
  /*
   * A text to show under the loading spinner
   */
  text?: string
}

export function Processing(props: ProcessingProps) {
  const { t } = useContext(I18nApplyContext)
  const { text = t('shared.processing') } = props

  return (
    <SXContainerFlexbox
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <SXProcessingCircularProgress size={64} />
      <SXProcessingTypography>{text}</SXProcessingTypography>
    </SXContainerFlexbox>
  )
}
