export default theme => ({
  icon: {
    color: theme.palette.primary.main
  },
  iconDisabled: {
    color: theme.palette.grey.A200
  },
  iconButton: {
    padding: 0
  }
})
