// @flow
import React from 'react'
import { Typography, Box } from '@material-ui/core'
import customerFields from 'constants/enums/customerFields'
import l10n from 'properties/translations'
import cx from 'classnames'
import NumberFormat from 'react-number-format'
import fieldMasks from 'constants/enums/fieldMasks'
import styles from 'screens/apply/AddressVerification.styles'
import { withStyles } from '@material-ui/core/styles'

type Props = {
  userNameComplete: string,
  email: string,
  phoneNumber: string,
  classes: any
}

const CustomerInfo = ({
  userNameComplete,
  email,
  phoneNumber,
  classes
}: Props) => (
  <Box>
    <Typography variant="body2" className={classes.bodyTextNormal}>
      {l10n.apply.addressVerification.validNameText}
    </Typography>
    <Typography>
      <Box component="span" className={classes.informationText}>
        {userNameComplete}
      </Box>
    </Typography>

    <Typography
      variant="body2"
      className={cx(classes.informationContainer, classes.bodyTextNormal)}
    >
      {l10n.apply.addressVerification.validEmailText}
    </Typography>
    <Typography>
      <Box component="span" className={classes.informationText}>
        {email}
      </Box>
    </Typography>

    <Typography
      variant="body2"
      className={cx(classes.informationContainer, classes.bodyTextNormal)}
    >
      {l10n.apply.addressVerification.validPhoneNumberText}
    </Typography>
    <Typography>
      <Box component="span" className={classes.informationText}>
        <NumberFormat
          format={fieldMasks[customerFields.phoneNumber]}
          value={phoneNumber}
          displayType="text"
        />
      </Box>
    </Typography>
  </Box>
)
export default withStyles(styles)(CustomerInfo)
