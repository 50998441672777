import { gql } from 'graphql.macro'

export default gql`
  query getBankAccount {
    channelPartner {
      id
      bankAccount {
        accountNumber
        routingNumber
        accountType
        status
        bank {
          name
          address
        }
      }
    }
  }
`
