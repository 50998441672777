import { bodyTextNormal, colors } from 'themes/default'

export default theme => ({
  subheading: {
    maxWidth: 340,
    marginTop: theme.spacing(4)
  },
  marginHeader: {
    marginBottom: theme.spacing(4)
  },
  titleHeader: {
    fontWeight: theme.typography.fontWeightRegular
  },
  centerItemsWrapper: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4)
  },
  doneButton: {
    width: 150
  },
  icon: {
    width: 45,
    height: 45,
    color: theme.palette.grey['600']
  },
  iconGray: {
    color: theme.palette.grey['500'],
    width: 28,
    height: 28,
    marginTop: 3
  },
  iconWrapperGray: {
    marginBottom: theme.spacing(2),
    backgroundColor: '#efefef',
    width: 70,
    height: 70,
    borderRadius: 35
  },
  iconSuccess: {
    color: theme.palette.iconSuccess,
    width: 28,
    height: 28,
    marginTop: 3
  },
  iconSuccessContainer: {
    backgroundColor: theme.palette.iconSuccessContainer,
    width: 70,
    height: 70,
    borderRadius: 35,
    marginBottom: theme.spacing(2)
  },
  successDescription: {
    color: theme.palette.common.successGreen
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  wrapWhenNecessaryText: {
    display: 'inline-block'
  },
  partnerTextColor: {
    color: colors.extraDarkGray
  },
  itemBold: {
    fontWeight: theme.typography.fontWeightBold
  },
  itemBoldWordBreak: {
    fontWeight: theme.typography.fontWeightBold,
    wordBreak: 'break-all'
  }
})
