import { gql } from 'graphql.macro'
import StageResponseFields from 'queries/fragments/StageResponseFields.graphql'
import ContractTermsFragment from 'queries/fragments/ContractTermsFragment.graphql'

export default gql`
  fragment ContractFragment on ContractResponse {
    achEnabled
    status
    loanProduct {
      id
      servicePlanAllowed
      promoPeriod
      loanProductType
      interestRate
      interestRateDiscounted
    }
    terms {
      ...ContractTermsFragment
    }
    projects {
      projectType
      projectName
      stages {
        ...StageResponseFields
      }
    }
    isOkayToCancel
    contractProjectSummary {
      code
      addOnCount
      addOnRate
      monthlyTotalCost
    }
    lender {
      lender
      displayInTila
    }
    signator(signatoryRole: PRIMARY_APPLICANT) {
      dateSigned
      signator
    }
  }
  ${ContractTermsFragment}
  ${StageResponseFields}
`
