const MuiButton = {
  root: {
    minHeight: '40px',
    borderRadius: '20px',
    margin: '8px',
    minWidth: '6.625rem'
  },
  label: {
    '& p': {
      color: 'inherit',
      opacity: '0.9'
    }
  },
  outlinedPrimary: {
    boxShadow: 'none'
  },
  containedPrimary: {
    boxShadow: 'none'
  },
  sizeLarge: {
    padding: '0 32px',
    height: '48px',
    borderRadius: '24px',
    minWidth: '10rem',
    boxShadow: 'none'
  },
  sizeSmall: {
    minWidth: '5.25rem',
    '& .MuiButton-label': {
      fontSize: '13px',
      fontWeight: 700
    }
  }
}
export default MuiButton
