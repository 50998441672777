// @flow
import React, { useContext, useState } from 'react'
import { noop, isArray, identity, flow } from 'lodash'
import { Button, Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import isEmpty from 'lodash/isEmpty'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import projectTypesId from 'constants/enums/projectTypeId'
import QuoteContext, { addProjectButtonPressed } from 'util/QuoteContext'
import type {
  Project,
  ChannelPartnerProjectConfiguration,
  CustomerProjectConfiguration
} from 'screens/quote/QuoteTypes'
import { calculateErrorForStage } from 'util/ProjectRowHelper'
import {
  EMPTY_STRING,
  DEFAULT_MAX_PROJECTS_ALLOWED
} from 'properties/properties'
import l10n from 'properties/translations'
import { projectTypeToId } from 'constants/enums/projectTypes'
import ProjectRow from './ProjectRow'
import projectsStyles from './Projects.styles'

const styles = theme => ({
  ...projectsStyles(theme)
})

export function projectIsNullOrOtherType(
  project: Project,
  projectIndex: number,
  projectsArray: Project[]
) {
  // return true when project type is Other and name is null, or when Select option is loaded
  return (
    (project.projectType === projectTypesId.otherOrCustom &&
      !project.projectName) ||
    project.projectType === EMPTY_STRING ||
    (isArray(projectsArray) &&
      projectsArray.length >= DEFAULT_MAX_PROJECTS_ALLOWED)
  )
}

export function projectHasInvalidStages(
  project: Project,
  channelPartnerProjectConfiguration: ChannelPartnerProjectConfiguration
) {
  const noStagesOrSolar =
    isEmpty(project.stages) ||
    project.projectType === projectTypeToId.SOLAR_SYSTEM
  const noStagesOrBattery =
    isEmpty(project.stages) || project.projectType === projectTypeToId.BATTERY
  if (noStagesOrSolar || noStagesOrBattery) return false
  return project.stages.some(stage => {
    const stageError = calculateErrorForStage(
      project.stages,
      stage,
      channelPartnerProjectConfiguration
    )
    return Object.values(stageError).some(identity)
  })
}

type Props = {
  classes: any,
  ldFlags: Object,
  getMonthlyPaymentsOnChange: Function,
  updateProjectsOnParent: Function,
  hideTitle: boolean,
  customerProjectConfiguration: CustomerProjectConfiguration,
  editOffer: boolean
}

const Projects = ({
  classes,
  getMonthlyPaymentsOnChange = noop,
  updateProjectsOnParent = noop,
  hideTitle = false,
  customerProjectConfiguration,
  editOffer
}: Props) => {
  const [autoFocus, setAutoFocus]: boolean = useState(false)
  const quoteContext = useContext(QuoteContext)

  const handleProjectAddition = () => {
    setAutoFocus(true)
    addProjectButtonPressed(quoteContext)
  }

  const shouldDisableAddProjectButton = (): boolean => {
    const { projects } = quoteContext

    /*  disable the Add Project button, if any project's type is '' (empty string) or type is 20 and name is blank or contain space.
     Project name must be one word and hence we dont allow space  */
    return projects.some(projectIsNullOrOtherType)
  }

  const { projects = [], readonly = false } = quoteContext
  const addProjectButtonDisabled = shouldDisableAddProjectButton()
  const projectCount = projects.length

  return (
    <Grid id="projects" className={classes.root} container>
      {!hideTitle && (
        <Typography variant="caption" className={classes.section}>
          {l10n.quote.projects.sectionTitle}
        </Typography>
      )}

      <Grid container className={classes.projects}>
        {projects.map(project => (
          <React.Fragment key={project.id}>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={7} className={classes.projectType}>
                  <Typography variant="caption">
                    {l10n.quote.projects.type}
                  </Typography>
                </Grid>
                <Grid item xs={5}>
                  <Typography variant="caption">
                    {l10n.quote.projects.cost}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className={classes.projectRowContainer}>
              <ProjectRow
                autoFocus={autoFocus}
                key={project.id}
                {...project}
                getMonthlyPaymentsOnChange={getMonthlyPaymentsOnChange}
                updateProjectsOnParent={updateProjectsOnParent}
                readonly={readonly}
                removable={projects.length > 1}
                toggleButtonDisplay={noop}
                projectCount={projectCount}
                customerProjectConfiguration={customerProjectConfiguration}
                editOffer={editOffer}
              />
            </Grid>
          </React.Fragment>
        ))}
      </Grid>

      {!readonly && (
        <Grid container justify="center">
          <Button
            data-testid="add-project"
            id="project:button:add"
            color="primary"
            variant="outlined"
            onClick={handleProjectAddition}
            disabled={addProjectButtonDisabled}
            className={classes.buttonTertiary}
          >
            <Typography className={classes.buttonLabelTertiary}>
              {l10n.quote.projects.add}
            </Typography>
          </Button>
        </Grid>
      )}
    </Grid>
  )
}

export default flow(withLaunchDarkly, withStyles(styles))(Projects)
