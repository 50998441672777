// @flow
import React, { Component } from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { withRouter } from 'react-router-dom'
import { flow } from 'lodash'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { Query } from '@apollo/client/react/components'
import Page from 'components/templates/Page'
import PageNav from 'components/shared/PageNav'
import { inIframe, inP3xIframe } from 'util/EnvironmentHelper'
import Routes from 'util/Routes'
import accountTypes from 'constants/enums/accountTypes'
import Error from 'components/shared/Error'
import Loading from 'components/shared/Loading'
import BankAccountQuery from '../queries/BankAccountQuery.graphql'
import styles from '../../apply/ApplicationFormStatus/commonStyles'
import BankAccountNew from './BankAccountNew'
import BankAccountEdit from './BankAccountEdit'
import BankAccountDisplay from './BankAccountDisplay'

type Props = {
  classes: {
    startButton: string
  },
  overrideHeader: Function
}

type BankAccount = {
  routingNumber: string,
  accountNumber: string,
  currentAccountNumber?: string,
  accountType: string,
  bankName: string,
  address: string,
  status: string
}

type AccountData = {
  id?: string,
  accountNumber?: string,
  routingNumber?: string,
  status?: string,
  accountType?: string,
  bank: {
    name?: string,
    address?: string
  }
}

type State = {
  edit: boolean
}

const obfuscateNumber = (clearString: string): string => {
  const length = clearString.length > 4 ? clearString.length : 10
  return '*'.repeat(length) + clearString.slice(-4)
}

const retrievedBankAccount = (accountData: AccountData): BankAccount => ({
  routingNumber: accountData.routingNumber
    ? obfuscateNumber(accountData.routingNumber)
    : '',
  accountNumber: accountData.accountNumber
    ? obfuscateNumber(accountData.accountNumber)
    : '',
  accountType: accountData.accountType
    ? accountData.accountType
    : accountTypes.checking,
  bankName:
    accountData.bank && accountData.bank.name ? accountData.bank.name : '',
  address:
    accountData.bank && accountData.bank.address
      ? accountData.bank.address
      : '',
  status: accountData.status ? accountData.status : ''
})

class BankAccountForm extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      edit: false
    }
    this.handleEdit = this.handleEdit.bind(this)
  }

  componentDidMount() {
    const { overrideHeader } = this.props
    if (inIframe()) {
      overrideHeader({ hidden: true })
    }
  }

  handleEdit = () => {
    this.setState({ edit: true })
  }

  render() {
    const { edit } = this.state

    return (
      <Query query={BankAccountQuery}>
        {({ loading, error, data }) => {
          if (error) return <Error />
          if (loading || !data) return <Loading />
          // eslint-disable-next-line
          let bankAccount = null
          let lastFour = '0000'
          let hasAccount = false

          if (data.channelPartner && data.channelPartner.bankAccount) {
            bankAccount = retrievedBankAccount(data.channelPartner.bankAccount)
            lastFour = bankAccount.accountNumber.slice(-4)
            hasAccount = true
          }

          return (
            <Page showBackButton={inP3xIframe() ? false : true}>
              <Grid
                container
                direction="column"
                justify="center"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item>
                  <PageNav backLink={Routes.admin()} />
                  {!hasAccount && <BankAccountNew />}
                  {hasAccount && !edit && (
                    <BankAccountDisplay
                      handleEdit={this.handleEdit}
                      bankAccount={bankAccount}
                    />
                  )}
                  {hasAccount && edit && (
                    <BankAccountEdit lastFour={lastFour} />
                  )}
                </Grid>
              </Grid>
            </Page>
          )
        }}
      </Query>
    )
  }
}

export default flow(
  withLaunchDarkly,
  withRouter,
  withStyles(styles)
)(BankAccountForm)
