// @flow
import React, { Component } from 'react'
import * as LDClient from 'launchdarkly-js-client-sdk'

import { getEnvironment } from 'util/EnvironmentHelper'
import backendEnvironments from 'constants/enums/backendEnvironments'
import LaunchDarklyContext from 'util/LaunchDarklyContext'

type Props = {
  children: Object,
  clientId: String
}

type State = {
  ldClient: Object,
  ldFlags: Object
}

class LaunchDarklyProvider extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    const { clientId } = props
    const ldOptions = { key: 'pos-ui' }
    const environmentName = getEnvironment()
    if (
      [backendEnvironments.local, backendEnvironments.qa].includes(
        environmentName
      )
    ) {
      ldOptions.sendEvents = false
      // eslint-disable-next-line no-console
      console.log(
        '[LaunchDarklyProvider] suppressing launchdarkly events/metrics'
      )
    }

    this.state = {
      ldClient: LDClient.initialize(clientId, ldOptions),
      ldFlags: {}
    }
  }

  componentDidMount() {
    this._isMounted = true
    const { ldClient } = this.state
    ldClient.waitForInitialization().then(() => {
      if (!this._isMounted) return
      this.setState({
        ldFlags: ldClient.allFlags()
      })
    })
    ldClient.on('change', this.updateFlags)
  }

  // componentWillUnmount() {
  //   this._isMounted = false
  // }

  _isMounted = false

  updateFlags = (flagMap: Object) => {
    if (!this._isMounted) return

    const newFlags = {}
    Object.keys(flagMap).forEach(flag => {
      newFlags[flag] = flagMap[flag].current
    })
    this.setState(prevState => ({
      ldFlags: { ...prevState.ldFlags, ...newFlags }
    }))
  }

  render() {
    const { children } = this.props
    return (
      <LaunchDarklyContext.Provider value={this.state}>
        {children}
      </LaunchDarklyContext.Provider>
    )
  }
}

export default LaunchDarklyProvider
