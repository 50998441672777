import { gql } from 'graphql.macro'
import SalespersonFields from 'queries/fragments/SalespersonFields.graphql'

export default gql`
  mutation SignContractGuest(
    $refreshToken: String!
    $deviceFingerprint: String!
    $signatory: SignatoryRoles
  ) {
    signContract(
      refreshToken: $refreshToken
      deviceFingerprint: $deviceFingerprint
      signatory: $signatory
    ) {
      salesperson {
        ...SalespersonFields
      }
    }
  }
  ${SalespersonFields}
`
