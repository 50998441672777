import { createMuiTheme } from '@material-ui/core/styles'
import palette from './palette'
import overrides from './overrides'

const shadows = [
  'none',
  '0px 1px 3px 0px rgba(0, 0, 0, 0.2),0px 1px 1px 0px rgba(0, 0, 0, 0.14),0px 2px 1px -1px rgba(0, 0, 0, 0.12)',
  '0px 1px 5px 0px rgba(0, 0, 0, 0.2),0px 2px 2px 0px rgba(0, 0, 0, 0.14),0px 3px 1px -2px rgba(0, 0, 0, 0.12)',
  '0px 1px 8px 0px rgba(0, 0, 0, 0.2),0px 3px 4px 0px rgba(0, 0, 0, 0.14),0px 3px 3px -2px rgba(0, 0, 0, 0.12)',
  '0px 2px 4px -1px rgba(0, 0, 0, 0.2),0px 4px 5px 0px rgba(0, 0, 0, 0.14),0px 1px 10px 0px rgba(0, 0, 0, 0.12)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 5px 8px 0px rgba(0, 0, 0, 0.14),0px 1px 14px 0px rgba(0, 0, 0, 0.12)',
  '0px 3px 5px -1px rgba(0, 0, 0, 0.2),0px 6px 10px 0px rgba(0, 0, 0, 0.14),0px 1px 18px 0px rgba(0, 0, 0, 0.12)',
  '0px 4px 5px -2px rgba(0, 0, 0, 0.2),0px 7px 10px 1px rgba(0, 0, 0, 0.14),0px 2px 16px 1px rgba(0, 0, 0, 0.12)',
  '0px 5px 5px -3px rgba(0, 0, 0, 0.2),0px 8px 10px 1px rgba(0, 0, 0, 0.14),0px 3px 14px 2px rgba(0, 0, 0, 0.12)',
  '0px 5px 6px -3px rgba(0, 0, 0, 0.2),0px 9px 12px 1px rgba(0, 0, 0, 0.14),0px 3px 16px 2px rgba(0, 0, 0, 0.12)',
  '0px 6px 6px -3px rgba(0, 0, 0, 0.2),0px 10px 14px 1px rgba(0, 0, 0, 0.14),0px 4px 18px 3px rgba(0, 0, 0, 0.12)',
  '0px 6px 7px -4px rgba(0, 0, 0, 0.2),0px 11px 15px 1px rgba(0, 0, 0, 0.14),0px 4px 20px 3px rgba(0, 0, 0, 0.12)',
  '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 12px 17px 2px rgba(0, 0, 0, 0.14),0px 5px 22px 4px rgba(0, 0, 0, 0.12)',
  '0px 7px 8px -4px rgba(0, 0, 0, 0.2),0px 13px 19px 2px rgba(0, 0, 0, 0.14),0px 5px 24px 4px rgba(0, 0, 0, 0.12)',
  '0px 7px 9px -4px rgba(0, 0, 0, 0.2),0px 14px 21px 2px rgba(0, 0, 0, 0.14),0px 5px 26px 4px rgba(0, 0, 0, 0.12)',
  '0px 8px 9px -5px rgba(0, 0, 0, 0.2),0px 15px 22px 2px rgba(0, 0, 0, 0.14),0px 6px 28px 5px rgba(0, 0, 0, 0.12)',
  '0px 8px 10px -5px rgba(0, 0, 0, 0.2),0px 16px 24px 2px rgba(0, 0, 0, 0.14),0px 6px 30px 5px rgba(0, 0, 0, 0.12)',
  '0px 8px 11px -5px rgba(0, 0, 0, 0.2),0px 17px 26px 2px rgba(0, 0, 0, 0.14),0px 6px 32px 5px rgba(0, 0, 0, 0.12)',
  '0px 9px 11px -5px rgba(0, 0, 0, 0.2),0px 18px 28px 2px rgba(0, 0, 0, 0.14),0px 7px 34px 6px rgba(0, 0, 0, 0.12)',
  '0px 9px 12px -6px rgba(0, 0, 0, 0.2),0px 19px 29px 2px rgba(0, 0, 0, 0.14),0px 7px 36px 6px rgba(0, 0, 0, 0.12)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 20px 31px 3px rgba(0, 0, 0, 0.14),0px 8px 38px 7px rgba(0, 0, 0, 0.12)',
  '0px 10px 13px -6px rgba(0, 0, 0, 0.2),0px 21px 33px 3px rgba(0, 0, 0, 0.14),0px 8px 40px 7px rgba(0, 0, 0, 0.12)',
  '0px 10px 14px -6px rgba(0, 0, 0, 0.2),0px 22px 35px 3px rgba(0, 0, 0, 0.14),0px 8px 42px 7px rgba(0, 0, 0, 0.12)',
  '0px 11px 14px -7px rgba(0, 0, 0, 0.2),0px 23px 36px 3px rgba(0, 0, 0, 0.14),0px 9px 44px 8px rgba(0, 0, 0, 0.12)',
  '0px 11px 15px -7px rgba(0, 0, 0, 0.2),0px 24px 38px 3px rgba(0, 0, 0, 0.14),0px 9px 46px 8px rgba(0, 0, 0, 0.12)'
]

const transitions = {
  easing: {
    easeInOut: 'cubic-bezier(0.4, 0, 0.2, 1)',
    easeOut: 'cubic-bezier(0.0, 0, 0.2, 1)',
    easeIn: 'cubic-bezier(0.4, 0, 1, 1)',
    sharp: 'cubic-bezier(0.4, 0, 0.6, 1)'
  },
  duration: {
    shortest: 150,
    shorter: 200,
    short: 250,
    standard: 300,
    complex: 375,
    enteringScreen: 225,
    leavingScreen: 195
  }
}

const zIndex = {
  mobileStepper: 1000,
  appBar: 1100,
  drawer: 1200,
  modal: 1300,
  snackbar: 1400,
  tooltip: 1500
}

export const colors = {
  primary: '#0e619c',
  secondary: '#edb548',
  errorRed: '#cb2431',
  iconSuccess: '#289471',
  redAlert: '#CB2432',
  demoModeRed: '#D14C43',
  iconSuccessContainer: '#e8f5f1',
  warningBackground: '#FFF8E1',
  warningRed: '#c83f62',
  checkmarkYellow: '#f5bf65',
  textLinkBlue: '#0e609c',
  textEmphasisBlue: '#296fa4',
  warningOrange: '#c58f3b',
  warningYellow: '#d79515',
  textBrightRed: '#cb2432',
  textSnackBranch: '#d3445d',
  background: '#fff',
  textDark: 'rgba(34, 42, 45, 1)',
  textLight: 'rgba(36, 36, 43, 0.2)',
  inactiveButtons: 'rgba(34, 42, 45, 0.15)',
  dividerLightGray: 'rgba(34, 42, 45, 0.1)',
  greenBackground: 'rgb(213, 230, 235)',
  backgroundImageAlternative: 'rgb(211, 211, 211)',
  backgroundImageAlternativeLogoRight: 'rgb(255, 255, 255)',
  lockedTextFieldColor: 'rgba(63, 191, 187, 0.2)',
  mosaicTeal: 'rgba(63, 191, 187, 1)',
  mosaicGold: 'rgba(248, 205, 127, 1)',
  mosaicBlue: 'rgba(80, 142, 170, 1)',
  mosaicOrange: 'rgba(237, 175, 38, 1)',
  mutedBlack: '#656565',
  normalBlack: '#121212',
  blueBorderColor: 'rgba(14, 97, 156, 0.5)',
  blueOutlineColor: 'rgba(14, 97, 156, 0.35)',
  blueBackgroundColor: 'rgba(14, 97, 156, 0.05)',
  blackColor: 'rgba(0, 0, 0, 0.87)',
  yellowBackgroundHint: '#F8EBC8',
  darkGray: '#666',
  lightGray: '#AAA',
  extraLightGray: '#DDD',
  blackLight: 'rgba(0, 0, 0, 0.25)',
  greenSuccess: 'rgba(40, 148, 113, 0.15)',
  stageGreen: 'rgb(21,153,78)',
  redFail: 'rgba(203, 36, 50, 0.15)',
  lockedIconColor: '#0E619C',
  shimmerDark: '#ecebeb',
  shimmerLight: '#f3f3f3',
  grayText: '#888888',
  whiteSmoke: '#f8f8f8',
  extraDarkGray: '#666666'
}

// Transparency to be added as two extra digits at the end of hex colors.
export const transparencies = {
  alpha10Percent: '1A',
  alpha20Percent: '33',
  alpha30Percent: '4D',
  alpha40Percent: '66',
  alpha50Percent: '80',
  alpha60Percent: '99',
  alpha70Percent: 'B3',
  alpha80Percent: 'CC',
  alpha90Percent: 'E6'
}

const typography = {
  fontFamily:
    '-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",Helvetica,Arial,sans-serif',
  fontSize: 14,
  fontWeightLight: 100,
  fontWeightRegular: 300,
  fontWeightMedium: 400,
  fontWeightBold: 500,
  fontWeightExtraBold: 600,
  caption: { lineHeight: 1.4 }
}

export const pageTitle = {
  fontSize: 24,
  fontWeight: 300,
  color: colors.normalBlack
}

export const bodyTextNormal = {
  fontSize: 16,
  fontWeight: 400,
  lineHeight: 1.5,
  color: colors.normalBlack
}

export const bodyTextGreen = {
  fontSize: 18,
  fontWeight: 400,
  lineHeight: 1.5,
  color: colors.iconSuccess
}

export const buttonMinWidth = {
  minWidth: '132px'
}

export default themeColors => {
  const theme = createMuiTheme({
    palette: palette(themeColors),
    typography,
    shadows,
    transitions,
    zIndex
  })

  theme.overrides = overrides(themeColors, theme)
  return theme
}
