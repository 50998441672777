import { gql } from 'graphql.macro'

export default gql`
  mutation ($request: [CreatePartnerLoginRequest!]!) {
    createPartnerLogins(request: { requests: $request }) {
      result {
        emailAddress
        result {
          id
          channelPartnerId
          firstName
          lastName
          emailAddress
          phoneNumber
          role
          status
        }
        errors {
          key
          message
          field
        }
      }
    }
  }
`
