import { gql } from 'graphql.macro'

export default gql`
  mutation submitApplicationAlternativeFinancing(
    $customerId: String!
    $application: AlternateCreditApplicationRequest!
  ) {
    submitApplicationAlternativeFinancing(
      customerId: $customerId
      alternateCreditApplicationRequest: $application
    ) {
      status
      __typename
    }
  }
`
