import { gql } from 'graphql.macro'

export default gql`
  fragment UserAuthModelFields on UserAuthModel {
    userType
    userRoles
    userId
    accessToken
    accessModes
    refreshToken
  }
`
