// @flow
import React from 'react'

const conjunctionList = ['', ', ', ' or ']

const getConjunction = (position, lastPosition) =>
  conjunctionList[
    (position === lastPosition && conjunctionList.length - 1) || position
  ]

type Props = {
  classes: string,
  browserItem: {},
  position: int,
  lastPosition: int
}

const LinkLabelBrowser = ({
  classes,
  browserItem,
  position,
  lastPosition
}: Props) => (
  <>
    {getConjunction(position, lastPosition)}
    <a className={classes} href={browserItem.href}>
      {browserItem.imageAlt}
    </a>
  </>
)

export default LinkLabelBrowser
