// @flow
import React from 'react'
import { Grid, InputAdornment, NativeSelect } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import l10n from 'properties/translations'
import { otherIncomeSources } from 'util/FoundationFormHelper'
import { formatToLowerWithoutUnderScore } from 'util/ApplicationFormStatusHelper'
import type { IncomeDataType } from 'util/TypesHelper'

type IncomeObject = Array<IncomeDataType>

type Props = {
  classes: Object,
  applicantOtherIncomeSources: Array<IncomeObject>,
  handleOtherIncomeSource: Function,
  handleOtherIncome: Function
}

const OtherIncome = (props: Props) => {
  const {
    classes,
    applicantOtherIncomeSources,
    handleOtherIncomeSource,
    handleOtherIncome
  } = props
  return applicantOtherIncomeSources.map((obj, index) => (
    <div key={index}>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            {l10n.foundation.applicationContent.otherIncomeSourceLabel}
            <br />
            <NativeSelect
              fullWidth
              onChange={handleOtherIncomeSource(index)}
              value={obj.incomeType}
              className={classes.nativeSelectDropStyle}
            >
              {otherIncomeSources}
            </NativeSelect>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <TextValidator
            fullWidth
            onChange={handleOtherIncome(index)}
            name={
              l10n.foundation.applicationContent.fieldNames
                .applicantDownpaymentAmount
            }
            value={obj.amountPerMonth}
            label={`Monthly ${formatToLowerWithoutUnderScore(
              obj.incomeType
            )} amount`}
            validators={[
              l10n.foundation.applicationContent.errorMessages.required,
              l10n.foundation.applicationContent.errorMessages
                .applicantDownPaymentRegex
            ]}
            errorMessages={[
              l10n.foundation.applicationContent.fieldRequiredLabel,
              l10n.foundation.applicationContent.errorMessages.invalidAmount
            ]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
            type="tel"
          />
        </Grid>
      </Grid>
    </div>
  ))
}

export default OtherIncome
