// @flow
import React, { useState } from 'react'
import { Button, Grid, Typography, CircularProgress } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { Link } from 'react-router-dom'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-material-ui'
import * as Yup from 'yup'
import cx from 'classnames'
import { Mutation } from '@apollo/client/react/components'
import type { BrowserHistory, HashHistory, MemoryHistory } from 'history'
import Page from 'components/templates/Page'
import SendResetMessaging from 'queries/SendResetMessaging.graphql'
import Routes from 'util/Routes'
import l10n from 'properties/translations'
import { CIRCULAR_PROGRESS_SIZE } from 'properties/properties'
import coreScreenStyles from 'themes/coreScreenStyles'

const styles = theme => ({
  ...coreScreenStyles(theme),
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  titleHeader: {
    fontWeight: '300',
    fontSize: '1.30rem'
  },
  okButton: {
    textDecoration: 'none'
  }
})

type Props = {
  classes: {
    marginBottom: String,
    marginTop: String,
    titleHeader: String,
    marginBottom: String,
    marginButton: String,
    okButton: String
  },
  history: BrowserHistory | HashHistory | MemoryHistory
}

const ForgotPassword = ({ classes, history }: Props) => {
  const [sendLinkButtonClicked, setSendLinkButtonClicked] = useState(false)
  const sendEmail = async (
    email: string,
    sendResetPasswordMessaging: Function,
    history: Object
  ) => {
    await sendResetPasswordMessaging({
      variables: {
        emailAddress: email
      }
    })
    history.push('/password-forgotten-email', {
      email
    })
  }

  return (
    <Page variant="narrowLogo">
      <Mutation mutation={SendResetMessaging}>
        {sendResetPasswordMessaging => (
          <Formik
            initialValues={{ email: '' }}
            validationSchema={Yup.object().shape({
              email: Yup.string()
                .email(l10n.admin.errors.invalidEmail)
                .required(l10n.admin.errors.required)
            })}
            onSubmit={(values, actions) => {
              // same shape as initial values
              sendEmail(values.email, sendResetPasswordMessaging, history)
              actions.setSubmitting(false)
              setSendLinkButtonClicked(true)
            }}
          >
            {({ isValid, isSubmitting, dirty }) => {
              const cannotSubmit = !isValid || !dirty || isSubmitting
              return (
                <Form>
                  <Grid
                    container
                    direction="column"
                    justify="flex-start"
                    alignItems="stretch"
                    className={cx(classes.marginBottom, classes.marginTop)}
                    spacing={2}
                  >
                    <Grid item xs={12}>
                      <Typography
                        data-testid="screen-title"
                        variant="subtitle1"
                        align="left"
                        className={classes.titleHeader}
                      >
                        {l10n.forgotPassword.title}
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography variant="subtitle1" align="left" gutterBottom>
                        {l10n.forgotPassword.description}
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Field
                        type="text"
                        name="email"
                        label={l10n.admin.passwordReset.email}
                        component={TextField}
                        fullWidth
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="center"
                    spacing={3}
                  >
                    <Button
                      data-testid="back-to-login"
                      component={Link}
                      to={Routes.login()}
                      className={classes.button}
                      variant="outlined"
                      color="primary"
                      size="small"
                    >
                      <Typography>
                        {l10n.forgotPassword.goBackToLoginButton}
                      </Typography>
                    </Button>
                    <Button
                      disabled={cannotSubmit || sendLinkButtonClicked}
                      classes={{ root: classes.button }}
                      variant="contained"
                      color="primary"
                      type="submit"
                    >
                      {sendLinkButtonClicked ? (
                        <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
                      ) : (
                        <Typography>
                          {l10n.forgotPassword.resendButton}
                        </Typography>
                      )}
                    </Button>
                  </Grid>
                </Form>
              )
            }}
          </Formik>
        )}
      </Mutation>
    </Page>
  )
}

export default withStyles(styles)(ForgotPassword)
