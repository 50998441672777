// @flow
import React from 'react'
import { Grid, IconButton, Typography, Menu, MenuItem } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import DemoCustomerEdit from 'components/shared/DemoCustomerEdit'
import userTypes from 'constants/enums/userTypes'
import modalKeys from 'constants/enums/modalKeys'
import permissionsEnum from 'constants/enums/permissions'
import translations from 'properties/translations'
import { userHasPermission, userRolesMatch } from 'util/UserHelper'
import UserContext from 'util/UserContext'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { moveAchBeforeLoanAgreementFlagEnabled } from 'util/FeatureHelper'
import useAccessControl from 'util/useAccessControl'
import styles from './MenuApply.styles'
import { Query } from '@apollo/client/react/components'
import GetOpportunityInfo from 'queries/GetOpportunityInfo.graphql'
import {
  EMPTY_OBJECT,
  isLoanAppCountersignedAndBeyond
} from 'properties/properties'
import userRolesEnum from 'constants/enums/userRoles'
import { getCustomerIdFromLocation, portalLink } from 'util/NavigationHelper'
import { get } from 'lodash'
import portalPaths from 'constants/enums/portalPaths'

type Props = {
  localizedTranslations: any,
  onMenuItemClick: Function,
  isInfoEditable: boolean,
  isOkayToCancel: boolean,
  classes: {
    moreIcon: string,
    menuApply: string,
    menuApplyContainer: string,
    invisibleMenuItem: string,
    titleCaseMenuItem: string
  },
  disabled: boolean,
  permissions: string[],
  achEnabled: boolean,
  isTilaPage: boolean,
  achFlagEnabled: boolean
}

const useStyles = makeStyles(styles)

const UnwrappedMenuApplyContent = ({
  localizedTranslations: l10n = translations,
  onMenuItemClick = null,
  ldFlags,
  ...menuApplyProps
}) => {
  const classes = useStyles()
  const {
    userType,
    permissions,
    isInfoEditable,
    achEnabled,
    isTilaPage,
    disabled,
    demoMode,
    onClickMenuIcon,
    menuAnchorEl,
    onMenuClose
  } = menuApplyProps

  const customerId = getCustomerIdFromLocation(window.location.pathname)
  const achFlagEnabled = moveAchBeforeLoanAgreementFlagEnabled(ldFlags)
  const userContext = React.useContext(UserContext)
  const { me } = userContext
  const isInstaller = userType === userTypes.installer
  const safePermissions = permissions || []
  const showMenuApplyToNonInstaller =
    isInfoEditable && safePermissions.includes(permissionsEnum.EDIT_CUSTOMER)
  const showEditCustomer =
    isInfoEditable &&
    userHasPermission(me, [
      permissionsEnum.EDIT_CUSTOMER,
      permissionsEnum.FULL_ACCESS
    ])
  const showBackOffice = opportunityStatus =>
    me.isSalesforceIdentityEnabled === true &&
    isInstaller &&
    userRolesMatch(me.userRoles, [
      userRolesEnum.installer.admin,
      userRolesEnum.installer.owner
    ]) &&
    isLoanAppCountersignedAndBeyond(opportunityStatus)

  const onBackOfficeClick = opportunityId => {
    const channelPartnerHostName = get(userContext, 'channelPartner.hostName')
    const opportunityLink = `${portalLink(
      channelPartnerHostName,
      portalPaths.opportunity
    )}/${opportunityId}`
    window.location = opportunityLink
  }

  const onItemClick = modalKey => {
    onMenuItemClick(modalKey)
  }

  if (!isInstaller && !showMenuApplyToNonInstaller) return null

  return (
    <Grid item>
      <IconButton
        data-testid="more"
        aria-haspopup="true"
        aria-label={l10n.apply.formStatus.menu.ariaLabels.showMoreOptions}
        className={classes.moreIcon}
        disabled={disabled}
        onClick={onClickMenuIcon}
        color="primary"
      >
        <MoreHorizIcon />
      </IconButton>
      <Query query={GetOpportunityInfo} variables={{ customerId }}>
        {({ data: { customer } = EMPTY_OBJECT }) => {
          return (
            <Menu
              data-testid="menu-apply"
              aria-labelledby="optionMenu"
              anchorEl={menuAnchorEl}
              open={Boolean(menuAnchorEl)}
              onClose={onMenuClose}
            >
              {showBackOffice(customer?.opportunityStatus) && (
                <MenuItem
                  button
                  data-testid={modalKeys.backOffice}
                  key={modalKeys.backOffice}
                  onClick={() => onBackOfficeClick(customer?.opportunityId)}
                >
                  <Typography
                    variant="body2"
                    className={classes.titleCaseMenuItem}
                  >
                    {l10n.apply.formStatus.menu.backOffice}
                  </Typography>
                </MenuItem>
              )}
              {!achEnabled && isTilaPage && achFlagEnabled && !demoMode && (
                <MenuItem
                  button
                  data-testid={modalKeys.setupAutopay}
                  key={modalKeys.setupAutopay}
                  onClick={() => onItemClick(modalKeys.setupAutopay)}
                >
                  <Typography
                    variant="body2"
                    className={classes.titleCaseMenuItem}
                  >
                    {l10n.apply.formStatus.menu.setupAutopay}
                  </Typography>
                </MenuItem>
              )}
              {userType === userTypes.installer && isInfoEditable && (
                <MenuItem
                  button
                  data-testid={modalKeys.editOffer}
                  role="menuItem"
                  key={modalKeys.editOffer}
                  onClick={() => onItemClick(modalKeys.editOffer)}
                >
                  <Typography variant="body2">
                    {l10n.apply.formStatus.menu.editOffer}
                  </Typography>
                </MenuItem>
              )}
              {showEditCustomer && (
                <DemoCustomerEdit
                  handleClick={() => onItemClick(modalKeys.editCustomerInfo)}
                >
                  {handleClick => (
                    <MenuItem
                      button
                      data-testid={modalKeys.editCustomerInfo}
                      role="menuItem"
                      key={modalKeys.editCustomerInfo}
                      onClick={handleClick}
                    >
                      <Typography variant="body2">
                        {l10n.apply.formStatus.menu.editCustomerInfo}
                      </Typography>
                    </MenuItem>
                  )}
                </DemoCustomerEdit>
              )}
              {userType === userTypes.installer && (
                <MenuItem
                  button
                  data-testid={modalKeys.shareLink}
                  key={modalKeys.shareLink}
                  role="menuItem"
                  onClick={() => onItemClick(modalKeys.shareLink)}
                >
                  <Typography variant="body2">
                    {l10n.apply.formStatus.menu.sendLink}
                  </Typography>
                </MenuItem>
              )}
              {/* This item is here for visually impaired users. A screen reader will read its value so they can close the menu. */}
              <MenuItem
                button
                data-testid="close-menu-item"
                key="close-menu-item"
                onClick={onMenuClose}
                className={classes.invisibleMenuItem}
              >
                <Typography variant="body2">
                  {l10n.apply.formStatus.menu.closeMenu}
                </Typography>
              </MenuItem>
            </Menu>
          )
        }}
      </Query>
    </Grid>
  )
}

export const MenuApplyContent = withLaunchDarkly(UnwrappedMenuApplyContent)

const MenuApply = (props: Props) => {
  const { loading, error, userType, permissions } = useAccessControl()

  if (loading || error) return null

  return (
    <MenuApplyContent
      userType={userType}
      permissions={permissions}
      {...props}
    />
  )
}

export default MenuApply
