// @flow
import React, { Component } from 'react'
import { Grid, TextField, FormHelperText } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import NumberFormat from 'react-number-format'
import cx from 'classnames'
import cloneDeep from 'lodash/cloneDeep'
import GooglePlacesAutosuggestField from 'components/shared/GooglePlacesAutosuggestField'
import TextValidator from 'components/shared/TextValidator'
import {
  ZIP_VALIDATORS,
  STREET_VALIDATORS,
  NO_SPECIAL_CHARACTERS_VALIDATORS
} from 'properties/properties'
import languageKeys from 'constants/enums/languageKeys'
import browserAutocompleteKeys from 'constants/enums/browserAutocompleteKeys'
import customerFields from 'constants/enums/customerFields'
import fieldMasks from 'constants/enums/fieldMasks'
import { colors } from 'themes/default'
import TranslationsContext from 'util/TranslationsContext'

import StateMenu from '../../common/StateMenu'
import { type AddressShape } from '../shapes/address'

const defaultAddress: AddressShape = {
  addressType: '',
  city: '',
  stateAbbreviation: '',
  street: '',
  zip: ''
}

const styles = theme => ({
  labelCtn: {
    paddingBottom: '0 !important',
    marginBottom: '-3px'
  },
  inputContainer: {
    paddingTop: '0 !important',
    paddingBottom: `${theme.spacing(2)}px !important`,
    '& input': {
      color: colors.normalBlack
    },
    '& ::placeholder': {
      color: colors.mutedBlack,
      opacity: 1
    }
  },
  condensedLabelRow: {
    [theme.breakpoints.up('md')]: {
      position: 'relative',
      marginTop: -1 * theme.spacing(2)
    }
  }
})

type Props = {
  address: ?AddressShape,
  addressLabel: string,
  addressType: string,
  canEdit: boolean,
  getErrorMessagesRequired: (fieldName: string) => string[],
  getValidatorsRequired: (fieldName: string) => string[],
  inputProps: any,
  isRequiredField: (fieldName: string) => string[],
  labelIsRequired: () => boolean,
  onAddressMapEdit: ({
    address: AddressShape,
    addressType: string,
    validationFields: string[]
  }) => void,
  addressIsModified: Function
}

class Address extends Component<Props> {
  static contextType = TranslationsContext

  onAutosuggestUpdate = ({ address, city, state, zip }) => {
    const {
      address: fullAddress = defaultAddress,
      addressType,
      onAddressMapEdit
    } = this.props

    const updatedAddress = cloneDeep(fullAddress)
    updatedAddress[customerFields.street] = address
    updatedAddress[customerFields.city] = city
    updatedAddress[customerFields.stateAbbreviation] = state
    updatedAddress[customerFields.zip] = zip
    updatedAddress.addressType = addressType

    onAddressMapEdit({
      address: updatedAddress,
      addressType,
      validationFields: [
        customerFields.addressStreet,
        customerFields.addressCity,
        customerFields.addressState,
        customerFields.addressZip
      ]
    })
  }

  getFieldName = (fieldName: string) => {
    const { addressType } = this.props
    return `${addressType}.${fieldName}`
  }

  editAddressField = (customerField: string) => (event: Object) => {
    const { addressIsModified, addressType } = this.props
    this.updateAddressMap(customerField, event.target.value)
    if (addressIsModified) {
      addressIsModified(addressType)
    }
  }

  editAutosuggestField = (newValue: string) => {
    const { addressIsModified, addressType } = this.props
    if (addressIsModified) {
      addressIsModified(addressType)
    }
    this.updateAddressMap(customerFields.street, newValue)
  }

  updateAddressMap = (customerField: string, value: string) => {
    const {
      address = defaultAddress,
      addressType,
      onAddressMapEdit
    } = this.props

    const updatedAddress = cloneDeep(address)
    updatedAddress[customerField] = value
    updatedAddress.addressType = addressType

    onAddressMapEdit({
      address: updatedAddress,
      addressType,
      validationFields: [customerField]
    })
  }

  render() {
    const {
      address: { city, stateAbbreviation, street, zip } = defaultAddress,
      addressLabel,
      canEdit,
      classes,
      getErrorMessagesRequired,
      getValidatorsRequired,
      inputProps: InputProps,
      isRequiredField,
      labelIsRequired
    } = this.props
    const l10n = this.context

    const streetField = this.getFieldName(customerFields.street)
    const cityField = this.getFieldName(customerFields.city)
    const stateField = this.getFieldName(customerFields.stateAbbreviation)
    const zipField = this.getFieldName(customerFields.zip)

    return (
      <>
        <Grid item xs={12} className={classes.labelCtn}>
          <FormHelperText>{addressLabel}</FormHelperText>
        </Grid>
        <Grid
          item
          xs={12}
          md={l10n.languageKey === languageKeys.en ? 4 : 12}
          className={classes.inputContainer}
        >
          <GooglePlacesAutosuggestField
            InputProps={{
              ...InputProps,
              disabled: !canEdit,
              errorMessages: [
                ...getErrorMessagesRequired(streetField, l10n),
                ...l10n.customer.streetErrorMessage
              ],
              placeholder: l10n.customer.streetShort,
              id: streetField,
              name: streetField,
              value: street,
              required: isRequiredField(streetField),
              validators: [
                ...getValidatorsRequired(streetField),
                ...STREET_VALIDATORS
              ]
            }}
            InputLabelProps={{ required: labelIsRequired() }}
            onAutosuggestUpdate={this.onAutosuggestUpdate}
            onChange={this.editAutosuggestField}
          />
        </Grid>
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          className={cx(classes.inputContainer, classes.condensedLabelRow)}
        >
          <TextValidator
            id={cityField}
            fullWidth
            placeholder={l10n.customer.city}
            label={l10n.customer.city}
            onChange={this.editAddressField(customerFields.city)}
            name={cityField}
            value={city}
            disabled={!canEdit}
            autoComplete={browserAutocompleteKeys.city}
            validators={[
              ...getValidatorsRequired(cityField),
              ...NO_SPECIAL_CHARACTERS_VALIDATORS
            ]}
            errorMessages={[
              ...getErrorMessagesRequired(cityField, l10n),
              l10n.customer.errorMessages.onlyLetters
            ]}
            required={isRequiredField(cityField)}
            InputLabelProps={{ required: labelIsRequired() }}
            InputProps={{ ...InputProps }}
            data-testid="addressCity"
          />
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={l10n.languageKey === languageKeys.en ? 2 : 4}
          className={cx(classes.inputContainer, classes.condensedLabelRow)}
        >
          {canEdit ? (
            <StateMenu
              id={stateField}
              canEdit={canEdit}
              onSelectChange={() =>
                this.editAddressField(customerFields.stateAbbreviation)
              }
              selection={stateAbbreviation}
              propertyName={stateField}
              required={isRequiredField(stateField)}
              InputLabelProps={{ required: labelIsRequired() }}
              errorMessages={getErrorMessagesRequired(stateField, l10n)}
              validators={getValidatorsRequired(stateField)}
              autoComplete={browserAutocompleteKeys.state}
            />
          ) : (
            <TextField
              disabled
              label={l10n.customer.state}
              InputProps={{ ...InputProps }}
              value={stateAbbreviation}
            />
          )}
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          md={l10n.languageKey === languageKeys.en ? 2 : 4}
          className={cx(classes.inputContainer, classes.condensedLabelRow)}
        >
          <NumberFormat
            customInput={TextValidator}
            format={fieldMasks.zip}
            id={zipField}
            fullWidth
            type="tel"
            placeholder={l10n.customer.zip}
            onChange={this.editAddressField(customerFields.zip)}
            name={zipField}
            label={l10n.customer.zip}
            value={zip}
            disabled={!canEdit}
            autoComplete={browserAutocompleteKeys.zip}
            validators={[...getValidatorsRequired(zipField), ...ZIP_VALIDATORS]}
            errorMessages={[
              ...getErrorMessagesRequired(zipField, l10n),
              l10n.customer.zipErrorMessage
            ]}
            required={isRequiredField(zipField)}
            InputLabelProps={{ required: labelIsRequired() }}
            InputProps={{
              ...InputProps,
              'data-testid': 'address-zip-code-field'
            }}
          />
        </Grid>
      </>
    )
  }
}

export default withStyles(styles)(Address)
