// @flow

export const taskStatusesEnum = Object.freeze({
  completed: 'COMPLETED',
  failed: 'FAILED',
  incomplete: 'INCOMPLETE',
  pendingReview: 'PENDING_REVIEW'
})

export type TaskStatuses = $Values<typeof taskStatusesEnum>

export default taskStatusesEnum
