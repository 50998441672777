import { gql } from 'graphql.macro'

export default gql`
  fragment StageResponseFields on StageResponse {
    id
    identifier
    status
    amount
    name
    completionDate
    completionOptions
    percentageOfTotalAmount
    dateInstallCompleteRequestCreatedForSms
  }
`
