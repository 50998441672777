// @flow

import cx from 'classnames'
import { Grid, Typography } from '@material-ui/core'
import React from 'react'

import l10n from 'properties/translations'

const renderDeclinedScreenDisclosure = classes => (
  <>
    <Typography
      variant="caption"
      gutterBottom
      className={cx(classes.formFieldLabel, classes.labelText)}
    >
      {l10n.foundation.disclosures}
    </Typography>
    , l10n.foundation.disclosure( classes.disclosureContainer,
    classes.discloseText, classes.discloseLink )
  </>
)

const renderAppContentDisclosure = classes => (
  <>
    <Typography
      variant="caption"
      gutterBottom
      className={cx(classes.formFieldLabel, classes.labelText)}
    >
      {l10n.foundation.disclosures}
    </Typography>
    , l10n.foundation.applicationContent.disclosure(
    classes.disclosureContainer, classes.discloseText, classes.discloseLink )
  </>
)

type Props = {
  classes: {
    disclosureContainer: string,
    disclosureLink: string,
    disclosureText: string,
    formFieldLabel: string,
    labelText: string
  },
  useGridComponent?: boolean,
  xs?: number,
  appContent: boolean
}

const FoundationDisclosures = (props: Props) => {
  const { classes, useGridComponent, xs, appContent } = props
  if (useGridComponent && !appContent) {
    return (
      <Grid item xs={xs} className={classes.disclosureText}>
        {renderDeclinedScreenDisclosure(classes)}
      </Grid>
    )
  }
  if (useGridComponent && appContent) {
    return (
      <Grid item xs={xs}>
        {renderAppContentDisclosure(classes)}
      </Grid>
    )
  }
  return <div>{renderDeclinedScreenDisclosure(classes)}</div>
}

FoundationDisclosures.defaultProps = {
  useGridComponent: true,
  xs: 12
}

export default FoundationDisclosures
