import React from 'react'
import padStart from 'lodash/padStart'
import NumberFormat from 'react-number-format'
import fieldMasks from 'constants/enums/fieldMasks'
import { NON_DIGIT_REGEX, reviewSummaryProperties } from 'properties/properties'

const { dollarNumberFormat } = reviewSummaryProperties

const leftMask = (str, visibleLength = str.length, mask = '*') =>
  str.substr(-visibleLength).padStart(str.length, mask)

const rightMask = (str, visibleLength = str.length, mask = '*') =>
  str.substring(0, visibleLength).padEnd(str.length, mask)

const fullMask = (str, mask = '*') => {
  if (str) {
    return mask.repeat(str.length)
  }
  return ''
}

const wrapTextWithParens = (...nodes) => (
  <>
    {'('}
    {nodes}
    {')'}
  </>
)

const digitsOnly = str => String(str).replace(NON_DIGIT_REGEX, '')

const formatDOB = (y, m, d) => {
  if (!y || !m || !d) return null
  return [
    digitsOnly(y),
    padStart(digitsOnly(m), 2, '0'),
    padStart(digitsOnly(d), 2, '0')
  ].join('-')
}

export const phoneNumberAsText = (phoneNumber, renderText = null) => (
  <NumberFormat
    displayType="text"
    format={fieldMasks.phoneNumber}
    value={phoneNumber}
    renderText={renderText}
  />
)

const dollarAmountAsText = amount => (
  <NumberFormat
    {...dollarNumberFormat}
    renderText={text => text}
    displayType="text"
    value={amount}
  />
)

const getCustomerFullName = (firstName, lastName) => {
  const getFullName = () => `${firstName} ${lastName}`
  const hasFirstNameAndLastName = () => firstName && lastName
  const getFirstNameOrLastName = () => firstName || lastName

  const fullName = hasFirstNameAndLastName()
    ? getFullName()
    : getFirstNameOrLastName() || ''

  return fullName
}

const StringHelper = {
  leftMask,
  rightMask,
  fullMask,
  wrapTextWithParens,
  digitsOnly,
  formatDOB,
  phoneNumberAsText,
  dollarAmountAsText,
  getCustomerFullName
}

export default StringHelper
