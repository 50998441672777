import React, { useContext, useRef } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TranslationsContext from 'util/TranslationsContext'
import { colors, bodyTextNormal } from 'themes/default'

const styles = theme => ({
  pleaseTellUsAboutYourselfContainer: {
    paddingTop: theme.spacing(3),
    '& p': { color: colors.normalBlack }
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  }
})
const useStyles = makeStyles(styles)

const FormDescription = () => {
  const l10n = useContext(TranslationsContext)
  const scrollTargetRef = useRef()
  const classes = useStyles()

  return (
    <Grid
      container
      spacing={2}
      className={classes.pleaseTellUsAboutYourselfContainer}
    >
      <Grid item>
        <Typography
          variant="body1"
          className={classes.bodyTextNormal}
          ref={scrollTargetRef}
        >
          {l10n.prequalificationForm.pleaseTellUsAboutYourself}
        </Typography>
      </Grid>
    </Grid>
  )
}

export default FormDescription
