// @flow
import { isTestingEnv } from 'util/EnvironmentHelper'

const kReportError = 'reportError'

export function manuallyReportError(error: Error) {
  if (isTestingEnv()) {
    // eslint-disable-next-line no-console
    console.error(kReportError, error)
  }
}

export default {
  manuallyReportError
}
