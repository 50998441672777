import customerFields from './customerFields'

const { phoneNumber, zip, ssn, verifySsn, dobDay, dobYear, last4Ssn } =
  customerFields

export default {
  [phoneNumber]: '(###) ###-####',
  phoneNumberNoParens: '###-###-####',
  [zip]: '#####',
  [ssn]: '#########',
  [verifySsn]: '#########',
  [last4Ssn]: '####',
  [dobDay]: '##',
  [dobYear]: '####',
  monthWork: '###',
  dateOfBirth: '##/##/####'
}

export const accountMasks = {
  bankAccountNumber: '############',
  bankAccountRoutingNumber: '#########'
}
