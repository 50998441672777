import { gql } from 'graphql.macro'

export default gql`
  query GetOpportunityInfo($customerId: String!) {
    customer(customerId: $customerId) {
      id
      status
      opportunityId
      opportunityStatus
      loanType
    }
  }
`
