import { gql } from 'graphql.macro'

export default gql`
  mutation signContract(
    $id: String!
    $signatorRole: SignatoryRoles
  ) {
    signContract(
      customerId: $id
      signatory: $signatorRole
    ) {
      status
    }
  }
`
