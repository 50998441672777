export default {
  cancelContract: 'cancelContract',
  confirmAutoRepComplete: 'confirmAutoRepComplete',
  confirmComplete: 'confirmComplete',
  confirmCompleteBorrower: 'confirmCompleteBorrower',
  confirmStage: 'confirmStage',
  editCustomerInfo: 'editCustomerInfo',
  editOffer: 'editOffer',
  salesAgreement: 'salesAgreement',
  sendRequest: 'sendRequest',
  shareLink: 'shareLink',
  submitInstallComplete: 'submitInstallComplete',
  permissionToOperate: 'permissionToOperate',
  accountDocument: 'accountDocument',
  setupAutopay: 'setupAutopay',
  customerConfirmation: 'customerConfirmation',
  backOffice: 'backOffice'
}
