/* eslint-disable react/jsx-boolean-value */
// @flow
import * as React from 'react'
import {
  Grid,
  Typography,
  InputAdornment,
  NativeSelect
} from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import l10n from 'properties/translations'
import { stateList, residenceTypes } from 'util/FoundationFormHelper'
import { MonthWorkFormat } from '../../NumberFormatHelper'

type Props = {
  classes: Object,
  handleFormState: string,
  coApplicantStreet: string,
  coApplicantCity: string,
  coApplicantState: string,
  coApplicantZip: number,
  coAppResidenceType: string,
  coAppMonthsAtResidence: string,
  coAppMonthlyPayment: number
}

const CoApplicantAddresss = (props: Props) => {
  const {
    classes,
    handleFormState,
    coApplicantStreet,
    coApplicantCity,
    coApplicantState,
    coApplicantZip,
    coAppResidenceType,
    coAppMonthsAtResidence,
    coAppMonthlyPayment
  } = props
  return (
    <>
      <Grid container className={classes.secondSectionGrid}>
        <Typography className={classes.sectionTitleTextStyle}>
          {l10n.foundation.applicationContent.coApplicantLivesLabel}
        </Typography>
      </Grid>

      <Grid container spacing={0}>
        <Grid container className={classes.customPrimaryResidenTitle}>
          <Typography>
            {l10n.foundation.applicationContent.primaryAddressTitle}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.subFieldTitleStyle}>
            <TextValidator
              fullWidth
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames.coApplicantStreet
              }
              value={coApplicantStreet}
              label={l10n.foundation.applicationContent.applicantStreetLabel}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .coAppStreetRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidStreet
              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.subFieldTitleStyle}>
            <TextValidator
              fullWidth
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames.coApplicantCity
              }
              value={coApplicantCity}
              label={l10n.foundation.applicationContent.applicantCityLabel}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages.coAppCityRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidCity
              ]}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            {l10n.foundation.applicationContent.applicantState}
            <br />
            <NativeSelect
              fullWidth
              onChange={handleFormState}
              value={coApplicantState}
              name={
                l10n.foundation.applicationContent.fieldNames.coApplicantState
              }
              required={true}
              className={classes.nativeSelectDropStyle}
            >
              {stateList}
            </NativeSelect>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.applicantZipStyle}>
          <div className={classes.subFieldTitleStyle}>
            <TextValidator
              fullWidth
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames.coApplicantZip
              }
              value={coApplicantZip}
              label={l10n.foundation.applicationContent.applicantZipCodeLabel}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages.coAppZipRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidZipcode
              ]}
              type="tel"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.appMonthsAtResidenceStyle}>
          <div className={classes.fieldTitleStyle}>
            {l10n.foundation.applicationContent.coApplicantresidenceType}
            <br />
            <NativeSelect
              fullWidth
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames.coAppResidenceType
              }
              value={coAppResidenceType}
              required={true}
              className={classes.nativeSelectDropStyle}
            >
              {residenceTypes}
            </NativeSelect>
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.coAppMonthsAtPrimaryStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .coAppMonthsAtResidence
              }
              value={coAppMonthsAtResidence}
              label={
                l10n.foundation.applicationContent.monthsAtPrimaryResidenceLabel
              }
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .coAppMonthsAtResidenceRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidMonth
              ]}
              InputProps={{
                inputComponent: MonthWorkFormat
              }}
              type="tel"
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid
          item
          xs={12}
          sm={6}
          className={`${classes.fieldTitleStyle} ${classes.gridItemStyle}`}
        >
          <TextValidator
            fullWidth
            onChange={handleFormState}
            name={
              l10n.foundation.applicationContent.fieldNames.coAppMonthlyPayment
            }
            value={coAppMonthlyPayment}
            label={
              l10n.foundation.applicationContent
                .coApplicantMonthlyHousingPayment
            }
            validators={[
              l10n.foundation.applicationContent.errorMessages.required,
              l10n.foundation.applicationContent.errorMessages.coAppAddressRegex
            ]}
            errorMessages={[
              l10n.foundation.applicationContent.fieldRequiredLabel,
              l10n.foundation.applicationContent.errorMessages.invalidMonth
            ]}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              )
            }}
            type="tel"
          />
        </Grid>
      </Grid>
    </>
  )
}

export default CoApplicantAddresss
