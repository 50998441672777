import { filter, isEmpty, map, has, some } from 'lodash'
import { COMMA_SPACE_SEPARATOR } from 'properties/properties'

export const getFieldErrors = (errors, fieldName) => {
  const errorsPresent = some(errors, ['field', fieldName]) // `_.matchesProperty` iteratee shorthand - same as field == fieldName
  if (errorsPresent) {
    return filter(errors, obj => obj.field !== fieldName)
  }
  return errors
}

export const displayErrorsInline = (type, formErrors, validationErrors) => {
  const isReq = some(validationErrors, errorObject => has(errorObject, type))

  let serverErrors = []
  if (!isReq) {
    serverErrors = filter(formErrors, obj => obj.key === type)
  }

  const errors =
    !isReq &&
    !isEmpty(serverErrors) &&
    map(serverErrors, 'message').join(COMMA_SPACE_SEPARATOR)
  return errors
}

const BankAccountHelper = { getFieldErrors, displayErrorsInline }

export default BankAccountHelper
