import React from 'react'
import { Subscribe } from 'unstated'

const withUnstatedContainer = (WrappedComponent, ContainerObject) => props => {
  const propNames = Object.keys(ContainerObject)
  const containers = Object.values(ContainerObject)

  return (
    <Subscribe to={[...containers]}>
      {(...names) => {
        const containerProps = {}
        propNames.forEach((propName, index) => {
          containerProps[propName] = names[index]
        })

        return <WrappedComponent {...containerProps} {...props} />
      }}
    </Subscribe>
  )
}

export default withUnstatedContainer
