// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import formConfig from 'properties/formConfig'
import { EMPTY_STRING } from 'properties/properties'
import SelectValidator from 'components/shared/SelectValidator'
import { colors } from 'themes/default'
import TranslationsContext from 'util/TranslationsContext'

const styles = theme => ({
  wide: {
    width: theme.spacing(10)
  },
  unselected: {
    color: `${colors.mutedBlack} !important`
  },
  selected: {
    color: `${colors.normalBlack} !important`
  }
})

type Props = {
  canEdit: boolean,
  onSelectChange: Function,
  selection: string,
  propertyName: string,
  required: boolean,
  InputLabelProps: {
    required: boolean
  },
  classes: {
    wide: string,
    unselected: string,
    selected: string
  },
  showLabel: boolean,
  errorMessages: string[],
  validators: string[],
  labelId?: string
}

class StateMenu extends React.Component<Props> {
  static contextType = TranslationsContext

  static defaultProps = {
    selection: '',
    propertyName: 'address.stateAbbreviation',
    required: false,
    InputLabelProps: {
      required: true
    },
    showLabel: true,
    labelId: undefined
  }

  get safeSelection() {
    const { selection } = this.props
    if (!formConfig.VALID_STATES.includes(selection)) {
      return EMPTY_STRING
    }
    return selection
  }

  render() {
    const {
      required,
      selection,
      onSelectChange,
      propertyName,
      classes,
      showLabel,
      validators,
      errorMessages = []
    } = this.props
    const l10n = this.context

    const stateList = formConfig.VALID_STATES.map(state => (
      <option key={state} value={state}>
        {state}
      </option>
    ))

    return (
      <SelectValidator
        fullWidth
        errorMessages={errorMessages}
        validators={validators}
        value={selection ? this.safeSelection : EMPTY_STRING}
        onChange={onSelectChange(propertyName)}
        required={required}
        classes={{
          root: selection ? classes.selected : classes.unselected
        }}
        name={propertyName}
        label={l10n.stateMenu.state}
        showLabel={showLabel}
        data-testid="addressState"
      >
        <option key="empty" value=""></option>
        {stateList}
      </SelectValidator>
    )
  }
}

export default withStyles(styles)(StateMenu)
