import React from 'react'
import noop from 'lodash/noop'

const ProjectRowContext = React.createContext()

const useProjectRowContext = () => {
  const context = React.useContext(ProjectRowContext)
  if (context === undefined) {
    return noop
  }
  return context
}

export { ProjectRowContext, useProjectRowContext }
