// @flow
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import TranslationsContext from 'util/TranslationsContext'
import Loading from 'components/shared/Loading'
import commonStyles from './commonStyles'
import TimeoutProcessing from './TimeoutProcessing'

const styles = theme => ({
  ...commonStyles(theme),
  timeOutText: { maxWidth: 500, marginTop: theme.spacing(4) },
  timeOutButton: { fontWeight: 600, marginTop: theme.spacing(4) }
})

type Props = {
  classes: {
    iconWrapperBlank: string,
    iconGray: string,
    subheading: string
  },
  stopPollingClient: () => {}
}

const timeout = 20000

class ProcessingContent extends Component<Props> {
  static contextType = TranslationsContext
  state = { timeout: false }

  componentDidMount() {
    setTimeout(this.stopPolling, timeout)
  }
  stopPolling = () => {
    this.setState({ timeout: true })
    this.props.stopPollingClient()
  }

  render() {
    const { classes } = this.props
    const l10n = this.context

    return !this.state.timeout ? (
      <Grid container direction="column" justify="center" alignItems="center">
        <Grid item>
          <Grid
            className={classes.iconWrapperBlank}
            justify="center"
            alignItems="center"
            container
          >
            <Grid item>
              <Loading block />
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.subheading}>
          <Typography variant="caption">
            {l10n.apply.formStatus.processing.description}
          </Typography>
        </Grid>
      </Grid>
    ) : (
      <TimeoutProcessing classes={classes} />
    )
  }
}

export default withStyles(styles)(ProcessingContent)
