// @flow
import React from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { Mutation } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { get, flow } from 'lodash'
import MenuApply from 'components/shared/MenuApply'
import routes from 'util/Routes'
import modalKeys from 'constants/enums/modalKeys'
import { localizedTranslations } from 'properties/translations'
import { Dialog } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import CancelContractDialog from 'screens/apply/components/CancelContractDialog'
import ShareLinkDialog from 'screens/apply/components/ShareLinkDialog'
import ProductSelectionModal from 'screens/apply/productSelectionModal/ProductSelectionModal'
import VerifyIdentityContent from 'screens/apply/ApplicationFormStatus/VerifyIdentityContent'
import verificationMutationActions from 'constants/enums/verificationMutationActions'
import applySections from 'constants/enums/applySections'
import CancelContractMutation from 'screens/apply/queries/CancelContract.graphql'
import CancelDialog from 'screens/apply/CancelDialog'
import { contractExists } from 'util/ContractCreationHelper'
import {
  lastFourSSNFlagEnabled,
  moveAchBeforeLoanAgreementFlagEnabled
} from 'util/FeatureHelper'
import { getDeviceFingerprint } from 'util/DeviceHelper'
import UpdateContract from 'queries/UpdateContract.graphql'
import UpdateQuote from 'queries/UpdateQuote.graphql'
import GetCustomerCreditData from 'screens/apply/queries/GetCustomerCreditData.graphql'
import GetCustomerCreditDataDiscounted from 'screens/apply/queries/GetCustomerCreditDataDiscounted.graphql'
import { track } from 'util/AnalyticsHelper'
import { getProductsFieldName } from 'util/LoanProductHelper'
import { customerLanguageKey } from 'util/CustomerHelper'
import { onSetupAutopay } from 'util/SetupFormHelper'
import UserContext from 'util/UserContext'
import ApplyContext from 'util/ApplyContext'
import { eventTypes } from 'constants/enums/analyticsEventTypes'
import { hasPartialCreditApplicationPermission } from 'util/UserHelper'
import GetCustomerStatuses from 'queries/GetCustomerStatus.graphql'
import styles from './OptionMenu.styles'

interface Props {
  customer: Object;
  client: Object;
  classes: Object;
  hasContract: boolean;
  ldFlags: Object;
  handleVerificationSuccess: Function;
  menuItemsDisabled: boolean;
  verifyIdentity: boolean;
  showVerifyDialog: boolean;
  toggleVerifyDialog: Function;
  onEditOffer: Function;
  fromPage: String;
  isInfoEditableSignedContract: boolean;
  achEnabled: boolean;
  isTilaPage: boolean;
  demoMode: boolean;
  history: Object;
  fullSsnRequired: Boolean;
}

interface State {
  anchorEl: Object;
  cancelConfirmed: boolean;
  showModal: boolean;
  modalKey: string;
  openCancelModal: Boolean;
}

const RenderVerifyIdentity = ({
  handleVerificationSuccess,
  id,
  l10n,
  showVerifyDialog,
  toggleVerifyDialog,
  ldFlags,
  fullSsnRequired,
  userHasPartialCreditAppPermission
}) => {
  if (
    lastFourSSNFlagEnabled(ldFlags) &&
    showVerifyDialog &&
    userHasPartialCreditAppPermission
  ) {
    return (
      <Redirect
        to={{
          pathname: routes.verify(id),
          state: {
            id,
            title: l10n.apply.formStatus.verifyIdentity.title,
            description: l10n.apply.verifyIdentityDescription,
            mutationToInvoke: verificationMutationActions.validateChallenge,
            verifyingIdentityPriorToEsign: true,
            fullSsnRequired
          }
        }}
      />
    )
  }
  return (
    <Dialog
      open={showVerifyDialog}
      title={l10n.apply.confirmIdentity}
      onClose={toggleVerifyDialog}
      data-testid="verify-dialog"
    >
      <VerifyIdentityContent
        customerId={id}
        description={l10n.apply.confirmIdentityDescription}
        mutationToInvoke={verificationMutationActions.validateChallenge}
        onVerificationSuccess={handleVerificationSuccess}
      />
    </Dialog>
  )
}

class OptionMenu extends React.Component<Props, State> {
  static contextType = UserContext

  state = {
    anchorEl: null,
    cancelConfirmed: false,
    showModal: false,
    modalKey: null,
    isRedirecting: false
  }

  toggleModal = () =>
    this.setState(prevState => ({
      ...prevState,
      modalKey: null
    }))

  handleMenuItemClick = modalKey => {
    const { cancelConfirmed } = this.state
    const {
      onEditOffer = null,
      isWithinCustomerForm,
      onEditCustomer,
      customer,
      history
    } = this.props
    if (cancelConfirmed) {
      this.setState({ modalKey, cancelConfirmed: false, anchorEl: null })
    }

    if (onEditOffer && modalKey === modalKeys.editOffer) {
      return this.setState({ anchorEl: null }, onEditOffer())
    }

    if (modalKey === modalKeys.editCustomerInfo && isWithinCustomerForm) {
      onEditCustomer()
      history.push(history.location.pathname)
    }

    if (modalKey === modalKeys.setupAutopay) {
      onSetupAutopay(customer, history)
    }
    return this.setState({ modalKey, anchorEl: null })
  }

  handleMenuClick = e => this.setState({ anchorEl: e.currentTarget })

  handleSave =
    ({ customerId, client, contract }) =>
    async variables => {
      const { history, ldFlags, openedFromEmailVerification } = this.props
      const achFlagEnabled = moveAchBeforeLoanAgreementFlagEnabled(ldFlags)

      const refetchQueries = [
        {
          query: achFlagEnabled
            ? GetCustomerCreditDataDiscounted
            : GetCustomerCreditData,

          variables: {
            id: customerId
          }
        },
        // Refetch so that stagenavigation reloads.
        {
          query: GetCustomerStatuses,
          variables: {
            customerId
          }
        }
      ]

      const params = { customerId, client, refetchQueries, variables, ldFlags }

      if (contractExists(contract)) {
        // If contract exists, need to call UpdateContract mutation which cancels, updates, and creates a new contract
        await this.updateContract(params)
      } else {
        // Otherwise just update the quote
        await this.updateQuote(params)
      }
      if (!openedFromEmailVerification) {
        this.setState(prevState => ({
          ...prevState,
          modalKey: null,
          cancelConfirmed: false
        }))
      }

      if (!history) return
      if (openedFromEmailVerification) {
        this.setState({ isRedirecting: true })
      }
      history.push(routes.apply(customerId))
    }

  handleMenuClose = () => {
    this.setState({ anchorEl: null, modalKey: null })
  }

  updateContract = async ({
    customerId,
    client,
    refetchQueries,
    variables,
    ldFlags
  }) => {
    await client.mutate({
      awaitRefetchQueries: true,
      mutation: UpdateContract,
      variables: {
        ...variables,
        deviceFingerprint: await getDeviceFingerprint()
      },
      errorPolicy: 'all',
      refetchQueries
    })
  }

  updateQuote = async ({ customerId, client, refetchQueries, variables }) => {
    const result = await client.mutate({
      mutation: UpdateQuote,
      variables,
      refetchQueries
    })

    if (get(result, 'data.update_quote')) {
      const { projects, hasAch, ...quote } = variables.quote
      track(eventTypes.editOfferSaved, {
        customerId: variables.customerId,
        ...quote,
        // eslint-disable-next-line no-unused-vars
        projects: projects.map(({ id, ...project }) => project)
      })
    }
  }

  onCancelContractSuccess = () => {
    const { onEditOffer } = this.props
    this.setState(
      {
        cancelConfirmed: true
      },
      onEditOffer
    )
  }

  onClickCancelDialog = async cancelMutation => {
    const {
      customer: { id },
      history
    } = this.props

    const { errors } = await cancelMutation({
      variables: { customerId: id }
    })
    if (errors) {
      this.setState(prevState => ({
        ...prevState,
        cancelErrors: errors
      }))
      return
    }
    const route = routes.apply(id)
    history.go(route)
  }

  render() {
    const { anchorEl, cancelConfirmed, modalKey, openCancelModal } = this.state
    const {
      customer,
      client,
      classes,
      hasContract = true,
      ldFlags,
      handleVerificationSuccess,
      menuItemsDisabled,
      verifyIdentity,
      showVerifyDialog,
      toggleVerifyDialog,
      fromPage = '',
      isInfoEditableSignedContract,
      fullSsnRequired
    } = this.props

    const l10n = localizedTranslations(customerLanguageKey(customer))

    // eslint-disable-next-line prefer-const
    const { contract, id, creditApplication, quote, isInfoEditable } = customer
    const isEditable =
      fromPage === applySections.SignedContract
        ? isInfoEditableSignedContract
        : isInfoEditable

    const isOkayToCancel = get(contract, 'isOkayToCancel', true)
    const loanProducts = get(
      creditApplication,
      getProductsFieldName(ldFlags),
      []
    )

    const loanAmount = get(quote, 'loanAmount', null)

    if (loanProducts.length === 0) return null

    const renderVerifyIdentityProps = userHasPartialCreditAppPermission => ({
      handleVerificationSuccess,
      id,
      l10n,
      showVerifyDialog,
      toggleVerifyDialog,
      ldFlags,
      fullSsnRequired,
      userHasPartialCreditAppPermission
    })

    return (
      <ApplyContext.Provider
        value={{
          isRedirecting: this.state.isRedirecting
        }}
      >
        <UserContext.Consumer>
          {userContext => {
            const permissions = get(userContext, 'me.permissions', [])

            const userHasPartialCreditAppPermission =
              hasPartialCreditApplicationPermission(permissions)

            return (
              <>
                <MenuApply
                  localizedTranslations={l10n}
                  onClickMenuIcon={this.handleMenuClick}
                  onMenuClose={this.handleMenuClose}
                  onMenuItemClick={this.handleMenuItemClick}
                  isInfoEditable={isEditable}
                  menuAnchorEl={anchorEl}
                  disabled={menuItemsDisabled}
                  disableEditOffer={!isOkayToCancel}
                  {...this.props}
                />
                {isOkayToCancel && (
                  <>
                    <ProductSelectionModal
                      {...customer}
                      isOpen={
                        hasContract
                          ? modalKey === modalKeys.editOffer && cancelConfirmed
                          : modalKey === modalKeys.editOffer
                      }
                      toggleModal={this.toggleModal}
                      handleSave={this.handleSave({
                        customerId: id,
                        client,
                        contract
                      })}
                    />
                    {hasContract && (
                      <CancelContractDialog
                        isChangeOrder
                        open={modalKey === modalKeys.editOffer}
                        onClose={this.toggleModal}
                        customerId={id}
                        title={l10n.apply.cancelContractDialog.title}
                        body={l10n.apply.cancelContractDialog.body}
                        confirmButtonTitle={
                          l10n.apply.cancelContractDialog.buttonTitle
                        }
                        onCancelContractSuccess={this.onCancelContractSuccess}
                      />
                    )}
                  </>
                )}

                {modalKey === modalKeys.editCustomerInfo && (
                  <Redirect to={{ pathname: routes.customer(id) }} />
                )}
                <ShareLinkDialog
                  customer={customer}
                  open={modalKey === modalKeys.shareLink}
                  onClose={this.handleMenuClose}
                  customerId={id}
                  title={l10n.apply.shareLinkDialog.title}
                  body={l10n.apply.shareLinkDialog.body}
                  confirmButtonTitle={l10n.apply.shareLinkDialog.buttonTitle()}
                  loanAmount={loanAmount}
                />
                {verifyIdentity && (
                  <RenderVerifyIdentity
                    {...renderVerifyIdentityProps(
                      userHasPartialCreditAppPermission
                    )}
                  />
                )}
                <Dialog
                  open={Boolean(openCancelModal)}
                  title={l10n.apply.cancelContractDialog.title}
                  onClose={this.handleMenuClose}
                  className={classes.cancelDialog}
                >
                  <Mutation mutation={CancelContractMutation}>
                    {cancelMutation => (
                      <CancelDialog
                        onClickBack={this.handleMenuClose}
                        classes={{
                          containerButton: classes.cancelButtonContainer
                        }}
                        onClickConfirm={() =>
                          this.onClickCancelDialog(cancelMutation)
                        }
                      />
                    )}
                  </Mutation>
                </Dialog>
              </>
            )
          }}
        </UserContext.Consumer>
      </ApplyContext.Provider>
    )
  }
}

export default flow(
  withLaunchDarkly,
  withApolloConsumer,
  withRouter,
  withStyles(styles)
)(OptionMenu)
