import addressErrorsEnum from 'constants/enums/addressErrors'
import { isEmpty } from 'lodash'
import makeSHA1 from 'crypto-js/sha1'
import l10n from 'properties/translations'
import {
  type AddressShape,
  type AddressShapeWithErrors
} from 'screens/customer/shapes/address'

const validatedAddressWithErrors = (addressValidated: AddressShapeWithErrors) =>
  addressValidated.filter(
    validatedAddress =>
      !validatedAddress.errors.includes(addressErrorsEnum.addressNotFound) &&
      !isEmpty(validatedAddress.errors)
  )

export const addressToId = (address: Object) =>
  makeSHA1(JSON.stringify(address)).toString()

export const formatAddress = (
  addressesWithErrors: AddressShapeWithErrors[],
  addressValidated: AddressShapeWithErrors,
  addresses: AddressShape[]
) =>
  addressesWithErrors
    .map(addressWithErrors => ({
      address: addressWithErrors,
      id: addressToId(addressWithErrors),
      title: l10n.apply.addressVerification.suggestedAddressLabel
    }))
    .concat({
      address: {
        ...addresses.find(
          address =>
            !address.addressType ||
            address.addressType === addressValidated.addressType
        )
      },
      id: addressToId(
        addresses.find(
          address =>
            !address.addressType ||
            address.addressType === addressValidated.addressType
        )
      ),
      title: l10n.apply.addressVerification.myAddressLabel
    })

export const findAddressById = (
  selectedId: string,
  validatedAddressMap: Object,
  addressType: string
) =>
  validatedAddressMap[addressType].find(
    option => option.id === selectedId[addressType]
  )

export const buildAddressOptions = (
  validatedAddresses: AddressShapeWithErrors[],
  addresses: AddressShape[]
) => {
  const addressOptions = {}

  validatedAddresses.forEach(item => {
    const addressesWithErrors = validatedAddressWithErrors([item])

    addressOptions[item.addressType] = formatAddress(
      addressesWithErrors,
      item,
      addresses
    )

    addressOptions[item.addressType].errors = item.errors
  })

  return addressOptions
}
