import React, { useContext } from 'react'
import { Grid } from '@material-ui/core'
import IframeThirdPartyCookiesErrorMessage from 'screens/apply/components/IframeThirdPartyCookiesErrorMessage'
import HandToCustomerDialog from 'screens/apply/components/HandToCustomerDialog'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import PrequalificationRouteContext from 'util/PrequalificationRouteContext'
import { inIframe } from 'util/EnvironmentHelper'
import { cookiesEnabled } from 'util/SessionHelper'
import { handToCustomerDialogFlagEnabled } from 'util/FeatureHelper'
import NotLiveDialog from './NotLiveDialog'

const Alerts = ({ ldFlags }) => {
  const { isSwiftlinks } = useContext(PrequalificationRouteContext)
  const shouldShowThirdPartyCookiesError = inIframe() && !cookiesEnabled()

  return (
    <>
      {isSwiftlinks ? (
        <NotLiveDialog />
      ) : (
        <HandToCustomerDialog
          isOpen={handToCustomerDialogFlagEnabled(ldFlags)}
        />
      )}
      {shouldShowThirdPartyCookiesError && (
        <Grid item>
          <IframeThirdPartyCookiesErrorMessage />
        </Grid>
      )}
    </>
  )
}

export default withLaunchDarkly(Alerts)
