// @flow
import React, { useEffect, useState } from 'react'
import { get, isEmpty, filter, head, flow } from 'lodash'
import { Redirect, withRouter } from 'react-router-dom'
import Client from 'gql/clientNew'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { startRoute, userRolesMatch } from 'util/UserHelper'
import MonitoringHelper from 'util/MonitoringHelper'
import GenericErrorPage from 'components/shared/GenericErrorPage'
import Routes from 'util/Routes'
import { isLoggedIn } from 'util/SessionHelper'
import setupOptionTypes from 'constants/enums/setupOptionTypes'
import userRolesEnum from 'constants/enums/userRoles'
import basePaths from 'constants/enums/basePaths'
import accessModeKeys from 'constants/enums/accessModeKeys'
import PartnerLoginQuery from 'screens/admin/queries/PartnerLoginQuery.graphql'

type Props = {
  userRoles: string[],
  accessModes: string[],
  ldFlags: any,
  attemptedLink: String,
  history: { push: Function }
}

const { client } = new Client()

const determineRedirectValue = async ({
  demoModeOnly,
  userRoles,
  setRedirectValue,
  setError
}) => {
  let navigateTo
  try {
    const result = await client.query({ query: PartnerLoginQuery })

    if (
      userRolesMatch(userRoles, [
        userRolesEnum.installer.admin,
        userRolesEnum.installer.owner
      ])
    ) {
      if (result) {
        let accountHasTasks = false

        const setupOptions = get(result, 'channelPartner.setupOptions', [])
        const account = head(
          filter(setupOptions, ['setupType', setupOptionTypes.account])
        )
        if (account) {
          accountHasTasks = !isEmpty(account.tasks)
        }

        if (accountHasTasks) {
          navigateTo = Routes.partnerAccount()
        }
        if (demoModeOnly) {
          navigateTo = Routes.admin()
        }
        navigateTo = Routes.start()
      }
    }
  } catch (error) {
    setError(error)
    MonitoringHelper.manuallyReportError(error)
  } finally {
    setRedirectValue(navigateTo)
  }
}

const OnLoginSuccess = ({
  userRoles,
  accessModes,
  ldFlags,
  attemptedLink,
  history
}: Props) => {
  const hasLiveModeAccess = accessModes.includes(accessModeKeys.live)
  const hasDemoModeAccess = accessModes.includes(accessModeKeys.demo)
  const demoModeOnly = !hasLiveModeAccess && hasDemoModeAccess
  const [redirectValue, setRedirectValue] = useState()
  const [error, setError] = useState()

  useEffect(
    function navigateToAttemptedLink() {
      if (isLoggedIn() && attemptedLink && attemptedLink !== basePaths.start) {
        history.replace(attemptedLink, { fromLogin: true })
      }
    },
    [attemptedLink, history]
  )

  useEffect(
    function loadRedirectValue() {
      determineRedirectValue({
        demoModeOnly,
        userRoles,
        setRedirectValue,
        setError
      })
    },
    [demoModeOnly, userRoles, setRedirectValue, setError]
  )

  if (!isLoggedIn()) return null

  if (error) {
    return <GenericErrorPage />
  }

  return (
    <Redirect
      to={{
        pathname: redirectValue || startRoute(userRoles, accessModes, ldFlags),
        state: { fromLogin: true }
      }}
    />
  )
}

export default flow(withLaunchDarkly, withRouter)(OnLoginSuccess)
