// @flow
import upperFirst from 'lodash/upperFirst'
import {
  addMonths,
  differenceInDays,
  differenceInSeconds,
  format,
  parse,
  parseISO,
  formatDistance
} from 'date-fns'
import { utcToZonedTime } from 'date-fns-tz'
import { localeForLanguageKey } from 'util/CustomerHelper'

import l10n, { localizedTranslations } from 'properties/translations'
import { UTC_SUFFIX, SAMPLE_NON_UTC_DATE } from 'properties/properties'
import languageKeys from 'constants/enums/languageKeys'

const dateFormats = {
  short: 'M/d',
  med: 'MMM dd',
  monthYear: 'MMM yyyy',
  dateAndTime: 'MMM dd, yyyy h:mmaaa',
  dateAndTimeWith3LetterMonth: 'MMM dd, yyyy',
  monthDayYear: 'MM/dd/yyyy'
}

/**
 * Print a Date object in the form of "January 2025"
 * @param date Date object to print
 * @returns {string} form
 */
function printDate(date: Date) {
  return `${l10n.monthNames[date.getMonth()]} ${date.getFullYear()}`
}

/**
 * Print a Date object in the form of "Jan 05, 2018 5:22 PM"
 * @param date Date object to print
 * @returns {string} form
 */
function printDateAndTime(date: Date) {
  return format(date, dateFormats.dateAndTime)
}

/**
 * Print a Date object in the form of "Jan 05, 2018 5:22 PM"
 * @param date Date object to print
 * @returns {string} form
 */
function printDateWith3LetterMonth(date) {
  if (typeof date === 'string') {
    date = parseISO(date)
  }
  return format(date, dateFormats.dateAndTimeWith3LetterMonth)
}

const isValid = (year: number, month: number, day: number) => {
  const date = new Date(year, month - 1, day)

  return date && date.getMonth() + 1 === month
}

/**
 * Print a months from now label in the form of "January 2025"
 * @param months number of months from now to format string for
 * @returns {string} label
 */

function createMonthsFromNowLabel(months: number = 0): String {
  return format(addMonths(new Date(), months), dateFormats.monthYear)
}

const daysSinceDate = (date: String, fromDate: Date = new Date()) => {
  if (!date) return 0
  return differenceInDays(fromDate, parseISO(date))
}

function shortenDateTerms(_t: any, dateStr: String): String {
  return dateStr
    .replace(new RegExp(`^${_t.time.aboutRegex} `), '')
    .replace(new RegExp(` ${_t.time.hours}`), _t.time.hoursShort)
    .replace(new RegExp(` ${_t.time.hour}`), _t.time.hoursShort)
    .replace(new RegExp(` ${_t.time.minutes}`), _t.time.minutesShort)
    .replace(new RegExp(` ${_t.time.minute}`), _t.time.minutesShort)
    .replace(new RegExp(` ${_t.time.days}`), _t.time.daysShort)
    .replace(new RegExp(` ${_t.time.day}`), _t.time.daysShort)
    .replace(new RegExp(` ${_t.time.weeks}`), _t.time.weeksShort)
    .replace(new RegExp(` ${_t.time.week}`), _t.time.weeksShort)
    .replace(new RegExp(` ${_t.time.months}`), _t.time.monthsShort)
    .replace(new RegExp(` ${_t.time.month}`), _t.time.monthsShort)
    .replace(new RegExp(` ${_t.time.years}`), _t.time.yearsShort)
    .replace(new RegExp(` ${_t.time.year}`), _t.time.yearsShort)
}

const customFormatDistance = (
  date: String,
  fromDate: Date,
  short: Boolean = false
) => {
  const options = { addSuffix: !short }
  const dateObj = date ? parseISO(date) : new Date()
  const baseDate = fromDate
  const result = formatDistance(dateObj, baseDate, options)
  if (result.startsWith(l10n.time.aboutRegex)) {
    return result.substr(result.indexOf(' ') + 1)
  }
  return result
}

const defaultDIWOptions = () => ({
  short: false,
  fromDate: new Date()
})

// Fix for incorrect Java LocalDateTime (no timezone added) https://docs.oracle.com/javase/8/docs/api/java/time/LocalDateTime.html
const appendUtcTimeZone = date => {
  if (
    date &&
    typeof date === 'string' &&
    !date.endsWith(UTC_SUFFIX) &&
    date.length === SAMPLE_NON_UTC_DATE.length
  ) {
    return `${date}${UTC_SUFFIX}`
  }
  return date
}

function distanceOfTimeInWords(
  languageKey: string,
  date: string,
  opts: Object = {}
) {
  const localized = localizedTranslations(languageKey)
  const options = {
    ...defaultDIWOptions(),
    ...opts
  }
  const { short, fromDate } = options

  date = appendUtcTimeZone(date)

  const secDiff = differenceInSeconds(fromDate, parseISO(date))
  let words = null
  if (secDiff < 60) words = short ? localized.time.now : localized.time.justNow

  const dayDiff = differenceInDays(fromDate, parseISO(date))
  if (dayDiff === 1)
    words = short ? `1${localized.time.daysShort}` : localized.time.yesterday

  if (!words) {
    switch (languageKey) {
      case languageKeys.es:
        words = formatDistance(Date.parse(date), Date.parse(fromDate), {
          locale: localeForLanguageKey(languageKey)
        })
        break
      case languageKeys.en:
      default:
        words = customFormatDistance(date, fromDate, short)
        break
    }
  }
  if (short) {
    // English only for now
    return shortenDateTerms(localized, words)
  }
  return upperFirst(words)
}

const utcToLocalTime = (serverTime: String) => {
  const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
  const utcTimeString = appendUtcTimeZone(serverTime) // Server time is in UTC but omits the UTC timezone from the string
  return utcToZonedTime(utcTimeString, localTimeZone)
}

const contactCardTimeFormat = 'yyyy-MM-dd hh:mm a'

const yearFromMMDDYYYYDate = (date: string): Number =>
  parse(date, dateFormats.monthDayYear, new Date()).getFullYear()
const monthFromMMDDYYYYDate = (date: string): Number =>
  parse(date, dateFormats.monthDayYear, new Date()).getMonth() + 1
const dayFromMMDDYYYYDate = (date: string): Number =>
  parse(date, dateFormats.monthDayYear, new Date()).getDate()

const DateHelper = {
  isValid,
  printDate,
  printDateAndTime,
  printDateWith3LetterMonth,
  daysSinceDate,
  utcToLocalTime,
  distanceOfTimeInWords,
  createMonthsFromNowLabel,
  contactCardTimeFormat,
  yearFromMMDDYYYYDate,
  monthFromMMDDYYYYDate,
  dayFromMMDDYYYYDate,
  appendUtcTimeZone
}

export default DateHelper
