export default theme => ({
  row: {
    padding: 20,
    marginBottom: theme.spacing(1.5),
    boxShadow: 'none',
    border: '2px solid #eee',
    borderColor: '#eee',
    cursor: 'pointer',
    height: 'auto'
  },
  rowSelected: {
    backgroundColor: theme.palette.secondary.light,
    border: '2px solid',
    borderColor: theme.palette.secondary.main
  },
  rowReadonly: {
    borderColor: theme.palette.grey['600'],
    cursor: 'default'
  },
  rowReadonlyAndSelected: {
    backgroundColor: theme.palette.grey['300']
  },
  productNumberTextContainerSlim: {
    paddingLeft: 10
  },
  productNumberText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    textTransform: 'lowercase',
    width: 100,
    '& span': {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  productNumberTextBold: {
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'lowercase',
    '& span': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  amountSpacing: {
    paddingRight: '5px'
  },
  productCostText: {
    display: 'inline-block',
    fontSize: 12,
    fontWeight: 400,
    textTransform: 'lowercase',
    '& span': {
      fontSize: 16,
      fontWeight: 600
    }
  },
  aprInclusiveOfPromo: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular
  },
  bottomText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightRegular,
    paddingLeft: '10px'
  },
  dividerPayment: {
    paddingTop: 0
  },
  loanPromoRateText: {
    color: theme.palette.common.black
  },
  promoPeriodGridItem: {
    position: 'relative',
    top: -2
  },
  marginforLongerText: {
    marginLeft: '-19px'
  }
})
