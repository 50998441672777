// @flow
/* eslint-disable jsx-a11y/anchor-has-content */
import React, { useContext } from 'react'
import cx from 'classnames'
import { Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useFile from 'util/UseFileHook'
import DisclosurePackageContext from 'util/DisclosurePackageContext'
import disclosureAssetTypes from 'constants/enums/disclosureAssetTypes'
import Loading from 'components/shared/Loading'
import Markdown, { defaultOverrides } from 'components/shared/Markdown'

import styles from './Disclosures.styles'

const useStyles = makeStyles(styles)

type Props = {
  isInactive?: boolean
}

export type PrequalificationDisclosuresClasses = {
  container: string,
  inactiveForm: string,
  legalTextContainer: string,
  equalHousingLenderIcon: string,
  paragraph: string,
  heading: string,
  link: string
}

const Image = props => {
  const classes: PrequalificationDisclosuresClasses = useStyles()
  // eslint-disable-next-line jsx-a11y/alt-text
  return <img {...props} className={classes.equalHousingLenderIcon} />
}
const H3 = props => {
  const classes: PrequalificationDisclosuresClasses = useStyles()
  return (
    <Typography variant="subtitle1" {...props} className={classes.heading} />
  )
}
const Link = props => {
  const classes: PrequalificationDisclosuresClasses = useStyles()
  return <a target="_blank" {...props} className={classes.link} />
}

const options = {
  overrides: {
    ...defaultOverrides,
    img: {
      component: Image
    },
    h3: {
      component: H3
    },
    a: {
      component: Link
    }
  }
}

const PrequalificationDisclosures = ({ isInactive = false }: Props) => {
  const classes: PrequalificationDisclosuresClasses = useStyles()
  const {
    disclosureFileForAssetType,
    disclosurePackage: { bundleId }
  } = useContext(DisclosurePackageContext)
  const { loading, text: disclosure } = useFile(
    disclosureFileForAssetType(disclosureAssetTypes.form)
  )
  return (
    <div
      className={cx({
        [classes.container]: true,
        [classes.inactiveForm]: isInactive
      })}
      data-testid="prequalification-disclosures"
      data-disclosure-bundleId={bundleId}
    >
      {loading || !disclosure ? (
        <Loading block hideText />
      ) : (
        <Markdown options={options}>{disclosure}</Markdown>
      )}
    </div>
  )
}
export default PrequalificationDisclosures
