export default theme => ({
  landlineModalTilet: {
    '& .MuiTypography-h6': {
      fontWeight: theme.typography.fontWeightRegular,
      fontSize: theme.spacing(3)
    }
  },
  landlineModalContent: {
    '& .MuiTypography-body1': {
      fontSize: theme.spacing(2),
      fontWeight: 'normal',
      color: theme.palette.darkBlack
    }
  },
  landlineModalActionButtons: {
    color: theme.palette.primary.main,
    fontWeight: theme.typography.fontWeightBold
  }
})
