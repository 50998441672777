// @flow
import {
  ChannelPartnerFlags,
  channelPartnerFlagsEnum
} from 'constants/enums/channelPartnerFlags'

export const channelPartnerHasPartialCreditApplicationFlag = (
  channelPartnerFlags: ChannelPartnerFlags[] = []
): boolean =>
  channelPartnerFlags.includes(
    channelPartnerFlagsEnum.PARTIAL_CREDIT_APPLICATION
  )

export const channelPartnerHasBlockOpportunityCreationFlag = (
  channelPartnerFlags: ChannelPartnerFlags[] = []
): boolean =>
  channelPartnerFlags.includes(
    channelPartnerFlagsEnum.BLOCK_OPPORTUNITY_CREATION
  )
