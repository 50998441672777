import React from 'react'
import { Typography } from '@material-ui/core'

const CommonImageCell = ({
  classes,
  item: { description, imageAlt, imageSrc }
}) => (
  <div key={description} className={classes.itemCell}>
    <img alt={imageAlt} src={imageSrc} className={classes.itemImage} />
    <Typography
      variant="body2"
      align="center"
      className={classes.itemDescription}
    >
      {description}
    </Typography>
  </div>
)

export default CommonImageCell
