// @flow
import React from 'react'
import { keys } from 'lodash'
import {
  FormControl,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { getAddressTypeTitle, sortAddresses } from 'util/AddressHelper'
import addressTypes from 'constants/enums/addressTypes'
import { type AddressShape } from 'screens/customer/shapes/address'
import Address from 'screens/apply/components/Address'

import styles from '../../AddressVerification.styles'

type AddressMap = {
  id: string,
  title: string,
  address: AddressShape
}

type Props = {
  classes: any,
  addressMap: { [key: string]: AddressMap[] },
  stateSelectedId: any,
  addressMap: any,
  handleChange: (target: any, value: string) => void
}

const AddressList = ({
  classes,
  addressMap,
  stateSelectedId,
  handleChange
}: Props) =>
  keys(addressMap)
    .sort(sortAddresses)
    .map(key => {
      const addressByType = addressMap[key]
      const selectedId = stateSelectedId[key] || addressByType[0].id
      return (
        <FormControl
          component="fieldset"
          required
          error
          key={selectedId}
          className={classes.row}
        >
          <Typography variant="h2">
            {getAddressTypeTitle(
              key,
              keys(addressMap).includes(addressTypes.secondHome) ||
                keys(addressMap).includes(addressTypes.rental)
            )}
          </Typography>
          <RadioGroup
            aria-label="address"
            name="address"
            value={selectedId}
            onChange={({ target }) => handleChange(target, key)}
          >
            {addressByType.map(({ address, id, title }: AddressMap) => (
              <FormControlLabel
                className={classes.radioButtonControl}
                key={id}
                value={id}
                control={
                  <Radio color="primary" className={classes.addressRadio} />
                }
                label={
                  <Address title={title} address={address} classes={classes} />
                }
              />
            ))}
          </RadioGroup>
        </FormControl>
      )
    })

export default withStyles(styles)(AddressList)
