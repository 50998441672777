import { gql } from 'graphql.macro'
import QuoteProjectResponseFields from 'queries/fragments/QuoteProjectResponseFields.graphql'
import LoanProductResponseFields from 'queries/fragments/LoanProductResponseFields.graphql'
import SampleTermsResponseFields from 'queries/fragments/SampleTermsResponseFields.graphql'

export default gql`
  query ShareLinkDialogCustomerQuery($customerId: String!) {
    customer(customerId: $customerId) {
      id
      firstName
      lastName
      email
      phoneNumber
      dateShareLinkSentToSms
      communications {
        dateEmailsSent(emailType: SHARE_LINK)
      }

      creditApplication {
        decision {
          qualifiedProducts {
            ...LoanProductResponseFields
            sampleTerms {
              ...SampleTermsResponseFields
            }
          }
        }
      }

      quote {
        loanProductId
        projects {
          ...QuoteProjectResponseFields
        }
      }

      contract {
        loanProduct {
          id
          promoPeriod
        }
        terms {
          amountFinanced
          apr
        }
      }
    }
  }

  ${QuoteProjectResponseFields}
  ${LoanProductResponseFields}
  ${SampleTermsResponseFields}
`
