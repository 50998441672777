import React, { useEffect } from 'react'
import get from 'lodash/get'
import { useQuery } from '@apollo/client'
import { Redirect, withRouter } from 'react-router-dom'
import Routes from 'util/Routes'
import creditApplicationDecisionStatuses from 'constants/enums/creditApplicationDecisionStatuses'
import CreditStipResolution from 'components/apply/CreditStipResolution'
import GetCreditApplication from 'screens/apply/queries/GetCreditApplication.graphql'
import Loading from 'components/shared/Loading'

const UploadDocuments = ({ customerId }) => {
  const { loading, data } = useQuery(GetCreditApplication, {
    variables: { id: customerId }
  })

  if (loading) return <Loading />
  const { customer } = data
  const decisionStatus = get(customer, 'creditApplication.decision.status')

  if (decisionStatus === creditApplicationDecisionStatuses.needsReview)
    return <CreditStipResolution customer={customer} />

  return <Redirect to={Routes.apply(customerId)} />
}

export default UploadDocuments

const UnwrappedUploadDocumentsScreen = ({ match, overrideHeader }) => {
  useEffect(() => {
    overrideHeader({ hidden: true })
  }, [overrideHeader])

  return <UploadDocuments customerId={match.params.customerId} />
}

export const UploadDocumentsScreen = withRouter(UnwrappedUploadDocumentsScreen)
