// @flow
import React, { useState } from 'react'
import { Menu, MenuItem, Grid, IconButton } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import ArrowDropDownCircleOutlinedIcon from '@material-ui/icons/ArrowDropDownCircleOutlined'
import l10n from 'properties/translations'
import styles from './StageMenu.styles'
import { useSlidingPaperDispatch } from 'util/SlidingPaperContext'
import type { StageMenuActions } from './StageTypes'

type Props = {
  disabled: boolean,
  projectCount: Number,
  classes: {
    [string]: string
  },
  actions: StageMenuActions
}

const StageMenu = ({ actions, classes, disabled, projectCount }: Props) => {
  const [isOpen, setIsOpen] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const setShouldCloseSlidingPaper = useSlidingPaperDispatch()

  return (
    <Grid item xs={1}>
      <IconButton
        ref={ref => setAnchorEl(ref)}
        onClick={() => {
          setIsOpen(true)
          setShouldCloseSlidingPaper(true)
        }}
        disabled={projectCount <= 1 && disabled}
        className={classes.iconButton}
        data-testid="stage-menu-button"
      >
        <ArrowDropDownCircleOutlinedIcon
          className={disabled ? classes.iconDisabled : classes.icon}
        />
      </IconButton>

      <Menu data-testid="stage-menu" open={isOpen} anchorEl={anchorEl}>
        {Object.keys(actions).map(actionKey => (
          <MenuItem
            data-testid={`stage-menu-item-${actionKey}`}
            key={actionKey}
            onBlur={() => {
              setIsOpen(false)
            }}
            onClick={e => {
              setIsOpen(false)
              setShouldCloseSlidingPaper(true)
              actions[actionKey](e)
            }}
          >
            {l10n.quote.projectRow.projectRowMenuOptions[actionKey]}
          </MenuItem>
        ))}
      </Menu>
    </Grid>
  )
}

export default withStyles(styles)(StageMenu)
