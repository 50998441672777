import basePaths from 'constants/enums/basePaths'

import { UUIDRegex } from './constants'

const {
  creditDecision,
  customer,
  demoShare,
  loanAgreement,
  share,
  signOffer,
  setup,
  applyForFinancing,
  returnFromContractGuest,
  confirmStage,
  resubmitSsn
} = basePaths

export const LinkSharingRoutes = {
  share: `${share}/:refreshToken`,
  signOffer: `${signOffer}/:refreshToken`,
  applyForFinancing: `${applyForFinancing}/:refreshToken`,
  resubmitSsn: `${resubmitSsn}/:refreshToken`,
  loanAgreement: `${loanAgreement}/:refreshToken`,
  creditDecision: `${creditDecision}/:refreshToken`,
  demoShare: `${demoShare}/:refreshToken`,
  demoShareProject: `${demoShare}/:refreshToken/:projectId`,
  customer: `${customer}/:refreshToken(${UUIDRegex})`,
  setup: `${setup}/:refreshToken(${UUIDRegex})`,
  returnFromContractGuest: `${returnFromContractGuest}/:customerId/:signatorRole?`,
  confirmStage: `${confirmStage}/:refreshToken(${UUIDRegex})/:projectId`
}

export default LinkSharingRoutes
