export default {
  productionProducts: [
    [480, 744],
    [739, 743],
    [740, 745],
    [926, 927],
    [1048, 1050]
  ],
  demoProducts: [
    [842, 846],
    [582, 847],
    [843, 848],
    [1034, 1035]
  ],
  testProducts: [
    [557, 950],
    [945, 949],
    [946, 951],
    [1139, 1140]
  ]
}
