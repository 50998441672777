// @flow
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import l10n from 'properties/translations'
import NumberFormat from 'react-number-format'
import { phoneFormat } from 'util/PhoneFormatHelper'
import PaperCtn from '../PaperCtn'

const styles = theme => ({
  contactInfo: {
    flex: '1',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2)
    }
  },
  header: {
    color: theme.palette.common.darkGray
  },
  name: {
    fontWeight: 600
  }
})

const ContactInfo = ({ email, name, phone, header, classes, type }) => (
  <Grid
    container
    justify="center"
    direction="column"
    className={classes.contactInfo}
  >
    <Grid>
      <Typography
        variant="subtitle1"
        className={classes.header}
        data-testid={`${type}-contact-header`}
      >
        {header}
      </Typography>
    </Grid>
    <Grid item>
      <Typography
        variant="h1"
        className={classes.name}
        data-testid={`${type}-contact-name`}
      >
        {name}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1" data-testid={`${type}-contact-email`}>
        {email}
      </Typography>
    </Grid>
    <Grid item>
      <Typography variant="subtitle1">
        <NumberFormat
          displayType="text"
          data-testid={`${type}-contact-phone`}
          format={phoneFormat(phone)}
          value={phone}
        />
      </Typography>
    </Grid>
  </Grid>
)

type Props = {
  classes: Object,
  contacts: Object
}

const Step2 = ({
  classes,
  contacts: { primaryContact, secondaryContact }
}: Props) => (
  <PaperCtn data-testid="onboard-contact-info">
    <ContactInfo
      {...primaryContact}
      classes={classes}
      header={l10n.onboardingSteps.step2.primaryContact}
      type="primary"
    />
    <ContactInfo
      {...secondaryContact}
      classes={classes}
      header={l10n.onboardingSteps.step2.secondaryContact}
      type="secondary"
    />
  </PaperCtn>
)
export default withStyles(styles)(Step2)
