import { colors } from 'themes/default'

export default theme => ({
  inactiveColor: {
    color: theme.palette.grey[900]
  },
  lockedField: {
    backgroundColor: 'rgba(14, 97, 156, 0.15)',
    borderRadius: 5,
    height: 55,
    display: 'flex',
    justifyContent: 'center',
    '& input': {
      padding: theme.spacing(2),
      fontWeight: 500,
      color: `${colors.normalBlack} !important`
    }
  },
  inputAdornment: {
    paddingRight: theme.spacing(1)
  },
  lockedFieldIcon: {
    color: colors.lockedIconColor
  },
  inputContainer: {
    '& input': {
      fontSize: '20px'
    },
    '& ::placeholder': {
      color: colors.mutedBlack,
      opacity: 1
    }
  },
  formFieldLabel: {
    marginBottom: theme.spacing(1)
  },
  labelText: {
    fontWeight: 400
  },
  emailAndPhoneLabelInlineBlock: {
    display: 'inline-block'
  },
  emailVerificationInputWrapper: {
    '& input': {
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: '500',
      height: '44px',
      border: '1px solid rgba(0, 0, 0, 0.15)',
      letterSpacing: '0.1em',
      lineHeight: '1.2',
      color: 'rgba(36, 36, 43, 1)',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none'
    }
  },
  phoneVerificationInputWrapper: {
    '& input': {
      textAlign: 'center',
      fontSize: '24px',
      fontWeight: '500',
      height: '44px',
      border: '1px solid rgba(0, 0, 0, 0.15)',
      letterSpacing: '0.1em',
      lineHeight: '1.2',
      color: 'rgba(36, 36, 43, 1)',
      '-webkit-appearance': 'none',
      '-moz-appearance': 'none',
      appearance: 'none'
    }
  }
})
