export default {
  approved: 'APPROVED',
  pending: 'PENDING',
  blocked: 'BLOCKED',
  needsReview: 'NEEDS_REVIEW',
  declined: 'DECLINED',
  notQualified: 'NOT_QUALIFIED',
  needToVerifySsnDob: 'NEED_TO_VERIFY_SSN_DOB',
  needFullSsn: 'NEED_FULL_SSN'
}
