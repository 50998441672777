import { quoteErrors, quoteErrorTypes } from 'constants/enums/quoteErrors'
import { typenames } from 'properties/properties'

export const inputDefaults = {
  loanProductId: null,
  principal: 0,
  lumpSumPayment: 0,
  payDownPercentage: 0,
  hasAchDiscount: false,
  servicePlanAmount: 0
}

export const outputDefaults = {
  periodPaymentDollars: 0,
  reAmortizedPeriodPaymentDollars: 0,
  interestAccruedDollars: 0,
  interestAvoidedDollars: 0,
  fullLoanLengthPeriods: 0,
  totalPeriods: 0
}

export const QuoteDefaults = {
  updateLoanAmount: null,
  updateDiscounts: null,
  updateLumpSumPayment: null,
  updateLoanProducts: null,
  message: {
    __typename: 'message',
    text: null,
    timeout: null
  },
  quote: {
    __typename: typenames.Quote,
    error: [quoteErrors[quoteErrorTypes.NO_LOAN_PRODUCT]],
    view: '',
    configuration: {
      __typename: 'quote_configuration',
      loanProducts: [],
      projects: []
    },
    schedule: {
      __typename: 'quote_schedule',
      output: {
        __typename: 'quote_schedule_output',
        years: []
      }
    },
    calculate: {
      __typename: 'quote_calculate',
      input: {
        __typename: 'quote_calculate_input',
        ...inputDefaults
      },
      output: {
        __typename: 'quote_calculate_output',
        ...outputDefaults
      }
    },
    pristine: true
  }
}
