import React from 'react'
import basePaths from 'constants/enums/basePaths'
import TokenExpired from 'components/shared/TokenExpired'
import ForgotPassword from 'components/shared/ForgotPassword'
import ForgotPasswordEmail from 'components/shared/ForgotPasswordEmail'
import PasswordResetForm from 'screens/admin/team/PasswordResetForm'
import Complete from 'screens/setup/Complete'
import Welcome from 'screens/admin/welcome/Welcome'
import { UUIDRegex } from 'appConfig/constants'
import Loadable from 'util/Loadable'
import { LinkSharingRoutes } from './linkSharingRoutes'
import ErrorHandlingPage from 'components/shared/ErrorHandlingPage'

const LinkSharingFrame = Loadable(
  import('screens/linkSharing/LinkSharingFrame')
)
const LoginEntry = Loadable(import('screens/login/LoginEntry'))

const {
  customer,
  expired,
  passwordForgotten,
  passwordForgottenEmail,
  resetPassword,
  setup,
  welcome,
  login,
  apply,
  confirmStage,
  lease,
  uploadPersonalDocuments,
  verifyIdentity
} = basePaths

const publicRoutesConfig = [
  {
    path: login,
    component: LoginEntry,
    exact: true
  },
  {
    path: `${welcome}/:code`,
    component: Welcome,
    exact: true
  },
  {
    path: `${expired}`,
    component: TokenExpired,
    exact: true
  },
  {
    path: `${passwordForgotten}`,
    component: ForgotPassword,
    exact: true
  },
  {
    path: `${passwordForgottenEmail}`,
    component: ForgotPasswordEmail,
    exact: true
  },
  {
    path: `${resetPassword}/:code`,
    component: PasswordResetForm,
    exact: true
  },
  {
    path: LinkSharingRoutes.share,
    component: LinkSharingFrame
  },
  {
    path: LinkSharingRoutes.signOffer,
    render: props => <LinkSharingFrame {...props} redirect={apply} />
  },
  {
    path: LinkSharingRoutes.applyForFinancing,
    render: props => <LinkSharingFrame {...props} redirect={apply} />
  },
  {
    path: LinkSharingRoutes.resubmitSsn,
    render: props => <LinkSharingFrame {...props} redirect={verifyIdentity} />
  },
  {
    path: LinkSharingRoutes.loanAgreement,
    component: LinkSharingFrame
  },
  {
    path: LinkSharingRoutes.creditDecision,
    component: LinkSharingFrame
  },
  {
    path: LinkSharingRoutes.demoShare,
    render: props => <LinkSharingFrame {...props} demoMode />
  },
  {
    path: LinkSharingRoutes.demoShareProject,
    render: props => <LinkSharingFrame {...props} demoMode />
  },
  {
    path: LinkSharingRoutes.customer,
    render: props => <LinkSharingFrame {...props} redirect={customer} />
  },
  {
    path: LinkSharingRoutes.setup,
    render: props => <LinkSharingFrame {...props} redirect={setup} />
  },
  {
    path: LinkSharingRoutes.returnFromContractGuest,
    render: props => <Complete integrator guest />
  },
  {
    path: LinkSharingRoutes.confirmStage,
    render: props => <LinkSharingFrame {...props} redirect={confirmStage} />
  },
  {
    path: `${lease}`,
    render: props => <ErrorHandlingPage {...props} />
  },
  {
    path: `${lease}/*`,
    render: props => <ErrorHandlingPage {...props} />
  },
  {
    path: `${uploadPersonalDocuments}/:refreshToken`,
    render: props => <LinkSharingFrame {...props} />
  }
]

export default publicRoutesConfig
