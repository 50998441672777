// @flow

import { get, isEmpty } from 'lodash'
import type { CustomerShape } from 'screens/customer/shapes/customer'

export const applyIncomplete = (customer: CustomerShape) => {
  const applyComplete = get(customer, 'applyComplete', false)
  const dateESignVerified = get(customer, 'dateESignVerified', null)

  return !applyComplete || isEmpty(dateESignVerified)
}
