import { colors } from 'themes/default'

export default theme => ({
  root: {
    margin: 0
  },
  autoCompleteComboBox: {
    '& .MuiInputBase-input': {
      padding: `${theme.spacing(1)}px 0 !important`
    },
    '& .MuiInputBase-root': {
      paddingBottom: 0
    }
  },
  helperText: {
    marginTop: '3px',
    color: colors.mutedBlack,
    display: 'block',
    lineHeight: '1.4',
    fontWeight: 400
  }
})
