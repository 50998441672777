/* eslint-disable react/jsx-boolean-value */
// @flow
import React from 'react'
import { Grid, Typography, Button, CircularProgress } from '@material-ui/core'
import cx from 'classnames'
import RefreshIcon from '@material-ui/icons/Refresh'
import foundationLogo from 'images/FFC-logo.jpg'
import userTypes from 'constants/enums/userTypes'
import l10n from 'properties/translations'
import basePaths from 'constants/enums/basePaths'
import applicationStates from 'constants/enums/foundationApplicationDecisionStatuses'
import Loading from 'components/shared/Loading'

const applicationText = status => {
  switch (status) {
    case applicationStates.approved:
    case applicationStates.counteroffered:
    case applicationStates.autoApproved:
      return l10n.foundation.decisionScreen.titleApproved
    case applicationStates.refer:
    case applicationStates.withdrawn:
    case applicationStates.nodecisionrun:
    case applicationStates.pending:
    case applicationStates.notFound:
      return l10n.foundation.decisionScreen.titlePending
    case applicationStates.declined:
      return l10n.foundation.decisionScreen.titleDenied
    case applicationStates.error:
      return l10n.foundation.decisionScreen.titleError
    default:
      return l10n.foundation.decisionScreen.titlePending
  }
}

type Props = {
  classes: {
    container: string,
    root: string,
    logoContainer: string,
    logoItem: string,
    logo: string,
    mainTitle: string,
    fontColor: string,
    subTitle: string,
    userData: string,
    userNames: string,
    userFont: string,
    userAdditional: string,
    buttonContainer: string,
    buttonColor: string,
    buttonFont: string,
    borrowerDisclaimer: string,
    installerDisclaimer: string,
    anchorStyle: string,
    logoColor: string
  },
  repData: {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  },
  userType: string,
  applicationState: string,
  didTimeOut: boolean,
  errorOccurred: boolean,
  isLoading: boolean,
  getDecision: boolean,
  history: Object
}

const redirectToPipeline = (e, path, history) => {
  e.preventDefault()
  history.push(path)
  return false
}

const urlFoundation =
  'https://decisionlender.solutions/tci/#/auth/dealerLogin/foun405/dealer/default'
const DecisionScreen = ({
  classes,
  repData: { firstName, lastName, email, phoneNumber } = {},
  userType,
  didTimeOut,
  applicationState,
  errorOccurred,
  isLoading,
  getDecision,
  history
}: Props) => (
  <div className={classes.container}>
    <Grid container className={classes.root} direction="column">
      <Grid
        container
        item
        xs={12}
        justify="center"
        alignItems="center"
        className={classes.logoContainer}
      >
        <Grid item xs={4} className={classes.logoItem}>
          <img
            alt="Foundation logo"
            className={classes.logo}
            src={foundationLogo}
          />
        </Grid>
      </Grid>
      {!didTimeOut ? (
        <>
          <Grid item xs={12}>
            <Typography
              test-id="decisionTitle"
              variant="h1"
              className={cx(classes.mainTitle, classes.fontColor)}
            >
              {l10n.foundation.decisionScreen.processing}
            </Typography>
          </Grid>
          <Grid item xs={12} alignItems="center">
            <Loading block />
            <center>
              <Typography className={classes.fontColor}>
                {l10n.foundation.decisionScreen.processingMessage}
              </Typography>
            </center>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Typography
              test-id="decisionTitle"
              variant="h1"
              className={cx(classes.mainTitle, classes.fontColor)}
            >
              {applicationText(applicationState)}
            </Typography>
            <Typography className={cx(classes.fontColor, classes.subTitle)}>
              {l10n.foundation.decisionScreen.subTitle(applicationState)}
            </Typography>
          </Grid>
          {userType === userTypes.borrower && (
            <Grid
              test-id="refContainer"
              className={classes.userData}
              item
              xs={12}
            >
              <Grid>
                <Typography
                  test-id="nameComplete"
                  className={cx(classes.userNames, classes.userFont)}
                >{`${firstName} ${lastName}`}</Typography>
                <Typography
                  test-id="email"
                  className={cx(classes.userAdditional, classes.userFont)}
                >
                  {email}
                </Typography>
                <Typography
                  test-id="phoneNumber"
                  className={cx(classes.userAdditional, classes.userFont)}
                >
                  {phoneNumber}
                </Typography>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12} className={classes.buttonContainer}>
            <Button
              test-id="buttonRefresh"
              onClick={getDecision}
              className={cx(classes.buttonColor, classes.buttonFont)}
            >
              {errorOccurred && (
                <span>${l10n.foundation.decisionScreen.titleError}</span>
              )}
              {isLoading ? (
                <CircularProgress size={16} data-testid="circular-progress" />
              ) : (
                <>
                  REFRESH <RefreshIcon />
                </>
              )}
            </Button>
          </Grid>
        </>
      )}
    </Grid>
    {didTimeOut && userType === userTypes.installer && (
      <Grid
        test-id="installerContainer"
        container
        direction="column"
        className={classes.borrowerDisclaimer}
      >
        <Grid item xs={12}>
          <Typography className={classes.installerDisclaimer}>
            {l10n.foundation.decisionScreen.disclaimerInstaller}
          </Typography>
        </Grid>
        <Grid className={classes.buttonContainer} item xs={12}>
          <a
            className={classes.anchorStyle}
            href={urlFoundation}
            rel="noopener noreferrer"
            target="_blank"
          >
            <Button className={cx(classes.userAdditional, classes.buttonFont)}>
              {l10n.foundation.decisionScreen.linkButtonText}
              <svg
                className={classes.logoColor}
                xmlns="http://www.w3.org/2000/svg"
                width="14"
                height="14"
                viewBox="0 0 24 24"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path d="M9 5v2h6.59L4 18.59 5.41 20 17 8.41V15h2V5z" />
              </svg>
            </Button>
          </a>
        </Grid>
        <Grid className={classes.buttonContainer} item xs={12}>
          <Button
            onClick={e => {
              redirectToPipeline(e, basePaths.pipeline, history)
            }}
            className={cx(classes.userAdditional, classes.buttonFont)}
          >
            {l10n.foundation.decisionScreen.buttonText}
          </Button>
        </Grid>
      </Grid>
    )}
  </div>
)

export default DecisionScreen
