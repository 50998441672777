/* eslint-disable react/jsx-boolean-value */
// @flow
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import {
  Grid,
  Typography,
  FormControlLabel,
  Checkbox,
  Button,
  NativeSelect
} from '@material-ui/core'
import { Query, Mutation } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { ValidatorForm } from 'react-material-ui-form-validator'
import { format, parse } from 'date-fns'
import { set, flow } from 'lodash'
import Loading from 'components/shared/Loading'
import Error from 'components/shared/Error'
import foundationLogo from 'images/FFC-logo.jpg'
import l10n from 'properties/translations'
import { DEBOUNCE_TIME } from 'properties/properties'
import addressTypes from 'constants/enums/addressTypes'
import formConfig from 'properties/formConfig'
import placeHolder from 'properties/placeholders'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import ErrorToast, { errorToastIcon } from 'components/shared/ErrorToast'
import {
  loanOptions,
  loanProductTypes,
  otherIncomeSources
} from 'util/FoundationFormHelper'
import submitApplicationAlternativeFinancing from '../query/submitApplicationAlternativeFinancing.graphql'
import FoundationDisclosure from '../FoundationDisclosure'
import CustomerQuery from '../../customer/queries/CustomerQuery.graphql'
import styles from '../ApplicationForm.styles'
import YourLoan from './YourLoan'
import AboutYou from './AboutYou'
import WhereYouLive from './WhereYouLive'
import WhereYouWork from './WhereYouWork'
import OtherIncomeSection from './OtherIncomeSection'
import AboutCoApplicant from './CoApplicant/AboutCoApplicant'
import CoApplicantAddress from './CoApplicant/CoApplicantAddress'
import CoApplicantEmployment from './CoApplicant/CoApplicantEmployment'

interface Props {
  customerId: string;
  client: Mutation;
}

const defaultState = {
  submitting: false,
  success: false,
  errorOccurred: false,
  formError: false,
  checked: false,
  appFirstName: '',
  appLastName: '',
  driversLicense: '',
  email: '',
  dob: placeHolder.dobPlaceholder,
  applicantState: '',
  applicantDrState: formConfig.VALID_STATES[0],
  ssn: placeHolder.ssnPlaceHolder,
  applicantAlternatePhone: '',
  monthsAtPrimaryAddress: '',
  monthlyPaymentAtPrimary: '',
  applicantPhone: '',
  applicantEmploymentStatus:
    l10n.foundation.applicationContent.employementStatusPlaceholder,
  applicantEmployerName: '',
  applicantEmployerPhone: '',
  applicantMonthlyIncome: '',
  applicantJobTitle: '',
  periodOfEmploymentMonths: '',
  applicantLoanOption:
    l10n.foundation.applicationContent.loanProductPlaceHolder,
  applicantLoanProductType:
    l10n.foundation.applicationContent.loadProductTypePlaceholder,
  applicantFinancingAmount: '',
  requestedLoanTerms:
    l10n.foundation.applicationContent.requestedLoanTermsDefault,
  applicantDownpaymentAmount: '',
  applicantOtherIncomeSources: [],
  coApplicantOtherIncomeSources: [],
  otherIncomeDefault: formConfig.OTHER_INCOME_SOURCES.OTHER,
  coAppOtherIncomeDefault: formConfig.OTHER_INCOME_SOURCES.OTHER,
  showCoAppSec: false,
  coAppFirstName: '',
  coAppLastName: '',
  coAppPhoneNumber: '',
  coAppAlternatePhone: '',
  coAppEmail: '',
  coAppDob: '',
  coAppssn: '',
  coAppDriversLicense: '',
  coApplicantStreet: '',
  coApplicantCity: '',
  coApplicantState: formConfig.VALID_STATES[0],
  coApplicantDrState: formConfig.VALID_STATES[0],
  coApplicantZip: '',
  coAppMonthsAtResidence: '',
  coAppMonthlyPayment: '',
  coAppEmploymentStatus:
    l10n.foundation.applicationContent.employementStatusPlaceholder,
  coAppEmployerName: '',
  coAppEmployerPhoneNumber: '',
  coAppMonthlyIncome: '',
  coAppJobTitle: '',
  coAppPeriodOfEmploymentMonths: '',
  applicantStreet: '',
  applicantCity: '',
  applicantZip: '',
  appResidenceType: l10n.foundation.applicationContent.residenceTypePlaceholder,
  coAppResidenceType:
    l10n.foundation.applicationContent.residenceTypePlaceholder
}

class ApplicationFormContent extends Component<Props> {
  constructor(props) {
    super(props)
    this.count = 0 // prevents for setting state multiple times
    this.nameFlag = 0 // prevents for setting state multiple times
    this.otherIncomeSelection = this.otherIncomeSelection.bind(this)
    this.otherIncomeSelectionForCoApp =
      this.otherIncomeSelectionForCoApp.bind(this)
    this.handleSubmitForm = this.handleSubmitForm.bind(this)
    this.getAddressTypeTitle = this.getAddressTypeTitle.bind(this)
    this.state = defaultState
  }

  componentWillUnmount() {
    clearTimeout(this.formValidationTimeoutId)
  }

  setPrimaryResidence = key => {
    const { street, city, zip, stateAbbreviation } = key
    if (this.count < 1) {
      this.setState(state => ({
        applicantStreet: state.applicantStreet === '' ? street : '',
        applicantCity: state.applicantCity === '' ? city : '',
        applicantState: state.applicantState === '' ? stateAbbreviation : '',
        applicantZip: state.applicantZip === '' ? zip : ''
      }))
    }

    this.count += 1 // prevents for setting state multiple times
  }

  setApplicantsPrePopulatedData = (firstName, lastName, phoneNumber, email) => {
    if (this.nameFlag < 1) {
      this.setState(state => ({
        appFirstName: state.appFirstName === '' ? firstName : '',
        appLastName: state.appLastName === '' ? lastName : '',
        applicantPhone:
          state.applicantPhone === '' ? phoneNumber.toString() : '',
        email: state.email === '' ? email : ''
      }))
    }

    this.nameFlag += 1 // prevents for setting state multiple times
  }

  getAddressTypeTitle = key => {
    switch (key.addressType) {
      case addressTypes.primary:
        this.setPrimaryResidence(key)
        return l10n.foundation.applicationContent.primaryAddressTitle
      case addressTypes.rental:
      case addressTypes.secondHome:
        return l10n.foundation.applicationContent.projectAddressTitle
      case addressTypes.mailing:
        return l10n.foundation.applicationContent.mailingAddressTitle
      default:
        return null
    }
  }

  getErrorMessages = () => {
    const errorMessages = {}
    const { childs } = this.form

    // // "Childs" [term from rn-mui-form-validator] is TextValidator nodes
    childs.forEach(child => {
      if (child.state.validators && child.state.validators[0] === 'required') {
        if (!child.state.value) {
          set(errorMessages, child.props.name, false)
        } else {
          set(errorMessages, child.props.name, true)
        }
      }
    })
    return errorMessages
  }

  handleCheck(event) {
    this.setState({
      checked: event.target.checked
    })
  }

  handleFormChange = () => {
    clearTimeout(this.formValidationTimeoutId)
  }

  handleClose = () => {
    this.props.handleClose()
  }

  hasError = object => Object.keys(object).some(k => !object[k])

  formatCoApplicantDob = date => {
    const newDate = `${date.substr(0, 2)}-${date.substr(2, 4)}-${date.substr(
      4
    )}`
    const parsedDate = parse(newDate, 'MM-dd-yyyy')
    return format(parsedDate, 'yyyy-MM-dd')
  }

  // if no amount is entered, converts empty strings to Number types for each object
  forMartOtherIncomeSources = arr => {
    const newArray = arr
    // eslint-disable-next-line array-callback-return
    arr.map((item, index) => {
      const newAmountPerMonth = Number(item.amountPerMonth)
      newArray[index].amountPerMonth = newAmountPerMonth
    })
    return newArray
  }

  resetFormFields = () => {
    this.setState(defaultState)
  }

  handleSubmitForm = async () => {
    const {
      appFirstName,
      appLastName,
      driversLicense,
      applicantDrState,
      applicantStreet,
      applicantCity,
      applicantZip,
      applicantState,
      appResidenceType,
      monthsAtPrimaryAddress,
      applicantPhone,
      email,
      applicantEmployerName,
      applicantEmployerPhone,
      applicantMonthlyIncome,
      applicantJobTitle,
      periodOfEmploymentMonths,
      coAppPeriodOfEmploymentMonths,
      applicantEmploymentStatus,
      applicantOtherIncomeSources,
      coApplicantOtherIncomeSources,
      monthlyPaymentAtPrimary,
      applicantLoanOption,
      applicantLoanProductType,
      applicantFinancingAmount,
      requestedLoanTerms,
      applicantDownpaymentAmount,
      coAppFirstName,
      coAppLastName,
      coAppPhoneNumber,
      coAppAlternatePhone,
      coAppEmail,
      coAppDob,
      coAppssn,
      coAppMonthsAtResidence,
      coAppMonthlyPayment,
      coAppEmploymentStatus,
      coAppEmployerPhoneNumber,
      coAppEmployerName,
      coAppMonthlyIncome,
      coAppDriversLicense,
      coApplicantDrState,
      coAppJobTitle,
      coApplicantStreet,
      coApplicantCity,
      coApplicantState,
      coApplicantZip,
      coAppResidenceType,
      showCoAppSec
    } = this.state
    // Build co-application object
    const coApplicantOtherIncomeSourcesFormatted =
      this.forMartOtherIncomeSources(coApplicantOtherIncomeSources)
    const applicantOtherincomeSourcesFormatted = this.forMartOtherIncomeSources(
      applicantOtherIncomeSources
    )
    const coApplicant = {
      ssn: coAppssn,
      driversLicenseNumber: coAppDriversLicense,
      driversLicenseStateAbbreviation: coApplicantDrState,
      firstName: coAppFirstName,
      lastName: coAppLastName,
      address: {
        street: coApplicantStreet,
        city: coApplicantCity,
        stateAbbreviation: coApplicantState,
        zipCode: coApplicantZip,
        residenceType: coAppResidenceType
      },
      periodOfResidenceMonths: Number(coAppMonthsAtResidence),
      phone: coAppPhoneNumber,
      alternatePhone: coAppAlternatePhone,
      email: coAppEmail,
      dob: this.formatCoApplicantDob(coAppDob),
      employerIncomeSource: {
        employerName: coAppEmployerName,
        applicantWorkPhone: coAppEmployerPhoneNumber,
        monthlyIncome: Number(coAppMonthlyIncome),
        jobTitle: coAppJobTitle,
        periodOfEmploymentMonths: Number(coAppPeriodOfEmploymentMonths),
        employmentStatus: coAppEmploymentStatus
      },
      otherIncomeSources: coApplicantOtherIncomeSourcesFormatted,
      monthlyHousingPayment: Number(coAppMonthlyPayment)
    }

    const application = {
      applicant: {
        driversLicenseNumber: driversLicense,
        driversLicenseStateAbbreviation: applicantDrState,
        firstName: appFirstName,
        lastName: appLastName,
        address: {
          street: applicantStreet,
          city: applicantCity,
          stateAbbreviation: applicantState,
          zipCode: applicantZip,
          residenceType: appResidenceType
        },
        periodOfResidenceMonths: Number(monthsAtPrimaryAddress),
        phone: applicantPhone,
        email,
        employerIncomeSource: {
          employerName: applicantEmployerName,
          applicantWorkPhone: applicantEmployerPhone,
          monthlyIncome: Number(applicantMonthlyIncome),
          jobTitle: applicantJobTitle,
          periodOfEmploymentMonths: Number(periodOfEmploymentMonths),
          employmentStatus: applicantEmploymentStatus
        },
        otherIncomeSources: applicantOtherincomeSourcesFormatted,
        monthlyHousingPayment: Number(monthlyPaymentAtPrimary)
      },
      loanOption: applicantLoanOption,
      productType: applicantLoanProductType,
      totalFinancingAmount: Number(applicantFinancingAmount),
      loanTermMonths: Number(requestedLoanTerms),
      downPaymentAmount: Number(applicantDownpaymentAmount)
    }
    // If co-applicant exists, add co-applicant to the application object
    if (showCoAppSec) {
      application.coApplicant = coApplicant
    }
    const { client, customerId } = this.props

    // Get Error Messages if any
    const errorMessages = this.getErrorMessages()
    if (!this.hasError(errorMessages)) {
      try {
        this.setState({ submitting: true })
        const res = await client.mutate({
          mutation: submitApplicationAlternativeFinancing,
          variables: {
            customerId,
            application
          }
        })
        if (res.data && res.data.submitApplicationAlternativeFinancing) {
          // eslint-disable-next-line
          this.setState({ success: true })
          this.props.updateForm(true)
        }
      } catch (err) {
        // eslint-disable-next-line
        this.setState({ errorOccurred: true, submitting: false })
      }
    } else {
      this.setState({ formError: true })
    }
  }

  handleApplicantLoanOption = event => {
    const { value, name } = event.target
    switch (value) {
      case 'CLOSED_END_INSTALLMENT':
        this.setState({
          [name]: value,
          requestedLoanTerms: 120
        })
        break
      case 'REVOLVING':
        this.setState({
          [name]: value,
          requestedLoanTerms: 0
        })
        break
      default:
        this.setState({
          [name]: value
        })
    }
  }

  // sets all states from the user input
  handleFormState = event => {
    const { value, name } = event.target
    this.setState({
      [name]: value
    })
  }

  otherIncomeSelection(event) {
    const { value } = event.target
    const newObj = {
      incomeType: value,
      amountPerMonth: ''
    }
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState(prevState => ({
      otherIncomeDefault: formConfig.OTHER_INCOME_SOURCES.OTHER,
      applicantOtherIncomeSources: [
        ...prevState.applicantOtherIncomeSources,
        newObj
      ]
    }))
  }

  otherIncomeSelectionForCoApp(event) {
    const { value } = event.target
    const newObj = {
      incomeType: value,
      amountPerMonth: ''
    }
    // eslint-disable-next-line react/no-did-mount-set-state
    this.setState(prevState => ({
      coAppOtherIncomeDefault: formConfig.OTHER_INCOME_SOURCES.OTHER,
      coApplicantOtherIncomeSources: [
        ...prevState.coApplicantOtherIncomeSources,
        newObj
      ]
    }))
  }

  handleCoAppOtherIncomeSource = i => e => {
    const { value } = e.target
    const coApplicantOtherIncomeSources = [
      ...this.state.coApplicantOtherIncomeSources
    ]
    coApplicantOtherIncomeSources[i].incomeType = value
    this.setState({
      coApplicantOtherIncomeSources
    })
  }

  handleCoAppOtherIncome = i => e => {
    const { value } = e.target
    const coApplicantOtherIncomeSources = [
      ...this.state.coApplicantOtherIncomeSources
    ]
    coApplicantOtherIncomeSources[i].amountPerMonth = value
    this.setState({
      coApplicantOtherIncomeSources
    })
  }

  handleOtherIncomeSource = i => e => {
    const { value } = e.target
    const applicantOtherIncomeSources = [
      ...this.state.applicantOtherIncomeSources
    ]
    applicantOtherIncomeSources[i].incomeType = value
    this.setState({
      applicantOtherIncomeSources
    })
  }

  handleOtherIncome = i => e => {
    const { value } = e.target
    const applicantOtherIncomeSources = [
      ...this.state.applicantOtherIncomeSources
    ]
    applicantOtherIncomeSources[i].amountPerMonth = value
    this.setState({
      applicantOtherIncomeSources
    })
  }

  toogleCoApp = () => {
    this.setState(prevState => ({
      showCoAppSec: !prevState.showCoAppSec,
      coApplicantOtherIncomeSources: [],
      coAppOtherIncomeDefault: formConfig.OTHER_INCOME_SOURCES.OTHER,
      coAppFirstName: '',
      coAppLastName: '',
      coAppPhoneNumber: '',
      coAppAlternatePhone: '',
      coAppEmail: '',
      coAppDob: '',
      coAppssn: '',
      coAppDriversLicense: '',
      coApplicantStreet: '',
      coApplicantCity: '',
      coApplicantState: formConfig.VALID_STATES[0],
      coApplicantDrState: formConfig.VALID_STATES[0],
      coApplicantZip: '',
      coAppMonthsAtResidence: '',
      coAppMonthlyPayment: '',
      coAppEmploymentStatus:
        l10n.foundation.applicationContent.employementStatusPlaceholder,
      coAppEmployerName: '',
      coAppEmployerPhoneNumber: '',
      coAppMonthlyIncome: '',
      coAppJobTitle: '',
      coAppPeriodOfEmploymentMonths: '',
      coAppResidenceType:
        l10n.foundation.applicationContent.residenceTypePlaceholder
    }))
  }

  isPrimaryAddress = key => key.addressType === addressTypes.primary

  showError = showError => () => {
    this.setState({
      formError: showError
    })
  }

  render() {
    const { classes, customerId } = this.props
    const {
      submitting,
      checked,
      driversLicense,
      dob,
      applicantState,
      applicantDrState,
      ssn,
      applicantAlternatePhone,
      monthsAtPrimaryAddress,
      monthlyPaymentAtPrimary,
      applicantEmploymentStatus,
      applicantEmployerName,
      applicantEmployerPhone,
      applicantMonthlyIncome,
      applicantJobTitle,
      periodOfEmploymentMonths,
      applicantLoanOption,
      applicantLoanProductType,
      applicantFinancingAmount,
      applicantDownpaymentAmount,
      applicantOtherIncomeSources,
      otherIncomeDefault,
      showCoAppSec,
      coAppFirstName,
      coAppLastName,
      coAppPhoneNumber,
      coAppAlternatePhone,
      coAppEmail,
      coAppDob,
      coAppssn,
      coAppDriversLicense,
      coApplicantStreet,
      coApplicantCity,
      coApplicantState,
      coApplicantDrState,
      coApplicantZip,
      coAppResidenceType,
      coAppMonthsAtResidence,
      coAppMonthlyPayment,
      coAppEmploymentStatus,
      coAppEmployerName,
      coAppEmployerPhoneNumber,
      coAppMonthlyIncome,
      coAppJobTitle,
      coAppPeriodOfEmploymentMonths,
      coApplicantOtherIncomeSources,
      coAppOtherIncomeDefault,
      applicantStreet,
      applicantCity,
      applicantZip,
      appResidenceType,
      formError
    } = this.state
    // toggle disable button based on check box status
    const disabled = !checked || submitting

    return (
      <div className={classes.formContainer}>
        <ErrorToast
          message={l10n.foundation.applicationContent.formErrorMessage}
          open={formError}
          handleClose={this.showError(false)}
          iconType={errorToastIcon.serverError}
        />
        <Query query={CustomerQuery} variables={{ id: customerId }}>
          {({ data, loading, error }) => {
            if (loading) return <Loading />
            if (error) return <Error />
            const { customer } = data
            const { firstName, lastName, email, addresses, phoneNumber } =
              customer
            this.setApplicantsPrePopulatedData(
              firstName,
              lastName,
              phoneNumber,
              email
            )
            return (
              <ValidatorForm
                ref={r => {
                  // initial isFormValue value
                  if (!this.firstRef) {
                    this.firstRef = true
                    this.handleFormChange()
                  }
                  this.form = r
                }}
                debounceTime={DEBOUNCE_TIME}
                noValidate="novalidate"
                onChange={this.handleFormChange}
                onSubmit={this.handleSubmitForm}
                data-testid="foundation-form"
              >
                <Grid
                  container
                  item
                  xs={4}
                  justify="center"
                  alignItems="center"
                  className={classes.logoContainer}
                >
                  <Grid item xs={4} className={classes.logoItem}>
                    <img
                      alt="Foundation logo"
                      className={classes.logo}
                      src={foundationLogo}
                    />
                  </Grid>
                </Grid>
                <Grid container>
                  <Typography className={classes.foundationTitle}>
                    {l10n.foundation.applicationContent.title}
                  </Typography>
                </Grid>
                <Grid container>
                  <Typography className={classes.foundationParagraph}>
                    {l10n.foundation.applicationContent.paragraph}
                  </Typography>
                </Grid>
                <Grid
                  container
                  spacing={0}
                  className={classes.customerBoxGridStyle}
                >
                  <Grid
                    item
                    className={classes.customerNameBox}
                    xs={12}
                    sm={12}
                  >
                    <Typography>
                      {firstName} {lastName}
                    </Typography>
                  </Grid>
                </Grid>

                {/** About you section */}
                <AboutYou
                  classes={classes}
                  handleFormState={this.handleFormState}
                  driversLicense={driversLicense}
                  applicantDrState={applicantDrState}
                  email={email}
                  dob={dob}
                  ssn={ssn}
                  phoneNumber={phoneNumber}
                  applicantAlternatePhone={applicantAlternatePhone}
                />
                {/** Where you live section */}
                <Grid container className={classes.secondSectionGrid}>
                  <Typography className={classes.sectionTitleTextStyle}>
                    {l10n.foundation.applicationContent.whereYouLiveLabel}
                  </Typography>
                </Grid>

                {/** Address in where you live section */}
                {/** If project address type is not primary show this Primary Address section */}
                <WhereYouLive
                  classes={classes}
                  isPrimaryAddress={this.isPrimaryAddress}
                  handleFormState={this.handleFormState}
                  addresses={addresses}
                  getAddressTypeTitle={this.getAddressTypeTitle}
                  monthsAtPrimaryAddress={monthsAtPrimaryAddress}
                  monthlyPaymentAtPrimary={monthlyPaymentAtPrimary}
                  appResidenceType={appResidenceType}
                  applicantStreet={applicantStreet}
                  applicantCity={applicantCity}
                  applicantState={applicantState}
                  applicantZip={applicantZip}
                />

                {/** Where you work section */}
                <Grid container className={classes.secondSectionGrid}>
                  <Typography className={classes.sectionTitleTextStyle}>
                    {l10n.foundation.applicationContent.whereYouWorkLabel}
                  </Typography>
                </Grid>

                <WhereYouWork
                  classes={classes}
                  handleFormState={this.handleFormState}
                  applicantEmploymentStatus={applicantEmploymentStatus}
                  applicantEmployerName={applicantEmployerName}
                  applicantEmployerPhone={applicantEmployerPhone}
                  applicantMonthlyIncome={applicantMonthlyIncome}
                  applicantJobTitle={applicantJobTitle}
                  periodOfEmploymentMonths={periodOfEmploymentMonths}
                />

                {/** Your Loan section */}
                <YourLoan
                  classes={classes}
                  customerId={customerId}
                  applicantFinancingAmount={applicantFinancingAmount}
                  applicantLoanOption={applicantLoanOption}
                  loanOptions={loanOptions}
                  applicantLoanProductType={applicantLoanProductType}
                  loanProductTypes={loanProductTypes}
                  applicantDownpaymentAmount={applicantDownpaymentAmount}
                  handleApplicantLoanOptionChange={
                    this.handleApplicantLoanOption
                  }
                  handleFormStateChange={this.handleFormState}
                />
                {/** Other income section */}
                <Grid container className={classes.secondSectionGrid}>
                  <Typography className={classes.sectionTitleTextStyle}>
                    {
                      l10n.foundation.applicationContent
                        .yourAdditionalIncomeLabel
                    }
                  </Typography>
                </Grid>

                {/** Applicant other income section */}
                <OtherIncomeSection
                  classes={classes}
                  applicantOtherIncomeSources={applicantOtherIncomeSources}
                  handleOtherIncomeSource={this.handleOtherIncomeSource}
                  handleOtherIncome={this.handleOtherIncome}
                />

                {/** This will add another additional income component */}
                <Grid container spacing={0}>
                  <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
                    <div className={classes.fieldTitleStyle}>
                      {
                        l10n.foundation.applicationContent
                          .otherIncomeSourceLabel
                      }
                      <br />
                      <NativeSelect
                        value={otherIncomeDefault}
                        onChange={this.otherIncomeSelection}
                        className={classes.nativeSelectDropStyle}
                      >
                        {otherIncomeSources}
                      </NativeSelect>
                    </div>
                  </Grid>
                </Grid>

                {/** Co-Applicant section - behind feature flag */}

                <Grid container className={classes.coApplicantGreenBox}>
                  <Button
                    onClick={this.toogleCoApp}
                    variant="outlined"
                    className={classes.removeBtn}
                  >
                    {`${
                      showCoAppSec
                        ? l10n.foundation.applicationContent.removeButtonLabel
                        : l10n.foundation.applicationContent.addButtonLabel
                    }`}
                  </Button>
                </Grid>

                {/** Co-applicant details section , show if add button clicked */}

                {showCoAppSec && (
                  <div>
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.secondSectionGrid}
                      >
                        <Typography className={classes.sectionTitleTextStyle}>
                          {
                            l10n.foundation.applicationContent
                              .aboutCoApplicantLabel
                          }
                        </Typography>
                      </Grid>
                    </Grid>

                    {/** Co-Applicant fieldset */}
                    <AboutCoApplicant
                      classes={classes}
                      handleFormState={this.handleFormState}
                      coAppFirstName={coAppFirstName}
                      coAppLastName={coAppLastName}
                      coAppDriversLicense={coAppDriversLicense}
                      coAppEmail={coAppEmail}
                      coAppDob={coAppDob}
                      coAppssn={coAppssn}
                      coAppPhoneNumber={coAppPhoneNumber}
                      coAppAlternatePhone={coAppAlternatePhone}
                      coApplicantDrState={coApplicantDrState}
                    />

                    {/** Co-Applicant fieldset Address section */}
                    <CoApplicantAddress
                      classes={classes}
                      handleFormState={this.handleFormState}
                      coApplicantStreet={coApplicantStreet}
                      coApplicantCity={coApplicantCity}
                      coApplicantState={coApplicantState}
                      coApplicantZip={coApplicantZip}
                      coAppResidenceType={coAppResidenceType}
                      coAppMonthsAtResidence={coAppMonthsAtResidence}
                      coAppMonthlyPayment={coAppMonthlyPayment}
                    />

                    {/** Co-Applicant fieldset Working section */}
                    <CoApplicantEmployment
                      classes={classes}
                      handleFormState={this.handleFormState}
                      coAppEmploymentStatus={coAppEmploymentStatus}
                      coAppEmployerName={coAppEmployerName}
                      coAppEmployerPhoneNumber={coAppEmployerPhoneNumber}
                      coAppMonthlyIncome={coAppMonthlyIncome}
                      coAppJobTitle={coAppJobTitle}
                      coAppPeriodOfEmploymentMonths={
                        coAppPeriodOfEmploymentMonths
                      }
                    />

                    {/** Co App Additional Income */}

                    <Grid container className={classes.secondSectionGrid}>
                      <Typography
                        className={classes.coApplicantAdditionalIncomeStyle}
                      >
                        {
                          l10n.foundation.applicationContent
                            .coApplicantAdditionalIncome
                        }
                      </Typography>
                    </Grid>

                    {/** Co Applicant Other Income Section */}

                    <OtherIncomeSection
                      classes={classes}
                      applicantOtherIncomeSources={
                        coApplicantOtherIncomeSources
                      }
                      handleOtherIncomeSource={
                        this.handleCoAppOtherIncomeSource
                      }
                      handleOtherIncome={this.handleCoAppOtherIncome}
                    />

                    {/** This will add another additional income component */}
                    <Grid container spacing={0}>
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        className={classes.gridItemStyle}
                      >
                        <div className={classes.fieldTitleStyle}>
                          {
                            l10n.foundation.applicationContent
                              .otherIncomeSourceLabel
                          }
                          <br />
                          <NativeSelect
                            value={coAppOtherIncomeDefault}
                            onChange={this.otherIncomeSelectionForCoApp}
                            className={classes.nativeSelectDropStyle}
                          >
                            {otherIncomeSources}
                          </NativeSelect>
                        </div>
                      </Grid>
                    </Grid>
                  </div>
                )}

                {/** Foundation Disclosure started */}
                <Grid container spacing={0}>
                  <Grid item xs={12} className={classes.gridItemStyle}>
                    <FoundationDisclosure appContent classes={classes} />
                  </Grid>
                </Grid>
                <Grid container className={classes.checkboxGridStyle}>
                  <Grid item xs={12} className={classes.gridItemStyle}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={checked}
                          onChange={e => this.handleCheck(e)}
                          classes={{
                            root: classes.root,
                            checked: classes.checked
                          }}
                        />
                      }
                      label={
                        l10n.foundation.applicationContent.checkboxAgreement
                      }
                    />
                  </Grid>
                </Grid>
                <Grid container className={classes.ctaBtnGridStyle}>
                  <Button
                    onClick={this.handleClose}
                    variant="outlined"
                    className={classes.cancelBtn}
                  >
                    {l10n.foundation.applicationContent.cancel}
                  </Button>
                  <Button
                    disabled={disabled}
                    type="submit"
                    variant="contained"
                    className={`${classes.continueBtn} submitBtn`}
                    onClick={this.handleSubmitForm}
                  >
                    {l10n.foundation.applicationContent.continue}
                  </Button>
                </Grid>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Typography className={classes.foundationParagraph}>
                      {l10n.foundation.applicationContent.acknowledgementText}
                    </Typography>
                  </Grid>
                </Grid>
              </ValidatorForm>
            )
          }}
        </Query>
      </div>
    )
  }
}

export default flow(
  withLaunchDarkly,
  withApolloConsumer,
  withStyles(styles)
)(ApplicationFormContent)
