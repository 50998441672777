import { gql } from 'graphql.macro'

export default gql`
  query creditApplicationStatus($customerId: String!) {
    customer(customerId: $customerId) {
      id
      creditApplicationAlternativeFinancing {
        status
      }
    }
  }
`
