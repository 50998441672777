// @flow
import React, { useState } from 'react'
import { v4 as uuidv4 } from 'uuid'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import basePaths from 'constants/enums/basePaths'
import userTypes from 'constants/enums/userTypes'
import PrequalificationRouteContext from 'util/PrequalificationRouteContext'
import {
  subdomainSupportsWeblinks,
  getAllowDenyHostnamesConfig
} from 'util/PrequalificationHelper'
import { isLoggedIn } from 'util/SessionHelper'
import PrequalificationForm from 'screens/apply/PrequalificationForm'
import {
  getAppVersionString,
  getAppVersionCommitHash
} from 'util/EnvironmentHelper'
import { redirectToLoginWithAttemptedLink } from 'util/Routes'

const identifyLDGuest = async ({
  ldClient,
  match,
  setHasIdentifiedLDGuest
}) => {
  const version = getAppVersionString()
  ldClient.identify({
    key: `guest-${uuidv4()}`,
    custom: {
      partnerCode: match.params.partnerCode,
      version,
      commitHash: getAppVersionCommitHash()
    }
  })
  setHasIdentifiedLDGuest(true)
}
const ALL_USER_TYPES = Object.values(userTypes)

export const PrequalificationFormWrapper = withLaunchDarkly(props => {
  const [hasIdentifiedLDGuest, setHasIdentifiedLDGuest] = useState(false)
  const {
    ldFlags,
    ldClient,
    match,
    location: { pathname }
  } = props
  const allowDenyHostnamesConfig = getAllowDenyHostnamesConfig(ldFlags)

  if (subdomainSupportsWeblinks()) {
    if (!isLoggedIn() && !hasIdentifiedLDGuest) {
      identifyLDGuest({ ldClient, match, setHasIdentifiedLDGuest })
    }
    return (
      <PrequalificationRouteContext.Provider
        value={{
          isSwiftlinks: true
        }}
      >
        <PrequalificationForm {...props} />
      </PrequalificationRouteContext.Provider>
    )
  }
  return redirectToLoginWithAttemptedLink(pathname)
})

export const PrequalificationRoute = {
  path: `${basePaths.weblinksPartnerPath}:partnerCode`,
  showLogo: true,
  component: PrequalificationFormWrapper,
  allowedUserTypes: ALL_USER_TYPES,
  hideHeader: true,
  noLogin: true
}

export default PrequalificationRoute
