import addressTypes from 'constants/enums/addressTypes'

const VALID_STATES = [
  'AK',
  'AL',
  'AR',
  'AS',
  'AZ',
  'CA',
  'CO',
  'CT',
  'DC',
  'DE',
  'FL',
  'FM',
  'GA',
  'GU',
  'HI',
  'IA',
  'ID',
  'IL',
  'IN',
  'KS',
  'KY',
  'LA',
  'MA',
  'MD',
  'ME',
  'MH',
  'MI',
  'MN',
  'MO',
  'MP',
  'MS',
  'MT',
  'NC',
  'ND',
  'NE',
  'NH',
  'NJ',
  'NM',
  'NV',
  'NY',
  'OH',
  'OK',
  'OR',
  'PA',
  'PR',
  'PW',
  'RI',
  'SC',
  'SD',
  'TN',
  'TX',
  'UT',
  'VA',
  'VI',
  'VT',
  'WA',
  'WI',
  'WV',
  'WY'
]

const ADDRESS_TYPES = [
  {
    addressType: addressTypes.primary,
    displayKey: 'primary'
  },
  {
    addressType: addressTypes.secondHome,
    displayKey: 'secondHome'
  },
  {
    addressType: addressTypes.rental,
    displayKey: 'rental'
  }
]

const RESIDENCE_TYPES = Object.freeze({
  RENT: 'Rent',
  OWN_PAID_IN_FULL: 'Own paid in full',
  MOBILE_HOME_WITHOUT_LAND: 'Mobile home without land',
  MOBILE_HOME_WITH_LAND: 'Mobile home with land',
  OTHER: 'Other',
  OWN_WITH_MORTGAGE: 'Own with mortgage'
})

const EMPLOYMENT_STATUS = Object.freeze({
  FULL_TIME: 'Full time',
  PART_TIME: 'Part time',
  UNEMPLOYED: 'Unemployed',
  STUDENT: 'Student',
  SELF_EMPLOYED: 'Self employed',
  RETIRED: 'Retired'
})

const LOAN_OPTIONS = Object.freeze({
  CLOSED_END_INSTALLMENT: 'Closed end installment',
  REVOLVING: 'Revolving'
})

const LOAN_PRODUCT_TYPES = Object.freeze({
  BATHROOM_REMODEL: 'Bathroom Remodel',
  SOLAR_ENERGY_EFFICIENCY: 'Solar / Energy Efficiency',
  HVAC: 'HVAC',
  WATER_TREATMENT: 'Water Treatment',
  ROOFING: 'Roofing',
  BASEMENT_FINISHING: 'Basement Finishing',
  BASEMENT_WATERPROOFING: 'Basement Waterproofing',
  BLINDS_SHADES: 'Blinds/Shades',
  CEMENT_CONCRETE: 'Cement/Concrete',
  DOG_FENCES: 'Dog Fences',
  DOORS: 'Doors',
  ELECTRICAL: 'Electrical',
  FENCES: 'Fences',
  FIREPLACES_CHIMNEYS_STOVES: 'Fireplaces/Chimneys/Stoves',
  FLOORING: 'Flooring',
  FOUNDATION: 'Foundation',
  GARAGE_STORAGE_SHEDS_BUILDINGS: 'Garage/Storage/Sheds/Buildings',
  GUTTERS: 'Gutters',
  INSULATION: 'Insulation',
  KITCHEN_REMODEL: 'Kitchen Remodel',
  LANDSCAPING: 'Landscaping',
  MOLD_REMEDIATION: 'Mold remediation',
  NON_INSTALLED: 'Non-Installed',
  OTHER: 'Other',
  OTHER_EXTERIOR: 'Other Exterior',
  OTHER_INTERIOR: 'Other Interior',
  OUTDOOR_LIGHTING: 'Outdoor lighting',
  PAINT: 'Paint',
  PATIOS_DECKS: 'Patios/Decks',
  PLUMBING: 'Plumbing',
  POOLS_SPAS: 'Pools/Spas',
  ROOM_ADDITIONS: 'Room Additions',
  SHELVING: 'Shelving',
  SIDING: 'Siding',
  STAIRLIFTS_MOBILITY: 'Stairlifts/Mobility',
  WALKIN_TUBS_SHOWERS: 'Walk-in tubs/showers',
  WINDOWS: 'Windows'
})

const OTHER_INCOME_SOURCES = Object.freeze({
  OTHER: 'Other',
  UNEMPLOYMENT: 'Unemployment',
  SOCIAL_SECURITY: 'Social security',
  SECOND_JOB: 'Second job',
  RETIREMENT_INCOME: 'Retirement income',
  RENTAL: 'Rental',
  PENSION: 'Pension',
  MILITARY_PENSION: 'Military pension',
  INVESTMENT: 'Investment',
  DISABILITY: 'Disability',
  COURT_ORDERED_PAYMENT: 'Court ordered payment',
  CHILD_SUPPORT: 'Child support',
  ALIMONY: 'Alimony'
})

export default {
  ADDRESS_TYPES,
  VALID_STATES,
  EMPLOYMENT_STATUS,
  LOAN_OPTIONS,
  LOAN_PRODUCT_TYPES,
  OTHER_INCOME_SOURCES,
  RESIDENCE_TYPES
}
