const projectTypesId = {
  solar: 1,
  roofing: 2,
  windows: 3,
  doors: 4,
  hvac: 5,
  interiorRemodel: 6,
  exteriorRemodel: 7,
  battery: 8,
  otherOrCustom: 20
}

export default projectTypesId
