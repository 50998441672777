// @flow
import type { SharedText } from './sharedText.type'

const sharedText: SharedText = {
  accountNumber: 'Número de cuenta',
  adminTeam: 'Equipo',
  adminTeamAdd: 'Invite a los miembros del equipo',
  adminAccount: 'Cuenta',
  adminAccountDocuments: 'Documentos de Cuenta',
  adminProducts: 'Planes de pago',
  adminBankAccount: 'Cuenta bancaria',
  applicant: 'Solicitante',
  back: 'Volver',
  cancel: 'Cancelar',
  cancelled: 'Cancelado',
  checking: 'De cheques',
  close: 'Cerrar',
  complete: 'Completo',
  completed: 'Finalizado',
  continue: 'Continuar',
  confirm: 'Confirmar',
  customer: 'Cliente',
  customerInfo: 'Información de cliente',
  disclosures: 'Divulgaciones',
  dob: 'Fecha de nacimiento',
  done: 'Terminar',
  edit: 'Editar',
  editOffer: 'Editar oferta',
  emailAddress: 'Email',
  firstName: 'Nombre',
  middleName: 'Segundo nombre (Opcional)',
  goBack: 'Volver',
  gotIt: 'OK',
  incomplete: 'Incompleto',
  lastName: 'Apellido',
  monthlyPayment: 'Mensualidad',
  newCustomer: 'Cliente Nuevo',
  next: 'Siguiente',
  optOutSMS: 'Este usuario prefiere no recibir mensajes SMS',
  phoneNumber: 'Número de móvil',
  pleaseWait: 'Espere',
  processing: 'Procesando',
  confirmCompletion: 'Confirmar Finalización',
  waitingForConfirmation: 'Esperando Confirmación',
  confirmed: 'Confirmado',
  projectId: 'ID del proyecto',
  projectDescription: 'Descripción del proyecto',
  document: 'Documento',
  uploaded: 'Subido',
  projectAddress: 'Dirección del proyecto',
  loan: 'Préstamo',
  projectCost: 'Costo del proyecto',
  projectName: 'Nombre del proyecto',
  projectStage: 'Etapa',
  project: 'Proyecto',
  projects: 'Proyectos',
  projectType: 'Tipo de proyecto',
  projectTypes: 'Tipos de proyecto',
  refresh: 'Actualizar',
  remove: 'Eliminar',
  resources: 'Recursos',
  reviewAndAccept: 'Descripción general de los términos',
  reviewAutopay: 'Revisar pago automático',
  required: 'Obligatorio',
  routingNumber: 'Número de redireccionamiento',
  savings: 'Ahorros',
  send: 'Enviar',
  signedTitle: 'Ha completado el proceso de financiación',
  stage: 'etapa',
  stageCost: 'Costo de etapa',
  stageName: 'Nombre de etapa',
  start: 'Inicio',
  status: 'Estado',
  street: 'Calle',
  submit: 'Enviar',
  summary: 'Resumen',
  unknownStatus: 'Estado desconocido',
  viewAgreement: 'Ver acuerdo',
  emailMustBeValid: 'Debe ser una dirección de correo electrónico válida.',
  emailInstaller:
    'Por favor, introduzca la dirección de correo electrónico de su cliente.',
  phoneNumberIsInvalid:
    'Número de móvil inválido. Por favor ingrese un número de móvil válido para continuar.',
  phoneNumberIsOptedOut:
    'Este número de teléfono ha optado por no recibir mensajes de texto.',
  copy: 'Copiar',
  copied: 'Copiado',
  setupAutopay: 'Configurar pago automático',
  welcome: 'Bienvenidos',
  salesAgreement: 'Acuerdo de Ventas',
  projectApproved: 'Aprobado',
  projectNew: 'Nuevo'
}

export default sharedText
