/* eslint-disable */
import { get, isEmpty } from 'lodash'
import Client from 'gql/clientNew'
import ReactSessionApi from 'react-session-api'
import {
  SWIFTLINK_URL,
  E2E_DOMAIN,
  SWIFTLINK_URL_QA,
  PUBLIC_APPLY_FORM_SUBDOMAIN_REGEX,
  EMPTY_STRING,
  SPACE_SEPARATOR,
  PLUS_SYMBOL,
  AGGREGATOR_CODE_REGEX,
  TESTING_DOMAIN,
  SWIFTLINK_VARIATIONS
} from 'properties/properties'
import userTypes from 'constants/enums/userTypes'
import customerLoanTypes from 'constants/enums/customerLoanTypes'
import createTheme, { colors } from 'themes/default'
import themeOverrideKeys from 'constants/enums/themeOverrideKeys'
import sessionStorageKeys from 'constants/enums/sessionStorageKeys'
import creditApplicationDecisionStatuses from 'constants/enums/creditApplicationDecisionStatuses'
import creditApplicationStatuses from 'constants/enums/creditApplicationStatuses'
import { cookiesEnabled, storeUserTokens } from 'util/SessionHelper'
import prequalificationFormStatuses from 'constants/enums/prequalificationFormStatuses'
import { isDevelopmentEnv } from 'util/EnvironmentHelper'
import featureFlags from 'constants/enums/featureFlags'
import errorTypes from 'constants/enums/errorTypes'
import { makeSafeSessionStorage } from 'util/makeSafeStorage'
import { track, identify } from 'util/AnalyticsHelper'
import MonitoringHelper from 'util/MonitoringHelper'
import { getCreditApplication } from 'util/NavigationHelper'
import GetPhoneInformation from 'screens/apply/queries/GetPhoneInformation.graphql'
import { selectProjectsDataForMutation } from 'util/ApplyHelper'
import UpdateQuote from 'queries/UpdateQuote.graphql'
import Session from 'react-session-api'
import localStorageKeys from 'constants/enums/localStorageKeys'
import windowPostMessageTypes from 'constants/enums/windowPostMessageTypes'
import analyticsEventTypes from 'constants/enums/analyticsEventTypes'
import { GET_QUOTE } from 'screens/quote/QuoteStore'
import customerFields from 'constants/enums/customerFields'
import {
  postMessageToParentWindow,
  getStoredIframeConfiguration
} from './IframeHelper'

const safeSessionStorage = makeSafeSessionStorage()
export const subdomainSupportsWeblinks = () => {
  const hostname = get(window, 'location.hostname', EMPTY_STRING)
  return PUBLIC_APPLY_FORM_SUBDOMAIN_REGEX.test(hostname) || isDevelopmentEnv()
}

const isTestingOrDev =
  Boolean(window.location.host.includes(`.${TESTING_DOMAIN}.`)) ||
  isDevelopmentEnv()
const isE2e = Boolean(window.location.host.includes(E2E_DOMAIN))

export const SWIFTLINK_SUBDOMAIN = isTestingOrDev
  ? SWIFTLINK_URL_QA
  : SWIFTLINK_URL(isE2e)

export const shouldSeeWeblinksPartnerNotFoundErrorPage = userType =>
  userType !== userTypes.installer

export const getCurrentPartnerTheme = (themeColors, themeOverrideKey) => {
  let updatedColors = colors
  if (themeColors && themeOverrideKey === themeOverrideKeys.default) {
    updatedColors = {
      ...colors,
      primary: themeColors.primaryColor,
      secondary: themeColors.secondaryColor
    }
  }
  return createTheme(updatedColors)
}

export const setEnteredCustomer = value => {
  if (!cookiesEnabled()) {
    Session.set(
      sessionStorageKeys.publicApplyFormEnteredCustomerKey,
      JSON.stringify(value)
    )
  } else {
    safeSessionStorage.setItem(
      sessionStorageKeys.publicApplyFormEnteredCustomerKey,
      JSON.stringify(value)
    )
  }
}

export const getEnteredCustomer = () => {
  try {
    return (
      JSON.parse(
        safeSessionStorage.getItem(
          sessionStorageKeys.publicApplyFormEnteredCustomerKey
        )
      ) || {}
    )
  } catch (_) {
    return {}
  }
}

export const clearEnteredCustomer = () => {
  if (!cookiesEnabled()) {
    Session.remove(sessionStorageKeys.publicApplyFormEnteredCustomerKey)
  } else {
    safeSessionStorage.removeItem(
      sessionStorageKeys.publicApplyFormEnteredCustomerKey
    )
  }
}

export const setCustomerIdInStorage = value => {
  if (!cookiesEnabled()) {
    Session.set(sessionStorageKeys.customerId, value)
  } else {
    safeSessionStorage.setItem(sessionStorageKeys.customerId, value)
  }
}

export const clearCustomerIdInStorage = () => {
  if (!cookiesEnabled()) {
    Session.remove(sessionStorageKeys.customerId)
  } else {
    safeSessionStorage.removeItem(sessionStorageKeys.customerId)
  }
}

export const getCustomerIdFromStorage = () => {
  try {
    if (!cookiesEnabled()) {
      return Session.getItem(sessionStorageKeys.customerId)
    } else {
      return safeSessionStorage.getItem(sessionStorageKeys.customerId)
    }
  } catch (_) {
    return EMPTY_STRING
  }
}

export const isAggregatorCodePresent = () =>
  AGGREGATOR_CODE_REGEX.test(get(window, 'location.search', EMPTY_STRING))

export const getAllowDenyHostnamesConfig = (ldFlags = []) => {
  return get(ldFlags, featureFlags.swiftlinksHostnameDenyList, {
    allow: [],
    deny: []
  })
}

export const allHostnamesAllowed = allowDenyHostnamesConfig => {
  const allowedPublicHostnames = get(allowDenyHostnamesConfig, 'allow', [])
  const deniedPublicHostnames = get(allowDenyHostnamesConfig, 'deny', [])
  return isEmpty(allowedPublicHostnames) && isEmpty(deniedPublicHostnames)
}

export const noHostnamesAllowed = allowDenyHostnamesConfig => {
  const deniedPublicHostnames = get(allowDenyHostnamesConfig, 'deny', [])
  return deniedPublicHostnames.includes(SWIFTLINK_VARIATIONS.ALL)
}

export const isHostnameAllowed = (allowDenyHostnamesConfig, hostname) => {
  const allowedPublicHostnames = get(allowDenyHostnamesConfig, 'allow', [])
  const deniedPublicHostnames = get(allowDenyHostnamesConfig, 'deny', [])
  if (deniedPublicHostnames.includes(hostname)) return false
  return (
    isEmpty(allowedPublicHostnames) || allowedPublicHostnames.includes(hostname)
  )
}

export const shouldHostnameBeAllowed = (
  allowDenyHostnamesConfig,
  partnerCode
) =>
  allHostnamesAllowed(allowDenyHostnamesConfig) ||
  isHostnameAllowed(allowDenyHostnamesConfig, partnerCode)

const completedStatuses = [
  creditApplicationDecisionStatuses.approved,
  creditApplicationDecisionStatuses.blocked,
  creditApplicationDecisionStatuses.notQualified,
  creditApplicationDecisionStatuses.needsReview,
  creditApplicationDecisionStatuses.declined
]
const scrollStatuses = [
  ...completedStatuses,
  prequalificationFormStatuses.processing
]

export const preventScrolling = () => {
  if (!window) return

  document.querySelector('#root').style = 'overflow-y: hidden'
}

export const postApplicationStatusMessageToParentWindowForCustomer = (
  contentHeight,
  decision
) => {
  if (!decision) return

  if (!window || window.top === window.self) return

  const topCoordinates = { x: 0, y: 0 }

  const statusObject = {
    author: 'mosaic',
    name: windowPostMessageTypes.applicationStatus,
    timestamp: new Date().getTime(),
    data: {
      decision
    },
    frameInfo: {
      recommendedScrollTo: scrollStatuses.includes(decision)
        ? topCoordinates
        : undefined,
      recommendedMinimumSize: {
        height: contentHeight
      },
      recommendedContinueButtonVisibility: {
        visible: completedStatuses.includes(decision)
      }
    }
  }
  try {
    postMessageToParentWindow(statusObject)

    if (iframeSuppressPreventScrolling(window.location.search)) return
    preventScrolling()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(e)
  }
}

const AUTO = 'auto'

export const iframeSuppressPreventScrolling = search => {
  const value = getStoredIframeConfiguration()
  if (value.get && value.get('scroll') === AUTO) return true

  // Fall back to URL param
  const parsed = new URLSearchParams(search.replace(/^\?/, EMPTY_STRING))
  if (parsed.has('scroll')) {
    return parsed.get('scroll') === AUTO
  }
  return false
}

export const mappedProjects = projects =>
  projects.map(proj => {
    return {
      addOns: proj.addOns,
      amount: proj.amount,
      id: proj.id,
      notes: proj.notes,
      projectName: proj.projectName,
      projectType: proj.projectType,
      stages: proj.stages
    }
  })

export const updateQuote = async (client, customerId, projects) => {
  const storedQuote = await client.query({ query: GET_QUOTE })

  const { hasAchDiscount, loanProductId, principal } = get(
    storedQuote,
    'data.quote.calculate.input',
    false
  )

  const quote = {
    customerId,
    quote: {
      loanAmount: principal,
      loanProductId,
      hasAch: hasAchDiscount,
      projects: selectProjectsDataForMutation(mappedProjects(projects))
    }
  }

  await client.mutate({
    mutation: UpdateQuote,
    variables: quote
  })
}

/*

APPROVED
DECLINED
NOT_QUALIFIED
NEEDS_REVIEW
PENDING
BLOCKED

*/
export const getDecisionStatusString = customer => {
  const decisionStatus = get(customer, 'creditApplication.decision.status', '')
  const creditApplicationStatus = getCreditApplication(customer)
  if (!customer) return prequalificationFormStatuses.loading
  if (creditApplicationStatus === creditApplicationStatuses.processing) {
    return prequalificationFormStatuses.processing
  }
  switch (decisionStatus) {
    case creditApplicationDecisionStatuses.approved:
      return prequalificationFormStatuses.approved
    case creditApplicationDecisionStatuses.pending:
      return prequalificationFormStatuses.pending
    case creditApplicationDecisionStatuses.blocked:
      return prequalificationFormStatuses.blocked
    case creditApplicationDecisionStatuses.needsReview:
      return prequalificationFormStatuses.needsReview
    case creditApplicationDecisionStatuses.notQualified:
      return prequalificationFormStatuses.notQualified
    case creditApplicationDecisionStatuses.declined:
      return prequalificationFormStatuses.declined
    case creditApplicationDecisionStatuses.needToVerifySsnDob:
      return prequalificationFormStatuses.needToVerifySsnDob
    default:
      return creditApplicationDecisionStatuses.pending
  }
}

export const getSwiftlinksReferrer = () => document.referrer

export const trackMutation = (
  mutation,
  trackedMutation,
  setTrackedMutation
) => {
  const mutationName = get(mutation, 'definitions[0].name.value', EMPTY_STRING)
  if (trackedMutation !== mutationName) {
    setTrackedMutation(mutationName)
  }
  return mutation
}

export const setAssignee = (searchParams: Object, ldFlags: Object) => {
  const assigneeParam = searchParams.get(sessionStorageKeys.assigneeEmail)
  if (!assigneeParam) return

  const assignee = assigneeParam.split(SPACE_SEPARATOR).join(PLUS_SYMBOL)

  if (assignee) {
    safeSessionStorage.setItem(sessionStorageKeys.assigneeEmail, assignee)
  }
}

export const getAssignee = () =>
  safeSessionStorage.getItem(sessionStorageKeys.assigneeEmail)

export const isPhoneOrEmailError = (
  emailErrorType,
  phoneErrorType,
  landlineNumberFlagEnabled
) =>
  Boolean(
    emailErrorType ||
      (phoneErrorType &&
        !ReactSessionApi.get(localStorageKeys.allowedLandline) &&
        landlineNumberFlagEnabled)
  )

export const isDeprecatedEndpointError = serverErrors => {
  if (!isEmpty(serverErrors)) {
    return serverErrors.find(
      error =>
        get(error, 'extensions.type', null) ===
        errorTypes.deprecatedEndpointException
    )
  }
  return false
}

export const scrollToTopOfThePage = () =>
  window.scrollTo({ top: 0, behavior: 'smooth' })

export const onAgreeAndContinue = ({ customerRefetch }) => {
  setEnteredCustomer({})
  customerRefetch()
}

export const onDeviceVerification = (
  customer: {
    email: string,
    phoneNumber: string
  },
  userAuthModel: ?UserAuthModel,
  {
    deviceFingerprint,
    afterDeviceVerification
  }: {
    deviceFingerprint: string,
    afterDeviceVerification: Function
  }
) => {
  const { accessToken, refreshToken, userType, userId } = userAuthModel || {}
  if (accessToken) {
    storeUserTokens(accessToken, refreshToken)
    identify(userType, userId)
    track(analyticsEventTypes.prequalificationUserLoggedIn, {
      deviceFingerprint
    })
  }
  afterDeviceVerification()
  setEnteredCustomer({})
  setCustomerIdInStorage(EMPTY_STRING)
}

export const getPhoneInformation = async (variables, setIsLandlineNumber) => {
  const { guestClient } = new Client()

  try {
    const {
      data: {
        getPhoneInformation: { isLandlineNumber }
      }
    } = await guestClient.query({
      query: GetPhoneInformation,
      variables: { phoneNumber: variables.phoneNumber }
    })
    await setIsLandlineNumber(isLandlineNumber)
    return isLandlineNumber
  } catch (error) {
    if (isDevelopmentEnv()) {
      // eslint-disable-next-line
      console.error(error)
    } else {
      MonitoringHelper.manuallyReportError(error)
    }
    return
  }
}

export const getPhoneAndEmailType = (type, val) => {
  if (
    [customerFields.phoneNumber, customerFields.phoneNumberAlt].includes(type)
  ) {
    const { floatValue } = val
    return floatValue
  }
  return val.target.value
}

export const isSolarLoanType = ({ loanType }) => {
  return loanType === customerLoanTypes.solar
}

export default {
  SWIFTLINK_SUBDOMAIN,
  subdomainSupportsWeblinks,
  shouldSeeWeblinksPartnerNotFoundErrorPage,
  getCurrentPartnerTheme,
  getEnteredCustomer,
  setEnteredCustomer,
  clearEnteredCustomer,
  isAggregatorCodePresent,
  getAllowDenyHostnamesConfig,
  isHostnameAllowed,
  shouldHostnameBeAllowed,
  postApplicationStatusMessageToParentWindowForCustomer,
  mappedProjects,
  updateQuote,
  getSwiftlinksReferrer,
  trackMutation,
  setAssignee,
  getAssignee,
  isPhoneOrEmailError,
  isDeprecatedEndpointError,
  onAgreeAndContinue,
  onDeviceVerification,
  getPhoneInformation,
  getPhoneAndEmailType,
  isSolarLoanType
}
