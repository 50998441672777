// @flow
import React, { Component } from 'react'
import { Grid, Link, Paper, Button, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { colors } from 'themes/default'
import { get, noop, flow } from 'lodash'
import cx from 'classnames'
import OptionalQuery from 'components/shared/OptionalQuery'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import LinkComponent from 'screens/pipeline/components/LinkComponent'
import CustomerQuery from 'screens/customer/queries/CustomerQuery.graphql'
import Routes from 'util/Routes'
import l10n from 'properties/translations'
import PageLayoutContext from 'util/PageLayoutContext'
import { destroyUserSession } from 'util/SessionHelper'
import { inIframe, inP3xIframe } from 'util/EnvironmentHelper'
import poweredByMosaic from 'images/mosaic-logo.svg'
import coreScreenStyles from 'themes/coreScreenStyles'
import s from '../../screens/common/OpportunityViewScreen.module.css'
import { DEFAULT_ELEVATION, EMPTY_OBJECT } from 'properties/properties'
import UserContext from 'util/UserContext'

const linkTo = (data, { isBorrower, userId, me, ldFlags }) => {
  if (!userId) {
    destroyUserSession(true)
    return Routes.login()
  }
  return isBorrower
    ? Routes.pendingState(get(data, 'customer', {}), me, ldFlags)
    : Routes.start()
}

const UnspecifiedErrorButton = classes => {
  return (
    <Grid container direction="column" justify="center" alignItems="center">
      <Grid item className={classes.marginButton}>
        <Button
          component={Link}
          href="/logout"
          variant="outlined"
          color="primary"
        >
          <Typography className={classes.backButtonText}>
            {l10n.notFound.login}
          </Typography>
        </Button>
      </Grid>
    </Grid>
  )
}

const styles = theme => ({
  ...coreScreenStyles(theme),
  marginBottom: {
    marginBottom: theme.spacing(2)
  },
  marginTop: {
    marginTop: theme.spacing(6)
  },
  marginButton: {
    marginBottom: theme.spacing(5)
  },
  backButtonText: {
    fontSize: theme.spacing(1.5)
  },
  descriptionText: {
    fontSize: theme.spacing(2) - 2
  },
  errorCodeText: {
    color: colors.extraDarkGray,
    fontSize: theme.spacing(1.5)
  },
  titleHeader: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.spacing(2) + 2
  },
  wrapper: {
    height: '101%'
  },
  logo: {
    height: 20
  },
  logoContainer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  paperContainer: {
    [theme.breakpoints.down('sm')]: {
      marginRight: 36,
      marginLeft: 36
    }
  },
  okButton: {
    textDecoration: 'none'
  }
})

type Props = {
  location: {
    pathname: string
  },
  classes: {
    wrapper: string,
    logoContainer: string,
    logo: string,
    paper: string,
    paperContainer: string,
    marginBottom: string,
    marginTop: string,
    titleHeader: string,
    marginBottom: string,
    marginButton: string,
    okButton: string
  },
  title: string,
  userId: string,
  isBorrower: Boolean,
  showHeader: Boolean,
  toggleHeader: () => {},
  descriptionOverviewText: string,
  descriptionDetailText: string,
  showBackButton: Boolean,
  showErrorCode: Boolean,
  ldFlags: any,
  unspecifiedError: Boolean
}

class GenericErrorPage extends Component<Props> {
  static contextType = UserContext

  static defaultProps = {
    userId: null,
    descriptionOverviewText: l10n.notFound.descriptionLn1,
    descriptionDetailText: l10n.notFound.descriptionLn2,
    showBackButton: true,
    showErrorCode: true,
    toggleHeader: noop,
    title: null,
    unspecifiedError: false
  }

  static contextType = PageLayoutContext

  componentDidMount() {
    const { showHeader, toggleHeader, location } = this.props

    if (!location) return

    if (showHeader) {
      toggleHeader()
    }
  }

  render() {
    const {
      classes,
      userId,
      isBorrower,
      toggleHeader,
      descriptionOverviewText,
      descriptionDetailText,
      showBackButton,
      title,
      unspecifiedError,
      ldFlags
    } = this.props

    const userContext = this.context
    const me = get(userContext, 'me', EMPTY_OBJECT)
    const isSalesforceIdentityEnabled = get(
      me,
      'isSalesForceIdentityEnabled',
      false
    )

    const { logo: logoVisible } = this.context

    const showBackground = !inIframe() && (isBorrower || !isSalesforceIdentityEnabled)
    return (
      <div
        className={cx({
          [classes.wrapper]: true,
          [s['has-customer']]: showBackground
        })}
      >
        {logoVisible && (
          <Grid
            container
            direction="column"
            alignItems="center"
            justify="center"
            spacing={1}
            className={classes.logoContainer}
          >
            <Grid item>
              <img
                alt={l10n.layout.poweredByMosaic}
                className={classes.logo}
                src={poweredByMosaic}
              />
            </Grid>
            <Grid item>
              <Typography variant="caption" align="center">
                {l10n.layout.applicationDescription}
              </Typography>
            </Grid>
          </Grid>
        )}
        <Paper
          className={cx(classes.paper, classes.paperContainer)}
          elevation={DEFAULT_ELEVATION}
        >
          <Grid
            container
            justify="center"
            alignItems="center"
            className={cx(classes.marginBottom, classes.marginTop)}
          >
            <Grid item>
              <Typography
                data-testid="screen-title"
                variant="subtitle1"
                align="center"
                className={classes.titleHeader}
              >
                {title || l10n.notFound.title}
              </Typography>
            </Grid>
            <Grid item />
          </Grid>
          <Grid
            container
            justify="center"
            alignItems="center"
            className={classes.marginBottom}
          >
            <Grid item>
              <Typography
                variant="subtitle1"
                align="center"
                gutterBottom
                className={classes.descriptionText}
              >
                {descriptionOverviewText}
              </Typography>
            </Grid>
          </Grid>

          {unspecifiedError && <UnspecifiedErrorButton classes={classes} />}

          {showBackButton && !inP3xIframe() && (
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item className={classes.marginButton}>
                <OptionalQuery
                  runQuery={isBorrower}
                  query={CustomerQuery}
                  variables={{ id: userId }}
                >
                  {({ data }) => {
                    const me = get(data, 'me', {})
                    return (
                      <LinkComponent
                        data-testid="not-found-back"
                        to={linkTo(data, { isBorrower, userId, me, ldFlags })}
                        disabled={false}
                        onClick={toggleHeader}
                        className={classes.okButton}
                      >
                        <Button variant="outlined" color="primary">
                          <Typography className={classes.backButtonText}>
                            {userId
                              ? l10n.notFound.goBack
                              : l10n.notFound.login}
                          </Typography>
                        </Button>
                      </LinkComponent>
                    )
                  }}
                </OptionalQuery>
              </Grid>
            </Grid>
          )}
          {descriptionDetailText && (
            <Grid container justify="center" alignItems="center">
              <Grid item>
                <Typography
                  variant="subtitle1"
                  align="center"
                  gutterBottom
                  className={classes.errorCodeText}
                >
                  {descriptionDetailText}
                </Typography>
              </Grid>
            </Grid>
          )}
        </Paper>
      </div>
    )
  }
}

export default flow(withLaunchDarkly, withStyles(styles))(GenericErrorPage)
