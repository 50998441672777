import React, { useContext } from 'react'
import { get } from 'lodash'
import customerFields from 'constants/enums/customerFields'
import ErrorMessage from 'components/shared/ErrorMessage'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { isPhoneOrEmailError } from 'util/PrequalificationHelper'
import { getErrorType } from 'util/CustomerHelper'
import { landlineNumberEnabled } from 'util/FeatureHelper'
import TranslationsContext from 'util/TranslationsContext'

const getErrorMessage = ({
  serverErrorMessages,
  l10n,
  landlineNumberFlagEnabled
}) => {
  const emailErrorType = getErrorType(serverErrorMessages, [
    customerFields.email,
    customerFields.primaryApplicantEmailAddress
  ])
  const phoneErrorType = getErrorType(serverErrorMessages, [
    customerFields.phoneNumber,
    customerFields.primaryApplicantPhoneNumber
  ])

  if (
    isPhoneOrEmailError(
      emailErrorType,
      phoneErrorType,
      landlineNumberFlagEnabled
    )
  ) {
    if (emailErrorType) {
      const keys = Object.keys(serverErrorMessages)
      const key = get(serverErrorMessages, `["${keys[0]}"].key`)
      const msg = l10n.apply.mobileVerificationScreen.emailErrorTypes[key]
      if (msg) return msg
    }
    if (phoneErrorType) {
      const keys = Object.keys(serverErrorMessages)
      const key = get(serverErrorMessages, `["${keys[0]}"].key`)
      const msg = l10n.apply.mobileVerificationScreen.phoneErrorTypes[key]
      if (msg) return msg
    }
    return l10n.prequalificationForm.checkAndTryAgain
  }
  return null
}

const ErrorComponent = ({ serverErrorMessages, recaptchaError, ldFlags }) => {
  const l10n = useContext(TranslationsContext)
  const landlineNumberFlagEnabled = landlineNumberEnabled(ldFlags)

  const errorMessage = React.useMemo(
    () =>
      getErrorMessage({
        serverErrorMessages,
        l10n,
        landlineNumberFlagEnabled
      }),
    [serverErrorMessages, l10n, landlineNumberFlagEnabled]
  )

  let recaptchaErrorMessage
  if (recaptchaError) {
    recaptchaErrorMessage = l10n.prequalificationForm.captchaError
  }

  return <ErrorMessage error={recaptchaErrorMessage || errorMessage} />
}

export default withLaunchDarkly(ErrorComponent)
