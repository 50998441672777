import { gql } from 'graphql.macro'

export default gql`
  query GetVerificationDatesEmailsSent(
    $customerId: String!
    $emailType: EmailTypes!
  ) {
    customer(customerId: $customerId) {
      id
      dateESignVerified
      emailAddressVerified
      contract {
        status
      }
      communications {
        dateEmailsSent(emailType: $emailType)
      }
      opportunityStatus
      isInfoEditable
    }
  }
`
