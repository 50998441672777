// @flow
import React from 'react'
import { Button, Link, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import lowerFirst from 'lodash/lowerFirst'
import NumberFormat from 'react-number-format'
import get from 'lodash/get'
import { filter, map } from 'lodash'
import accountTypes from 'constants/enums/accountTypes'
import DateHelper from 'util/DateHelper'
import opportunityStatuses from 'constants/enums/opportunityStatuses'
import creditApplicationDecisionStatuses from 'constants/enums/creditApplicationDecisionStatuses'
import projectStatuses from 'constants/enums/projectStatuses'
import contractStatuses from 'constants/enums/contractStatuses'
import userTypes from 'constants/enums/userTypes'
import flagTypes from 'constants/enums/flagTypes'
import errorKeys from 'constants/enums/errorKeys'
import lendersEnum from 'constants/enums/lenders'
import loanProductTypeEnum from 'constants/enums/loanProductTypes'
import languageKeys from 'constants/enums/languageKeys'
import {
  COMMA_SPACE_SEPARATOR,
  supportPhone,
  mosaicAddress,
  DOLLAR_DECIMAL_SCALE,
  DOLLAR_PREFIX,
  MOBILE_DETECT_OS,
  contactCardSupportTypes
} from 'properties/properties'
import taskTypes from 'constants/enums/taskTypes'
import projectTypeIds from 'constants/enums/projectTypeId'
import channelPartnerDocumentTypes from 'constants/enums/channelPartnerDocumentTypes'
import fileUploadParams from 'constants/enums/fileUploadParams'
import customerStatuses from 'constants/enums/customerStatuses'
import projectRowMenuOptions from 'constants/enums/projectRowMenuOptions'
import { decisionScreenSubTitle } from 'util/TranslationHelper'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'
import { type ChannelPartnerProjectConfiguration } from 'screens/quote/QuoteTypes'
import styles from 'screens/setup/components/commonStyles'
import lenderDialogStyles from 'screens/apply/components/summaryTable/LenderDialog.styles'
import type { CustomerShape } from 'screens/customer/shapes/customer'
import LinkLabelBrowser from '../components/shared/LinkLabelBrowser'
import { type LenderObjectType } from '../util/LenderHelper'

const useStyles = makeStyles(styles)
const useLenderDialogStyles = makeStyles(lenderDialogStyles)

export default sharedText => {
  const languageKey = languageKeys.en

  const pipeline = {
    complete: sharedText.complete,
    incomplete: sharedText.incomplete,
    disabled: 'Disabled',
    customer: sharedText.customer,
    modified: 'Activity',
    queue: 'Queue',
    info: 'Info',
    estimate: 'Estimate',
    apply: 'Apply',
    setup: 'Setup',
    status: sharedText.status,
    stage: 'stage',
    lapse: 'Queue',
    sortLabel: 'Sort',
    daysInactive: 'Days inactive',
    currentlyEmpty: 'Your Pipeline is currently empty!',
    whenNewCustomers: 'When you add new customers,',
    customerAppearHere: 'they will appear here.',
    noMatchFound: 'We could not find a match for ',
    awaitingConfirmation: 'Awaiting Confirmation',
    salesAgreementNotAccepted: 'Sales Agreement not accepted',
    unassigned: 'Unassigned',
    edit: sharedText.edit.toLowerCase(),
    loanAppIncompleteAlert: {
      [taskTypes.welcomeCall]: 'Welcome call required:',
      [taskTypes.documentsUpload]: 'Sales Agreement required:',
      [taskTypes.esignPerfection]: 'E-sign verification required:',
      daysWaiting: 'days waiting'
    },
    moreInfo: 'more info',
    opportunityId: 'ID ',
    close: 'CLOSE',
    creditDecision: {
      [creditApplicationDecisionStatuses.pending]: 'Pending ',
      [creditApplicationDecisionStatuses.declined]: 'Declined ',
      [creditApplicationDecisionStatuses.needsReview]: 'Needs review ',
      [creditApplicationDecisionStatuses.blocked]: 'Blocked ',
      [creditApplicationDecisionStatuses.notQualified]: 'Not qualified '
    },
    creditDecisionModal: {
      pending: {
        text: (
          <>
            Mosaic is unable to complete a credit decision. Please check back
            soon, <b>no action is needed at this time</b>.
          </>
        )
      },
      declined: {
        text: 'This applicant has been declined for credit.'
      },
      notQualified: {
        text: 'This applicant is not eligible based on applicant information provided and has been declined credit.'
      },
      needsReview: {
        [flagTypes.identity]: {
          text: (
            <>
              <p>
                This application has been conditionally approved. To complete a
                credit decision,{' '}
                <b>
                  the applicant is required to provide Identification
                  documentation
                </b>
                . The applicant has been sent instructions on what is needed to
                move forward.
              </p>
              <p>
                If we do not receive the documentation from the customer within
                25 days of our request, we will be unable to give the
                application further consideration.
              </p>
              <p>
                If your customer is unsure of what information to provide in
                response to our request, they should contact our customer
                support team at{' '}
                <Link href={`tel:855-558-6007`}>(855) 558-6007</Link>, Mon-Fri,
                7am-9pm Pacific.
              </p>
            </>
          )
        },
        [flagTypes.ofac]: {
          text: (
            <>
              This application has been conditionally approved, but is pending
              internal Mosaic review of information provided.{' '}
              <b>No action is needed at this time</b>.
            </>
          )
        },
        [flagTypes.income]: {
          text: (
            <>
              <p>
                This application has been conditionally approved. To complete a
                credit decision,{' '}
                <b>the applicant is required to provide Income documentation</b>
                . The applicant has been sent instructions on what is needed to
                move forward.
              </p>
              <p>
                If we do not receive the documentation from the customer within
                25 days of our request, we will be unable to give the
                application further consideration.
              </p>
              <p>
                If your customer is unsure of what information to provide in
                response to our request, they should contact our customer
                support team at{' '}
                <Link href={`tel:855-558-6007`}>(855) 558-6007</Link>, Mon-Fri,
                7am-9pm Pacific.
              </p>
            </>
          )
        },
        [flagTypes.fraudReport]: {
          text: (
            <>
              <p>
                This application has been conditionally approved. To complete a
                credit decision,{' '}
                <b>Mosaic needs to speak with the Applicant via phone</b>. The
                Applicant has been sent instructions on what is needed to move
                forward.
              </p>
              <p>
                If we do not speak to the customer within 25 days of our
                request, we will be unable to give the application further
                consideration.
              </p>
              <p>
                Please encourage your customer to contact our customer support
                team at <Link href={`tel:855-558-6007`}>(855) 558-6007</Link>,
                Mon-Fri, 7am-9pm Pacific.
              </p>
            </>
          )
        }
      },
      blockedCreditFreeze: {
        text: (
          <>
            <p>
              Blocked - Mosaic is unable to complete a Credit Decision.{' '}
              <b>
                Applicant action is needed and instructions have been sent to
                the applicant
              </b>
              .
            </p>
            <p>
              For any questions, our customer support team is available at{' '}
              <Link href={`tel:855-558-6007`}>(855) 558-6007</Link>, Mon-Fri,
              7am-9pm Pacific.
            </p>
          </>
        )
      },
      blockedSsnDobMismatch: {
        text: (
          <>
            <p>
              Blocked - Mosaic is unable to complete a credit decision based on
              applicant information provided.{' '}
              <b>
                Applicant should call Mosaic Credit Ops and may need to reapply
              </b>
              .
            </p>
            <p>
              For any questions, our customer support team is available at{' '}
              <Link href={`tel:855-558-6007`}>(855) 558-6007</Link>, Mon-Fri,
              7am-9pm Pacific.
            </p>
          </>
        )
      }
    },
    loanCancellationAlert: {
      requestedByInstaller: partnerName =>
        `Cancellation requested by ${partnerName}`,
      requestedByBorrower: 'Cancellation requested by customer',
      denied: 'Cancellation request is denied',
      cancelled: 'Cancellation is approved and complete'
    },
    loanCancellationAlertModal: {
      header: {
        requested: 'Cancellation requested',
        denied: 'Cancellation request denied',
        cancelled: 'Cancelled'
      },
      body: {
        requestedByInstaller: partnerName =>
          `Cancellation of this post-funded loan has been requested by ${partnerName}. Mosaic will reach out to the customer to confirm.`,
        requestedByBorrower:
          partnerName => `Cancellation of this post-funded loan has been requested by the customer. Action is required from ${partnerName} to confirm this. Please contact our customer support team at (855) 558-6007, Mon-Friday, 7am-9pm Pacific.
          This post-funded loan will be cancelled in 21 days of the request unless we hear from ${partnerName}. `,
        denied:
          'Cancellation request of this post-funded loan has been denied. No action is required.',
        cancelled:
          'Cancellation request of this post-funded loan has been approved and completed.'
      }
    },
    creditApprovalExpired: 'Credit approval has expired ',
    creditApprovalExpiresIn: (daysUntilExpiry: number) =>
      `Credit approval expires in ${daysUntilExpiry} days `,
    expiryModal: {
      title: creditApprovalAlreadyExpired =>
        creditApprovalAlreadyExpired
          ? 'Credit approval expired'
          : 'Credit approval',
      description: (
        creditApprovalAlreadyExpired: boolean,
        daysUntilExpiry: number
      ) =>
        creditApprovalAlreadyExpired ? (
          <>
            <p>
              This customer’s credit approval is currently expired. Please ask
              the customer to <strong> submit a new credit application.</strong>{' '}
            </p>

            <p>
              For any questions, our customer support team is available at (855)
              558-6007, Mon-Friday, 7am-9pm Pacific.
            </p>
          </>
        ) : (
          <p>
            This customer’s credit approval will{' '}
            <strong> expire in {daysUntilExpiry} days </strong> if the customer
            has not signed the loan agreement.
          </p>
        ),
      close: 'Close'
    }
  }

  const opportunityStatusTitles = {
    [opportunityStatuses.new]: 'New opportunity',
    [opportunityStatuses.prequalSent]: 'Pre-qualification submitted',
    [opportunityStatuses.prequalCompleted]: 'Pre-qualification submitted',
    [opportunityStatuses.creditApproved]: 'Pre-qualification approved',
    [opportunityStatuses.creditDenied]: 'Pre-qualification denied',
    [opportunityStatuses.loanAppSent]: 'Loan agreement sent',
    [opportunityStatuses.loanAppCompleted]: 'Loan agreement signed',
    [opportunityStatuses.loanAppApproved]: 'Loan agreement signed',
    [opportunityStatuses.loanAppIncomplete]: 'Loan agreement signed',
    [opportunityStatuses.loanAppCountersigned]: 'Loan agreement countersigned',
    [opportunityStatuses.installComplete]: 'System installation complete',
    [opportunityStatuses.installConfirmed]: 'System installation confirmed',
    [opportunityStatuses.finalPayment]: 'Final installment payment made',
    [opportunityStatuses.deleted]: 'Archived',
    [customerStatuses.cancelled]: sharedText.cancelled,
    [opportunityStatuses.secondLook]: 'Second look'
  }

  const projectStatusesTitles = {
    [projectStatuses.Unspecified]: 'Unspecified',
    [projectStatuses.Cancelled]: sharedText.cancelled,
    [projectStatuses.InspectionComplete]: 'Inspection Complete',
    [projectStatuses.EquipmentApproved]: 'Equipment Approved',
    [projectStatuses.EquipmentSubmitted]: 'Equipment Submitted',
    [projectStatuses.Approved]: 'Approved',
    [projectStatuses.New]: 'New',
    [projectStatuses.InstallComplete]: 'Install Complete',
    [projectStatuses.InstallCompletePending]: 'Install Complete Pending',
    [projectStatuses.InstallConfirmed]: 'Install Confirmed',
    [projectStatuses.FinalConfirmation]: 'Final Confirmation'
  }

  const time = {
    now: 'Now',
    justNow: 'Just now',
    minute: 'minute',
    minutes: 'minutes',
    minutesShort: 'm',
    hour: 'hour',
    hours: 'hours',
    hoursShort: 'h',
    yesterday: 'Yesterday',
    daysShort: 'd',
    days: 'days',
    day: 'day',
    weeksShort: 'w',
    weeks: 'weeks',
    week: 'week',
    yearsShort: 'y',
    years: 'years',
    year: 'year',
    monthsShort: 'mo',
    months: 'months',
    month: 'month',
    indefiniteArticleRegex: 'a|an',
    aboutRegex: 'about'
  }

  const monthNames = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]

  type LoanProductStringProps = {
    apr: Number,
    loanMonths: Number
  }

  // Quote
  const quote = {
    next: sharedText.next,
    discounts: 'Discounts',
    rate: 'Rate',
    zeroPercent: '0%',
    oneHundredPercent: '100%',
    descriptionOptional: 'Optional description',
    discountsLabel: (
      monthNumber: number,
      date: Date,
      lumpSumPercentage: number,
      hidePayoffDate: Date,
      lumpSumAmount: number
    ) =>
      `I plan to pay down ${lumpSumPercentage}% ($${new Intl.NumberFormat().format(
        Math.round(lumpSumAmount)
      )}) by month ${monthNumber || '0'} ${
        hidePayoffDate ? '' : ` - approximately ${date || ''}.`
      } `,
    automaticPaymentsLabel:
      'I want to lower my interest rate by making automatic payments (ACH) from my bank account.',
    savings: {
      extraPaydown: 'Extra Paydown',
      interestSaved: 'Interest Saved',
      moreSavings: 'Savings through autopay and/or extra payment'
    },
    monthlyPayment: {
      beforeMonth: 'Before month',
      afterMonth: 'After month',
      monthlyPayment: sharedText.monthlyPayment,
      combinedMonthlyPayment: 'Combined monthly payment'
    },
    monthlyPaymentAccessible: (
      isBeforePeriod = false,
      monthPeriod,
      amount = 0
    ) =>
      `${
        isBeforePeriod ? 'Before month' : 'After month'
      } ${monthPeriod} the amount is  $${amount}`,
    loanProducts: {
      title: 'PAYMENT PLAN',
      financePlans: 'Options'
    },
    projectRow: {
      customLabel: 'Project name',
      custom: 'Custom',
      partialUpFront: '30% payment upfront / 70% at project confirmation.',
      completeAfterCompletion: '100% at project confirmation.',
      customCompleteAfterCompletion: 'Payments made at each stage confirmation',
      projectTypePlaceholder: 'Select',
      projectNameError: 'Custom projects must have a name.',
      stageLabel: 'Stage name',
      stage: {
        minCostError: ({
          minStageAmount,
          minStagePercentage
        }: ChannelPartnerProjectConfiguration) =>
          `Must be > $${minStageAmount} and > ${minStagePercentage.toFixed(
            0
          )}% of project cost.`,
        nameRequiredError: 'A name is required for each project stage.'
      },
      projectRowMenuOptions: {
        [projectRowMenuOptions.add]: 'Add a stage',
        [projectRowMenuOptions.delete]: 'Delete this stage',
        [projectRowMenuOptions.deleteProject]: 'Delete this project'
      },
      projectTypes: {
        [projectTypeIds.doors]: 'Doors',
        [projectTypeIds.windows]: 'Windows',
        [projectTypeIds.hvac]: 'HVAC',
        [projectTypeIds.roofing]: 'Roofing',
        [projectTypeIds.interiorRemodel]: 'Interior Remodel',
        [projectTypeIds.exteriorRemodel]: 'Exterior Remodel',
        [projectTypeIds.otherOrCustom]: 'Other',
        [projectTypeIds.solar]: 'Solar',
        [projectTypeIds.battery]: 'Battery'
      }
    },
    servicePlan: {
      title: 'MAINTENANCE PLAN',
      productName: 'HVAC',
      code: 'SP',
      description: {
        maintenancePlan: `10 years for ${DOLLAR_PREFIX}`,
        amountAddOns: amount => amount,
        servicePlanText: ' per month which can be cancelled anytime'
      },
      unavailable: 'Unavailable for this payment plan',
      deafultOption: '1 maintenance plan'
    },
    tabs: {
      discounts: 'Discounts',
      options: 'Options',
      projects: sharedText.projects
    },
    payoffDate: {
      payoffDate: date => `You’ll pay your loan off ${date}`
    },
    actionBar: {
      below: amount => `Below minimum of ${amount}`,
      above: amount => `Exceeds maximum of ${amount}`,
      estimate: amount => `Estimate ${amount}`
    },
    paymentsTable: {
      seeTable: 'See payments table',
      title: 'Payment table',
      month: 'Month',
      payments: 'Payments',
      remainingBalance: 'Remaining Balance',
      accruedInterest: 'Accrued Interest',
      totalInterest: 'Total Interest'
    },
    borrowerContactInstallerNoLoanProductChosen:
      'You currently do not have any payment plans available for this project type.',
    projects: {
      sectionTitle: sharedText.projects,
      type: 'Type',
      cost: 'Cost',
      add: 'Add project'
    }
  }

  const customer = {
    editDisclaimer: (phone: string, supportMail: string) => (
      <>
        {`If you need to make additional edits, please contact customer support at +${phone} or `}
        <Link href={`mailto:${supportMail}`}>{supportMail}</Link>
      </>
    ),
    customerInfo: sharedText.customerInfo,
    editCustomerInfo: 'Edit Customer Info',
    onEditCustomerInfo: 'Save Changes',
    onEditCustomerAndContinue: 'Save & Continue',
    onCancelEdition: sharedText.cancel,
    archiveCustomer: 'Archive Customer',
    updateCustomer: 'Update Customer',
    next: sharedText.next,
    pleaseWait: sharedText.pleaseWait,
    city: 'City',
    state: 'State',
    street: 'Street address (of installation)',
    streetShort: sharedText.street,
    zip: 'ZIP code',
    phoneNumber: sharedText.phoneNumber,
    fullName: 'Full name',
    firstName: sharedText.firstName,
    middleName: sharedText.middleName,
    lastName: sharedText.lastName,
    email: sharedText.emailAddress,
    emailOptional: 'Email Address',
    emailOptionalEmphasis:
      '(Optional. Customer will receive a link to the application via email)',
    editCustomer: '(stage name here)',
    newCustomer: sharedText.newCustomer,
    notes: 'Notes',
    assignedTo: 'Assigned to',
    assignedToHelperText:
      'Requires admin permission to change. If changed, new assignee will be notified by email.',
    photo: 'Photo',
    errorMessages: {
      noSpecialCharacters: 'Only letters, apostrophes, and hyphens are allowed',
      noCompoundNames: 'Compound names are not permitted',
      nameMinLength: 'Must be at least two letters',
      onlyLetters: 'Letters only',
      lastNameErrorMessage: 'The last name cannot have single letter words'
    },
    lastModifiedBy: (when: String) =>
      `Last modified ${lowerFirst(
        DateHelper.distanceOfTimeInWords(languageKeys.en, when)
      )}`,
    emailErrorMessage: sharedText.emailMustBeValid,
    emailTakenErrorMessage: 'This email is already in use',
    phoneNumberErrorMessage: 'Must be a valid phone number',
    phoneNumberMinLength: 'Must be a valid phone number',
    customerCreated: 'Customer created!',
    customerSaved: 'Customer saved!',
    emailHelpText: 'Your own personal email you can access',
    customerNotFound: 'Customer not found',
    customerNotFoundError:
      'Sorry, the customer you are looking for does not exist or is no longer available.',
    streetErrorMessage: [
      'Street address cannot only contain numbers.',
      'Street must be less than 50 characters.'
    ],
    zipErrorMessage: 'ZIP code must be 5 digits.',
    dobDayErrorMessage: 'Date must be a valid calendar date.',
    dobYearErrorMessage: 'Year must be valid.',
    dobYearRangeAlert:
      'Confirm that the date of birth that you entered is correct.',
    ssnErrorMessage: 'Please enter a valid SSN',
    verifySsnErrorMessage: 'Invalid Social Security Number',
    ssn: 'Social Security Number',
    verifySsn: 'Social Security Number',
    verifySsnLast4: 'Last 4 digits of your SSN',
    ssnHelpText: 'Numbers only, no dashes.',
    verifySsnLast4HelpText: 'Last 4 digits of your SSN',
    lastFourOfSsn: 'Last 4 of SSN',
    verifySsnHelpText: 'Numbers only, no dashes',
    fullSsnRequiredLater:
      'You will be required to provide your full Social Security Number later.',
    otherHouseholdIncome: 'Other household income (annual)',
    otherHouseholdIncomeHelpText:
      'Other gross income for household members at the same address.',
    personalIncome: 'Personal income (annual)',
    personalIncomeHelpText:
      'Your annual pre-tax (gross) income. Alimony, child support, and separate maintenance income are optional. ',
    highPersonalIncomeAlert:
      'Please review your stated income for accuracy. If correct, ignore this message. ',
    lowPersonalIncomeAlert:
      "Please verify that you've entered your total yearly income. ",
    readMore: 'Read more',
    // Space is required to separate from personalIncomeHelpText
    personalIncomeReadMoreText:
      ' You may include any source of income you wish to have considered as a basis for repaying this obligation, for example – salary, wages, interest, dividend, rental income, retirement benefits. Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.',
    dob: sharedText.dob,
    dobDay: 'Day',
    dobYear: 'Year',
    dobMonth: 'Month',
    continue: sharedText.continue,
    confirm: sharedText.confirm,
    disclosures: {
      authorization:
        'Authorization to Obtain Your Consumer Reports and Share Information',
      electronic: 'Electronic Communications Consent',
      textingCalling:
        'Automated Calling/Text Messaging and Call Recording Consent',
      arbitration: 'An Arbitration Provision',
      webbankLoan: 'All Home Improvement Loans made by WebBank, Member FDIC',
      patriotContent:
        'PATRIOT ACT DISCLOSURE: To help the government fight the funding of terrorism and money laundering activities, federal law requires all financial institutions to obtain, verify, and record information that identifies each person who opens an account. What this means for you: When you open an account, we will ask for your name, address, date of birth, and other information that will allow us to identify you. We may also ask to see your driver’s license or other identifying documents.'
    },
    projectAddress: sharedText.projectAddress,
    phoneNumberHelpText: {
      prependingText: 'Add an ',
      buttonText: 'alternate phone number',
      appendingText: ', if any.',
      completeText: 'Add an alternate phone number, if any.'
    },
    phoneNumberAlternate: 'Alternate phone number',
    phoneNumberAlternateHelpText: sharedText.remove,
    projectAddressTypeLabel: 'Project address use',
    primaryAddress: 'Primary address',
    mailingAddress: 'Mailing address',
    showMailingAddress: 'Prefer to receive mail at a different address',
    assignedToLabel: 'Referred by (Optional)',
    borrowerFirstTime: {
      title: 'Welcome to your loan application!',
      subTitle:
        'From now until your project is complete, you can use this app to:',
      capabilities: [
        'Complete your pre-qualification',
        'Sign and access loan documents',
        'Sign up for autopay'
      ],
      continue: sharedText.continue
    },
    confirmAddressModal: {
      title: 'Are you sure?',
      description:
        'Editing this address will require signing a new loan agreement.',
      cancelLabel: sharedText.cancel,
      approveLabel: 'Yes, edit customer info'
    },
    loanId: (loanId: number) => `Loan ID: ${loanId || ''}`,
    submitError:
      'There was an unexpected error while submitting your application. Please try again later.',
    checkAndTryAgain: 'Please check what you’ve entered and try again.',
    landlineAPIMessage: 'Phone number must not be a landline.'
  }

  const stateMenu = {
    state: 'State'
  }

  const shared = {
    error: 'An error occurred.',
    error403: 'Error 403',
    errorCode401: 'Error code 401',
    offline: 'You seem to be offline right now',
    newContent: 'New content is available; please refresh.',
    offlineNetworkError:
      'Network error: The internet connection appears to be offline.',
    forbidden: 'You are not authorized to view this page.',
    errorGoBack: sharedText.goBack,
    copy: 'Copy',
    copied: 'Copied!',
    sessionExpired: 'Session expired',
    refresh:
      'Due to inactivity your session has expired. Please refresh the page.'
  }

  const apply = {
    send: sharedText.send,
    submit: sharedText.submit,
    agreeAndContinue: 'Agree & Continue',
    cancelDialogTitle: {
      title: 'Are you sure?',
      subtitle:
        'If you leave now you will need to start over with financing if you change your mind later. You can always apply for financing again later.'
    },
    cancelScreen: {
      title: sharedText.cancelled,
      description: 'Your financing has been cancelled.',
      descriptionText:
        'Please return to your cart and proceed with another payment option.'
    },
    apply: 'Apply',
    continue: sharedText.continue,
    continueToEditOffer: 'Continue to application',
    disclosures: sharedText.disclosures,
    newCustomer: sharedText.newCustomer,
    noHardInquiry: 'This will not make a hard inquiry into your credit.',
    mobileVerification: 'Mobile verification',
    mobileVerificationWithEmail:
      'To proceed we need to correspond with the customer’s mobile number via SMS. Please have the customer enter their mobile number and read the disclosures below',
    subInfo:
      'To see if your customer qualifies for financing, we first need to contact their mobile number via SMS. Please have the customer enter their number and read the disclosure below.',
    creditApplication: 'Instant pre-qualification',

    handBackText: (onEditOffer: () => {}, className: string) => (
      <>
        <div>
          {
            'Please hand the device back to your rep to edit the offer in order to continue.'
          }
        </div>
        <Button onClick={onEditOffer} color="primary" className={className}>
          <span>edit offer</span>
        </Button>
      </>
    ),

    addressVerification: {
      cancelButton: sharedText.back,
      continueButton: sharedText.continue,
      projectAddressTitle: sharedText.projectAddress,
      primaryAddressTitle: 'Primary Address',
      mailingAddressTitle: 'Mailing Address',
      multipleAddressDescription: 'Which address would you like to use?',
      addressWarning:
        ' Changes to project or primary address will require signing new loan documents.',
      personalInformation:
        'Please confirm your personal information is spelled correctly and that is your own personal email address you can access.',
      description:
        'The address you’ve entered may be invalid. Please confirm the installation address you’d like to use below or press cancel to enter a different address.',
      missingSecondaryNumber:
        'The address you’ve entered may require additional information. Please confirm the installation address you’d like to use below or press cancel to enter a different address.',
      exactAddressMatch:
        'Please confirm the below is your installation address or press cancel to enter a different address.',
      myAddressLabel: 'Use the address I entered:',
      selectAddressButton: 'Use Selected Address',
      proceedAnywayButton: 'Proceed Anyway',
      confirmAddressButton: 'Confirm Address',
      suggestedAddressLabel: 'Use suggested address:',
      title: 'Installation address verification',
      confirmTitle: 'Confirm your info',
      validNameText: 'Is your name spelled correctly?',
      validEmailText: 'Is this an email address you can access?',
      validPhoneNumberText: 'Is this your correct mobile phone number? ',
      addressInvalid: 'Address invalid'
    },

    contactRep: {
      description: (channelPartnerName: string) =>
        `Please contact your ${channelPartnerName} rep and make sure they have the proper loan amount and finance plan selected in order to continue.`,
      weblinksDescription: (channelPartnerName: string) =>
        `${channelPartnerName} will follow up with you about next steps in moving forward with your project.`
    },

    formStatus: {
      demoEdit:
        'In live mode, you can edit some customer information (street addresses, email address, and secondary phone number) if first payment has not yet been made to the contractor. Admin and owner roles will always have the ability to reassign.',
      defaultStatus: {
        title: sharedText.unknownStatus
      },
      menu: {
        editOffer: sharedText.editOffer,
        shareLink: sharedText.share,
        sendLink: sharedText.send,
        setupAutopay: sharedText.setupAutopay,
        editCustomerInfo: 'Edit Customer Info',
        closeMenu: 'Close menu',
        ariaLabels: {
          moreOptionsMenu: 'More options menu',
          showMoreOptions: 'Show more options'
        },
        backOffice: 'Back office'
      },
      processing: {
        description: 'This should only take a few seconds...',
        timeout:
          'Thank you for your application. It’s taking longer than usual to get a response from the credit bureau. We will keep trying and update you by email when we have a decision ready. Thanks for your patience.',
        refresh: sharedText.refresh,
        title: sharedText.processing
      },
      verifyIdentity: {
        description:
          'Please verify the following information you previously provided on your credit application.',
        title: 'Verify identity',
        applicantLabel: sharedText.applicant
      },
      informationVerification: {
        description:
          'Please verify the following information from your pre-qualification.',
        title: 'Information verification'
      },
      complete: {
        description:
          'Your pre-qualification results have been sent to the email address you provided.',
        description2: 'Please check your inbox.',
        title: sharedText.complete
      },
      conditionalApproval: {
        title: 'Conditional qualification'
      },
      stipResolutionConfirmation: {
        title: 'Your documents have been received',
        subheading: 'Documents under review',
        description:
          'Thank you for submitting your supporting documentation. We will email you as soon as we have a completed review of your application.'
      },
      conditionalApprovalUpload: {
        subHeader: 'Please provide additional documentation',
        descriptionLn1:
          'Your submission has been held for further manual review for identity verification, qualification is conditional on that further review.',
        descriptionLn2:
          'Based on the specifics of your submission we also need to collect some additional documentation.',
        descriptionLn3: 'Submit document now',
        descriptionLn4:
          'To proceed with your application, please check your email for an email from Mosaic and follow the directions within. ',
        descriptionLn4Action:
          'If we are not able to verify your identity, then your pre-qualification may be denied.'
      },
      conditionalApprovalPhone: {
        subHeader: 'Please make yourself available to receive our phone call',
        descriptionLn1:
          'Your submission has been held for further manual review for identity verification, qualification is conditional on that further review.',
        descriptionLn2:
          'In order for us to complete our review and move forward, we need to contact you directly by phone for verification. Please make yourself available to receive our call, and be prepared to answer the verification questions.',
        descriptionLn3: 'Calls are typically made ',
        descriptionLn3Action: 'within 24 hours.',
        descriptionLn4:
          'To proceed with your application, please check your email for an email from Mosaic and follow the directions within.'
      },
      approved: {
        description: financingLimit =>
          `Congratulations, you qualify for financing up to ${financingLimit}`,
        financingText: 'Congratulations, you qualify for financing up to ',
        yourLoanAmount: 'Your loan amount',
        yourFinancePlan: 'Your finance plan:',
        yourFinancePlanWebbank: 'Finance plan based on loan amount:',
        totalAmountToFinance: 'Total amount to finance:',
        totalAmountToFinanceWebbank: 'Total loan amount:',
        title: 'Qualified',
        viewOffer: 'Continue to Loan Terms',
        estPerMonth: 'Est. per month',
        promoPeriod: (updatePaymentPlans: boolean = false): string =>
          updatePaymentPlans ? 'Promo' : 'Promo period',
        bottomText: (
          years: number,
          apr: number,
          months: number,
          options = { isPromo: false },
          updatePaymentPlans: boolean = false
        ) =>
          `${options.isPromo ? '+' : ''}${years.toFixed(
            0
          )} years at ${apr.toFixed(2)}% ${
            months
              ? `APR if not paid ${
                  updatePaymentPlans ? 'in full' : ''
                } within ${months.toFixed(0)} months`
              : 'APR'
          }`,
        bottomTextRate: (apr: number) => `at ${apr.toFixed(2)}% APR`,
        apr: (apr: number) => `at ${apr}%`,
        month: '-month',
        yearsRate: (
          loanTenorMonths: number = 0,
          promoPeriod: number = 0,
          isPromoPeriod: boolean = false
        ) =>
          `${isPromoPeriod ? '+' : ''}${Math.round(
            (loanTenorMonths - promoPeriod) / 12
          )} years `,
        monthRate: (
          loanTenorMonths: number,
          updatePaymentPlans: boolean = false
        ) =>
          loanTenorMonths
            ? ` APR if not paid ${
                updatePaymentPlans ? 'in full' : ''
              } within ${loanTenorMonths.toFixed(0)} months`
            : ' APR',
        aprInclusiveOfPromo: (
          apr: number,
          months: number = 0,
          updatePaymentPlans: boolean = false
        ) =>
          updatePaymentPlans
            ? `at ${apr.toFixed(
                2
              )}% APR if not paid in full within ${months} months`
            : `${apr.toFixed(2)}% APR, inclusive of Promo`,
        descriptionVoided: 'Please continue after ',
        descriptionVoidedLinkText: 'editing this offer',
        promo: 'promo',
        loanAmountOutOfLimitMessage: (partner: string) =>
          `Please contact your ${partner} rep and make sure they have the proper loan amount and finance plan selected in order to continue`
      }
    },

    phoneVerificationStatus: {
      defaultStatus: {
        title: sharedText.unknownStatus
      },
      pending: {
        description: 'Waiting for SMS response from',
        cancel: sharedText.cancel,
        title: sharedText.processing
      },
      expired: {
        description:
          'Sorry, it has been past 30 days since mobile verification was sent.',
        cancel: sharedText.goBack,
        title: 'Expired'
      },
      verified: {
        description: 'Please hand the device over to the customer',
        button: 'Yes I am the customer',
        title: sharedText.completed
      },
      optedOut: {
        description: 'Customer has replied STOP. Loan process cannot continue',
        contact:
          'If you feel this was an error please contact Mosaic at (510) 746-8602 or ',
        goBack: sharedText.goBack,
        supportEmail: 'support@joinmosaic.com',
        title: 'Opted out'
      },
      internalError: {
        errorMessage: 'SMS failed to be delivered',
        title: 'Internal Error',
        tryAgain: 'Try Again'
      }
    },
    phoneVerificationErrors: {
      defaultError: {
        title: 'Unknown error'
      },
      invalidRequest: {
        errorMessage: 'Hash is bad or channel partner is not whitelisted.'
      },
      phoneNumberOptedout: {
        errorMessage: 'Customer has replied STOP. Loan process cannot continue'
      },
      phoneNumberIsLandLine: {
        errorMessage: 'This phone number is a landline.'
      },
      phoneNumberIsInvalid: {
        errorMessage: 'Invalid phone number.'
      },
      invalidEmailAddress: {
        errorMessage: 'Invalid Email Address.'
      },
      noSalesPerson: {
        errorMessage: 'No valid salesperson.'
      },
      unexpectedFailure: {
        errorMessage: 'Some unexpected failure! SMS not sent.',
        title: 'Unexpected failure'
      }
    },
    productSelection: {
      title: sharedText.editOffer,
      subHeader:
        'Change the estimate by adding/removing project types or editing the cost.',
      subHeaderWebbank:
        'Change the total project amount by adding/removing project types or editing the cost.',
      approvedLoanAmount: 'Qualified for up to:',
      projectEstimate: 'Total amount to finance',
      loanProduct: 'Loan Product',
      projectEstimateWebbank: 'Total project amount:',
      totalMonthlyPayment: sharedText.monthlyPayment,
      combinedMonthlyPayment: 'Combined monthly payment',
      monthlyPaymentAccessible: (
        isBeforePeriod = false,
        monthPeriod,
        amount = 0
      ) =>
        `${
          isBeforePeriod ? 'Before month' : 'After month'
        } ${monthPeriod} the amount is  $${amount}`,
      monthlyPayments: {
        before: 'Monthly payment before month',
        after: 'Monthly payment after month',
        combinedBefore: 'Combined Monthly payment before month',
        combinedAfter: 'Combined Monthly payment after month'
      },
      financeProductSelectTitle: 'Finance plans',
      save: 'Save',
      cancel: sharedText.cancel,
      financeProductError: 'please select a finance product below',
      overLimitError: 'max total project amount is ',
      underLimit: 'min total project amount is ',
      amountNotAvailable: '$--.--'
    },
    PartnerText: () => {
      const linkTextColor = useStyles()
      return (
        <>
          Financing applied for and processed through the Mosaic platform is
          originated by Solar Mosaic LLC or one of its{' '}
          <a
            href="https://www.joinmosaic.com/mosaic-lending-financing-partners/"
            target="_blank"
            rel="noreferrer"
            className={linkTextColor.partnerTextColor}
          >
            lending/financing partners
          </a>
          . Refer to transaction specific disclosures and loan documents for
          details and additional information. Your financing may be contingent
          upon you becoming a member of the lender organization at no membership
          cost to you.
        </>
      )
    },
    disclosureNotAcceptedError:
      'You must accept the disclosures before submitting.',
    agreeToAllDisclosures: 'I agree to the Terms and Conditions listed above.',
    titleAttestation:
      'I declare that I own the project address in my name or directly through a trust where I am the trustee.',
    titleAttestationNotAccepted:
      'To pre-qualify for credit, the declaration above must apply to you and you must check the box.',
    termsAndConditionsNotAccepted:
      'To pre-qualify for credit, please agree to these terms and conditions.',
    accept: {
      reviewAndAccept: sharedText.reviewAndAccept,
      pleaseReview: 'Please review and accept your offer.',
      amountFinancedDeprecated: 'Amount financed:',
      amountFinanced: 'Amount financed',
      amountFinancedSubText:
        'The amount of credit provided to you or on your behalf.',
      firstPaymentDue: 'First payment due:',
      aprDeprecated: 'Annual Percentage Rate (“APR”):',
      apr: 'Annual Percentage Rate (“APR”) ',
      aprSubText: 'The cost of your credit as a yearly rate.',
      aprDescription: (aprDiscounted: number) =>
        `Want to decrease your APR to ${aprDiscounted}% and lower your monthly payments? ***`,
      editOffer: sharedText.editOffer,
      reviewAndSign: sharedText.continue,
      monthlyPaymentDeprecated: 'Monthly payment:',
      monthlyPayment: 'Monthly payments ',
      MonthlyPaymentDescription: ({
        loanProductType,
        monthlyPaymentObject: {
          numMonthlyPayments,
          numFinalPayments,
          numPaymentsUntilReamortization,
          estimatedFirstPaymentDate,
          numRegularPaymentsAfterReamortization,
          firstPaymentDateAfterReamortization,
          actualFinalMonthlyPayment,
          actualChoiceMonthlyPayment,
          actualStandardMonthlyPayment
        }
      }) => {
        const classesViaHook = useStyles()
        return loanProductType === loanProductTypeEnum.powerSwitch ? (
          <>
            <Typography variant="body2" component="div">
              {' '}
              <b>
                {numPaymentsUntilReamortization} payments of{' '}
                <span data-testid="monthly-payment-description-monthly-payment">
                  {actualChoiceMonthlyPayment}
                </span>
              </b>{' '}
              <span className={classesViaHook.wrapWhenNecessaryText}>
                beginning {estimatedFirstPaymentDate}
              </span>
            </Typography>
            <Typography variant="body2" component="div">
              {' '}
              <b>
                {numRegularPaymentsAfterReamortization} payments of{' '}
                <span data-testid="monthly-payment-description-monthly-payment">
                  {actualStandardMonthlyPayment}
                </span>
              </b>{' '}
              <span>
                beginning {firstPaymentDateAfterReamortization}, assuming no
                payments have been made
              </span>
            </Typography>
            <Typography variant="body2" component="div">
              <b>
                {numFinalPayments} final payment of{' '}
                <span data-testid="monthly-payment-description-actual-final-payment">
                  {actualFinalMonthlyPayment}
                </span>
              </b>
            </Typography>
          </>
        ) : (
          <>
            <Typography variant="body2" component="div">
              <b>
                {numMonthlyPayments} payments of{' '}
                <span data-testid="monthly-payment-description-monthly-payment">
                  {actualStandardMonthlyPayment}
                </span>
              </b>{' '}
              <span>beginning {estimatedFirstPaymentDate}</span>
            </Typography>
            <Typography variant="body2" component="div">
              <b>
                {numFinalPayments} final payment of{' '}
                <span data-testid="monthly-payment-description-actual-final-payment">
                  {actualFinalMonthlyPayment}
                </span>
              </b>
            </Typography>
          </>
        )
      },
      numberOfPayments: 'Number of payments:',
      financeChargesDeprecated: 'Finance charges:',
      financeCharges: 'Finance charges ',
      financeChargesSubText: 'The dollar amount your credit will cost you.',
      totalPaymentsDeprecated: 'Total payments:',
      totalPayments: 'Total payments ',
      promoPeriod: 'Promo period',
      loanTerms: 'Loan terms',
      notAvailable: 'N/A',
      customerInfo: sharedText.customerInfo,
      fullName: 'Full name:',
      mobilePhone: 'Mobile phone number:',
      emailAddress: 'Email address:',
      installationAddress: 'Project address:',
      primaryAddress: 'Primary Address:',
      estimateFootnote: '*All values are based on estimates.',
      achFootNote: {
        note1: '*** Subject to terms and conditions. Refer to ',
        note2: 'Autopay ACH Terms',
        note3: ' for details.'
      },

      aSignedCopyOfTheLoanAgreement:
        'After signing, you will receive a signed copy of the loan agreement by email and SMS, and you will have three (3) business days to cancel.',
      ifYouPayOffThePrincipalDeprecated:
        'All values representative of principal balance paid in full during the promo period. While there are no mandatory payments during the promo period, we recommend paying your principal balance in full during the promo period to avoid additional costs. If the principal balance is not paid in full during the promo period, the deferred interest will be charged to your account from the loan start date at an Annual Percentage Rate of ',
      ifYouPayOffThePrincipal:
        'All values representative of principal balance paid in full during the promo period. ',
      promoPeriodReadMore: 'Read more',
      promoPeriodMoreInfo:
        'While there are no mandatory payments during the promo period, we recommend paying your principal balance in full during the promo period to avoid additional costs. If the principal balance is not paid in full during the promo period, the deferred interest will be charged to your account from the loan start date at an Annual Percentage Rate of ',
      promoPeriodInfo: ' Important information about the Promo Period: ',
      loadingTerms: 'Loading terms...',
      hardCreditInquiry:
        'Continue to review and sign your loan agreement. Upon our initial payment to your contractor, a hard credit inquiry will appear on your credit report.',
      interestDeferred: 'Interest deferred',
      noMandatoryPayments: 'No mandatory payments',
      noPaymentsFor: (contract: Object) =>
        `No payments for ${get(contract, 'loanProduct.promoPeriod', 0)} months`,
      loanAgreementError: `There is an issue with your loan agreement. Please contact us at ${supportPhone}.`,
      genericError:
        'Something went wrong, cannot proceed to loan agreement signing at this time.',
      addOnInfo: 'Maintenance plan terms',
      addOnQuanity: 'Quantity of maintenance plans:',
      addOnRate: 'Per maintenance plan rate:',
      addOnTotal: 'Total monthly cost:',
      addOnDisclosure:
        'Maintenance plan may be cancelled at any time, however, cancellation of maintenance plan requires ongoing maintenance of the unit(s) in accordance with your extended warranty. ',
      addOnDisclosureMoreInfo:
        'Please see the terms of your warranty prior to cancellation.',
      addOnDisclosureDeprecated: (
        <span>
          {
            'Maintenance plan may be cancelled at any time, however, cancellation of maintenance plan requires ongoing maintenance of the unit(s) in accordance with your extended warranty. Please see the terms of your warranty prior to cancellation.'
          }
          <br />
          <br />
          {
            'Maintenance plan payments must be current prior to maintenance plan visits.'
          }
          <br />
          <br />
          {
            'Monthly maintenance plan will run through the full term of the loan, even after the loan is paid in full, unless you decide to cancel early.'
          }
        </span>
      ),
      project: sharedText.projects,
      stage: 'Project stage',
      stageName: 'stage',
      optOutAddOnDisclosure:
        'Your unit may require ongoing maintenance in accordance with warranties associated with your HVAC. See your warranty for further details.',
      projectStageDescription: (projectName, stageName, amount) =>
        `${projectName}, ${stageName} ${sharedText.stage} ${amount}`,
      stagedFunding: {
        disbursements:
          'Disbursements to your contractor will be made as each stage is completed. Interest will begin to accrue on the date of each disbursement.',
        disbursementsDeprecated:
          'Disbursements will be made as each stage is completed. Interest will begin to accrue on the date of each disbursement.',
        personalInfoDeprecated: 'Please review your personal info.',
        personalInfo:
          'Please review your personal info and contact your rep about any errors.'
      },
      lenderSection: {
        lenderLabel: 'Lender',
        FinancingOfferedBy: ({
          name,
          className,
          isConnexusLender = false
        }: {
          name: string,
          className: string
        }) => (
          <>
            Your financing is offered by{' '}
            <span className={className}>{name}</span>.{' '}
            {isConnexusLender &&
              'We’ll create a membership for you if you don’t already have one.'}
          </>
        ),
        label: 'Lender',
        Proceed: ({ name }: { name: string }) => (
          <>
            To proceed with this loan, you will need to become a member of{' '}
            {name}. There is no cost to you to join. Mosaic will fund the $10
            required for your membership.{' '}
          </>
        ),
        learnMore: 'Learn more'
      }
    },
    lender: {
      [lendersEnum.CONNEXUS]: {
        name: 'Connexus Credit Union',
        shortName: 'Connexus',
        association: 'Connexus Association'
      },
      [lendersEnum.MOSAIC]: {
        name: 'Solar Mosaic LLC',
        shortName: 'Mosaic'
      }
    },
    lenderDialog: {
      title: 'Lender',
      close: 'Close',
      switchLender: 'switch to a different lender',
      switchingLender: 'Switching lender...',
      Text: ({
        lender,
        linkComponent,
        switchLenderLinkComponent,
        lenderAssociationComponent,
        showSwitchLenderLink,
        customer,
        refetch,
        closeModal,
        setLoading
      }: {
        lender: LenderObjectType,
        linkComponent: React$PureComponent,
        switchLenderLinkComponent: React$PureComponent,
        lenderAssociationComponent: React$PureComponent,
        showSwitchLenderLink: boolean,
        customer: CustomerShape,
        refetch: Function,
        closeModal: Function,
        setLoading: boolean
      }) => {
        const classes = useLenderDialogStyles()
        const LinkComponent = linkComponent
        const SwitchLenderLinkComponent = switchLenderLinkComponent
        const LenderAssociationComponent = lenderAssociationComponent
        return (
          <Typography
            variant="body2"
            component="p"
            className={classes.proceedText}
            data-testid="proceed-text"
          >
            You will need to become a member of{' '}
            <LinkComponent lender={lender} /> to proceed with this loan.
            Membership requires the opening of a savings account and a donation
            to the <LenderAssociationComponent lender={lender} />. Mosaic will
            pay the required fee to make you eligible for membership at the
            outset of your account with {lender.shortName}. Specifically, Mosaic
            will fund the initial $5 deposit to open a savings account and will
            donate $5, on your behalf, to the {lender.association}. You will be
            responsible for maintaining your membership. If you are already a
            member of {lender.name} then this will not create a new membership
            for you.{' '}
            {showSwitchLenderLink ? (
              <>
                If you do not want to become a member of this credit union, you
                may be able to{' '}
                <SwitchLenderLinkComponent
                  lender={lender}
                  customer={customer}
                  refetch={refetch}
                  closeModal={closeModal}
                  setLoading={setLoading}
                />
                .
              </>
            ) : (
              <>
                If you do not want to become a member of this credit union,
                please reach out to your rep to discuss your payment options.
              </>
            )}
          </Typography>
        )
      }
    },
    loadingTerms: 'Loading terms...',
    confirmIdentity: 'Before we continue...',
    confirmIdentityDescription:
      'Keeping your personal and financial information safe is important to us. Please confirm your identity before continuing.',
    confirmIdentityError:
      'The information entered does not match our records, please try again.',
    verifyIdentityDescription:
      'Please verify the following information you previously provided in your credit application.',
    cancelButton: 'Cancel financing',
    cancelContractDialog: {
      title: 'Are you sure?',
      subtitle:
        'If you leave now you will need to start over with financing if you change your mind later. You can always apply for financing again later.',
      body: 'We’ll take you back to the product selection page from which you may choose to make edits.',
      backButton: sharedText.back,
      confirmButton: 'Yes, cancel financing'
    },

    shareLinkDialog: {
      // The installer will be reading the share link dialog, so leave this in English
      title: sharedText.send,
      body: 'For the customer to continue on their own device, please confirm this is the customer’s information and select a delivery method below.',
      buttonTitle: (flag: boolean = false) =>
        flag ? 'Certify Now' : 'Yes, Send Now',
      customerConfirmationButtonTitle: 'Send Now',
      confirmedProjectBtnTitle: sharedText.done,
      promotionalPrefix: 'Promotional',
      loanProductString: ({ loanMonths, apr }: LoanProductStringProps) =>
        `${Math.floor(Number(loanMonths) / 12)}-year at ${apr.toFixed(2)}% APR`,
      projectTypes: 'Project types:',
      projectName: 'Project name:',
      customerName: 'Customer name:',
      loanAmount: 'Loan amount:',
      loanDetails: 'Loan details:',
      landlineLabel: 'No mobile phone number for this customer',
      sendTo: 'Send to',
      noFinanceProductSelected: '(no finance product selected)',
      noProjectTypeSelected: '(no project type and cost entered)',
      sent: 'Sent',
      sentLbl: 'You sent this ',
      sendToLabel1:
        'You are about to send an SMS text message to the customer at phone number ',
      sendToLabel2: ' to request confirmation. Are you sure?',
      doneSuccessfullySentToMobile: 'Done! Text Message sent to',
      doneSuccessfullySentToEmail: 'Done! Email sent to',
      DoneSuccessfullySentBoth: ({ email, phoneNumber }) => (
        <>
          {`Done! We sent a text message to `}
          <b>{phoneNumber}</b>
          {` and an email to `}
          <b>{email}</b>.
        </>
      ),
      cancel: sharedText.cancel
    },

    signed: {
      continue: sharedText.continue,
      description:
        'Please check your email or SMS for a signed copy of your loan agreement.',
      title: sharedText.signedTitle,
      viewAgreement: sharedText.viewAgreement
    },

    emailVerification: {
      title: 'Action needed',
      subTitle: 'Consent to receiving electronic communications',
      emailSign: 'Confirm your email address',
      waiting: 'Waiting for a response from',

      description: (email: string) => (
        <>
          {
            'In order to continue with your loan, we need to verify that the email '
          }
          <b>{email ? `${email} ` : 'above'}</b>
          {` belongs to you. Select the button below to send yourself an email with
        the subject `}
          <b>
            {`"Action Needed: Consent to receiving electronic
          communications"`}
          </b>
          . Please follow the link to verify.
        </>
      ),
      sendEmail: 'Send Email Now',
      sent: date => `Sent ${date}`,
      resendEmail: 'Resend email',
      never: 'Not yet sent',
      userLinkEmailVerification: {
        needToVerify:
          'In order to proceed with your loan, we need to verify that the above email address belongs to you.',
        pleaseOpen:
          'Please open the email with subject "Action Needed: Verify your email address" and follow the link to confirm you have access to this email account.'
      }
    },

    summary: {
      title: sharedText.summary,
      projects: 'Projects:',
      incomplete: sharedText.incomplete,
      waitingForConfirmation: 'Waiting for confirmation',
      installationComplete: 'Installation complete',
      viewAgreement: sharedText.viewAgreement,
      createChangeOrder: 'Create Change Order',
      requestChangeOrder: 'Request change order',
      changeOrderText:
        'The customer has already signed the loan agreement. If you continue, the existing loan agreement will be voided and the customer will be informed via email. After updating the project information please ask the customer to review and accept the offer.',
      cancel: sharedText.cancel,
      confirm: 'Yes void agreement now',
      loanAgreementError: `There is an issue with your loan agreement. Please contact us at ${supportPhone}.`,
      noContract: 'This customer does not have a contract.'
    },

    mobileVerificationScreen: {
      emailErrorTypes: {
        [errorKeys.emailInstaller]: sharedText.emailInstaller,
        [errorKeys.emailInvalidFormat]: sharedText.emailMustBeValid
      },
      phoneErrorTypes: {
        [errorKeys.phoneNumberIsInvalid]: sharedText.phoneNumberIsInvalid,
        [errorKeys.phoneNumberIsOptedOut]: sharedText.phoneNumberIsOptedOut,
        PhoneNumberIsLandline: phoneNumber =>
          `Cannot send an SMS to ${phoneNumber} because it is a landline. Please provide a mobile phone number.`,
        landlineWarning: {
          title:
            'A mobile phone number is required in order to enable your rep to make your project run more efficiently. ',
          iDoNotOwnMobileLink: 'I do not own a mobile phone',
          modalTitle: 'No mobile phone',
          modalContent:
            'Receiving text (SMS) updates makes it easy for you to instantly get updates. If you continue with a non-mobile phone number, we will send all communication by email only.',
          backButton: sharedText.back,
          emailOnlyButton: sharedText.emailOnly
        },
        ServerError:
          'We were unable to reach this mobile number. Please confirm that the number is entered correctly, or try using a different phone number.'
      }
    },

    errorMessages: {
      noLoanProducts: (partnerName: string) =>
        `${partnerName}'s account is being updated. Please reach out to your sales rep before you can proceed.`
    },
    waitingForContract: 'Waiting for contract to be created.'
  }

  const layout = {
    poweredByMosaic: 'Powered by Mosaic',
    applicationDescription: 'Tap Into Financing'
  }

  const start = {
    applyNow: 'Pre-Qualify Now',
    contractor: 'Contractor',
    demoMode: 'Demo Mode',
    giveAnEstimate: 'Give An Estimate',
    myPipeline: 'My pipeline',
    newCustomer: sharedText.newCustomer,
    onboarding: {
      text1: {
        text1: 'You’re in demo mode.',
        text2: 'Go poke around!'
      },
      text2: 'Hint: you can access the menu from anywhere with this icon.',
      text3: 'Ready to go live? Hit this switch.'
    }
  }

  const formFields = {
    required: 'This field is required'
  }

  const login = {
    form: {
      apply: {
        content: 'Don’t offer Mosaic financing yet?',
        link: 'Learn More'
      },
      button: 'Log In',
      email: {
        error: 'Invalid email address'
      },
      error: 'Wrong user or password. Try again',
      forgot: 'Forgot?'
    },
    loginFailReason: {
      unexpectedError: {
        title: 'Unexpected Error',
        content: 'There was an issue logging you in.  Please try again.'
      },
      title: 'Log in failed',
      content:
        'Check your email and password. If you have tried multiple times your account may now be locked. If so, try again in a few minutes.'
    },
    loginMessageError: {
      ok: 'OK'
    },
    logoutStatuses: {
      expired: {
        title: 'Session Timeout',
        content: 'You have been automatically logged out because of inactivity.'
      },
      logout: {
        title: 'Not logged in',
        content: 'Please log in to continue.'
      },
      duplicate: {
        title: 'ID currently in use',
        content:
          'You have been logged out because your ID was used to log in elsewhere.'
      }
    }
  }

  const setup = {
    confirmation: {
      accountTypes: {
        [accountTypes.checking]: sharedText.checking,
        [accountTypes.savings]: sharedText.savings
      },
      bankFields: {
        accountHolderFullName: 'Account Holder’s Name(s):',
        accountNumber: 'Account Number:',
        accountType: 'Account Type:',
        accountTypeLabel: 'Account type',
        bankName: 'Bank/Credit Union Name ("Depository"):',
        routingNumber: 'ABA Number'
      },
      buttons: {
        cancel: sharedText.goBack,
        disclosureText1: 'I agree to the ',
        disclosureText2: 'Autopay ACH Terms.',
        submit: sharedText.continue,
        disclosureError: 'To set up autopay, please agree to these terms.'
      },
      descriptionPart1:
        'The payment amount debited monthly on or after the dates set forth in my Loan Agreement will be ',
      descriptionPart2:
        '. If the amount debited will vary from this amount, Mosaic will provide notice at least ten (10) days before the scheduled date of the debit.',
      descriptionPart3: 'You’ll enjoy ',
      descriptionPart4:
        ' No Payments + Deferred Interest. If the balance is not paid in full in ',
      descriptionPart5:
        ', the deferred interest will be charged to your account from the date of funds disbursement at an Annual Percentage Rate (APR) of ',
      descriptionPart6:
        'To avoid interest charges you must pay the amount financed in full by the promotion expiration date listed on your statements. You can make payments on your loan online, by check, or by phone. Review your statements for details.',
      descriptionWithoutAddOn1:
        'The payment amount(s) debited monthly will be ',
      descriptionWithoutAddOn2:
        ' for the monthly loan payment. If the debited amount(s) will vary, Mosaic will provide notice at least ten (10) days before the scheduled date of the debit(s).',
      descriptionWithAddOn1: 'The payment amount(s) debited monthly will be ',
      descriptionWithAddOn2: ' for the monthly loan payment and ',
      descriptionWithAddOn3:
        ' for the monthly maintenance plan(s). If the debited amount(s) will vary, Mosaic will provide notice at least ten (10) days before the scheduled date of the debit(s).',
      errors: {
        [errorKeys.bankAccountInvalidAccountInformation]:
          'Account verification failed for the account entered. Please verify the information is correct as entered, and the account has sufficient available balance.'
      }
    },
    disclosures: {
      closeButton: sharedText.close,
      descriptionPart1:
        'You voluntarily authorize Solar Mosaic LLC and its assignees and servicers (“Mosaic”) to initiate electronic debits from your deposit account designated in the prior screen, or any additional accounts you designate to Mosaic in the future.',
      descriptionPart1_WebBank:
        'You voluntarily authorize (“Authorization”) WebBank, Member FDIC and its assignees and loan servicers (together, “we” or “us”) to initiate electronic debits (also known as “ACH debits”) from your deposit account designated in the prior screen, or any additional accounts you designate to us in the future.',
      descriptionPart2:
        'The amount debited will be the monthly payment owed on your Home Improvement Loan Agreement (or any modified amounts later agreed to).  You also authorize us to automatically debit any of the following payment(s): (i) any prepayments or other payments you schedule through Mosaic’s website, or otherwise authorize (verbally or in writing); or (ii) the amount of any late charges or NSF fees due under your Home Improvement Loan Agreement from time to time. ',
      descriptionPart2_SAC:
        'IMPORTANT NOTE: If you entered into a Home Improvement Loan Agreement with Deferred Interest, any automatic debit will not begin until after the promotional period ends.  If the full loan amount financed is not paid during the promotional period, deferred interest will be charged to your loan.  The deferred interest will be calculated from the date of loan disbursement and at the Annual Percentage Rate (APR) expressly stated in the Home Improvement Loan Agreement. In order to avoid the addition of interest to your loan, you must pay the full amount financed on or before the promotional end date found in your Home Improvement Loan Agreement and/or monthly loan statements.',
      descriptionPart3_Start:
        'The payment amount debited monthly on or after the dates set forth in my Home Improvement Loan Agreement will be ',
      descriptionPart3_End:
        '. If the amount debited will vary from this amount, we will provide notice at least ten (10) days before the scheduled date of the debit.',
      descriptionPart3_SAC:
        'PLEASE NOTE: If you entered into a Home Improvement Loan Agreement with Deferred Interest and you did not repay the full loan amount financed by the end of the promotional period, then the amount debited will include the deferred interest accrued.',
      descriptionPart4:
        'By clicking “I AGREE”, you voluntarily authorize us to electronically debit your account (and, if necessary, electronically credit your account to correct erroneous debits). We may also re-submit electronic debits that are not honored up to two additional times, and you may be liable to the Depository for resulting charges if you do not have sufficient funds in the account at the time of re-submission.',
      descriptionSavingsMoneyMarketDisclaimer:
        '*Transfers from Savings Accounts and Money Market Deposit Accounts are limited. If an automatic debit exceeds my limit it may be declined by the Depository or I may be charged a fee by the Depository as provided in my Home Improvement Loan Agreement with my Depository.',
      descriptionPart5:
        'You promise that you are authorized to agree to ACH debits from the account at the Depository described above and you are not required to obtain the agreement or consent of any other person who owns or has a claim to funds in that account (and that you have obtained all required agreements and consents from other persons who own or have a claim to funds in that account). If you inadvertently transpose a digit or make a similar error in providing us with information about the account, you authorize us to correct the error after verifying the information with me. You understand that if your account at the Depository does not have enough available collected funds to cover the scheduled debit, the Depository may charge you overdraft or other fees, and you may owe us an NSF and/or late fee as described in the Home Improvement Loan Agreement.',
      descriptionPart6:
        'You can cancel this Authorization or any payment under this Authorization by contacting us at ',
      descriptionPart7:
        '(or any subsequent email address or point of contact provided)',
      descriptionPart8:
        ' and this cancellation will be effective within three (3) business days after receipt. You may also cancel this Authorization by giving the Depository notice of termination or by stopping payment of any scheduled debit at least three (3) business days before the scheduled date of the next debit. You may obtain additional information about the Depository’s preauthorized ACH debit stop payment requirements. You may authorize direct payments from a new account by contacting us at ',
      descriptionPart9:
        ' (or any subsequent email address or point of contact provided) at least three (3) business days prior to your payment due date. Such new account will replace the account designated in this Authorization and will be subject to these terms and conditions.',
      descriptionPart10:
        'You understand that if you terminate this Authorization or stop payment of any scheduled debit(s), you will still be required to make the monthly payments due under the Home Improvement Loan Agreement.',
      descriptionPart11:
        'You further understand that your Home Improvement Loan Agreement offers a 0.25% rate reduction on loans that are paid under this Authorization. If you terminate this Authorization, your annual percentage rate will increase by 0.25%. An increase will take the form of higher payment amounts. If you switch from check to ACH payments by consenting to this Authorization, it may take one billing cycle for the interest rate reduction to take effect.',
      descriptionPart12:
        'BY CLICKING “I AGREE”, YOU AGREE AND UNDERSTAND THAT YOU ARE AUTHORIZING US TO DEBIT AND CREDIT YOUR ACCOUNT AT THE DEPOSITORY AS DESCRIBED IN THIS AUTHORIZATION. YOU ALSO AGREE TO RECEIVE A COPY OF THIS AUTHORIZATION ELECTRONICALLY FOR YOUR RECORDS.',
      loanNoticesEmail: 'loannotices@joinmosaic.com',
      title: 'Authorization for Direct Payment via ACH'
    },
    form: {
      accountHolderFullName: {
        label: 'Account holder name'
      },
      accountNumber: {
        error: 'Invalid account number',
        label: sharedText.accountNumber
      },
      paymentTitle: 'PAYMENT',
      paymentTitle1: 'PAYMENT 1',
      paymentTitle2: 'PAYMENT 2',
      payment1Disclosure:
        'If you set up autopay (ACH), your APR would decrease to ',
      payment2Disclosure:
        'Set up autopay now and you don’t have to think about making both payments by check every month.',
      permonthCost1: ' per month for your loan',
      permonthCost2: ' per month for your maintenance plan',
      accountTypes: {
        checking: {
          label: sharedText.checking
        },
        savings: {
          label: sharedText.savings
        }
      },
      bankName: {
        label: 'Bank name'
      },
      buttons: {
        done: sharedText.done,
        continue: sharedText.continue,
        doItLater: 'Skip'
      },
      descriptionPart1:
        'If you make payments by ACH, your APR would decrease to ',
      descriptionPart2: ' and your monthly payments would decrease by ',
      descriptionPart3: ', to ',
      descriptionPart4: '.',
      routingNumber: {
        label: sharedText.routingNumber,
        minDigitsError: 'Routing number must be 9 digits',
        numberError: 'Invalid routing number'
      },
      title: sharedText.setupAutopay,
      reviewAutopayTitle: sharedText.reviewAutopay,
      titleNoContract: 'No contract yet',
      textNoContract:
        'You can set up autopay once you complete your application.'
    },
    thankYou: {
      title: 'Thank you',
      description: 'You have successfully switched to pay by ACH.'
    },
    setupSkipped: {
      title: 'OK, thanks',
      description:
        'We’ll send you a reminder about the option to sign up for autopay later. '
    },
    integrator: {
      title: 'Thanks!',
      description: (firstName: string, lastName: string) => (
        <>
          {`You’re done! We’ll notify your rep`}{' '}
          <strong>
            {firstName} {lastName}
          </strong>{' '}
          {`that you have completed financing and they will let you know
            about next steps.`}
        </>
      ),
      contractAlreadySigned: 'Contract is already signed for this customer'
    },
    emailConfirmation: {
      title: 'Consent provided',
      description:
        'Thank you for providing consent to receive electronic communications and disclosures electronically',
      buttonText: sharedText.continue
    },
    phoneNumber: '510-344-8321',
    setUpPaymentPlan: phoneNumber =>
      `There are currently no payment plans for this branch of your business. If this issue persists, please contact Mosaic support at ${phoneNumber}.`
  }

  const addToHomescreen = {
    navLink: 'Install this app',
    title: 'Mosaic Point-of-Sale',
    intro:
      'To install this application on your homescreen for quick and easy access ',
    instructions: {
      // eslint-disable-next-line quotes
      ios: ['just tap the ', " icon then choose 'Add to homescreen'."],
      // eslint-disable-next-line quotes
      android: ['just tap the ', " icon then choose 'Add to homescreen'."]
    },
    close: sharedText.close
  }

  const sidebarNav = {
    admin: 'Setup',
    logout: 'Log out',
    pipeline: 'Pipeline',
    finance: 'Finance',
    notifications: 'Notifications',
    resources: 'Resources',
    settings: 'Settings',
    start: 'Start',
    backOffice: 'Back-office',
    portalBackOffice: 'Portal back-office',
    backOfficeDescription:
      'View your bank disbursements, email notifications, and training materials. Log in using the same credentials.',
    help: 'Help / Support',
    ariaLabels: {
      closeMenu: 'Close menu',
      start: 'Start',
      pipeline: 'Pipeline',
      setup: 'Setup',
      resources: 'Resources',
      backOffice: 'Portal backoffice',
      helpAndSupport: 'Help and support',
      logout: 'Logout'
    }
  }

  const contractFrame = {
    loanAgreementError: `There is an issue with your loan agreement. Please contact us at ${supportPhone}.`,
    signContract: 'Sign contract'
  }

  const projectStatus = {
    title: 'Project status',
    markAsComplete: 'MARK AS COMPLETE',
    confirmed: 'CONFIRMED',
    confirmedDate: date => `CONFIRMED ${date}`,
    description: 'Ericson to change or remove this description text....',
    refreshButton: sharedText.refresh,
    servicePlanAndCount: count =>
      `${count} maintenance plan${count === 1 ? '' : 's'}`,
    servicePlanDescription: {
      servicePlanText: `Each 10-year maintenance plan for ${DOLLAR_PREFIX}`,
      amountAddOns: price => price,
      partnerNameText: partnerName =>
        ` per month is provided by ${partnerName}.`
    },
    subheading: sharedText.project
  }

  const common = {
    readOnlyDisclaimerMessages: {
      [contractStatuses.signed]:
        'Please refer to your final loan agreement for the most accurate information.',
      [contractStatuses.cancelled]: (userType: string) =>
        `This application is no longer active. ${
          userType === userTypes.installer
            ? 'To proceed, the customer must reapply.'
            : 'Please contact your rep to apply.'
        }`,
      [opportunityStatuses.deleted]: (userType: string) =>
        `This application is no longer active. ${
          userType === userTypes.installer
            ? 'To proceed, the customer must reapply.'
            : 'Please contact your rep to apply.'
        }`
    }
  }

  const installComplete = {
    actions: {
      opportunityOnHold: 'Opportunity on Hold',
      submitInstallCompleteRequest: (isAutoRep: boolean) =>
        isAutoRep ? 'Confirm Completion' : 'Request Confirmation',
      sendReminder: (isAutoRep: boolean) =>
        isAutoRep ? 'Confirm Completion' : 'Send Reminder',
      queued: 'Confirmation Request Sent to Customer',
      confirmed: date => `Confirmed ${date}`
    },
    modal: {
      title: 'Request confirmation',
      header: {
        OnlyEmail: ({ email }: { email: string }) => {
          const modalStyles = useStyles()
          return (
            <>
              We will email (
              <span className={modalStyles.itemBoldWordBreak}>{email}</span>)
              the customer a request to confirm that this stage of this project
              is complete.
            </>
          )
        },
        EmailAndPhone: ({
          email,
          phoneNumber
        }: {
          email: string,
          phoneNumber: string
        }) => {
          const modalStyles = useStyles()
          return (
            <>
              We will email (
              <span className={modalStyles.itemBoldWordBreak}>{email}</span>)
              and text (
              <span className={modalStyles.itemBold}>{phoneNumber}</span>) the
              customer a request to confirm that this stage of this project is
              complete.
            </>
          )
        }
      },
      body: 'Send a request for the customer to mark the project below as having been completed.',
      projectType: sharedText.projectType,
      projectCost: sharedText.projectCost,
      projectName: sharedText.projectName,
      stageName: sharedText.stageName,
      stageCost: sharedText.stageCost,
      customer: sharedText.customer,
      address: 'Address',
      projectAddress: 'Project address'
    }
  }

  const confirmComplete = {
    actions: {
      opportunityOnHold: 'Opportunity on Hold',
      submitInstallCompleteRequest: 'Confirm Completion',
      submitInstallCompleteRequestHelper: 'Request confirmation from customer',
      confirmed: date => `Confirmed ${date}`
    },
    modal: {
      title: 'Please certify this project is complete',
      dashboardTitle: 'Confirm completion',
      autoRepTitle: 'Confirm completion',
      salesAgreementTitle: 'Sales agreement',
      ptoDocumentTitle: 'Permission to Operate (PTO) Document',
      ptoDucmentShortTitle: 'PTO Document',
      completionCertificate: 'Completion Certificate',
      body: '',
      dashboardBodyPTO: (
        <>
          Please make sure this is the correct customer before uploading the{' '}
          <strong>PTO document</strong>
        </>
      ),
      dashboardBody:
        'Please make sure this is the correct customer and project before uploading the.',
      dashboardBodyStaged: (
        maxSize: number = fileUploadParams.MAX_FILE_UPLOAD_SIZE
      ) => (
        <>
          Please make sure this is the correct customer, project and stage
          before uploading the <strong>Completion Certificate</strong>.{' '}
          {genericUploader.maxSizeLabel(maxSize)}
        </>
      ),
      dashboardBodySalesAgreement: (maxSize: number) => (
        <>
          Please make sure this is the correct customer before uploading the{' '}
          <strong>Sales Agreement</strong>, also known as the home improvement
          agreement (HIA). {genericUploader.maxSizeLabel(maxSize)}
        </>
      ),
      dashboardUpload: 'Upload a completion certificate',
      disclosure: [
        'Pursuant to the executed Mosaic Participation Agreement or the Mosaic Dealer Agreement (in either case, the “Agreement”) between Solar Mosaic LLC (or any of its successors, heirs and permitted assigns), and my firm (“Participant” or “Dealer”), I certify that:',
        '(1) I am a Participant Authorized User or a Dealer Authorized User, as applicable;',
        '(2) This project is complete; and',
        '(3) All representations by Participant or Dealer in the Agreement remain true',
        'Capitalized terms used but not defined herein have the meaning given to them in the Agreement.'
      ],
      disclosureStaged: [
        'Pursuant to the executed Mosaic Participation Agreement or the Mosaic Dealer Agreement (in either case, the “Agreement”) between Solar Mosaic LLC (or any of its successors, heirs and permitted assigns), and my firm (“Participant” or “Dealer”), I certify that:',
        '(1) I am a Participant Authorized User or a Dealer Authorized User, as applicable;',
        '(2) This stage is complete; and',
        '(3) All representations by Participant or Dealer in the Agreement remain true',
        'Capitalized terms used but not defined herein have the meaning given to them in the Agreement.'
      ],
      finalConfirm:
        'Please make sure you understand the following before you submit confirmation of completion:',
      finalConfirmItems: [
        'You will not be able to adjust the loan amount or add projects',
        'Your final disbursement will be scheduled',
        'The status of the project will be marked complete'
      ],
      successfulDescription:
        'Thank you for certifying that this project is complete. Note that the customer has also been notified via email and text (SMS) at ',
      successfulDescriptionStaged:
        'Thank you for certifying that this project stage is complete. Note that the customer has also been notified via email and text (SMS) at ',
      gotItButton: sharedText.gotIt,
      successfulTitle: 'Successfully confirmed',
      replaceCertificate: 'Replace certificate',
      disclosureLabel: 'Disclosure',
      userName: 'User name',
      dateAndTime: 'Date and time',
      projectType: sharedText.projectType,
      projectName: sharedText.projectName,
      stageName: sharedText.stageName,
      projectID: 'Project ID',
      stageID: 'Stage ID',
      projectCost: sharedText.projectCost,
      stageCost: sharedText.stageCost,
      customerName: sharedText.customer,
      projectAddress: sharedText.projectAddress,
      buttonTitle: (flag: boolean = false) =>
        flag ? 'CERTIFY NOW' : 'CONFIRM NOW'
    },
    stipulationResolution: {
      borrowerMessage:
        'You have been conditionally pre-qualified. However, we still require further information to complete our review. ',
      resolveNow: 'Resolve it now.',
      installerMessage:
        'We have conditionally pre-qualified this customer. However, we still require further information from the customer to complete our review.'
    }
  }

  const fileUpload = {
    description: (helperText: ?string) => (
      <>
        Please make sure this is the correct customer before uploading the
        <b> {helperText}</b>, also known as the home improvement agreement
        (HIA).
      </>
    )
  }

  const notFound = {
    title: 'Page not found',
    maintenanceTitle: 'Site under maintenance',
    descriptionLn1:
      'Sorry, the page you are looking for does not exist or is no longer available.',
    maintenanceDescriptionLn1:
      'Sorry, the page you are looking for is currently unavailable. Please try refreshing this page in a few minutes.',
    descriptionLn2: 'Error code: 404',
    maintenanceDescriptionLn2: 'Thanks for your patience.',
    invalidShareLink:
      'This link appears to be broken. Please connect with your installer to receive a new link.',
    goBack: sharedText.goBack,
    login: 'Back to Log in',
    networkErrorTitle: 'Network Error',
    descriptionNetworkErrorLn1:
      'Sorry, your connection seems to be having trouble.',
    descriptionNetworkErrorLn2:
      'Please check your connection and try refreshing this page in a few minutes.',
    partner: {
      title: 'Partner not found',
      descriptionLn1:
        'The link you have accessed may be misspelled or incorrect.',
      descriptionLn2: 'Please check your spelling and try again.'
    },
    genericErrorTitle: 'Something unexpected happened',
    genericErrorDescription:
      'We’re sorry for the inconvenience. An error report has been generated.'
  }

  const serverError = {
    title: 'Internal Server Error',
    descriptionLn1: 'Service is not accessible, please try again later.',
    InstallCompleteException: sharedText.optOutSMS,
    PhoneNumberOptedOutException: sharedText.optOutSMS
  }

  const expired = {
    title: 'Your password reset token has expired',
    resendButton: 'Resend',
    description:
      'We can resend you a new one if you need to reset your password.'
  }

  const forgotPassword = {
    title: 'Forgot password',
    description:
      'Please enter your email address and we’ll send you a link you can use to reset your password.',
    descriptionEmail: (email?: string) => (
      <>
        If we find <b>{email || 'your email address'}</b> in our system, we will
        send an email with a link to reset your password.
      </>
    ),
    descriptionEmailCheckSpamFolder: `
    Please check your spam folder or verify the email above is correct
    if you don’t see the email in your inbox.
  `,
    resendButton: 'SEND LINK',
    goBackToLoginButton: 'Back to log in',
    fieldName: sharedText.emailAddress
  }

  const serviceWorkerMessages = {
    newContent: 'New updates are available.',
    buttonText: sharedText.refresh.toUpperCase()
  }

  const contract = {
    contractExceedsProductLimit:
      'Total project amount cannot exceed approved financing amount.'
  }

  const themeSwitcher = {
    theme: 'Theme'
  }

  const banner = {
    demoModeBodyText: {
      default:
        'During demo mode, customers you create are for demonstration purposes only. Some features have been disabled to make exploring the app easier.',
      setup:
        'You are still in demo mode but changes you make within Setup will be saved.',
      shareLink:
        'In live mode, the customer will receive a link to their application, to proceed on their own device.',
      reviewAndSign:
        'In live mode, the customer will sign loan documents within this app. For demo mode, we will proceed to the step immediately following loan agreement signing.',
      reviewAndSignLast4Ssn:
        'In live mode, the customer will be asked to provide their full 9-digit Social Security Number, then sign loan documents within this app. For demo mode, we will proceed to the step immediately following loan agreement signing.',
      emailVerification:
        'In live mode, this page presents when the customer needs to prove their ability to access information electronically. From this page, the customer will trigger a verification email to the email address provided.',
      achSetup:
        'In live mode, the customer can set up autopay. The customer can choose to skip this, and they will receive a reminder.',
      viewAgreement:
        'In live mode, once documents have been signed, they can be viewed within the app here. Viewing the agreement is disabled in demo mode.',
      welcomeMessage:
        'Welcome to demo mode. Customers created here are for demonstration purposes only. Email communications and other capabilities have been disabled to make exploring the app easier.',
      documentUpload:
        'In live mode, the Mosaic operations team will be reviewing the sales agreement (HIA) document you uploaded.'
    },
    demoModeButtonText: {
      default: 'Ok',
      proceed: 'Proceed',
      continueDemo: 'CONTINUE DEMO',
      gotIt: sharedText.gotIt
    },
    demoModeTitleText: 'Demo',
    demoModeTitleTextNew: 'Demo Mode'
  }

  const demoModeAccessRevoked = {
    title: 'Access revoked',
    buttonText: 'Okay',
    logoutBodyText:
      'You no longer have access to demo mode, and you will be logged out.  If you think this was done in error, please contact your Mosaic rep.',
    redirectBodyText:
      'You no longer have access to demo mode.  We’ll take you back to live mode so you can continue using the app.  If you think this was done in error, please contact your Mosaic rep.'
  }

  const documentsTemplate = (
    title: string,
    document: string,
    additionalDescription: string = ''
  ) => {
    return {
      title: title,
      documentDescription: (
        <>
          Please provide a copy of
          <strong> {document}</strong>
          {`${additionalDescription ? ' ' : ''}${additionalDescription}.`}
        </>
      )
    }
  }

  const admin = {
    submit: sharedText.submit.toUpperCase(),
    title: 'Setup',
    lead: 'Set up your business. You can change it anytime.',
    notApproved:
      'Complete the steps below to set up your business profile while we review your application.',
    approvedWhenHasNoProductsAndHasNoBankAccount:
      'You have been approved. In order to begin offering financing, you need to finish setting up your business profile.',
    approvedWhenHasProductsAndHasNoBankAccount:
      'You have been approved. In order to begin offering financing, you need to link your bank account.',
    approvedWhenHasNoProductsAndHasBankAccount:
      'You have been approved. In order to begin offering financing, you need to select financial products.',
    approvedWhenHasProductsAndHasBankAccount:
      'You are almost ready to offer financing. There are just a few more steps on our end.',
    liveModeEnabled: 'You are ready to offer financing.',
    product: {
      title: sharedText.adminProducts,
      description:
        'Please contact your Mosaic account manager to make changes to your payment plans.',
      redSuggest:
        'Please contact your account manager to enable plans that fit your business. ',
      suggest:
        'We included a demo payment plan so you can inmediately play with the point-of-sale app.'
    },
    account: {
      title: sharedText.adminAccount,
      titleP3x: sharedText.adminAccountDocuments,
      tileDescription:
        'Some additional documents are required before we can approve your application.',
      completed: sharedText.completed,
      headerWithCount: (count: number) => {
        const plural = count !== 1
        const isAre = plural ? 'are' : 'is'
        const s = plural ? 's' : ''

        return count === 0
          ? 'You are all set. We do not need any additional documents from you at this time'
          : `There ${isAre} ${count} additional document${s} required to configure your account.`
      },
      requiredDocuments: 'Required documents',
      optionalDocuments: 'Optional documents',
      portal: (
        <>
          Go to <b>Portal</b>
        </>
      ),
      descriptionWithCounts: (requiredCount: number, optionalCount: number) => {
        const plural = requiredCount !== 1
        const quantityText =
          requiredCount === 2 ? 'both' : `all ${requiredCount.toFixed(0)}`
        const text = plural
          ? `${quantityText} of the above required documents have been`
          : 'the required document has been'
        const pluralOptional = optionalCount !== 1
        const s = pluralOptional ? 's' : ''
        return (
          <>
            <p data-testid="account-description-required">
              Once {text} uploaded, our partner onboarding team will take 1-2
              business days to complete their review. After our final review has
              been completed and a decision has been made, your dedicated
              Partner Development Manager will call you to go over next steps.
            </p>
            <p>
              If you have any questions about the process or would like
              additional information, please do not hesitate to contact our
              partner onboarding team at{' '}
              <Link href={`tel:855-452-4757`}>855-452-4757</Link> or email us at{' '}
              <Link href={`mailto:partner@joinmosaic.com`}>
                {' '}
                partner@joinmosaic.com
              </Link>
              .
            </p>
            {optionalCount > 0 && (
              <p data-testid="account-description-optional">
                As an option, you can provide {optionalCount.toFixed(0)}{' '}
                additional financial document{s} (listed below) for us to
                consider with your partner application.
              </p>
            )}
          </>
        )
      },
      uploadNow: 'Upload Now',
      modal: {
        title: '[title here]',
        header: 'Please provide a copy of [document name here]',
        subtitle: 'Select and upload file',
        chooseFile: 'Choose File'
      },
      maxFilesText: fileTypeList => (
        <>
          Maximum of 1 file allowed to be uploaded. Supported file types are
          <strong> {fileTypeList}</strong>.
        </>
      ),
      documents: {
        [channelPartnerDocumentTypes.generalLiabilityInsurance]:
          documentsTemplate(
            'Liability binder',
            'General Liability Insurance Binder'
          ),
        [channelPartnerDocumentTypes.workersCompensationInsurance]:
          documentsTemplate(
            'Insurance binder',
            'Workers Compensation Insurance'
          ),
        [channelPartnerDocumentTypes.homeImprovementAgreement]:
          documentsTemplate(
            'Sales agreement',
            'Sales Agreement',
            'including a 3 day right to cancel, no performance guarantees, and a 2+ year workmanship warranty'
          ),
        [channelPartnerDocumentTypes.currentProfitAndLossFinancial]:
          documentsTemplate(
            'Current P&L statement',
            'Current Year’s Profit and Loss Statement'
          ),
        [channelPartnerDocumentTypes.previousYearsProfitAndLossFinancial]:
          documentsTemplate(
            'Previous P&L statement',
            'Previous Year’s Profit and Loss Statement'
          ),
        [channelPartnerDocumentTypes.currentBalanceSheetFinancial]:
          documentsTemplate(
            'Current balance sheet',
            'Current Year’s Balance Sheet',
            'either year-to-date or through the end of the most recent quarter'
          ),
        [channelPartnerDocumentTypes.previousYearsBalanceSheetFinancial]:
          documentsTemplate(
            'Previous balance sheet',
            'Previous Year’s Balance Sheet'
          ),
        [channelPartnerDocumentTypes.secretaryOfState]: documentsTemplate(
          'Secretary of state registration',
          'Secretary of State Registration'
        ),
        [channelPartnerDocumentTypes.subcontractorsAgreement]:
          documentsTemplate(
            'Subcontractor’s agreement',
            'Subcontractor’s Agreement'
          ),
        [channelPartnerDocumentTypes.contractorsLicenseForSubcontractor]:
          documentsTemplate(
            'Contractor’s license for subcontractor',
            'Contractor’s License for Subcontractor'
          ),
        [channelPartnerDocumentTypes.subcontractorsWorkersCompensation]:
          documentsTemplate(
            'Subcontractor’s workers’ compensation',
            'Subcontractor’s Workers’ Compensation'
          ),
        [channelPartnerDocumentTypes.contractorsLicense]: documentsTemplate(
          'Contractor’s license',
          'Contractor’s License'
        )
      },
      documentPending: 'PENDING REVIEW'
    },
    adminProjectTypes: {
      title: sharedText.projectTypes,
      description:
        'Select which project types your sales reps are able to use for new opportunities',
      editButton: 'EDIT PROJECT TYPES',
      saveButton: 'SAVE CHANGES',
      cancel: sharedText.cancel.toUpperCase(),
      adminProjectList: sharedText.projectType,
      adminProjectDescription: 'Disbursement'
    },
    projectTypes: {
      title: sharedText.projectTypes,
      byId: {
        [projectTypeIds.doors]: 'Doors',
        [projectTypeIds.windows]: 'Windows',
        [projectTypeIds.hvac]: 'HVAC',
        [projectTypeIds.roofing]: 'Roofing',
        [projectTypeIds.interiorRemodel]: 'Interior Remodel',
        [projectTypeIds.exteriorRemodel]: 'Exterior Remodel',
        [projectTypeIds.otherOrCustom]: 'Custom',
        [projectTypeIds.solar]: 'Solar',
        [projectTypeIds.battery]: 'Battery'
      }
    },
    team: {
      title: sharedText.adminTeam,
      description: 'Invite your team members.',
      tapRoleLabel:
        'Tap the role label to filter and see only members with that role.',
      roleDescription: (role: string) =>
        `Only showing team members with ${role} role. `,
      showAllRoles: 'show all',
      statusDescription: 'All team members who have not set up their account.',
      roleAndStatusDescription: (role: string) =>
        `All ${role} users who have not set up their account.`
    },
    teamMembers: {
      title: {
        add: sharedText.adminTeamAdd,
        edit: 'Edit member'
      },
      description:
        'Send an email invite to each team member to complete their account and get started.',
      firstName: sharedText.firstName,
      lastName: sharedText.lastName,
      phoneNumber: `${sharedText.phoneNumber} (optional)`,
      emailAddress: sharedText.emailAddress,
      role: 'Role',
      addAnother: 'Send and Invite Another',
      remove: sharedText.remove,
      submit: 'Send Invite',
      saveChanges: 'Save Changes'
    },
    teamMember: {
      edit: sharedText.edit,
      resendAccountSetup: 'Resend Invite',
      deleteLabel: 'Delete',
      roles: {
        ADMIN: 'Admin',
        OWNER: 'Owner',
        SALES: 'Rep',
        SALES_MGR: 'Sales Manager',
        OPERATIONS: 'Operations',
        PENDING: 'Waiting'
      }
    },
    bankInformation: {
      linked: 'Linked',
      title: sharedText.adminBankAccount,
      titleVerify: 'Verify account number',
      addBankInfo: 'Add bank information',
      titleEdit: 'Edit bank information',
      descriptionMain:
        'Add the account where you want funds deposited and fees charged.',
      description:
        'This is the account where all the funds will be deposited and fees will be charged.',
      descriptionInvalid:
        'Unfortunately we were not able to verify the account you were submitting. Please try again',
      descriptionVerify: (number: string) =>
        `Enter your current account number ending in ${number}. Note that all owners are notified about changes in bank information.`,
      descriptionNewAccount:
        'Enter your new account number. This is the account where all the funds will be deposited and fees will be charged.',
      routingNumber: sharedText.routingNumber,
      currentRoutingNumber: 'Current routing number',
      accountNumber: sharedText.accountNumber,
      currentAccountNumber: 'Current account number',
      editButton: 'Edit Bank Info',
      continueButton: sharedText.continue,
      bankAccountUpdatedSuccess: 'Bank account updated',
      bankAccountAddedSuccess: 'Bank account added',
      checkAndTryAgain: 'Please check what you’ve entered and try again.'
    },
    passwordReset: {
      title: 'Secure your Mosaic account by creating a unique password.',
      email: sharedText.emailAddress,
      createPassword: 'Create password',
      verifyPassword: 'Verify password',
      passwords: 'Passwords'
    },
    welcome: {
      title: 'Welcome to ',
      siteName: 'Portal 2X',
      body: 'The easiest way to make financing options available to homeowners.',
      emailNote:
        'We emailed you a link in case you choose to continue this later.'
    },
    errors: {
      required: sharedText.required,
      invalidEmail: 'Invalid email',
      tooShort: 'Too Short!',
      tooLong: 'Too Long!',
      phone: 'Phone should be a number',
      maxPhoneNumber: 'Maximum 10-digit Phone Number',
      minPhoneNumber: 'Minimum 10-digit Phone Number',
      membersWithErrors: 'There’s a problem with your submission. See below.',
      minimumLength: (fieldName: string, min: number) =>
        `${fieldName} have to have a minimum of ${min} characters`,
      passwordDoNotAllowBlankSpaces:
        'Please remove any white space from your password',
      passwordMatch: 'Passwords have to match',
      accountMatch: 'Account numbers have to match'
    },
    response: {
      success: 'Request has been sent',
      memberCreatedSuccessfully: 'Member has been created',
      memberUpdatedSuccessfully: 'Member has been updated'
    },
    roles: {
      sales: 'Rep',
      admin: 'Admin',
      owner: 'Owner',
      salesmgr: 'Sales_Mgr'
    },
    rolesHelperText: {
      sales: 'Create, complete and view their opportunities.',
      salesmgr: 'Create, complete and view their team’s opportunities.',
      admin: 'Manage teams, plans, and opportunities.',
      owner: 'Full access (including bank info).'
    },
    statusInformation: {
      applicationTitle: 'Partner application',
      applicationComplete: 'Submitted'
    },
    demoPaymentPlanOnly: 'Demo only',
    paymentFactor: 'Payment Factor',
    dealerFee: 'Dealer Fee',
    apr: 'APR',
    month: '-month',
    tos: {
      title: 'Terms of Service',
      subtitle: 'Please read and accept our Terms of Service before continuing',
      body: [
        'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
        'Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor ',
        'in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, ',
        'sunt in culpa qui officia deserunt mollit anim id est laborum.'
      ],
      checkbox1:
        'I verify that I have the ability to sign on behalf of the business',
      checkbox1Error: 'You have to be able to sing on behalf of the business',
      checkbox2: 'I have read and agreed to the Terms of Service',
      checkbox2Error: 'You have to agree to the Terms of Service',
      button: 'Accept',
      link: 'Agree to Terms of Service',
      ownerToAgree: 'Owner needs to agree to Terms of Service.'
    },
    finishedSettingUp: 'Finished setting up?',
    goToPortalLink: 'Go to Portal'
  }

  const resources = {
    title: 'Resources',
    lead: 'A collection of resources for training and selling.',
    swiftlink: {
      title: 'SwiftLink™',
      customLinkTitle: () => (
        <>
          Share this custom link with your customers to instantly pre-qualify
          them. Customers will be <b>assigned</b> to you.
        </>
      ),
      subtitle:
        'Share this link with your customers to instantly pre-qualify them.',
      customLinkSubtitle: () => (
        <>
          Or, share this link and the customer will <b>not be assigned</b> to
          you.
        </>
      ),
      customLinkDescription: () => (
        <>
          Everyone in your team with an ‘Admin’ role will automatically get an
          email notification about <span>unassigned</span> new opportunities
          coming from SwiftLink and can take steps to assign them to anyone in
          your team.
        </>
      ),
      description: () => (
        <>
          Everyone in your team with an ‘Admin’ role will automatically get an
          email notification about new opportunities coming from your SwiftLink.
          They’ll also see these new opportunities in their pipeline as{' '}
          <span>Unassigned </span>
          and can take steps to assign them to anyone in your team.
        </>
      ),
      portalPipeline: (portalLink: string) => (
        <>
          Once your customer submits an application using SwiftLink it will
          appear in your <Link href={portalLink}>portal pipeline</Link>.
        </>
      )
    }
  }

  const addressTypes = {
    primary: 'Primary residence',
    rental: 'Rental',
    secondHome: 'Second home'
  }

  const snackbarBranchTexts = {
    snackBarMessage:
      'We are temporarily unable to process new opportunities. Please contact your admin.',
    snackBarBranchMessage: (branches: Array) => {
      const invalidBranches = filter(branches, branch => !branch.isValid)
      const invalidBranchNames = map(invalidBranches, 'name').join(
        COMMA_SPACE_SEPARATOR
      )
      return `Team members in the branch '${invalidBranchNames}' are unable to process new opportunities until products are added to this branch in the portal by an admin.`
    },
    adminTextButton: 'Edit Branch',
    setUpTextButton: 'Go To Portal',
    phoneNumber: '510-344-8321'
  }

  const snackbarPSZeroTexts = {
    snackbarPSZeroMessage: (portalLink: string) => (
      <>
        You are logged in to an account offering PowerSwitch Zero. Login to{' '}
        <Link href={portalLink}>portal</Link> to submit applications, and
        provide estimates to your customers.
      </>
    )
  }

  const snackbarPrequalDisabledTexts = {
    message: 'Pre-qualification has been disabled.'
  }

  const contactCardLease = {
    supportEmail: 'leasesupport@joinmosaic.com',
    supportPhoneNumber: '(833) 497-0050'
  }

  const contactCardLoan = {
    supportEmail: 'support@joinmosaic.com',
    supportPhoneNumber: '(855) 746-5551'
  }

  const contactCardEmail = {
    emailText: 'Email us anytime:',
    supportEmail: 'support@joinmosaic.com',
    responseText: '90% answered within two business hours'
  }
  const contactCardTemplate = {
    type: 'default',
    titleMessage: 'Call us if you have immediate questions...',
    supportNumber: '1 (855)-746-5551',
    formattedSupportNumber: '18557465551',
    supportText: 'General Support',
    startTime: '07:00 am',
    endTime: '06:00 pm',
    satStartTime: '07:00 am',
    satEndTime: '06:00 pm',
    sunStartTime: null,
    sunEndTime: null,
    contactHours: 'Mon to Sat 7am – 6pm, Sun closed (PT)',
    ...contactCardEmail
  }
  const contactCardPrequalTemplate = {
    type: 'underwriting',
    titleMessage: 'Do you need help with your conditional pre-qualification?',
    supportNumber: '1 (855) 558-6007',
    formattedSupportNumber: '18555586007',
    supportText: 'Underwriting Support',
    startTime: '07:00 am',
    endTime: '06:00 pm',
    satStartTime: '07:00 am',
    satEndTime: '06:00 pm',
    sunStartTime: null,
    sunEndTime: null,
    contactHours: 'Mon to Sat 7am – 6pm, Sun closed (PT)',
    ...contactCardEmail
  }

  const contactCard = {
    help: 'Help',
    supportAreas: {
      timezonePT: 'America/Los_Angeles'
    },
    availabilityText: {
      availableText: 'Available Now',
      notavailableText: 'Currently unavailable'
    },
    supportTypes: contactCardSupportTypes,
    DefaultSupport: { ...contactCardTemplate },
    GeneralSupportContractor: { ...contactCardTemplate, type: 'general' },
    GeneralSupportCustomer: { ...contactCardTemplate, type: 'general' },
    UnderwritingSupportContractor: { ...contactCardPrequalTemplate },
    UnderwritingSupportCustomer: { ...contactCardPrequalTemplate },
    LoanAndPaymentSupport: {
      ...contactCardTemplate,
      type: 'loanpayment',
      supportText: 'Loan & Payment Support',
      startTime: '07:00 am',
      endTime: '06:00 pm',
      friStartTime: '07:00 am',
      friEndTime: '06:00 pm',
      satStartTime: '07:00 am',
      satEndTime: '06:00 pm',
      sunStartTime: null,
      sunEndTime: null,
      contactHours: 'Mon to Sat 7am – 6pm, Sun closed (PT)'
    },
    WelcomeCallSupport: {
      type: 'welcome',
      titleMessage: 'To start a Welcome Call please call...',
      supportNumber: '1 (855)-587-2499',
      formattedSupportNumber: '18555872499',
      supportText: 'Welcome Call Support',
      startTime: '07:00 am',
      endTime: '06:00 pm',
      satStartTime: '07:00 am',
      satEndTime: '06:00 pm',
      sunStartTime: null,
      sunEndTime: null,
      contactHours: 'Mon to Sat 7am – 6pm, Sun closed (PT)',
      ...contactCardEmail
    }
  }

  const signedDocument = {
    error: {
      missingDocument: (documentType: string) =>
        `No document of type ${documentType} was found`
    },
    download: 'Download'
  }

  const foundation = {
    disclosure: (containerClass: string, textClass: string) => (
      <div className={containerClass}>
        <div className={textClass}>
          <span>
            {`To save you time, we can pass along to Foundation Finance Company
            (“Foundation”) information you submitted as part of the pre-approval request you
            submitted through Mosaic. By clicking “Yes” below, you authorize Mosaic to share with
            Foundation, for purposes of applying for financing through them, any information you
            provided in your pre-approval submission or application, that was not approved for a
            loan, as well as information from certain third parties from whom we receive information
            about your mobile device.`}
          </span>
          <br />
          <br />
          <span>
            {`You understand that Foundation is not affiliated with and is not an agent, employee,
            vendor, or representative of Mosaic or any lender through the Mosaic platform.`}
          </span>
        </div>
      </div>
    ),
    title: 'Let’s try another route?',
    disclosures: sharedText.disclosures,
    paragraph:
      'Unfortunately, we’re unable to approve your credit application (we’ll send details by mail soon), but we have another financing option to try. We just need your permission to proceed.',
    submit: 'YES TRY ANOTHER',
    checkboxAgreement: 'By checking this box, you consent to this sharing.',
    successMessage: 'Successfully accepted.',
    applicationContent: {
      projectAddressTitle: sharedText.projectAddress,
      primaryAddressTitle: 'Primary Residence',
      mailingAddressTitle: 'Mailing Address',
      title: 'Additional information',
      paragraph:
        'Mosaic will collect the information and submit it to Foundation Finance Company to complete your request. Please complete the following information for:',
      aboutYouLabel: 'About you:',
      driverLicenseLabel: 'Driver license number',
      inValidInput: 'Invalid Input',
      driverLicenseState: 'Driver license state',
      applicantState: 'State',
      applicantEmailLabel: sharedText.emailAddress,
      dob: sharedText.dob,
      requestedLoanTermsDefault: 120,
      employementStatusPlaceholder: 'FULL_TIME',
      loanProductPlaceHolder: 'CLOSED_END_INSTALLMENT',
      loadProductTypePlaceholder: 'BATHROOM_REMODEL',
      residenceTypePlaceholder: 'RENT',
      ssnLabel: 'Social Security Number',
      fieldRequiredLabel: 'this field is required',
      applicantMobileNumberLabel: 'Mobile number',
      applicantAlternateNumberLabel: 'Alternate phone number',
      whereYouLiveLabel: 'Where you live:',
      residenceTypeLabel: 'Residence type',
      monthsAtPrimaryResidenceLabel: 'Months at primary residence',
      monthlyPaymentLabel: 'Monthly housing payment',
      whereYouWorkLabel: 'Where you work:',
      applicantPrimaryAddressLabel: 'Primary Residence',
      applicantMailingAddressLabel: 'Mailing Address',
      applicantProjectAddressLabel: sharedText.projectAddress,
      applicantStreetLabel: sharedText.street,
      applicantCityLabel: 'City',
      applicantZipCodeLabel: 'ZIP code',
      employmentStatusLabel: 'Employment status',
      employerNameLabel: 'Employer name',
      employerPhoneNumberLabel: 'Applicant work phone',
      coAppEmployerPhoneNumberLabel: 'Co-Applicant work phone',
      appPeriodOfEmploymentMonths: 'Months working for employer',
      monthlyIncomeLabel: 'Monthly income',
      occupationTitleLabel: 'Occupation / Job title',
      yourLoanLabel: 'Your loan:',
      loanOptionLabel: 'Loan option',
      loanProductTypeLabel: 'Loan product type',
      totalFinancingAmountLabel: 'Total financing amount requested',
      requestedLoanTermLabel: 'Requested loan term (months)',
      downpaymentAmountLabel: 'Down payment amount',
      yourAdditionalIncomeLabel: 'Your additional income:',
      otherIncomeSourceLabel: 'Other income source',
      coApplicantLabel: 'Your co-applicant',
      removeButtonLabel: 'REMOVE CO-APPLICANT',
      addButtonLabel: 'ADD CO-APPLICANT',
      coApplicantFirstNameLabel: sharedText.firstName,
      coApplicantLastNameLabel: sharedText.lastName,
      coApplicantMobileNumberLabel: sharedText.phoneNumber,
      coApplicantAlternateNumberLabel: 'Alternate number',
      coApplicantEmailLabel: sharedText.emailAddress,
      coApplicantDOBLabel: 'Date of birth (mm/dd/yyyy)',
      coApplicantSSNLabel: 'Social Security Number',
      coApplicantWorkLabel: 'Where co-applicant works:',
      coApplicantLivesLabel: 'Where co-applicant lives:',
      coApplicantPrimaryAddressLabel: 'Primary Residence',
      coApplicantresidenceType: 'Residence type',
      coAppPeriodOfEmploymentMonths: 'Months working for employer',
      coAppEmployerNameLabel: 'Employer Name',
      coApplicantMonthsAtResidence: 'Months at primary residence',
      coApplicantMonthlyHousingPayment: 'Monthly housing payment',
      coApplicantAdditionalIncome: 'Additional income of co-applicant',
      formErrorMessage: 'Please fill out all the required fields',
      errorMessages: {
        invalidDriversLicense: 'Drivers License is not valid',
        invalidAmount: 'Amount not valid',
        invalidMonth: 'Months not valid',
        invalidName: 'Invalid Name',
        invalidEmployer: 'Invalid Employer',
        invalidJobtitle: 'Invalid Job Title',
        invalidEmail: 'Email is not valid',
        invalidSSN: 'SSN is not valid',
        invalidPhone: 'Phone number is not valid',
        invalidStreet: 'Invalid Address',
        invalidCity: 'Invalid City',
        invalidZipcode: 'Invalid ZipCode',
        required: sharedText.required.toLowerCase(),
        applicantDriversLicenseRegex: 'matchRegexp:^[0-9a-zA-Z]{4,15}$',
        alternatePhoneRegex: 'matchRegexp:^[0-9]{10}$',
        applicantDownPaymentRegex: 'matchRegexp:^[0-9]*$',
        applicantMonthsAtPrimaryAddressRegex: 'matchRegexp:^[0-9]*$',
        applicantMonthlyPaymentRegex: 'matchRegexp:^[0-9]*$',
        applicantStreetRegex: 'matchRegexp:^[0-9a-zA-Z]',
        applicantCityRegex: 'matchRegexp:^[a-zA-Z]',
        applicantZipRegex: 'matchRegexp:^[0-9]{5}$',
        employerNameRegex: 'matchRegexp:(^[a-zA-Z0-9_.\\- ]{1,64})+$',
        employerPhoneRegex: 'matchRegexp:^[0-9]{10}$',
        applicantMonthlyIncomeRegex: 'matchRegexp:^[0-9]*$',
        applicantJobTitleRegex: 'matchRegexp:^[a-zA-Z]{3,64}',
        employedMonthsRegex: 'matchRegexp:^[0-9]*$',
        financingAmountRegex: 'matchRegexp:^[0-9]*$',
        applicantdownpaymentAmountRegex: 'matchRegexp:^[0-9]*$',
        coAppNameRegex: 'matchRegexp:^[a-zA-Z]',
        coAppDriversLicenseRegex: 'matchRegexp:^[0-9a-zA-Z]{4,15}$',
        textValidatorEmailCheck: 'isEmail',
        coAppSSNValidation: 'matchRegexp:^[0-9]{9}$',
        coAppPhoneRegex: 'matchRegexp:^[0-9]{10}$',
        coAppStreetRegex: 'matchRegexp:^[0-9a-zA-Z]',
        coAppCityRegex: 'matchRegexp:^[a-zA-Z]',
        coAppZipRegex: 'matchRegexp:^[0-9]{5}$',
        coAppMonthsAtResidenceRegex: 'matchRegexp:^[0-9]',
        coAppAddressRegex: 'matchRegexp:^[0-9]*$',
        coAppEmployerNameRegex: 'matchRegexp:(^[a-zA-Z0-9_.\\- ]{1,64})+$',
        coAppMonthlyIncomeRegex: 'matchRegexp:^[0-9]*$',
        coAppJobTitleRegex: 'matchRegexp:^[a-zA-Z]{3,64}',
        coAppEmploymentPeriodRegex: 'matchRegexp:^[0-9]*$'
      },
      fieldNames: {
        driversLicense: 'driversLicense',
        applicantDrState: 'applicantDrState',
        email: 'email',
        dob: 'dob',
        ssn: 'ssn',
        applicantMobilePhone: 'applicantMobilePhone',
        applicantAlternatePhone: 'applicantAlternatePhone',
        applicantDownpaymentAmount: 'applicantDownpaymentAmount',
        applicantAddress: 'applicantAddress',
        monthsAtPrimaryAddress: 'monthsAtPrimaryAddress',
        monthlyPaymentAtPrimary: 'monthlyPaymentAtPrimary',
        appResidenceType: 'appResidenceType',
        applicantStreet: 'applicantStreet',
        applicantCity: 'applicantCity',
        applicantState: 'applicantState',
        applicantZip: 'applicantZip',
        applicantEmploymentStatus: 'applicantEmploymentStatus',
        applicantEmployerName: 'applicantEmployerName',
        applicantEmployerPhone: 'applicantEmployerPhone',
        applicantMonthlyIncome: 'applicantMonthlyIncome',
        applicantJobTitle: 'applicantJobTitle',
        periodOfEmploymentMonths: 'periodOfEmploymentMonths',
        applicantFinancingAmount: 'applicantFinancingAmount',
        applicantLoanOption: 'applicantLoanOption',
        applicantLoanProductType: 'applicantLoanProductType',
        coAppFirstName: 'coAppFirstName',
        coAppLastName: 'coAppLastName',
        coAppDriversLicense: 'coAppDriversLicense',
        coApplicantDrState: 'coApplicantDrState',
        coAppEmail: 'coAppEmail',
        coAppDob: 'coAppDob',
        coAppssn: 'coAppssn',
        coAppPhoneNumber: 'coAppPhoneNumber',
        coAppAlternatePhone: 'coAppAlternatePhone',
        coApplicantStreet: 'coApplicantStreet',
        coApplicantCity: 'coApplicantCity',
        coApplicantState: 'coApplicantState',
        coApplicantZip: 'coApplicantZip',
        coAppResidenceType: 'coAppResidenceType',
        coAppMonthsAtResidence: 'coAppMonthsAtResidence',
        coAppMonthlyPayment: 'coAppMonthlyPayment',
        coAppEmploymentStatus: 'coAppEmploymentStatus',
        coAppEmployerName: 'coAppEmployerName',
        coAppEmployerPhoneNumber: 'coAppEmployerPhoneNumber',
        coAppMonthlyIncome: 'coAppMonthlyIncome',
        coAppJobTitle: 'coAppJobTitle',
        coAppPeriodOfEmploymentMonths: 'coAppPeriodOfEmploymentMonths'
      },
      disclosure: (containerClass: string, textClass: string) => (
        <div className={containerClass}>
          <div className={textClass}>
            <span>
              <span>
                {`By clicking Continue, you promise all information is true and complete. You intend Foundation and any assignee to rely
              upon this information in deciding whether or not to extend credit to you. You authorize a full investigation and release
              of your credit record and your employment history. You also authorize Foundation and any assignee to release
              information about credit experience with them. You consent to receiving autodialed or prerecorded message calls
              from us or those acting on our behalf at any mobile telephone number you provide to us.`}
              </span>
              <br /> <br />
              <span>
                {`CREDIT REPORT NOTICE: We may request a credit report for any
              legitimate purpose associated with your application for credit,
              extending credit, modifying the terms of your credit agreement, or
              collection on your account. On your request, we will inform you if
              such a report was ordered and will give you the name and address
              of the credit reporting agency that furnished the report.`}
              </span>
              <br /> <br />
              <span>
                {`ALABAMA AND NEBRASKA RESIDENTS: You must be 19 years old to apply
              for credit.`}
              </span>
              <br /> <br />
              <span>
                {`CALIFORNIA RESIDENTS: If married, you may apply for a
              separate account.`}
              </span>
              <br /> <br />
              <span>
                {`NEW YORK RESIDENTS: We may obtain your credit
              report and we will inform you, upon request, whether a report was
              obtained and, if so, the name and address of the consumer reporting
              agency.`}
              </span>
              <br /> <br />
              <span>
                {`OHIO RESIDENTS: The Ohio laws against discrimination require
              that all creditors make credit equally available to all credit
              worthy customers, and that credit reporting agencies maintain
              separate credit histories on each individual upon request. The Ohio
              civil rights commission administers compliance with this law.`}
              </span>
              <br /> <br />
              <span>
                {`VERMONT RESIDENTS: We may obtain your credit reports at any time,
              for any legitimate purpose associated with the account or the
              application or request for an account, including but not limited to
              reviewing, modifying, renewing and collecting on your account. On
              your request we will inform you if such a report was ordered. If so,
              we will give you the name and address of the consumer reporting
              agency furnishing the report.`}
              </span>
              <br /> <br />
              <span>
                {`WISCONSIN NOTICE TO MARRIED APPLICANTS: No provision of any marital
              property agreement, unilateral statement under Wis. Stats. §766.59,
              or court decisions under Wis. Stats. §766.70 adversely affects the
              interest of the creditor unless the creditor is furnished a copy of
              the decree, agreement, statement, or has actual knowledge of the
              adverse provision prior to the time credit is granted.`}
              </span>
              <br /> <br />
              <span>
                {
                  'IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT'
                }
              </span>
              <br /> <br />
              <span>
                {`To help the government fight the funding of terrorism and money laundering activities, Federal law requires all
              financial institutions to obtain, verify, and record information that identifies each person who opens an account. What
              this means to you: When you open an account, we will ask for your name, address, date of birth, and other
              information that will allow us to identify you. We may also ask to see your driver’s license or other identifying
              documents.`}
              </span>
            </span>
          </div>
        </div>
      ),
      disclosures: sharedText.disclosures,
      checkboxAgreement: 'I agree to the above disclosures.',
      acknowledgementText:
        'By selecting "Continue", you acknowledge that you are submitting an application for credit to Foundation Finance Company.',
      cancel: sharedText.cancel,
      continue: sharedText.continue
    },
    decisionScreen: {
      processing: sharedText.processing,
      titleApproved: 'You’ve been approved',
      titleDenied: 'We were unable to approve your application',
      titlePending: 'Your application is pending',
      titleError: 'We’ve encountered an error',
      subTitle: (decision: string) => decisionScreenSubTitle(decision),
      disclaimerInstaller: (
        <>
          <b> Dealer note: </b>
          please log in to your Foundation Finance Company dealer portal and
          generate loan documents. By Choosing FOUNDATION DEALER PORTAL, you
          will automatically be redirected.
        </>
      ),
      linkButtonText: 'FOUNDATION DEALER PORTAL',
      buttonText: 'BACK TO PIPELINE',
      processingMessage: 'This should only take few seconds...'
    }
  }

  const onboardingSteps = {
    logoAlt: 'Mosaic Logo',
    step0: {
      title: 'Who is Mosaic?',
      imageDescriptions: [
        'Close more deals by offering financing through Mosaic*',
        'Grow your business',
        'Simple, easy process',
        'Instant prequalification with soft credit check'
      ],
      footerText:
        '*All loans originated by WebBank, Member FDIC, Equal Housing Lender.'
    },
    step1: {
      title: 'How do I use this app?',
      header:
        'Check out this quick video on how the Mosaic app can supercharge your sales!',
      videoTitle: 'Mosaic Onboarding Video'
    },
    step2: {
      title: 'How do I get help?',
      primaryContact: 'Primary Contact',
      secondaryContact: 'Secondary Contact'
    },
    step3: {
      title: 'Add this app to my home screen',
      Text1MobileLink: ({ os }) => (
        <span>
          {`Looks like you’re using an ${
            os === MOBILE_DETECT_OS.ios ? 'Apple' : 'Android'
          } device. In ${
            os === MOBILE_DETECT_OS.ios ? 'Safari' : 'Chrome'
          }, visit `}
          <a href="https://tap.joinmosaic.com/">tap.joinmosaic.com</a>.
        </span>
      ),
      text2:
        'You will see the Mosaic login page. To add the app to your home screen, tap this icon and choose “Add to Home Screen.”',
      text3: 'And... done! Look for the Mosaic app on your home screen.'
    }
  }

  const unsupportedBrowser = {
    title: 'Oops! Your browser is out of date.',
    description: ({ unsupportedBrowsersList, classes }) => {
      return (
        <>
          {
            'Looks like you’re using a web browser that’s not compatible with Mosaic. We recommend using the latest versions of '
          }
          {unsupportedBrowsersList.map((browserItem, key) => (
            <LinkLabelBrowser
              key={key}
              classes={classes}
              browserItem={browserItem}
              position={key}
              lastPosition={unsupportedBrowsersList.length - 1}
            />
          ))}
          {
            ' for instructions on how to safely download and install those browsers, choose one fo the icons below.'
          }
        </>
      )
    },
    body1: 'You may use any of the following supported browsers:',
    body2: 'Please ensure JavaScript and cookies are enabled.',
    supportedBrowsers: [
      'Safari™ 11 or higher (macOS or iOS)',
      'Google® Chrome™ 71 or higher (Windows, macOS, Android or iOS)',
      'Mozilla Firefox 47 or higher (Windows, Android or macOS)',
      'Firefox 18 or higher (iOS)',
      'Edge 42® or higher (Windows or Android)',
      'Opera 57 or higher (Windows)',
      'Opera Touch 1.10 or higher (iOS or Android)',
      'Samsung internet browser (Android)'
    ],
    disclaimer:
      'Linked third-party sites are neither operated, nor affiliated with Mosaic. Our terms and conditions, privacy and security policies don’t apply to the site you’re about to enter, so please review these items on the third-party site. Mosaic is not responsible for and does not control, endorse, or monitor content or offerings on third-party sites.'
  }

  const dashboard = {
    documentAlert: {
      textByType: {
        notUploaded: {
          text: {
            [userTypes.installer]:
              'Your selected project(s) require a Sales Agreement to be uploaded before proceeding. ',
            [userTypes.borrower]:
              'Solar projects require the installer to upload the Sales Agreement before your projects can be completed.'
          },
          textButton: 'Upload now?'
        },
        rejected: {
          text: {
            [userTypes.installer]:
              'Your selected project(s) require a Sales Agreement to be uploaded before proceeding. ',
            [userTypes.borrower]:
              'The Sales Agreement review is on hold. The installer may not proceed until the proper document is provided.'
          },
          textButton: 'Redo upload?'
        },
        pending: {
          text: {
            [userTypes.installer]:
              'The Sales Agreement is pending review. Once it is approved, you can proceed with the project(s). ',
            [userTypes.borrower]:
              'The Sales Agreement is pending review. Once it is approved, your project(s) can be completed.'
          },
          textButton: 'Redo upload'
        },
        accepted: {
          text: {
            [userTypes.installer]:
              'The Sales Agreement has been accepted. You can now proceed with the project(s). ',
            [userTypes.borrower]:
              'The Sales Agreement has been accepted. Your project(s) can now be completed.'
          },
          textButton: 'Redo upload'
        }
      }
    },
    projectStatus: {
      refreshButton: sharedText.refresh
    },
    projectDetail: {
      redoUpload: 'Redo upload?',
      projectNamePrefix: 'Project:',
      identifier: 'Project ID',
      type: sharedText.projectType,
      status: sharedText.status,
      projectApproved: 'Approved',
      statusText: (projectStatus: string) =>
        projectStatusesTitles[projectStatus],
      cost: sharedText.projectCost,
      notes: 'Project description',
      confirmCompletion: 'Confirm Completion',
      confirmCompletionHelper: 'Confirm it yourself',
      requestConfirmation: 'Request Confirmation',
      requestConfirmationHelper: 'Request confirmation from customer',
      queued: 'Confirmation Request Sent to Customer',
      sendReminder: 'Send Reminder',
      maintenancePlans: 'Maintenance plans',
      confirmProject:
        'Confirm when a project is complete to disburse funds and update status.',
      confirmProjectStage:
        'Confirm when a project stage is complete to disburse funds and update status.',
      completeWithAllStages: 'This project and all its stages are complete.',
      uploadCompletionCertificate: 'Upload a completion certificate',
      genericUploadTextHelper: 'Select and upload file',
      replaceCertificateBtn: 'Replace certificate?',
      replaceUploadedCertificateLink: 'Replace uploaded certificate?',
      completedProject: 'This project and all its stages are complete.',
      fileFormatErrorMessage:
        'Your file must be a PDF or image smaller than 100 MB. Please try a different file.',
      projectConfirmed: 'Project confirmed',
      stageConfirmed: 'Stage confirmed',
      projectComplete: 'Project complete',
      projectPending: 'Project complete pending',
      decisionModal: {
        title: 'Project completion',
        subtitle: 'There are two ways to confirm this project stage:',
        requestConfirmationHelpertext: 'Ask the customer to confirm',
        confirmationRequestSent: 'Confirmation request sent to customer',
        uploadCertificateButtonLabel: 'Upload Certificate'
      },
      stageSection: {
        stage: 'Stage',
        confirmCompletion: 'Confirm Completion',
        waitingConfirmation: 'Waiting for Confirmation',
        confirmed: 'Confirmed',
        complete: sharedText.completed,
        document: 'Document',
        incomplete: sharedText.incomplete
      },
      borrowerConfirmation: {
        title: 'Are you sure?',
        Content: ({
          stageName,
          stageCost,
          partnerName
        }: {
          stageName: string,
          stageCost: Int,
          partnerName: string
        }) => (
          <>
            Confirming this {stageName} stage as completed will disburse{' '}
            <NumberFormat
              displayType="text"
              thousandSeparator
              fixedDecimalScale
              decimalScale={DOLLAR_DECIMAL_SCALE}
              prefix={DOLLAR_PREFIX}
              renderText={text => <SuperScriptAmount price={text} />}
              value={stageCost}
            />{' '}
            to {partnerName}. Would you like to proceed?
          </>
        ),
        actions: {
          back: 'Back',
          proceed: 'Yes, Proceed'
        }
      }
    },
    documentRow: {
      completed: sharedText.completed.toLowerCase(),
      incomplete: sharedText.incomplete.toLowerCase(),
      completedDateLabel: 'Uploaded',
      key: 'document',
      name: {
        salesAgreement: 'Sales Agreement'
      },
      pending: 'pending review',
      redoUpload: 'Redo upload',
      requiredForProjectType: (projectTypeLabel: string) =>
        `Required for ${projectTypeLabel} projects`,
      uploadNow: 'Upload Now'
    }
  }

  const genericUploader = {
    uploadTextHelper: 'Select and upload file',
    successBadgeCaption: 'Upload is complete.',
    successBadgeTitle: sharedText.done,
    file: 'File',
    chooseFile: 'Choose File',
    chooseDifferentFile: 'Choose Different File',
    invalidFileType:
      'You are about to drop an invalid file. It will be rejected.',
    maxSizeLabel: (maxSize: number) =>
      `Max size ${maxSize / Math.pow(10, 6)} MB.`
  }

  const partnerDocumentUploadDialog = {
    title: 'Document upload',
    body: 'Please provide a copy of',
    fileFormatErrorMessage:
      'Your file must be in PDF, Word, Excel, or CSV format and be smaller than 100 MB. Please try a different file.',
    supportedFileTypes: 'PDF, Word Doc, Excel, and CSV'
  }

  const prequalificationForm = {
    title: 'Pre-qualification',
    initialHelpText:
      'Please provide a valid email address you can access and a mobile phone number to receive instant notifications about your project and keep it running smoothly.',
    thanksContinueBelow:
      'Thanks! Please continue below. We also sent you a link in case you need to finish this later.',
    pleaseTellUsAboutYourself:
      'Tell us a bit more about yourself. Don’t worry, this pre-qualification will not make a hard inquiry on your credit.',
    assignToHelpText:
      'The email address of the sales rep who referred you to Mosaic. Leave it blank if none.',
    submit: sharedText.submit,
    agreeAndContinue: 'Agree & Continue',
    error: 'There was a server error. Please try again at another time.',
    captchaError:
      'The captcha token sent is invalid. Please refresh the page and try again.',
    checkAndTryAgain: 'Please check what you’ve entered and try again.',
    firstNameHelperText: 'Your legal name, not a nickname.',
    incomePlaceholder: '$0',
    ssnPlaceholder: '000-00-0000',
    addressBody1:
      'Tell us where this project will be located. You can also provide a primary address and a mailing address if this project will not be at your primary address.',
    creditScoreNotice:
      'Submitting this pre-qualification will not affect your credit score.',
    informationIsSecured: 'Your information is secured by',
    SSL: 'SSL',
    bitEncrypted: '128-Bit Encryption',
    verified: {
      title: 'Phone number verified',
      subtitle: 'You can continue completing the form.',
      button: sharedText.continue
    },
    prequalifyExplanation: (renovatorName: string) =>
      `See if you qualify for financing through Mosaic for your home improvement project. After which, one of our ${renovatorName} representatives will reach out and discuss your project with you.`,
    welcome: (renovatorName: string) => `Welcome to ${renovatorName}`,
    thirdPartyCookiesDisabledError: `It looks like you are running in Incognito Mode, or haven't allowed
      third-party cookies. Because of this, we aren't able to pre-fill your application, but you
      can continue filling it out here.`,
    phoneNumberIsLandlineError:
      'Cannot send an SMS to a landline. Please provide a mobile phone number.',
    phoneNumberIsLandlineCustomer:
      'The submitted number is not a valid mobile phone number.',
    phoneNumberError: 'Valid mobile phone number required',
    creditApplicationExpiredError: {
      title: 'Application Expired',
      description:
        'This application for financing has timed out. Please have the borrower open a new application.'
    },
    handDeviceToCustomer: {
      title: 'Please hand the screen over to the customer.',
      goBack: sharedText.goBack,
      continue: 'Yes, I am the customer'
    },
    refreshLabel: 'Refresh',
    notLiveYetDialog: {
      title: 'Oops, this is not live yet.',
      body: (partnerName: string) =>
        `Once ${partnerName} is up and running you will be able to submit your credit application here to pre-qualify for financing through Mosaic.`
    },
    jumpToErrors: 'Jump to errors',
    FormErrorsText: ({ classes, children }) => (
      <Typography
        variant="body2"
        component="span"
        className={classes.errorText}
      >
        There are some errors in the form above. {children}
      </Typography>
    )
  }

  const screenTitles = {
    default: 'Mosaic',
    start: sharedText.start,
    pipeline: 'Pipeline',
    setup: 'Setup',
    apply: 'Apply',
    estimate: 'Estimate',
    reviewAndAccept: sharedText.reviewAndAccept,
    customerInfo: sharedText.customerInfo,
    summary: sharedText.summary,
    setupAutopay: sharedText.setupAutopay,
    dashboard: 'Dashboard',
    projectDetail: 'Project detail',
    applicationFormStatus: 'Application status',
    prequalificationForm: 'Pre-qualification',
    signed: sharedText.signedTitle,
    contract: 'Contract',
    reviewAutopay: sharedText.reviewAutopay,
    resources: sharedText.resources,
    swiftlink: sharedText.swiftlink,
    welcome: sharedText.welcome,
    adminTeam: sharedText.adminTeam,
    adminTeamAdd: sharedText.adminTeamAdd,
    adminAccount: sharedText.adminAccount,
    adminProducts: sharedText.adminProducts,
    adminProjectTypes: sharedText.projectTypes,
    adminBankAccount: sharedText.adminBankAccount
  }

  return {
    languageKey,
    time,
    quote,
    apply,
    pipeline,
    monthNames,
    customer,
    stateMenu,
    shared,
    start,
    formFields,
    layout,
    login,
    addToHomescreen,
    opportunityStatusTitles,
    projectStatusesTitles,
    setup,
    sidebarNav,
    contractFrame,
    projectStatus,
    common,
    installComplete,
    notFound,
    serverError,
    serviceWorkerMessages,
    contract,
    themeSwitcher,
    banner,
    mosaicAddress,
    admin,
    demoModeAccessRevoked,
    addressTypes,
    snackbarBranchTexts,
    snackbarPSZeroTexts,
    snackbarPrequalDisabledTexts,
    foundation,
    contactCard,
    contactCardLease,
    contactCardLoan,
    expired,
    forgotPassword,
    signedDocument,
    confirmComplete,
    onboardingSteps,
    unsupportedBrowser,
    dashboard,
    fileUpload,
    genericUploader,
    prequalificationForm,
    resources,
    partnerDocumentUploadDialog,
    sharedTexts: sharedText,
    screenTitles
  }
}
