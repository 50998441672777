import { gql } from 'graphql.macro'

export default gql`
  mutation sendShareLink(
    $customerId: String!
    $sendVia: LinkShareSendViaTypes!
  ) {
    sendShareLink(customerId: $customerId, sendVia: $sendVia)
  }
`
