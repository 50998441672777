// @flow
import get from 'lodash/get'
import featureFlags from 'constants/enums/featureFlags'
import customerLoanTypes from 'constants/enums/customerLoanTypes'

const featureFlagEnabled =
  (featureFlag: string): Function =>
  (ldFlags: Object): boolean =>
    ldFlags && ldFlags[featureFlag]

export const fullHostnameOnAccessTokenCookieEnabled = featureFlagEnabled(
  featureFlags.useFullHostnameOnAccessTokenCookie
)

export const updatePaymentPlansLdFlagEnabled = featureFlagEnabled(
  featureFlags.updatePaymentPlans
)

export const documentTaskStatusLdFlagEnabled = featureFlagEnabled(
  featureFlags.documentTaskStatus
)

export const removeSaveButtonFromEditCustomerInfoFlagEnabled =
  featureFlagEnabled(featureFlags.removeSaveButtonFromEditCustomerInfo)

export const editCustomerFlag = featureFlagEnabled(
  featureFlags.editCustomerFlag
)

export const s3LinearProgressFlag = featureFlagEnabled(
  featureFlags.s3LinearProgressFlag
)

export const allowSolarCustomersInPipelineFlag = featureFlagEnabled(
  featureFlags.allowSolarCustomersInPipeline
)

export const emailAddressVerificationFlagEnabled = featureFlagEnabled(
  featureFlags.emailAddressVerification
)

export const singleDobFieldFlagEnabled = featureFlagEnabled(
  featureFlags.singleDobField
)

export const creditExpirationAlertsFlagEnabled = featureFlagEnabled(
  featureFlags.creditExpirationAlerts
)

export const loanCancellationAlertsFlagEnabled = featureFlagEnabled(
  featureFlags.loanCancellationAlerts
)

export const integratorDocusignFlagEnabled = featureFlagEnabled(
  featureFlags.integratorDocusign
)

export const landlineNumberEnabled = featureFlagEnabled(
  featureFlags.landlineNumberEnabled
)

export const getGooglePlacesToken = featureFlagEnabled(
  featureFlags.googlePlacesToken
)

export const moveAchBeforeLoanAgreementFlagEnabled = featureFlagEnabled(
  featureFlags.moveAchBeforeLoanAgreement
)

export const connexusMembershipFlagEnabled = featureFlagEnabled(
  featureFlags.connexusMembership
)

export const deprecateVerifyAndAuthenticateFlagEnabled = featureFlagEnabled(
  featureFlags.deprecateVerifyAndAuthenticate
)

export const borrowerDashboardInstallConfirmationFlagEnabled =
  featureFlagEnabled(featureFlags.borrowerDashboardInstallConfirmation)

export const dashboardHubAndSpokeFlagEnabled = featureFlagEnabled(
  featureFlags.dashboardHubAndSpoke
)

export const lastFourSSNFlagEnabled = featureFlagEnabled(
  featureFlags.lastFourSSN
)

export const lastFourSSNLeaseFlagEnabled = featureFlagEnabled(
  featureFlags.lastFourSSNLease
)

export const handToCustomerDialogFlagEnabled = featureFlagEnabled(
  featureFlags.handToCustomerModal
)

export const solarIntegratorPrequalificationEnabled = featureFlagEnabled(
  featureFlags.solarIntegratorPrequalification
)

export const clearScreenNameInIframeFlagEnabled = featureFlagEnabled(
  featureFlags.clearScreenNameInIframe
)

// TX-393
export const coApplicantFormEnabled = (ldFlags, additionalProps = {}) => {
  // NOTE: Per BE team, we will never have a defaultLoanType of solar under current configurations.
  //
  // const defaultLoanType = get(
  //   additionalProps,
  //   'channelPartner.projectConfiguration.defaultLoanType'
  // )
  const customerLoanType = get(additionalProps, 'customer.loanType')

  return (
    customerLoanType === customerLoanTypes.solar // ||
    // defaultLoanType === customerLoanTypes.solar
  )
}

// AEA-128
export const radioButtonForPropertyOwnership = featureFlagEnabled(
  featureFlags.radioButtonForPropertyOwnership
)

// AEA-211
export const ssnMismatchRequiresBothApplicants = featureFlagEnabled(
  featureFlags.ssnMismatchRequiresBothApplicants
)

// AEA-215
export const householdIncomeOnHomeP2XApplications = featureFlagEnabled(
  featureFlags.householdIncomeOnHomeP2XApplications
)

// AOS-6
export const setupAutopay = featureFlagEnabled(featureFlags.setupAutopay)

export const clearSessionOnAnyRefreshToken = featureFlagEnabled(
  featureFlags.clearSessionOnAnyRefreshToken
)

//Cookie Prefix Enabled
export const cookiePrefixEnabled = featureFlagEnabled(
  featureFlags.cookiePrefixEnabled
)

export const fullStoryEnabled = featureFlagEnabled(featureFlags.enableFullStory)
