import { gql } from 'graphql.macro'

export default gql`
  query getValidatedAddresses($addresses: CustomerAddressesRequest!) {
    validateAddresses(addresses: $addresses) {
      street
      city
      stateAbbreviation
      zip
      addressType
      errors
      __typename
    }
  }
`
