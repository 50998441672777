// @flow

export const financingOptionsEnum = Object.freeze({
  servicePlan: 'SERVICE_PLAN',
  batteryOnly: 'BATTERY_ONLY'
})

export type FinancingOptions = $Values<typeof financingOptionsEnum>

export default financingOptionsEnum
