import React from 'react'

export const enUS = {
  greetings: {
    hello: 'Hello'
  },
  applicant: {
    applicantInfo: {
      title: 'Applicant info',
      loan: {
        title: 'Applicant',
        applicant1Body: 'Tell us a bit more about yourself.',
        applicant2Body:
          'Adding a co-applicant will increase the likelihood of getting pre-qualified. Both applicants must sign an agreement.',
        projectAddressResident:
          'Can you verify you are a resident at the project address?',
        primaryAddress:
          'If you do not live at the project address, what’s your primary address?',
        lastFourSsn: 'Last 4 of Social Security Number',
        ownershipAndResidence: 'Ownership & Residence'
      }
    },
    validationErrors: {
      emailMustBeValid: 'Must be a valid email address.',
      emailInstaller: 'Please enter your customer’s email address',
      phoneNumberIsInvalid:
        'Invalid phone number. Please enter a valid mobile phone number to proceed.',
      phoneNumberIsOptedOut:
        'This phone number has opted out of receiving text messages.',
      sameApplicant: 'Names and SSN cannot match for both co-applicants'
    },
    firstName: 'First name',
    firstNameDescription: 'Your legal name, not a nickname',
    lastName: 'Last name',
    middleName: 'Middle name (optional)',
    email: 'Email',
    mobilePhone: 'Mobile phone',
    alternatePhone: 'Alternate phone (optional)',
    alternatePhoneNumberHelperText:
      'Other phone number by which you can be reached.',
    socialSecurityNumber: 'Social Security Number',
    dateOfBirth: 'Date of birth (MM/DD/YYYY)',
    incomeFields: {
      title: 'Annual income',
      personalIncomeLabel: (name: string) => `${name}'s personal income`,
      personalIncomeNoNameLabel: 'Personal income',
      coApplicantNoNameIncomeLabel: (number: number) =>
        `Applicant ${number} personal income`,
      personalIncomeHelper:
        'Annual pre-tax (gross) income. Alimony, child support, and separate maintenance income are optional. ',
      loanPersonalIncomeHelper:
        'Your annual pre-tax (gross) income. Alimony, child support, and separate maintenance income are optional. ',
      readMore: 'Read more',
      personalIncomeReadMoreText:
        'You may include any source of income you wish to have considered as a basis for repaying this obligation, for example – salary, wages, interest, dividend, rental income, retirement benefits. Alimony, child support, or separate maintenance income need not be revealed if you do not wish to have it considered as a basis for repaying this obligation.',
      householdLabel: 'Other household income (optional)',
      householdIncomeHelper:
        'Additional gross income of other household members at the same address and not included by either applicant above.',
      highIncomeAlert:
        'Please review your stated income for accuracy. If correct, ignore this message.',
      lowIncomeAlert:
        'Please verify that you’ve entered your total yearly income.'
    },
    termsAndConditions: {
      title: 'Terms & Conditions',
      subtitle:
        'For information about our collection, sharing, and use of your personal information, please see our ',
      privacyPolicy: 'Privacy Policy.',
      errorMessage:
        'To pre-qualify for credit, please agree to these terms and conditions.',
      agreeTermsAndConditions:
        'I agree to the Terms and Conditions listed above.'
    },
    projectAddressFields: {
      title: 'Project address',
      streetAddress: 'Street address',
      city: 'City',
      state: 'State',
      zipCode: 'ZIP code',
      loan: {
        body: 'Tell us where this project will be located. You can also provide a primary address and a mailing address if this project will not be at your primary address.'
      }
    },
    agreeTermsAndConditions:
      'I agree to the Terms and Conditions listed above.',
    applicantAddress: {
      title: 'Applicant address',
      sameAsProjectAddress: 'Same as the project address'
    },
    mailingAddress: {
      title: 'Applicant mailing address',
      sameAsProjectAddress: 'Same as the project address'
    },
    propertyOwnership: {
      title: 'Property ownership',
      titleLoan: 'Ownership:',
      checkboxLabel:
        'I own the property in my name or directly through a trust where I am the trustee.',
      checkboxLabelLoan:
        'I own the project address in my name or directly through a trust where I am the trustee.',
      errorMessage: 'Please review the statement above.'
    },
    propertyOwnershipRadio: {
      label:
        'Can you verify that you own the project address in your name or directly through a trust where you are the trustee?'
    },
    fullApplication: {
      title: 'Pre-qualify',
      submittingNote:
        'Submitting a pre-qualification will not affect your credit score.'
    },
    contactInfoOnlyApplication: {
      title: 'Pre-qualify now',
      subtitle:
        'Please continue below. We also sent you a link in case you need to finish this later.',
      markdown: `<p>By providing your mobile phone number you agree to receive SMS text messages regarding the status of your financing. Read full [Calling/Text Messaging and Call Recording Consent](https://financing-terms.nonprod-mosaic.com/#callingtext-messaging--call-recording-consent)</p>`
    },
    optionalInfo: {
      title: 'Optional info',
      receiveMailAtDifferentAddress:
        'Do you prefer to receive mail at a different address?',
      mailingAddressPart1:
        'If you do not prefer to receive mail at the project address, what’s your ',
      mailingAddressPart2: 'mailing address',
      mailingAddressPart3: '?',
      alternatePhoneNumber: 'Alternate phone number',
      alternatePhoneNumberDescription:
        'Other phone number by which you can be reached?',
      referredByDescription:
        'The email address of the sales rep who referred you to Mosaic? Leave it blank if none.',
      referredBy: 'Referred by'
    },
    coApplicant: {
      addACoApplicant: 'Add a Co-applicant',
      addACoApplicantDescription:
        'By selecting “Add a Co-applicant” I acknowledge that I intend to apply jointly, or I have permission to apply on behalf of this co-applicant, and that we intend to apply jointly.'
    },
    minimumOwnership: {
      title: 'Are you sure?',
      body: 'At least one applicant must own the home at this project address. This applicant’s name must be on title or directly through a trust where they are the trustee. If you continue, there is a good chance you may not pre-qualify.',
      bodyHideContinue:
        'At least one applicant must own the home at this project address. This applicant’s name must be on title or directly through a trust where they are the trustee.'
    },
    yesOrNo: {
      yes: 'Yes',
      no: 'No'
    },
    dateOfBirthAlert:
      'Confirm that the date of birth that you entered is correct.'
  },
  securityInfoText: {
    ssl: 'SSL',
    encryption: '128-BIT ENCRYPTION'
  },
  contactInfoSection: {
    title: 'Your contact info',
    emailAddress: 'Email address',
    mobileNumber: 'Mobile phone number',
    landlineAlert:
      'A text-enabled mobile phone enables your rep to make your project run more efficiently. ',
    landlineAlertButton: 'I do not own a mobile phone'
  },
  decision: {
    processing: {
      title: 'Processing...',
      subtitle: 'This should just take a few seconds.'
    },
    buttons: { continue: 'Continue' },
    congratulations: {
      title: 'Congratulations!',
      subtitle: 'You qualify for our lease program!',
      body: (maxMonthlyPayment: number) =>
        `You can get a system for up to $${maxMonthlyPayment} per month.  You can use as much or as little of that amount as you'd like.`,
      selectAnEstimate: 'Select an estimate',
      salesRepText: (salesrepName: String) =>
        `Now pass the device back to ${salesrepName} to get an estimate.`,
      passBackToSalesRepToContinue: (salesRepName: String) =>
        `Now pass the screen back to ${salesRepName} to continue.`
    },
    adverse: {
      title: 'Thank you',
      subtitle: 'Your decision will be sent to the email address you provided.',
      body: (salesRepName: string) =>
        `Now pass the device back to ${salesRepName}`,
      salesRepInTouch: (salesRepName: string) =>
        `${salesRepName} will be in touch soon to discuss next steps.`
    },
    applicationNeedsReview: {
      title: 'Application needs review',
      applicationApproved:
        'This application has been conditionally approved. To complete a credit decision, the ',
      incomeDocumentation:
        'customer is required to provide Income documentation.',
      instructionsSent:
        'The applicant has been sent instructions on what is needed to move forward.',
      documentationNeeded:
        'If we do not receive the documentation from the customer within 25 days of our request, we will be unable to give the application further consideration.',
      contactCustomerSupport:
        'If your customer is unsure of what information to provide in response to our request, they should contact our customer support team at '
    },
    applicationBlocked: {
      title: 'Application has been blocked',
      unableToComplete:
        'Mosaic is unable to complete a Credit Decision based on Applicant information provided. ',
      reApply:
        'Applicant should call Mosaic Credit Ops and may need to reapply.',
      customerSupport:
        'For any questions, our customer support team is available at '
    },
    supportPhoneNumber: '(855) 558-6007',
    supportOfficeHours: ', Mon-Friday, 7am-9pm Pacific.'
  },
  applicationStatus: {
    approved: {
      title: 'Congratulations!',
      subtitle: () =>
        `You’ve been approved for financing through Mosaic for up to `,
      body: (installerName: string) =>
        `Next, ${installerName} will be sending you a Home Improvement Agreement (the installation contract between you and ${installerName}) and we will be sending you the loan agreement (the financing contract between you and the lender). When you receive these documents, please go over them carefully because they include information regarding certain financing conditions, loan disbursements, the ongoing servicing of your loan and other important loan information.`,
      totalLoanAmount: 'Total loan amount:',
      financingApplied:
        'Financing applied for and processed through the Mosaic platform is originated by Solar Mosaic LLC or one of its lending/financing partners. Refer to transaction specific disclosures and loan documents for details and additional information. Your financing may be contingent upon you becoming a member of the lender organization at no membership cost to you.',
      continueToLoanTerms: 'Continue to loan terms',
      description: financingLimit =>
        `Congratulations, you qualify for financing up to ${financingLimit}`,
      financingText: 'Congratulations, you qualify for financing up to ',
      yourLoanAmount: 'Your loan amount',
      yourFinancePlan: 'Your finance plan:',
      yourFinancePlanWebbank: 'Finance plan based on loan amount:',
      totalAmountToFinance: 'Total amount to finance:',
      totalAmountToFinanceWebbank: 'Total loan amount:',
      viewOffer: 'Continue to Loan Terms',
      estPerMonth: 'Est. per month',
      descriptionVoided: 'Please continue after ',
      descriptionVoidedLinkText: 'editing this offer',
      loanAmountOutOfLimitMessage: (partner: string) =>
        `Please contact your ${partner} rep and make sure they have the proper loan amount and finance plan selected in order to continue`
    },
    needsReview: {
      title: 'Conditional approval',
      conditionallyQualified: maxBorrowingAmount =>
        `You conditionally qualify for a loan through Mosaic for up to ${maxBorrowingAmount} to finance a home solar system and related upgrades, using equipment that has been approved by Mosaic.`,
      heldForIdentityVerification:
        'Your application has been held for further manual review; approval is CONDITIONAL on that further review.',
      provideDocumentation:
        'In order for us to complete our review and move forward, we need to further review your application and supporting documentation. Please be prepared to promptly provide further documentation. ',
      contactShortly: 'We will contact you shortly.',
      denyApplication:
        'If we are not able to verify your identity, we may deny your application.'
    },
    thankYou: {
      title: 'Thanks',
      done: 'You’re done! We’ll notify your rep ',
      completedFinancing:
        ' that you have completed financing and they will let you know about next steps.'
    },
    paymentPlan: {
      title: 'Payment plan:',
      estPerMonth: 'est. per month',
      paymentPeriod: (paymentPeriod: number) => `${paymentPeriod}-month`,
      paymentPeriodAndAprInfo: (paymentPeriod: number, aprPercentage: number) =>
        `${(Math.floor(paymentPeriod) / 12).toFixed(
          0
        )} years at ${aprPercentage.toFixed(2)}% APR`
    },
    sentToEmail: {
      title: 'Pre-qualification decision complete',
      subtitle: 'Your decision will be sent to the email address you provided'
    }
  },
  emailVerification: {
    title: 'Action needed',
    subTitle: 'Consent to receiving electronic communications',
    emailSign: 'Confirm your email address',
    waiting: 'Waiting for a response from',
    description: (email: string) => (
      <>
        {
          'In order to continue with your loan, we need to verify that the email '
        }
        <b>{email ? `${email} ` : 'above'}</b>
        {` belongs to you. Select the button below to send yourself an email with the subject `}
        <b>
          {`"Action Needed: Consent to receiving electronic communications"`}
        </b>
        {'. Please follow the link to verify.'}
      </>
    ),
    sendEmail: 'Send Email Now',
    sent: date => `Sent ${date}`,
    resendEmail: 'Resend email',
    never: 'Not yet sent',
    userLinkEmailVerification: {
      needToVerify:
        'In order to proceed with your loan, we need to verify that the above email address belongs to you.',
      pleaseOpen:
        'Please open the email with subject "Action Needed: Verify your email address" and follow the link to confirm you have access to this email account.'
    },
    customerNotFoundError:
      'Sorry, the customer you are looking for does not exist or is no longer available.'
  },
  loanIdentityVerification: {
    socialSecurityNumber: 'Social Security Number',
    lastFourSsn: 'Last 4 of Social Security Number',
    dateOfBirth: 'Date of birth (MM/DD/YYYY)',
    confirmIdentityError:
      'The information entered does not match our records, please try again.',
    overviewOfTerms: {
      title: 'Verify identity',
      subtitle:
        'Please verify the following information you previously provided in your credit application.',
      continue: 'Continue'
    },
    creditDecision: {
      title: 'Information verification',
      subtitle:
        'Please verify the following information from your pre-qualification.',
      continue: 'Confirm'
    }
  },
  formStep: {
    errorMessage: 'Please fix the errors above'
  },
  shared: {
    continue: 'Continue',
    continueAs: (salesRepName: string) => `Continue as ${salesRepName}`,
    continueAnyway: 'Continue anyway',
    goBack: 'Go back',
    agreeAndContinue: 'Agree & continue',
    processing: 'Processing, please wait...',
    processingTimeout: {
      title: 'Processing',
      text: 'It’s taking longer than usual to get a response from the credit bureau. We will keep trying an update to you by email when we have a decision ready for you. Thank you for your patience.',
      refresh: 'Refresh'
    },
    customerNotFoundError:
      'Sorry, the customer you are looking for does not exist or is no longer available.'
  },
  stipResolution: {
    title: 'Additional documentation needed',
    description:
      'Thank you for choosing Mosaic. Based on the specifics of your application, it looks like we need to collect some additional documentation before we can complete our review. Don’t worry, you’re almost there!',
    doneTitle: 'Your documents have been received',
    doneDescription:
      'Thank you for submitting your supporting documentation. We will email you as soon as we have a completed review of your application.',
    button: 'Upload files now',
    proofOfIncome: 'Proof of income',
    photoID: 'Photo ID',
    files: 'Files:',
    dragAndDropFile: 'Drag and drop your files here or ',
    browseFile: 'browse',
    acceptedFiles: {
      title: 'We accept one of the following:',
      lastYearsW2: 'Last year’s W2’s',
      paystubs: '2 paystubs from the last 30 days',
      lastYearsTaxDocument: 'Last year’s 1099 tax document',
      mostRecentSocialSecurityStatement:
        'Most recent social security statement',
      mostRecentPensionStatement: 'Most recent pension statement',
      proofOfDisability:
        'Proof of disability & most recent social security statement',
      lastYearsSignedTaxDocument: 'Last year’s signed 1040 tax document',
      proofOfAlimony:
        'Proof of alimony or child support (if you wish for it to be considered)',
      personalIncomeOnly:
        'Please include only your personal income. Do not include income of other members of your household. You may upload multiple files.',
      driversLicense: 'Driver’s license',
      passport: 'Passport',
      stateIdCard: 'State ID card',
      otherPhoto: 'Other government-issued photo identification',
      onlyOneIdentification:
        'You may only submit a single piece of identification. If you upload multiple files only the most recent upload will be considered.'
    },
    uploadError: {
      'file-too-large': (maxSizeInMB: number) =>
        `File size exceeds ${maxSizeInMB} MB`,
      'too-many-files': 'Exceeds the file limit',
      'file-invalid-type': 'Not valid format'
    },
    uploadDocumentError:
      'Some files were not successfully uploaded, try again later'
  },
  creditApplication: {
    confirmInfo: {
      applicant1: 'Applicant 1',
      applicant2: 'Applicant 2',
      chooseAddress: 'Which address would you like to use?',
      continue: 'continue',
      goBack: 'Go back',
      header: 'Confirm your info',
      headerText:
        'Please review your personal info and make sure it is all correct before you continue.',
      address: {
        title1: 'Use suggested address:',
        title2: 'Use the address I entered:'
      }
    }
  },
  schemaValidation: {
    last4DigitsOnly: 'Last 4 digits only',
    dateOfBirthRequired: 'Date of birth is required',
    invalidDate: 'Invalid date',
    fullSsnRequired: 'Social security number must have 9 digits',
    invalidNumberOfDigits: 'Invalid number of digits',
    nineDigitsOnly: '9 digits only',
    ssnDigitsOnly: 'The social security number should have digits only',
    fourSsnRequired: 'Last 4 digits of social security number is required',
    fourDigitsOnly: '4 digits only',
    firstNameRequired: 'First name is required',
    min2Letters: 'Must be at least two letters',
    nameInvalidChars:
      'Only letters, apostrophes, hyphens and spaces are allowed',
    lastNameRequired: 'Last name is required',
    emailRequired: 'Email is required',
    invalidEmail: 'Must be a valid email',
    mobilePhoneRequired: 'Mobile phone is required',
    invalidPhone: 'Invalid phone number',
    invalidSsn: 'Invalid social security number',
    requiredField: 'Field is required',
    positiveNumber: 'Must be a positive number',
    invalidZipCode: 'Invalid zip code',
    invalidState: 'Must be a state from the list',
    reviewStatementAbove: 'Please review the statement above.',
    confirmDateOfBirth: 'Invalid date',
    selectAnAswer: 'Select an answer',
    maxCityCharacter: 'City can’t have more than 38 characters',
    minCityCharacter: 'City must be at least three letters',
    invalidCityCharacter: 'Only letters and spaces are allowed',
    streetMaxCharacter: 'Can’t have more than 50 characters',
    streetOnlyNumbers: 'Cannot only have numbers',
    selectAnAnswer: 'Please select an answer',
    suffixFirstLastName: (suffixes: string[], lastSuffix: string) =>
      `Cannot be equal to ${suffixes.join(', ')} or ${lastSuffix}`,
    lastNameSingleLetter: 'The last name cannot have single letter words',
    firstNameSingleLetter: 'The first name cannot have single letter words'
  },
  ssnField: {
    show: 'Show',
    hide: 'Hide'
  }
}

export const esUS = {
  greetings: {
    hello: 'Hola'
  },
  applicant: {
    applicantInfo: {
      title: 'Información del solicitante',
      loan: {
        title: 'Solicitante',
        applicant1Body: 'Cuéntenos un poco más sobre usted.',
        applicant2Body:
          'Agregar un cosolicitante aumentará la probabilidad de obtener la calificación previa. Ambos solicitantes deben firmar un acuerdo.',
        projectAddressResident:
          '¿Puedes verificar que eres residente en la dirección del proyecto?',
        primaryAddress:
          'Si no vive en la dirección del proyecto, ¿cuál es su dirección principal?',
        lastFourSsn: 'SSN (últimos 4 números)',
        ownershipAndResidence: 'Propiedad y Residencia'
      }
    },
    validationErrors: {
      emailMustBeValid: 'Debe ser una dirección de correo electrónico válida.',
      emailInstaller:
        'Por favor, introduzca la dirección de correo electrónico de su cliente.',
      phoneNumberIsInvalid:
        'Número de móvil inválido. Por favor ingrese un número de móvil válido para continuar.',
      phoneNumberIsOptedOut:
        '"Este número de móvil optó no recibir mensajes de texto."',
      sameApplicant:
        'Los nombres y el SSN no pueden ser el mismo para ambos aplicantes'
    },
    firstName: 'Nombre (solo legal)',
    firstNameDescription: 'Nombre Legal, no un sobrenombre',
    middleName: 'Segundo nombre (opcional)',
    lastName: 'Apellido',
    email: 'Correo electrónico',
    mobilePhone: 'Teléfono móvil',
    alternatePhone: 'Teléfono alternativo (opcional)',
    alternatePhoneNumberHelperText:
      'Otro número de teléfono por el cual se le puede localizar.',
    socialSecurityNumber: 'Número de Seguridad Social',
    dateOfBirth: 'Fecha de nacimiento (MM/DD/AAAA)',
    incomeFields: {
      title: 'Ingresos anuales',
      personalIncomeLabel: (name: string) => `Renta personal de ${name}`,
      personalIncomeNoNameLabel: 'Renta personal',
      coApplicantNoNameIncomeLabel: (number: number) =>
        `Renta personal de solicitante ${number}`,
      personalIncomeHelper: 'Ingreso anual antes de impuestos (bruto). ',
      loanPersonalIncomeHelper:
        'El ingreso por pensión alimenticia, manutención de los hijos y por separación son opcionales. ',
      readMore: 'Leer más',
      personalIncomeReadMoreText:
        'Puede incluir cualquier fuente de ingresos que desee tener en cuenta como base para pagar esta obligación, por ejemplo: salario, salarios, intereses, dividendos, ingresos por alquileres, beneficios de jubilación. No es necesario revelar los ingresos por pensión alimenticia, manutención de los hijos o manutención separada si no desea que se consideren como base para pagar esta obligación.',
      householdLabel: 'Otros ingresos del hogar (opcional)',
      householdIncomeHelper:
        'Ingreso bruto adicional de otros miembros del grupo familiar en la misma dirección y no incluidos por ninguno de los solicitantes anteriores.',
      highIncomeAlert:
        'Por favor verifique que sus ingresos personales sean correctos. De ser así, ignore este mensaje.',
      lowIncomeAlert:
        'Por favor verifique que ingresó sus ingresos personales anuales totales.'
    },
    termsAndConditions: {
      title: 'Términos y condiciones',
      subtitle:
        'Para obtener información sobre cómo recopilamos, compartimos y usamos su información personal, consulte nuestra ',
      privacyPolicy: 'Política de Privacidad.',
      errorMessage:
        'Para precalificar para crédito, por favor acepte estos términos y condiciones.',
      agreeTermsAndConditions:
        'Acepto los términos y las condiciones enumerados con anterioridad.'
    },
    projectAddressFields: {
      title: 'Dirección del proyecto',
      streetAddress: 'Domicilio',
      city: 'Ciudad',
      state: 'Estado',
      zipCode: 'Código postal',
      loan: {
        body: 'Cuéntenos dónde se ubicará este proyecto. También puede proporcionar una dirección principal y una dirección postal si este proyecto no se encontrará en su dirección principal.'
      }
    },
    agreeTermsAndConditions:
      'Acepto los términos y las condiciones enumerados con anterioridad.',
    applicantAddress: {
      title: 'Dirección del solicitante',
      sameAsProjectAddress: 'Igual que la dirección del proyecto'
    },
    mailingAddress: {
      title: 'Dirección postal del solicitante',
      sameAsProjectAddress: 'Igual que la dirección del proyecto'
    },
    propertyOwnership: {
      title: 'Propiedad de bienes',
      titleLoan: 'Propiedad:',
      checkboxLabel:
        'Soy dueño de la propiedad a mi nombre o directamente a través de un fideicomiso donde soy el fideicomisario.',
      checkboxLabelLoan:
        'Soy dueño de la propiedad a mi nombre o directamente a través de un fideicomiso donde soy el fideicomisario.',
      errorMessage: 'Revise la declaración anterior.'
    },
    propertyOwnershipRadio: {
      label:
        'Puedes verificar que la propiedad está a su nombre o es dueño directamente a través de un fideicomiso donde es el fideicomisario?'
    },
    fullApplication: {
      title: 'Clasificación previa',
      submittingNote:
        'Presentar una calificación previa no afectará su puntuación de crédito.'
    },
    contactInfoOnlyApplication: {
      title: 'Obtenga una clasificación previa ahora',
      subtitle:
        'Proporcione una dirección de correo electrónico válida a la que pueda acceder y un número de teléfono celular para recibir notificaciones instantáneas sobre su proyecto.',
      markdown: `<p>Cuando proporciona su número de teléfono celular, acepta recibir mensajes de texto SMS sobre el estado de su financiamiento. Leer completo [Consentimiento para llamadas/mensajes de texto y grabación de llamadas](https://financing-terms.nonprod-mosaic.com/es#TCPA_1_ES)</p>`
    },
    optionalInfo: {
      title: 'Información opcional',
      receiveMailAtDifferentAddress:
        '¿Prefiere recibir el correo en una dirección diferente?',
      mailingAddressPart1:
        'Si no prefiere recibir el correo en la dirección del proyecto, ¿cuál es su ',
      mailingAddressPart2: 'dirección postal',
      mailingAddressPart3: '?',
      alternatePhoneNumber: 'Número de teléfono alternativo',
      alternatePhoneNumberDescription:
        '¿Tiene otro número de teléfono por el cual se lo puede localizar?',
      referredByDescription:
        '¿Recuerda la dirección de correo electrónico del representante de ventas que lo refirió a Mosaic? Deje los espacios en blanco si no corresponde.',
      referredBy: 'Referido por'
    },
    coApplicant: {
      addACoApplicant: 'Agregar un cosolicitante',
      addACoApplicantDescription:
        'Cuando selecciona “Agregar un cosolicitante”, reconozco que tengo la intención de presentar una solicitud conjunta o tengo permiso para presentar una solicitud en nombre de este cosolicitante y que ambos tenemos la intención de presentar una solicitud conjunta.'
    },
    minimumOwnership: {
      title: '¿Está seguro?',
      body: 'Al menos un solicitante debe ser propietario de la casa en esta dirección del proyecto. El nombre de este solicitante debe estar en el título o directamente en un fideicomiso en el que sea el fideicomisario. Si continúa, es muy probable que no obtenga una calificación previa.',
      bodyHideContinue:
        'Al menos un solicitante debe ser propietario de la casa en esta dirección del proyecto. El nombre de este solicitante debe estar en el título o directamente en un fideicomiso en el que sea el fideicomisario.'
    },
    yesOrNo: {
      yes: 'Sí',
      no: 'No'
    },
    dateOfBirthAlert:
      'Confirme que la fecha de nacimiento ingresada es correcta.'
  },
  securityInfoText: {
    ssl: 'SSL',
    encryption: 'CIFRADO DE 128 BITS'
  },
  contactInfoSection: {
    title: 'Su información de contacto',
    emailAddress: 'Dirección de correo electrónico',
    mobileNumber: 'Teléfono móvil',
    landlineAlert:
      'Un teléfono celular habilitado para texto le permite a su representante hacer que su proyecto se ejecute de manera más eficiente. ',
    landlineAlertButton: 'No tengo teléfono celular'
  },
  decision: {
    processing: {
      title: 'Procesando...',
      subtitle: 'Esto debería tomar solo unos segundos.'
    },
    buttons: { continue: 'Continuar' },
    congratulations: {
      title: '¡Felicidades!',
      subtitle: '¡Califica para nuestro programa de arrendamiento!',
      body: (maxMonthlyPayment: number) =>
        `Puede obtener un sistema de hasta $${maxMonthlyPayment} por mes. Puede usar tanto como desee de ese importe.`,
      selectAnEstimate: 'Seleccione un presupuesto',
      salesRepText: (salesrepName: String) =>
        `Ahora devuélvale el dispositivo a ${salesrepName} para obtener un presupuesto.`,
      passBackToSalesRepToContinue: (salesRepName: String) =>
        `Ahora devuélvale la pantalla a ${salesRepName} para continuar.`
    },
    adverse: {
      title: 'Gracias',
      subtitle:
        'La decisión se enviará a la dirección de correo electrónico que proporcionó.',
      body: (salesRepName: string) =>
        `Ahora devuélvale el dispositivo a ${salesRepName}`,
      salesRepInTouch: (salesRepName: string) =>
        `${salesRepName} se comunicará pronto para analizar los próximos pasos.`
    },
    applicationNeedsReview: {
      title: 'La solicitud necesita revisión',
      applicationApproved:
        'Esta solicitud se ha aprobado condicionalmente. Para completar una decisión de crédito, ',
      incomeDocumentation:
        'se requiere que el cliente presente la documentación de ingresos.',
      instructionsSent:
        'Al solicitante se le han enviado instrucciones sobre lo que se necesita para seguir continuar.',
      documentationNeeded:
        'Si no recibimos la documentación del cliente dentro de los 25 días de enviada nuestra solicitud, no podremos seguir considerando la solicitud.',
      contactCustomerSupport:
        'Si su cliente no está seguro de qué información brindar en respuesta a nuestra solicitud, debe comunicarse con nuestro equipo de atención al cliente al '
    },
    applicationBlocked: {
      title: 'La aplicación ha sido bloqueada',
      unableToComplete:
        'Mosaic no puede completar una decisión de crédito con la información proporcionada por el solicitante. ',
      reApply:
        'El solicitante debe llamar al Servicio de atención al cliente de Mosaic y es posible que deba volver a presentar una solicitud.',
      customerSupport:
        'Ante cualquier duda, nuestro equipo de atención al cliente está disponible a través del número '
    },
    supportPhoneNumber: '(855) 558-6007',
    supportOfficeHours:
      ', Lunes a viernes, de 7:00 a. m. a 9:00 p. m., hora del Pacífico.'
  },
  applicationStatus: {
    approved: {
      title: '¡Felicidades!',
      subtitle: () =>
        `Se ha aprobado el financiamiento mediante Mosaic por un importe máximo de `,
      body: (installerName: string) =>
        `A continuación, ${installerName} le enviará un contrato de mejoras para el hogar (el contrato de instalación que se celebra entre usted y ${installerName}) y nosotros le enviaremos el contrato del préstamo (el contrato de financiamiento que se celebra entre usted y el prestamista). Cuando reciba estos documentos, revíselos cuidadosamente, ya que incluyen información sobre ciertas condiciones financieras, los pagos del préstamo, el servicio continuo de su préstamo y otra información importante sobre este.`,
      totalLoanAmount: 'Importe total del préstamo:',
      financingApplied:
        'El financiamiento solicitado y procesado a través de la plataforma Mosaic proviene de Solar Mosaic, LLC. o uno de sus socios de préstamo/financiamiento. Consulte las divulgaciones específicas de la transacción y los documentos del préstamo para obtener detalles e información adicional. Su financiamiento puede depender de que se convierta en miembro de la organización de prestamistas sin costo alguno para usted.',
      continueToLoanTerms: 'Continuar a los términos del préstamo',
      description: financingLimit =>
        `Felicidades, usted califica para el financiamiento de hasta ${financingLimit}`,
      financingText:
        'Felicidades, usted califica para el financiamiento de hasta ',
      yourLoanAmount: 'El importe de su préstamo',
      yourFinancePlan: 'Su plan de finanzas:',
      yourFinancePlanWebbank:
        'Plan de financiación según el importe del préstamo:',
      totalAmountToFinance: 'Importe total a financiar:',
      totalAmountToFinanceWebbank: 'Importe total del préstamo:',
      viewOffer: 'Continuar a los Términos del préstamo',
      estPerMonth: 'Presupuesto por mes',
      descriptionVoided: 'Continúe después ',
      descriptionVoidedLinkText: 'de editar esta oferta',
      loanAmountOutOfLimitMessage: (partner: string) =>
        `Comuníquese con su ${partner} representante y asegúrese de que haya seleccionado el importe del préstamo y el plan de financiamiento adecuados para poder continuar`
    },
    needsReview: {
      title: 'Aprobación condicional',
      conditionallyQualified: maxBorrowingAmount =>
        `Usted califica condicionalmente para un préstamo a través de Mosaic de hasta ${maxBorrowingAmount} destinado a financiar un sistema solar doméstico y las actualizaciones relacionadas, con equipos aprobados por Mosaic. `,
      heldForIdentityVerification:
        'Su solicitud está retenida y requiere una revisión manual adicional. La aprobación se otorga de manera CONDICIONAL, ya que está sujeta a dicha revisión adicional.',
      provideDocumentation:
        'Para que podamos completar nuestra revisión y seguir adelante, necesitamos revisar más a fondo su solicitud y la documentación de respaldo. Esté preparado para presentar sin demora otros documentos de verificación de identidad. ',
      contactShortly: 'Nos comunicaremos con usted en breve.',
      denyApplication:
        'Si no podemos verificar su identidad, es posible que rechacemos su solicitud.'
    },
    thankYou: {
      title: 'Gracias',
      done: '¡Ya terminaste! Le notificaremos a su representante ',
      completedFinancing:
        ' que ha completado la financiación y le informarán sobre los próximos pasos.'
    },
    paymentPlan: {
      title: 'Plan de pago:',
      estPerMonth: 'presupuesto por mes',
      paymentPeriod: (paymentPeriod: number) => `${paymentPeriod}-mes`,
      paymentPeriodAndAprInfo: (paymentPeriod: number, aprPercentage: number) =>
        `${(Math.floor(paymentPeriod) / 12).toFixed(
          0
        )} años en ${aprPercentage.toFixed(2)} % de APR`
    },
    sentToEmail: {
      title: 'Completar',
      subtitle:
        'La decisión se enviará a la dirección de correo electrónico que proporcionó'
    }
  },
  emailVerification: {
    title: 'Acción requerida',
    subTitle: 'Autorización para recibir comunicaciones electrónicas',
    emailSign: 'Confirmar la dirección de correo electrónico',
    waiting: 'Esperando una respuesta de',
    description: (email: string) => (
      <>
        {
          'Para continuar con su préstamo, necesitamos verificar que el correo electrónico '
        }
        <b>{email ? `${email} ` : 'anterior'}</b>
        {` le pertenece. Seleccione el botón de abajo para enviarse un correo electrónico con el asunto `}
        <b>
          {`“Acción requerida: autorización para recibir comunicaciones electrónicas”`}
        </b>
        {'. Haga clic en el enlace para verificar el correo.'}
      </>
    ),
    sendEmail: 'Enviar correo electrónico ahora',
    sent: date => `Enviado el ${date}`,
    resendEmail: 'Reenviar el correo electrónico',
    never: 'Aún no se ha enviado',
    userLinkEmailVerification: {
      needToVerify:
        'Para proceder con su préstamo, necesitamos verificar que la dirección de correo electrónico anterior le pertenece.',
      pleaseOpen:
        'Abra el correo electrónico con el asunto “Acción necesaria: verifique su dirección de correo electrónico” y siga el enlace para confirmar que tiene acceso a esta cuenta de correo electrónico.'
    },
    customerNotFoundError:
      'Lo sentimos, el cliente que busca no existe o ya no está disponible.'
  },
  loanIdentityVerification: {
    socialSecurityNumber: 'Número de Seguridad Social',
    lastFourSsn: 'Últimos 4 dígitos de su Número de Seguro Social (SSN)',
    dateOfBirth: 'Fecha de nacimiento (MM/DD/AAAA)',
    confirmIdentityError:
      'La información que ingresó no coincide con nuestros registros, por favor intente nuevamente',
    overviewOfTerms: {
      title: 'Verificar identidad',
      subtitle:
        'Verifique la siguiente información que proporcionó anteriormente en su solicitud de crédito.',
      continue: 'Continuar'
    },
    creditDecision: {
      title: 'Verificación de información',
      subtitle: 'Verifique la siguiente información de su precalificación.',
      continue: 'Confirmar'
    }
  },
  formStep: {
    errorMessage: 'Corrija los errores anteriores'
  },
  shared: {
    continue: 'Continuar',
    continueAs: (salesRepName: string) => `Continuar como ${salesRepName}`,
    continueAnyway: 'Continuar igual',
    goBack: 'Volver',
    agreeAndContinue: 'Aceptar y continuar',
    processing: 'Procesando, por favor espere...',
    processingTimeout: {
      title: 'Procesando',
      text: 'La respuesta de su oficina de crédito está tardando más de lo usual. Continuaremos intentando, y lo actualizaremos vía email cuando tengamos lista una decisión para usted. Gracias por su paciencia.',
      refresh: 'Refrescar'
    },
    customerNotFoundError:
      'Lamentablemente el cliente que busca no existe o no esta disponible.'
  },
  stipResolution: {
    title: 'Se necesita documentación adicional',
    description:
      'Gracias por elegir a Mosaic. Según los detalles de su solicitud, al parecer necesitamos recopilar documentación adicional antes de que podamos completar nuestra revisión. ¡No se preocupe, estamos casi listos!',
    doneTitle: 'Sus documentos fueron recibidos',
    doneDescription:
      'Gracias por enviar su documentación de respaldo. Le enviaremos un correo electrónico tan pronto como tengamos una revisión completa de su solicitud.',
    button: 'Subir archivos ahora',
    photoID: 'Identificación fotográfica',
    proofOfIncome: 'Comprobante de ingreso',
    files: 'Archivos:',
    dragAndDropFile: 'Arrastre y suelte sus archivos aquí o ',
    browseFile: 'navegar',
    acceptedFiles: {
      title: 'Aceptamos una de las siguientes opciones:',
      lastYearsW2: 'W2 del año pasado',
      paystubs: '2 colillas de pago de los últimos 30 días',
      lastYearsTaxDocument: 'Documento tributario 1099 del año pasado',
      mostRecentSocialSecurityStatement:
        'Declaración de seguridad social más reciente',
      mostRecentPensionStatement: 'Declaración de pensión más reciente',
      proofOfDisability:
        'Comprobante de discapacidad y declaración de seguridad social más reciente',
      lastYearsSignedTaxDocument:
        'Documento tributario 1040 del año pasado firmado',
      proofOfAlimony:
        'Comprobante de pensión alimenticia o manutención de los hijos (si desea que se considere)',
      personalIncomeOnly:
        'Incluya solo sus ingresos personales. No incluya los ingresos de otros miembros de su grupo familiar. Puede cargar varios archivos.',
      driversLicense: 'Licencia de conducir',
      passport: 'Pasaporte',
      stateIdCard: 'Tarjeta de identificación del estado',
      otherPhoto: 'Otra identificación con foto emitida por el gobierno',
      onlyOneIdentification:
        'Solo puede presentar una sola identificación. Si carga varios archivos, solo se considerará el que haya cargado de manera más reciente.'
    },
    uploadError: {
      'file-too-large': (maxSizeInMB: number) =>
        `El tamaño exede ${maxSizeInMB} MB`,
      'too-many-files': 'Exede la cantidad de archivos',
      'file-invalid-type': 'Formato invalido'
    },
    uploadDocumentError:
      'Algunos documentos no se subieron correctamente, intente nuevamente mas tarde'
  },
  creditApplication: {
    confirmInfo: {
      applicant1: 'Solicitante 1',
      applicant2: 'Solicitante 2',
      chooseAddress: '¿Qué dirección le gustaría usar?',
      continue: 'continuar',
      goBack: 'Volver',
      header: 'Confirma tu información',
      headerText:
        'Revise su información personal y asegúrese de que todo sea correcto antes de continuar.',
      address: {
        title1: 'Utilice la dirección sugerida:',
        title2: 'Usa la dirección que ingresé:'
      }
    }
  },
  schemaValidation: {
    last4DigitsOnly: 'Solo los últimos 4 dígitos',
    dateOfBirthRequired: 'Fecha de nacimiento es requerida',
    invalidDate: 'Fecha inválida',
    fullSsnRequired: 'Los 9 dígitos del seguro social son requeridos',
    invalidNumberOfDigits: 'Cantidad inválida de dígitos',
    nineDigitsOnly: 'Solo 9 dígitos',
    ssnDigitsOnly: 'SSN solo se admiten números',
    fourSsnRequired: 'Los últimos 4 dígitos del seguro social son requeridos',
    fourDigitsOnly: 'Solo 4 dígitos',
    firstNameRequired: 'Nombre es requerido',
    min2Letters: 'Debe haber al menos 2 letras',
    nameInvalidChars: 'Solo se permiten letras, apóstrofes, y guiones',
    lastNameRequired: 'Apellido es requerido',
    emailRequired: 'Email es requerido',
    invalidEmail: 'Email inválido',
    mobilePhoneRequired: 'Teléfono móvil es requerido',
    invalidPhone: 'Numero de teléfono inválido',
    invalidSsn: 'Número de seguro social inválido',
    requiredField: 'Campo requerido',
    positiveNumber: 'Debe ser un número positivo',
    invalidZipCode: 'Código postal inválido',
    invalidState: 'Debe ser un estado de la lista',
    reviewStatementAbove: 'Revise la declaración anterior.',
    confirmDateOfBirth: 'Fecha inválida',
    selectAnAswer: 'Elije una respuesta',
    maxCityCharacter: 'Ciudad no puede tener mas de 38 letras',
    minCityCharacter: 'Ciudad debe tener al menos tres letras',
    invalidCityCharacter: 'Sólo se permiten letras y espacios',
    streetMaxCharacter: 'No puede contener mas de 50 caracteres',
    streetOnlyNumbers: 'No puede contener solo números',
    selectAnAnswer: 'Por favor seleccione una respuesta',
    suffixFirstLastName: (suffixes: string[], lastSuffix: string) =>
      `No puede ser igual a ${suffixes.join(', ')} o ${lastSuffix}`,
    lastNameSingleLetter:
      'El apellido no puede tener palabras de una sola letra',
    firstNameSingleLetter: 'El nombre no puede tener palabras de una sola letra'
  },
  ssnField: {
    show: 'Mostrar',
    hide: 'Ocultar'
  }
}

export const zhCN = {
  greetings: {
    hello: '你好'
  },
  applicant: {
    incomeFields: {
      // TODO
    }
  }
}

export default {
  enUS,
  esUS,
  zhCN,
  // Always export one of your locales as the base (two-character) language
  en: enUS,
  es: esUS,
  zh: zhCN
}
