// @flow
import React, { useContext } from 'react'
import {
  CircularProgress,
  Grid,
  Paper,
  Divider,
  Typography
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import cx from 'classnames'
import get from 'lodash/get'
import noop from 'lodash/noop'
import { CIRCULAR_PROGRESS_SIZE, NO_ELEVATION } from 'properties/properties'
import TranslationsContext from 'util/TranslationsContext'
import l10n from 'properties/translations'
import { updatePaymentPlansLdFlagEnabled } from 'util/FeatureHelper'
import styles from './LoanProductInfoBox.styles'
import withLaunchDarkly from '../../../components/shared/LaunchDarklyHOC'
import MonthlyPaymentView from './MonthlyPaymentView'
import {
  getPromoPeriodText,
  getNonPromoPeriodText
} from './LoanProductInfoBoxHelper'

type renderMonthlyPaymentParams = {
  loanProduct: Object,
  estimatedMonthlyPayment: number,
  isLoadingMonthlyPayment: boolean,
  estimatedMonthlyPaymentPromo: number,
  ignorePromo?: boolean,
  classes: Object,
  ldFlags: Object
}
const RenderMonthlyPayment = ({
  loanProduct,
  estimatedMonthlyPayment,
  isLoadingMonthlyPayment = false,
  estimatedMonthlyPaymentPromo,
  ignorePromo = false,
  classes,
  ldFlags
}: renderMonthlyPaymentParams) => {
  const l10n = useContext(TranslationsContext)
  return (
    <Grid item xs={6}>
      <Grid
        container
        justify="flex-start"
        classes={{ container: classes.productCostContainer }}
      >
        <Grid item>
          <Typography
            data-testid="loan.monthlyPayment"
            className={classes.productCostText}
          >
            <MonthlyPaymentView
              loanProduct={loanProduct}
              estimatedMonthlyPaymentPromo={estimatedMonthlyPaymentPromo}
              estimatedMonthlyPayment={estimatedMonthlyPayment}
              isLoading={isLoadingMonthlyPayment}
              ignorePromo={ignorePromo}
              classes={classes}
              ldFlags={ldFlags}
            />
            {!isLoadingMonthlyPayment &&
              l10n.apply.formStatus.approved.estPerMonth}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}

const FinancePlanPeriod = ({
  numMonthlyPaymentsPromo = 0,
  loanMonthTerm = 0,
  promoPeriod = 0,
  updatePaymentPlansFlagEnabled = false,
  servicePlanIsAvailable
}) => {
  const l10n = useContext(TranslationsContext)
  const { approved } = l10n.apply.formStatus

  const promoText = getPromoPeriodText(
    loanMonthTerm,
    numMonthlyPaymentsPromo,
    promoPeriod,
    updatePaymentPlansFlagEnabled,
    servicePlanIsAvailable,
    l10n
  )

  const nonPromoText = getNonPromoPeriodText(
    loanMonthTerm,
    promoPeriod,
    updatePaymentPlansFlagEnabled,
    servicePlanIsAvailable,
    l10n
  )

  const periodText = numMonthlyPaymentsPromo ? promoText : nonPromoText

  return updatePaymentPlansFlagEnabled ? (
    <>
      <span>{periodText}</span>
    </>
  ) : (
    <>
      <span>{numMonthlyPaymentsPromo || loanMonthTerm}</span>
      {`${approved.month} `}
      {numMonthlyPaymentsPromo &&
        approved.promoPeriod(updatePaymentPlansFlagEnabled)}
    </>
  )
}

const renderBottomPeriod = (
  interestRate: number = 0,
  loanMonthTerm: number = 0,
  promoPeriod: number = 0,
  updatePaymentPlans: boolean = false
) => {
  if (updatePaymentPlans) {
    return (
      <>
        <span>
          {l10n.apply.formStatus.approved.yearsRate(
            loanMonthTerm,
            promoPeriod,
            !!promoPeriod
          )}
        </span>

        {l10n.apply.formStatus.approved.aprInclusiveOfPromo(
          interestRate,
          promoPeriod,
          updatePaymentPlans
        )}
      </>
    )
  }
  return (
    <>
      <span>
        {l10n.apply.formStatus.approved.yearsRate(
          loanMonthTerm,
          promoPeriod,
          !!promoPeriod
        )}
      </span>
      {l10n.apply.formStatus.approved.aprInclusiveOfPromo(
        interestRate,
        promoPeriod,
        updatePaymentPlans
      )}
    </>
  )
}

const RenderNoPromoRate = (
  classes: Object,
  updatePaymentPlans: boolean = false,
  loanMonthTerm: number = 0,
  promoPeriod: number = 0,
  interestRate: number = 0
) => {
  const l10n = useContext(TranslationsContext)
  return (
    <Typography data-testid="loan.rate" className={classes.bottomText}>
      {!updatePaymentPlans &&
        l10n.apply.formStatus.approved.yearsRate(
          loanMonthTerm,
          promoPeriod,
          !!promoPeriod
        )}
      {l10n.apply.formStatus.approved.bottomTextRate(interestRate)}
    </Typography>
  )
}

MonthlyPaymentView.defaultProps = {
  ignorePromo: false
}

const LoanProductInfoBox = withStyles(styles)(
  ({
    classes,
    onClick,
    loanProduct,
    loanProduct: { numMonthlyPaymentsPromo, loanMonthTerm, promoPeriod } = {},
    isSelected,
    isLoadingMonthlyPayment,
    isLoadingFinancePlan,
    estimatedMonthlyPayment,
    estimatedMonthlyPaymentPromo,
    disabled,
    appearsInModal = false,
    ldFlags,
    servicePlans
  }) => {
    const servicePlanIsAvailable = servicePlans && servicePlans.length > 0
    const interestRate = get(loanProduct, 'interestRate', 0)
    const l10n = useContext(TranslationsContext)
    const servicePlanContainerStyles: any = servicePlanIsAvailable && {
      direction: 'column',
      alignItems: 'center',
      justify: 'center'
    }

    const updatePaymentPlans = updatePaymentPlansLdFlagEnabled(ldFlags)

    return (
      <Paper
        elevation={NO_ELEVATION}
        onClick={disabled ? noop : onClick}
        classes={{
          root: cx(classes.row, {
            [classes.rowSelected]: isSelected,
            [classes.rowReadonly]: disabled,
            [classes.rowReadonlyAndSelected]: isSelected && disabled
          })
        }}
      >
        {isLoadingFinancePlan ? (
          <Grid container justify="space-around" alignItems="center">
            <Grid item>
              <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
            </Grid>
          </Grid>
        ) : (
          <Grid container spacing={1} {...servicePlanContainerStyles}>
            <Grid item xs={6}>
              <Grid
                container
                justify="flex-start"
                classes={{
                  container: cx({
                    [classes.productNumberTextContainer]: appearsInModal,
                    [classes.productNumberTextContainerSlim]: !appearsInModal
                  })
                }}
              >
                <Grid item>
                  <Typography
                    data-testid="loan.period"
                    className={classes.productNumberTextBold}
                  >
                    {servicePlanIsAvailable ? (
                      <span
                        className={
                          !appearsInModal ? classes.marginforLongerText : ''
                        }
                      >
                        <FinancePlanPeriod
                          numMonthlyPaymentsPromo={numMonthlyPaymentsPromo}
                          loanMonthTerm={loanMonthTerm}
                          promoPeriod={promoPeriod}
                          updatePaymentPlansFlagEnabled={updatePaymentPlans}
                          servicePlanIsAvailable={servicePlanIsAvailable}
                        />
                      </span>
                    ) : (
                      <>
                        <FinancePlanPeriod
                          numMonthlyPaymentsPromo={numMonthlyPaymentsPromo}
                          loanMonthTerm={loanMonthTerm}
                          promoPeriod={promoPeriod}
                          updatePaymentPlansFlagEnabled={updatePaymentPlans}
                          servicePlanIsAvailable={servicePlanIsAvailable}
                        />
                      </>
                    )}
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
            {servicePlanIsAvailable
              ? null
              : RenderMonthlyPayment({
                  loanProduct,
                  estimatedMonthlyPayment,
                  isLoadingMonthlyPayment,
                  estimatedMonthlyPaymentPromo,
                  classes,
                  ldFlags
                })}
            <Grid item xs={12} className={classes.dividerPayment}>
              <Divider light />
            </Grid>
            {numMonthlyPaymentsPromo && (
              <Grid item xs={12}>
                <Grid container spacing={1} {...servicePlanContainerStyles}>
                  <Grid item xs={6}>
                    <Grid
                      container
                      justify="flex-start"
                      classes={{
                        container: cx({
                          [classes.productNumberTextContainer]: appearsInModal,
                          [classes.productNumberTextContainerSlim]:
                            !appearsInModal
                        })
                      }}
                    >
                      {servicePlanIsAvailable ? null : (
                        <Grid
                          item
                          classes={{ item: classes.promoPeriodGridItem }}
                        >
                          <Typography
                            data-testid="loan.promo-period"
                            className={classes.productNumberTextBold}
                          >
                            <span>
                              {l10n.apply.formStatus.approved.yearsRate(
                                loanMonthTerm,
                                promoPeriod,
                                !!promoPeriod
                              )}
                            </span>
                          </Typography>
                        </Grid>
                      )}
                    </Grid>
                  </Grid>

                  {servicePlanIsAvailable
                    ? null
                    : RenderMonthlyPayment({
                        loanProduct,
                        estimatedMonthlyPayment,
                        isLoadingMonthlyPayment,
                        estimatedMonthlyPaymentPromo,
                        ignorePromo: true,
                        classes,
                        ldFlags
                      })}
                  {servicePlanIsAvailable ? null : (
                    <Grid item xs={12} className={classes.dividerPayment}>
                      <Divider light />
                    </Grid>
                  )}
                  {servicePlanIsAvailable ? (
                    <Grid
                      item
                      xs={12}
                      className={classes.aprInclusiveOfPromoContainer}
                    >
                      <Typography
                        data-testid="loan.monthlyPayment"
                        className={classes.aprInclusiveOfPromo}
                      >
                        {renderBottomPeriod(
                          interestRate,
                          loanMonthTerm,
                          promoPeriod,
                          updatePaymentPlans
                        )}
                      </Typography>
                    </Grid>
                  ) : (
                    <Grid
                      item
                      xs={12}
                      className={classes.aprInclusiveOfPromoContainer}
                    >
                      <Typography
                        data-testid="loan.monthlyPayment"
                        className={classes.aprInclusiveOfPromo}
                      >
                        {l10n.apply.formStatus.approved.aprInclusiveOfPromo(
                          interestRate,
                          promoPeriod || numMonthlyPaymentsPromo,
                          updatePaymentPlans
                        )}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            )}
            {numMonthlyPaymentsPromo <= 0 && (
              <Grid item xs={12} className={classes.sacMessageContainer}>
                {RenderNoPromoRate(
                  classes,
                  updatePaymentPlans,
                  loanMonthTerm,
                  !!promoPeriod,
                  interestRate
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Paper>
    )
  }
)

export default withLaunchDarkly(LoanProductInfoBox)
