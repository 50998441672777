// @flow
import React, {
  useContext,
  useEffect,
  useState,
  useRef,
  useCallback
} from 'react'
import { flow, get, isEmpty } from 'lodash'
import NumberFormat from 'react-number-format'
import {
  Grid,
  Button,
  Paper,
  Typography,
  CircularProgress
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import Dialog from 'components/shared/Dialog'
import StringHelper from 'util/StringHelper'
import l10n from 'properties/translations'
import { Query } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { gsap, TweenLite } from 'gsap'
import {
  EMPTY_STRING,
  DOLLAR_PREFIX,
  CIRCULAR_PROGRESS_SIZE,
  MINIMUM_LOAN_AMOUNT,
  DEFAULT_ELEVATION,
  EMPTY_ARRAY,
  EMPTY_OBJECT
} from 'properties/properties'
import {
  selectProjectDataWithTypename,
  getProjectWithAddOns,
  getMonthlyPayment,
  showCombinedAmount,
  reduceProjectAndStageAmounts,
  selectProjectsDataForMutation,
  isProjectAmountValid,
  safeLoanAmount
} from 'util/ApplyHelper'
import {
  currentlySupportedLoanTypes,
  filterLoanProducts,
  getMinimumLoanAmount,
  getBorrowingLimit,
  currentProjectsEnums,
  getProductsFieldName
} from 'util/LoanProductHelper'
import userTypes from 'constants/enums/userTypes'
import SuperScriptAmount from 'components/shared/SuperScriptAmount'
import lenders from 'constants/enums/lenders'
import { calculateServicePlanAmount } from 'util/MathHelper'
import { updatePaymentPlansLdFlagEnabled } from 'util/FeatureHelper'
import QuoteContext, {
  defaultChannelPartnerProjectConfiguration
} from 'util/QuoteContext'
import ApplyContext from 'util/ApplyContext'
import type {
  ServicePlan as ServicePlanType,
  ChannelPartnerAddOn,
  ChannelPartnerProjectConfiguration,
  CustomerProjectConfiguration,
  ProjectAddOn
} from 'screens/quote/QuoteTypes'
import UserContext from 'util/UserContext'
import GetMonthlyPayment from 'screens/apply/queries/GetMonthlyPayment.graphql'
import Projects, {
  projectHasInvalidStages
} from 'screens/quote/components/inputs/Projects'
import styles from './ProductSelectionModal.styles'
// @todo: move the globals graphql queries to a global directory
import GetCreditApplication from 'screens/apply/queries/GetCreditApplication.graphql'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import ApprovedLoanAmount from './ApprovedLoanAmount'
import ProjectAmount from './ProjectAmount'
import {
  buildLoanProduct,
  MonthlyPaymentProps,
  Project,
  getLabel,
  findLoanProduct,
  isSelectedLoanProductIncompatibleWithBatteryOnly,
  isBatteryOnly,
  isProjectNameValid,
  getProjectsWithServicePlan,
  hasProjectsWithAmount,
  getAddonsOfTypeServicePlan,
  removeTypeName,
  getMinBorrowingLimit,
  findServicePlanWithCode,
  buildAddOnObject,
  getExistingProjectWithServicePlan
} from 'util/ProductSelectionModalHelper'
import FinancePlansSection from './FinancePlansSection'
import { GET_QUOTE, QuoteStore } from 'screens/quote/QuoteStore'
import useAccessControl from 'util/useAccessControl'

gsap.registerPlugin(TweenLite)

type Props = {
  handleSave: Function,
  client: {
    query: Function
  },
  id: string,
  quote: Object,
  isOpen: boolean,
  classes: Object,
  toggleModal: () => void,
  lender: Object,
  ldFlags: Object,
  lender: string,
  creditApplication: {
    decision: {
      loanProducts: Array<{
        loanProductId: Object,
        borrowingLimit: Number,
        minimumAmount: Number
      }>
    },
    financingLimit: Number
  }
}

const MonthlyPayment = ({
  classes,
  label,
  value,
  notAvailable
}: MonthlyPaymentProps) => (
  <>
    <Typography
      className={classes.totalMonthlyPaymentColor}
      variant="caption"
      align="center"
    >
      {label}
    </Typography>
    <Typography
      className={classes.totalMonthlyPaymentColor}
      align="center"
      variant="h6"
    >
      {notAvailable ? (
        l10n.apply.productSelection.amountNotAvailable
      ) : (
        <NumberFormat
          value={value}
          prefix={DOLLAR_PREFIX}
          displayType="text"
          thousandSeparator
          renderText={text => <SuperScriptAmount price={text} />}
        />
      )}
    </Typography>
  </>
)

const ProductSelectionModal = (props: Props) => {
  const userContext = useContext(UserContext)
  const {
    loading: loadingPermissions,
    error: errorOnPermissions,
    hasAccess
  } = useAccessControl([userTypes.installer])

  const [totalMonthlyPaymentState, setTotalMonthlyPaymentState] =
    useState<Object>(0)
  const [selectedLoanProductId, setSelectedLoanProductId] = useState<string>(
    get(props, 'quote.loanProductId')
  )
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [cancelling, setCancelling] = useState<boolean>(false)
  const [projects, setProjects] = useState<Object>([])
  const [loanProductsWithMonthlyPayments, setLoanProductsWithMonthlyPayments] =
    useState<Object>([])

  const [serviceOption, setServiceOption] = useState<number>(0)
  const [servicePlanSelected, setServicePlanSelected] = useState<boolean>(false)
  const [isLoadingMonthlyPayments, setIsLoadingMonthlyPayments] =
    useState<boolean>(false)
  const [servicePlanAmount, setServicePlanAmount] = useState<Object>(0)
  const [addOnsArray, setAddOnsArray] = useState<ProjectAddOn[]>([])
  const [servicePlans, setServicePlans] = useState<ServicePlanType[]>([])
  const [numberOfPromoMonths, setNumberOfPromoMonths] = useState<Object>(null)
  const [initialProjects, setInitialProjects] = useState<Project[]>([])
  const [persistMonthlyPayment, setPersistMonthlyPayment] =
    useState<boolean>(false)
  const [servicePlanDropDownOpen, setServicePlanDropDownOpen] =
    useState<boolean>(false)
  const [channelPartnerAddOns, setChannelPartnerAddOns] = useState<
    ChannelPartnerAddOn[]
  >([])
  const [customerProjectConfiguration, setCustomerProjectConfiguration] =
    useState<CustomerProjectConfiguration>({})

  const [
    channelPartnerProjectConfiguration,
    setChannelPartnerProjectConfiguration
  ] = useState<ChannelPartnerProjectConfiguration>({})

  const monthlyPaymentRef: ?React$ElementRef<any> = useRef(null)
  const persistMonthlyPaymentRef: ?React$ElementRef<any> = useRef(null)

  const {
    classes,
    client,
    id,
    isOpen,
    ldFlags,
    lender,
    quote = {},
    toggleModal,
    creditApplication,
    handleSave
  } = props

  const setTotalMonthlyPayment = (
    selectedLoanProductId,
    isLoanProductClicked = false,
    _loanProductsWithMonthlyPayments = loanProductsWithMonthlyPayments
  ) => {
    const monthlyPayment = getMonthlyPayment(
      _loanProductsWithMonthlyPayments,
      selectedLoanProductId,
      ldFlags
    )

    const newMonthlyPayment = parseFloat(monthlyPayment).toFixed(2)

    if (monthlyPayment === null) return

    setTotalMonthlyPaymentState(newMonthlyPayment)
  }

  const projectsWithServicePlanAvailable = (
    addOnProjectTypes,
    selectedProjects
  ) => {
    if (!isEmpty(addOnProjectTypes)) {
      const projectsWithServicePlan = getProjectsWithServicePlan(
        servicePlans,
        selectedProjects
      )

      if (projectsWithServicePlan.length === 0) {
        return false
      }
    }
    return true
  }

  const createServicePlanAddons = async (numberOfPlans: Object) => {
    const channelPartner = get(userContext, 'channelPartner', EMPTY_OBJECT)
    const channelPartnerAddOns = get(channelPartner, 'addOns')

    const servicePlan =
      channelPartnerAddOns && findServicePlanWithCode(channelPartnerAddOns)

    if (!servicePlan) {
      return []
    }
    const addOnsArray = []

    for (let i = 0; i < numberOfPlans; i++) {
      addOnsArray.push(
        buildAddOnObject(
          l10n.quote.servicePlan.code,
          Number(servicePlan.defaultPrice)
        )
      )
    }

    return addOnsArray
  }
  const getMonthlyPaymentsOnChange = useCallback(async () => {
    const customerId = id

    try {
      setIsLoadingMonthlyPayments(true)

      const result = await client.query({
        query: GET_QUOTE
      })

      const projects = get(result, 'data.quote.configuration.projects', [])

      setProjects(projects)

      const projectHasServicePlan = projectsWithServicePlanAvailable(
        servicePlans,
        projects
      )

      if (
        typeof projectHasServicePlan !== 'undefined' &&
        projectHasServicePlan === false
      ) {
        setServicePlanAmount(0)
        setServiceOption(0)
        setServicePlanSelected(false)
      }

      const loanAmount = reduceProjectAndStageAmounts(projects)
      const loanAmountToQuery = safeLoanAmount(loanAmount)

      if (loanAmountToQuery < MINIMUM_LOAN_AMOUNT) {
        setIsLoadingMonthlyPayments(false)
        return
      }

      const { data } = await client.query({
        fetchPolicy: 'no-cache',
        query: GetMonthlyPayment,
        variables: {
          customerId,
          loanAmount: loanAmountToQuery
        }
      })

      const productsFieldName = getProductsFieldName(ldFlags)
      const loanProductsWithMonthlyPayments = get(
        data,
        `customer.creditApplication.${productsFieldName}`,
        []
      )

      setIsLoadingMonthlyPayments(false)
      setLoanProductsWithMonthlyPayments(loanProductsWithMonthlyPayments)
      setTotalMonthlyPayment(
        selectedLoanProductId,
        true,
        loanProductsWithMonthlyPayments
      )
    } catch (e) {
      setIsLoadingMonthlyPayments(false)
    }
  }, [
    id,
    projectsWithServicePlanAvailable,
    selectedLoanProductId,
    servicePlans
  ])

  const handleProjectConfig = async () => {
    const channelPartner = get(userContext, 'channelPartner', EMPTY_OBJECT)

    const channelPartnerProjectConfiguration: ChannelPartnerProjectConfiguration =
      get(channelPartner, 'projectConfiguration', {})

    try {
      const {
        data: {
          customer: { projectConfiguration }
        }
      } = await client.query({
        query: GetCreditApplication,
        variables: { id }
      })

      const customerProjectConfiguration: CustomerProjectConfiguration =
        projectConfiguration

      setCustomerProjectConfiguration(customerProjectConfiguration)
      setChannelPartnerProjectConfiguration(channelPartnerProjectConfiguration)
    } catch (e) {
      // noop
    }
  }

  const servicePlanCallback = useCallback(async () => {
    // Check for serviceplan data, if available, calculate total service plan amount

    const addOnsArray = await createServicePlanAddons(Number(serviceOption))

    if (addOnsArray.length > 0) {
      const servicePlanAmount = calculateServicePlanAmount(addOnsArray)
      // Updating service plan amount here

      setServicePlanAmount(servicePlanSelected ? servicePlanAmount : 0)
      setAddOnsArray(addOnsArray)
      setTotalMonthlyPayment(selectedLoanProductId, true)
    }
  }, [selectedLoanProductId, serviceOption, servicePlanSelected])

  const monthlyPaymentScroll = useCallback(
    (e, offset = 48) => {
      if (!monthlyPaymentRef || monthlyPaymentRef.current == null) return

      const { top } = monthlyPaymentRef.current.getBoundingClientRect()
      const isInViewport =
        top + offset >= 0 && top - offset <= window.innerHeight
      if (isInViewport && persistMonthlyPayment) {
        setPersistMonthlyPayment(false)
      }
      if (top > 42) {
        setPersistMonthlyPayment(false)
      } else if (!isInViewport && !persistMonthlyPayment) {
        setPersistMonthlyPayment(true)
        TweenLite.to(persistMonthlyPaymentRef.current, 0.1, {
          opacity: '1',
          ease: 'Circ.easeIn'
        })
      }
    },
    [persistMonthlyPayment]
  )

  useEffect(() => {
    servicePlanCallback()
  }, [servicePlanSelected, serviceOption])

  const checkLoanProSelectionForServicePlan = async (
    selectedLoanProduct,
    id,
    setServicePlanLoanProductSelected
  ) => {
    const numberOfPromoMonths = get(
      selectedLoanProduct,
      'numMonthlyPaymentsPromo'
    )

    setNumberOfPromoMonths(numberOfPromoMonths)
    setServicePlanSelected(false)
    setServiceOption(0)

    const servicePlanAllowed = get(selectedLoanProduct, 'servicePlanAllowed')
    if (servicePlanAllowed) {
      setServicePlanLoanProductSelected(true)
    } else {
      setServicePlanLoanProductSelected(false)
      setServicePlanSelected(false)
      setServicePlanAmount(0)
    }
    setTotalMonthlyPayment(id, true)
  }

  const handleClickOnServicePlan = (
    selectedLoanProduct,
    loanProductId,
    setServicePlanLoanProductSelected
  ) => {
    checkLoanProSelectionForServicePlan(
      selectedLoanProduct,
      loanProductId,
      setServicePlanLoanProductSelected
    )

    setSelectedLoanProductId(loanProductId)
  }

  const handleServicePlanData = useCallback(async () => {
    const channelPartner = get(userContext, 'channelPartner', EMPTY_ARRAY)
    const initialProjects = get(quote, 'projects', EMPTY_ARRAY)

    setInitialProjects(initialProjects)
    await updateProjects(initialProjects)
    const addOns = channelPartner && get(channelPartner, 'addOns')
    const servicePlans = addOns && getAddonsOfTypeServicePlan(addOns)

    // check if channel partner has service plan active
    if (!isEmpty(servicePlans)) {
      setServicePlans(servicePlans)
      setChannelPartnerAddOns(addOns)
      // Check if we have correct project types selected for service plans
      // if so, get the data and apply that to service plan component
      if (!isEmpty(initialProjects)) {
        const projectWithAddOn = getProjectWithAddOns(initialProjects)

        if (projectWithAddOn) {
          const numberofServicePlanSelected =
            projectWithAddOn.addOns && projectWithAddOn.addOns.length // initialAddons[0] is array of addons

          setServicePlanSelected(true)
          setServiceOption(numberofServicePlanSelected)
        }
      }
    }

    getMonthlyPaymentsOnChange()
  }, [quote, userContext])

  const resetInitialProjects = useCallback(async () => {
    const upToDateProjects = cancelling ? initialProjects : projects
    await updateProjects(upToDateProjects)

    setProjects(upToDateProjects)
  }, [cancelling, initialProjects, projects])

  const handleChangeOnPromoMonths = newPromoMonths => {
    setNumberOfPromoMonths(newPromoMonths)
  }

  useEffect(() => {
    handleServicePlanData()
    handleProjectConfig()
    window.addEventListener('scroll', monthlyPaymentScroll, {
      capture: true
    })

    return () => {
      resetInitialProjects()
      window.removeEventListener('scroll', monthlyPaymentScroll, {
        capture: true
      })
    }
  }, [])

  const onSave =
    (hasAch, projectEstimate, selectedLoanProductId, projects, id) =>
    async () => {
      setIsSubmitting(true)

      const variables = {
        quote: {
          hasAch,
          loanAmount: projectEstimate,
          loanProductId: selectedLoanProductId,
          projects: selectProjectsDataForMutation(projects)
        },
        customerId: id
      }

      if (!isEmpty(addOnsArray)) {
        // Update local project addOns if necessary
        if (servicePlanSelected || serviceOption === 0) {
          const addOnObjectWithoutTypeName = addOnsArray.map(removeTypeName)
          updateProjectsWithAddOns(
            servicePlans,
            variables.quote.projects,
            addOnObjectWithoutTypeName,
            serviceOption
          )

          setProjects(variables.quote.projects)
        }
      }

      await handleSave(variables)

      setIsSubmitting(false)
    }

  // cycle through all the selected projects, find the projectId to attach the addOns object to if exists
  const updateProjectsWithAddOns = (
    addOnProjectTypes,
    selectedProjects,
    addOnsArray,
    serviceOptionCount
  ) => {
    const projectsWithServicePlan = getProjectsWithServicePlan(
      addOnProjectTypes,
      selectedProjects
    )

    if (projectsWithServicePlan.length === 0) {
      return EMPTY_STRING
    }

    const existingProjectWithServicePlan = getExistingProjectWithServicePlan(
      projectsWithServicePlan
    )

    const addOnsArrayToSet = serviceOptionCount === 0 ? [] : addOnsArray

    if (existingProjectWithServicePlan) {
      existingProjectWithServicePlan.addOns = addOnsArrayToSet
      return existingProjectWithServicePlan
    } else {
      projectsWithServicePlan[0].addOns = addOnsArrayToSet
      return projectsWithServicePlan[0]
    }
  }

  const updateProjectsOnParent = async () => {
    const result = await client.query({
      fetchPolicy: 'cache-only',
      query: GET_QUOTE
    })

    const projects = get(result, 'data.quote.configuration.projects', [])

    setProjects(projects)
  }

  const getLoanProducts = () => {
    const projectTypes = get(customerProjectConfiguration, 'projectTypes', [])
    let loanProducts = get(creditApplication, getProductsFieldName(ldFlags), [])

    loanProducts = loanProducts.map(loanProd => buildLoanProduct(loanProd))
    let result = loanProducts

    result = loanProducts.filter(filterLoanProducts(projects, projectTypes))

    return result
  }

  const getLoanProductsAll = () => {
    return get(creditApplication, getProductsFieldName(ldFlags), [])
  }

  const getFinancingLimit = () => {
    return get(creditApplication, 'financingLimit', MINIMUM_LOAN_AMOUNT)
  }

  const updateProjects = async projects => {
    if (!isEmpty(projects)) {
      QuoteStore.updateProjects({
        projects: selectProjectDataWithTypename(projects)
      })
    } else {
      QuoteStore.clearCalculateInput()
    }
  }

  const findSelectedLoanProduct = loanProducts =>
    findLoanProduct(loanProducts, selectedLoanProductId)

  const getApprovedLoanAmount = () => {
    const { borrowingLimit } = findSelectedLoanProduct(getLoanProducts())

    if (borrowingLimit) {
      const isborrowingLimit = getMinBorrowingLimit(
        borrowingLimit,
        getFinancingLimit()
      )

      return isborrowingLimit
    }

    const maxBorrowingLimit = Math.max(
      0,
      ...getLoanProducts().map(lp => lp.borrowingLimit)
    )

    const loanAmountsApproved = getMinBorrowingLimit(
      getFinancingLimit(),
      maxBorrowingLimit
    )

    return loanAmountsApproved
  }

  const checkSaveButtonDisabled = ({
    projectEstimate,
    approvedLoanAmount,
    minimumLoanAmount,
    maximumLoanAmount,
    selectedLoanProductId,
    isLoadingMonthlyPayments
  }) => {
    if (isLoadingMonthlyPayments) return true
    if (!selectedLoanProductId) return true

    if (
      projectEstimate >
      Math.min(parseFloat(approvedLoanAmount), maximumLoanAmount)
    ) {
      return true
    }
    return projectEstimate < minimumLoanAmount
  }

  const determineDisabledMessage = (
    projectEstimate,
    approvedLoanAmount,
    minimumLoanAmount,
    maximumLoanAmount
  ) => {
    const wrapText = StringHelper.wrapTextWithParens

    const props = {
      key: 'number-format',
      prefix: DOLLAR_PREFIX,
      displayType: 'text',
      thousandSeparator: true
    }

    if (!selectedLoanProductId)
      return wrapText(l10n.apply.productSelection.financeProductError)

    if (projectEstimate < minimumLoanAmount) {
      return wrapText(
        l10n.apply.productSelection.underLimit,
        <NumberFormat {...props} value={minimumLoanAmount} />
      )
    }

    const maxLoanAmount = Math.min(
      parseFloat(approvedLoanAmount),
      maximumLoanAmount
    )
    if (projectEstimate > maxLoanAmount) {
      return wrapText(
        l10n.apply.productSelection.overLimitError,
        <NumberFormat {...props} value={maxLoanAmount} />
      )
    }

    return EMPTY_STRING
  }

  const servicePlanDropDownToggle = () => {
    setServicePlanDropDownOpen(!servicePlanDropDownOpen)
  }

  // When the unit amount of the service plan changes
  const changeService = async event => {
    setServiceOption(event.target.value)
    setServicePlanSelected(event.target.value > 0)
    setServicePlanDropDownOpen(false)

    const addOnsArray = await createServicePlanAddons(
      Number(event.target.value)
    )

    if (addOnsArray.length > 0) {
      const servicePlanAmount = calculateServicePlanAmount(addOnsArray)

      setServicePlanAmount(event.target.value > 0 ? servicePlanAmount : 0)
      setAddOnsArray(addOnsArray)
    }
  }

  // detect is projects type has chanaged, eg. from HVAC to other, if so
  // the addOns associated to the project needs to be removed, and the servicePlan
  // amount needs to be updated. Also, if the HVAC project is deleted, but the projects
  // list still contain other HVAC, move the addOns to the other HVAC
  const onProjectsChanged = async () => {
    const addOnsArray = await createServicePlanAddons(Number(serviceOption))
    if (addOnsArray.length > 0) {
      setAddOnsArray(addOnsArray)
    }
  }

  const clearSelectedLoanProduct = () => {
    if (selectedLoanProductId) {
      setSelectedLoanProductId(undefined)
    }
  }

  const renderMonthlyPayment = (
    numberOfPromoMonths,
    classes,
    paymentValue,
    beforeLabel,
    afterLabel,
    notAvailable,
    ldFlags
  ) =>
    numberOfPromoMonths === null ? (
      <div
        className={classes.sectionCtn}
        ref={el => {
          monthlyPaymentRef.current = el
        }}
      >
        <MonthlyPayment
          classes={classes}
          label={l10n.apply.productSelection.totalMonthlyPayment}
          value={paymentValue}
          notAvailable={notAvailable}
          ldFlags={ldFlags}
        />
      </div>
    ) : (
      <div
        ref={el => {
          monthlyPaymentRef.current = el
        }}
      >
        <div className={classes.sectionCtn}>
          <MonthlyPayment
            classes={classes}
            label={beforeLabel}
            value={0}
            ldFlags={ldFlags}
          />
        </div>
        <div className={classes.sectionCtn}>
          <MonthlyPayment
            classes={classes}
            label={afterLabel}
            value={paymentValue}
            notAvailable={notAvailable}
            ldFlags={ldFlags}
          />
        </div>
      </div>
    )

  const hasAch = get(quote, 'hasAch', false)
  const approvedLoanAmount = getApprovedLoanAmount()
  const projectEstimate = reduceProjectAndStageAmounts(projects)

  const minimumLoanAmount = getMinimumLoanAmount(
    getLoanProducts(),
    selectedLoanProductId
  )

  const maximumLoanAmount = getBorrowingLimit(
    getLoanProducts(),
    selectedLoanProductId
  )

  const saveButtonIsDisabled = checkSaveButtonDisabled({
    projectEstimate,
    approvedLoanAmount,
    minimumLoanAmount,
    maximumLoanAmount,
    selectedLoanProductId,
    projects,
    isLoadingMonthlyPayments
  })

  const disabledMessage = determineDisabledMessage(
    projectEstimate,
    approvedLoanAmount,
    minimumLoanAmount,
    maximumLoanAmount
  )

  const hasProjects = hasProjectsWithAmount(projects)

  const cancelAndClose = async () => {
    setCancelling(true)
    toggleModal()
    resetInitialProjects()
  }

  const paymentValue = servicePlanSelected
    ? showCombinedAmount(servicePlanAmount, totalMonthlyPaymentState)
    : totalMonthlyPaymentState

  const getLabelHOF = getLabel(servicePlanSelected, numberOfPromoMonths)
  const beforeLabel = getLabelHOF('combinedBefore', 'before')
  const afterLabel = getLabelHOF('combinedAfter', 'after')

  const foundLoanProduct = findLoanProduct(
    getLoanProductsAll(),
    selectedLoanProductId
  )

  const currentLoanProductType = selectedLoanProductId
    ? foundLoanProduct && foundLoanProduct.loanProductType
    : null

  const selectedLoanProductIncompatible = !currentlySupportedLoanTypes(
    customerProjectConfiguration.projectTypes,
    projects
  ).includes(currentLoanProductType)

  const selectedLoanProduct = findSelectedLoanProduct(getLoanProducts())
  const currentProjectsEnumsArr = currentProjectsEnums(projects)

  const selectedLoanProductIncompatibleWithBatteryOnly =
    isSelectedLoanProductIncompatibleWithBatteryOnly(selectedLoanProduct)

  if (
    (selectedLoanProductId &&
      customerProjectConfiguration.projectTypes &&
      !isEmpty(projects) &&
      selectedLoanProductIncompatible) ||
    (isBatteryOnly(currentProjectsEnumsArr) &&
      selectedLoanProductIncompatibleWithBatteryOnly)
  ) {
    clearSelectedLoanProduct()
  }

  if (loadingPermissions || errorOnPermissions) return null

  return (
    <Dialog
      open={isOpen}
      onClose={cancelAndClose}
      onBackdropClick={cancelAndClose}
      title={l10n.apply.productSelection.title}
    >
      <Query query={GET_QUOTE}>
        {({ data: dataTop }) => {
          const cachedProjects = get(
            dataTop,
            'quote.configuration.projects',
            []
          )

          const channelPartnerProjectConf =
            channelPartnerProjectConfiguration ||
            defaultChannelPartnerProjectConfiguration

          return (
            <ApplyContext.Consumer>
              {applyContext => {
                const isRedirecting = get(applyContext, 'isRedirecting')
                return (
                  <QuoteContext.Provider
                    value={{
                      client,
                      projects: cachedProjects,
                      servicePlans: servicePlans || [],
                      channelPartnerAddOns: channelPartnerAddOns || [],
                      channelPartnerProjectConfiguration:
                        channelPartnerProjectConf,
                      customerProjectConfiguration,
                      // TODO - this is pretty confusing - why is it readonly only when readonly is false?!
                      readonly: !hasAccess || isSubmitting || isRedirecting
                    }}
                  >
                    <Grid container>
                      <Grid item xs={12} className={classes.section}>
                        <Typography
                          variant="body2"
                          paragraph
                          className={classes.bodyTextNormal}
                        >
                          {lender === lenders.WEBBANK
                            ? l10n.apply.productSelection.subHeaderWebbank
                            : l10n.apply.productSelection.subHeader}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} className={classes.section}>
                        <ApprovedLoanAmount
                          classes={classes}
                          amount={approvedLoanAmount}
                        />
                        <ProjectAmount
                          classes={classes}
                          projectEstimate={projectEstimate}
                          lender={lender}
                        />
                        <Grid alignItems="center">
                          <div className={classes.sectionCtn}>
                            {disabledMessage && (
                              <Typography
                                variant="body2"
                                className={classes.errorText}
                                align="center"
                              >
                                {disabledMessage}
                              </Typography>
                            )}
                          </div>
                          {(servicePlans.length > 0 ||
                            updatePaymentPlansLdFlagEnabled(ldFlags)) &&
                            (isLoadingMonthlyPayments ? (
                              <Grid
                                container
                                className={classes.loadingContainer}
                                alignItems="center"
                                justify="center"
                              >
                                <CircularProgress
                                  size={CIRCULAR_PROGRESS_SIZE}
                                />
                              </Grid>
                            ) : (
                              renderMonthlyPayment(
                                numberOfPromoMonths,
                                classes,
                                paymentValue,
                                beforeLabel,
                                afterLabel,
                                Boolean(disabledMessage),
                                ldFlags
                              )
                            ))}
                          {!isLoadingMonthlyPayments && persistMonthlyPayment && (
                            <Grid
                              container
                              ref={el => {
                                persistMonthlyPaymentRef.current = el
                              }}
                              className={classes.persistMonthlyPaymentCtn}
                              justify="center"
                              align="center"
                            >
                              {(() => {
                                if (numberOfPromoMonths) {
                                  return (
                                    <Paper
                                      className={classes.persistMonthlyPayment}
                                      elevation={DEFAULT_ELEVATION}
                                    >
                                      {disabledMessage && (
                                        <Typography
                                          variant="body2"
                                          className={classes.errorText}
                                          align="center"
                                        >
                                          {disabledMessage}
                                        </Typography>
                                      )}
                                      <MonthlyPayment
                                        classes={classes}
                                        label={beforeLabel}
                                        value={0}
                                        ldFlags={ldFlags}
                                      />
                                      <MonthlyPayment
                                        classes={classes}
                                        label={afterLabel}
                                        value={paymentValue}
                                        notAvailable={Boolean(disabledMessage)}
                                        ldFlags={ldFlags}
                                      />
                                    </Paper>
                                  )
                                }
                                return (
                                  <Paper
                                    className={classes.persistMonthlyPayment}
                                    elevation={DEFAULT_ELEVATION}
                                  >
                                    {disabledMessage && (
                                      <Typography
                                        variant="body2"
                                        className={classes.errorText}
                                        align="center"
                                      >
                                        {disabledMessage}
                                      </Typography>
                                    )}
                                    <MonthlyPayment
                                      classes={classes}
                                      label={
                                        l10n.apply.productSelection
                                          .totalMonthlyPayment
                                      }
                                      value={paymentValue}
                                      notAvailable={Boolean(disabledMessage)}
                                      disabledMessage={disabledMessage}
                                      ldFlags={ldFlags}
                                    />
                                  </Paper>
                                )
                              })()}
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item xs={12}>
                        <Projects
                          hideTitle
                          getMonthlyPaymentsOnChange={
                            getMonthlyPaymentsOnChange
                          }
                          updateProjectsOnParent={updateProjectsOnParent}
                          customerProjectConfiguration={
                            customerProjectConfiguration
                          }
                          editOffer
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <FinancePlansSection
                          servicePlans={servicePlans}
                          disabled={!hasAccess || isSubmitting || isRedirecting}
                          selectedLoanProductId={selectedLoanProductId}
                          setTotalMonthlyPayment={setTotalMonthlyPayment}
                          numberOfPromoMonths={numberOfPromoMonths}
                          serviceOption={serviceOption}
                          loanProducts={getLoanProducts()}
                          loanProductsWithMonthlyPayments={
                            loanProductsWithMonthlyPayments
                          }
                          saveButtonIsDisabled={saveButtonIsDisabled}
                          projectEstimate={projectEstimate}
                          projects={projects}
                          hasProjects={hasProjects}
                          isLoadingMonthlyPayments={isLoadingMonthlyPayments}
                          onProjectsChanged={onProjectsChanged}
                          servicePlanDropDownOpen={servicePlanDropDownOpen}
                          changeService={changeService}
                          servicePlanDropDownToggle={servicePlanDropDownToggle}
                          servicePlanSelected={servicePlanSelected}
                          handleChangeOnPromoMonths={handleChangeOnPromoMonths}
                          checkLoanProSelectionForServicePlan={
                            checkLoanProSelectionForServicePlan
                          }
                          handleClickOnServicePlan={handleClickOnServicePlan}
                        />
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                    >
                      <Grid item className={classes.gridBtnCtn}>
                        <Button
                          fullWidth
                          variant="outlined"
                          color="primary"
                          onClick={cancelAndClose}
                          disabled={isSubmitting || isRedirecting}
                          className={classes.button}
                        >
                          <Typography>
                            {l10n.apply.productSelection.cancel}
                          </Typography>
                        </Button>
                      </Grid>
                      <Grid item className={classes.gridBtnCtn}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="primary"
                          disabled={
                            saveButtonIsDisabled ||
                            isRedirecting ||
                            !hasAccess ||
                            isSubmitting ||
                            isProjectNameValid(projects) ||
                            cachedProjects.some(
                              project =>
                                !isProjectAmountValid(project) ||
                                projectHasInvalidStages(
                                  project,
                                  customerProjectConfiguration
                                )
                            )
                          }
                          onClick={onSave(
                            hasAch,
                            projectEstimate,
                            selectedLoanProductId,
                            projects,
                            id
                          )}
                          className={classes.button}
                        >
                          {isSubmitting || isRedirecting ? (
                            <CircularProgress size={CIRCULAR_PROGRESS_SIZE} />
                          ) : (
                            <Typography>
                              {l10n.apply.productSelection.save}
                            </Typography>
                          )}
                        </Button>
                      </Grid>
                    </Grid>
                  </QuoteContext.Provider>
                )
              }}
            </ApplyContext.Consumer>
          )
        }}
      </Query>
    </Dialog>
  )
}

export default flow(
  withApolloConsumer,
  withLaunchDarkly,
  withStyles(styles)
)(ProductSelectionModal)
