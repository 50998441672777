export default theme => ({
  inactiveColor: {
    color: theme.palette.grey[900]
  },
  captionButton: {
    fontSize: 'inherit',
    color: theme.palette.action.link,
    position: 'relative',
    verticalAlign: 'baseline'
  },
  altPhoneText: {
    paddingTop: '15px'
  },
  formFieldLabel: {
    marginBottom: theme.spacing(1)
  },
  labelText: {
    fontWeight: 400
  }
})
