// @flow
import React, { useContext } from 'react'
import NumberFormat from 'react-number-format'
import { ButtonBase, FormHelperText } from '@material-ui/core'
import cx from 'classnames'
import TextValidator from 'components/shared/TextValidator'
import TranslationsContext from 'util/TranslationsContext'
import placeholders from 'properties/placeholders'
import { DOLLAR_PREFIX } from 'properties/properties'
import customerFields from 'constants/enums/customerFields'

/* Values to display for customer in demo mode */
const DEMO_MODE_DISPLAY_VALUES = {
  personalIncome: '$XX,XXX'
}

type Props = {
  classes: classesShape,
  demoMode: boolean,
  isRequiredField: (fieldName: string) => boolean,
  getErrorMessagesRequired: (fieldName: string) => string[],
  getValidatorsRequired: (fieldName: string) => string[],
  labelIsRequired: () => boolean,
  onClickReadMorePersonalIncome: func,
  onFieldEdit: func,
  readMoreClicked: boolean,
  disabled?: boolean,
  placeholder?: string,
  incomeAlerts: {
    highPersonalIncomeAlert: boolean,
    lowPersonalIncomeAlert: boolean
  },
  singleDobField?: boolean,
  onKeyPress: Function
}

const PersonalIncomeField = (props: Props) => {
  const {
    classes,
    demoMode,
    isRequiredField,
    getErrorMessagesRequired,
    getValidatorsRequired,
    labelIsRequired,
    onClickReadMorePersonalIncome,
    onFieldEdit,
    readMoreClicked,
    disabled = false,
    placeholder = undefined,
    incomeAlerts: {
      highPersonalIncomeAlert = false,
      lowPersonalIncomeAlert = false
    },
    singleDobField,
    onKeyPress
  } = props
  const l10n = useContext(TranslationsContext)

  return (
    <>
      {demoMode ? (
        <TextValidator
          disabled
          fullWidth
          id={customerFields.personalIncome}
          label={l10n.customer.personalIncome}
          name={customerFields.personalIncome}
          value={DEMO_MODE_DISPLAY_VALUES.personalIncome}
        />
      ) : (
        <NumberFormat
          id={customerFields.personalIncome}
          type="tel"
          fullWidth
          allowNegative={false}
          decimalScale={0}
          thousandSeparator
          customInput={TextValidator}
          prefix={DOLLAR_PREFIX}
          label={l10n.customer.personalIncome}
          onChange={onFieldEdit(customerFields.personalIncome)}
          name={customerFields.personalIncome}
          placeholder={placeholder || placeholders.personalIncome}
          validators={getValidatorsRequired(customerFields.personalIncome)}
          errorMessages={getErrorMessagesRequired(
            customerFields.personalIncome,
            l10n
          )}
          required={isRequiredField(customerFields.personalIncome)}
          InputLabelProps={{
            required: labelIsRequired(),
            shrink: singleDobField ? true : undefined
          }}
          disabled={disabled}
          data-testid="personal-income"
          className={cx({
            [classes.outOfRangeInput]:
              highPersonalIncomeAlert || lowPersonalIncomeAlert
          })}
          InputProps={{ onKeyPress }}
        />
      )}
      <FormHelperText>
        <>
          {highPersonalIncomeAlert && (
            <span className={classes.outOfRangeAlert}>
              {l10n.customer.highPersonalIncomeAlert}
            </span>
          )}
          {lowPersonalIncomeAlert && (
            <span className={classes.outOfRangeAlert}>
              {l10n.customer.lowPersonalIncomeAlert}
            </span>
          )}
          {l10n.customer.personalIncomeHelpText}
          {!readMoreClicked && (
            <ButtonBase
              onClick={onClickReadMorePersonalIncome}
              classes={{ root: classes.captionButton }}
            >
              {l10n.customer.readMore}
            </ButtonBase>
          )}
          {readMoreClicked && (
            <span>{l10n.customer.personalIncomeReadMoreText}</span>
          )}
        </>
      </FormHelperText>
    </>
  )
}

export default PersonalIncomeField
