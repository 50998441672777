// @flow
import { v4 as uuidv4 } from 'uuid'
import { isEmpty, isUndefined, get, omitBy } from 'lodash'
import { quoteErrors, quoteErrorTypes } from 'constants/enums/quoteErrors'
import { calculateLumpSumPayment } from 'util/MathHelper'
import {
  stagesTotal,
  safeLoanAmount,
  isSolarOrBatteryProject,
  reduceProjectAndStageAmounts
} from 'util/ApplyHelper'
import {
  isPromoProduct,
  getMinimumLoanAmount,
  getMaximumLoanAmount
} from 'util/LoanProductHelper'
import type { CalculateOutputType, ProjectAddOn } from './QuoteTypes'
import { inputDefaults, outputDefaults, QuoteDefaults } from './QuoteDefaults'
import { makeVar, gql } from '@apollo/client'
import projectFields from 'queries/fragments/projectFields.graphql'
import loanProductFields from 'queries/fragments/loanProductFields.graphql'
import { typenames } from 'properties/properties'

const DEFAULT_QUOTE = {
  __typename: 'quote',
  error: [quoteErrors[quoteErrorTypes.NO_LOAN_PRODUCT]],
  view: '',
  configuration: {
    __typename: 'quote_configuration',
    loanProducts: [],
    projects: []
  },
  schedule: {
    output: {
      years: []
    }
  },
  calculate: {
    __typename: 'quote_calculate',
    input: {
      ...inputDefaults,
      __typename: 'quote_calculate_input'
    },
    output: {
      ...outputDefaults,
      __typename: 'quote_calculate_output'
    }
  },
  pristine: true
}

type Message = {
  text: any,
  timeout: any
}

const MESSAGE_DEFAULTS: Message = {
  text: null,
  timeout: null,
  __typename: 'Message'
}

export const quote: any = makeVar(DEFAULT_QUOTE)
export const message: any = makeVar<Message>(MESSAGE_DEFAULTS)

type UpdateProjectAddonsInput = {
  id: string,
  addOns: ProjectAddOn
}

// Function to generate the default project shape, calculating a unique ID each time
const getDefaultProjectShape = () => {
  return {
    __typename: typenames.Project,
    id: uuidv4(),
    projectType: '', // default value
    amount: null,
    notes: '',
    status: null,
    projectName: null,
    addOns: [],
    stages: []
  }
}

/**
 * Given an existing quote object, returns an object with the parameter data
 * inserted into the "input" child-object.
 *
 * @param existing
 * @param input
 * @returns {{quote: {calculate: {input: {}}}}}
 */

export function constructInputData(existing, input) {
  return {
    ...existing,
    calculate: {
      ...existing.calculate,
      input: {
        ...existing.calculate.input,
        ...input
      }
    }
  }
}

/**
 * Given an existing configuration object, returns an object with the parameter data
 * inserted into the "configuration" child-object.
 *
 * @param existing
 * @param input
 * @returns {{quote: {calculate: {input: {}}}}}
 */

export function constructConfigurationData(existing, input) {
  return {
    ...existing,
    configuration: {
      ...existing.configuration,
      ...input
    }
  }
}

/**
 * Given an input field name, returns a function that will write the value
 * of that field to the quote configuration.
 *
 * @param fieldName
 * @returns {function(*, *, {cache: *})}
 */
function resolveCalculateConfiguration(fieldName: string) {
  return args => {
    const existing = { ...quote() }

    const updatedQuote2 = constructConfigurationData(existing, {
      [fieldName]: args[fieldName]
    })

    quote(updatedQuote2)

    return null
  }
}

/**
 * Given an input field name, returns a function that will write the value
 * of that field to the quote calculator input cache.
 *
 * @param fieldName
 * @returns {function(*, *, {cache: *})}
 */
function resolveCalculateInputField(fieldName: string) {
  return args => {
    const loanProducts = get(quote(), 'configuration.loanProducts', [])
    const calculateInput = { ...quote().calculate.input }

    const isPromo = Boolean(
      loanProducts.find(
        lp => lp.id === calculateInput.loanProductId && isPromoProduct(lp)
      )
    )

    const safePayDownPercentage = get(args, 'payDownPercentage', 0)

    const lumpSumPayment = calculateLumpSumPayment(
      calculateInput.principal,
      safePayDownPercentage >= 0
        ? safePayDownPercentage
        : calculateInput.payDownPercentage,
      isPromo
    )

    const updatedQuote = constructInputData(quote(), {
      lumpSumPayment: lumpSumPayment,
      [fieldName]: args[fieldName]
    })

    quote(updatedQuote)

    return null
  }
}

function resolveCalculateOutput() {
  return (calculateOutput: CalculateOutputType) => {
    const updatedQuote = {
      ...quote(),
      calculate: {
        ...quote().calculate,
        output: {
          ...quote().calculate.output,
          ...calculateOutput
        }
      }
    }

    quote(updatedQuote)

    return null
  }
}

function resolveCalculateInput() {
  return calculateInput => {
    const { projects } = calculateInput
    delete calculateInput.projects // eslint-disable-line no-param-reassign
    const existing = get(quote(), 'calculate.input')

    const updatedCalculateInput = {
      ...quote().calculate.input,
      loanProductId: calculateInput.loanProductId,
      principal: existing.principal
    }

    const updatedQuote = {
      ...quote(),
      configuration: {
        ...quote().configuration,
        projects
      },
      calculate: {
        ...quote().calculate,
        input: updatedCalculateInput
      }
    }

    quote(updatedQuote)

    QuoteStore.checkLoanAmount()

    return null
  }
}

const defaultResolveClearCalculateInputOptions = {
  clearProjects: true
}

function resolveClearCalculateInput(
  defaultOptions = defaultResolveClearCalculateInputOptions
) {
  return (args: any) => {
    const shouldClear = defaultOptions.clearProjects || args.reset
    const existing = get(quote(), 'calculate')

    if (existing) {
      const input = shouldClear ? inputDefaults : existing.input

      const updatedQuote = {
        ...quote(),
        pristine: true,
        calculate: {
          ...quote().calculate,
          input: { ...quote().calculate.input, ...input },
          output: { ...quote().calculate.output, ...outputDefaults }
        }
      }

      quote(updatedQuote)
    }

    let projects = [...get(quote(), 'configuration.projects')]

    const temp = getDefaultProjectShape()

    if (shouldClear || isEmpty(projects)) {
      projects = [temp]
    }

    const updatedQuote = {
      ...quote(),
      configuration: {
        ...quote().configuration,
        projects
      }
    }

    quote(updatedQuote)

    return null
  }
}

function resolveCheckLoanAmount() {
  return () => {
    const data = quote()

    const {
      calculate: { input: { loanProductId } = {} } = {},
      configuration: { loanProducts = [], projects: projects2 = [] } = {}
    } = data

    const minimumLoanAmount = getMinimumLoanAmount(loanProducts, loanProductId)
    const maximumLoanAmount = getMaximumLoanAmount(loanProducts, loanProductId)

    const dollars = reduceProjectAndStageAmounts(projects2)
    const safeDollars = Number.isNaN(dollars) ? 0 : dollars

    if (data.error) {
      data.error = data.error.filter(
        error =>
          error.type !== quoteErrorTypes.EXCEEDS_MAXIMUM_LOAN_AMOUNT &&
          error.type !== quoteErrorTypes.BELOW_MINIMUM_LOAN_AMOUNT
      )
    } else {
      data.error = []
    }

    if (safeDollars < minimumLoanAmount) {
      data.error.push(
        quoteErrors[quoteErrorTypes.BELOW_MINIMUM_LOAN_AMOUNT](
          minimumLoanAmount
        )
      )
    } else if (safeDollars > maximumLoanAmount) {
      data.error.push(quoteErrors[quoteErrorTypes.EXCEEDS_MAXIMUM_LOAN_AMOUNT])
    }

    quote({ ...quote(), error: data.error })

    QuoteStore.updateLoanAmount({ principal: safeLoanAmount(safeDollars) })

    return data
  }
}

function resolveCheckLoanProduct() {
  return ({ loanProductId }) => {
    const errorData = quote().error

    if (errorData.error) {
      errorData.error = errorData.error.filter(
        error => error.type !== quoteErrorTypes.NO_LOAN_PRODUCT
      )
    } else {
      errorData.error = []
    }
    if (
      loanProductId < 0 ||
      loanProductId === null ||
      loanProductId === undefined
    ) {
      errorData.error.push(quoteErrors[quoteErrorTypes.NO_LOAN_PRODUCT])
    }

    quote({ ...quote(), error: errorData.error })

    return null
  }
}

function resolveClearMessage() {
  message(QuoteDefaults.message)

  return null
}

function resolveSetMessage(_message: Message) {
  message(_message)

  return null
}

export const GET_QUOTE = gql`
  query getQuote {
    quote @client {
      error {
        type
        value
      }
      configuration {
        projects {
          ...projectFields
        }
        loanProducts {
          ...loanProductFields
        }
      }
      calculate {
        input {
          lumpSumPayment
          principal
          loanProductId
          hasAchDiscount
          servicePlanAmount
        }
        output {
          periodPaymentDollars
          reAmortizedPeriodPaymentDollars
          interestAvoidedDollars
        }
      }
      pristine
    }
  }

  ${projectFields}
  ${loanProductFields}
`

export const GET_INPUTS = gql`
  query getInputs {
    quote @client {
      configuration {
        loanProducts {
          ...loanProductFields
        }
      }
      calculate {
        input {
          loanProductId
          principal
          lumpSumPayment
          hasAchDiscount
        }
      }
    }
  }

  ${loanProductFields}
`

export const GET_MESSAGE_QUERY = gql`
  query getMessage {
    message @client {
      text
      timeout
    }
  }
`

const findProjectIndex = (projects, idToFind) =>
  projects.findIndex(proj => proj.id === idToFind)

export const QuoteStore = {
  updateLoanAmount: resolveCalculateInputField('principal'),
  updateDiscounts: resolveCalculateInputField('hasAchDiscount'),
  updateLumpSumPayment: resolveCalculateInputField('lumpSumPayment'),
  updateLoanProductId: resolveCalculateInputField('loanProductId'),
  updatePayDownPercentage: resolveCalculateInputField('payDownPercentage'),
  updateServicePlanAmount: resolveCalculateInputField('servicePlanAmount'),
  updateCalculateOutput: resolveCalculateOutput(),
  updateCalculateInput: resolveCalculateInput(),
  clearCalculateInput: resolveClearCalculateInput(),
  bootstrapCalculateInput: resolveClearCalculateInput({
    clearProjects: false
  }),
  clearMessage: resolveClearMessage,
  setMessage: resolveSetMessage,
  checkLoanAmount: resolveCheckLoanAmount(),
  updateLoanProducts: resolveCalculateConfiguration('loanProducts'),
  updateProjects: resolveCalculateConfiguration('projects'),
  checkLoanProduct: resolveCheckLoanProduct(),
  addProject: ({ id, amount, projectType, notes, projectName }) => {
    const newProject = {
      ...getDefaultProjectShape(),
      __typename: typenames.Project,
      id,
      amount,
      projectType,
      notes,
      projectName
    }

    quote({
      ...quote(),
      configuration: {
        ...quote().configuration,
        projects: [...quote().configuration.projects, newProject]
      }
    })
  },
  deleteProject: ({ id }) => {
    const projects = get(quote(), 'configuration.projects', [])
    const projectToDelete = projects.find(project => project.id === id)

    if (!isEmpty(projectToDelete.addOns)) {
      const firstOtherProjectOfSameType = quote().configuration.projects.find(
        project => project.projectType === projectToDelete.projectType
      )

      if (firstOtherProjectOfSameType) {
        firstOtherProjectOfSameType.addOns = projectToDelete.addOns
      }
    }

    const updatedProjects = get(quote(), 'configuration.projects', []).filter(
      project => project.id !== id
    )

    quote({
      ...quote(),
      configuration: { ...quote().configuration, projects: updatedProjects }
    })

    QuoteStore.checkLoanAmount()
  },
  deleteStage: ({ id, stageId }) => {
    const projects = get(quote(), 'configuration.projects', [])
    const projectsUpdated = [...projects]
    const projectToModify = projects.find(project => project.id === id)
    const projectCopy = { ...projectToModify }

    if (projectToModify) {
      const filteredStages = projectToModify.stages.filter(
        stage => stage.id !== stageId
      )

      projectCopy.stages = filteredStages
    }

    const index = findProjectIndex(projects, projectCopy.id)

    projectsUpdated[index] = projectCopy

    projectCopy.amount = stagesTotal(
      projectCopy.stages,
      projectCopy.amount,
      isSolarOrBatteryProject(projectCopy.projectType)
    )

    quote({
      ...quote(),
      configuration: { ...quote().configuration, projects: projectsUpdated }
    })
  },

  updateProject: ({
    id,
    amount,
    projectType,
    notes,
    projectName,
    addOns,
    stages
  }) => {
    const existingProject = quote().configuration.projects.find(
      proj => proj.id === id
    )

    let updatedAddOns = addOns
    let projects = get(quote(), 'configuration.projects', [])
    let updatedProjects = [...projects]

    if (
      existingProject &&
      existingProject.projectType !== projectType &&
      !isEmpty(existingProject.addOns)
    ) {
      // Clear out addOns when changing project type,
      // unless there's another place to add it to
      updatedAddOns = []

      const firstOtherAddOnEligibleProject = projects.find(
        project =>
          project.id !== id &&
          project.projectType === existingProject.projectType
      )

      if (firstOtherAddOnEligibleProject) {
        const firstOtherAddOnEligibleProjectTemp = {
          ...firstOtherAddOnEligibleProject
        }
        firstOtherAddOnEligibleProjectTemp.addOns = existingProject.addOns

        updatedProjects = [...projects]

        const firstOtherAddOnEligibleProjectIndex = findProjectIndex(
          updatedProjects,
          firstOtherAddOnEligibleProject.id
        )

        updatedProjects[firstOtherAddOnEligibleProjectIndex] =
          firstOtherAddOnEligibleProjectTemp
      }
    }

    const existingStages = existingProject ? existingProject.stages : []
    const existingAddOns = existingProject ? existingProject.addOns : []

    const data = {
      __typename: typenames.Project,
      id,
      amount: stagesTotal(
        stages || existingStages,
        amount,
        isSolarOrBatteryProject(projectType)
      ),
      projectType: projectType, //|| existingProject.projectType,
      notes: notes, //|| existingProject.notes,
      projectName: projectName, // || existingProject.projectName,
      stages: stages || existingStages,
      addOns: updatedAddOns || existingAddOns
    }
    const dataToWrite = omitBy(data, item => isUndefined(item))
    const projectToUpdateIndex = findProjectIndex(updatedProjects, id)
    const temp = { ...existingProject, ...dataToWrite }
    updatedProjects[projectToUpdateIndex] = temp

    quote({
      ...quote(),
      pristine: false,
      configuration: { ...quote().configuration, projects: updatedProjects }
    })

    QuoteStore.checkLoanAmount()

    return null
  },

  updateProjectAddons: ({ id, addOns }: UpdateProjectAddonsInput) => {
    const projectToUpdate = get(quote(), 'configuration.projects', []).find(
      project => project.id === id
    )

    // projectToUpdate.addOns = addOns

    const temp = { ...projectToUpdate }
    temp.addOns = addOns

    let updatedProjects = get(quote(), 'configuration.projects', []).filter(
      project => project.id !== id
    )

    updatedProjects = !isEmpty(updatedProjects)
      ? [...updatedProjects, temp]
      : [temp]

    quote({
      ...quote(),
      pristine: false,
      configuration: {
        ...quote().configuration,
        projects: updatedProjects
      }
    })

    return null
  }
}
