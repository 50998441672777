import addressTypes from 'constants/enums/addressTypes'
import l10n from 'properties/translations'
import pick from 'lodash/pick'
import addressFields from 'constants/enums/addressFields'

export const getAddressTypeTitle = (key, isToPrimaryresidence) => {
  switch (key) {
    case addressTypes.primary:
      return isToPrimaryresidence
        ? l10n.apply.addressVerification.primaryAddressTitle
        : l10n.apply.addressVerification.projectAddressTitle
    case addressTypes.rental:
    case addressTypes.secondHome:
      return l10n.apply.addressVerification.projectAddressTitle
    case addressTypes.mailing:
      return l10n.apply.addressVerification.mailingAddressTitle
    default:
      return null
  }
}

export const sortAddresses = (firstElement, secondElement) => {
  if (
    firstElement === addressTypes.primary &&
    (secondElement === addressTypes.secondHome ||
      secondElement === addressTypes.rental)
  ) {
    return 1
  }
  if (firstElement === addressTypes.primary) {
    return -1
  }
  if (
    firstElement === addressTypes.secondHome ||
    firstElement === addressTypes.rental
  ) {
    return -1
  }
  if (firstElement === addressTypes.mailing) {
    return 1
  }
  return 0
}

export const getFormattedAddress = (addresses, addressMap) =>
  addresses.length
    ? addresses.map(address => pick(address, [...addressFields, 'addressType']))
    : Array.from(addressMap.values())

const addressHelper = { getAddressTypeTitle, sortAddresses }

export default addressHelper
