import l10n from 'properties/translations'

const { admin } = l10n

export default {
  ADD_TITLE: admin.teamMembers.title.add,
  ADD_SUBMIT: admin.teamMembers.submit,
  ADD_SUCCESS: admin.response.memberCreatedSuccessfully,
  EDIT_TITLE: admin.teamMembers.title.edit,
  EDIT_SUBMIT: admin.teamMembers.saveChanges,
  EDIT_SUCCESS: admin.response.memberUpdatedSuccessfully
}
