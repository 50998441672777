export default theme => ({
  container: {
    height: 'inherit'
  },
  stepper: { backgroundColor: 'transparent' },
  headerContainer: {
    backgroundColor: theme.palette.common.white,
    height: theme.spacing(6),
    width: '100%',
    position: 'absolute'
  },
  logoContainer: {
    '& > img': {
      height: theme.spacing(2)
    }
  },
  skipLinkContainer: {
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    right: theme.spacing(2),
    '& > span > a': {
      color: theme.palette.common.darkGray,
      textTransform: 'capitalize',
      textDecoration: 'none'
    }
  },
  activeDot: { backgroundColor: theme.palette.common.white },
  arrow: { color: theme.palette.common.white },
  title: {
    textAlign: 'center',
    color: theme.palette.common.white,
    fontSize: 30,
    fontWeight: 450,
    marginTop: theme.spacing(2),
    flex: 1
  },
  swipeableContainer: {
    flex: 1,
    marginTop: theme.spacing(6),
    '& > div': {
      height: '100%',
      '& > .react-swipeable-view-container': {
        height: 'inherit',
        '& > div': {
          display: 'flex',
          flexDirection: 'column'
        }
      }
    }
  }
})
