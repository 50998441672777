// @flow

export const documentTypesEnum = Object.freeze({
  contractorsLicense: 'CONTRACTORS_LICENSE',
  contractorsLicenseForSubcontractor: 'CONTRACTORS_LICENSE_FOR_SUBCONTRACTOR',
  currentBalanceSheetFinancial: 'CURRENT_YTD_BALANCE_SHEET_FINANCIAL',
  currentProfitAndLossFinancial: 'CURRENT_YTD_PROFIT_AND_LOSS_FINANCIAL',
  previousYearsBalanceSheetFinancial: 'PREVIOUS_YEARS_BALANCE_SHEET_FINANCIAL',
  generalLiabilityInsurance: 'GENERAL_LIABILITY_INSURANCE',
  homeImprovementAgreement: 'HOME_IMPROVEMENT_AGREEMENT',
  previousYearsProfitAndLossFinancial:
    'PREVIOUS_YEARS_PROFIT_AND_LOSS_FINANCIAL',
  secretaryOfState: 'SECRETARY_OF_STATE',
  subcontractorsAgreement: 'SUBCONTRACTORS_AGREEMENT',
  subcontractorsWorkersCompensation: 'SUBCONTRACTORS_WORKERS_COMPENSATION',
  workersCompensationInsurance: 'WORKERS_COMPENSATION_INSURANCE'
})

export type DocumentTypes = $Values<typeof documentTypesEnum>

export default documentTypesEnum
