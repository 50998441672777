// flow
import React, { Component } from 'react'
import { Typography, Link } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import checkAvailability from 'util/ContactCardHelper'
import cx from 'classnames'
import { POLLING_INTERVAL_10SEC } from 'properties/properties'
import { colors, bodyTextNormal } from 'themes/default'
import defaultTranslations from 'properties/translations'
import TranslationsContext from 'util/TranslationsContext'

interface Props {
  titleMessage: string;
  supportNumber: string;
  contactHours: string;
  supportText: string;
  formattedSupportNumber: string;
  type: string;
  general: string;
  defaultSupport: string;
  underwriting: string;
  welcome: string;
  loanpayment: string;
  underwritingClass: string;
  welcomeClass: string;
  loanClass: string;
  supportObj: object;
  timezonePT: string;
  supportPhoneNumber: string;
}

const styles = () => ({
  contactCardTel: {
    fontWeight: '700',
    fontSize: '1.3em'
  },
  contactCardHoursCtn: {
    display: 'flex',
    margin: '0 24px',
    justifyContent: 'center'
  },
  contactCardUnAvailability: {
    color: '#DF6974',
    fontWeight: '400'
  },
  contactCardAvailability: {
    color: colors.iconSuccess,
    fontWeight: '400'
  },
  contactCardEmail: {
    paddingTop: '5%'
  },
  contactCardEmailLink: {
    paddingTop: '2%'
  },
  contactCardResponseMessage: {
    color: 'grey',
    fontSize: '0.7em',
    paddingTop: '2%',
    paddingBottom: '5%'
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  }
})

type State = {
  isSupportAvailable: boolean
}

class ContactBox extends Component<Props, State> {
  constructor(props) {
    super(props)
    const { timezonePT } = props
    const { type } = props.supportObj
    this.state = {
      isSupportAvailable: checkAvailability(type, props, timezonePT)
    }
  }

  static contextType = TranslationsContext

  static defaultProps = {
    general: defaultTranslations.contactCard.supportTypes.general,
    defaultSupport: defaultTranslations.contactCard.supportTypes.default,
    underwriting: defaultTranslations.contactCard.supportTypes.underwriting,
    welcome: defaultTranslations.contactCard.supportTypes.welcome,
    loanpayment: defaultTranslations.contactCard.supportTypes.loanpayment,
    timezonePT: defaultTranslations.contactCard.supportAreas.timezonePT
  }

  componentDidMount() {
    const { timezonePT } = this.props
    const { type } = this.props.supportObj
    this.timer = setInterval(() => {
      const isSupportAvailable = checkAvailability(type, this.props, timezonePT)
      this.setState({ isSupportAvailable })
    }, POLLING_INTERVAL_10SEC)
  }

  componentWillUnmount() {
    clearInterval(this.timer)
  }

  render() {
    const l10n = this.context
    const { classes, supportPhoneNumber } = this.props
    const { isSupportAvailable } = this.state
    const { contactHours, supportText, titleMessage, formattedSupportNumber } =
      this.props.supportObj

    let extraStyle = ''
    if (typeof this.props.underwritingClass !== 'undefined') {
      extraStyle = this.props.underwritingClass
    } else if (typeof this.props.welcomeClass !== 'undefined') {
      extraStyle = this.props.welcomeClass
    } else if (typeof this.props.loanClass !== 'undefined') {
      extraStyle = this.props.welcomeClass
    }

    const { availableText, notavailableText } =
      l10n.contactCard.availabilityText
    const isMobile = (userAgent = navigator.userAgent) => /Mobi/.test(userAgent)
    return (
      <div>
        <Typography
          align="center"
          data-testid="contact-box-title-message"
          variant="body2"
          className={cx(extraStyle, classes.bodyTextNormal)}
          gutterBottom
        >
          {titleMessage}
        </Typography>
        <Typography
          align="center"
          variant="body2"
          data-testid="contact-box-support-number"
          className={classes.contactCardTel}
        >
          {isMobile() ? (
            <Link
              href={`tel:${formattedSupportNumber}`}
              component="a"
              underline="none"
            >
              {supportPhoneNumber}
            </Link>
          ) : (
            <span>{supportPhoneNumber}</span>
          )}
        </Typography>
        <Typography
          align="center"
          data-testid="contact-box-support-text"
          gutterBottom
        >
          {supportText}
        </Typography>
        <div className={classes.contactCardHoursCtn}>
          <Typography
            data-testid="contact-box-days-hours"
            variant="caption"
            align="center"
          >
            {contactHours}
          </Typography>
        </div>
        {isSupportAvailable ? (
          <Typography
            variant="caption"
            data-testid="contact-box-availability-text"
            align="center"
            className={classes.contactCardAvailability}
            paragraph
          >
            {availableText}
          </Typography>
        ) : (
          <Typography
            variant="caption"
            align="center"
            data-testid="contact-box-availability-text"
            className={classes.contactCardUnAvailability}
            paragraph
          >
            {notavailableText}
          </Typography>
        )}
      </div>
    )
  }
}

export default withStyles(styles)(ContactBox)
