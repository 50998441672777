import React, { useContext } from 'react'
import StringHelper from 'util/StringHelper'
import { TextField } from '@material-ui/core'
import TranslationsContext from 'util/TranslationsContext'

const CustomerFullName = ({ firstName, lastName, inputProps }) => {
  const l10n = useContext(TranslationsContext)
  return (
    <TextField
      label={l10n.customer.fullName}
      value={StringHelper.getCustomerFullName(firstName, lastName)}
      InputProps={{ ...inputProps }}
      disabled
      fullWidth
    />
  )
}

export default CustomerFullName
