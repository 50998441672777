// @flow
import get from 'lodash/get'

const featureFlags = Object.freeze({
  foundation: 'pos.foundation-finance-second-look-credit.boolean',
  maintainanceMode: 'pos.maintenance-mode.boolean',
  supportedBrowsersAutomatedTesting:
    'pos.supported-browsers-for-automated-testing.json',
  projectTypeCustomization: 'ex-3414.project-type-customization.boolean',
  appCues: 'ex-4526-app-cues-sdk',
  weblinksConditionalApproval:
    'ex-5049.weblinks-conditional-approval-uploads.boolean',
  conditionalApprovalForAggregators:
    'ex-5049.hide-conditional-approval-uploads-for-aggregators.json',
  reCaptchaSiteKeys: 'ex-5156.p2x-recaptcha-site-keys.string',
  updatePaymentPlans: 'ex-4448.update-payment-plans.boolean',
  documentTaskStatus: 'ex-5294.document-task-status.boolean',
  swiftlinksHostnameDenyList: 'ex-5672.swiftlinks-hostname-deny-list.json',
  onboardingFlag: 'mag-1149.onboarding-flow.boolean',
  handToCustomerModal: 'ex-5910.hand-back-to-customer-modal.boolean',
  swiftlinksPartnerConversionPixels:
    'ex-6037.swiftlinks-partner-conversion-pixels.json',
  removeSaveButtonFromEditCustomerInfo:
    'ex-6034.remove-save-button-from-edit-info.boolean',
  editCustomerFlag:
    'ex-6072-mobile-number-modification-in-edit-customer-info.boolean',
  s3LinearProgressFlag: 'ex-6082-magnes-progress-indication.boolean',
  allowSolarCustomersInPipeline: 'ex-6235.solar-customers-in-pipeline.boolean',
  emailAddressVerification: 'ex-6211-bypass-email-verification.boolean',
  singleDobField: 'ex-6154.new-dob-field.boolean',
  creditExpirationAlerts: 'ex-6065.credit-expiration-alerts.boolean',
  loanCancellationAlerts: 'ex-2008.alerts-for-cancellation.boolean',
  integratorDocusign: 'ex-6632.integrator-docusign-callback.boolean',
  landlineNumberEnabled: 'ex-6281-allow-non-mobile-phone-numbers.boolean',
  googlePlacesToken: 'ex-6636.google-places-api-keys.json',
  removeTasksFromPipeline: 'ex-6603.remove-tasks-from-pipeline.boolean',
  moveAchBeforeLoanAgreement: 'api-556.move-ach-before-loan-agreement.boolean',
  connexusMembership: 'ex-6621.connexus-membership.boolean',
  deprecateVerifyAndAuthenticate:
    'ex-6973.deprecate-verify-and-authenticate.boolean',
  borrowerDashboardInstallConfirmation:
    'ex-6282.borrower-dashboard-install-confirmation.boolean',
  dashboardHubAndSpoke: 'ex-7097.dashboard-hub-and-spoke.boolean',
  lastFourSSN: 'ex-7173.last-four-ssn.boolean',
  lastFourSSNLease: 'apd-300.last-four-ssn-lease.boolean',
  solarIntegratorPrequalification:
    'ex-7556.solar-integrator-prequalification.boolean',
  financingPlatformOptions: 'tx-114.mosaic-financing-platform-options.json',
  clearScreenNameInIframe:
    'aea-72.clear-screen-name-in-iframe-after-qualified-screen-viewed.boolean',
  radioButtonForPropertyOwnership:
    'aea-128.application-form-changes-for-ownership.boolean',
  ssnMismatchRequiresBothApplicants:
    'aea-211.ssn-mismatch-requires-both-applicants.boolean',
  householdIncomeOnHomeP2XApplications:
    'aea-215.household-income-on-home-p2x-applications.boolean',
  clearSessionOnAnyRefreshToken:
    'aea-478.clear-session-on-any-usage-of-refresh-token.boolean',
  cookiePrefixEnabled: 'apd.cookie_prefix_enabled.boolean',
  useFullHostnameOnAccessTokenCookie:
    'aea.tap-prefix-on-pos-access-token-cookie.boolean',
  enableFullStory: 'atg-177.full-story'
})

export type FeatureFlagType = $Values<typeof featureFlags>

export const getIsProjectTypeCustomization = (ldFlags: {
  [FeatureFlagType]: Object
}) => get(ldFlags, featureFlags.projectTypeCustomization, false)

export default featureFlags
