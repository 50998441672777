export const canSubmitVerifyIdentityForm = ({
  verifySsn,
  fullSsnRequired,
  dobDay,
  dobMonth,
  dobYear,
  dobRequired
}) => {
  if (!verifySsn) return false
  if (dobRequired) {
    if (!dobYear || dobYear.length < 4) return false
    if (![dobMonth, dobDay].every(value => value.length)) return false
  }
  if (fullSsnRequired) {
    return verifySsn.length === 9
  }
  return verifySsn.length === 4
}

export const formatDateOfBirth = ({ formValues, dobRequired = true }) => {
  return dobRequired
    ? `${formValues.dobYear}-${formValues.dobMonth}-${formValues.dobDay}`
    : null
}
