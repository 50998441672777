import { gql } from 'graphql.macro'

export default gql`
  mutation ResetPassword($password: String!, $resetCode: String!) {
    forgotPartnerLoginPassword(password: $password, resetCode: $resetCode) {
      userAuth {
        accessModes
        accessToken
        refreshToken
        userId
        userRoles
      }
      errors {
        key
        message
        field
      }
    }
  }
`
