import React from 'react'
import { SvgIcon } from '@material-ui/core'

const BubbleFilledIcon = props => (
  <SvgIcon {...props}>
    <g id="Page-1" strokeWidth="1" fill="none" fillRule="evenodd">
      <circle id="Oval" fill="#fff" strokeWidth="2" cx="11" cy="11" r="10" />
    </g>
  </SvgIcon>
)

export default BubbleFilledIcon
