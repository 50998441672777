import { gql } from 'graphql.macro'
import SalespersonFields from 'queries/fragments/SalespersonFields.graphql'
import LoanProductResponseFields from 'queries/fragments/LoanProductResponseFields.graphql'
import SampleTermsResponseFields from 'queries/fragments/SampleTermsResponseFields.graphql'

export default gql`
  query getEstimate($customerId: String!, $loanAmount: BigDecimal!) {
    me {
      userType
    }
    customer(customerId: $customerId) {
      id
      salesperson {
        ...SalespersonFields
      }
      creditApplication {
        decision {
          qualifiedProducts {
            ...LoanProductResponseFields
            sampleTerms(loanAmount: $loanAmount) {
              ...SampleTermsResponseFields
            }
          }
        }
      }
    }
  }
  ${SalespersonFields}
  ${LoanProductResponseFields}
  ${SampleTermsResponseFields}
`
