// @flow
import React, { Component } from 'react'
import noop from 'lodash/noop'
import cx from 'classnames'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  IconButton
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import SuccessBadge from 'components/shared/SuccessBadge'
import l10n from 'properties/translations'
import poweredByMosaic from 'images/mosaic-logo.svg'
import styles from './Dialog.styles'

type Sizes = 'default' | 'large'
const SUCCESS_BADGE_DURATION = 2000
interface Props {
  open: boolean;
  title: string;
  onClose: Function;
  classes: {
    bannerTitle: string,
    dialog: string,
    largeModal: string,
    logo: string,
    title: string
  };
  children: Object;
  onEnter: Function;
  onEntered: Function;
  onExit: Function;
  onExited: Function;
  onScroll: Function;
  actionContent: Object;
  footerContent: Object;
  hideCloseButton: boolean;
  onBackdropClick: Function;
  scroll: string;
  showBanner: boolean;
  size: Sizes;
  disableBackdropClick: boolean;
  TransitionComponent: Function;
  showSuccessBadge?: boolean;
  successBadgeCaption: string;
  genericUploader?: boolean;
}

class SharedDialog extends Component<Props, {}> {
  static defaultProps = {
    actionContent: null,
    disableBackdropClick: false,
    footerContent: null,
    hideCloseButton: false,
    onBackdropClick: noop,
    onEnter: noop,
    onEntered: noop,
    onExit: noop,
    onExited: noop,
    onScroll: noop,
    scroll: 'body',
    showBanner: false,
    size: 'default',
    title: null,
    showSuccessBadge: false,
    successBadgeCaption: l10n.dashboard.projectDetail.projectConfirmed,
    genericUploader: false,
    maxWidth: 'sm'
  }

  timerHandle = 0

  componentWillUnmount = () => {
    this.clearTimer()
  }

  renderSuccessBadge = onClose => {
    this.setTimer(onClose)
    const { successBadgeCaption, genericUploader } = this.props
    return (
      <SuccessBadge
        centered
        caption={successBadgeCaption}
        genericUploader={genericUploader}
      />
    )
  }

  setTimer = onClose => {
    if (this.timerHandle) {
      return
    }
    this.timerHandle = setTimeout(() => onClose(), SUCCESS_BADGE_DURATION)
  }
  clearTimer = () => {
    if (this.timerHandle) {
      clearTimeout(this.timerHandle)
      this.timerHandle = 0
    }
  }

  render() {
    const {
      open,
      title,
      onClose,
      classes,
      children,
      onEnter,
      onEntered,
      onExit,
      onExited,
      onScroll,
      actionContent,
      hideCloseButton,
      onBackdropClick,
      scroll,
      showBanner,
      footerContent,
      disableBackdropClick,
      TransitionComponent,
      showSuccessBadge,
      paperProps,
      maxWidth
    } = this.props

    const showDialogTitle = showBanner || title
    return (
      <Dialog
        open={open}
        TransitionComponent={TransitionComponent}
        onClose={onClose}
        onEnter={onEnter}
        onEntered={onEntered}
        onExit={onExit}
        onExited={onExited}
        onScroll={onScroll}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        scroll={scroll}
        PaperProps={paperProps}
        classes={{
          root: classes.root,
          paperScrollBody: classes.dialog,
          paperScrollPaper: classes.dialogScrollPaper,
          ...(showBanner ? { paperWidthSm: classes.largeModal } : {})
        }}
        onBackdropClick={onBackdropClick}
        BackdropProps={{
          className: classes.backdrop
        }}
        disableBackdropClick={disableBackdropClick}
        maxWidth={maxWidth}
      >
        {showSuccessBadge && this.renderSuccessBadge(onClose)}
        <div className={showSuccessBadge ? classes.fadeOutContent : null}>
          {showDialogTitle && (
            <DialogTitle
              id="alert-dialog-title"
              data-testid="dialog-title"
              className={showBanner ? classes.bannerTitle : classes.title}
            >
              <Grid container justify="space-between">
                {title && (
                  <Typography variant="h1" className={classes.pageTitle}>
                    {title}
                  </Typography>
                )}
                {showBanner && (
                  <Grid item xs={12}>
                    <img
                      alt="Mosaic"
                      className={classes.logo}
                      src={poweredByMosaic}
                    />
                  </Grid>
                )}
                {!hideCloseButton && (
                  <IconButton
                    className={cx(classes.icon, classes.iconHover)}
                    onClick={onClose}
                    aria-label="Close"
                  >
                    <CloseIcon />
                  </IconButton>
                )}
              </Grid>
            </DialogTitle>
          )}
          <DialogContent
            classes={{
              root: classes.dialogScrollPaperContent
            }}
          >
            {children}
          </DialogContent>
          {actionContent && <DialogActions>{actionContent}</DialogActions>}
          {footerContent}
        </div>
      </Dialog>
    )
  }
}
export default withStyles(styles)(SharedDialog)
