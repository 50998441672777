/* eslint-disable react/jsx-boolean-value */
// @flow
import * as React from 'react'
import { Grid, NativeSelect } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import l10n from 'properties/translations'
import { stateList } from 'util/FoundationFormHelper'
import { PhoneFormat, SsnFormat, DOBFormat } from '../../NumberFormatHelper'

type Props = {
  classes: Object,
  handleFormState: string,
  coAppFirstName: string,
  coAppLastName: string,
  coAppDriversLicense: number,
  coAppEmail: string,
  coAppDob: string,
  coAppssn: number,
  coAppPhoneNumber: number,
  coAppAlternatePhone: number,
  coApplicantDrState: string
}

const AboutCoApplicant = (props: Props) => {
  const {
    classes,
    handleFormState,
    coAppFirstName,
    coAppLastName,
    coAppDriversLicense,
    coAppEmail,
    coAppDob,
    coAppssn,
    coAppPhoneNumber,
    coAppAlternatePhone,
    coApplicantDrState
  } = props
  return (
    <>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={
                l10n.foundation.applicationContent.coApplicantFirstNameLabel
              }
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames.coAppFirstName
              }
              value={coAppFirstName}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages.coAppNameRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidName
              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={
                l10n.foundation.applicationContent.coApplicantLastNameLabel
              }
              onChange={handleFormState}
              name={l10n.foundation.applicationContent.fieldNames.coAppLastName}
              value={coAppLastName}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages.coAppNameRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidName
              ]}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.coAppdriverLicenseFieldStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.driverLicenseLabel}
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .coAppDriversLicense
              }
              value={coAppDriversLicense}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .coAppDriversLicenseRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages
                  .invalidDriversLicense
              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            {l10n.foundation.applicationContent.driverLicenseState}
            <br />
            <NativeSelect
              fullWidth
              onChange={handleFormState}
              value={coApplicantDrState}
              name={
                l10n.foundation.applicationContent.fieldNames.coApplicantDrState
              }
              required={true}
              className={classes.nativeSelectDropStyle}
            >
              {stateList}
            </NativeSelect>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.coApplicantEmailLabel}
              onChange={handleFormState}
              name={l10n.foundation.applicationContent.fieldNames.coAppEmail}
              value={coAppEmail}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .textValidatorEmailCheck
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidEmail
              ]}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.coApplicantDOBLabel}
              onChange={handleFormState}
              name={l10n.foundation.applicationContent.fieldNames.coAppDob}
              value={coAppDob}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel
              ]}
              InputProps={{ inputComponent: DOBFormat }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={l10n.foundation.applicationContent.coApplicantSSNLabel}
              onChange={handleFormState}
              name={l10n.foundation.applicationContent.fieldNames.coAppssn}
              value={coAppssn}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages
                  .coAppSSNValidation
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidSSN
              ]}
              InputProps={{ inputComponent: SsnFormat }}
            />
          </div>
        </Grid>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={
                l10n.foundation.applicationContent.coApplicantMobileNumberLabel
              }
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames.coAppPhoneNumber
              }
              value={coAppPhoneNumber}
              validators={[
                l10n.foundation.applicationContent.errorMessages.required,
                l10n.foundation.applicationContent.errorMessages.coAppPhoneRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.fieldRequiredLabel,
                l10n.foundation.applicationContent.errorMessages.invalidPhone
              ]}
              InputProps={{ inputComponent: PhoneFormat }}
            />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={0}>
        <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
          <div className={classes.fieldTitleStyle}>
            <TextValidator
              fullWidth
              label={
                l10n.foundation.applicationContent
                  .coApplicantAlternateNumberLabel
              }
              onChange={handleFormState}
              name={
                l10n.foundation.applicationContent.fieldNames
                  .coAppAlternatePhone
              }
              value={coAppAlternatePhone}
              validators={[
                l10n.foundation.applicationContent.errorMessages.coAppPhoneRegex
              ]}
              errorMessages={[
                l10n.foundation.applicationContent.errorMessages.invalidPhone
              ]}
              InputProps={{ inputComponent: PhoneFormat }}
            />
          </div>
        </Grid>
      </Grid>
    </>
  )
}

export default AboutCoApplicant
