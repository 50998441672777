import { colors } from 'themes/default'

const styles = theme => {
  return {
    verifyIdentityDialog: {
      padding: theme.spacing(4),
      overflowY: 'unset'
    },
    verifyIdentityDialogSingleDob: {
      padding: theme.spacing(4),
      overflowY: 'unset',
      '& .MuiInputBase-input': {
        padding: '8px 0',
        fontSize: '20px',
        lineHeight: 'normal',
        color: colors.normalBlack,
        '& ::placeholder': {
          fontSize: '20px'
        }
      },
      '& .MuiInputBase-multiline': {
        padding: 0
      },
      '& .MuiInputLabel-root': {
        top: '-3px',
        color: colors.mutedBlack,
        fontSize: '20px'
      },
      '& .MuiInputLabel-shrink': {
        top: 0,
        transform: 'translate(0, 1px) scale(0.63)'
      },
      '& .MuiFormHelperText-root': {
        lineHeight: 1.4
      }
    }
  }
}

export default styles
