// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import l10n from 'properties/translations'
import poweredByMosaic from 'images/mosaic-logo.svg'

const styles = theme => ({
  logo: {
    height: 20
  },
  logoSmall: {
    height: theme.spacing(2)
  },
  logoContainer: {
    paddingTop: theme.spacing(6),
    paddingBottom: theme.spacing(6)
  },
  logoContainerSmall: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3)
  }
})

type AlignType = 'right' | undefined

type Props = {
  classes: {
    logo: string,
    logoContainer: string
  },
  small: boolean,
  align: AlignType
}

const alignItemsValue = (align: AlignType) => {
  switch (align) {
    case 'right':
      return 'flex-end'
    default:
      return 'center'
  }
}

const LogoBanner = (props: Props) => {
  const { classes, align = undefined, small = false } = props

  return (
    <Grid
      container
      direction="column"
      alignItems={alignItemsValue(align)}
      justify="center"
      spacing={1}
      className={small ? classes.logoContainerSmall : classes.logoContainer}
    >
      <Grid item>
        <img
          alt={l10n.layout.poweredByMosaic}
          className={small ? classes.logoSmall : classes.logo}
          src={poweredByMosaic}
        />
      </Grid>
      {!small && (
        <Grid item>
          <Typography variant="caption" align="center">
            {l10n.layout.applicationDescription}
          </Typography>
        </Grid>
      )}
    </Grid>
  )
}

export default withStyles(styles)(LogoBanner)
