const disclosureIdentifiers = {
  credit: 'credit',
  esign: 'esign',
  arbitration: 'arbitration',
  sms: 'sms',
  patriotAct: 'patriotAct',
  equalHousingLender: 'equalHousingLender',
  lender: 'lender'
}

export default disclosureIdentifiers

export const assetCodes = {
  ECOA_ES: 'ECOA_ES',
  ECOA: 'ECOA',
  INCOME_ES: 'INCOME_ES',
  INCOME: 'INCOME',
  ONSCREEN_ES: 'ONSCREEN_ES',
  ONSCREEN_WBBK_ES: 'ONSCREEN_WBBK_ES',
  ONSCREEN_WBBK: 'ONSCREEN_WBBK',
  ONSCREEN: 'ONSCREEN',
  OTHERINC_ES: 'OTHERINC_ES',
  OTHERINC: 'OTHERINC',
  OWN_ES: 'OWN_ES',
  OWN: 'OWN',
  RES_ES: 'RES_ES',
  RES: 'RES',
  // TCPA_1 and TCPA_2 are identical, but we are maintaining both versions
  // in the disclosure service for asset code backwards compatibility.
  TCPA_1_ES: 'TCPA_1_ES',
  TCPA_1: 'TCPA_1',
  TCPA_2_ES: 'TCPA_2_ES',
  TCPA_2: 'TCPA_2'
}

export const disclosureIdentifiersToAssetCodes = {
  [disclosureIdentifiers.sms]: [
    assetCodes.TCPA_1,
    assetCodes.TCPA_1_ES,
    assetCodes.TCPA_2,
    assetCodes.TCPA_2_ES
  ]
}

export const disclosurePackageTypes = {
  solarSoft: 'solar-soft',
  homeSoft: 'home-soft',
  zeroSoft: 'zero-soft'
}
