import { bodyTextNormal, buttonMinWidth } from 'themes/default'

export default theme => ({
  section: {
    marginBottom: theme.spacing(4)
  },
  sectionCtn: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      paddingTop: '6px'
    },
    '& p': {
      marginLeft: theme.spacing(1)
    }
  },
  totalMonthlyPayment: {
    display: 'flex',
    flexDirection: 'column',
    '& span': {
      paddingTop: '6px'
    },
    '& p': {
      marginLeft: theme.spacing(1)
    }
  },
  persistMonthlyPaymentCtn: {
    zIndex: 1,
    position: 'fixed',
    top: theme.spacing(3),
    left: 0,
    width: '100%',
    opacity: 0
  },
  persistMonthlyPayment: {
    justifyContent: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    // [theme.breakpoints.down('sm')]: {
    //   width: '40%'
    // },
    '& span': {
      paddingTop: theme.spacing(1)
    },
    padding: theme.spacing(2)
  },
  gridBtnCtn: {
    width: '60%',
    '& button': {
      margin: `${theme.spacing(1)}px 0`
    }
  },
  totalMonthlyPaymentColor: {
    color: theme.palette.common.successGreen
  },
  totalMonthlyPaymentAmountColor: {
    color: theme.palette.common.successGreen
  },
  errorText: {
    color: theme.palette.error.main
  },
  root: {
    marginBottom: 36
  },
  row: {
    padding: 18,
    marginBottom: 6,
    boxShadow: 'none',
    border: '2px solid #eee',
    cursor: 'pointer',
    borderRadius: 6,
    textAlign: 'center'
  },
  rowSelected: {
    backgroundColor: theme.palette.secondary.light,
    border: `2px solid ${theme.palette.secondary.main}`
  },
  rowReadonly: {
    border: '1px dashed rgba(0, 0, 0, 0.3) !important',
    cursor: 'default'
  },
  rowReadonlyAndSelected: {
    backgroundColor: theme.palette.grey['200']
  },
  productNumberText: {
    fontSize: 12,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'lowercase',
    '& span': {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold
    }
  },
  productNumberTextBold: {
    fontSize: 14,
    textTransform: 'lowercase',
    lineHeight: 1.2,
    '& span': {
      fontSize: 16
    }
  },
  productNumberReadonly: {
    color: theme.palette.grey['500']
  },
  borrowerContactInstaller: {
    textAlign: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(4),
    color: theme.palette.grey['400']
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120
  },
  spaceOnTop: {
    marginTop: theme.spacing(2) * 4
  },
  productNameTopSpace: {
    marginTop: theme.spacing(-1)
  },
  productNameBottomSpace: {
    marginBottom: theme.spacing(1)
  },
  unavailableRow: {
    padding: 30,
    marginBottom: 6,
    boxShadow: 'none',
    border: '2px solid #eee',
    cursor: 'pointer',
    borderRadius: 6,
    textAlign: 'center'
  },
  serviceSelectBottomSpace: {
    marginBottom: theme.spacing(-1)
  },
  labelTitle: { textAlign: 'center', marginBottom: theme.spacing(1) },
  btnCtn: {
    marginBottom: theme.spacing(2)
  },
  button: {
    ...buttonMinWidth
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  loadingContainer: {
    minHeight: '54px'
  }
})
