// @flow
import React, { useContext } from 'react'
import { FormControl, InputLabel, NativeSelect } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import formConfig from 'properties/formConfig'
import { EMPTY_STRING } from 'properties/properties'
import customerFields from 'constants/enums/customerFields'
import TranslationsContext from 'util/TranslationsContext'

const styles = theme => ({
  container: {
    marginTop: theme.spacing(1)
  },
  select: {
    color: theme.palette.grey['600'],
    fontWeight: theme.typography.fontWeightRegular
  }
})

const OptionList = () => {
  const l10n = useContext(TranslationsContext)
  return formConfig.ADDRESS_TYPES.map(
    ({
      addressType,
      displayKey
    }: {
      addressType: string,
      displayKey: string
    }) => (
      <option key={addressType} value={addressType}>
        {l10n.addressTypes[displayKey]}
      </option>
    )
  )
}

type Props = {
  canEdit: boolean,
  classes: {
    container: string,
    select: string
  },
  onSelectChange: (event: Object) => void,
  selection: string
}

const AddressTypeDropdown = (props: Props) => {
  const { canEdit, classes, onSelectChange, selection } = props
  const l10n = useContext(TranslationsContext)
  return (
    <FormControl disabled={!canEdit} fullWidth className={classes.container}>
      <InputLabel shrink={!!selection}>
        {l10n.customer.projectAddressTypeLabel}
      </InputLabel>
      <NativeSelect
        value={selection || EMPTY_STRING}
        onChange={onSelectChange}
        classes={{
          select: !selection && classes.select
        }}
        name={customerFields.projectAddressType}
      >
        <OptionList />
      </NativeSelect>
    </FormControl>
  )
}

export default withStyles(styles)(AddressTypeDropdown)
