import MobileDetect from 'mobile-detect'
import { MOBILE_DETECT_OS } from 'properties/properties'

const getMobileInfo = () => {
  const md = new MobileDetect(window.navigator.userAgent)
  const isMobile = md.mobile()
  const mobileOS = md.os()
  const isWebkit = md.version('Webkit')

  return {
    isWebkit,
    isMobile,
    mobileOS
  }
}

const isStandalone = () => {
  const isInWebAppiOS = window.navigator.standalone === true
  if (!window.matchMedia) return false
  const isInWebAppChrome = window.matchMedia(
    '(display-mode: standalone)'
  ).matches

  return isInWebAppiOS || isInWebAppChrome
}

const isMobileAndroidiOS = () => {
  const { isMobile, mobileOS } = getMobileInfo()

  return (
    isMobile && (mobileOS === MOBILE_DETECT_OS.android || MOBILE_DETECT_OS.ios)
  )
}

const isWebkitMobile = () => {
  const { isMobile, isWebkit } = getMobileInfo()

  return isMobile && isWebkit
}

const supportsTouchEvents = 'ontouchstart' in window

const isMobile = () => getMobileInfo().isMobile

const MobileInfo = {
  isMobile,
  isStandalone,
  getMobileInfo,
  isMobileAndroidiOS,
  isWebkitMobile,
  supportsTouchEvents
}

export default MobileInfo
