// @flow
import React, { Component } from 'react'
import { getEnvironment } from 'util/EnvironmentHelper'
import MonitoringHelper from 'util/MonitoringHelper'
import GenericErrorPage from 'components/shared/GenericErrorPage'
import l10n from 'properties/translations'
import { EMPTY_STRING } from 'properties/properties'
import backendEnvironments from 'constants/enums/backendEnvironments'

type State = {
  hasError: boolean,
  error: {
    name: string,
    message: string
  }
}

type Props = {
  children: React$Node
}

// via https://reactjs.org/docs/error-boundaries.html#introducing-error-boundaries
export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = {
      hasError: false,
      name: EMPTY_STRING,
      message: EMPTY_STRING
    }
  }

  static getDerivedStateFromError() {
    // Update state so the next render will show the fallback UI.
    return { hasError: true }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);
    this.setState({
      error: {
        name: error.name,
        message: error.message
      }
    })

    MonitoringHelper.manuallyReportError(error)
    // eslint-disable-next-line no-console
    console.warn(error, errorInfo)
  }

  render() {
    const { error } = this.state
    const environmentName = getEnvironment()
    const title =
      error && environmentName !== backendEnvironments.production
        ? error.name
        : l10n.notFound.genericErrorTitle
    const descriptionOverviewText =
      error && environmentName !== backendEnvironments.production
        ? error.message
        : l10n.notFound.genericErrorDescription
    if (this.state.hasError) {
      return (
        <GenericErrorPage
          unspecifiedError
          showBackButton={false}
          title={title}
          descriptionOverviewText={descriptionOverviewText}
          descriptionDetailText={''}
          showErrorCode={false}
        />
      )
    }

    return this.props.children
  }
}
