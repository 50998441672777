// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography } from '@material-ui/core'
import { Link } from 'react-router-dom'
import Button from 'components/shared/Button'
import { flow } from 'lodash'
import withLaunchDarkly from './LaunchDarklyHOC'

const styles = () => ({
  iconButton: {
    marginLeft: '-12px',
    padding: '8px'
  }
})

type Props = {
  backLink: string,
  buttonLink?: string,
  buttonLabel?: string,
  classes: Object,
  ldFlags: Object
}

const PageNav = (props: Props) => {
  const { buttonLink, buttonLabel } = props

  return (
    <>
      <Grid container item justify="flex-end">
        {buttonLink && (
          <Grid item>
            <Button
              component={Link}
              to={buttonLink}
              color="primary"
              type="button"
              size="large"
              variant="outlined"
            >
              <Typography>{buttonLabel}</Typography>
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  )
}

PageNav.defaultProps = {
  buttonLink: null,
  buttonLabel: null
}

export default flow(withLaunchDarkly, withStyles(styles))(PageNav)
