// @flow
import React, { Component } from 'react'
import { withStyles } from '@material-ui/core/styles'
import { Grid, Typography, Button } from '@material-ui/core'
import { Formik, Form, FieldArray } from 'formik'
import { isEmpty, head, flow } from 'lodash'
import { Mutation } from '@apollo/client/react/components'
import withApolloConsumer from 'components/shared/hocs/withApolloConsumer'
import { EMPTY_STRING } from 'properties/properties'
import type { BrowserHistory, HashHistory, MemoryHistory } from 'history'
import Routes from 'util/Routes'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import MonitoringHelper from 'util/MonitoringHelper'
import Page from 'components/templates/Page'
import l10n from 'properties/translations'
import CreateMember from 'queries/team/CreateMember.graphql'
import GetMember from 'queries/team/GetMember.graphql'
import UpdateMember from 'queries/team/UpdateMember.graphql'
import MembershipSchema from 'properties/validations/adminAddEditMember'
import SuccessToast from 'components/shared/SuccessToast'
import ErrorToast, { errorToastIcon } from 'components/shared/ErrorToast'
import Loading from 'components/shared/Loading'
import adminRoles from 'constants/enums/adminRoles'
import userRoles from 'constants/enums/userRoles'
import modes from 'constants/enums/teamMemberModes'
import teamMemberTextTypes from 'constants/enums/teamMemberTextTypes'
import PageNav from 'components/shared/PageNav'
import TeamQuery from '../queries/TeamQuery.graphql'
import Adminstyles from '../Admin.styles'
import TeamMemberForm from './TeamMemberForm'

const { admin } = l10n

type Props = {
  classes: { [key: string]: any },
  match: {
    params: {
      teamMemberId: string
    }
  },
  client: Mutation,
  history: BrowserHistory | HashHistory | MemoryHistory,
  me: Object,
  ldFlags: Object
}

type Role = {
  label: string,
  value: string,
  helperText: string
}

type State = {
  mode: string,
  member: {
    id?: string,
    firstName: string,
    lastName: string,
    emailAddress: string,
    phoneNumber: string,
    role: string
  },
  roles: Array<Role>,
  openSuccess: boolean,
  errorOccurred: boolean,
  loading: boolean,
  anyErrorWithAddMember: boolean,
  continueAdding: boolean
}

const SALES_MGR_LABEL = 'Sales Manager'

const MemberRoles = [
  {
    label: l10n.admin.roles.sales,
    value: adminRoles.sales,
    helperText: l10n.admin.rolesHelperText.sales
  },
  {
    label: SALES_MGR_LABEL,
    value: adminRoles.salesmgr,
    helperText: l10n.admin.rolesHelperText.salesmgr
  },
  {
    label: l10n.admin.roles.admin,
    value: adminRoles.admin,
    helperText: l10n.admin.rolesHelperText.admin
  },
  {
    label: l10n.admin.roles.owner,
    value: adminRoles.owner,
    helperText: l10n.admin.rolesHelperText.owner
  }
]

const styles = theme => ({
  ...Adminstyles(theme)
})

class TeamMembersForm extends Component<Props, State> {
  constructor(props) {
    super(props)

    this.state = {
      member: {
        firstName: EMPTY_STRING,
        lastName: EMPTY_STRING,
        emailAddress: EMPTY_STRING,
        phoneNumber: EMPTY_STRING,
        role: 'SALES'
      },
      roles: MemberRoles,
      mode: modes.ADD,
      openSuccess: false,
      errorOccurred: false,
      loading: false,
      anyErrorWithAddMember: false,
      continueAdding: false
    }
  }

  async componentDidMount() {
    const { teamMemberId } = this.props.match.params
    const { client } = this.props

    if (teamMemberId) {
      this.setState({ mode: modes.EDIT })
      this.setLoading(true)
      try {
        const res = await client.query({
          query: GetMember,
          fetchPolicy: 'network-only',
          variables: {
            partnerId: teamMemberId
          }
        })

        this.setState({ member: res.data.partnerLogin })
      } catch (err) {
        MonitoringHelper.manuallyReportError(err)
        // eslint-disable-next-line no-console
        console.warn('Team Member Form Error', err)
      }
      this.setLoading(false)
    }
  }

  getTitle = () => this.getTextByMode('TITLE')

  getSubmitLabel = () => this.getTextByMode('SUBMIT')

  getSuccessSubmitMessage = () => this.getTextByMode('SUCCESS')

  getTextByMode = textType => {
    const { mode } = this.state
    return teamMemberTextTypes[`${mode}_${textType}`]
  }

  setLoading = (bool: boolean) => {
    this.setState({ loading: bool })
  }

  buildMutation = (mutation, variable) => {
    const { client } = this.props

    return client.mutate({
      mutation,
      variables: {
        request: variable
      },
      refetchQueries: [{ query: TeamQuery }]
    })
  }

  updateMember = async (values, actions) => {
    const { setSubmitting } = actions
    setSubmitting(true)
    const { teamMemberId } = this.props.match.params
    const { emailAddress, firstName, lastName, phoneNumber, role } =
      values.members[0]

    // Reassigning properties as the members object has a property __typename that will throw a graphql error.
    const updatedMember = {
      id: teamMemberId,
      emailAddress,
      firstName,
      lastName,
      phoneNumber,
      role
    }

    try {
      await this.buildMutation(UpdateMember, updatedMember)
      this.showSuccess(true)()
    } catch (err) {
      this.showError(true)()
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  }

  createMember = async (values, actions) => {
    // BE throws error if phoneNumber is an empty string. Not sending any phoneNumber is OK.
    // If phoneNumber is an empty string, don't send it.
    const membersFormatted = values.members.map(member =>
      member.phoneNumber === EMPTY_STRING
        ? {
            emailAddress: member.emailAddress,
            firstName: member.firstName,
            lastName: member.lastName,
            role: member.role
          }
        : member
    )

    const { setErrors, setSubmitting } = actions
    await this.setState({ anyErrorWithAddMember: false })
    setSubmitting(true)
    try {
      const res = await this.buildMutation(CreateMember, membersFormatted)
      const data = res.data.createPartnerLogins.result

      let anyError = false
      if (!isEmpty(data)) {
        data.map(member => {
          if (member.errors && member.errors.length > 0) {
            this.setState({ anyErrorWithAddMember: true })

            anyError = true
          }

          return member
        })

        if (!anyError) {
          actions.resetForm()
          this.showSuccess(true)()
        }
      }

      const firstDatum = head(data)
      if (firstDatum.errors) {
        const errorArr = firstDatum.errors.map(obj => ({
          [obj.field]: obj.message
        }))
        setErrors({ members: errorArr })
      }
    } catch (err) {
      this.showError(true)()
      setSubmitting(false)
    } finally {
      setSubmitting(false)
    }
  }

  handleSubmit = async (values, actions) => {
    const { mode } = this.state

    if (mode === modes.ADD) {
      this.createMember(values, actions)
    } else {
      this.updateMember(values, actions)
    }
  }

  showSuccess = bool => async () => {
    this.setState(prevState => ({ loading: !prevState.loading }))
    await this.setState({
      openSuccess: bool
    })
    const { openSuccess, continueAdding } = this.state
    const { history } = this.props

    // Redirects only if success toast is closed and if "Submit & Add Another" was not clicked
    if (!openSuccess && !continueAdding) {
      this.setState(prevState => ({ loading: !prevState.loading }))
      history.push(Routes.team())
    }
  }

  showError = bool => () => {
    this.setState({
      errorOccurred: bool
    })
  }

  render() {
    const {
      openSuccess,
      errorOccurred,
      loading,
      anyErrorWithAddMember,
      member,
      mode,
      roles
    } = this.state
    const { classes, me } = this.props
    const { teamMemberId } = this.props.match.params

    const meIsOwner = me.userRoles.includes(userRoles.installer.owner)
    const meEmailAddress = me.emailAddress

    return (
      <Page showBackButton>
        <SuccessToast
          message={this.getSuccessSubmitMessage()}
          open={openSuccess}
          handleClose={this.showSuccess(false)}
        />
        <ErrorToast
          message={l10n.shared.error}
          open={errorOccurred}
          handleClose={this.showError(false)}
          iconType={errorToastIcon.serverError}
        />
        {loading ? (
          <Loading />
        ) : (
          <>
            <Grid
              container
              direction="column"
              justify="center"
              alignItems="stretch"
              spacing={1}
            >
              <PageNav backLink={Routes.team()} />
              <Grid item>
                <Typography
                  variant="h1"
                  data-testid="team-members-form-title"
                  className={classes.pageTitle}
                >
                  {this.getTitle()}
                </Typography>
              </Grid>
              <Grid item>
                <Typography
                  variant="subtitle1"
                  data-testid="team-members-form-subtitle"
                  className={classes.bodyTextNormal}
                >
                  {l10n.admin.teamMembers.description}
                </Typography>
              </Grid>
              {anyErrorWithAddMember && (
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    data-testid="team-members-form-error"
                    className={classes.errorMessage}
                  >
                    {admin.errors.membersWithErrors}
                  </Typography>
                </Grid>
              )}
            </Grid>
            <Formik
              initialValues={{ members: [member] }}
              validationSchema={MembershipSchema}
              onSubmit={(values, actions) => {
                this.handleSubmit(values, actions)
              }}
              enableReinitialize
            >
              {({ values, errors, submitForm, isSubmitting }) => {
                const isSendInviteEnabled = values.members.map(teamList => {
                  const fieldValidation =
                    teamList.firstName === EMPTY_STRING ||
                    teamList.lastName === EMPTY_STRING ||
                    teamList.emailAddress === EMPTY_STRING ||
                    (errors.members && errors.members.length >= 1)
                  return fieldValidation
                })
                const isSubmitEnabled = isSubmitting ? (
                  <Loading block />
                ) : (
                  this.getSubmitLabel()
                )
                return (
                  <Grid
                    container
                    className={classes.memberForm}
                    component={Form}
                    direction="row"
                    justify="center"
                    alignItems="stretch"
                    spacing={1}
                  >
                    <FieldArray name="members">
                      {arrayHelpers => (
                        <>
                          {values.members &&
                            values.members.length > 0 &&
                            values.members.map((member, index) => (
                              <TeamMemberForm
                                key={index}
                                index={index}
                                roles={roles}
                                errors={errors}
                                helper={arrayHelpers}
                                mode={mode}
                                displayRemove={values.members.length > 1}
                                member={member}
                                meIsOwner={meIsOwner}
                                meEmailAddress={meEmailAddress}
                              />
                            ))}
                          {!teamMemberId ? (
                            <Grid
                              container
                              direction="column"
                              justify="center"
                              alignItems="center"
                              spacing={3}
                            >
                              <Grid item>
                                <Button
                                  color="primary"
                                  className={classes.buttonAdd}
                                  onClick={() => {
                                    this.setState({ continueAdding: true })
                                    submitForm()
                                  }}
                                >
                                  <Typography>
                                    {admin.teamMembers.addAnother}
                                  </Typography>
                                </Button>
                              </Grid>
                            </Grid>
                          ) : null}
                        </>
                      )}
                    </FieldArray>
                    <Grid
                      container
                      direction="column"
                      justify="center"
                      alignItems="center"
                      spacing={3}
                    >
                      <Grid item>
                        <Button
                          type="submit"
                          variant="contained"
                          size="large"
                          disabled={isSendInviteEnabled[0] || isSubmitting}
                          onClick={() => {
                            this.setState({
                              continueAdding: false
                            })
                          }}
                          data-testid="team-members-form-save"
                          color="primary"
                        >
                          <Typography>{isSubmitEnabled}</Typography>
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                )
              }}
            </Formik>
          </>
        )}
      </Page>
    )
  }
}

export default flow(
  withLaunchDarkly,
  withApolloConsumer,
  withStyles(styles)
)(TeamMembersForm)
