import React from 'react'
import NumberFormat from 'react-number-format'
import FormikNumberFormat from './FormikNumberFormat'

const NumberField = props => {
  const {
    field: { value: formikValue = '' },
    format
  } = props

  return (
    <NumberFormat
      {...props}
      customInput={FormikNumberFormat}
      format={format}
      value={formikValue}
      onValueChange={({ value }) => {
        const { name, value: fieldValue } = props.field

        // Fix autofill error in Chrome
        if (fieldValue || value) {
          props.form.setFieldValue(name, value)
        }
      }}
    />
  )
}

export default NumberField
