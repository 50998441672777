// @flow
import React, { useState, useEffect } from 'react'
import { Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import type { StageResponse, StageError } from 'screens/quote/QuoteTypes'
import { PROJECT_COST_CHANGE_DEBOUNCE_TIME } from 'properties/properties'
import { get, noop, throttle } from 'lodash'
import { processStageNameValue } from 'util/ProjectRowHelper'
import styles from './StagesContainer.styles'
import fieldNames from './stageFieldNames.enum'
import stageActions from './stageActions.enum'
import StageInputRow from './StageInputRow'
import type { StageInputHandlerProps } from './StageTypes'

type Props = {
  classes: {
    [string]: string
  },
  stages: Array<StageResponse>,
  readonly: boolean,
  autoFocusNewStage: boolean,
  handleStageField: (props: StageInputHandlerProps) => void,
  handleStageFieldAmount: (props: StageInputHandlerProps) => void,
  handleStageDeletion: (stageId: string) => void,
  handleAddStage: () => void,
  handleBlur: () => void,
  maxStagesReached: boolean,
  errors: { [key: string]: StageError }
}

const StagesContainer = ({
  classes,
  stages,
  handleStageField,
  handleStageFieldAmount,
  handleStageDeletion,
  handleAddStage,
  maxStagesReached,
  errors,
  handleBlur,
  ...drilledProps
}: Props) => {
  const [localStages, setLocalStages] = useState({})
  const [dirtyFields, setDirtyFields] = useState({})
  const [throttledHandlers, setThrottledHandlers] = useState({
    handleStageField: noop,
    handleStageFieldAmount: noop
  })

  useEffect(() => {
    setThrottledHandlers({
      handleStageField: throttle(
        handleStageField || noop,
        PROJECT_COST_CHANGE_DEBOUNCE_TIME
      ),
      handleStageFieldAmount: throttle(
        handleStageFieldAmount || noop,
        PROJECT_COST_CHANGE_DEBOUNCE_TIME
      )
    })
  }, [handleStageField, handleStageFieldAmount])

  const stagesCount = stages ? stages.length : 0
  const getStage = stageId => stages.find(stage => stage.id === stageId)
  const buildDeleteActionObject = (stageId: string) => {
    if (stagesCount <= 1) return {}
    return {
      [stageActions.delete]: async () => {
        await handleStageDeletion(stageId)
        handleBlur()
      }
    }
  }

  const setFieldDirty = (stageId, fieldName) => {
    const stageDirtyFields = dirtyFields[stageId] || {}
    setDirtyFields({
      ...dirtyFields,
      [stageId]: {
        ...stageDirtyFields,
        [fieldName]: true
      }
    })
  }

  const localHandleBlur = (stageId, fieldName) => () => {
    setFieldDirty(stageId, fieldName)
    handleBlur()
  }

  const localHandleStageField = async ({
    stageId,
    fieldName,
    value: initialValue
  }) => {
    const currentStage = localStages[stageId] || getStage(stageId)
    let value = initialValue
    if (fieldName === fieldNames.name) {
      value = processStageNameValue(value)
    }
    const changes = {
      [fieldName]: value
    }
    if (currentStage[fieldName] === changes[fieldName]) return
    setLocalStages({
      ...localStages,
      [stageId]: {
        ...currentStage,
        ...changes
      }
    })
    localHandleBlur(stageId, fieldName).call()
    if (!throttledHandlers.handleStageField) return
    throttledHandlers.handleStageField({ stageId, fieldName, value })
  }

  const localHandleStageFieldAmount = async ({ stageId, fieldName, value }) => {
    const currentStage = localStages[stageId] || getStage(stageId)
    setLocalStages({
      ...localStages,
      [stageId]: {
        ...currentStage,
        [fieldName]: value
      }
    })
    setFieldDirty(stageId, fieldName)
    if (!throttledHandlers.handleStageFieldAmount) return
    throttledHandlers.handleStageFieldAmount({ stageId, fieldName, value })
  }

  return (
    <Grid
      item
      xs={12}
      className={classes.stagesContainer}
      data-testid="stages-container"
    >
      {stages &&
        stages.map((stage, index) => (
          <StageInputRow
            key={stage.id}
            {...drilledProps}
            stage={localStages[stage.id] || stage}
            index={index}
            stageError={get(errors, stage.id, {})}
            stageDirtyFields={dirtyFields[stage.id] || {}}
            handleBlur={localHandleBlur}
            handleStageField={localHandleStageField}
            handleStageFieldAmount={localHandleStageFieldAmount}
            stagesCount={stagesCount}
            buildDeleteActionObject={buildDeleteActionObject}
          />
        ))}
    </Grid>
  )
}

export default withStyles(styles)(StagesContainer)
