import React from 'react'
import { ValidatorComponent } from 'react-material-ui-form-validator'
import { FormHelperText, Grid } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import ValidatorMessageStyles from './ValidatorMessage.styles'

const styles = theme => ({
  ...ValidatorMessageStyles(theme)
})

class ValidatorMessage extends ValidatorComponent {
  render() {
    const {
      children,
      classes,
      errorMessages,
      requiredError,
      validatorListener,
      validators,
      ...rest
    } = this.props

    const { isValid } = this.state

    return (
      <Grid {...rest}>
        {children}

        {!isValid && (
          <FormHelperText className={classes.error} error>
            {this.getErrorMessage()}
          </FormHelperText>
        )}
      </Grid>
    )
  }
}

export default withStyles(styles)(ValidatorMessage)
