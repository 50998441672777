// @flow
import React from 'react'

export type PrequalificationRouteContextType = {
  isSwiftlinks: boolean
}

const defaultContext: PrequalificationRouteContextType = {
  isSwiftlinks: false
}

export default React.createContext<PrequalificationRouteContextType>(defaultContext)
