import { gql } from 'graphql.macro'
import ContractResponseWithDiscountedFields from 'queries/fragments/ContractResponseWithDiscountedFields.graphql'
import QuoteProjectResponseFields from 'queries/fragments/QuoteProjectResponseFields.graphql'
import LoanProductResponseFields from 'queries/fragments/LoanProductResponseFields.graphql'
import SampleTermsResponseFields from 'queries/fragments/SampleTermsResponseFields.graphql'
import ProjectConfigurationResponseFields from 'queries/fragments/ProjectConfigurationResponseFields.graphql'
import ApplicantResponseFields from 'queries/fragments/ApplicantResponseFields.graphql'

export default gql`
  query getCreditApplication($id: String!) {
    customer(customerId: $id) {
      id
      firstName
      lastName
      email
      phoneNumber
      phoneNumberAlt1
      deviceVerificationStatus
      status
      opportunityStatus
      dateShareLinkSentToSms
      emailAddressVerified
      communications {
        dateEmailsSent(emailType: SHARE_LINK)
        userLinkEmailsSent: dateEmailsSent(
          emailType: EMAIL_VERIFICATION_REMINDER
        )
      }
      lender
      availableLenders {
        lender
      }
      loanType
      language
      addresses {
        addressType
        street
        city
        stateAbbreviation
        zip
      }
      creditApplication {
        conditionalApprovalLink
        status
        financingLimit
        decision {
          flags
          status
          expired
          qualifiedProducts {
            ...LoanProductResponseFields
            sampleTerms {
              ...SampleTermsResponseFields
            }
          }
          stipulations {
            tasks {
              taskType
            }
          }
        }
      }

      quote {
        hasAch
        loanProductId
        prePayment
        projects {
          ...QuoteProjectResponseFields
        }
        loanAmount
      }

      contract {
        ...ContractResponseWithDiscountedFields
      }
      isInfoEditable
      projectConfiguration {
        ...ProjectConfigurationResponseFields
      }
      primaryApplicant {
        ...ApplicantResponseFields
      }
      secondaryApplicant {
        ...ApplicantResponseFields
      }
      salesperson {
        firstName
        lastName
      }
    }
    me {
      userType
    }
  }
  ${ContractResponseWithDiscountedFields}
  ${QuoteProjectResponseFields}
  ${LoanProductResponseFields}
  ${SampleTermsResponseFields}
  ${ProjectConfigurationResponseFields}
  ${ApplicantResponseFields}
`
