export default {
  admin: '/admin',
  accept: '/accept',
  start: '/',
  settings: '/settings',
  apply: '/apply',
  applyForFinancing: '/apply-for-financing',
  customer: '/customer',
  login: '/login',
  logout: '/logout',
  resetPassword: '/reset-password',
  pipeline: '/pipeline',
  quote: '/quote',
  estimate: '/estimate',
  setup: '/setup',
  contract: '/contract',
  returnFromContract: '/return-from-contract',
  returnFromContractGuest: '/return-from-contract-guest',
  share: '/share',
  sharePendingState: '/share-pending-state',
  demoShare: '/share/demo',
  welcome: '/welcome',
  expired: '/expired',
  passwordForgotten: '/password-forgotten',
  passwordForgottenEmail: '/password-forgotten-email',
  loanAgreement: '/loan-agreement',
  creditDecision: '/credit-decision',
  onboarding: '/onboarding',
  dashboard: '/dashboard',
  opportunity: '/opportunity',
  // Weblinks partners hardcoded for now
  weblinksPartnerPath: '/',
  swiftlink: '/swiftlink',
  products: '/products',
  partner: '/partner',
  autopay: '/autopay',
  thanks: '/thanks',
  team: '/team',
  members: '/members',
  projectTypes: '/project-types',
  partnerAccount: '/account',
  bankAccount: '/bank-account',
  resources: '/resources',
  signOffer: '/sign-offer',
  signing: '/signing',
  confirmStage: '/share',
  verify: '/verify',
  verifyCoApp: '/verify-co-app',
  verifyIdentity: '/verify-identity',
  resubmitSsn: '/resubmit-ssn',
  lease: '/lease',
  offer: '/offer',
  uploadPersonalDocuments: '/upload-personal-documents',
  documentResolution: '/document-resolution',
  application: '/application',
  newExperience: '/new-experience'
}
