import { gql } from 'graphql.macro'

export default gql`
  fragment ProjectTypeConfigurationResponseFields on ProjectTypeConfigurationResponse {
    projectType
    disbursementSequenceType
    disbursementText
    loanProductTypes
    projectTypeText
    enabled
    stageConfiguration {
      maxStagesPerProject
      minStageAmount
      minStagePercentage
    }
  }
`
