import React, { useContext } from 'react'
import { FormControlLabel, Checkbox } from '@material-ui/core'
import customerFields from 'constants/enums/customerFields'
import TranslationsContext from 'util/TranslationsContext'

const AlternativeAddressCheckbox = ({
  classes,
  disabled,
  handleChange,
  checked
}) => {
  const l10n = useContext(TranslationsContext)
  return (
    <FormControlLabel
      control={
        <Checkbox
          data-testid="mailing-address-toggle"
          disabled={disabled}
          onChange={handleChange}
          color="primary"
          name={customerFields.showAddress}
          checked={checked}
        />
      }
      classes={{ label: classes.label }}
      label={l10n.customer.showMailingAddress}
    />
  )
}

export default AlternativeAddressCheckbox
