/* eslint-disable */
import React from 'react'
import { get, isEmpty } from 'lodash'
// TODO: import and fire Analytics events
// TODO: import and define loanPortalBackOffice route
import portalPaths from 'constants/enums/portalPaths'
import { eventCommands, eventType } from '@solarmosaic/mds.chrome.header'
import adminEnvironments from 'constants/enums/adminEnvironments'

import Routes from 'util/Routes'
import {
  getUsageContextForEnv,
  inIframe,
  getEnvironment
} from 'util/EnvironmentHelper'
import ContactCard from 'components/shared/ContactCard'
import financingPlatformOptions from 'constants/enums/financingPlatformOptions'
import featureFlags from 'constants/enums/featureFlags'
import { userTypesEnum } from 'constants/enums/userTypes'

export const leaseBaseURLs = {
  qa: 'https://qa.api.joinmosaic.com',
  test: 'https://test.api.joinmosaic.com',
  prod: 'https://api.joinmosaic.com',
  sandbox: 'https://sandbox.api.joinmosaic.com'
}
export const leaseBaseURL = leaseBaseURLs[getUsageContextForEnv()]

export const leaseLoanProductIds = {
  qa: 4443,
  test: 9634,
  prod: 5212,
  sandbox: 22139
}
export const leaseLoanProductId = leaseLoanProductIds[getUsageContextForEnv()]

export const leaseTierIds = {
  qa: 99,
  test: 99,
  prod: 6109,
  sandbox: 23016
}
export const leaseTierId = leaseTierIds[getUsageContextForEnv()]

export const leaseTierGroupIds = {
  qa: 33,
  test: 33,
  prod: 5868,
  sandbox: 22766
}
export const leaseTierGroupId = leaseTierGroupIds[getUsageContextForEnv()]

export const leaseLender = 'ModernHome'
export const defaultScope = 'trane'

export const headerProps = (hide = false, hideMenu = false) => ({
  hide: hide || inIframe(),
  hideMenu,
  renderHelp: ({ visible, onClose }) => (
    <ContactCard
      open={visible}
      onClose={onClose}
      customerIsBorrower={false}
      isOkayToCancelFlag={false}
      blockedByManualReview={false}
      financingType={financingPlatformOptions.lease}
    />
  )
})

export const defaultFooterProps = (userType, customer) => {
  const creditApplicationStatus = get(
    customer,
    'creditApplication.decision.status'
  )
  const blockedByManualReview = get(customer, 'blockedByManualReview')
  const isOkayToCancelFlag = get(customer, 'contract.isOkayToCancel', false)
  const contractStatusFlag = get(customer, 'contract.status')
  const opportunityStatus = get(customer, 'opportunityStatus')
  return {
    renderHelp: ({ visible, ...other }) => (
      <ContactCard
        open={visible}
        customerIsBorrower={userType === userTypesEnum.borrower}
        financingType={financingPlatformOptions.loan}
        {...{
          customer,
          isOkayToCancelFlag,
          blockedByManualReview,
          contractStatusFlag,
          creditApplicationStatus,
          opportunityStatus
        }}
        {...other}
      />
    )
  }
}

export const leaseDefaultWrapperProps = ldFlags => {
  const options = get(ldFlags, featureFlags.financingPlatformOptions)
  const isLeaseNavigationVisible = options.includes(
    financingPlatformOptions.lease
  )
  const isLoanNavigationVisible = options.includes(
    financingPlatformOptions.loan
  )

  return {
    headerProps: headerProps(),
    navigationProps: { isLeaseNavigationVisible, isLoanNavigationVisible }
  }
}

export const loanDefaultWrapperProps = (hideHeader = inIframe()) => ({
  headerProps: {
    hide: hideHeader,
    hideMenu: true,
    hideSearch: true
  }
})

const portalPathURL = (
  hostName: string = 'login',
  link: string = '/sign-in/reset-password'
): string => `${adminEnvironments[getEnvironment()](hostName)}${link}`

const portalLink = (hostName: string, link: string): string =>
  portalPathURL(hostName, link)

// TODO: fire analyticsEvents here
export const wrapperEventHandlers = ({
  history,
  headerContext,
  channelPartner
}) => ({
  logo: () => {
    if (headerContext) headerContext.resetHeader()
    history.push(Routes.start())
  },
  // Navigation
  help: () => {
    const event = new CustomEvent(eventType, {
      detail: { command: eventCommands.showHelp }
    })
    window.dispatchEvent(event)
  },
  home: () => {
    if (headerContext) headerContext.resetHeader()
    history.push(Routes.start())
  },
  leasePipeline: () => history.push(Routes.leasePipeline()),
  loanPipeline: () => {
    if (headerContext) headerContext.resetHeader()
    history.push(Routes.pipeline())
  },
  loanResources: () => {
    if (headerContext) headerContext.resetHeader()
    history.push(Routes.resources())
  },
  loanPortalBackOffice: () => {
    const hostName = get(channelPartner, 'hostName', '')
    if (!isEmpty(hostName)) {
      const url = portalLink(hostName, portalPaths.finance)
      window.open(url, '_blank')
    }
  },
  setup: () => {
    if (headerContext) headerContext.resetHeader()
    history.push(Routes.admin())
  },
  logout: () => {
    if (headerContext) headerContext.resetHeader()
    history.replace(Routes.logout())
  }
})

export const leaseAPIBasePaths = {
  engagement: '/lease/v1/pipeline',
  contact: '/lease/v1/contact',
  shareEstimate: '/lease/v1/estimates/share'
}

export const FinancingTypes = {
  LEASE: 'lease',
  LOAN: 'loan'
}

export const FirstStepModes = {
  HiddenAfterNext: 'HiddenAfterNext',
  AlwaysVisible: 'AlwaysVisible'
}
