import { gql } from 'graphql.macro'
import QuoteStageResponseFields from './QuoteStageResponseFields.graphql'

export default gql`
  fragment QuoteProjectResponseFields on QuoteProjectResponse {
    id
    projectType
    amount
    notes
    # status # deprecated
    projectName
    addOns {
      code
      price
    }
    stages {
      ...QuoteStageResponseFields
    }
  }
  ${QuoteStageResponseFields}
`
