import { gql } from 'graphql.macro'

import LoanProductResponseFields from 'queries/fragments/LoanProductResponseFields.graphql'
import ChannelPartnerSetupOptionResponseFields from 'queries/fragments/ChannelPartnerSetupOptionResponseFields.graphql'

export default gql`
  query getPartnerLogin {
    channelPartner {
      status
      id
      bankAccount {
        accountNumber
        routingNumber
        status
        accountType
        bank {
          name
          address
        }
      }
      availableProducts {
        ...LoanProductResponseFields
      }
      setupOptions {
        ...ChannelPartnerSetupOptionResponseFields
      }
    }
  }
  ${LoanProductResponseFields}
  ${ChannelPartnerSetupOptionResponseFields}
`
