import React, { useContext } from 'react'
import {
  Checkbox,
  FormControl,
  FormHelperText,
  FormControlLabel
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import TranslationsContext from 'util/TranslationsContext'
import PrequalificationFormContext from 'util/PrequalificationFormContext'

const styles = theme => ({
  agreeToDisclosuresCheckbox: {
    marginBottom: theme.spacing(4)
  },
  label: {
    paddingTop: theme.spacing(1.5)
  },
  termsAndConditionsError: { paddingLeft: theme.spacing(4), fontSize: '0.875rem' }
})
const useStyles = makeStyles(styles)

const DisclosuresCheckbox = ({
  disclosuresAreAccepted,
  toggleDisclosuresAcceptance,
  submitButtonClicked
}) => {
  const l10n = useContext(TranslationsContext)
  const { customerVerified } = useContext(PrequalificationFormContext)
  const classes = useStyles()

  return (
    <FormControl
      error={!disclosuresAreAccepted}
      className={classes.agreeToDisclosuresCheckbox}
    >
      <FormControlLabel
        classes={{ label: classes.label }}
        disabled={!customerVerified}
        control={
          <Checkbox onChange={toggleDisclosuresAcceptance} color="primary" />
        }
        label={l10n.apply.agreeToAllDisclosures}
      />

      {submitButtonClicked && !disclosuresAreAccepted && customerVerified && (
        <FormHelperText className={classes.termsAndConditionsError}>
          {l10n.apply.termsAndConditionsNotAccepted}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default DisclosuresCheckbox
