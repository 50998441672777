import { gql } from 'graphql.macro'

export default gql`
  query GetCustomerVerify($customerId: String!) {
    customer(customerId: $customerId) {
      id
      firstName
      lastName
      language
    }
  }
`
