// Custom styles specifically for the Admin screen
import coreScreenStyles from 'themes/coreScreenStyles'
import BackgroundMobileImage from 'images/background-mobile3.jpg'
import BackgroundDesktopImage from 'images/background-desktop3.jpg'
import {
  colors,
  pageTitle,
  bodyTextNormal,
  buttonMinWidth
} from 'themes/default'

export default theme => ({
  ...coreScreenStyles(theme),
  adminNavItemTitle: {
    marginBottom: 6
  },
  adminNavItem: {
    border: `1px solid ${theme.palette.primary.main}59`, // 59 adds 35% of opacity
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    paddingRight: theme.spacing(3),
    position: 'inherit',
    '&:hover': {
      backgroundColor: '#f8f8f8'
    }
  },
  adminStatusInfo: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  adminNavItemLink: {
    textDecoration: 'none'
  },
  adminArrowContainer: {
    bottom: '50%',
    position: 'absolute',
    width: '28px',
    height: '28px',
    [theme.breakpoints.down('sm')]: {
      left: '95%'
    },
    [theme.breakpoints.up('sm')]: {
      left: '98%'
    },
    cursor: 'pointer'
  },
  adminNavCtn: {
    flexDirection: 'column',
    position: 'relative'
  },
  adminNavItemArrow: {
    backgroundColor: 'white',
    borderRadius: '15px',
    border: `1px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    height: '100%',
    width: '100%'
  },
  adminBankAccount: {
    padding: theme.spacing(1.25)
  },
  adminNavMiniProduct: {
    backgroundColor: '#f2f2f2',
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.25),
    margin: '4px'
  },
  adminNavTeamItem: { borderTop: '1px solid #ddd' },
  adminNavTeam: {
    padding: `${theme.spacing(1)}px 0`
  },
  adminTeamNavTeamRole: {
    fontWeight: 500
  },
  page: {
    backgroundAttachment: 'fixed',
    backgroundRepeat: 'repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url("${BackgroundMobileImage}")`
    },
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url("${BackgroundDesktopImage}")`
    },
    minHeight: '100%',
    height: '100%'
  },
  chip: {
    fontSize: theme.spacing(1.3),
    borderRadius: theme.spacing(0.25),
    height: theme.spacing(2.75),
    textTransform: 'uppercase',
    padding: theme.spacing(0.25),
    fontWeight: 500,
    cursor: 'pointer',
    '& span': {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1)
    }
  },
  adminChip: {
    backgroundColor: 'rgb(249, 173, 49)',
    color: theme.palette.common.white
  },
  ownerChip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgb(249, 173, 49)',
    border: '1px solid rgb(249, 173, 49)'
  },
  salesChip: {
    backgroundColor: 'rgb(242, 242, 242)'
  },
  salesMgrChip: {
    backgroundColor: 'rgb(242, 242, 242)',
    color: '#000'
  },
  waitingChip: {
    backgroundColor: 'transparent',
    color: colors.mutedBlack,
    '& span': {
      padding: 0
    }
  },
  container: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(5),
    minHeight: '100%',
    maxHeight: '100%',
    overflow: 'auto',
    maxWidth: theme.breakpoints.values.md - theme.spacing(2),
    [theme.breakpoints.up('md')]: {
      marginRight: 'auto',
      marginLeft: 'auto'
    }
  },
  buttonAdd: {
    fontSize: '12px',
    fontWeight: 500
  },
  errorMessage: {
    color: colors.textBrightRed,
    fontWeight: 'normal',
    fontSize: theme.spacing(2) - 2
  },
  errorRequired: { color: 'red' },
  memberForm: {
    marginTop: theme.spacing(2)
  },
  paymentPlanError: {
    color: colors.textBrightRed,
    fontSize: theme.typography.fontSize,
    fontWeight: '400'
  },
  teamDivider: {
    marginBottom: theme.spacing(2)
  },
  adminProjHeading: {
    marginBottom: theme.spacing(2)
  },
  projTypeHeading: {
    display: 'flex',
    marginBottom: theme.spacing(2),
    color: `${theme.palette.grey['600']}`
  },
  projectTypeList: {
    display: 'flex',
    alignItems: 'center'
  },
  projectButton: {
    marginTop: theme.spacing(2)
  },
  projectBorder: {
    borderBottomWidth: 'thin',
    borderBottomStyle: 'dashed',
    borderBottomColor: `${theme.palette.grey['400']}`,
    marginBottom: theme.spacing(1),
    fontSize: '1rem'
  },
  teamFormDivider: {
    marginBottom: theme.spacing(3)
  },
  teamMemberValid: {
    backgroundColor: '#f2f2f2',
    borderRadius: theme.spacing(0.25)
  },
  formErrorContainer: {
    textAlign: 'center'
  },
  productsCol: {
    padding: '1rem'
  },
  productsBoldText: {
    fontWeight: 400
  },
  greenCheckmark: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '48px',
    height: '48px',
    borderRadius: '50%',
    background: 'rgba(102, 187, 106, 0.2)'
  },
  successText: {
    color: '#66BB6A'
  },
  teamRadioButton: {
    '& span:nth-child(2)': {
      paddingTop: '8px'
    }
  },
  productPercentage: {
    display: 'block',
    color: 'rgba(36, 36, 43, 1)'
  },
  productPercentageNumber: { fontWeight: 600 },
  productPercentageText: { fontWeight: 600, opacity: 0.6 },
  productName: { fontWeight: 600, color: 'rgba(36, 36, 43, 1)' },
  paymentPlanItem: { display: 'flex', padding: '8px' },
  paymentPlanLeftColumnContainer: { maxWidth: '35%' },
  paymentPlanLeftColumn: { fontWeight: 600 },
  paymentPlanRightColumnRoot: {
    minWidth: '65%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  paymentPlanRightColumnContainer: { minWidth: '35%', maxWidth: '90%' },
  swiftlinkDescription: {
    marginBottom: 6,
    '& span': { color: colors.redAlert }
  },
  swiftlinkTitle: {
    fontWeight: 500
  },
  welcomeContainer: {
    paddingLeft: '12%',
    paddingRight: '12%'
  },
  welcomeBody: {
    padding: `0 ${theme.spacing(10)}`
  },
  showAllLink: {
    color: colors.primary,
    textDecoration: 'none'
  },
  setupMenu: {
    padding: `0 0 0 ${theme.spacing(1.5)}px`
  },
  accountDescription: {
    marginBottom: 6
  },
  accountNavItemTitle: {
    fontWeight: '600',
    marginBottom: theme.spacing(2)
  },
  accountNavItem: {
    backgroundColor: theme.palette.grey['200'],
    boxShadow: 'none',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    paddingRight: theme.spacing(3),
    position: 'inherit'
  },
  accountNavCtn: {
    flexDirection: 'column',
    position: 'relative'
  },
  accountDocumentsTitle: {
    color: theme.palette.common.darkGray,
    fontWeight: '400',
    fontSize: theme.spacing(1.5),
    marginBottom: theme.spacing(2)
  },
  accountTitle: {
    fontWeight: '400'
  },
  accountDocumentTileContainer: {
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`
  },
  accountUploadNowButton: {},
  accountReviewPendingText: {
    fontWeight: 500,
    alignSelf: 'center'
  },
  pageTitle: {
    fontWeight: pageTitle.fontWeight,
    fontSize: pageTitle.fontSize,
    color: pageTitle.color
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  finishedGoToPortalBlock: {
    marginTop: theme.spacing(6.5)
  },
  button: {
    ...buttonMinWidth
  },
  teamMemberRoleAndMenuContainer: { height: '100%' },
  buttonMargin: {
    marginTop: theme.spacing(3)
  },
  mdsCheckmark: {
    width: '165px',
    marginBottom: theme.spacing(2)
  },
  p3xAdminEmptyState: {
    textAlign: 'center',
    marginTop: theme.spacing(10)
  }
})
