// @flow
import React from 'react'
import { Paper, Grid } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import { DEFAULT_ELEVATION } from 'properties/properties'

const styles = () => ({
  paper: {
    flex: 6,
    height: '100%',
    margin: '.5rem 3rem 1rem',
    // eslint-disable-next-line no-useless-computed-key
    ['@media (max-width: 420px)']: {
      margin: '.5rem 1.5rem 1rem'
    }
  },
  ctn: {
    height: 'inherit'
  }
})

type Props = {
  children?: React.Node,
  classes: Object
}

const PaperCtn = ({ classes, children, ...props }: Props) => (
  <Paper className={classes.paper} elevation={DEFAULT_ELEVATION} {...props}>
    <Grid container direction="column" className={classes.ctn}>
      {children}
    </Grid>
  </Paper>
)

export default withStyles(styles)(PaperCtn)
