// @flow
import * as React from 'react'
import { Grid, InputAdornment, NativeSelect } from '@material-ui/core'
import { TextValidator } from 'react-material-ui-form-validator'
import l10n from 'properties/translations'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import { addressFormat } from 'util/CustomerHelper'
import { residenceTypes } from 'util/FoundationFormHelper'
import { MonthWorkFormat } from '../NumberFormatHelper'

type Props = {
  ldFlags: Object,
  handleFormState: string,
  isPrimaryAddress: Function,
  classes: Object,
  addresses: Object,
  getAddressTypeTitle: Function,
  monthsAtPrimaryAddress: number,
  monthlyPaymentAtPrimary: number,
  appResidenceType: string,
  applicantStreet: string,
  applicantCity: string,
  applicantState: string,
  applicantZip: number
}

const WhereYouLive = (props: Props) => {
  const {
    handleFormState,
    isPrimaryAddress,
    classes,
    addresses,
    getAddressTypeTitle,
    monthsAtPrimaryAddress,
    monthlyPaymentAtPrimary,
    appResidenceType
  } = props
  return (
    <>
      {addresses.map(
        (key, index) =>
          isPrimaryAddress(key) && (
            <div key={key.addressType}>
              <Grid container spacing={0}>
                <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
                  <div className={classes.subFieldTitleStyle}>
                    <TextValidator
                      fullWidth
                      disabled
                      name={
                        l10n.foundation.applicationContent.fieldNames
                          .applicantAddress
                      }
                      label={getAddressTypeTitle(key)}
                      value={addressFormat(addresses[index])}
                    />
                  </div>
                </Grid>
                <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
                  <div className={classes.fieldTitleStyle}>
                    <TextValidator
                      fullWidth
                      onChange={handleFormState}
                      name={
                        l10n.foundation.applicationContent.fieldNames
                          .monthsAtPrimaryAddress
                      }
                      value={monthsAtPrimaryAddress}
                      label={
                        l10n.foundation.applicationContent
                          .monthsAtPrimaryResidenceLabel
                      }
                      validators={[
                        l10n.foundation.applicationContent.errorMessages
                          .required,
                        l10n.foundation.applicationContent.errorMessages
                          .applicantMonthsAtPrimaryAddressRegex
                      ]}
                      errorMessages={[
                        l10n.foundation.applicationContent.fieldRequiredLabel,
                        l10n.foundation.applicationContent.errorMessages
                          .invalidMonth
                      ]}
                      InputProps={{
                        inputComponent: MonthWorkFormat
                      }}
                      type="tel"
                    />
                  </div>
                </Grid>
              </Grid>
              <Grid container spacing={0}>
                <Grid
                  item
                  className={`${classes.applicantMonthlyPaymentFieldStyle} ${classes.gridItemStyle}`}
                  xs={12}
                  sm={6}
                >
                  <TextValidator
                    fullWidth
                    onChange={handleFormState}
                    name={
                      l10n.foundation.applicationContent.fieldNames
                        .monthlyPaymentAtPrimary
                    }
                    value={monthlyPaymentAtPrimary}
                    label={
                      l10n.foundation.applicationContent.monthlyPaymentLabel
                    }
                    validators={[
                      l10n.foundation.applicationContent.errorMessages.required,
                      l10n.foundation.applicationContent.errorMessages
                        .applicantMonthlyPaymentRegex
                    ]}
                    errorMessages={[
                      l10n.foundation.applicationContent.fieldRequiredLabel,
                      l10n.foundation.applicationContent.errorMessages
                        .invalidAmount
                    ]}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">$</InputAdornment>
                      )
                    }}
                    type="tel"
                  />
                </Grid>
                <Grid item xs={12} sm={6} className={classes.gridItemStyle}>
                  <div className={classes.fieldTitleStyle}>
                    {l10n.foundation.applicationContent.residenceTypeLabel}
                    <br />
                    <NativeSelect
                      fullWidth
                      onChange={handleFormState}
                      name={
                        l10n.foundation.applicationContent.fieldNames
                          .appResidenceType
                      }
                      value={appResidenceType}
                      required
                      className={classes.nativeSelectDropStyle}
                    >
                      {residenceTypes}
                    </NativeSelect>
                  </div>
                </Grid>
              </Grid>
            </div>
          )
      )}
    </>
  )
}

export default withLaunchDarkly(WhereYouLive)
