import BackgroundMobileImage from 'images/background-mobile3.jpg'
import BackgroundDesktopImage from 'images/background-desktop3.jpg'
import { pageTitle, bodyTextGreen, bodyTextNormal } from 'themes/default'

export default theme => ({
  page: {
    backgroundPosition: 'fixed',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      backgroundImage: `url("${BackgroundMobileImage}")`
    },
    [theme.breakpoints.up('sm')]: {
      backgroundImage: `url("${BackgroundDesktopImage}")`
    }
  },
  pageIframe: {
    [theme.breakpoints.down('sm')]: {
      backgroundImage: 'none'
    },
    [theme.breakpoints.up('sm')]: {
      backgroundImage: 'none'
    }
  },
  marginHeader: {
    marginBottom: theme.spacing(4)
  },
  pageTitle: {
    fontWeight: pageTitle.fontWeight,
    fontSize: pageTitle.fontSize,
    color: pageTitle.color
  },
  bodyTextGreen: {
    fontSize: bodyTextGreen.fontSize,
    fontWeight: bodyTextGreen.fontWeight,
    lineHeight: bodyTextGreen.lineHeight,
    color: bodyTextGreen.color
  },
  bodyTextNormal: {
    fontSize: bodyTextNormal.fontSize,
    fontWeight: bodyTextNormal.fontWeight,
    lineHeight: bodyTextNormal.lineHeight,
    color: bodyTextNormal.color
  },
  paymentText: {
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    color: theme.palette.common.successGreen
  },
  dividerLine: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  },
  btnCtn: {
    margin: '1rem .5rem'
  },
  integratorCompleteTitle: {
    color: theme.palette.common.successGreen,
    fontWeight: 400,
    textAlign: 'center',
    marginTop: `${theme.spacing(11)}px`,
    marginBottom: `${theme.spacing(3)}px`
  },
  integratorCompleteContent: {
    textAlign: 'center',
    marginBottom: `${theme.spacing(11)}px`
  },
  optionMenuAbsolute: {
    position: 'absolute',
    right: `${theme.spacing(2)}px`,
    [theme.breakpoints.up('md')]: {
      top: `${theme.spacing(5)}px`,
      right: `${theme.spacing(5)}px`
    }
  },
  container: {
    minHeight: '40vh',
    '& .MuiInputBase-input': {
      boxSizing: 'content-box'
    }
  }
})
