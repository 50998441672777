export default {
  pending: 'PENDING',
  approved: 'APPROVED',
  autoApproved: 'AUTO_APPROVED',
  counteroffered: 'COUNTER_OFFERED',
  refer: 'REFER',
  declined: 'DECLINED',
  withdrawn: 'WITHDRAWN',
  error: 'ERROR',
  nodecisionrun: 'NO_DECISION_RUN',
  notFound: 'NOT_FOUND'
}
