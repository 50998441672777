// @flow
import React, { Component } from 'react'
import { Snackbar, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { withStyles } from '@material-ui/core/styles'
import ServerError from '@material-ui/icons/ErrorOutline'

const styles = () => ({
  icon: {
    fontSize: 16
  },
  iconButton: {
    width: 40
  },
  iconStyle: {
    verticalAlign: 'middle',
    fontSize: 20,
    marginRight: 6,
    marginTop: -1
  }
})

export const errorToastIcon = {
  serverError: ServerError
}

type Props = {
  message: string,
  handleClose: Function,
  open: boolean,
  iconType: string,
  classes: {
    container: string,
    iconButton: string,
    icon: string
  },
  autoHideDuration: number
}

class ErrorToast extends Component<Props> {
  static defaultProps = {
    open: false,
    classes: {},
    autoHideDuration: 3000
  }

  handleClose = () => {
    const { handleClose } = this.props
    handleClose()
  }

  render() {
    const { message, open, classes, iconType, autoHideDuration } = this.props
    const Icon = iconType

    const action = (
      <IconButton
        key="close"
        color="inherit"
        aria-label="Close"
        onClick={this.handleClose}
        className={classes.iconButton}
      >
        <CloseIcon className={classes.icon} />
      </IconButton>
    )

    return (
      <>
        <Snackbar
          open={open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          message={
            <>
              {Icon && <Icon className={classes.iconStyle} />}
              <>{message}</>
            </>
          }
          action={action}
          autoHideDuration={autoHideDuration}
        />
      </>
    )
  }
}

export default withStyles(styles)(ErrorToast)
