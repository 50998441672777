import React, { useEffect, useContext } from 'react'
import { get } from 'lodash'
import { P2XScreen } from '@solarmosaic/p2x.screens.p2x-screen'
import { getUserTypeFromProps } from 'util/NavigationHelper'
import { getCustomerLocale } from 'util/CustomerHelper'
import { defaultFooterProps, loanDefaultWrapperProps } from 'properties/p3x'
import { useLazyQuery } from '@apollo/client'
import GetCustomerStatus from 'queries/GetCustomerStatus.graphql'
import UserContext from 'util/UserContext'
import { coApplicantFormEnabled } from 'util/FeatureHelper'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'

const ScreenWrapper = props => {
  const { channelPartner, customer: contextCustomer } = useContext(UserContext)
  const [getCustomerStatus, { data }] = useLazyQuery(GetCustomerStatus)
  const { ldFlags, children } = props

  const customer = props.customer || contextCustomer || get(data, 'customer')

  useEffect(() => {
    if (!customer && props.customerId) {
      const customerId = props.customerId

      getCustomerStatus({
        variables: {
          customerId
        }
      })
    }
  }, [customer, props.customerId, getCustomerStatus])

  const showCoApplicant = coApplicantFormEnabled(ldFlags, {
    channelPartner,
    customer
  })

  if (!showCoApplicant) {
    return children
  }

  const userType = getUserTypeFromProps(props)
  const locale = getCustomerLocale(customer)

  return (
    <P2XScreen
      wrapperProps={{
        ...loanDefaultWrapperProps(),
        footerProps: defaultFooterProps(userType, customer),
        locale
      }}
    >
      {children}
    </P2XScreen>
  )
}

export default withLaunchDarkly(ScreenWrapper)
