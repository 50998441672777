// @flow
import React from 'react'

export type PrequalificationFormContextType = {
  customer: any,
  customerLoading: boolean,
  customerVerified: boolean,
  customerPending: boolean,
  customerExpired: boolean,
  customerLoanType: string
}

const defaultContext: PrequalificationFormContextType = {
  customer: undefined,
  customerLoading: false,
  customerVerified: false,
  customerPending: false,
  customerExpired: false,
  customerLoanType: ''
}

export default React.createContext<PrequalificationFormContextType>(defaultContext)
