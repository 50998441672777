import head from 'lodash/head'

export const delay = ms => new Promise(resolve => setTimeout(resolve, ms))

export const throttledQueryLoad = async (ms, client, queryOptions) => {
  const minimumLoadPromise = delay(ms)
  const results = await Promise.all([
    client.query(queryOptions),
    minimumLoadPromise
  ])
  const { data } = head(results)
  return data
}
