// @flow
import { makeVar, gql } from '@apollo/client'

export const SETUP_DEFAULTS = {
  bankAccount: null,
  cancelled: false
}

export const setup: any = makeVar(SETUP_DEFAULTS)

const cancelLocalSetup = () => {
  setup({
    ...setup(),
    cancelled: true
  })

  return null
}

const resetLocalSetupCancelled = () => {
  setup({
    ...setup(),
    cancelled: false
  })

  return null
}

const cleanUpLocalBankAccount = () => {
  setup({
    ...setup(),
    bankAccount: null
  })

  return null
}

const setLocalBankAccount = ({ bankAccount }) => {
  setup({
    ...setup(),
    bankAccount: {
      ...bankAccount
    }
  })

  return null
}

export const GET_SETUP = gql`
  query getSetup {
    setup @client {
      bankAccount {
        accountHolderFullName
        accountNumber
        accountType
        bankName
        routingNumber
      }
      cancelled
    }
  }
`

export const SetupStore = {
  cancelLocalSetup,
  cleanUpLocalBankAccount,
  setLocalBankAccount,
  resetLocalSetupCancelled
}
