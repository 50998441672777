import { fade } from '@material-ui/core/styles/colorManipulator'

const palette = themeColors => {
  return {
    common: {
      black: themeColors.normalBlack,
      realBlack: themeColors.blackColor,
      white: themeColors.background,
      teal: themeColors.mosaicTeal,
      successGreen: themeColors.iconSuccess,
      textLinkBlue: themeColors.textLinkBlue,
      mutedBlack: themeColors.mutedBlack,
      darkGray: themeColors.darkGray,
      blackLight: themeColors.blackLight,
      redFail: themeColors.redFail,
      greenSuccess: themeColors.greenSuccess,
      blueBackgroundColor: themeColors.blueBackgroundColor,
      blueOutlineColor: themeColors.blueOutlineColor,
      textSnackBranch: themeColors.textSnackBranch,
      textBrightRed: themeColors.textBrightRed,
      whiteSmoke: themeColors.whiteSmoke,
      extraDarkGray: themeColors.extraDarkGray
    },
    type: 'light',
    overlay: 'rgba(196, 196, 196, 0.85)',
    primary: {
      light: fade(themeColors.primary, 0.15),
      main: themeColors.primary,
      dark: themeColors.primary[700],
      contrastText: themeColors.background
    },
    secondary: {
      light: fade(themeColors.secondary, 0.2),
      main: themeColors.secondary,
      dark: themeColors.primary[700],
      contrastText: themeColors.background
    },
    error: {
      light: '#e57373',
      main: themeColors.errorRed,
      dark: '#d32f2f',
      contrastText: themeColors.background
    },
    grey: {
      50: '#fafafa',
      100: '#f5f5f5',
      200: '#eeeeee',
      300: '#e0e0e0',
      400: '#bdbdbd',
      500: '#9e9e9e',
      600: '#757575',
      700: '#616161',
      800: '#424242',
      900: '#212121',
      A100: '#d5d5d5',
      A200: '#aaaaaa',
      A400: '#303030',
      A700: '#616161'
    },
    black: 'rgb(34, 34, 34)',
    darkBlack: '#222222',
    contrastThreshold: 3,
    tonalOffset: 0.2,
    text: {
      primary: themeColors.normalBlack,
      secondary: themeColors.mutedBlack,
      disabled: 'rgba(0, 0, 0, 0.38)',
      hint: 'rgba(0, 0, 0, 0.38)'
    },
    divider: 'rgba(0, 0, 0, 0.12)',
    border: 'rgba(0, 0, 0, 0.25)',
    iconSuccess: themeColors.iconSuccess,
    redAlert: themeColors.redAlert,
    iconSuccessContainer: themeColors.iconSuccessContainer,
    background: {
      paper: themeColors.background,
      default: '#fafafa',
      secondary: fade(themeColors.primary, 0.15),
      backgroundImageAlternative: themeColors.backgroundImageAlternative,
      backgroundImageAlternativeLogoRight:
        themeColors.backgroundImageAlternativeLogoRight,
      lockedTextFieldColor: themeColors.lockedTextFieldColor,
      warning: themeColors.warningBackground
    },
    action: {
      active: themeColors.mutedBlack,
      hover: 'rgba(0, 0, 0, 0.14)',
      selected: 'rgba(0, 0, 0, 0.08)',
      disabled: 'rgba(0, 0, 0, 0.26)',
      disabledBackground: 'rgba(0, 0, 0, 0.12)',
      actionTextColor: themeColors.warningRed,
      textLinkColor: themeColors.primary,
      textEmphasisColor: themeColors.textEmphasisBlue,
      checkmarkColor: themeColors.checkmarkYellow,
      link: themeColors.primary,
      startButton: themeColors.blueBorderColor,
      warningBannerOrange: themeColors.warningOrange,
      yellowBackgroundHint: themeColors.yellowBackgroundHint
    },
    gradients: {
      warningGradient:
        'linear-gradient(135deg, #D14C43 25%, #C53D34 25%, #C53D34 50%, #D14C43 50%, #D14C43 75%, #C53D34 75%, #C53D34 100%)'
    }
  }
}

export default palette
