import { gql } from 'graphql.macro'

import ProjectConfigurationResponseFields from 'queries/fragments/ProjectConfigurationResponseFields.graphql'
import ChannelPartnerSetupOptionResponseFields from 'queries/fragments/ChannelPartnerSetupOptionResponseFields.graphql'
export default gql`
  query getMeWithBranches {
    me {
      userId
      firstName
      lastName
      userType
      userRoles
      permissions
      accessModes
      emailAddress
      isSalesforceIdentityEnabled
      branch {
        id
        name
        loanProducts {
          count
          solarCount
          homeCount
        }
      }
      branches {
        id
        name
        isActive
      }
    }
    channelPartner {
      id
      name
      status
      logoUrl
      alternateFinancingEnabled
      bundleId
      disclosures {
        assetCode
        version
      }
      aggregatorCodes
      flags
      addOns {
        code
        projectType
        defaultPrice
      }
      theme {
        primaryColor
        secondaryColor
        headerColor
      }
      hostName
      projectConfiguration {
        ...ProjectConfigurationResponseFields
      }
      setupOptions {
        ...ChannelPartnerSetupOptionResponseFields
      }
    }
    customer {
      id
      opportunityId
      aggregatorCode
      loanType
      language
      salesperson {
        id
      }
    }
  }

  ${ProjectConfigurationResponseFields}
  ${ChannelPartnerSetupOptionResponseFields}
`
