import React from 'react'
import {
  Select,
  FormControl,
  FormHelperText,
  InputLabel
} from '@material-ui/core'
import { ValidatorComponent } from 'react-material-ui-form-validator'

// This file sourced from react-material-ui-form-validator github
class SelectValidator extends ValidatorComponent {
  render() {
    const {
      label,
      error,
      errorMessages,
      validators,
      requiredError,
      helperText,
      validatorListener,
      withRequiredValidator,
      showLabel = true,
      showError = true,
      fullWidth = true,
      ...rest
    } = this.props

    const { isValid } = this.state

    return (
      <FormControl fullWidth={fullWidth} error={!isValid || error}>
        {showLabel && <InputLabel>{label}</InputLabel>}
        <Select native {...rest} error={!isValid || error} />
        <FormHelperText>
          {showError && !isValid ? this.getErrorMessage() : helperText}
        </FormHelperText>
      </FormControl>
    )
  }
}

export default SelectValidator
