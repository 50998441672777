// @flow
import React from 'react'
import NumberFormat from 'react-number-format'
import fieldMasks from 'constants/enums/fieldMasks'
import placeholders from 'properties/placeholders'
import FormikNumberFormat from './FormikNumberFormat'

type Props = {
  field: { name: string, value: string },
  form: { setFieldValue: Function }
}

const PhoneField = (props: Props) => {
  const {
    field: { value: formikValue = '' }
  } = props

  return (
    <NumberFormat
      placeholder={placeholders.phoneNumber}
      {...props}
      customInput={FormikNumberFormat}
      format={fieldMasks.phoneNumber}
      mask="_"
      onValueChange={({ value }) => {
        const { name, value: fieldValue } = props.field

        // Fix autofill error in Chrome
        if (fieldValue || value) {
          props.form.setFieldValue(name, value)
        }
      }}
      type="tel"
      value={formikValue}
    />
  )
}

export default PhoneField
