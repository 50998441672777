// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import get from 'lodash/get'
import applicationStates from 'constants/enums/foundationApplicationDecisionStatuses'
import DecisionScreen from './DecisionScreen'
import creditApplicationStatus from './query/creditApplicationStatus.graphql'

const styles = theme => ({
  root: { padding: theme.spacing(2) },
  logo: {
    marginTop: '10px',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: theme.spacing(7),
    width: '180px'
  },
  logoItem: {
    textAlign: 'center',
    flexBasis: '0%',
    maxWidth: '100%'
  },
  borrowerDisclaimer: {
    backgroundColor: 'rgb(239,239,239)',
    padding: theme.spacing(2)
  },
  mainTitle: {
    fontWeight: 300,
    marginBottom: theme.spacing(2)
  },
  subTitle: {
    marginBottom: theme.spacing(1)
  },
  fontColor: { color: 'rgb(96,96,96)' },
  buttonColor: {
    color: '#28903b',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2)
  },
  buttonContainer: { textAlign: 'center' },
  userData: {
    backgroundColor: 'rgb(239,239,239)',
    textAlign: 'center',
    padding: theme.spacing(3),
    marginTop: theme.spacing(2)
  },
  userNames: { fontWeight: 600 },
  userAdditional: { color: '#28903b' },
  buttonFont: { fontWeight: 600, fontSize: '16px' },
  installerDisclaimer: { marginBottom: theme.spacing(2) },
  userFont: { fontSize: '16px', marginBottom: theme.spacing(0.5) },
  container: { maxWidth: '600px', margin: '0 auto' },
  logoColor: { fill: '#28903b' },
  anchorStyle: { textDecoration: 'none' }
})

type Props = {
  classes: {
    container: string,
    root: string,
    logoContainer: string,
    logoItem: string,
    logo: string,
    mainTitle: string,
    fontColor: string,
    subTitle: string,
    userData: string,
    userNames: string,
    userFont: string,
    userAdditional: string,
    buttonContainer: string,
    buttonColor: string,
    buttonFont: string,
    borrowerDisclaimer: string,
    installerDisclaimer: string,
    anchorStyle: string,
    logoColor: string
  },
  repData: {
    firstName: string,
    lastName: string,
    email: string,
    phoneNumber: string
  },
  userType: string,
  applicationState: string,
  client: Object,
  customerId: string,
  history: Object
}

type State = {
  isLoading: boolean,
  errorOccurred: boolean,
  applicationState: string,
  didTimeOut: boolean
}

class DecisionContainer extends React.Component<Props, State> {
  state = {
    isLoading: false,
    errorOccurred: false,
    applicationState: this.props.applicationState || applicationStates.pending,
    didTimeOut: false
  }

  componentDidMount() {
    this.getDecision()
  }

  getDecision = () => {
    this.setState({ isLoading: true, didTimeOut: false })
    setTimeout(() => {
      this.setState({ didTimeOut: true })
    }, 15000)
    this.getCreditDecision()
  }

  getCreditDecision = async () => {
    const { client, customerId } = this.props
    const { didTimeOut, applicationState } = this.state
    try {
      const result = await client.query({
        query: creditApplicationStatus,
        fetchPolicy: 'network-only',
        variables: { customerId }
      })
      const status = get(
        result.data.customer,
        'creditApplicationAlternativeFinancing.status'
      )
      this.setState({ applicationState: status, errorOccurred: false })
    } catch (err) {
      this.showError(err)
    }
    if (!didTimeOut && applicationState === 'REFER') {
      this.getCreditDecision()
      return
    }
    this.setState({ isLoading: false })
  }

  showError = bool => {
    this.setState({
      errorOccurred: bool
    })
  }

  render() {
    return (
      <DecisionScreen
        {...this.props}
        {...this.state}
        getDecision={this.getDecision}
      />
    )
  }
}

export default withStyles(styles)(DecisionContainer)
