// @flow

import userRoles from './userRoles'

export const signatorRolesEnum = Object.freeze({
  PRIMARY_APPLICANT: 'PRIMARY_APPLICANT',
  SECONDARY_APPLICANT: 'SECONDARY_APPLICANT'
})

export const userRolesToSignatorRolesMapping = {
  [userRoles.borrower.primary]: signatorRolesEnum.PRIMARY_APPLICANT,
  [userRoles.borrower.secondary]: signatorRolesEnum.SECONDARY_APPLICANT
}

export type SignatorRoles = $Values<typeof signatorRolesEnum>

export default signatorRolesEnum
