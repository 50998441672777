export default theme => ({
  marginHeader: {},
  container: {
    paddingTop: theme.spacing(8)
  },
  icon: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2)
  },
  goBackText: {
    fontSize: theme.spacing(1.5)
  },
  errorTitleDescription: {
    fontSize: theme.spacing(2) + 2,
    fontWeight: theme.typography.fontWeightBold
  },
  errorTextDescription: {
    [theme.breakpoints.down('sm')]: {
      padding: `0 ${theme.spacing(2.5)}px`
    },
    fontSize: theme.spacing(2) - 2
  },
  alignErrorDescription: {
    minHeight: theme.spacing(37.5)
  }
})
