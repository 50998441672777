// @flow
import React, { useContext } from 'react'
import { Grid, Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import QuoteContext from 'util/QuoteContext'
import l10n from 'properties/translations'
import { flow } from 'lodash'
import withLaunchDarkly from 'components/shared/LaunchDarklyHOC'
import styles from './StageErrorMessage.styles'

const { minCostError, nameRequiredError } = l10n.quote.projectRow.stage

const StageErrorMessage = ({
  classes,
  stageError,
  stageDirtyFields,
  ldFlags
}) => {
  const { channelPartnerProjectConfiguration } = useContext(QuoteContext)
  const { showNameError, showAmountError } = stageError

  let text
  if (showNameError && stageDirtyFields.name) {
    text = nameRequiredError
  }
  if (showAmountError) {
    text = minCostError(channelPartnerProjectConfiguration)
  }
  if (!text) return null

  return (
    <Grid item xs={11} className={classes.stageErrorContainer}>
      <Typography
        data-testid="stage-error-message"
        variant="caption"
        className={classes.stageErrorMessage}
      >
        {text}
      </Typography>
    </Grid>
  )
}

export default flow(withLaunchDarkly, withStyles(styles))(StageErrorMessage)
