import { matchPath } from 'react-router-dom'
import { get, has, isString, isEmpty } from 'lodash'
import { portalPathURL } from 'util/PortalAdminHelper'
import { isBorrower } from 'util/CustomerHelper'
import portalPaths from 'constants/enums/portalPaths'
import { privateRoutesConfig } from 'appConfig'

export const getCustomerIdFromProps = props => {
  const customerIdFromUrl = get(props, 'match.params.customerId')
  if (customerIdFromUrl) return customerIdFromUrl

  if (props.me && isBorrower(props.me)) {
    return get(props, 'me.userId')
  }

  // If customerId isn't either in the URL or me query (for borrowers), it doesn't exist in this context
  return undefined
}

export const getUserTypeFromProps = props => get(props, 'me.userType')

export const getCustomerIdFromLocation = pathname => {
  let params = {}
  const parts = pathname.split('/')

  privateRoutesConfig.forEach(route => {
    if (!route.path.startsWith(`/${parts[1]}`)) return
    const match = matchPath(pathname, { path: route.path })
    if (match && !isEmpty(match.params)) params = match.params
  })
  return params.customerId
}

export const shouldShowMosaicLogo = (pathname, isFullSolar) => {
  if (isFullSolar) return false

  return (
    privateRoutesConfig.find(
      route => (matchPath(pathname, { path: route.path }) || {}).isExact
    ) || {}
  ).showLogo
}

export const customerHasCreditApplication = customer => {
  if (!customer) return false
  if (customer.creditApplication === undefined) {
    throw new Error('Customer object must contain key `creditApplication`')
  }
  return isString(get(customer, 'creditApplication.status'))
}

export const getCreditApplication = customer => {
  return get(customer, 'creditApplication.status', false)
}

export const reloadPage = () => {
  document.location.reload()
}

export const portalLink = (hostName: string, link: string): string =>
  portalPathURL(hostName, link)

export const goToPortalLink = (hostName?: string) => {
  const url = portalLink(hostName, portalPaths.branches)
  window.open(url, '_blank')
}

export const queryStringHasKey = (queryString: string = '', key: string) => {
  if (!queryString) return false
  let stringToParse = queryString
  if (queryString.startsWith('?')) {
    stringToParse = queryString.substring(1)
  }
  const urlParams = new URLSearchParams(stringToParse)
  const queryParams = Object.fromEntries(urlParams.entries())
  return has(queryParams, key)
}

export const parseQuery = (search: string) => {
  const urlParams = new URLSearchParams(search)
  const parsedQuery = Object.fromEntries(urlParams.entries())
  return parsedQuery
}

export function addStyle(styleString: string) {
  const style = document.createElement('style')
  style.textContent = styleString
  document.head.append(style)
}
