// @flow
import React from 'react'
import { withStyles } from '@material-ui/core/styles'
import { DOT_SEPARATOR } from 'properties/properties'
import SuperScriptAmountStyles from 'components/shared/SuperScriptAmount.styles'

type Props = { price: string, 'data-testid': string }

const SuperScriptAmount = ({ price, classes, ...rest }: Props) => {
  const [whole, decimal, secondDecimal] = String(price).split(DOT_SEPARATOR)
  const decimalWithPoint = decimal => decimal && `.${decimal}`
  return (
    <span data-testid={rest['data-testid']}>
      {whole}
      <sup key="2" className={classes.sup}>
        {decimalWithPoint(decimal)}
        {decimalWithPoint(secondDecimal)}
      </sup>
    </span>
  )
}

export default withStyles(SuperScriptAmountStyles)(SuperScriptAmount)
