import { useState, useEffect } from 'react'

const CustomerLoadedHandler = ({
  children,
  reset,
  customer
}: {
  children: React$Node,
  reset: Function,
  customer: { id: string }
}) => {
  const [memoizedCustomer, setMemoizedCustomer] = useState(false)
  useEffect(() => {
    if (!memoizedCustomer.id && customer.id) {
      // Customer just finished loading
      reset()
    }
    setMemoizedCustomer(customer)
  }, [customer, memoizedCustomer, reset])
  return children
}

export default CustomerLoadedHandler
